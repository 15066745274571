import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Grid, FormControl, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { useLinkStyles } from 'src/styles/linkStyles';
import { linkUrlRegex } from 'src/utils/constant';

export function AddLinkForm({ initialValues, saveLink }) {
  const classes = useLinkStyles();
  const currentSchool = useSelector((state) => state.school.currentSchool);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setFieldValue }) => {
        let errorObj = {};
        if (!values.title) {
          errorObj = { ...errorObj, title: 'Title is required.' };
        }
        if (!values.url)
          errorObj = { ...errorObj, url: 'URL/Email is required.' };
        if (values.url) {
          if (values.url.includes('@')) {
            // EMAIL VALIDATION

            const schema = yup.object().shape({
              email: yup.string().email().required()
            });
            try {
              await schema.validate({
                email: values.url.split('mailto:')[1] ?? values.url
              });
            } catch (error) {
              if (error.message) {
                errorObj = { ...errorObj, url: 'Email is not valid.' };
              }
            }
          } else {
            // URL VALIDATION
            const checkURLIsValid = values.url.match(linkUrlRegex);
            if (checkURLIsValid == null) {
              errorObj = { ...errorObj, url: 'URL is not valid.' };
            }
          }
        }
        setErrors(errorObj);
        if (!Object.keys(errorObj).length) {
          setFieldValue('title', '');
          setFieldValue('url', '');
          saveLink({ schoolId: currentSchool.id, ...values });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <div className={classes.addLinkForm}>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  <FormControl component="fieldset">
                    <TextField
                      label="Title"
                      variant="outlined"
                      name="title"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      error={Boolean(touched.title && errors.title)}
                      value={values.title}
                      className={classes.inputControlBox}
                      helperText={touched.title && errors.title}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormControl component="fieldset">
                    <TextField
                      label="URL/Email"
                      variant="outlined"
                      className={classes.inputControlBox}
                      name="url"
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      error={Boolean(touched.url && errors.url)}
                      value={values.url}
                      helperText={touched.url && errors.url}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Grid container justify="flex-end">
                    <Button
                      variant="contained"
                      component="span"
                      color="primary"
                      className={classes.buttonText}
                      startIcon={<AddIcon />}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Add Link
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}
