import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withFirebase } from 'src/utils/firebase';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  counties,
  timeZones,
  defaultPNTime,
  muiTimePickerTheme,
  defaultPNStaffTime
} from 'src/utils/constant';
import {
  TextField,
  Typography,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  FormControl,
  Button,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { TimePicker } from '@material-ui/pickers';
import AddSchoolAPISyncForm from './schoolAutomation/AddSchoolAPISyncForm';
import LoadingBar from 'src/components/schoolAutomationComp/LoadingBarStatus';
import { profileStyles } from 'src/styles/schoolStyle/profileStyles';
import { deepEqual } from 'src/utils/deepEqual';
import { useRef } from 'react';
 
// eslint-disable-next-line no-unused-vars
function Profile({ firebase, ...props }) {
  const classes = profileStyles();
  const {
    isEdit = false,
    setOpenDeleteModal,
    accountFlag,
    setIsApiSyncForm,
    isValidationSuccessFull,
    setIsValidationSuccessFull,
    setIsSchoolCreated,
    setApiSyncSchoolData,
    isApiSyncForm,
    submitForm,
    checkSchoolDataUpdated,
    isCancelButtonClicked,
    handleCancelButtonVisibility
  } = props;
  const prefillSchoolData = useSelector((state) => state.school.currentSchool);
  const isSchoolDataUpdated = useSelector((state) => state.school.isSchoolDataUpdated);
  const [initialValues, setInitialValue] = useState({
    schoolName: '',
    country: '',
    timezone: '',
    state: '',
    notificationsTiming: {
      year: defaultPNTime,
      class: defaultPNTime,
      coCurricular: defaultPNTime,
      subject: defaultPNTime,
      staff: defaultPNStaffTime
    }
  });
 
  const [schoolAccountType, setSchoolAccountType] = useState('APISync');
  const [formKey, setFormKey] = useState(Math.random());
  const buttonTextRef = useRef(null);
 
  useEffect(() => {
    if (isApiSyncForm) {
      setSchoolAccountType('APISync');
    } else {
      setSchoolAccountType('manualEntry');
    }
  }, [isApiSyncForm]);
 
  useEffect(() => {
    const profileValues = {
      schoolName: prefillSchoolData.schoolName || '',
      country: prefillSchoolData.country || '',
      timezone: prefillSchoolData.timezone || '',
      state: prefillSchoolData.state || '',
      notificationsTiming: {
        year: prefillSchoolData?.notificationsTiming?.year
          ? prefillSchoolData?.notificationsTiming?.year
          : defaultPNTime,
        class: prefillSchoolData?.notificationsTiming?.class
          ? prefillSchoolData?.notificationsTiming?.class
          : defaultPNTime,
        coCurricular: prefillSchoolData?.notificationsTiming?.coCurricular
          ? prefillSchoolData?.notificationsTiming?.coCurricular
          : defaultPNTime,
 
        subject: prefillSchoolData?.notificationsTiming?.subject
          ? prefillSchoolData?.notificationsTiming?.subject
          : defaultPNTime,
 
        staff: prefillSchoolData?.notificationsTiming?.staff
          ? prefillSchoolData?.notificationsTiming?.staff
          : defaultPNStaffTime
      }
    };    
    setInitialValue(profileValues);
    setFormKey(Math.random());
    if (isCancelButtonClicked) {
      handleCancelButtonVisibility()
    }
  }, [prefillSchoolData, isCancelButtonClicked]);

  // SCHEMA VALIDATOR FOR
  const validationSchema = Yup.object().shape({
    schoolName: Yup.string().trim().required('School name is required'),
    country: Yup.string().trim().required('Country is required'),
    timezone: Yup.string().trim().required('Timezone is required'),
    state: Yup.string().trim()
  });
 
  const handleDateActionChange = (newTime, value, formData) => {
    setInitialValue(() => ({
      ...formData,
      notificationsTiming: {
        ...formData.notificationsTiming,
        [value]: moment(newTime.$d).format('LLLL')
      }
    }));
  };
  const handleSchoolAccountType = (event) => {
    if (event.target.value === 'APISync') {
      setIsApiSyncForm(true);
    } else {
      setIsApiSyncForm(false);
    }
    setSchoolAccountType(event.target.value);
  };
 
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {isEdit ? (
          ''
        ) : !isValidationSuccessFull ? (
          <section style={{ marginBottom: '1rem' }}>
            <div className={classes.label}>Create with</div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={schoolAccountType}
                onChange={handleSchoolAccountType}
              >
                <FormControlLabel
                  value="APISync"
                  control={<Radio color="primary" />}
                  label="API Sync"
                />
                <FormControlLabel
                  value="manualEntry"
                  control={<Radio color="primary" />}
                  label="Manual entry"
                />
              </RadioGroup>
            </FormControl>
          </section>
        ) : (
          <>
            <LoadingBar
              setIsValidationSuccessFull={setIsValidationSuccessFull}
              setApiSyncSchoolData={setApiSyncSchoolData}
              setIsSchoolCreated={setIsSchoolCreated}
            />
          </>
        )}
      </div>
      <div style={{ display: 'inline-flex', width: '100%' }}>
        {!isValidationSuccessFull &&
          (!isEdit && schoolAccountType === 'APISync' ? (
            <AddSchoolAPISyncForm
              setIsValidationSuccessFull={setIsValidationSuccessFull}
            />
          ) : (
            <Formik
              key={formKey}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                if (props.accountFlag && buttonTextRef.current === 'Save') {
                  submitForm({ ...values }, buttonTextRef.current);
                } else {
                  submitForm({ ...values }, buttonTextRef.current);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                initialValues: formikInitialValues
              }) => {
                if (deepEqual(formikInitialValues, initialValues)) {
                  checkSchoolDataUpdated(values)
                }
 
                return (
                  <form
                    noValidate
                    onSubmit={handleSubmit}
                    className={classes.contentWrapper}
                  >
                    {/* ==== */}
                    <Typography className={classes.formTitle}>
                      School Profile
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormControl
                          component="fieldset"
                          classes={{ root: classes.formControl }}
                        >
                          <TextField
                            label="School Name"
                            variant="outlined"
                            name="schoolName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(
                              touched.schoolName && errors.schoolName
                            )}
                            helperText={touched.schoolName && errors.schoolName}
                            value={values.schoolName}
                            className={classes.controlBox}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          classes={{ root: classes.formControl }}
                        >
                          <InputLabel id="country-select-outlined-label">
                            Country
                          </InputLabel>
                          <Select
                            labelId="country-select-outlined-label"
                            id="country-select-outlined"
                            value={values.country}
                            onBlur={handleBlur}
                            onChange={(e) => setFieldValue('country', e.target.value)}
                            className={classes.inputControlBox}
                            error={Boolean(touched.country && errors.country)}
                            name="country"
                            label="Country"
                          >
                            <MenuItem value="">
                              <em>Country</em>
                            </MenuItem>
                            {counties.length &&
                              counties.map((item) => {
                                return (
                                  <MenuItem key={item.code} value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {touched.country && errors.country && (
                            <FormHelperText style={{ color: 'red' }}>
                              {errors.country}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          classes={{ root: classes.formControl }}
                        >
                          <InputLabel id="timezone-select-outlined-label">
                            Timezone
                          </InputLabel>
                          <Select
                            labelId="timezone-select-outlined-label"
                            id="timezone-select-outlined"
                            value={values.timezone}
                            onBlur={handleBlur}
                            onChange={(e) => setFieldValue('timezone', e.target.value)}
                            className={classes.inputControlBox}
                            error={Boolean(touched.timezone && errors.timezone)}
                            name="timezone"
                            label="Timezone"
                          >
                            <MenuItem value="">
                              <em>Timezone</em>
                            </MenuItem>
                            {timeZones.length &&
                              timeZones.map((item) => {
                                return (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {touched.timezone && errors.timezone && (
                            <FormHelperText style={{ color: 'red' }}>
                              {errors.timezone}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
 
                      <Grid item xs={12}>
                        <FormControl
                          component="fieldset"
                          classes={{ root: classes.formControl }}
                        >
                          <TextField
                            variant="outlined"
                            value={values.state}
                            onBlur={(e) => {
                              setFieldValue(
                                'state',
                                e.target.value.trim().split(/ +/).join(' ')
                              );
                              handleBlur(e);
                            }}
                            onChange={handleChange}
                            error={Boolean(touched.state && errors.state)}
                            name="state"
                            label="State / City"
                            className={classes.controlBox}
                            helperText={touched.state && errors.state}
                          />
                        </FormControl>
                      </Grid>
                      {/* ---- */}
 
                      <Grid item xs={12}>
                        <ThemeProvider theme={muiTimePickerTheme}>
                          <Typography className={classes.notificationTitle}>
                            Push Notification Times
                          </Typography>
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateRows: 'repeat(5, 1fr)'
                            }}
                          >
                            <Grid className={classes.flexBoxClass}>
                              <TimePicker
                                autoOk
                                name={`yearNotification`}
                                onBlur={handleBlur}
                                onChange={(newTime) =>
                                  handleDateActionChange(
                                    newTime,
                                    'year',
                                    values
                                  )
                                }
                                value={
                                  (values &&
                                    values?.notificationsTiming?.year) ||
                                  ''
                                }
                                format="h:mma"
                                style={{ width: 80 }}
                                className={classes.formDateControlFromBordered}
                              />
                              <Box sx={{ margin: '0 5px' }}>
                                <Typography className={classes.timeLabel}>
                                  Year
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid className={classes.flexBoxClass}>
                              <TimePicker
                                autoOk
                                name={`classNotification`}
                                onBlur={handleBlur}
                                onChange={(newTime) =>
                                  handleDateActionChange(
                                    newTime,
                                    'class',
                                    values
                                  )
                                }
                                value={
                                  (values &&
                                    values.notificationsTiming?.class) ||
                                  ''
                                }
                                format="h:mma"
                                style={{ width: 80 }}
                                className={classes.formDateControlFromBordered}
                              />
                              <Typography className={classes.timeLabel}>
                                Class
                              </Typography>
                            </Grid>
                            <Grid className={classes.flexBoxClass}>
                              <TimePicker
                                autoOk
                                name={`coCurricularNotification`}
                                onBlur={handleBlur}
                                onChange={(newTime) =>
                                  handleDateActionChange(
                                    newTime,
                                    'coCurricular',
                                    values
                                  )
                                }
                                value={
                                  (values &&
                                    values.notificationsTiming?.coCurricular) ||
                                  ''
                                }
                                format="h:mma"
                                style={{ width: 80 }}
                                className={classes.formDateControlFromBordered}
                              />
                              <Typography className={classes.timeLabel}>
                                Co-Curricular
                              </Typography>
                            </Grid>
                            <Grid className={classes.flexBoxClass}>
                              <TimePicker
                                autoOk
                                name={`subjectNotification`}
                                onBlur={handleBlur}
                                onChange={(newTime) =>
                                  handleDateActionChange(
                                    newTime,
                                    'subject',
                                    values
                                  )
                                }
                                value={
                                  (values &&
                                    values.notificationsTiming?.subject) ||
                                  ''
                                }
                                format="h:mma"
                                style={{ width: 80 }}
                                className={classes.formDateControlFromBordered}
                              />
                              <Typography className={classes.timeLabel}>
                                Subject
                              </Typography>
                            </Grid>
                            <Grid className={classes.flexBoxClass}>
                              <TimePicker
                                autoOk
                                name={`staffNotification`}
                                onBlur={handleBlur}
                                onChange={(newTime) =>
                                  handleDateActionChange(
                                    newTime,
                                    'staff',
                                    values
                                  )
                                }
                                value={
                                  (values &&
                                    values.notificationsTiming?.staff) ||
                                  ''
                                }
                                format="h:mma"
                                style={{ width: 80 }}
                                className={classes.formDateControlFromBordered}
                              />
                              <Typography className={classes.timeLabel}>
                                Staff
                              </Typography>
                            </Grid>
                          </Box>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            buttonTextRef.current = 'Next';
                            handleSubmit();
                            window.scroll(0, 0);
                          }}
                          className={classes.disabledButtonText}
                        >
                          Next
                        </Button>
                        {accountFlag && isEdit && (
                          <Button
                            variant="contained"
                            color="primary"
                            className={
                              isSchoolDataUpdated
                                ? classes.buttonText
                                : classes.disabledButtonText
                            }
                            onClick={() => {
                              buttonTextRef.current = 'Save';
                              handleSubmit();
                              window.scroll(0, 0);
                            }}
                            style={{ marginLeft: '16px' }}
                            disabled={
                              isSchoolDataUpdated
                                ? false
                                : true
                            }
                          >
                            Save
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          ))}
 
        {isEdit && (
          <Button
            className={classes.deleteButton}
            onClick={() => setOpenDeleteModal && setOpenDeleteModal(true)}
          >
            <div className={classes.deleteText}>Delete School</div>
          </Button>
        )}
      </div>
    </div>
  );
}
 
export default withFirebase(Profile);
