import React, { forwardRef } from 'react';
import TextField from '@material-ui/core/TextField';

// const useStyles = makeStyles((theme) => ({
//   controlBox: {
//     width: '100%',
//     borderRadius: 4,
//     backgroundColor: '#ffffff',
//     '& .MuiOutlinedInput-root:hover, & .MuiOutlinedInput-root:focus': {
//       boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
//     }
//   },
//   inputControlBox: {
//     '& .MuiSelect-outlined:hover, & .MuiSelect-outlined:focus': {
//       boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
//     }
//   }
// }));

const phoneInput = (props, ref) => {
  return (
    <TextField
      {...props}
      // InputProps={{
      //   className: classes.input
      // }}
      inputRef={ref}
      label="Phone Number"
      variant="outlined"
      name="phone"
    />
  );
};
export default forwardRef(phoneInput);
