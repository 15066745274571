export const API_MANUAL_OPTIONS = [
  {
    label: 'API & Manual',
    value: 'all'
  },
  {
    label: 'API',
    value: 'API'
  },
  {
    label: 'Manual',
    value: 'Manual'
  }
];
