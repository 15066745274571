// Agent set Success
export const setAgent = (agent) => ({
  type: 'SET_AGENT',
  agent
});

// Reset Agent
export const resetAgent = () => ({
  type: 'RESET_AGENT'
});
