import axios from './axios';

// Constants
import { API_ROUTES } from 'src/constants/api-routes';
import { ROUTES } from 'src/constants/routes';
import { LOCAL_STORAGE_KEY } from 'src/constants/common';

// Utils
import { getRouteByQueryParams } from 'src/utils/route';

export const generateToken = async (userId) => {
  if (userId) {
    try {
      const response = await axios.post(API_ROUTES.GENERATE_TOKEN, { userId });
      return response?.data?.token;
    } catch (error) {
      console.error(error);
      throw error;
    }
  } else {
    Object.values(LOCAL_STORAGE_KEY).forEach((localStorageKey) => {
      localStorage.removeItem(localStorageKey);
    });
    window.location.href = ROUTES.LOGIN;
  }
};

export const sendVerificationCode = async (email) => {
  try {
    const encodedEmail = encodeURIComponent(email);
    const URL = getRouteByQueryParams(API_ROUTES.SEND_VERIFICATION_CODE, {
      dest: encodedEmail
    });
    const response = await axios.get(URL);
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const verifyCode = async ({ email, code }) => {
  try {
    const response = await axios.post(API_ROUTES.VERIFY_CODE, { email, code });
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateMaintenanceMode = async (mode) => {
  try {
    const response = await axios.post(API_ROUTES.UPDATE_MAINTENANCE_MODE, {
      mode
    });
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getMaintenanceMode = async () => {
  try {
    const response = await axios.get(API_ROUTES.GET_MAINTENANCE_MODE);
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
