/* eslint-disable camelcase */
import React, { useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// Material-UI Components
import { Paper, Button, Typography, Divider } from '@material-ui/core';

// Layouts
import Dashboard from '../layout/Dashboard';

// Assets
import { RightArrow } from 'src/assets/Icons';
import { membersAccountStyles } from 'src/styles/membersStyle/membersAccountStyles';
// eslint-disable-next-line camelcase
import api_icon from 'src/assets/apiIcon.svg';
import schoolBytes from 'src/assets/schoolByte2.webp';

// Redux-Actions
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { setFamily } from 'src/redux/actions/familyActions';
import UpdateMember from './UpdateMember';
import { MESSAGE_TYPES } from 'src/constants/common';

// Services
import { getMember, updateMember } from 'src/services/members';

// Utils
import { routes, colors } from 'src/utils/constant';
import { encryptSHA256 } from 'src/utils/functions';
import { withFirebase, FIREBASE_API_URL } from 'src/utils/firebase';

function MembersAccount({ firebase }) {
  const classes = membersAccountStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const currentFamily = useSelector((state) => state.family.currentFamily);

  const deleteAuthUserCaller = async (userId) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: userId
      })
    };
    fetch(`${FIREBASE_API_URL}/deleteUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result;
      })
      .then(
        () => {
          //
        },
        (error) => {
          const errorMessage = _.get(error, 'message', '');
          console.log(errorMessage);
          dispatch(addMessage(errorMessage, MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      );
  };

  const getComparedAndDifference = (data, compArray = []) => {
    return data.filter((el) => !compArray.includes(el));
  };

  const getFormData = async (formData) => {
    dispatch(loaderStart());

    const finalData = {
      ...currentFamily,
      ...formData
    };

    const getUpdatedGroups = [];

    getMember(familyID)
      .then(
        async (response) => {
          const familyData = response.data;
          if (familyData) {
            if (finalData.childs.length) {
              let oldMemberResponse = await getMember(familyID);
              oldMemberResponse = oldMemberResponse.data;
              finalData.childs.forEach((child, childIndex) => {
                let mergeNewClassAndGroups = _.compact([
                  child.group,
                  child.groups
                ]);
                mergeNewClassAndGroups = _.uniq(
                  // eslint-disable-next-line prefer-spread
                  [].concat.apply([], mergeNewClassAndGroups)
                );
                let mergeOldClassAndGroups = _.compact([
                  oldMemberResponse.childs[childIndex]?.group,
                  oldMemberResponse.childs[childIndex]?.groups
                ]);
                mergeOldClassAndGroups = _.uniq(
                  // eslint-disable-next-line prefer-spread
                  [].concat.apply([], mergeOldClassAndGroups)
                );

                // get old groups id which is updated from old selection
                const removeGroups = getComparedAndDifference(
                  mergeOldClassAndGroups,
                  mergeNewClassAndGroups
                );

                // get new groups id which is updated from old selection
                const newGroupReceived = getComparedAndDifference(
                  mergeNewClassAndGroups,
                  mergeOldClassAndGroups
                );

                const allGroupIds = _.compact([child.group, child.groups]);

                const pushRecord = {
                  familyId: child.id,
                  groupIds: [],
                  // eslint-disable-next-line prefer-spread
                  familyGroups: [].concat.apply([], allGroupIds) || []
                };
                if (newGroupReceived) {
                  newGroupReceived.forEach((el) => {
                    pushRecord.groupIds.push({
                      id: el,
                      isAdd: true
                    });
                  });
                }
                if (removeGroups) {
                  removeGroups.forEach((el) => {
                    pushRecord.groupIds.push({
                      id: el,
                      isAdd: false
                    });
                  });
                }
                getUpdatedGroups.push({
                  ...pushRecord
                });
              });
            }

            const updatedFinalData = {
              ...finalData
            };

            const findExtraUser = familyData.childs.filter((o1) => {
              // if match found return false
              return _.findIndex(currentFamily.childs, { id: o1.id }) !== -1
                ? false
                : true;
            });

            if (findExtraUser.length) {
              for (const user of findExtraUser) {
                if (user.userId) {
                  await deleteAuthUserCaller(user.userId);
                  await firebase.deleteUser(user.userId);
                }
              }
            }

            if (getUpdatedGroups.length) {
              getUpdatedGroups.forEach((grp) => {
                if (grp.groupIds.length) {
                  // Below function for update groups
                  firebase.updateGroupsByFamilyId(grp);
                  // Below function for update group related all entries
                  firebase.updateGroupEntriesByFamilyId(grp);
                }
              });
            }

            updateMember(updatedFinalData.id, {
              firstName: updatedFinalData?.firstName,
              lastName: updatedFinalData?.lastName,
              primaryEmail: updatedFinalData?.primaryEmail,
              phoneNo: updatedFinalData?.phoneNo,
              ...(familyData?.status === 'Active' && updatedFinalData?.userName
                ? { userName: updatedFinalData?.userName }
                : {}),
              ...(familyData?.status === 'Active' && updatedFinalData?.password
                ? { password: updatedFinalData?.password }
                : {}),
              country: updatedFinalData?.country, // [Australia, New Zealand, Hong Kong]
              timezone: updatedFinalData?.timezone // [8, 8.01, 8.75, 9.5, 10, 10.01, 10.5, 12, 12.75]
            })
              .then(() => {
                if (updatedFinalData.childs.length) {
                  updatedFinalData.childs.map((child) => {
                    let childObject = {
                      ...child,
                      role: 'Child',
                      userName: child.userName,
                      email: `${child.userName}@mywhanau-user.com`,
                      child: child.id,
                      family: updatedFinalData.id
                    };
                    if (child.password) {
                      childObject = {
                        ...childObject,
                        password: '',
                        isViewedOnboard: false,
                        encryptedPassword: encryptSHA256(childObject.password)
                      };
                    }
                    if (currentFamily.planType !== 'Free') {
                      firebase.addUser(childObject, child.userId);
                    }
                    return child;
                  });
                }
                dispatch(loaderStop());
                dispatch(setFamily(updatedFinalData));
                dispatch(addMessage('Family Updated!'));
              })
              .catch((error) => {
                dispatch(addMessage(error?.response?.data?.message, MESSAGE_TYPES.ERROR));
                dispatch(loaderStop());
              });
          }
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
      });
  };

  const familyID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  useEffect(() => {
    if (!currentFamily.lastName && familyID) {
      dispatch(loaderStart());

      getMember(familyID)
        .then((response) => {
          const familyData = response.data;
          if (familyData) {
            dispatch(setFamily({ ...familyData, planType: 'Free' }));
          }
          dispatch(loaderStop());
        })
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    }
  }, [currentFamily, familyID, firebase, dispatch]);

  const handlePauseAccount = useCallback(() => {
    const finalData = {
      ...currentFamily,
      planStatus: 'Pause'
    };

    let schoolData = [];
    if (finalData.childs.length && currentFamily.planType !== 'FreeVersion') {
      schoolData = finalData.childs.reduce((v, { school }) => {
        v.push(school);
        return v;
      }, []);
    } else if (currentFamily.planType === 'Free') {
      finalData.childs = [];
    }
    finalData.childsSchools = schoolData;

    const parents = finalData.parents;
    if (parents && parents.length > 0) {
      finalData.primaryEmail = parents[0].emailAddress;
    }

    dispatch(loaderStart());
    updateMember(finalData.id, {
      isPauseAccount: true
    })
      .then(
        () => {
          dispatch(loaderStop());
          dispatch(setFamily(finalData));
          dispatch(addMessage('Family account paused!'));
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  }, [currentFamily, dispatch]);

  const handleResumeAccount = useCallback(() => {
    const finalData = {
      ...currentFamily,
      planStatus: ''
    };

    const parents = finalData.parents;
    if (parents && parents.length > 0) {
      finalData.primaryEmail = parents[0].emailAddress;
    }

    dispatch(loaderStart());
    updateMember(finalData.id, {
      isPauseAccount: false
    })
      .then(
        () => {
          dispatch(loaderStop());
          dispatch(setFamily(finalData));
          dispatch(addMessage('Family account resumed!'));
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  }, [currentFamily, dispatch]);

  return (
    <React.Fragment>
      <Dashboard>
        <div className={classes.root}>
          <div>
            <Typography
              variant="body1"
              className={classes.bredthcrumbTitle}
              onClick={() =>
                window.location.pathname.match('/new')
                  ? history && history.push(routes.membersNew)
                  : window.location.pathname.match('/pending')
                    ? history && history.push(routes.membersPending)
                    : history && history.push(routes.membersActive)
              }
            >
              Members
            </Typography>
            <RightArrow
              viewBox="0 0 24 24"
              height={24}
              width={24}
              style={{ fontSize: 22, verticalAlign: 'middle' }}
            />
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ marginBottom: 20, marginTop: 22 }}
          >
            {`${currentFamily.firstName || ''} ${currentFamily?.lastName || ''}`}
          </Typography>
          <Paper className={classes.tabNavigations}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.memberCalendar + '/' + familyID)
                    : history.push(routes.familyCalendarPaid + '/' + familyID);
                }}
              >
                Calendar
              </Button>
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.membersUsers + '/' + familyID)
                    : history.push(routes.familyUsersPaid + '/' + familyID);
                }}
              >
                Children
              </Button>
              <span
                style={{
                  borderRight: `1px solid ${colors.primary}`,
                  margin: '0 8px',
                  height: 20
                }}
              ></span>
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.memberAccount + '/' + familyID)
                    : history.push(routes.familyAccountPaid + '/' + familyID);
                }}
                className={classes.activeTab}
              >
                Account
              </Button>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '1rem',
                  marginRight: 0,
                  marginLeft: 'auto'
                }}
              >
                {currentFamily.apiSource?.includes('School Bytes') && (
                  <img src={schoolBytes} height="38px" />
                )}
                {currentFamily.createdByApiSync && <img src={api_icon} />}
              </div>
            </div>
            <Divider variant="middle" />
            <UpdateMember
              submitForm={getFormData}
              handlePause={handlePauseAccount}
              handleResume={handleResumeAccount}
            />
          </Paper>
        </div>
      </Dashboard>
    </React.Fragment>
  );
}

export default withFirebase(MembersAccount);
