import axiosInstance from 'src/config/axios';

async function makeApiRequest({ url, data = null, method = 'GET' }) {
  try {
    const response = await axiosInstance({
      url,
      method,
      data
    });
    return { data: response.data, error: null };
  } catch (error) {
    console.log('Error getting document: ', error);
    return {
      data: null,
      error:
        error?.response?.data?.message ||
        'Server connection issue. Please refresh'
    };
  }
}

export default makeApiRequest;
