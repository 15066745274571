// Set Free School List
export const setFreeSchoolList = (freeSchoolList) => ({
  type: 'SET_FREE_SCHOOL_LIST',
  freeSchoolList
});

// Set All School List
export const setAllSchoolList = (allSchoolList) => ({
  type: 'SET_ALL_SCHOOL_LIST',
  allSchoolList
});
