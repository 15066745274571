export const SEARCH_ICON = require('./search-icon.svg');
export const FILE_ICON = require('./icons-file-text.svg');
export const NO_IMAGE = require('./no-image.svg');
export const API_ICON = require('./apiIcon.svg');

export const EDIT_ICON = require('./edit-grey.png');
export const ACTION_ICON = require('./action.png');
export const PROFILE_CIRCLE = require('./profile-circle.png');
export const LOGO = require('./logo.png');
export const Edit = require('./edit-icon.png');
export const LOADER = require('./loader.gif');
export const IMAGE = require('./image.png');
export const DrawerClose = require('./close.png');
export const ExternalLink = require('./external-link.png');
export const HandBook = require('./handBook.png');
export const EditPencil = require('./edit-pencil.png');
export const Calendar = require('./calendar.png');
export const RepeatIcon = require('./repeat.png');
export const Lock = require('./lock.png');
export const LocationIcon = require('./map-pin.png');
export const FILE_NOT_FOUND = require("./file-not-found@3x.png");
