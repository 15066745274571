import React, { useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Avatar, Divider, Grid, Box } from '@material-ui/core';
import { colors } from 'src/utils/constant';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ExternalLink } from 'src/assets';

const useStyles = makeStyles(() => ({
  card: {
    minHeight: 385,
    maxWidth: 385,
    boxShadow: `0 0 25px 0 rgba(76, 91, 104, 0.13)`
  },
  cardContent: {
    height: 216
  },
  cardActions: {
    height: 60,
    cursor: 'pointer',
    justifyContent: 'center'
  },
  cardHover: {
    boxShadow: `8px 16px 46px -1px rgba(76, 91, 104, 0.44)`
  },
  title: {
    fontSize: 23,
    color: colors.primaryDarkGrey,
    textAlign: 'center',
    marginTop: 64,
    fontWeight: 'bold',
    lineHeight: 1.3,
    letterSpacing: 1,
    marginBottom: 4
  },
  lightGreyText: {
    color: colors.primaryLightGrey,
    fontSize: 18,
    textAlign: 'center',
    lineHeight: 1.67,
    marginBottom: 16
  },
  postDividerDarkText: {
    height: 23,
    fontFamily: 'Roboto',
    fontSze: 18,
    lineHeight: 1.28,
    letterSpacing: 0.11,
    textAlign: 'center',
    color: colors.primaryDark,
    marginTop: 17,
    fontWeight: 500
  },
  buttonLabel: {
    color: colors.white,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 0.86,
    letterSpacing: -0.43,
    padding: '5px 12px',
    borderRadius: 2
  },
  avatar: {
    position: 'absolute',
    top: 40,
    left: 'calc(50% - 60px)',
    height: 120,
    width: 120,
    border: `solid 2px ${colors.white}`,
    fontSize: '32px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: colors.primaryDarkGrey,
    backgroundColor: colors.primaryLightGrey
  },
  buttonLabelHover: {
    backgroundColor: colors.primary,
    color: colors.white,
    justifyContent: 'center',
    transition: 'all 500ms ease-in-out'
  },
  buttonHover: {
    color: colors.white,
    borderRadius: 0,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.28,
    letterSpacing: 0.11
  },
  headerLinkIcon: {
    marginLeft: 9,
    paddingRight: 11,
    verticalAlign: 'middle'
  },
  divider: { marginBottom: 16 },
  dividerRoot: {
    width: 31,
    backgroundColor: colors.primaryLightGrey,
    borderRadius: 2
  },
  dividerMiddle: {
    margin: 'auto'
  },
  usersPrimaryDarkGrey: {
    color: colors.primaryDarkGrey,
    textAlign: 'center',
    marginTop: 84,
    fontWeight: 500,
    lineHeight: 0.86,
    letterSpacing: -0.43,
    marginBottom: '10%'
  },
  notesPostDividerText: {
    fontSize: 23,
    color: colors.primaryDarkGrey,
    textAlign: 'center',
    marginTop: '6%',
    fontWeight: 'bold',
    lineHeight: 1.3,
    letterSpacing: 1,
    marginBottom: 4
  },
  primaryTextClass: {
    position: 'absolute',
    zIndex: 1,
    textTransform: 'uppercase',
    fontWeight: 500,
    color: colors.white,
    paddingLeft: 15,
    fontSize: 14
  },
  portalContainer: {
    position: 'absolute',
    top: 15,
    right: 0,
    paddingRight: 15
  },
  portalText: {
    margin: '0 5px',
    fontSize: 14,
    color: colors.primaryLightGrey
  },
  boxContent: {
    width: 50,
    height: 12,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.86,
    letterSpacing: -0.43,
    textAlign: 'center',
    color: colors.primaryDarkGrey
  },
  childCard: {
    color: 'transparent',
    textShadow: '0 0 8px rgba(0,0,0,0.5)',
    pointerEvents: 'none',
    userSelect: 'none',
    '&&::selection': {
      color: 'transparent',
      background: 'transparent'
    }
  },
  lastnameText: {
    color: 'transparent',
    textShadow: '0 0 8px rgba(0,0,0,0.5)',
    fontSize: 18,
    textAlign: 'center',
    lineHeight: 1.67,
    marginBottom: 16,
    pointerEvents: 'none',
    userSelect: 'none',
    '&&::selection': {
      color: 'transparent',
      background: 'transparent'
    }
  },
  avatarInitial: {
    position: 'absolute',
    top: 40,
    left: 'calc(50% - 60px)',
    height: 120,
    width: 120,
    border: `solid 2px ${colors.white}`,
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: 'transparent',
    textShadow: '0 0 10px rgba(0,0,0,0.5)',
    backgroundColor: colors.primaryLightGrey
  }
}));

export default function CardComponent(props) {
  const classes = useStyles();
  const { cardData, format, onClick } = props;
  const [hovered, setHovered] = useState(false);
  const profileImage = _.get(cardData, 'profileImage.url', '');
  const schools = useSelector((state) => state.auth.schools);
  const family = useSelector((state) => state.family.currentFamily.planType);
  const childVisiility = useSelector((state) => state?.auth?.user?.country);

  const getSchoolName = (id) => {
    const schoolData = schools.find((school) => school.id === id);
    return schoolData ? schoolData.name.toUpperCase() : '';
  };
  const capitalizeString = (str) => {
    return str.replace(/\w\S*/g, function (stringData) {
      return (
        stringData.charAt(0).toUpperCase() + stringData.slice(1).toLowerCase()
      );
    });
  };

  return (
    <Card
      className={clsx(classes.card, hovered && classes.cardHover)}
      variant="elevation"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {cardData.isParent && cardData.index === 0 && !format.displayParent && (
        <p className={classes.primaryTextClass}>Primary</p>
      )}
      <CardHeader
        style={{
          backgroundColor: cardData.isParent
            ? colors.primaryLightGrey
            : colors.secondaryGreyLight,
          position: 'relative',
          height: 109
        }}
        avatar={
          <Avatar
            aria-label="recipe"
            src={profileImage}
            alt={cardData.initials}
            className={
              childVisiility !== 'Australia' &&
              family === 'Free' &&
              cardData?.isParent
                ? classes.avatar
                : childVisiility !== 'Australia' && family === 'Free'
                  ? classes.avatarInitial
                  : classes.avatar
            }
          >
            {childVisiility !== 'Australia' &&
            family === 'Free' &&
            !cardData.isParent
              ? 'Redacted'
              : cardData.initials}
          </Avatar>
        }
        subheader={
          cardData.schoolPortal && format.displayParent ? (
            <div
              className={classes.portalContainer}
              onClick={() => window.open(cardData.schoolPortal, '_blank')}
            >
              {
                <div style={{ display: 'flex', cursor: 'pointer' }}>
                  <p className={classes.portalText}>Portal</p>
                  <img
                    src={ExternalLink}
                    alt=""
                    style={{ height: 18, width: 18, opacity: 0.4 }}
                  />
                </div>
              }
            </div>
          ) : format.headerLink === 'right' &&
            format.displayParent &&
            cardData.schoolPortal ? (
            <div style={{ position: 'absolute', top: 13, right: 0 }}>
              <a
                style={{
                  float: 'right',
                  color: colors.primaryLightGrey,
                  textDecoration: 'none',
                  fontSize: 14,
                  textAlign: 'center'
                }}
                target={'_blank'} // eslint-disable-line
                to={cardData.schoolPortal}
                href={cardData.schoolPortal}
                rel="noreferrer"
              >
                {format.headerLinkText}
                <span className={classes.headerLinkIcon}>
                  {format.headerLinkIcon || ''}
                </span>
              </a>
            </div>
          ) : (
            <div style={{ position: 'absolute', top: 13, left: 19 }}>
              {cardData.link ? (
                <Link
                  style={{
                    float: 'right',
                    color: format.linkColor ? format.linkColor : 'grey',
                    textDecoration: 'none',
                    fontWeight: 'bold'
                  }}
                  to="/"
                >
                  {format.headerLinkText}
                  {format.headerLinkIcon ? format.headerLinkIcon : ''}
                </Link>
              ) : null}
            </div>
          )
        }
      />
      <Grid>
        <CardContent classes={{ root: classes.cardContent }}>
          <Typography
            className={
              format.bordered ? classes.usersPrimaryDarkGrey : classes.title
            }
            border={1}
          >
            {format.bordered ? (
              <Box
                border={1}
                px={0.5}
                py={0.5}
                borderColor={colors.primaryDarkGrey}
                borderRadius={2}
                component="span"
              >
                <span className={classes.boxContent}>
                  {cardData.isParent ? 'PARENT' : 'CHILD'}
                </span>
              </Box>
            ) : (
              <span
                className={
                  childVisiility !== 'Australia' &&
                  family === 'Free' &&
                  cardData?.isParent
                    ? classes.parentCard
                    : childVisiility !== 'Australia' &&
                      family === 'Free' &&
                      classes.childCard
                }
              >
                {childVisiility !== 'Australia' &&
                family === 'Free' &&
                !cardData.isParent
                  ? 'Redacted'
                  : cardData.firstName.toUpperCase()}
              </span>
            )}
          </Typography>
          {cardData.lastName && !format.bordered && (
            <Typography
              className={
                childVisiility !== 'Australia' &&
                family === 'Free' &&
                cardData?.isParent
                  ? classes.lightGreyText
                  : childVisiility !== 'Australia' && family === 'Free'
                    ? classes.lastnameText
                    : classes.lightGreyText
              }
            >
              {childVisiility !== 'Australia' &&
              family === 'Free' &&
              !cardData.isParent
                ? 'Redacted'
                : cardData.lastName}
            </Typography>
          )}
          <Divider
            variant="middle"
            className={classes.divider}
            classes={{
              root: classes.dividerRoot,
              middle: classes.dividerMiddle
            }}
          />
          {cardData.firstName && format.bordered && (
            <Typography className={classes.title} style={{ marginTop: '4%' }}>
              {cardData.firstName.toUpperCase()}
            </Typography>
          )}
          {cardData.lastName && format.bordered && (
            <Typography className={classes.lightGreyText}>
              {cardData.lastName}
            </Typography>
          )}
          {cardData.school && !format.bordered && format.displayParent && (
            <Typography className={classes.postDividerDarkText}>
              {capitalizeString(getSchoolName(cardData.school))}
            </Typography>
          )}
          {!cardData.school && !format.bordered && format.displayParent && (
            <Typography className={classes.postDividerDarkText}>
              Parent
            </Typography>
          )}
          {cardData.lightGreyTextSecondary && (
            <Typography className={classes.lightGreyText}>
              {cardData.lightGreyTextSecondary}
            </Typography>
          )}
        </CardContent>
        <CardActions
          className={hovered ? classes.buttonLabelHover : ''}
          classes={{ root: classes.cardActions }}
          onClick={() => (hovered ? onClick({ ...cardData }) : null)}
        >
          <Typography
            className={
              hovered
                ? classes.buttonHover
                : !format.bordered && !cardData.isParent
                  ? classes.buttonLabel
                  : ''
            }
            style={{
              color: 'white',
              backgroundColor:
                !format.bordered && !cardData.isParent && !hovered
                  ? colors.primaryDarkGrey
                  : 'transparent'
            }}
            onClick={() => onClick({ ...cardData })}
          >
            {hovered
              ? format.buttonTextHover
              : !format.bordered && !cardData.isParent
                ? cardData?.year === '-1'
                  ? 'Pre K'
                  : cardData?.year === '0'
                    ? 'Kindy'
                    : 'Year ' + cardData?.year
                : ''}
          </Typography>
        </CardActions>
      </Grid>
    </Card>
  );
}
