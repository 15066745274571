import React from 'react';
import { styled } from '@mui/system';
import SwitchUnstyled, {
  switchUnstyledClasses
} from '@mui/core/SwitchUnstyled';

const Root = styled('span')`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 80px;
  height: 40px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #bfc5d1;
    border-radius: 25px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 30px;
    height: 30px;
    top: 4.6px;
    left: 5px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 44px;
      top: 4.6px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: #8bc517;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

export default function UnstyledSwitches({ handleChecked, checked }) {
  const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
  return (
    <div>
      <SwitchUnstyled
        component={Root}
        {...label}
        onClick={handleChecked}
        checked={checked}
      />
    </div>
  );
}
