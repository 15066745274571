import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import _ from 'lodash';
import { yearList } from 'src/utils/constant';
import { withFirebase } from 'src/utils/firebase';
import { addMessage } from 'src/redux/actions/appActions';
import { getSchoolList } from 'src/redux/actions/authActions';
import { getSchools } from 'src/services/schools';
import { calendarStyles } from 'src/styles/schoolStyle/calendarStyles';
import { deepEqual } from 'src/utils/deepEqual';
import { MESSAGE_TYPES } from 'src/constants/common';
import { useRef } from 'react';

function Calendar(props) {
  const classes = calendarStyles();
  const dispatch = useDispatch();
  const buttonTextRef = useRef(null);
  const prefillSchoolData = useSelector((state) => state.school.currentSchool);
  const isSchoolDataUpdated = useSelector((state) => state.school.isSchoolDataUpdated);
  const [initialValues, setInitialValues] = useState({
    freeCalendars: []
  });
  const formKey = Math.random();
  const { isEdit = false, checkSchoolDataUpdated, isCancelButtonClicked, handleCancelButtonVisibility } = props;
  // SCHEMA VALIDATOR FOR
  const validationSchema = Yup.object().shape({
    freeCalendars: Yup.array().of(Yup.string().trim().required())
  });

  const formik = useFormik({
    enableReinitialize : true,
    key : formKey,
    initialValues : initialValues,
    validationSchema : validationSchema,
    onSubmit : async (values) => {
      if (props.accountFlag && buttonTextRef.current === 'Save') {
        await props.submitForm(values, buttonTextRef.current);
        getAllSchools();
      } else {
        await props.submitForm(values, buttonTextRef.current);
        getAllSchools();
      }
    }
  })
  
  const {handleSubmit, values, setFieldValue, initialValues: formikInitialValues} = formik


  // SET PREFILL DATA IF IS-EDIT TRUE
  useEffect(() => {
    if (prefillSchoolData?.freeCalendars?.length > 0 && initialValues?.freeCalendars?.length === 0) {
      setInitialValues({
        freeCalendars:
        _.uniq([...prefillSchoolData.freeCalendars]).sort((a, b) => a - b) || []
      });
    }
  }, [prefillSchoolData, initialValues]);

  useEffect(() => {
    const freeCalendarsData = _.uniq([...prefillSchoolData.freeCalendars]).sort((a, b) => a - b) || [];
    setInitialValues({
      freeCalendars:freeCalendarsData      
    });
    setFieldValue('freeCalendars', freeCalendarsData);
    handleCancelButtonVisibility()
  }, [prefillSchoolData, isCancelButtonClicked])

  useEffect(() => {
    if (deepEqual(formikInitialValues, initialValues)) {
      const formikChangesValues = {freeCalendars: values.freeCalendars.sort((a, b) => a - b)}
      checkSchoolDataUpdated(formikChangesValues)
    }
  }, [formikInitialValues, initialValues, values])


  const isGlobalCheckBoxChecked = ({groupYears, formikValues}) => {
    const allGroupYears = groupYears.map((year) => year.value);
    return allGroupYears.every((yearValue) => formikValues?.freeCalendars.includes(yearValue));
  };

  const isGlobalCheckBoxIndeterminateChecked = ({groupYears, formikValues}) => {
    const allGroupYears = groupYears.map((year) => year.value);
    if (isGlobalCheckBoxChecked({groupYears, formikValues})) {
      return false;
    } else {
      return allGroupYears.some((yearValue) => formikValues?.freeCalendars.includes(yearValue))
    }
  };

  const handleGlobalGroupCheckBox = ({event, groupYears, setFieldValue, formikValues}) => {
    if (event.target.checked) {
      const allGroupYears = groupYears.map((year) => year.value);
      setFieldValue('freeCalendars', [...new Set([...formikValues.freeCalendars, ...allGroupYears])]);
    } else {
      const allGroupYears = groupYears.map((year) => year.value);
      const removeGroupYears = formikValues.freeCalendars.filter(
        (yearValue) => !allGroupYears.includes(yearValue) || prefillSchoolData?.autoAssignedYears.includes(yearValue)
      )
      setFieldValue('freeCalendars', removeGroupYears);
    }
  };

  const handleCheckBoxChange = ({ isChecked, yearValue, setFieldValue, formikValues}) => {
    if (isChecked) {
      setFieldValue('freeCalendars', [...formikValues.freeCalendars, yearValue]);
    } else {
      setFieldValue('freeCalendars', formikValues.freeCalendars.filter((yearVal) => yearVal !== yearValue))
    }
  };

  const getAllSchools = async () => {
    // PHASE2/CHANGES/SCHOOL

    try {
      const result = await getSchools();
      const schools = [];
      result.forEach((school) => {
        const yearData = school.freeCalendars.map((calendar) => ({
          label: `Year ${calendar}`,
          value: calendar
        }));

        schools.push({
          id: school.id,
          name: school.schoolName,
          years: yearData.length ? yearData : []
        });
      });
      dispatch(getSchoolList(schools));
    } catch (error) {
      console.error(error);
      dispatch(
        addMessage(
          'Server connection issue. Please refresh',
          MESSAGE_TYPES.ERROR
        )
      );
    }
  };

  return (
    <div>
      <div className={classes.title}>Years</div>
      <div className={classes.subtitle}>
        Add the calendar years to be made available for this school.
      </div>
      <Grid container spacing={0}>
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{
            width: '100%',
            fontFamily: 'Roboto-Regular',
            letterSpacing: '.3px',
            fontSize: '1rem'
          }}
        >
          <Grid
            container
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '3rem',
              marginBottom: '2rem'
            }}
          >
            {yearList.map((group, groupIndex) => (
              <Grid item lg={2} md={4} xs={6} key={groupIndex}>
                {Object.entries(group).map(([groupKey, groupYears]) => {
                  return (
                    <div
                    key={groupKey}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <FormControlLabel
                      key={groupIndex}
                      control={
                        <Checkbox
                          name={groupKey}
                          color="primary"
                          className={classes.checkboxColor}
                          onChange={(event) => {
                            handleGlobalGroupCheckBox({
                              event,
                              groupYears,
                              setFieldValue,
                              formikValues: values
                            })
                          }}
                          indeterminate={isGlobalCheckBoxIndeterminateChecked({groupYears, formikValues: values})}
                          checked={isGlobalCheckBoxChecked({groupYears, formikValues: values})}
                        />
                      }
                      className={classes.formControlLabelColorCustom}
                      label="Select all"
                    />
                    {groupYears.map((year) => (
                      <FormControlLabel
                        key={year.value}
                        control={
                          <Checkbox
                            color="primary"
                            disabled={
                              prefillSchoolData?.createdByApiSync &&
                              prefillSchoolData?.autoAssignedYears?.includes(
                                year.value
                              )
                            }
                            checked={values?.freeCalendars.includes(year.value)}
                            className={classes.checkboxColor}
                            onChange={(event) => {
                              handleCheckBoxChange({
                                isChecked: event.target.checked,
                                yearValue: year.value,
                                setFieldValue,
                                formikValues: values
                              })
                            }}
                            name={year.value}
                          />
                        }
                        label={year.label}
                      />
                    ))}
                  </div>
                  )
                })}
              </Grid>
            ))}
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              buttonTextRef.current = 'Back'
              handleSubmit();
              window.scroll(0, 0);
            }}
            className={classes.disabledButtonText}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              buttonTextRef.current = 'Next'
              handleSubmit();
              window.scroll(0, 0);
            }}
            style={{ marginLeft: '16px' }}
            className={classes.disabledButtonText}
          >
            Next
          </Button>
          {isEdit && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                buttonTextRef.current = 'Save'  
                handleSubmit();
                window.scroll(0, 0);
              }}
              style={{ marginLeft: '16px', width: 106 }}
              className={
                isSchoolDataUpdated
                  ? classes.buttonText
                  : classes.disabledButtonText
              }
              disabled={
                isSchoolDataUpdated
                  ? false
                  : true
              }
            >
              Save
            </Button>
          )}
          {/* {isEdit ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSave(true);
                handleSubmit();
              }}
              style={{ marginLeft: '2%', width: 106 }}
              className={
                isFormValuesUpdated
                  ? classes.buttonText
                  : classes.disabledButtonText
              }
              disabled={!isFormValuesUpdated}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSave(false);
                handleSubmit();
              }}
              style={{ marginLeft: '2%' }}
              className={classes.buttonText}
            >
              Create School Profile
            </Button>
          )} */}
        </form>
      </Grid>
    </div>
  );
}

export default withFirebase(Calendar);
