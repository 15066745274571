import { createMuiTheme } from '@material-ui/core';

import { colors } from 'src/utils/constant';

export const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersStaticWrapper: { staticWrapperRoot: { alignItems: 'center' } },
    MuiPickersCalendar: {
      transitionContainer: { margin: '20px 0 20px 0', minHeight: 210 },
      week: {
        justifyContent: 'space-between',
        padding: '0 16px',
        daySelected: {
          color: `${colors.primaryDark} !important`,
          backgroundColor: `${colors.primaryLightGrey} !important`
        }
      }
    },
    MuiPickersBasePicker: {
      container: {
        width: '100%'
      },
      pickerView: {
        // maxWidth: 'unset',
        maxWidth: '100%',
        justifyContent: 'space-between'
      }
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        justifyContent: 'space-between',
        padding: '0 16px'
      },
      switchHeader: {
        position: 'relative'
      },
      dayLabel: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 3.5,
        letterSpacing: 0.1,
        alignItems: 'center',
        color: `${colors.primaryDark} !important`
      },
      iconButton: {
        '&:first-of-type': {
          position: 'absolute',
          right: 50
        },
        '&:second-of-type': {
          position: 'absolute',
          right: 0
        }
      },
      transitionContainer: {
        height: 40,
        '& p': {
          left: 15,
          right: 'unset',
          fontSize: 23,
          fontWeight: 500,
          lineHeight: 1.74,
          color: colors.primaryDark
        }
      }
    },
    MuiPickersDay: {
      day: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 3.5,
        letterSpacing: 0.1,
        alignItems: 'center',
        color: colors.primaryDark
      },
      daySelected: {
        color: `${colors.primaryDark} !important`,
        backgroundColor: `${colors.primaryLightGrey} !important`
      }
    }
  }
});
