import { makeStyles } from '@material-ui/core';
import { colors } from 'src/utils/constant';

export const calendarStyles = makeStyles(() => ({
  checkboxColor: {
    color: '#bfc5d1'
  },
  formControlLabelColorCustom: {
    color: '#bfc5d1'
  },
  disabledButtonText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    border: `solid 1px ${colors.primaryLightGrey}`,
    backgroundColor: colors.secondaryGreyLightSync,
    color: colors.primaryDarkGrey,
    boxShadow: 'none !important',
    '&:hover': {
      backgroundColor: colors.secondaryGreyLightSync
    },
    '&:disabled': {
      backgroundColor: colors.secondaryGreyLightSync,
      color: colors.primaryDarkGrey
    },
    height: 50,
    width: 106
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey,
    marginBottom: 24
  },
  calendarItem: {
    marginBottom: 24
  },
  controlBox: {
    height: 60,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  adButton: {
    width: 134,
    height: 30,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    marginTop: 12,
    marginBottom: 35
  },
  adButtonText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: colors.primary
  },
  buttonText: {
    height: 50,
    padding: '12px 30px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: '0.1px',
    textAlign: 'center'
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    // width: 523,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  modalContent: {
    minHeight: 75,
    paddingTop: 12
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark
  },
  modalBox: {
    width: 450
    // height: 170,
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  modalActions: {
    marginTop: '2%'
  },
  inputModalControlBox: {
    backgroundColor: '#ffffff',
    marginTop: 24
  },
  buttonTextSave: {
    height: 24,
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center'
  }
}));
