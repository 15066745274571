import CryptoJS from 'crypto-js';
import moment from 'moment';
import _ from 'lodash';
import { FIREBASE_API_URL } from './firebase';
import {
  encryptedPasswordSecret,
  emailTypes,
  schoolAllYears,
  freeFamilyChildStatus
} from './constant';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { ContentState } from 'draft-js';
import { EditorState } from 'draft-js';
/**
 * Get query string params from url
 *
 * @param {string} name name of the query param.
 * @param {string} url Url from which we have to extract param.
 * @return {string} value of param
 */

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export const generateUserEmail = (username) => {
  return `${username}@mywhanau-user.com`;
};

export const getParameterByName = (name, url) => {
  if (!url) return '';
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const isCodeVerified = () => {
  return localStorage.getItem('isCodeVerified') || null;
};

export const isCodeVerifiedSession = () => {
  return window.sessionStorage.getItem('isCodeVerified') || null;
};

export const encryptSHA256 = (text) => {
  return CryptoJS.enc.Base64.stringify(
    // eslint-disable-next-line new-cap
    CryptoJS.HmacSHA256(text, encryptedPasswordSecret)
  );
};

export const encrypt = (text) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

export const decrypt = (data) => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};

export const generateFileHash = (length = 9) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  if (length === 20) {
    // Generate unique Id for document using combination of date and result value
    result = new Date().valueOf() + result;
    result = result.substring(0, length);
  }
  return result;
};

export const setSchoolFileName = (file, schoolId, type) =>
  `${schoolId}-${type}.${file.name.split('.').pop()}`;

export const generateMonthOptionsForFilter = () => {
  const now = moment();
  const currentMonth = moment(now)
    .subtract(1, 'years')
    .subtract(1, 'months')
    .format('MM YYYY');
  const calenderView = [];
  let prevMonth = moment(now);
  while (prevMonth.format('MM YYYY') !== currentMonth) {
    calenderView.push({
      label: prevMonth.format('MMMM YYYY'),
      value: prevMonth.format('YYYY-MM-DD')
    });
    prevMonth = prevMonth.subtract(1, 'months');
  }
  return calenderView;
};

// start generate password 8 characters, one uppercase letter, one lowercase letter and one number.
// Ref : https://stackoverflow.com/questions/20856822/javascript-random-password-with-specific-format

const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const lower = 'abcdefghijklmnopqrstuvwxyz';
const digit = '0123456789';
// const spacialChar = '!@#$%^&*(()_'
const all = upper + lower + digit;

function rand(max) {
  return Math.floor(Math.random() * max);
}

function random(set) {
  return set[rand(set.length - 1)];
}

function generate(length, set) {
  const result = [];
  while (length--) result.push(random(set));
  return result;
}

function shuffle(arr) {
  let result = [];

  while (arr.length) {
    result = result.concat(arr.splice(rand[arr.length - 1]));
  }

  return result;
}

export const generatePatternPassword = (length) => {
  let result = []; // we need to ensure we have some characters

  result = result.concat(generate(1, upper)); // 1 upper case
  result = result.concat(generate(1, lower)); // 1 lower case
  result = result.concat(generate(1, digit)); // 1 digit
  // result = result.concat(generate(1, spacialChar)) // 1 spacial char
  result = result.concat(generate(length - 3, all)); // remaining - whatever

  return shuffle(result).join(''); // shuffle and make a string
};

// end generate password 8 characters, one uppercase letter, one lowercase letter and one number.

export const formatFreeFamily = (familyData, status = null) => {
  const { NEW, ACTIVE, OPEN_REMIND } = freeFamilyChildStatus;
  const childs = formatChilds(familyData.childs, status);
  const familySurname =
    familyData.familySurname || setSurname(familyData.childs);
  const createdDate = familyData.createdDate || moment.utc().format();
  const accountStatus = childs.map((child) => child.status);
  const userId = familyData.userId || '';
  const familyStatus = userId
    ? ACTIVE
    : accountStatus.includes(NEW)
      ? NEW
      : accountStatus[0] || familyData.status || OPEN_REMIND;
  const schools = familyData.schools || [];
  const existingChilds = childs.map((child, index) => ({
    ...child,
    color: schools[index] && schools[index].color ? schools[index].color : ''
  }));

  const formatted = {
    ...familyData,
    childs,
    childsSchools: [],
    country: familyData.country,
    createdDate,
    createdDateTimestamp: new Date(createdDate),
    encryptedPassword: familyData.encryptedPassword || '',
    familySurname,
    aliasFamilySurname: familySurname.toLowerCase(),
    freeHost: 'true',
    id: familyData.id || '',
    myWhanauEmail: '',
    parents: formatParents(familyData),
    password: '',
    planStatus: familyData.planStatus || '',
    planType: familyData.planType || 'Free',
    primaryEmail: familyData.primaryEmail,
    schoolIds:
      familyData.schoolIds || childs.map((child) => child.school) || [],
    schools: existingChilds
      .map((child) => ({
        color: child.color || '',
        schoolName: child.school || '',
        year: child.year || '',
        years: child.years || []
      }))
      .sort((a, b) => a.year - b.year),
    timezone: familyData.timezone,
    userId,
    userName: familyData.userName || '',
    termsChecked: familyData.termsChecked || '',
    accountStatus,
    status: familyStatus,
    statusUpdatedAt:
      childs?.[0]?.statusUpdatedAt ||
      familyData.statusUpdatedAt ||
      moment.utc().format(),
    childYears: _.uniq(_.compact(childs.map((child) => child.year)))
  };
  return formatted;
};

const formatParents = (familyData) => {
  return [...familyData.parents];
};

const formatChilds = (childs, status = null) => {
  return childs.map((child) => {
    const formated = {
      ...child,
      createdDate: child.createdDate
        ? child.createdDate
        : moment.utc().format(),
      status: childs[0].status ? childs[0].status : status,
      statusUpdatedAt:
        (status === null || child.status === status) && child.statusUpdatedAt
          ? child.statusUpdatedAt
          : moment.utc().format(),
      id: child.id || generateFileHash(20),
      firstName: child.firstName,
      group: child.group || '',
      groups: child.groups && child.groups.length ? child.groups : [],
      initials:
        child.initials ||
        `${child.firstName[0] || ''}${child.lastName[0] || ''}`,
      lastName: child.lastName,
      password: child.password || '',
      school: child.school,
      schoolPortal: child.schoolPortal || '',
      schoolPortalLogin: child.schoolPortalLogin || '',
      schoolPortalPassword: child.schoolPortalPassword || '',
      userName: child.userName || '',
      year: child.year,
      years: child.years
    };
    return formated;
  });
};

const setSurname = (childs) => {
  let surname = childs?.[0]?.lastName || '';
  if (childs.length > 1 && childs[0].lastName !== childs[1].lastName)
    surname = `${surname}-${childs[1].lastName}`;
  return surname;
};

const getEmailParamas = ({
  type,
  customData,
  sendTo,
  needEventTracking = false
}) => {
  const params = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ type, customData, sendTo, needEventTracking })
  };
  return params;
};

export const emailToApprovedFamily = async (familyData, schoolName = '') => {
  const { primaryEmail } = familyData;
  const { firstName } = familyData.parents[0];
  const params = getEmailParamas({
    type: emailTypes.MEMBER_APPROVED.type,
    customData: {
      ...emailTypes.MEMBER_APPROVED.customData,
      firstName,
      schoolName,
      userEmail: primaryEmail
    },
    sendTo: primaryEmail,
    needEventTracking: true
  });
  try {
    const res = await fetch(`${FIREBASE_API_URL}/sendEmail`, params);
    return res;
  } catch (err) {
    console.log('ERROR: ', err);
    return err;
  }
};

export const getGroupYears = ({ groups }) => {
  // Get years from all groups and remove duplicate years
  const yearsLabel = _.uniq(
    _.flatten(groups.map((group) => group.data().years))
  );
  // Format years from text to number
  const formatedYears = _.compact(
    yearsLabel.map((year) => schoolAllYears[year])
  );
  const [allLabelYear, otherYear] = _.partition(
    formatedYears,
    (year) => year === 'all'
  );
  // Sort numeric years, merge all label and numeric years array
  const forYears = _.flatten([allLabelYear, otherYear.sort((a, b) => a - b)]);
  return forYears;
};

export const getGroupMembers = ({ groups }) => {
  const allGroupMembers = _.uniq(
    _.flatten(groups.map((group) => group.data().groupMembers))
  );
  const groupMembers = _.compact(allGroupMembers);
  return groupMembers;
};

export const updateYearAndMemForRepeatEntries = ({
  repeatEntries,
  entryData,
  allGroupData
}) => {
  repeatEntries.map((repeatEntry) => {
    if (repeatEntry.groups) {
      let repeatEntriesUpdatedData = {
        forYears: entryData.forYears,
        groupMembers: entryData.groupMembers
      };
      if (!_.isEqual(entryData.groups, repeatEntry.groups)) {
        const repeatEntryGroupsData = allGroupData.filter((group) =>
          repeatEntry.groups.includes(group.data().id)
        );
        const updatedYears = getGroupYears({ groups: repeatEntryGroupsData });
        const updatedMembers = getGroupMembers({
          groups: repeatEntryGroupsData
        });
        repeatEntriesUpdatedData = {
          forYears: updatedYears,
          groupMembers: updatedMembers
        };
      }
      repeatEntry.forYears = repeatEntriesUpdatedData.forYears;
      repeatEntry.groupMembers = repeatEntriesUpdatedData.groupMembers;
    }
  });
  return repeatEntries;
};

export const isValidFileType = (fileType, onlyImageType = false) => {
  const allowedExtensions = [
    'jpg',
    'jpeg',
    'png',
    ...(!onlyImageType
      ? ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx']
      : [])
  ];
  return allowedExtensions.includes(fileType);
};

export const formatFilename = (file) => {
  // eslint-disable-next-line no-useless-escape
  const fullFileName = file.split(/\.(?=[^\.]+$)/)[0];
  const fileType = file.split('.').pop().toLowerCase();
  const parts = fullFileName.split('_');
  const dateIndex = parts.findIndex(
    (x) => typeof Number(x) === 'number' && !isNaN(Number(x))
  );
  const filename = parts.filter(
    (x) => x !== parts[dateIndex] && x !== 'mywhanau'
  );
  const name = filename[0] + '_mywhanau_' + parts[dateIndex] + '.' + fileType;
  return name;
};
export const getInitials = (name) => {
  const words = name.split(' ');
  const initials = words.map((word) => word.charAt(0)).join('');
  return initials.toUpperCase();
};

// To get HTML content
export const toGetHTMLContent = (description) => {
  return typeof description === 'string'
    ? description
    : draftToHtml(convertToRaw(description.getCurrentContent()));
};

export const toGetContentFromHTML = (description) => {
  if (typeof description === 'string') {
    const contentBlock = htmlToDraft(description || '');
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    }
  } else {
    return description;
  }
};

// To sort/fix linked entry order issue
export const sortLinkedEntries = (data) => {
  const primaryEntry = data.find((entry) => entry.isPrimary);
  const linkedIdsOrder = primaryEntry?.linkedArray || [];
  const linkedEntries = linkedIdsOrder.map((linkedId) =>
    data.find((entry) => entry.id === linkedId)
  );
  const sortedData = _.compact([primaryEntry, ...linkedEntries]);
  return sortedData;
};
