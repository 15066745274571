import React, { useEffect, useCallback, useMemo, useState } from 'react';
import app from 'firebase/app';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CreateSingleFamilyEntry from '../views/families/CreateSingleFamilyEntry';
import CreateGroupCalenderEntry from '../views/families/CreateGroupCalenderEntry';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {
  AppBar,
  Toolbar,
  Popover,
  List,
  ListItem,
  Fab,
  ListItemText,
  makeStyles,
  Button,
  Divider,
  Typography,
  Avatar,
  TextField,
  FormControl,
  IconButton,
  Badge,
  CircularProgress,
  InputAdornment,
  Backdrop
} from '@material-ui/core';
import { colors } from 'src/utils/constant';
import { withFirebase, FIREBASE_API_URL } from 'src/utils/firebase';
import { encryptSHA256, isValidFileType } from 'src/utils/functions';
import {
  loaderStart,
  loaderStop,
  addMessage,
  setPreserveEntryData
} from 'src/redux/actions/appActions';
import { Formik } from 'formik';
import { resetAgent } from 'src/redux/actions/agentActions';
import {
  loginSuccess,
  logoutSuccess,
  getSchoolList
} from 'src/redux/actions/authActions';
import RightDrawer from 'src/components/RightDrawer';
import Modal from 'src/components/Modal';
import * as Yup from 'yup';
import _ from 'lodash';
import { setAgent } from 'src/redux/actions/agentActions';
import ScanningUploadFileModal from './ScanningUploadFileModal';
import { getSchools } from 'src/services/schools';
import { MESSAGE_TYPES } from 'src/constants/common';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: '#F4F6F8'
  },
  shadowNone: {
    boxShadow: 'none'
  },
  maintenanceBanner: {
    background: '#bd0000',
    textAlign: 'center',
    '& p': {
      fontFamily: 'Roboto',
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: 1.2,
      color: '#fff',
      margin: '8px auto'
    }
  },
  rightHeder: {
    flexGrow: 1,
    marginRight: -28
  },
  userlabel: {
    marginRight: 10,
    display: 'inline-block',
    fontWeight: 500
  },
  iconWrapper: {
    textAlign: 'right',
    width: '100%'
  },
  popoverClassList: {
    marginTop: '0'
  },
  listItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: colors.primary,
      color: colors.white
    }
  },
  siderSave: {
    width: 95,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary
  },
  headingContainer: {
    margin: '10px 30px',
    marginBottom: '3.7%',
    marginTop: '4.7%',
    position: 'relative'
  },
  mainHeading: {
    width: 93,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  saveButtonContainer: {
    float: 'right',
    position: 'absolute',
    top: 0,
    right: 0
  },
  subHeading: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    width: 344
  },
  dividerClass: {
    marginBottom: 22,
    marginTop: 22
  },
  inputControlBox: {
    width: 427,
    backgroundColor: '#ffffff',
    marginTop: 24
  },
  popoverClass: {
    '& .MuiPaper-rounded': {
      right: '70px',
      left: 'unset  !important',
      top: '70px !important'
    }
  },

  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    width: 523,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  modalContent: {
    minHeight: 75,
    paddingTop: 12
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark
  },
  modalBox: {
    width: 450
    // height: 170,
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  modalActions: {
    marginTop: '2%'
  },
  profileImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24
  },
  removeImageContainer: {
    marginLeft: 30,
    // marginTop: 20,
    color: colors.primaryDarkGrey,
    cursor: 'pointer'
  },
  profileAvatar: {
    fontWeight: 500,
    fontSize: 14,
    color: colors.primaryDarkGrey,
    backgroundColor: colors.primaryLightGrey
  },
  imageContainer: {
    width: 100,
    height: 100,
    backgroundColor: colors.primaryLightGrey,
    borderRadius: '50%',
    fontSize: '32px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: colors.primaryDarkGrey
  },
  fabStyleBottom: {
    position: 'fixed',
    bottom: 20,
    right: 20
  },
  popoverClassBottom: {
    '& .MuiPaper-root': {
      bottom: 80,
      top: 'unset !important',
      left: 'unset !important',
      right: 30
    }
  },
  listItemRootBottom: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: colors.primary,
      color: colors.white
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  uploadImageText: {
    fontSize: 14,
    color: colors.primaryDarkGrey,
    fontWeight: 'bold'
  }
}));

function TopHeader({ firebase }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [isDisableButton, setDisableButton] = useState();
  // const [hasPreserveEntryData, setPreserveEntryData] = useState();
  const hasPreserveEntryData = useSelector(
    (state) => state.app.hasPreserveEntryData
  );
  const [isOpenModal, setOpenModal] = useState({ open: false, values: null });
  const [formValues, setFormValues] = useState(null);
  const [isDisabledField, setDisabledField] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const currentUser = useSelector((state) => state.auth.user);
  const currentAgent = useSelector((state) => state.agent.currentAgent);
  const maintenanceMode = useSelector(
    (state) => state.maintenance.maintenanceMode
  );
  const isLoading = useSelector((state) => state.app.isLoading);
  const [profileImageObject, setProfileImageObject] = React.useState(null);
  const [isOpenBottom, setOpenBottom] = useState({ open: false, value: null });
  const [isSingleFamilyOpen, setSingleFamilyOpen] = useState({
    open: false,
    value: null
  });
  const [anchorElBottom, setAnchorElBottom] = React.useState(null);
  const openBottom = Boolean(anchorElBottom);
  const idBottom = openBottom ? 'simple-popover' : undefined;
  const [newPasswordFieldType, setNewPasswordFieldType] = useState('password');
  const [confirmPasswordFieldType, setConfirmPasswordFieldType] =
    useState('password');
  const [fileUploadModal, setFileUploadModal] = useState({
    isModalOpen: false,
    fileUploadState: 0
  });

  const onToggleNewPasswordFieldType = () => {
    if (newPasswordFieldType === 'password') {
      setNewPasswordFieldType('text');
    } else {
      setNewPasswordFieldType('password');
    }
  };

  const onToggleConfirmPasswordFieldType = () => {
    if (confirmPasswordFieldType === 'password') {
      setConfirmPasswordFieldType('text');
    } else {
      setConfirmPasswordFieldType('password');
    }
  };
  const handleClickBottom = (event) => {
    setAnchorElBottom(event.currentTarget);
  };

  const locationUrl = useMemo(() => {
    return window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1
    );
  }, []);

  const handleCloseBottom = (type) => {
    if (
      hasPreserveEntryData &&
      hasPreserveEntryData.entries &&
      hasPreserveEntryData.entries[0] &&
      hasPreserveEntryData.entries[0].groupType !== type
    ) {
      dispatch(setPreserveEntryData(null));
    }
    setAnchorElBottom(null);
  };

  const handleOnClose = () => {
    setFileUploadModal((prev) => ({ ...prev, isModalOpen: false }));
  };

  const uploadAgentsProfileImage = (event) => {
    const fileLength = event.target.files.length;
    if (fileLength > 0) {
      const file = event.target.files[0];
      const fileType = file.name.split('.').pop().toLowerCase();
      event.target.value = '';
      if (isValidFileType(fileType, true)) {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 0 });
        firebase
          .uploadAgentsProfilePicture(file)
          .then((snapShot) => {
            // takes a snap shot of the process as it is happening
            if (snapShot && snapShot.state === 'success') {
              firebase
                .getDownloadURLForAgentsProfileImage(file)
                .then((fireBaseUrl) => {
                  const profileImage = {
                    url: fireBaseUrl,
                    storage_path: `userProfiles/agents/${file.name}`
                  };
                  setProfileImageObject(profileImage);
                  handleOnClose();
                })
                .catch((err) => {
                  handleOnClose();
                  // catches the errors
                  console.log('Error while Getting Agents Download URL', err);
                });
            } else {
              setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
            }
          })
          .catch((err) => {
            setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
            // catches the errors
            console.log('Error while Uploading Agents Profile Picture', err);
          });
      } else {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 1 });
      }
    }
  };

  const removeAgentsProfileImage = () => {
    const profileImageObjectData = profileImageObject;
    const storagePath = _.get(profileImageObjectData, 'storage_path', '');
    firebase
      .deleteAgentsProfileImageFromStorage(storagePath)
      .then(() => {
        setProfileImageObject(null);
        if (currentAgent.profileImage !== null) {
          currentAgent.profileImage = null;
          const user = { ...currentAgent };
          delete user.password;
          saveUpdated({ currentUser: user, id: currentAgent.uid });
        }
      })
      .catch((error) => {
        console.log('error while removing profile picture', error);
      });
  };

  const logout = useCallback(() => {
    firebase.doSignOut();
    localStorage.removeItem('isCodeVerified');
    dispatch(logoutSuccess());
    history.push('/login');
  }, [firebase, history, dispatch]);

  const getSchoolListHandle = useCallback(async () => {
    // PHASE2/CHANGES/SCHOOL

    try {
      const schools = [];
      const result = await getSchools();
      result.forEach((school) => {
        const yearData = school.freeCalendars?.map((calendar) => ({
          label: `Year ${calendar}`,
          value: calendar
        }));

        schools.push({
          id: school.id,
          name: school.schoolName,
          years: yearData.length ? yearData : []
        });
      });
      dispatch(getSchoolList(schools));
    } catch (error) {
      console.error(error);
      dispatch(
        addMessage(
          'Server connection issue. Please refresh',
          MESSAGE_TYPES.ERROR
        )
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (!currentUser || !currentUser.uid) {
      firebase.doOnAuthStateChanged((user) => {
        if (user != null) {
          firebase
            .getUserByID(user.uid)
            .then((doc) => {
              const userData = doc.data();
              if (
                userData &&
                ['Agent', 'Manager', 'Admin'].includes(userData.role)
              ) {
                dispatch(
                  loginSuccess({
                    uid: user.uid,
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    phoneNumber: userData.phoneNumber,
                    profileImage: userData.profileImage,
                    initials: userData.initials,
                    userName: userData.userName,
                    role: userData.role,
                    country: userData.country,
                    encryptedPassword: userData.encryptedPassword || ''
                  })
                );
                setProfileImageObject(userData.profileImage);
                getSchoolListHandle();
              } else {
                logout();
                dispatch(addMessage('Permission Denied', MESSAGE_TYPES.ERROR));
              }
            })
            .catch(function (error) {
              console.log('Error getting cached document:', error);
              history.go(0);
            });
        }
      });
    } else {
      setProfileImageObject(currentUser.profileImage);
    }
  }, [
    firebase,
    dispatch,
    currentUser,
    history,
    logout,
    open,
    getSchoolListHandle
  ]);

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      // const auth = app.auth();
      const db = app.firestore();
      const doc = db.collection('users').doc(currentUser.uid);

      // const observer = doc.onSnapshot(
      doc.onSnapshot(
        (docSnapshot) => {
          // logic for if manager change role OR password then it automatically logout
          // Use encryptedPassword instead of password according
          const userData = docSnapshot.data();
          if (userData && (userData.role || userData.encryptedPassword)) {
            if (
              currentUser.role !== userData.role ||
              (userData.encryptedPassword &&
                currentUser.encryptedPassword &&
                userData.encryptedPassword !== currentUser.encryptedPassword)
            ) {
              logout();
            }
          }
          // End logic for if manager change role then it automatically logout

          // ...
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
      firebase
        .getUserByID(currentUser.uid)
        .then(
          () => {},
          (error) => {
            console.log('Snap shot Error: ', error);
            if (error.code === 'permission-denied') {
              dispatch(
                addMessage(
                  error.message ? error.message : 'Something went wrong!',
                  MESSAGE_TYPES.ERROR
                )
              );
              logout();
            }
          }
        )
        .catch((error) => {
          console.log('Snap shot Error on error : ', error);
        });
    }
    // eslint-disable-next-line
  }, [currentUser, logout]);

  const changePassword = (userId, password, newPassword) => {
    if (password && newPassword && password === newPassword) {
      dispatch(loaderStart());
      const requestOptions = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: userId,
          new_password: password,
          confirm_password: newPassword
        })
      };
      fetch(`${FIREBASE_API_URL}/changePassword`, requestOptions)
        .then((response) => {
          const result = response.json();
          if (!response.ok) {
            throw new Error(result?.message);
          }
          return result;
        })
        .then(
          () => {
            dispatch(loaderStop());
            // Update users collection with encryptedPassword
            firebase.updateAgent(
              {
                ...currentUser,
                password: '',
                encryptedPassword: encryptSHA256(password)
              },
              currentUser.uid
            );
            logout();
          },
          (error) => {
            const errorMessage = _.get(error, 'message', '');
            console.log(errorMessage);
            dispatch(loaderStop());
          }
        );
    }
  };

  const handleClose = useCallback(() => {
    setOpenModal({ open: false, values: null });
    setOpen(false);
  }, [setOpenModal]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required('First name is required'),
    lastName: Yup.string().trim().required('Last name is required'),
    initials: Yup.string().trim().required('Initials is required'),
    email: Yup.string().trim().email().required('Valid email is required'),
    password: Yup.string()
      .trim()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
        'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.',
        { excludeEmptyString: true }
      ),
    newPassword: Yup.string()
      .trim()
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          'Both password need to be the same'
        )
      }),
    profileImage: Yup.mixed()
  });

  const saveUpdated = ({ currentUser, id }) => {
    dispatch(loaderStart());
    const updatedData = currentUser.password
      ? {
          ...currentUser,
          password: '',
          encryptedPassword: encryptSHA256(currentUser.password)
        }
      : currentUser;
    firebase
      .updateAgent(updatedData, id)
      .then(
        async () => {
          await updateFirebaseUser(currentUser.uid, currentUser.email);
          dispatch(loaderStop());
          setOpenModal({
            open: false,
            values: null
          });
          setOpen(false);
          handleClosePopover();
          dispatch(
            loginSuccess({
              ...currentUser
            })
          );

          if (locationUrl === 'agents') {
            window.location.reload();
          }
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(
            addMessage(
              'Agent was not created. Please try again',
              MESSAGE_TYPES.ERROR
            )
          );
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(
          addMessage(
            'Agent was not created. Please try again',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });
  };

  const updateFirebaseUser = async (userId, emailAddress) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: userId,
        email_address: emailAddress
      })
    };
    await fetch(`${FIREBASE_API_URL}/updateUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result;
      })
      .then(
        () => {},
        (error) => {
          const errorMessage = _.get(error, 'message', '');
          console.log(errorMessage);
          dispatch(loaderStop());
        }
      );
  };

  const openRightDrawer = useCallback(
    (data) => {
      setOpen(true);
      if (data && data.role === 'Agent') {
        setDisabledField(true);
      } else {
        setDisabledField(false);
      }
    },
    [setOpen]
  );

  const onClose = useCallback(
    (currentState) => {
      setOpen(currentState);
      dispatch(resetAgent());
      handleClosePopover();
    },
    [setOpen, dispatch]
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const { displayName, initials, profileImage } = useMemo(() => {
    if (currentUser && currentUser.uid) {
      return {
        displayName: `${currentUser.firstName} ${currentUser.lastName}`,
        initials: currentUser.initials,
        profileImage: currentUser.profileImage
      };
    }
    return {
      displayName: '',
      initials: '',
      profileImage: ''
    };
  }, [currentUser]);

  const onCloseForm = () => {
    const values = formValues;
    const valuesObject = {
      firstName: values.firstName,
      lastName: values.lastName,
      initials: values.initials,
      email: values.email,
      profileImage: profileImageObject
        ? profileImageObject
        : currentUser.profileImage
          ? currentUser.profileImage
          : '',
      userName: currentUser.userName || '',
      phoneNumber: currentUser.phoneNumber || ''
    };
    const dataObject = {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      initials: currentUser.initials,
      email: currentUser.email,
      profileImage: currentUser.profileImage || '',
      userName: currentUser.userName || '',
      phoneNumber: currentUser.phoneNumber || ''
    };
    const sameData = _.isEqual(valuesObject, dataObject);
    if (sameData) {
      setOpen(false);
      if (!_.isEmpty(values.password) && !_.isEmpty(values.newPassword)) {
        changePassword(currentUser.uid, values.password, values.newPassword);
      }
    } else {
      setOpenModal({
        open: true,
        values: {
          currentUser: { ...currentUser, ...valuesObject },
          id: currentUser.uid
        }
      });
      // saveUpdated(currentUser, currentUser.uid);
      if (!_.isEmpty(values.password) && !_.isEmpty(values.newPassword)) {
        changePassword(currentUser.uid, values.password, values.newPassword);
      }
    }
  };

  if (currentUser === null) {
    return null;
  }

  return (
    <AppBar
      position="fixed"
      color="transparent"
      classes={{ root: classes.shadowNone }}
      className={classes.appBar}
    >
      {maintenanceMode ? (
        <div className={classes.maintenanceBanner}>
          <Typography>Maintenance mode is turned on</Typography>
        </div>
      ) : (
        <></>
      )}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Toolbar>
          <div className={classes.rightHeder}>
            <div className={classes.iconWrapper}>
              <Typography classes={{ root: classes.userlabel }}>
                {displayName}
              </Typography>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
                style={{ marginRight: 12 }}
              >
                <Avatar
                  className={classes.profileAvatar}
                  alt="Admin Agent"
                  src={_.get(profileImage, 'url', '')}
                >
                  {initials}
                </Avatar>
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                classes={{ root: classes.popoverClassList }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <List>
                  <ListItem
                    classes={{
                      root: classes.listItemRoot
                    }}
                    button
                    onClick={() => {
                      dispatch(setAgent(currentUser));
                      openRightDrawer(currentUser);
                    }}
                  >
                    <ListItemText primary={'My Account'} />
                  </ListItem>
                  <ListItem
                    classes={{
                      root: classes.listItemRoot
                    }}
                    button
                    onClick={logout}
                  >
                    <ListItemText primary={'Sign Out'} />
                  </ListItem>
                </List>
              </Popover>
            </div>
          </div>
        </Toolbar>
        <RightDrawer
          isOpen={isOpen}
          onClose={() => {
            onClose(true);
            onCloseForm();
          }}
          width={520}
        >
          {
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress color="primary" />
            </Backdrop>
          }
          <div>
            <Formik
              initialValues={{
                firstName:
                  currentUser && currentUser.firstName
                    ? currentUser.firstName
                    : '',
                lastName:
                  currentUser && currentUser.lastName
                    ? currentUser.lastName
                    : '',
                email:
                  currentUser && currentUser.email ? currentUser.email : '',
                initials:
                  currentUser && currentUser.initials
                    ? currentUser.initials
                    : '',
                password: '',
                newPassword: '',
                profileImage:
                  currentUser && currentUser.profileImage
                    ? currentUser.profileImage
                    : ''
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setErrors }) => {
                const valuesObject = {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  initials: values.initials,
                  email: values.email,
                  profileImage: profileImageObject
                    ? profileImageObject
                    : currentUser.profileImage
                      ? currentUser.profileImage
                      : '',
                  userName: currentUser.userName || '',
                  phoneNumber: currentUser.phoneNumber || ''
                };
                const dataObject = {
                  firstName: currentUser.firstName,
                  lastName: currentUser.lastName,
                  initials: currentUser.initials,
                  email: currentUser.email,
                  profileImage: currentUser.profileImage || '',
                  userName: currentUser.userName || '',
                  phoneNumber: currentUser.phoneNumber || ''
                };
                const sameData = _.isEqual(valuesObject, dataObject);
                if (sameData) {
                  if (
                    !_.isEmpty(values.password) &&
                    !_.isEmpty(values.newPassword)
                  ) {
                    changePassword(
                      currentUser.uid,
                      values.password,
                      values.newPassword
                    );
                    setOpen(false);
                    handleClosePopover();
                  } else if (
                    !_.isEmpty(values.password) &&
                    _.isEmpty(values.newPassword)
                  ) {
                    setErrors({ newPassword: 'Password Must be same!' });
                  } else {
                    setOpen(false);
                    handleClosePopover();
                  }
                } else {
                  if (
                    !_.isEmpty(values.password) &&
                    _.isEmpty(values.newPassword)
                  ) {
                    setErrors({ newPassword: 'Password Must be same!' });
                  } else {
                    const user = { ...currentUser };
                    delete user.password;
                    saveUpdated({
                      currentUser: { ...user, ...valuesObject },
                      id: currentUser.uid
                    });
                    if (
                      !_.isEmpty(values.password) &&
                      !_.isEmpty(values.newPassword)
                    ) {
                      changePassword(
                        currentUser.uid,
                        values.password,
                        values.newPassword
                      );
                    }
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                handleLoad,
                setFieldValue
              }) => {
                const isSubmitButtonDisabled =
                  _.isEmpty(values.firstName) ||
                  _.isEmpty(values.lastName) ||
                  _.isEmpty(values.initials) ||
                  _.isEmpty(values.email);
                setDisableButton(isSubmitButtonDisabled);
                if (
                  !_.isEmpty(touched) &&
                  !isSubmitButtonDisabled &&
                  !_.isEmpty(errors)
                ) {
                  setDisableButton(true);
                }
                const profileImageURL = profileImageObject
                  ? profileImageObject
                  : currentUser.profileImage
                    ? currentUser.profileImage
                    : '';
                setFormValues(values);
                return (
                  // eslint-disable-next-line react/no-unknown-property
                  <form noValidate onSubmit={handleSubmit} onLoad={handleLoad}>
                    <div style={{ margin: '10px 30px', position: 'relative' }}>
                      <span className={classes.mainHeading}>Edit Account</span>
                      <div
                        style={{
                          float: 'right',
                          position: 'absolute',
                          top: 0,
                          right: 0
                        }}
                      >
                        <Button
                          variant="contained"
                          color={isDisableButton ? 'secondary' : 'primary'}
                          className={classes.siderSave}
                          onClick={handleSubmit}
                          disabled={isDisableButton}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                    <Divider
                      variant="middle"
                      className={classes.dividerClass}
                    />
                    <div style={{ margin: '10px 30px', marginBottom: 105 }}>
                      <FormControl
                        component="fieldset"
                        style={{ width: '100%' }}
                      >
                        <TextField
                          label="First Name"
                          variant="outlined"
                          onBlur={handleBlur}
                          name="firstName"
                          value={values.firstName ? values.firstName : ''}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue(
                              `initials`,
                              `${e.target.value.charAt(
                                0
                              )}${values.lastName.charAt(0)}`
                            );
                          }}
                          error={Boolean(touched.firstName && errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                          className={classes.inputControlBox}
                          autoFocus
                          disabled={isDisabledField}
                        />
                      </FormControl>
                      <FormControl component="fieldset">
                        <TextField
                          label="Last Name"
                          variant="outlined"
                          onBlur={handleBlur}
                          name="lastName"
                          value={values.lastName ? values.lastName : ''}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue(
                              `initials`,
                              `${values.firstName.charAt(
                                0
                              )}${e.target.value.charAt(0)}`
                            );
                          }}
                          error={Boolean(touched.lastName && errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          className={classes.inputControlBox}
                          disabled={isDisabledField}
                        />
                      </FormControl>
                      <div style={{ width: 427 }}>
                        <FormControl component="fieldset">
                          <TextField
                            label="Initials"
                            variant="outlined"
                            className={classes.inputControlBox}
                            onBlur={handleBlur}
                            name="initials"
                            value={values.initials ? values.initials : ''}
                            onChange={handleChange}
                            error={Boolean(touched.initials && errors.initials)}
                            helperText={touched.initials && errors.initials}
                            style={{ width: 120, marginRight: 25 }}
                            disabled={isDisabledField}
                          />
                        </FormControl>
                      </div>
                      <div className={classes.profileImageWrapper}>
                        <label
                          htmlFor="upload-agent-profile-picture"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <TextField
                            style={{ display: 'none' }}
                            id="upload-agent-profile-picture"
                            name="upload-agent-profile-picture"
                            type="file"
                            onChange={(e) => uploadAgentsProfileImage(e)}
                          />
                          <Badge
                            overlap="circle"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                            }}
                            // badgeContent={
                            //   _.isEmpty(profileImageURL) && <CameraAltIcon />
                            // }
                          >
                            <Avatar
                              className={classes.imageContainer}
                              alt={values.initials}
                              src={_.get(profileImageURL, 'url', '')}
                            >
                              {values.initials}
                            </Avatar>
                          </Badge>
                          {_.isEmpty(profileImageURL) && (
                            <div className={classes.removeImageContainer}>
                              <Typography className={classes.uploadImageText}>
                                Upload Image
                              </Typography>
                            </div>
                          )}
                        </label>
                        {!_.isEmpty(profileImageURL) && (
                          <div
                            className={classes.removeImageContainer}
                            onClick={() => removeAgentsProfileImage()}
                          >
                            <Typography className={classes.uploadImageText}>
                              Remove
                            </Typography>
                          </div>
                        )}
                      </div>
                      <Divider className={classes.dividerClass} />
                      <FormControl component="fieldset">
                        <TextField
                          label="Login Email"
                          variant="outlined"
                          onBlur={handleBlur}
                          name="email"
                          value={values.email ? values.email : ''}
                          onChange={handleChange}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          className={classes.inputControlBox}
                          disabled={isDisabledField}
                        />
                      </FormControl>
                      <div style={{ width: '100%', marginBottom: 20 }}>
                        <FormControl component="fieldset">
                          <TextField
                            label="New Password"
                            variant="outlined"
                            onBlur={handleBlur}
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            className={classes.inputControlBox}
                            type={newPasswordFieldType}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle newPassword visibility"
                                    onClick={() =>
                                      onToggleNewPasswordFieldType()
                                    }
                                    tabIndex="-1"
                                  >
                                    <img
                                      alt="Password Requirement"
                                      src={
                                        newPasswordFieldType === 'text'
                                          ? '/static/images/password-hide.png'
                                          : '/static/images/password-see.png'
                                      }
                                      width="22"
                                      height="22"
                                    />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </FormControl>
                        <FormControl component="fieldset">
                          <TextField
                            label="New Password(again)"
                            variant="outlined"
                            onBlur={handleBlur}
                            name="newPassword"
                            value={values.newPassword}
                            onChange={handleChange}
                            error={Boolean(
                              touched.newPassword && errors.newPassword
                            )}
                            helperText={
                              touched.newPassword && errors.newPassword
                            }
                            className={classes.inputControlBox}
                            type={confirmPasswordFieldType}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle newPassword visibility"
                                    onClick={() =>
                                      onToggleConfirmPasswordFieldType()
                                    }
                                    tabIndex="-1"
                                  >
                                    <img
                                      alt="Password Requirement"
                                      src={
                                        confirmPasswordFieldType === 'text'
                                          ? '/static/images/password-hide.png'
                                          : '/static/images/password-see.png'
                                      }
                                      width="22"
                                      height="22"
                                    />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                    {isOpenModal.open && (
                      <Modal isOpen={isOpenModal.open} onClose={handleClose}>
                        <div className={classes.modalBox}>
                          <Typography className={classes.modalTitle}>
                            Save your changes?
                          </Typography>
                          <div className={classes.modalContent}>
                            <Typography className={classes.modalSubTitle}>
                              Your changes will be lost if you don&apos;t save
                              them.
                            </Typography>
                          </div>
                          <div className={classes.modalActions}>
                            <Button
                              onClick={() => {
                                saveUpdated(isOpenModal.values);
                              }}
                              color="primary"
                              variant="contained"
                              size="large"
                              className={classes.primaryButton}
                              startIcon={
                                isLoading && (
                                  <CircularProgress
                                    color="secondary"
                                    size={25}
                                  />
                                )
                              }
                            >
                              {!isLoading && 'Save Changes'}
                            </Button>
                            <Button
                              onClick={handleClose}
                              variant="contained"
                              size="large"
                              className={classes.greyButton}
                            >
                              Don&apos;t Save
                            </Button>
                          </div>
                        </div>
                      </Modal>
                    )}
                  </form>
                );
              }}
            </Formik>
          </div>
        </RightDrawer>

        {isOpenBottom.open && (
          <CreateGroupCalenderEntry
            isOpen={isOpenBottom.open}
            type={isOpenBottom.value}
            onClose={() => {
              setOpenBottom({ open: false, value: null });
            }}
            // hasPreserveEntryData={hasPreserveEntryData}
            // setPreserveEntryData={setPreserveEntryData}
          />
        )}
        {isSingleFamilyOpen.open && (
          <CreateSingleFamilyEntry
            isOpen={isSingleFamilyOpen.open}
            type={isSingleFamilyOpen.value}
            onClose={() => {
              setSingleFamilyOpen({ open: false, value: null });
            }}
            // hasPreserveEntryData={hasPreserveEntryData}
            // setPreserveEntryData={setPreserveEntryData}
          />
        )}
        <ScanningUploadFileModal
          handleOnClose={handleOnClose}
          isOpen={fileUploadModal.isModalOpen}
          fileUploadState={fileUploadModal.fileUploadState}
        />
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fabStyleBottom}
          onClick={handleClickBottom}
          // onClick={() => setOpenBottom(true)}
          style={{ right: anchorElBottom ? 37 : 20 }}
        >
          {openBottom ? <CloseIcon /> : <AddIcon />}
        </Fab>
        <Popover
          id={idBottom}
          open={openBottom}
          anchorEl={anchorElBottom}
          onClose={handleCloseBottom}
          classes={{ root: classes.popoverClassBottom }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <List>
            <ListItem
              button
              classes={{
                root: classes.listItemRootBottom
              }}
              onClick={() => {
                handleCloseBottom('Single');
                setSingleFamilyOpen({ open: true, value: 'Single' });
              }}
            >
              <ListItemText primary={'Single Family'} />
            </ListItem>
            <ListItem
              button
              classes={{
                root: classes.listItemRootBottom
              }}
              onClick={() => {
                handleCloseBottom('Families');
                setOpenBottom({ open: true, value: 'Families' });
              }}
            >
              <ListItemText primary={'Group (Family)'} />
            </ListItem>
            <ListItem
              button
              classes={{
                root: classes.listItemRootBottom
              }}
              onClick={() => {
                handleCloseBottom('Group');
                setOpenBottom({ open: true, value: 'Group' });
              }}
            >
              <ListItemText primary={'Group (Custom)'} />
            </ListItem>
            {/* <ListItem
              button
              classes={{
                root: classes.listItemRootBottom
              }}
              onClick={() => {
                handleCloseBottom('Free');
                setOpenBottom({ open: true, value: 'Free' });
              }}
            >
              <ListItemText primary={'Group (Free Cal)'} />
            </ListItem> */}
          </List>
        </Popover>
      </MuiPickersUtilsProvider>
    </AppBar>
  );
}

export default withFirebase(TopHeader);
