// Group set Success
export const setGroup = (group) => ({
  type: 'SET_GROUP',
  group
});

// Reset Group
export const resetGroup = () => ({
  type: 'RESET_GROUP'
});

export const setAddEditGroupModalData = (groupAddEditModalData) => ({
  type: 'SET_ADD_EDIT_GROUP_MODAL_DATA',
  groupAddEditModalData
});

export const setPaidGroupModalData = (groupPaidModalData) => ({
  type: 'SET_PAID_GROUP_MODAL_DATA',
  groupPaidModalData
});
