import axios from './axios';

// Constants
import { API_ROUTES } from 'src/constants/api-routes';

// Utils
import { getRouteByParams, getRouteByQueryParams } from 'src/utils/route';

export const addSchoolFormDetailsOrUpdateSchool = async (schoolId, payload) => {
  try {
    const URL = getRouteByParams(
      API_ROUTES.UPDATE_SCHOOL_OR_CREATE_SCHOOL_FORM,
      { id: schoolId }
    );
    const response = await axios.post(URL, payload);
    return response.status === 200 && response?.data?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const isUrlAvailable = async (url) => {
  try {
    const response = await axios.post(API_ROUTES.GET_SCHOOL_BY_URL, { url });
    return response.status === 200 && response?.data
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSchools = async () => {
  try {
    const response = await axios.get(API_ROUTES.GET_SCHOOLS);
    return response.status === 200 && response?.data?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSchoolByID = async (schoolId) => {
  try {
    const URL = getRouteByParams(API_ROUTES.GET_SCHOOL_ID, { id: schoolId });
    const response = await axios.get(URL);
    return response.status === 200 && response?.data?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addSchool = async (payload) => {
  try {
    const response = await axios.post(API_ROUTES.CREATE_SCHOOL, payload);
    return response.status === 200 && response?.data?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteSchool = async (schoolId) => {
  try {
    const URL = getRouteByParams(API_ROUTES.DELETE_SCHOOL, { id: schoolId });
    const response = await axios.delete(URL);
    return response.status === 200 && response?.data?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addSchoolLinks = async (links) => {
  try {
    const response = await axios.post(API_ROUTES.CREATE_SCHOOL_LINK, links);
    return response.status === 200 && response?.data?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateSchoolLinks = async (links) => {
  try {
    const response = await axios.post(API_ROUTES.UPDATE_SCHOOL_LINK, links);
    return response.status === 200 && response?.data?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteSchoolLinks = async ({ schoolId, linkId }) => {
  try {
    const URL = getRouteByParams(API_ROUTES.DELETE_SCHOOL_LINK, {
      schoolId,
      linkId
    });
    const response = await axios.delete(URL);
    return response.status === 200 && response?.data?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteSchoolForm = async (schoolId) => {
  try {
    const URL = getRouteByParams(API_ROUTES.DELETE_SCHOOL_FORM, {
      id: schoolId
    });
    const response = await axios.delete(URL);
    return response.status === 200 && response?.data?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getFreeSchools = async () => {
  try {
    const response = await axios.get(API_ROUTES.GET_FREE_SCHOOLS);
    return response.status === 200 && response?.data?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSchoolsByFilter = async ({
  pageNo,
  pageSize,
  searchText,
  createdBy
}) => {
  try {
    const URL = getRouteByQueryParams(API_ROUTES.GET_SCHOOL_BY_FILTER, {
      pageNo,
      pageSize,
      searchText,
      createdBy
    });
    const response = await axios.get(URL);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const generateStudentReport = async (schoolId) => {
  try {
    const response = await axios.post(API_ROUTES.GENERATE_STUDENT_REPORT, {
      schoolId
    });
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchLanguageList = async () => {
  try {
    const response = await axios.get(API_ROUTES.FETCH_LANGUAGE_LIST);
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// ❌ NOT_USED
export const getCategoryNameForGroups = async () => {};

// ----------🔍SEARCH_BY----------
// PHASE2/CHANGES/SCHOOL
// useSelector((state) => state.school.currentSchool

// ✔️ 🔍 ⌛ ☠️ ✋ 👉 ❗ 📌 ❌

// ----------📌FILES_WILL_BE_CHANGE----------
// ❌ [CONDITION_NEVER_TRUE] src/components/TopHeader.js [getSchools]
// ✔️ src/components/schoolAutomationComp/LoadingBarStatus.js  [getSchools]
// ✔️ src/components/SuccessComponent.js [getSchoolByID]

// ❌ [USED_IN_TOP_HEADER]  src/views/families/CreateGroupCalenderEntry.js [getSchools, getSchoolByID]
// ✔️ src/views/families/AddFamilyMember.js [getSchools]
// ✔️ src/views/families/FamilyViewPaid.js [getSchools]
// ✔️ src/views/families/Users.js [getSchools]
// 👉 src/views/families/components/GroupEditSection.js  [getSchools, getSchoolByID]

// ✔️ src/views/members/AddChildren.js [getSchools]
// ✔️ src/views/members/MembersUsersView.js [getSchools, getSchoolByID]
// ❌ [FILE_NOT_IN_USE] src/views/members/components/GroupEditSection.js [getSchools, getSchoolByID]

// ❌ [FILE_NOT_IN_USE] src/views/groups/CreateGroup.js [getSchoolByID, updateSchool]

// ✔️ src/views/schools/EditSchoolCalendar.js [getSchoolByID, updateSchool]
// ✔️ src/views/schools/EditSchoolProfile.js [getSchoolByID, updateSchool, deleteSchool]
// ✔️ src/views/schools/Files.js [getSchoolByID, updateSchool]
// ✔️ src/views/schools/index.js [getSchoolByID]
// ✔️ src/views/schools/Links.js [getSchoolByID, updateSchool, deleteSchool]
// ✔️ src/views/schools/groups/PaidEditGroup.js [getSchoolByID, updateSchool]
// ✔️ src/views/schools/groups/SchoolGroup.js [getSchoolByID, updateSchool]
// ✔️ src/views/schools/groups/SchoolGroupWrapper.js [getSchoolByID, updateSchool]
// ✔️ src/views/schools/CreateSchool.js [addSchool]
// ❌ [USED_IN_STEPPER_COMPONENT, BUT_CONDITION_NEVER_TRUE] src/views/schools/Calendar.js [getSchools]
// 👉 src/views/schools/CategoryGroupCalendarEntry.js [✔️getSchools, [❌CONDITION_NEVER_TRUE] getSchoolByID]
// ✔️ src/views/schools/CreateSchoolCalenderEntry.js [getSchools]
// ✔️ src/views/schools/students/index.js [getSchools, getSchoolByID]
// ✔️ src/views/schools/Form.js [addSchoolFormDetails, isUrlAvailable, getSchoolByID, updateSchool]
