/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, TableRow, TableCell, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Dashboard from '../layout/Dashboard';
import { withFirebase } from 'src/utils/firebase';
import TableList from 'src/components/TableList';
import { loaderStart, loaderStop } from 'src/redux/actions/appActions';
import { routes } from 'src/utils/constant';
import {
  setSchool,
  resetSchool,
  setSchoolPreserveValues
} from 'src/redux/actions/schoolActions';
import api_icon from 'src/assets/apiIcon.svg';
import { getSchoolByID, getSchoolsByFilter } from 'src/services/schools';
import lodash from 'lodash';
import { useCallback } from 'react';
import { schoolIndexStyles } from 'src/styles/schoolStyle/schoolIndexStyles';

const columns = [
  {
    id: 'schoolName',
    label: 'School',
    minWidth: 170,
    hasSort: true,
    width: '30%'
  },
  {
    id: 'country',
    label: 'Country',
    minWidth: 100,
    hasSort: true,
    width: '14%'
  },
  {
    id: 'freecals',
    label: 'Free Cals',
    minWidth: 170,
    setLabelStyle: true,
    width: '14%'
  },
  {
    id: 'form',
    label: 'Form',
    format: 'icon',
    iconPosition: 'center',
    icon: false,
    width: '14%'
  },
  {
    id: 'apiSync',
    label: '',
    align: 'center',
    format: 'icon',
    iconPosition: 'center',
    icon: false
  }
];

function CellItem({ row, column }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const value = row[column.id];
  const classes = schoolIndexStyles();
  // Get single school's data
  const handleTableRow = async () => {
    dispatch(loaderStart());
    // PHASE2/CHANGES/SCHOOL

    try {
      const school = await getSchoolByID(row.id);
      dispatch(setSchool(school));
      dispatch(setSchoolPreserveValues(school));
      dispatch(loaderStop());
      if (row.freeCalendars.length) {
        history.push({
          pathname: routes.schoolCalendar + '/' + row['id'],
          openRightDrawer: false
        });
      } else {
        history.push({
          pathname: routes.schoolAccount + '/' + row['id'],
          openRightDrawer: false
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (column.id === 'schoolName') {
    return (
      <span
        className={classes.schoolName}
        onClick={() => {
          handleTableRow();
        }}
      >
        {value}
      </span>
    );
  }
  if (column.id === 'country') {
    return value;
  }
  if (column.id === 'freecals') {
    if (row?.freeCalendars?.length) {
      return <span className={classes.hasCal}>YES</span>;
    }
    return <span className={classes.noCal}>NO</span>;
  }
  if (column.id === 'form') {
    if (row?.formData?.isPublished && row?.freeCalendars?.length) {
      return (
        <span
          className={classes.actionIcon}
          onClick={() => {
            dispatch(setSchool(row));
            history.push({
              pathname: routes.schoolCalendar + '/' + row['id'],
              openRightDrawer: true
            });
          }}
        >
          <Typography className={classes.fromValue}>Live</Typography>
        </span>
      );
    } else if (!row?.formData?.isPublished && row.freeCalendars?.length) {
      return (
        <span
          className={classes.actionIcon}
          onClick={() => {
            dispatch(setSchool(row));
            history.push({
              pathname: routes.schoolCalendar + '/' + row['id'],
              openRightDrawer: true
            });
          }}
        >
          <Typography className={classes.fromValue}>None</Typography>
        </span>
      );
    } else {
      return '';
    }
  }
  if (column.id === 'apiSync' && row?.createdByApiSync) {
    return (
      <img
        src={api_icon}
        style={{ paddingRight: '1rem', float: 'right', marginRight: '52px' }}
      />
    );
  }
  return '';
}

function TableRowItem({ row, columns, firebase }) {
  const classes = schoolIndexStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleTableRow = async () => {
    dispatch(loaderStart());
    // PHASE2/CHANGES/SCHOOL

    try {
      const school = await getSchoolByID(row.id);
      dispatch(setSchool(school));
      dispatch(setSchoolPreserveValues(school));
      dispatch(loaderStop());
      if (row.freeCalendars.length) {
        history.push({
          pathname: routes.schoolCalendar + '/' + row['id'],
          openRightDrawer: false
        });
      } else {
        history.push({
          pathname: routes.schoolAccount + '/' + row['id'],
          openRightDrawer: false
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      className={classes.tableRow}
      onClick={(e) => {
        if (e.target.tagName.toLowerCase() === 'td') {
          handleTableRow();
        }
      }}
    >
      {columns.map((column) => {
        return (
          <TableCell
            key={column.id}
            align={column.align}
            className={classes.dataTableCell}
            style={{ width: column.width }}
          >
            <CellItem row={row} column={column} firebase={firebase} />
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function SchoolListView({ firebase }) {
  const classes = schoolIndexStyles();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [tableRecords, setTableRecords] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [rowsCountPerPage, setRowsCountPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(false);

  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchDebouncedFunction = useCallback(
    lodash.debounce((debouncedValue) => {
      setDebouncedSearchText(debouncedValue);
    }, 1000),
    []
  );

  useEffect(() => {
    dispatch(resetSchool());
  }, []);

  // Search text function
  const searchChangeHandler = (e) => {
    setCurrentPage((prev) => !prev);
    const { value } = e.target;
    setSearchText(value.toLowerCase().trim());
    searchDebouncedFunction(value.toLowerCase().trim());
  };

  useEffect(() => {
    dispatch(loaderStart());
    getSchoolsByFilter({
      pageSize: rowsCountPerPage,
      searchText: debouncedSearchText
    })
      .then((result) => {
        setTableData(result.data);
        setTableRecords(result.totalRecords);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(loaderStop());
      });
  }, [debouncedSearchText, rowsCountPerPage]);

  // Page change next/prev function
  const handlePageChange = async (currentPage, newPage, rowsPerPage) => {
    dispatch(loaderStart());
    try {
      const result = await getSchoolsByFilter({
        pageSize: rowsPerPage,
        pageNo: newPage + 1,
        searchText: debouncedSearchText
      });
      setTableData(result.data);
      setTableRecords(result.totalRecords);
      dispatch(loaderStop());
    } catch (error) {
      console.error(error);
      dispatch(loaderStop());
    }
  };

  // Page size change function
  const handleRowsPerPageChange = async (rowsPerPage) => {
    dispatch(loaderStart());
    try {
      const result = await getSchoolsByFilter({
        pageSize: rowsPerPage,
        searchText: searchText
      });
      setTableData(result.data);
      setTableRecords(result.totalRecords);
      dispatch(loaderStop());
    } catch (error) {
      console.error(error);
      dispatch(loaderStop());
    }
  };

  return (
    <Dashboard>
      <Typography variant="h2" color="textPrimary" style={{ marginBottom: 20 }}>
        Schools
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={classes.actionButton}
          startIcon={<AddIcon />}
          classes={{ startIcon: classes.startIcon }}
          onClick={() => history.push(routes.schoolCreate)}
        >
          Add New School
        </Button>
      </Typography>
      <TableList
        currentPage={currentPage}
        columns={columns}
        rows={tableData || []}
        totalCount={tableRecords || 0}
        searchPlaceholderText={'Search by school name'}
        searchChangeHandler={searchChangeHandler}
        pageTitle={'Schools'}
        tableRow={(row, index) => (
          <TableRowItem
            key={index}
            columns={columns}
            row={row}
            firebase={firebase}
          />
        )}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        setRowsCountPerPage={setRowsCountPerPage}
      />
    </Dashboard>
  );
}

export default withFirebase(SchoolListView);
