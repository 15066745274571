import axios from './axios';

// Constants
import { API_ROUTES } from 'src/constants/api-routes';

// Utils
import { getRouteByParams, getRouteByQueryParams } from 'src/utils/route';

export const getStudentsBySchool = async ({
  schoolId,
  pageNo,
  pageSize,
  searchText,
  year,
  createdBy,
  accountStatus
}) => {
  try {
    const route = getRouteByParams(API_ROUTES.GET_STUDENTS_BY_SCHOOL, {
      accountStatus
    });
    const URL = getRouteByQueryParams(route, {
      schoolId,
      createdBy,
      pageNo,
      pageSize,
      searchText,
      year
    });
    const response = await axios.get(URL);
    return response.status === 200 && response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createStudent = async (payload) => {
  try {
    const response = await axios.post(API_ROUTES.CREATE_STUDENT, payload);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateStudent = async (studentId, payload) => {
  try {
    const URL = getRouteByParams(API_ROUTES.UPDATE_STUDENT, {
      id: studentId
    });
    const response = await axios.post(URL, payload);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getStudent = async (studentId) => {
  try {
    const URL = getRouteByParams(API_ROUTES.GET_STUDENT, {
      id: studentId
    });
    const response = await axios.get(URL);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteStudent = async (studentId) => {
  try {
    const URL = getRouteByParams(API_ROUTES.DELETE_STUDENT, { id: studentId });
    const response = await axios.delete(URL);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getStudentsAndMembersBySchool = async ({ schoolId, years }) => {
  try {
    const response = await axios.post(API_ROUTES.GET_STUDENTS_MEMBERS_BY_SCHOOL, {
      schoolId,
      ...(years?.length > 0 ? { years } : {})      
    });
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
