import axios from './axios';

// Constants
import { API_ROUTES } from 'src/constants/api-routes';

// Utils
import { getRouteByParams, getRouteByQueryParams } from 'src/utils/route';

export const getMembers = async (payload) => {
  try {
    const route = getRouteByParams(API_ROUTES.GET_MEMBERS_BY_FILTER, {
      accountStatus: payload.accountStatus
    });
    const URL = getRouteByQueryParams(route, {
      pageNo: payload?.pageNo,
      pageSize: payload?.pageSize,
      searchText: payload?.searchText,
      year: payload?.year,
      schoolId: payload?.schoolId,
      pendingStatus: payload?.pendingStatus
    });
    const response = await axios.get(URL);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getMember = async (memberId) => {
  try {
    const URL = getRouteByParams(API_ROUTES.GET_MEMBER, { id: memberId });
    const response = await axios.get(URL);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateMember = async (memberId, payload) => {
  try {
    const URL = getRouteByParams(API_ROUTES.UPDATE_MEMBER, { id: memberId });
    const response = await axios.post(URL, payload);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteMember = async (memberId) => {
  try {
    const URL = getRouteByParams(API_ROUTES.DELETE_MEMBER, { id: memberId });
    const response = await axios.delete(URL);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const isMemberExist = async (email) => {
  try {
    const URL = getRouteByParams(API_ROUTES.SEARCH_BY_PRIMARY, {
      primaryEmail: email
    });
    const response = await axios.get(URL);
    return response.status === 200 && response?.data ? true : false;
  } catch (error) {
    return false;
  }
};

export const searchMember = async (email) => {
  try {
    const URL = getRouteByParams(API_ROUTES.SEARCH_BY_PRIMARY, {
      primaryEmail: email
    });
    const response = await axios.get(URL);
    return response.status === 200 && response?.data;
  } catch (error) {
    return false;
  }
};

export const createMember = async (payload) => {
  // New, Active, Invited
  try {
    const response = await axios.post(API_ROUTES.CREATE_MEMBER, payload);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const approveMultipleMembersByIds = async (memberIds) => {
  try {
    const response = await axios.post(API_ROUTES.APPROVE_MULTIPLE_MEMBERS, {
      memberIds
    });
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteMultipleMembersIds = async (memberIds) => {
  try {
    const response = await axios.delete(API_ROUTES.DELETE_MULTIPLE_MEMBERS, {
      data: { memberIds }
    });
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const linkOrUnLinkMember = async (payload) => {
  try {
    const response = await axios.post(API_ROUTES.LINK_UNLINK_MEMBER, payload);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// ----------🔍SEARCH_BY----------
// useSelector((state) => state.family.currentFamily);

// ✔️ 🔍 ⌛ ☠️ ✋ 👉 ❗ 📌 ❌

// ✋ HOW_TO_INTEGRATED?
// 1. First check API which API is used for what?
// 2. Which firebase function replace with REST_APIs and how many places?
// 3. Check UI Module/Functionality...

// 📌 SOME_FUNCTIONS STILL EXIST FOR PAID_FAMILY...

// ✋ NEW_FEATURES/UI_CHANGES
// (1)
// API_NAME   : Search by primaryEmail
// UI_CHANGES : (Member > Children > RightDrawer > UI Will be change here)

// (2)
// API_NAME   : Link/Unlink member to non-api child
// UI_CHANGES : (Member > Children > RightDrawer > UI Will be change here)

// (3)
// API_NAME   : NEW, INVITED, ACTIVE MEMBER
// UI_CHANGES : REGISTRATION FLOW (UI CHANGES AS PER DOCS.)

// (4)
// API_NAME   : NEW (No Child), INVITED (Child Compulsory), ACTIVE (Child + Credentials) MEMBER
// UI_CHANGES : Student List > Add Student (New Button Added)

// (5)
// UI_CHANGES : Student List > Status Dropdown (Dropdown item added),
// UI_CHANGES : Student List > New Column [UnlinkDate, Reason]

// 👉 FIREBASE_REPLACEMENT
// firebase.isPrimaryEmailExist : isMemberExist
// firebase.getFamilies : getMembers
// firebase.getFamilyById : getMember
// firebase.setFamily : createMember
// firebase.updateFamily : updateMember
// firebase.getFamilyBySchoolIdCalendarHost : Get student-Member (School & year wise)
// firebase.getFamiliesByStatus : Get student-Member (School & year wise)
// firebase.approveFamiliesById : approveMultipleMembers
// firebase.deleteNonActiveFamiliesById : deleteMultipleMembers
// firebase.deleteFamily : deleteMember
// firebase.updateFamilyByGroupId : UN_USED

// ❌ DELETED_APIS
// 1. /admin/families/free/counts
// 2. /admin/families/free/:accountStatus ---NEW--> /admin/members/:accountStatus
// 3. /admin/families/free/members        ---NEW--> /admin/students-members/:schoolId
// 4. /admin/child/updateGroup
