import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function CalendarIcon({ color, ...props }) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <path
            transform="matrix(1, 0, 0, 1, 0, 0)"
            id="prefix__a"
            d="M16.304 0c.513 0 .936.386.994.883l.006.117v1.295h2.122c2.142 0 3.891 1.685 3.995 3.801l.005.2v13.13c0 2.21-1.79 4-4 4H4c-2.21 0-4-1.79-4-4V6.296c0-2.21 1.79-4 4-4l2.121-.001V1c0-.552.448-1 1-1 .514 0 .936.386.994.883L8.122 1 8.12 2.295h2.592V1c0-.552.448-1 1-1 .513 0 .936.386.993.883l.007.117v1.295h2.591V1c0-.552.448-1 1-1zM6.121 4.295H4c-1.105 0-2 .896-2 2v13.131c0 1.105.895 2 2 2h15.426c1.105 0 2-.895 2-2V6.296c0-1.105-.895-2-2-2l-2.122-.001v1.296c0 .553-.447 1-1 1-.512 0-.935-.386-.993-.883l-.007-.117V4.295h-2.591v1.296c0 .553-.448 1-1 1-.513 0-.935-.386-.993-.883l-.007-.117V4.295H8.121v1.296c0 .553-.447 1-1 1-.512 0-.935-.386-.993-.883l-.006-.117-.001-1.296zm5.592 4.888c.513 0 .936.386.993.883l.007.117v2.825h2.826c.552 0 1 .448 1 1 0 .514-.386.936-.883.994l-.117.007-2.826-.001v2.827c0 .552-.448 1-1 1-.513 0-.935-.386-.993-.884l-.007-.116v-2.827H7.887c-.552 0-1-.447-1-1 0-.512.386-.935.883-.993l.117-.006 2.826-.001v-2.825c0-.553.448-1 1-1z"
          />
        </mask>
        <g
          fill={color || '#474747'}
          mask="url(#prefix__b)"
          transform="matrix(1.386518, 0, 0, 1.347865, 3.994385, 4.727264)"
        >
          <path d="M0 0H24V24H0z" />
        </g>
      </g>
    </SvgIcon>
  );
}
export default CalendarIcon;
