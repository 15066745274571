import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Button,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import FullPage from '../../layout/FullPage';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withFirebase } from '../../../utils/firebase';
import { loaderStop, loaderStart } from '../../../redux/actions/appActions';

// SCHEMA VALIDATOR FOR RESET PASSWORD FORM
const resetPasswordFormSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required')
});

const useStyles = makeStyles(() => ({
  containerBox: {
    minHeight: 400,
    padding: '20px 0'
  },
  contentPadding: {
    paddingLeft: 30,
    paddingRight: 30
  },
  backAction: {
    paddingLeft: 15
  },
  content: {
    background: 'url(/static/images/footer-hand.png) bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPositionY: '95%'
  }
}));

function ResetPasswordView({ firebase }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorFromFirebase, setErrorFromFirebase] = useState('');
  const [open, setOpen] = useState(false);

  const _handleFormSubmit = useCallback(
    (submittedFormFieldValues) => {
      const { email } = submittedFormFieldValues;
      setErrorFromFirebase(false);
      dispatch(loaderStart());
      firebase
        .doPasswordReset(email)
        .then(() => {
          localStorage.setItem('emailID', email);
          setOpen(true);
          dispatch(loaderStop());
        })
        .catch((error) => {
          console.log('error', error);
          const errorMessage = _.get(error, 'message', '');
          setErrorFromFirebase(errorMessage);
          dispatch(loaderStop());
        });
    },
    [firebase, dispatch]
  );

  if (open) {
    return (
      <FullPage cardContentProps={{ className: classes.content }}>
        <div className={classes.containerBox}>
          <Typography
            variant="h2"
            color="textPrimary"
            gutterBottom={true}
            className={classes.contentPadding}
          >
            Instructions Sent!
          </Typography>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.contentPadding}
          >
            Check your account email for instructions on how to reset your
            password.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            className={classes.backAction}
          >
            <IconButton
              aria-label="back button"
              onClick={() => history.push('/login')}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography>Sign In</Typography>
          </Box>
        </div>
      </FullPage>
    );
  }

  return (
    <FullPage>
      <Typography variant="h2" color="textPrimary" gutterBottom={true}>
        Reset Password
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        Enter your account email address below and reset instructions will be
        sent to you.
      </Typography>
      <Formik
        initialValues={{
          email: ''
        }}
        validationSchema={resetPasswordFormSchema}
        onSubmit={(values) => {
          _handleFormSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => {
          let isSubmitButtonDisabled = _.isEmpty(values.email);
          if (
            !_.isEmpty(touched) &&
            !isSubmitButtonDisabled &&
            !_.isEmpty(errors)
          ) {
            isSubmitButtonDisabled = true;
          }
          return (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                error={
                  Boolean(touched.email && errors.email) ||
                  !_.isEmpty(errorFromFirebase)
                }
                fullWidth
                // autoFocus
                helperText={touched.email && errors.email}
                onChange={(e) => {
                  setErrorFromFirebase(false);
                  handleChange(e);
                }}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                type="email"
                value={values.email}
                variant="outlined"
                autoComplete="off"
                style={{ marginTop: 31 }}
              />
              {errorFromFirebase && (
                <Box ml={2} mb={4}>
                  <FormHelperText error>{errorFromFirebase}</FormHelperText>
                </Box>
              )}
              <Box mt={2} style={{ marginTop: 23 }}>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color={'primary'}
                  style={{ height: 50 }}
                >
                  Submit
                </Button>
                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </Box>
            </form>
          );
        }}
      </Formik>
      <Box
        mt={5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 64, marginBottom: 10 }}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="back button"
            onClick={() => history.push('/login')}
            style={{ height: 50 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography>I remembered it. Back to Login.</Typography>
        </Box>
      </Box>
    </FullPage>
  );
}

export default withFirebase(ResetPasswordView);
