import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { colors } from 'src/utils/constant';
import { DrawerClose } from 'src/assets/Icons';

const useStyles = makeStyles({
  paperBox: {
    margin: 5,
    borderRadius: 5,
    height: 'calc(100% - 10px)',
    overflowY: 'inherit'
  },
  rootBackground: {
    backgroundColor: 'rgba(76, 91, 104, 0.6)'
  },
  list: {
    width: (props) => props.width || 580,
    maxWidth: '100%',
    overflow: 'auto'
  },
  closeIcon: {
    height: 50,
    width: 50,
    padding: 18,
    '&:hover': {
      transform: 'rotate(90deg)',
      transition: 'transform 550ms ease',
      fill: colors.primary
    }
  },
  rotate: {
    left: -60,
    top: 5,
    backgroundColor: '#ffffff',
    borderRadius: 25,
    position: 'absolute',
    cursor: 'pointer',
    height: 50,
    width: 50,
    boxShadow: '0 3px 5px -3px #000'
  }
});

/**
 *
 * @param {children} Components
 * @param {width} Number
 * @param {isOpen} Bool
 * @param {onClose} Callback
 */

export default function RightDrawer({ children, width, ...props }) {
  const classes = useStyles({ width });
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(props.isOpen);
  }, [props.isOpen]);

  const toggleDrawer =
    () =>
    (event) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift' || event.key === 'Escape')
      ) {
        return;
      }
      props.onClose(false);
    };

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
        classes={{ paper: classes.paperBox, root: classes.rootBackground }}
      >
        <div className={classes.rotate}>
          <DrawerClose
            className={classes.closeIcon}
            onClick={() => props.onClose(false)}
          />
        </div>
        <div
          className={clsx(classes.list, props.className)}
          role="presentation"
        >
          {children}
        </div>
      </Drawer>
    </React.Fragment>
  );
}
