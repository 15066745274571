/* eslint-disable camelcase */
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Link,
  Paper,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { RightArrow } from 'src/assets/Icons';
import Dashboard from 'src/views/layout/Dashboard';
import api_icon from 'src/assets/apiIcon.svg';
import schoolBytes from 'src/assets/schoolByte2.webp';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { routes } from 'src/utils/constant';
import NavTab from '../NavTab';
import RightDrawer from 'src/components/RightDrawer';
import { Editor } from 'react-draft-wysiwyg';
import { useState } from 'react';
import { useCallback } from 'react';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { ContentState } from 'draft-js';
import { EditorState } from 'draft-js';
import {
  addMessage,
  loaderStart,
  loaderStop
} from 'src/redux/actions/appActions';
import { setSchool } from 'src/redux/actions/schoolActions';
import { withFirebase } from 'src/utils/firebase';
import { Edit, HandBook } from 'src/assets';
import { useEffect } from 'react';
import studentReportingIcon from 'src/assets/icn-student-reporting.svg';
import {
  addSchoolFormDetailsOrUpdateSchool,
  getSchoolByID
} from 'src/services/schools';
import { schoolGroupWrapperStyles } from 'src/styles/schoolStyle/groupStyle/schoolGroupWrapperStyles';
import { MESSAGE_TYPES } from 'src/constants/common';

// eslint-disable-next-line no-unused-vars
const SchoolWrapper = ({ firebase, children }) => {
  const classes = schoolGroupWrapperStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const prefillSchoolData = useSelector((state) => state.school.currentSchool);
  const isLoading = useSelector((state) => state.app.isLoading);
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [memberData, setMemberData] = useState(false);
  const [defaultNote, setDefaultNote] = useState('');
  const [openStudentReportPopUp, setOpenStudentReportPopUp] = useState(false);
  const schoolID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  const { schoolName } = useMemo(() => {
    if (prefillSchoolData) {
      return {
        schoolName: prefillSchoolData.schoolName,
        id: prefillSchoolData.id
      };
    }
    return {
      schoolName: '',
      id: ''
    };
  }, [prefillSchoolData]);

  useEffect(() => {
    if (!prefillSchoolData.schoolName && schoolID) {
      dispatch(loaderStart());
      // PHASE2/CHANGES/SCHOOL

      getSchoolByID(schoolID)
        .then(async (school) => {
          if (school) {
            dispatch(setSchool(school));
          }
          dispatch(loaderStop());
        })
        .catch((error) => {
          console.error(error);
          dispatch(addMessage('Error on getting school', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    }
  }, [prefillSchoolData.schoolName, schoolID, dispatch]);

  const onClose = useCallback(() => {
    setEditMode(false);
    setOpenDrawer(false);
  }, [setOpenDrawer]);

  const openRightDrawer = useCallback(
    (data) => {
      setMemberData(data);
      const contentBlock = htmlToDraft(data.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      setOpenDrawer(true);
    },
    [setOpenDrawer, prefillSchoolData]
  );

  const handleClose = useCallback(() => {
    setEditMode(false);
  }, []);

  const onCloseForm = () => {
    const defaultData = draftToHtml(
      convertToRaw(defaultNote.getCurrentContent())
    );
    const memberNotes = memberData.notes;
    if (defaultData === memberNotes) {
      // handleClose();
      onClose();
    } else if (
      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
      !memberNotes
    ) {
      onClose();
    } else if (memberNotes === '') {
      // handleClose();
      onClose();
    }
  };

  const onSaveChanges = async () => {
    // PHASE2/CHANGES/SCHOOL
    dispatch(loaderStart());

    const payload = {
      notes: memberData?.notes
    };

    try {
      await addSchoolFormDetailsOrUpdateSchool(prefillSchoolData.id, payload);
      dispatch(loaderStop());
      dispatch(setSchool(payload));
      dispatch(addMessage('Changes saved'));
      const contentBlock = htmlToDraft(payload?.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      handleClose();
    } catch (error) {
      console.error(error);
      dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  const onEditorChange = (value) => {
    const editorData = draftToHtml(convertToRaw(value.getCurrentContent()));
    setMemberData({ ...memberData, notes: editorData });
  };

  const navTabsOptions = useMemo(() => {
    return [
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Calendar',
              link: `${routes.schoolCalendar}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Groups',
        link: `${routes.schoolGroup}/${schoolID}`
      },
      {
        label: 'Students',
        link: `${routes.schoolStudent}/${schoolID}`
      },
      {
        divider: true
      },
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Links',
              link: `${routes.schoolLinks}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Notes',
        handleTabClick: () => openRightDrawer(prefillSchoolData)
      },
      {
        label: 'Files',
        link: `${routes.schoolFiles}/${schoolID}`
      },
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Form',
              link: `${routes.schoolForm}/${schoolID}`
            }
          ]
        : []),
      {
        divider: true
      },
      {
        label: 'Account',
        link: `${routes.schoolAccount}/${schoolID}`
      }
    ];
  }, [prefillSchoolData]);

  return (
    <Dashboard>
      <div className={classes.root}>
        <div>
          <Typography
            variant="body1"
            className={classes.bredthcrumbTitle}
            onClick={() => history && history.push(routes.schools)}
          >
            Schools
          </Typography>
          <RightArrow
            viewBox="0 0 24 24"
            height={24}
            width={24}
            style={{ fontSize: 22, verticalAlign: 'middle' }}
          />
        </div>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20, marginTop: 22 }}
        >
          {schoolName}
        </Typography>

        <Paper style={{ position: 'relative' }}>
          <img
            className={classes.reportImage}
            src={studentReportingIcon}
            alt="Student report"
            onClick={() => setOpenStudentReportPopUp(!openStudentReportPopUp)}
          />
          {openStudentReportPopUp ? (
            <article className={classes.studentReportPopUp}>
              <div style={{ fontWeight: 500, marginBottom: '10px' }}>
                Student reporting
              </div>
              {prefillSchoolData?.createdByApiSync ? (
                <div className={classes.flexBoxBetween}>
                  <span>Population</span>
                  <span>
                    {prefillSchoolData?.studentReport?.apiStudent || 0}
                  </span>
                </div>
              ) : null}
              <div className={classes.flexBoxBetween}>
                <span>Active</span>
                <span>{prefillSchoolData?.studentReport?.active || 0}</span>
              </div>
              <div className={classes.flexBoxBetween}>
                <span>Inactive</span>
                <span>{prefillSchoolData?.studentReport?.inActive || 0}</span>
              </div>
              {prefillSchoolData?.createdByApiSync ? (
                <div className={classes.flexBoxBetween}>
                  <span>Opportunity</span>
                  <span>
                    {prefillSchoolData?.studentReport?.nonRegistered || 0}
                  </span>
                </div>
              ) : null}
            </article>
          ) : null}
          <section
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 20px 0'
            }}
          >
            <div>
              <NavTab
                tabs={navTabsOptions}
                selectedTab={isOpenDrawer ? 'Notes' : 'Groups'}
              />
            </div>
            {prefillSchoolData && prefillSchoolData?.createdByApiSync && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '1rem'
                }}
              >
                <img src={schoolBytes} height="38px" />
                <img src={api_icon} />
              </div>
            )}
          </section>
          <Divider />
          {children}
        </Paper>
      </div>

      <RightDrawer
        isOpen={isOpenDrawer}
        onClose={() => {
          onClose();
          onCloseForm();
        }}
        width={900}
        style={{ overflowX: 'hidden' }}
      >
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <div className={classes.drawerLeftContent}>
          <div className={classes.leftContentMargin}>
            <div className={classes.leftContentHeader}>
              {isEditMode ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.siderEdit}
                  onClick={() => {
                    const defaultData = draftToHtml(
                      convertToRaw(defaultNote.getCurrentContent())
                    );
                    const memberNotes = memberData.notes;
                    if (defaultData === memberNotes) {
                      handleClose();
                    } else if (
                      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
                      !memberNotes
                    ) {
                      handleClose();
                    } else {
                      onSaveChanges();
                    }
                  }}
                  style={{ width: 165 }}
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.siderEdit}
                  onClick={() => setEditMode(true)}
                  startIcon={<img src={Edit} />}
                >
                  Edit
                </Button>
              )}
            </div>
            <Divider
              variant="fullWidth"
              style={{ marginBottom: 20, marginTop: 22 }}
            />
            {!isEditMode && !memberData.notes && (
              <Typography className={classes.siderDetail}>
                Click Edit to add a note...
              </Typography>
            )}
            {!isEditMode &&
              memberData.notes &&
              memberData.notes.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' && (
                <Typography className={classes.siderDetail}>
                  Click Edit to add a note...
                </Typography>
              )}
            <Editor
              stripPastedStyles={true}
              readOnly={!isEditMode}
              defaultEditorState={defaultNote}
              toolbarClassName={
                !isEditMode ? classes.toolbarHidden : classes.toolbar
              }
              onEditorStateChange={onEditorChange}
              editorClassName={
                !isEditMode ? classes.textEditor : classes.textEditorOnEdit
              }
              toolbar={{
                options: ['inline', 'list', 'link'],
                inline: {
                  options: ['bold', 'italic', 'underline', 'strikethrough']
                },
                list: {
                  options: ['unordered']
                },
                link: {
                  defaultTargetOption: '_blank',
                  options: ['link']
                }
              }}
            />
          </div>
        </div>
        <div className={classes.drawerRightContent}>
          <div style={{ padding: 18, zIndex: 1 }}>
            <div className={classes.header}>
              {memberData.schoolName && (
                <Typography
                  className={classes.textwraplabel}
                  title={memberData.schoolName}
                >
                  {memberData.schoolName}
                </Typography>
              )}
            </div>
            <Divider style={{ marginBottom: 20 }} />
            {memberData.country && (
              <>
                <Typography className={classes.heading}>COUNTRY</Typography>
                <Typography className={classes.siderDetail}>
                  {memberData.country}
                </Typography>
              </>
            )}
            {memberData.websiteUrl && (
              <>
                <Typography className={classes.heading}>WEBSITE</Typography>
                <Link
                  color="secondary"
                  component={Link}
                  onClick={() => window.open(memberData.websiteUrl, '_blank')}
                  className={classes.siderDetail}
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                  Click Here
                </Link>
              </>
            )}
          </div>
          <div className={classes.handBookContainer}>
            <img src={HandBook} alt="" className={classes.handBookClass} />
          </div>
        </div>
      </RightDrawer>
    </Dashboard>
  );
};

export default withFirebase(SchoolWrapper);
