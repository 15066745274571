import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';

const persistConfig = {
  key: 'root_mywhanau',
  storage: storage,
  whitelist: ['auth'] // which reducer want to store
};
const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

const pReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(...middlewares);
const store = createStore(pReducer, middleware);
const persistor = persistStore(store);

export { persistor, store };
