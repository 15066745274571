import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { StepConnector } from '@material-ui/core';
import { colors } from 'src/utils/constant';
import SuccessComponent from '../components/SuccessComponent';
const useStyles = makeStyles((theme) => ({
  rootContainer: {
    width: '100%',
    height: '100%'
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',
    boxShadow: 'none'
  },
  stepContent: {
    margin: 0,
    padding: 0,
    border: 'none'
  },
  stepperRoot: {
    padding: '30px 40px'
  },
  stepperConnectorRoot: {
    border: 'none',
    minHeight: 0
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '17.8%'
  },
  active: {
    '& $line': {
      borderColor: '#784af4'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#784af4'
    }
  },
  activeStepLabel: {
    height: 22,
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.57,
    letterSpacing: 'normal',
    color: colors.primaryDarkGrey
  },
  label: {
    width: 160,
    height: 22,
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.57,
    letterSpacing: 'normal',
    color: colors.primaryLightGrey,
    cursor: 'pointer'
  },
  cancelButton: {
    width: 106,
    height: 50,
    borderRadius: 3,
    border: 1,
    borderStyle: 'solid',
    backgroundColor: colors.secondaryGreyLight,
    borderColor: colors.primaryLightGrey,
    margin: '44px 0px',    
    marginLeft: '37px',
  },
  primaryButton: {
    width: 106,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginLeft: '15%',
    marginTop: '5.5%',
    color: colors.white
  },
  buttonText: {
    height: 24,
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center'
  },
  iconStyle: {
    height: 48,
    width: 48,
    position: 'relative',
    borderRadius: 50,
    backgroundColor: '#8bc517'
  },
  activeIcon: {
    backgroundColor: '#8bc517'
  },
  inactiveIcon: {
    backgroundColor: '#bfc5d1'
  },
  image: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: '25%',
    left: '25%',
    tintColor: 'white'
  },
  line: {
    width: 1,
    height: 50,
    border: 1,
    borderStyle: 'solid',
    borderColor: '#eeeeee',
    marginLeft: 24
  },
  connectorRoot: {
    padding: 0,
    margin: 0
  },
  displayNone: {
    display: 'none'
  }
}));

export default function StepperComponent(props) {
  const classes = useStyles();
  const {
    title,
    steps,
    getStepContent,
    colorlibStepIcon,
    successTitle,
    subTitle,
    iconImage,
    buttonLabel,
    listUrl,
    createUrl,
    handleReset,
    handleCancel,
    activeStep,
    showSteps = true,
    isCancelButton,
    stepClick
  } = props;

  return (
    <div className={classes.rootContainer}>
      {activeStep !== steps.length && (
        <Paper className={classes.root}>
          <div
            className={!showSteps ? classes.displayNone : null}
            style={{ float: 'left', width: '23.1%' }}
          >
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              connector={
                <StepConnector
                  classes={{
                    line: classes.line,
                    root: classes.connectorRoot
                  }}
                />
              }
              classes={{ root: classes.stepperRoot }}
            >
              {steps.map((label, index) => (
                <Step
                  key={label}
                  onClick={() => {
                    if (activeStep > index) {
                      stepClick && stepClick(index);
                    }
                  }}
                >
                  <StepLabel
                    StepIconComponent={colorlibStepIcon}
                    classes={{
                      active: classes.activeStepLabel,
                      label: classes.label
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {isCancelButton && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button className={classes.cancelButton} onClick={handleCancel}>
                  <div
                    className={clsx(classes.buttonText, {
                      color: colors.primaryDarkGrey
                    })}
                  >
                    Cancel
                  </div>
                </Button>
              </div>
            )}
          </div>
          <div style={{ float: 'right', width: '76.9%' }}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              connector={
                <StepConnector
                  classes={{
                    vertical: classes.stepContent,
                    lineVertical: classes.stepperConnectorRoot
                  }}
                />
              }
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  {activeStep === index ? (
                    <StepContent classes={{ root: classes.stepContent }}>
                      <div>{getStepContent(index)}</div>
                    </StepContent>
                  ) : (
                    ''
                  )}
                </Step>
              ))}
            </Stepper>
          </div>
        </Paper>
      )}
      {activeStep === steps.length && (
        <SuccessComponent
          mainTitle={title}
          title={successTitle}
          subTitle={subTitle}
          iconImage={iconImage}
          buttonLabel={buttonLabel}
          listUrl={listUrl}
          createUrl={createUrl}
          handleReset={handleReset}
        />
      )}
    </div>
  );
}
