/* eslint-disable camelcase */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
// import moment from 'moment';

// Material-UI Components
import { Paper, Button, Divider, Grid, Typography } from '@material-ui/core';

// Assets
import { membersUsersViewStyles } from 'src/styles/membersStyle/membersUsersViewStyles';
import api_icon from 'src/assets/apiIcon.svg';
import schoolBytes from 'src/assets/schoolByte2.webp';
import { RightArrow } from 'src/assets/Icons';
import { Add as AddIcon } from '@material-ui/icons';

// Constants
// import { STUDENTS_OF_MEMBER } from 'src/constants/table';
// import {
//   DEFAULT_PAGE,
//   DEFAULT_PAGE_SIZE,
//   DEFAULT_ROWS_PER_PAGE_OPTIONS,
//   SEARCH_DEBOUNCE_TIME
// } from 'src/constants/common';

// Layouts
import Dashboard from '../layout/Dashboard';

// Components
import CreateEditStudent from '../schools/students/CreateEditStudent';
import CardComponent from './components/CardComponent';
// import Table from 'src/components/Table';

// Redux-Actions
import {
  loaderStop,
  loaderStart,
  addMessage
} from 'src/redux/actions/appActions';
import { setFamily } from 'src/redux/actions/familyActions';

// Services
import { getMember } from 'src/services/members';
import { getGroupBySchoolId } from 'src/services/groups';
import { getSchoolByID, getSchools } from 'src/services/schools';
import { MESSAGE_TYPES } from 'src/constants/common';

// Utils
import { colors, routes, year as DefaultYear } from 'src/utils/constant';
import { childSchema } from 'src/utils/schemas';
import { withFirebase } from 'src/utils/firebase';
import {
  createStudent,
  getStudent,
  updateStudent
} from 'src/services/students';

const AccountsCardDataFormat = {
  headerLink: 'left',
  buttonTextHover: 'Manage',
  headerLinkText: 'Primary',
  linkColor: '#FFFFFF',
  displayParent: 1,
  nameToupper: 1
};

const AccountsCardDataFormatPaid = {
  headerLink: 'left',
  buttonTextHover: 'Manage',
  headerLinkText: 'Primary',
  linkColor: '#FFFFFF',
  bordered: 1,
  displayParent: 0,
  nameToupper: 1
};

function MembersUsersView() {
  const classes = membersUsersViewStyles();
  const history = useHistory();

  const [yearsData, setYearsData] = useState();
  const [groups, setGroups] = useState([]);
  const [isOpenStudentDrawer, setIsOpenStudentDrawer] = useState(false);
  const [categories, setCategories] = useState();
  const [currentStudent, setCurrentStudent] = useState();
  const [isEdit, setIsEdit] = useState(false);
  // const [debouncedSearchStudent, setDebouncedSearchStudent] = useState('');
  // const [page, setPage] = useState(DEFAULT_PAGE);
  // const [tableData, setTableData] = useState([]);
  // const [totalRecords, setTotalRecords] = useState(0);
  // const [searchStudent, setSearchStudent] = useState('');
  // const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  // const [selectedRows, setSelectedRows] = useState([]);
  const [schools, setSchools] = useState([]);

  const currentFamily = useSelector((state) => state.family.currentFamily);

  const dispatch = useDispatch();

  // const getDebouncedSearchValue = useCallback(
  //   _.debounce((debouncedValue) => {
  //     setDebouncedSearchStudent(debouncedValue);
  //   }, SEARCH_DEBOUNCE_TIME),
  //   []
  // );

  // useEffect(() => {
  //   if (schools.length > 0) {
  //     const data = currentFamily.childs.map((d) => {
  //       const school = schools.find((s) => s.id === d.school);
  //       return { ...d, schoolName: school.name };
  //     });
  //     setTableData(data);
  //     setTotalRecords(data.length);
  //   }
  // }, [currentFamily, schools]);

  // useEffect(() => {
  //   console.log('debouncedSearchStudent', debouncedSearchStudent);
  //   console.log('page', page);
  // }, [debouncedSearchStudent, page]);

  useEffect(() => {
    // PHASE2/CHANGES/SCHOOL

    getSchools()
      .then((result) => {
        const schools = [];
        result.forEach((school) => {
          const yearData = school.freeCalendars.map((calendar) => {
            const getCurrentYear = _.find(
              DefaultYear,
              (elem) => elem.value === calendar
            );
            return {
              label: getCurrentYear.label,
              value: calendar
            };
          });
          schools.push({
            id: school.id,
            name: school.schoolName,
            years: yearData.length ? yearData : []
          });
        });
        setSchools(schools);
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          addMessage(
            'Server connection issue. Please refresh',
            MESSAGE_TYPES.ERROR
          )
        );
      });
  }, []);

  const openRightDrawer = useCallback(
    async (data) => {
      if (data?.id) {
        setIsEdit(true);
        dispatch(loaderStart());
        fetchYearsOnSchoolChange(data.school, false);

        getStudent(data?.id)
          .then((response) => {
            const result = response.data;
            const student = {
              id: result?.id,
              firstName: result?.firstName,
              lastName: result?.lastName,
              school: result?.school,
              year: result?.year,
              groups: result?.groups?.map((g) => g.id),
              memberReferences: result?.memberReferences,
              createdByApiSync: result?.createdByApiSync,
              familyId: currentFamily.id,
              planType: currentFamily.planType
            };
            setCurrentStudent(student);
            setIsOpenStudentDrawer(true);
            dispatch(loaderStop());
          })
          .catch((error) => {
            console.log('error', error);
            dispatch(loaderStop());
          });
      } else {
        setIsEdit(false);
        setIsOpenStudentDrawer(true);
      }
    },
    [setIsOpenStudentDrawer, setCurrentStudent, currentFamily]
  );

  const familyID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  useEffect(() => {
    fetchFamilyData();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchFamilyData = useCallback(() => {
    dispatch(loaderStart());
    getMember(familyID)
      .then((response) => {
        const familyData = response?.data;
        if (familyData) {
          dispatch(setFamily({ ...familyData, planType: 'Free' }));
        }
        dispatch(loaderStop());
      })
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchYearsOnSchoolChange = useCallback(
    async (schoolId, shouldStartLoader) => {
      // PHASE2/CHANGES/SCHOOL
      try {
        const school = await getSchoolByID(schoolId);
        const yearsData = DefaultYear.filter((y) =>
          school?.freeCalendars?.includes(y.value)
        );
        setYearsData(yearsData);
        const categories = [];
        school.categories.forEach((category) => {
          categories.push(category);
        });
        setCategories(categories);
        fetchGroups(school.id, shouldStartLoader);
      } catch (error) {
        dispatch(loaderStop());
        console.error(error);
      }
    }
  );

  const fetchGroups = async (schoolId, shouldStartLoader = true) => {
    try {
      shouldStartLoader && dispatch(loaderStart());
      const result = await getGroupBySchoolId(schoolId);
      const groupsList = [];
      result.data.forEach((item) => {
        groupsList.push({
          key: item.groupName,
          value: item.id,
          years: item.years,
          selected: false,
          groupMembers: item.groupMembers,
          category: item.category ? [item.category] : [],
          createdByApiSync: item.createdByApiSync || false,
          autoAssignedMembers: item?.autoAssignedMembers
        });
      });
      if (groupsList.length > 0) {
        groupsList.push({
          key: 'Select All',
          value: 'all',
          selected: false
        });
      }
      setGroups(groupsList);
      dispatch(loaderStop());
    } catch (error) {
      console.log('Error getting documents: ', error);
      dispatch(
        addMessage(
          'Server connection issue. Please refresh',
          MESSAGE_TYPES.ERROR
        )
      );
      dispatch(loaderStop());
    }
  };

  const handleUpdateChild = (studentData, groupData, student) => {
    if (student) {
      const formData = studentData
      // UPDATE FLOW...
      getMember(familyID)
        .then(async (response) => {
          const familyData = response.data;
          if (familyData) {
            const selectedChildData = familyData?.childs?.find(
              (child) => child.id === student.id
            );

            const selectedGroups = groupData?.map((g) => ({
              groupId: g?.value,
              isAdd: g?.selected
            }));

            const studentUpdatePayload = {
              firstName: formData?.firstName,
              lastName: formData?.surname,
              year: formData?.year,
              ...(selectedChildData?.color
                ? { color: selectedChildData?.color }
                : {}),

              school: formData?.school,
              groups: selectedGroups
            };
            // Update family
            updateStudent(selectedChildData?.id, studentUpdatePayload).then(
              () => {
                dispatch(
                  addMessage(
                    `Child ${isEdit ? 'updated' : 'created'} successfully`
                  )
                );
                handleCloseStudentDrawerClose();
                dispatch(loaderStop());
                fetchFamilyData();
              }
            );
          }
        })
        .catch((err) => {
          handleCloseStudentDrawerClose();
          dispatch(loaderStop());
          console.log('error', err);
          dispatch(
            addMessage(`Error on ${isEdit ? 'updating' : 'creating'} a child`)
          );
        });
    } else {
      const formData = {
        ...studentData,
        memberIds: studentData?.memberIds?.length ? [...new Set([...studentData.memberIds, familyID])] : [familyID]
      }  
      createStudent({
        firstName: formData?.firstName,
        lastName: formData?.surname,
        year: formData?.year,
        school: formData?.school,
        ...(formData?.memberIds ? { memberIds: formData?.memberIds } : {}), // optional
        groupIds: groupData?.filter((g) => g.selected).map((g) => g?.value)
      })
        .then((response) => {
          console.log('response', response);
          dispatch(
            addMessage(`Child ${isEdit ? 'updated' : 'created'} successfully`)
          );
          handleCloseStudentDrawerClose();
          dispatch(loaderStop());
          fetchFamilyData();
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(loaderStop());
          dispatch(
            addMessage(
              `Error on ${isEdit ? 'updating' : 'creating'} a child`,
              MESSAGE_TYPES.ERROR
            )
          );
        });
    }
  };

  const handleCloseStudentDrawerClose = (isUpdate) => {
    setIsOpenStudentDrawer(false);
    setCurrentStudent();
    setIsEdit(false);
    setCategories();

    if (isUpdate) {
      fetchFamilyData();
    }
  };

  // const handleChangePage = (pageNo) => {
  //   setPage(pageNo);
  // };

  // const handleChangeRowsPerPage = (rowsLimit) => {
  //   setRowsPerPage(rowsLimit);
  // };

  // const handleRowCheckBox = (dataId, checked) => {
  //   if (checked) {
  //     setSelectedRows((prevState) => {
  //       return !prevState.includes(dataId) ? [...prevState, dataId] : prevState;
  //     });
  //   } else {
  //     setSelectedRows((prevState) => {
  //       return prevState.includes(dataId)
  //         ? [...prevState].filter((id) => dataId !== id)
  //         : prevState;
  //     });
  //   }
  // };

  // const handleClickRow = (dataId) => {
  //   const clickedRowData = tableData?.find((d) => d.id === dataId);
  //   openRightDrawer(clickedRowData);
  // };

  // const handleSearch = (searchText) => {
  //   getDebouncedSearchValue(searchText);
  //   setSearchStudent(searchText);
  // };

  // const handleGlobalCheckBox = (checked) => {
  //   if (checked) {
  //     setSelectedRows(tableData.map((d) => d.id));
  //   } else {
  //     setSelectedRows([]);
  //   }
  // };

  // const handleRenderRow = (key, data) => {
  //   const getRenderData = (key, data) => {
  //     switch (key) {
  //       case 'year':
  //         return data === '-1'
  //           ? 'Pre K'
  //           : data === '0'
  //           ? 'Kindy'
  //           : `Year ${data}`;
  //       case 'createdDate':
  //         return moment(data).tz('Australia/Sydney').format('DD MMM YY');
  //       default:
  //         return data;
  //     }
  //   };

  //   return <span className={classes.surname}>{getRenderData(key, data)}</span>;
  // };

  return (
    <Dashboard>
      <div className={classes.root}>
        <div>
          <Typography
            variant="body1"
            className={classes.bredthcrumbTitle}
            onClick={() => history && history.push(routes.membersActive)}
          >
            Members
          </Typography>
          <RightArrow
            viewBox="0 0 24 24"
            height={24}
            width={24}
            style={{ fontSize: 22, verticalAlign: 'middle' }}
          />
        </div>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20, marginTop: 22 }}
        >
          {`${currentFamily.firstName} ${currentFamily?.lastName}`}
        </Typography>
        <Paper className={classes.tabNavigations}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.memberCalendar + '/' + familyID)
                  : history.push(routes.familyCalendarPaid + '/' + familyID);
              }}
            >
              Calendar
            </Button>
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.membersUsers + '/' + familyID)
                  : history.push(routes.familyUsersPaid + '/' + familyID);
              }}
              className={classes.activeTab}
            >
              Children
            </Button>
            <span
              style={{
                borderRight: `1px solid ${colors.primary}`,
                margin: '0 8px',
                height: 20
              }}
            ></span>
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.memberAccount + '/' + familyID)
                  : history.push(routes.familyAccountPaid + '/' + familyID);
              }}
            >
              Account
            </Button>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '1rem',
                marginRight: 0,
                marginLeft: 'auto'
              }}
            >
              {currentFamily.apiSource?.includes('School Bytes') && (
                <img src={schoolBytes} height="38px" />
              )}
              {currentFamily.createdByApiSync && <img src={api_icon} />}
            </div>
          </div>
          <Divider />
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.actionButton}
            startIcon={<AddIcon />}
            onClick={() => openRightDrawer()}
          >
            New Child
          </Button>
        </Paper>
        <Grid container spacing={3}>
          {/* <Table
            columns={STUDENTS_OF_MEMBER}
            data={tableData}
            selectedRows={selectedRows}
            rowsPerPage={rowsPerPage}
            page={page}
            totalRecords={totalRecords}
            searchValue={searchStudent}
            rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
            renderRow={handleRenderRow}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangeGlobalCheckBox={handleGlobalCheckBox}
            onChangeRowCheckBox={handleRowCheckBox}
            onClickRow={handleClickRow}
            onSearch={handleSearch}
          /> */}

          {currentFamily.childs.map((cardData, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3}>
              <CardComponent
                cardData={{
                  ...childSchema,
                  ...cardData,
                  ...{ isParent: false },
                  ...{ index }
                }}
                format={
                  currentFamily.planType === 'Free'
                    ? AccountsCardDataFormat
                    : AccountsCardDataFormatPaid
                }
                onClick={openRightDrawer}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      {isOpenStudentDrawer && (
        <CreateEditStudent
          isOpen={isOpenStudentDrawer}
          onClose={handleCloseStudentDrawerClose}
          handleSchoolChange={fetchYearsOnSchoolChange}
          onUpdate={handleUpdateChild}
          schools={schools}
          currentSchoolId={''}
          years={yearsData}
          student={currentStudent}
          groups={groups}
          categories={categories}
          isEdit={isEdit}
          isCreateChild={!isEdit ? true : false}
        />
      )}
    </Dashboard>
  );
}

export default withFirebase(MembersUsersView);
