import moment from 'moment';
import * as Yup from 'yup';

export const entryValidationSchema = Yup.object().shape({
  id: Yup.string().trim(),
  label: Yup.string().trim().default('none').required('Label is required'),
  title: Yup.string().trim().required('Title is required'),
  location: Yup.string().trim(),
  description: Yup.string().trim(),
  for: Yup.string().trim().required('For is required'),
  from: Yup.object().required('From is required'),
  to: Yup.object()
    .required('To is required')
    .test(
      'date test',
      'From date needs to be before the To date',
      function () {
        const { from, to } = this.parent;
        return moment(to.date, 'MMM DD,YYYY').isSameOrAfter(
          moment(from.date, 'MMM DD,YYYY')
        );
      }
    ),
  allDay: Yup.boolean(),
  checkDescription: Yup.boolean(),
  actions: Yup.object().shape({
    repeat: Yup.array().of(Yup.object().shape({ type: Yup.string().trim() })),
    notification: Yup.array().of(
      Yup.object().shape({
        days: Yup.string().trim(),
        time: Yup.string().trim()
      })
    ),
    deadline: Yup.array(),
    reminder: Yup.array()
  }),
  newFromTimeFormate: Yup.object().when('allDay', {
    is: false,
    then: Yup.object({
      hh: Yup.string().trim().required('time is required'),
      mm: Yup.string().trim().required('time is required'),
      ap: Yup.string().trim().required('time is required')
    })
  }),
  newToTimeFormate: Yup.object().when('allDay', {
    is: false,
    then: Yup.object({
      hh: Yup.string().trim().required('time is required'),
      mm: Yup.string().trim().required('time is required'),
      ap: Yup.string().trim().required('time is required')
    })
  })
});
