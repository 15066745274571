// Login Success
export const loginSuccess = (user) => ({
  type: 'LOGIN_SUCCESS',
  user: user
});

// Login Fail
export const loginFail = (error) => ({
  type: 'LOGIN_FAIL',
  message: error
});

// Logout Success
export const logoutSuccess = () => ({
  type: 'LOGOUT_SUCCESS'
});

export const sessionInative = () => ({
  type: 'SESSION_INACTIVE'
});

// School List
export const getSchoolList = (schools) => ({
  type: 'GET_SCHOOL_LIST',
  schools
});
export const updateSchoolList = (school) => ({
  type: 'UPDATE_SCHOOL_LIST',
  school
});
// Family List
export const getFamilyList = (families) => ({
  type: 'GET_FAMILY_LIST',
  families
});