import React from 'react';
import dayjs from 'dayjs';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FILE_NOT_FOUND } from 'src/assets';
import {
  CheckCircleIcon,
  DownloadIcon,
  LinkIcon,
  TrashIcon
} from 'src/assets/Icons';
import _ from 'lodash';
import { useState } from 'react';
import clsx from 'clsx';
import { DatePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import { withFirebase } from 'src/utils/firebase';
import { loaderStart, loaderStop } from 'src/redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { formatFilename } from 'src/utils/functions';

const {
  TableRow,
  makeStyles,
  TableCell,
  Link,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogContent
} = require('@material-ui/core');
const { colors, copyFileUrl } = require('src/utils/constant');

const useStyles = makeStyles(() => ({
  tableRow: {
    height: '72px',
    '&:hover': {
      backgroundColor: `rgba(244, 245, 247, 0.51) !important`,
      cursor: 'pointer'
    }
  },
  firstDataTableCell: {
    borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
  dataTableCell: {
    color: colors.primaryDark,
    padding: 8,
    '&:first-child': {
      paddingLeft: '20px'
    },
    '&:last-child': {
      paddingLeft: '38px',
      verticalAlign: 'unset',
      paddingTop: '14px'
    }
  },
  entryModalDataTableCell: {
    '&:first-child': {
      paddingLeft: '25px'
    },
    '&:last-child': {
      paddingLeft: '14px',
      verticalAlign: 'unset',
      paddingTop: '14px'
    }
  },
  fileName: {
    display: 'grid',
    gridTemplateColumns: 'auto 305px',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: colors.primaryDark,
    '&:hover': {
      color: '#8bc517',
      '& .action-items': {
        opacity: 1
      }
    }
  },
  fileNameBodyContainer: {
    display: 'flex'
  },
  fileNameAndUpdateDateContainer: {
    display: 'grid',
    gridTemplateRows: '20px auto',
    minWidth: '410px',
    gap: '6px'
  },
  fileNameFormatContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  fileNameFormat: {
    width: 263,
    height: 22,
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.primaryDarkGrey,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 3
  },
  fileStatus: {
    fontSize: 14,
    backgroundColor: '#e8f5e9',
    color: '#348f37',
    padding: '0 5px',
    marginLeft: 28
  },
  siderDetail: {
    fontSize: 16,
    color: colors.primaryDarkGrey,
    marginBottom: 8,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    '&:hover': {
      color: '#8bc517'
    }
  },
  actionButtons: {
    opacity: 0,
    textAlign: 'right'
  },
  entryModalActionButtons: {
    marginTop: '5px'
  },
  buttonLabelText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1
  },
  actionIcons: {
    margin: '0 10px'
  },
  fileImageContainer: {
    width: 56,
    height: 56,
    padding: 16,
    borderRadius: 5,
    border: 'solid 1px #bfc5d1',
    backgroundColor: '#f0f0f0',
    position: 'relative'
  },
  fileImage: {
    width: 56,
    height: 56,
    borderRadius: 5,
    position: 'absolute',
    top: 0,
    left: 0
  },
  fileNotFoundImage: {
    width: 24,
    height: 24,
    margin: 16,
    objectFit: 'contain',
    opacity: 0.5
  },
  insertFileUrl: {
    marginRight: '30px'
  },
  iconStyle: {
    fontSize: 22,
    verticalAlign: 'middle'
  },
  dateText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.11,
    color: colors.primaryDark
  },
  setExpiryDateLink: {
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.primaryDark,
    textDecoration: 'underline'
  },
  formDateControlFromBordered: {
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: 3,
    padding: 0,
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-input': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10
    }
  },
  endOfYearDialogPaper: {
    background: 'none',
    boxShadow: 'none',

    '& .MuiPickersCalendar-transitionContainer': {
      minHeight: '230px'
    },
    '& .MuiPickersStaticWrapper-staticWrapperRoot': {
      borderRadius: '4px',
      boxShadow:
        '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)'
    }
  },
  endOfYearDialogContent: {
    padding: '30px 45px 8px',
    '&:first-child': {
      paddingTop: '30px'
    }
  },
  endOfYearButtonContainer: {
    marginTop: '35px',
    display: 'flex',
    justifyContent: 'center'
  },
  endOfYearButton: {
    width: '190px',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 16px',
    borderRadius: '1000px',
    backgroundColor: colors.white,
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: '0.1px',
    color: '#000'
  },
  setExpiryDateActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '140px',
    marginLeft: 'auto',
    marginRight: '13px'
  },
  newExpiryDateContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  newExpiryDateTitle: {
    color: 'red'
  },
  newExpiryDate: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.11,
    textDecoration: 'underline'
  }
}));

function FileTableRow({
  firebase,
  row,
  columns,
  deleteFile,
  setIsOpenModal,
  setActiveRowData,
  setCopyToChipboardSuccess,
  isCopyToChipboardSuccess,
  isEntryModal = false,
  entryModalData,
  handleInsertClick,
  getFiles
}) {
  const dispatch = useDispatch();
  const [showDatePicker, setShowDatePicker] = useState({});
  const [state, setState] = useState({});
  const classes = useStyles();
  const currentTimestamp = dayjs().unix();

  const CheckCircleIconComponent = ({ iconColor }) => (
    <CheckCircleIcon
      viewBox="0 0 24 24"
      height={24}
      width={24}
      color={iconColor}
      className={classes.iconStyle}
    />
  );

  const CopyToClipboardComponent = ({ text }) => (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setCopyToChipboardSuccess(true);
      }}
    >
      <Button
        variant="contained"
        component="span"
        size="small"
        color="primary"
        startIcon={
          <LinkIcon
            viewBox="0 0 24 24"
            height={24}
            width={30}
            color="#FFF"
            className={classes.iconStyle}
          />
        }
        style={{ borderRadius: 3, width: 169, height: 40 }}
      >
        <Typography className={classes.buttonLabelText}>
          Copy File URL
        </Typography>
      </Button>
    </CopyToClipboard>
  );

  const DownloadIconComponent = ({ iconColor }) => (
    <DownloadIcon
      viewBox="0 0 24 24"
      height={24}
      width={24}
      color={iconColor}
      className={classes.iconStyle}
    />
  );

  const DeleteIconComponent = ({ iconColor }) => (
    <TrashIcon
      viewBox="0 0 24 24"
      height={24}
      width={24}
      color={iconColor}
      className={classes.iconStyle}
    />
  );

  const downloadFile = (fileUrl, fileName) => {
    fetch(fileUrl).then(async (response) => {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const handleDateChange = ({ row, id, date, shouldDelete = false }) => {
    const newExpiryDate = date ? date : dayjs().endOf('year');
    setShowDatePicker((prev) => ({ ...prev, [id]: false }));
    const { ...restRow } = row;
    const updatableData = shouldDelete
      ? restRow
      : { ...restRow, expiryDate: dayjs(newExpiryDate).unix() };
    dispatch(loaderStart());
    firebase
      .updateFile({ ...updatableData }, id)
      .then(() => {
        getFiles();
      })
      .catch(() => {
        dispatch(loaderStop());
      });
  };

  const CellItem = ({ row, column }) => {
    const value = row[column.id];
    if (column.id === 'fileName') {
      const currentData = dayjs.unix(currentTimestamp).format('YYYY/MM/DD');
      const uploadedDate = dayjs.unix(row['uploadDate']).format('YYYY/MM/DD');
      const fileUrl = row['fileHash'];
      return (
        <div className={classes.fileName}>
          <div className={classes.fileNameBodyContainer}>
            <div className={classes.fileNameAndUpdateDateContainer}>
              <div className={classes.fileNameFormatContainer}>
                <span className={classes.fileNameFormat}>
                  {formatFilename(value)}
                </span>
                {currentData === uploadedDate && (
                  <div>
                    <span className={classes.fileStatus}>NEW</span>
                  </div>
                )}
              </div>
              <span className={classes.dateText}>{uploadedDate}</span>
            </div>
            {!isEntryModal && (
              <span>
                {' '}
                <Link
                  color="secondary"
                  component={Link}
                  onClick={() =>
                    window.open(`${copyFileUrl}${fileUrl}`, '_blank')
                  }
                  className={classes.siderDetail}
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                  {`${copyFileUrl}${fileUrl}`}
                </Link>
              </span>
            )}
          </div>
          <div
            className={clsx(
              classes.actionButtons,
              {
                [classes.entryModalActionButtons]: isEntryModal
              },
              'action-items'
            )}
          >
            {isEntryModal ? (
              <Button
                variant="contained"
                component="span"
                size="small"
                color="primary"
                className={classes.insertFileUrl}
                startIcon={
                  <LinkIcon
                    viewBox="0 0 24 24"
                    height={24}
                    width={24}
                    color="#FFF"
                    className={classes.iconStyle}
                  />
                }
                onClick={() => {
                  handleInsertClick(fileUrl, entryModalData);
                }}
              >
                Insert File URL
              </Button>
            ) : (
              <>
                {isCopyToChipboardSuccess ? (
                  <Button
                    component="span"
                    size="small"
                    variant="outlined"
                    color="primary"
                    disable="true"
                    style={{ borderRadius: 3, width: 119, height: 40 }}
                    startIcon={<CheckCircleIconComponent iconColor="#8bc517" />}
                  >
                    <Typography className={classes.buttonLabelText}>
                      Copied
                    </Typography>
                  </Button>
                ) : (
                  <CopyToClipboardComponent
                    text={`${copyFileUrl}${fileUrl}`}
                    iconColor="#FFF"
                  />
                )}
                <IconButton
                  aria-label="download"
                  className={classes.actionIcons}
                  onClick={() => downloadFile(row['fileUrl'], value)}
                >
                  <DownloadIconComponent />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  className={classes.actionIcons}
                  onClick={() => {
                    deleteFile(value, row['id']);
                  }}
                >
                  <DeleteIconComponent />
                </IconButton>
              </>
            )}
          </div>
        </div>
      );
    }
    if (column.id === 'fileUrl') {
      let imageSource = '';
      let showFileNotFound = false;
      if (!_.isEmpty(value)) {
        if (row['fileType'].indexOf('image/') !== -1) {
          imageSource = value;
        } else {
          imageSource = FILE_NOT_FOUND;
          showFileNotFound = true;
        }
      } else {
        imageSource = FILE_NOT_FOUND;
        showFileNotFound = true;
      }

      const defaultImage = FILE_NOT_FOUND;

      return (
        <div className={classes.fileImageContainer}>
          <img
            alt={row['fileName']}
            src={
              (state['imageError' + row['id']] && defaultImage) || imageSource
            }
            onError={() => {
              setState({
                ['imageError' + row['id']]: true
              });
            }}
            className={clsx(classes.fileImage, {
              [classes.fileNotFoundImage]:
                state['imageError' + row['id']] || showFileNotFound
            })}
            onClick={() => {
              if (!isEntryModal) {
                setIsOpenModal(true);
                setActiveRowData(row);
                setCopyToChipboardSuccess(false);
              }
            }}
          />
        </div>
      );
    }

    if (column.id === 'uploadDate') {
      return (
        <div>
          {row?.expiryDate ? (
            <div className={classes.setExpiryDateActionsContainer}>
              <div className={classes.newExpiryDateContainer}>
                <span className={classes.newExpiryDateTitle}>Delete after</span>
                <span
                  className={classes.newExpiryDate}
                  onClick={() => {
                    setShowDatePicker((prev) => ({ ...prev, [row.id]: true }));
                  }}
                >
                  {dayjs.unix(row.expiryDate).format('YYYY/MM/DD')}
                </span>
              </div>
              <IconButton
                size="small"
                aria-label="close"
                onClick={() =>
                  handleDateChange({ id: row.id, row, shouldDelete: true })
                }
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          ) : (
            <span
              className={classes.setExpiryDateLink}
              onClick={() =>
                setShowDatePicker((prev) => ({ ...prev, [row.id]: true }))
              }
            >
              Set expiry date
            </span>
          )}

          <Dialog
            open={!!showDatePicker[row.id]}
            classes={{
              paper: classes.endOfYearDialogPaper
            }}
            onClose={() =>
              setShowDatePicker((prev) => ({ ...prev, [row.id]: false }))
            }
            onKeyDown={(event) => {
              if (event.key === 'Escape') {
                setShowDatePicker((prev) => ({ ...prev, [row.id]: false }));
              }
            }}
          >
            <DialogContent classes={{ root: classes.endOfYearDialogContent }}>
              <DatePicker
                format="MMM DD,YYYY"
                className={classes.formDateControlFromBordered}
                autoOk
                okLabel=""
                cancelLabel=""
                name={`expiryDate-${row.id}`}
                variant="static"
                value={
                  row.expiryDate
                    ? dayjs.unix(row.expiryDate).format('YYYY/MM/DD')
                    : dayjs
                        .unix(row['uploadDate'])
                        .add(4, 'month')
                        .format('YYYY/MM/DD')
                }
                onChange={(date) =>
                  handleDateChange({
                    row,
                    id: row.id,
                    date
                  })
                }
                open={!!showDatePicker[row.id]}
              />
              <div className={classes.endOfYearButtonContainer}>
                <Button
                  variant="contained"
                  onClick={() => handleDateChange({ row, id: row.id })}
                  className={classes.endOfYearButton}
                >
                  Delete at end of year
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      );
    }
    return '';
  };

  const TableRowItem = ({ row, columns }) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        className={classes.tableRow}
        onMouseLeave={(e) => {
          if (!isEntryModal && e.target.tagName.toLowerCase() === 'td') {
            setCopyToChipboardSuccess(false);
          }
        }}
      >
        {columns.map((column) => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              className={clsx(classes.dataTableCell, {
                [classes.entryModalDataTableCell]: isEntryModal
              })}
              {...column.cellAttr}
            >
              <CellItem row={row} column={column} />
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return <TableRowItem row={row} columns={columns} />;
}

export default withFirebase(FileTableRow);
