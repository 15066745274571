import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import lodash from 'lodash';
import * as yup from 'yup';
import clsx from 'clsx';
 
// Material-UI Components
import {
  Button,
  Typography,
  CircularProgress,
  InputLabel,
  FormControl,
  TextField,
  Select,
  Grid,
  MenuItem,
  InputAdornment,
  IconButton
} from '@material-ui/core';
 
// Assets
import { Loop } from '@material-ui/icons';
 
// Components
import Modal from 'src/components/Modal';
 
// Redux-Actions
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { setFamily } from 'src/redux/actions/familyActions';
import { addMemberStyles } from 'src/styles/membersStyle/addMemberStyles';
import { MESSAGE_TYPES } from 'src/constants/common';
 
// Services
import { isMemberExist } from 'src/services/members';
 
// Utils
import { generatePatternPassword, getInitials } from 'src/utils/functions';
import { counties, timeZones, routes } from 'src/utils/constant';
import { withFirebase, FIREBASE_API_URL } from 'src/utils/firebase';
 
function AddMember({
  submitForm,
  isEdit = false,
  handlePause,
  handleResume,
  firebase,
  accountFlag,
  handleCancelDisable,
  setHandleCancelClick,
  handleCancelClick
}) {
  const classes = addMemberStyles();
 
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.app.isLoading);
  const prefillFamilyData = useSelector((state) => state.family.currentFamily);
 
  const [initialValues, setInitialValue] = useState({
    planType: 'Free',
    lastName: '',
    userName: '',
    password: '',
    country: '',
    timezone: '',
    firstName: '',
    primaryEmail: '',
    phoneNo: '',
    initials: ''
  });
 
  const [isPauseAccountModal, setPauseAccountModal] = useState(false);
  const [formKey, setFormKey] = useState(Math.random());
  const [isResumeAccountModal, setResumeAccountModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [resumeText, setResumeText] = useState('');
  const [pauseText, setPauseText] = useState('');
  const [deleteText, setDeleteText] = useState('');
  const [isSave, setSave] = useState(false);
  const [isOpenConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [isRotate, setRotate] = useState(false);
 
  // Existing Email check
  const [isPrimaryEmailExistCheck, setIsPrimaryEmailExistCheck] =
    useState(false);
 
  const onTogglePasswordFieldType = () => {
    setPasswordFieldType(
      passwordFieldType === 'password' ? 'text' : 'password'
    );
  };
  // SCHEMA VALIDATOR FOR CREATE NEW PASSWORD FORM
  const validationSchema = yup.object().shape({
    firstName: yup.string().trim().required('First name is required'),
    primaryEmail: yup
      .string()
      .trim()
      .email('Valid email is required')
      .required('Email is required'),
    lastName: yup.string().trim().required('Family Surname is required'),
    userName: yup
      .string()
      .trim()
      .when('planType', {
        is: (planType) => planType !== 'Free',
        then: yup
          .string()
          .required('User Name is required')
          .matches(/^[a-zA-Z0-9]+$/, 'Invalid User Name'),
        otherwise: yup.string().trim()
      }),
    password: yup
      .string()
      .trim()
      .when(['isEdit', 'planType'], {
        is: (isEdit, planType) => !isEdit && planType !== 'Free',
        then: yup
          .string()
          .required('Password is required')
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
            'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.'
          ),
        otherwise: yup
          .string()
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
            'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.',
            { excludeEmptyString: true }
          )
      }),
    planType: yup.string().trim().required('Plan Type is required'),
    country: yup.string().trim().required('Country is required'),
    timezone: yup.string().trim().required('Timezone is required'),
    phoneNo: yup
      .string()
      .trim()
      .matches(/^[1-9]\d{5,}$/, 'Phone number must be valid.')
      .required('Phone Number is required.')
  });
 
  useEffect(() => {
    dispatch(
      setFamily({
        ...prefillFamilyData,
        planType: 'Free'
      })
    );
  }, []);
 
  useEffect(() => {
    if (handleCancelClick) {
      setInitialValue({
        planType: prefillFamilyData.planType || '',
        lastName: prefillFamilyData.lastName,
        userName: prefillFamilyData.userName,
        password: prefillFamilyData.password,
        country: prefillFamilyData.country,
        timezone: prefillFamilyData.timezone,
        firstName: prefillFamilyData.firstName,
        primaryEmail: prefillFamilyData.primaryEmail,
        initials: prefillFamilyData.initials
      });
      setFormKey(Math.random());
      setHandleCancelClick(false);
      handleCancelDisable(false);
    }
  }, [handleCancelClick, setHandleCancelClick]);
 
  const deleteFamilyEntries = async (familyID) => {
    await firebase.deleteEntries(familyID);
  };
 
  const deleteAuthUserCaller = async (userId) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: userId
      })
    };
    fetch(`${FIREBASE_API_URL}/deleteUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result;
      })
      .then(
        () => {
          //
        },
        (error) => {
          const errorMessage = lodash.get(error, 'message', '');
          console.log(errorMessage);
          dispatch(addMessage(errorMessage, MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      );
  };
 
  const deleteUserHandle = async () => {
    setDeleteText('');
    dispatch(loaderStart());
    try {
      if (prefillFamilyData.userId) {
        await deleteAuthUserCaller(prefillFamilyData.userId);
        await firebase.deleteUser(prefillFamilyData.userId);
 
        Array.isArray(prefillFamilyData.childs) &&
          prefillFamilyData.childs.map((childData) => {
            deleteAuthUserCaller(childData.id);
            return firebase.deleteUser(childData.id);
          });
      }
 
      await deleteFamilyEntries(prefillFamilyData.id);
      firebase
        .deleteFamily(prefillFamilyData.id)
        .then(
          () => {
            dispatch(loaderStop());
            setOpenDeleteModal(false);
            setOpenConfirmDeleteModal(true);
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(
              addMessage(
                'Family was not deleted. Please try again',
                MESSAGE_TYPES.ERROR
              )
            );
            dispatch(loaderStop());
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(
            addMessage(
              'Family was not deleted. ooo Please try again',
              MESSAGE_TYPES.ERROR
            )
          );
          dispatch(loaderStop());
        });
    } catch (error) {
      console.log(error, 'error');
      dispatch(
        addMessage(
          'Family was not deleted. kkk Please try again',
          MESSAGE_TYPES.ERROR
        )
      );
      dispatch(loaderStop());
    }
  };
 
  const generatePassword = (setFieldValue) => {
    setRotate(true);
    setFieldValue('password', generatePatternPassword(8));
    dispatch(addMessage('Password generated and set'));
    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };
 
  const checkUserExistOrNot = (userName, setErrors) => {
    const email = `${userName}@mywhanau-user.com`;
    firebase
      .isUserExists(email)
      .then((results) => {
        if (results.size > 0) {
          setErrors({
            userName: `Username is already in use by another account.`
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
 
  return (
    <div>
      <div className={classes.contentWrapper}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors }) => {
            const formValues = {
              ...values,
              freeHost: true
            };
            const email = `${values.userName}@mywhanau-user.com`;
 
            const handleSubmitForm = () => {
              if (accountFlag && isSave) {
                submitForm(formValues, true);
              } else {
                submitForm(formValues);
                handleCancelDisable(false);
              }
            };
 
            if (
              !prefillFamilyData.userName ||
              prefillFamilyData.userName !== values.userName
            ) {
              if (email && values.userName) {
                isMemberExist(email)
                  .then((isExist) => {
                    if (isExist) {
                      setErrors({
                        userName: `${values.userName} username is already in use by another account.`
                      });
                    } else {
                      handleSubmitForm();
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
              } else {
                handleSubmitForm();
              }
            } else {
              handleSubmitForm();
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            setErrors,
            setFieldError,
            validateForm,
            setTouched
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                {values.planType ? (
                  <div style={{ marginLeft: '-2.5%' }}>
                    <div className={classes.label}>Member Profile</div>
                    <FormControl
                      component="fieldset"
                      className={clsx(classes.textFieldContainer)}
                    >
                      <TextField
                        label="First Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        name="firstName"
                        onChange={(e) => {
                          setFieldValue('firstName', e.target.value);
                          setFieldValue(
                            'initials',
                            getInitials(`${e.target.value} ${values?.lastName}`)
                          );
                        }}
                        value={values.firstName}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        className={classes.inputControlBox}
                      />
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      className={clsx(classes.textFieldContainer)}
                    >
                      <TextField
                        label="Surname"
                        variant="outlined"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue('lastName', e.target.value);
                          setFieldValue(
                            'initials',
                            getInitials(
                              `${values?.firstName} ${e.target.value}`
                            )
                          );
                        }}
                        error={Boolean(touched.lastName && errors.lastName)}
                        value={values.lastName}
                        className={classes.inputControlBox}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </FormControl>
 
                    <FormControl
                      component="fieldset"
                      className={clsx(classes.textFieldContainer)}
                    >
                      <TextField
                        label="Email Address"
                        variant="outlined"
                        className={classes.inputControlBox}
                        onBlur={async (event) => {
                          handleBlur(event)
                          const enteredEmail = event.target.value;
                          if (enteredEmail) {
                            const isEmailExist =
                              await isMemberExist(enteredEmail);
                            if (isEmailExist) {
                              setIsPrimaryEmailExistCheck(true);
                              setFieldError(
                                'primaryEmail',
                                `${enteredEmail} Email already exists`
                              );
                            } else {
                              setIsPrimaryEmailExistCheck(false);
                            }
                          } else {
                            setFieldError('primaryEmail', 'Email is required');
                          }
                          
                        }}
                        name="primaryEmail"
                        onChange={(e) => {
                          setFieldValue('primaryEmail', e.target.value.trim());
                        }}
                        value={values.primaryEmail}
                        error={
                          isPrimaryEmailExistCheck ||
                          Boolean(touched.primaryEmail && errors.primaryEmail)
                        }
                        helperText={
                          (touched.primaryEmail && errors.primaryEmail) ||
                          (isPrimaryEmailExistCheck && `Email already exists`)
                        }
                      />
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      className={clsx(classes.textFieldContainer)}
                    >
                      <TextField
                        label="Phone Number"
                        variant="outlined"
                        name="phoneNo"
                        value={values.phoneNo}
                        disableUnderline
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.inputControlBox}
                        error={Boolean(touched.phoneNo && errors.phoneNo)}
                        helperText={touched.phoneNo && errors.phoneNo}
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.inputControlBox}
                    >
                      <InputLabel id="country-select-outlined-label">
                        Country
                      </InputLabel>
                      <Select
                        labelId="country-select-outlined-label"
                        id="country-select-outlined"
                        value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.country && errors.country)}
                        name="country"
                        label="Country"
                      >
                        <MenuItem value="">
                          <em>Country</em>
                        </MenuItem>
                        {counties.map((object) => (
                          <MenuItem key={object.code} value={object.name}>
                            {object.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.country && errors.country && (
                        <span style={{ height: 20 }} className={classes.error}>
                          {errors.country}
                        </span>
                      )}
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.inputControlBox}
                    >
                      <InputLabel id="timezone-select-outlined-label">
                        Time zone
                      </InputLabel>
                      <Select
                        labelId="timezone-select-outlined-label"
                        id="timezone-select-outlined"
                        value={values.timezone}
                        onChange={(e) => {
                          handleChange(e);
                          if (accountFlag && isEdit) handleCancelDisable(true);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.timezone && errors.timezone)}
                        name="timezone"
                        label="Timezone"
                      >
                        <MenuItem value="">
                          <em>TimeZone</em>
                        </MenuItem>
                        {timeZones.map((object) => (
                          <MenuItem key={object.value} value={object.value}>
                            {object.text}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.timezone && errors.timezone && (
                        <span style={{ height: 20 }} className={classes.error}>
                          {errors.timezone}
                        </span>
                      )}
                    </FormControl>
 
                    <div className={classes.label}>Login Credentials</div>
                    <div
                      className={classes.subtitle}
                      style={{ marginBottom: '1rem' }}
                    >
                      Only add these if you are saving the account with the
                      status of Active.
                    </div>
 
                    <FormControl component="fieldset">
                      <TextField
                        label="Login Username"
                        variant="outlined"
                        className={classes.inputControlBox}
                        name="userName"
                        onBlur={(e) => {
                          handleBlur(e);
                          if (
                            (!prefillFamilyData.userName ||
                              prefillFamilyData.userName !== values.userName) &&
                            e.target.value
                          ) {
                            checkUserExistOrNot(e.target.value, setErrors);
                          }
                        }}
                        onChange={(e) => {
                          setFieldValue(
                            'userName',
                            e.target.value.replace(/\s/g, '')
                          );
                          if (accountFlag && isEdit) handleCancelDisable(true);
                        }}
                        error={Boolean(touched.userName && errors.userName)}
                        value={values.userName}
                        helperText={touched.userName && errors.userName}
                      />
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      className={clsx(classes.textFieldContainer)}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          label="Password"
                          variant="outlined"
                          className={classes.inputControlBox}
                          name="password"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            if (accountFlag && isEdit)
                              handleCancelDisable(true);
                          }}
                          error={Boolean(touched.password && errors.password)}
                          value={values.password}
                          helperText={touched.password && errors.password}
                          type={passwordFieldType}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={onTogglePasswordFieldType}
                                  tabIndex="-1"
                                >
                                  <img
                                    alt="Password Requirement"
                                    src={
                                      passwordFieldType === 'text'
                                        ? '/static/images/password-hide.png'
                                        : '/static/images/password-see.png'
                                    }
                                    width="22"
                                    height="22"
                                  />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        <span
                          className={classes.generate}
                          onClick={() => generatePassword(setFieldValue)}
                        >
                          Generate
                          <Loop
                            height={24}
                            width={24}
                            style={{ cursor: 'pointer' }}
                            className={isRotate && classes.closeIconRotate}
                          />
                        </span>
                      </div>
                    </FormControl>
 
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          const errors = await validateForm();
                          if (Object.keys(errors).length === 0) {
                            const enteredEmail = values.primaryEmail;
                            if (enteredEmail) {
                              const isEmailExist =
                                await isMemberExist(enteredEmail);
                              if (isEmailExist) {
                                setIsPrimaryEmailExistCheck(true);
                                setErrors({
                                  primaryEmail: `${enteredEmail} Email already exists`
                                });
                                setSave(false);
                                window.scroll(0, 0);
                              } else {
                                setIsPrimaryEmailExistCheck(false);
                                handleSubmit();
                                setSave(false);
                                window.scroll(0, 0);
                              }
                            }
                          } else {
                            setErrors(errors);
                            setTouched({
                              firstName: true,
                              lastName: true,
                              primaryEmail: true,
                              phoneNo: true,
                              country: true,
                              timezone: true,
                              userName: true,
                              password: true
                            });
                          }
                        }}
                        className={classes.buttonText}
                      >
                        Next
                      </Button>
                      {accountFlag && isEdit && (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonText}
                          onClick={() => {
                            setSave(true);
                            handleSubmit();
                          }}
                          style={{ marginLeft: '2%' }}
                        >
                          Save
                        </Button>
                      )}
                    </Grid>
                  </div>
                ) : null}
              </form>
            );
          }}
        </Formik>
      </div>
 
      {/* Modals */}
      {isPauseAccountModal && (
        <Modal
          isOpen={isPauseAccountModal}
          onClose={() => {
            setPauseAccountModal(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Account Pause?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                Pausing this account will revoke access for all users associated
                with this family, until account pause is disabled. You can
                continue to manage the account from within the admin.
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “PAUSE ACCOUNT” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={pauseText}
                  onChange={(e) => setPauseText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  handlePause && handlePause();
                  setPauseAccountModal(false);
                }}
                color="primary"
                variant="contained"
                size="large"
                disabled={pauseText !== 'PAUSE ACCOUNT'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Pause'}
              </Button>
              <Button
                onClick={() => {
                  setPauseAccountModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isResumeAccountModal && (
        <Modal
          isOpen={isResumeAccountModal}
          onClose={() => {
            setPauseAccountModal(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Account Resume?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                Resume account will activate access for all users associated
                with this family. You can continue to manage the account from
                within the admin.
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “RESUME ACCOUNT” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={resumeText}
                  onChange={(e) => setResumeText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  handleResume && handleResume();
                  setResumeText('');
                  setResumeAccountModal(false);
                }}
                color="primary"
                variant="contained"
                size="large"
                disabled={resumeText !== 'RESUME ACCOUNT'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Resume'}
              </Button>
              <Button
                onClick={() => {
                  setPauseAccountModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenDeleteModal && (
        <Modal
          isOpen={isOpenDeleteModal}
          onClose={() => {
            setOpenDeleteModal(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Delete Family Account?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                <span style={{ fontWeight: 'bold' }}>
                  Warning: This action cannot be reversed.
                </span>
                <br />
                The following data will be permanently removed from the
                database.
              </Typography>
            </div>
            <div className={classes.modalContent}>
              <Typography className={classes.modalSubTitle}>
                - Family account (admin)
                <br />
                - All associated calendar entries
                <br />
                - Family account and login credentials (app)
                <br />
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “DELETE ACCOUNT” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={deleteText}
                  onChange={(e) => setDeleteText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={deleteUserHandle}
                color="primary"
                variant="contained"
                size="large"
                disabled={deleteText !== 'DELETE ACCOUNT'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Delete'}
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenConfirmDeleteModal && (
        <Modal
          isOpen={isOpenConfirmDeleteModal}
          onClose={() => setOpenConfirmDeleteModal(false)}
        >
          <div className={classes.modalBox}>
            <Typography
              className={classes.modalTitle}
              style={{ marginBottom: 18 }}
            >
              Family Account Deleted
            </Typography>
            <Typography
              className={classes.modalSubTitle}
              style={{ marginBottom: 26 }}
            >
              All data associated with {prefillFamilyData.userName} family has
              now been removed from the database.
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  setOpenConfirmDeleteModal(false);
                  history.push(routes.families);
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
              >
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
 
export default withFirebase(AddMember);
