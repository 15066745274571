const initialState = {
  freeSchoolList: [],
  allSchoolList: []
};
// Reducers (Modifies The State And Returns A New State)
const reusableStateValueReducer = (state = initialState, action) => {
  switch (action.type) {
    // Set free schools list
    case 'SET_FREE_SCHOOL_LIST': {
      return {
        ...state,
        freeSchoolList: action.freeSchoolList
      };
    }

    case 'SET_ALL_SCHOOL_LIST': {
      return {
        ...state,
        allSchoolList: action.allSchoolList
      };
    }

    // Default
    default: {
      return state;
    }
  }
};

// Exports
export default reusableStateValueReducer;
