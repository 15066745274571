import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import lodash from 'lodash';
import moment from 'moment';
import clsx from 'clsx';

// Material-UI Components
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Typography from '@material-ui/core/Typography';

// Layouts
import Dashboard from 'src/views/layout/Dashboard';

// Components
import StepperComponent from 'src/components/StepperComponent';
import AddMember from './AddMember';

// Assets
import { FamiliesIcon, RightArrow } from 'src/assets/Icons';
import FILE from 'src/assets/file-plus.svg';
import POCKET from 'src/assets/pocket.svg';
import USER from 'src/assets/user.svg';
import { createMemberStyles } from 'src/styles/membersStyle/createMemberStyles';

// Redux-Actions
import {
  loaderStop,
  loaderStart,
  addMessage
} from 'src/redux/actions/appActions';
import { setFamily, resetFamily } from 'src/redux/actions/familyActions';

// Components
import AddChildren from './AddChildren';

// Services
import { createMember, getMember } from 'src/services/members';
import { MESSAGE_TYPES } from 'src/constants/common';

// Utils
import { withFirebase, FIREBASE_API_URL } from 'src/utils/firebase';
import { routes } from 'src/utils/constant';
import { encryptSHA256 } from 'src/utils/functions';
import { freeFamilyChildStatus } from '../../utils/constant';

function getStepContent(step, getFormData) {
  switch (step) {
    case 0:
      return <AddMember submitForm={getFormData} />;
    case 1:
      return <AddChildren submitForm={getFormData} />;
    // case 2:
    //   return <AddFamilyHost submitForm={getFormData} />;
    default:
      return 'Unknown step';
  }
}

function CreateMember({ firebase }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = createMemberStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [createid, setCreateId] = useState('');
  const familySchema = useSelector((state) => state.family.currentFamily);

  const ColorlibStepIcon = (props) => {
    const { active } = props;
    const icons = {
      1: (
        <div
          className={classes.iconStyle}
          style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
        >
          <img src={FILE} alt="Search" className={classes.image} />
        </div>
      ),
      2: (
        <div
          className={classes.iconStyle}
          style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
        >
          <img src={USER} alt="Search" className={classes.image} />
        </div>
      ),
      3: (
        <div
          className={classes.iconStyle}
          style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
        >
          {familySchema.planType === 'Free' ? (
            <CalendarTodayOutlinedIcon classes={{ root: classes.closeIcon }} />
          ) : (
            <img src={POCKET} alt="Search" className={classes.image} />
          )}
        </div>
      )
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  };

  const addChild = async (data) => {
    const { child } = data;
    const username = `${child.userName}@mywhanau-user.com`;
    const password = child.password;

    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email_address: username,
        password: password
      })
    };
    return await fetch(`${FIREBASE_API_URL}/createUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result;
      })
      .then(
        async (result) => {
          if (result.user) {
            const userID = result.user.uid;
            const userObject = {
              role: 'Child',
              userName: child.userName,
              email: username,
              child: child.id,
              isViewedOnboard: false
            };
            await firebase.addUser(userObject, userID);
            return userID;
          } else {
            // dispatch(loaderStop());
            if (result.code === 'auth/email-already-exists') {
              dispatch(
                addMessage(
                  `${child.userName} username is already in use by another account.`
                )
              );
            } else if (result.code === 'auth/invalid-email') {
              dispatch(addMessage(`${child.userName} username was not valid`));
            } else {
              // dispatch(
              //   addMessage(
              //     `${child.userName} user was  not created. Please try again`
              //   )
              // );
            }
          }
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(
            addMessage(
              'User was  not created. Please try again',
              MESSAGE_TYPES.ERROR
            )
          );
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(
          addMessage(
            'User was not created. Please try again',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });
  };

  const getComparedAndDifference = (data, compArray = []) => {
    return data.filter((el) => !compArray.includes(el));
  };

  const getFormData = async (formData, status) => {
    if (activeStep < 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      dispatch(setFamily({ ...familySchema, ...formData }));
    } else if (activeStep === 1) {
      const finalData = {
        ...familySchema,
        ...formData,
        ...{ createdDate: moment.utc().format() }
      };

      dispatch(loaderStart());

      let updatedChildData = finalData.childs || [];

      let filterChildData = [];
      const pushRecord = {
        members: []
      };
      if (finalData.childs.length && familySchema.planType !== 'Free') {
        const childArray = [];
        for (const child of finalData.childs) {
          const childData = {
            child
          };
          const data = await addChild(childData);
          childArray.push(data);
        }
        updatedChildData = finalData.childs.map((child, index) => {
          let childObj = {
            ...child,
            userId: childArray[index]
          };
          if (child.password) {
            childObj = {
              ...childObj,
              password: '',
              encryptedPassword: encryptSHA256(childObj.password)
            };
          }
          return childArray[index] && childObj;
        });
      } else {
        // update groupData if child have groups
        if (finalData.childs.length) {
          finalData.childs.forEach((child) => {
            let mergeNewClassAndGroups = lodash.compact([
              child.group,
              child.groups
            ]);
            mergeNewClassAndGroups = lodash.uniq(
              // eslint-disable-next-line prefer-spread
              [].concat.apply([], mergeNewClassAndGroups)
            );

            // get new groups id which is updated from old selection
            const newGroupReceived = getComparedAndDifference(
              mergeNewClassAndGroups,
              []
            );

            if (newGroupReceived) {
              newGroupReceived.forEach((el) => {
                pushRecord.members.push({
                  childId: child.id,
                  groupId: el,
                  isAdd: true
                });
              });
            }
          });
        }
      }

      filterChildData = updatedChildData.filter((child) => {
        if (child.firstName && child.lastName && child.year && child.school) {
          return true;
        }
        return false;
      });

      const updatedFinalData = {
        ...finalData,
        childs: filterChildData
      };

      if (status === freeFamilyChildStatus.INVITED) {
        await createMember({
          member: {
            firstName: updatedFinalData?.firstName,
            lastName: updatedFinalData?.lastName,
            primaryEmail: updatedFinalData?.primaryEmail,
            phoneNo: updatedFinalData?.phoneNo,
            country: updatedFinalData?.country, // [Australia, New Zealand, Hong Kong]
            timezone: updatedFinalData?.timezone, // [8, 8.01, 8.75, 9.5, 10, 10.01, 10.5, 12, 12.75]
            status: 'Invited', // Default =
            isAdmin: true
          },
          students: updatedFinalData?.childs?.map((c) => ({
            firstName: c?.firstName,
            lastName: c?.lastName,
            school: c?.school,
            year: c?.year, // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, -1]
            ...(Array.isArray(c?.groups) && c?.groups?.length > 0
              ? { groupIds: c?.groups }
              : {})
            // optional
          }))
        })
          .then(async (response) => {
            const familyResult = response.data;
            console.log('INVITED > familyResult', familyResult);
            // Update group entries
            setCreateId(familyResult.id);
            getMember(familyResult.id)
              .then(async (response) => {
                const familyData = response.data;
                dispatch(setFamily(familyData));
                dispatch(loaderStop());
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              })
              .catch((err) => {
                console.log('Error: ', err);
                dispatch(loaderStop());
              });
          })
          .catch((error) => {
            console.log('Error: ', error);
            dispatch(
              addMessage(
                'Member was not created. Please try again',
                MESSAGE_TYPES.ERROR
              )
            );
            dispatch(loaderStop());
          });
      } else if (status === freeFamilyChildStatus.NEW) {
        await createMember({
          member: {
            firstName: updatedFinalData?.firstName,
            lastName: updatedFinalData?.lastName,
            primaryEmail: updatedFinalData?.primaryEmail,
            phoneNo: updatedFinalData?.phoneNo,
            country: updatedFinalData?.country, // [Australia, New Zealand, Hong Kong]
            timezone: updatedFinalData?.timezone, // [8, 8.01, 8.75, 9.5, 10, 10.01, 10.5, 12, 12.75]
            status: 'New', // Default =
            isAdmin: true
          },
          ...(updatedFinalData?.childs?.length > 0
            ? {
                students: updatedFinalData?.childs?.map((c) => ({
                  firstName: c?.firstName,
                  lastName: c?.lastName,
                  school: c?.school,
                  year: c?.year, // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, -1]
                  ...(Array.isArray(c?.groups) && c?.groups?.length > 0
                    ? { groupIds: c?.groups }
                    : {})
                }))
              }
            : {})
        })
          .then(async (response) => {
            const familyResult = response.data;
            setCreateId(familyResult.id);
            getMember(familyResult.id)
              .then(async (response) => {
                const familyData = response.data;
                dispatch(setFamily(familyData));
                dispatch(loaderStop());
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              })
              .catch((err) => {
                console.log('Error: ', err);
                dispatch(loaderStop());
              });
          })
          .catch((error) => {
            console.log('Error: ', error);
            dispatch(
              addMessage(
                'Family was not created. Please try again',
                MESSAGE_TYPES.ERROR
              )
            );
            dispatch(loaderStop());
          });
      } else {
        await createMember({
          member: {
            firstName: updatedFinalData?.firstName,
            lastName: updatedFinalData?.lastName,
            primaryEmail: updatedFinalData?.primaryEmail,
            phoneNo: updatedFinalData?.phoneNo,
            country: updatedFinalData?.country, // [Australia, New Zealand, Hong Kong]
            timezone: updatedFinalData?.timezone, // [8, 8.01, 8.75, 9.5, 10, 10.01, 10.5, 12, 12.75]
            userName: updatedFinalData?.userName,
            password: updatedFinalData?.password,
            status: 'Active', // Default =
            isAdmin: true
          },
          students: updatedFinalData?.childs?.map((c) => ({
            firstName: c?.firstName,
            lastName: c?.lastName,
            school: c?.school,
            year: c?.year, // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, -1]
            ...(Array.isArray(c?.groups) && c?.groups?.length > 0
              ? { groupIds: c?.groups }
              : {})
          }))
        })
          .then(async (response) => {
            const familyResult = response.data;
            setCreateId(familyResult.id);
            getMember(familyResult.id)
              .then(async (response) => {
                const familyData = response.data;
                dispatch(setFamily(familyData));
                dispatch(loaderStop());
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              })
              .catch((err) => {
                console.log('Error: ', err);
                dispatch(loaderStop());
              });
          })
          .catch((error) => {
            console.log('Error: ', error);
            dispatch(
              addMessage(
                'Member was not created. Please try again',
                MESSAGE_TYPES.ERROR
              )
            );
            dispatch(loaderStop());
          });
      }
    }
  };

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [setActiveStep]);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [setActiveStep]);

  const handleReset = useCallback(() => {
    dispatch(resetFamily());
    setActiveStep(0);
  }, [setActiveStep]);

  return (
    <Dashboard>
      {activeStep < 2 && (
        <>
          <div>
            <Typography
              variant="body1"
              className={classes.bredthcrumbTitle}
              onClick={() => history && history.push(routes.membersActive)}
            >
              Members
            </Typography>
            <RightArrow
              viewBox="0 0 24 24"
              height={24}
              width={24}
              className={classes.iconStyleBredthcrumb}
            />
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.familySurnameStyle}
          >
            Add a new member
          </Typography>
        </>
      )}
      <div
        className={clsx({
          [classes.rootContainer]: activeStep < 3
        })}
      >
        <StepperComponent
          steps={['Account', 'Children']}
          showSteps={Boolean(familySchema.planType)}
          getStepContent={(step) => getStepContent(step, getFormData)}
          stepClick={(step) => setActiveStep(step)}
          title={'Member'}
          colorlibStepIcon={ColorlibStepIcon}
          successTitle={'Member Created'}
          subTitle={'View Member'}
          iconImage={<FamiliesIcon />}
          buttonLabel={'Create New Member'}
          listUrl={
            familySchema.planType === 'Free'
              ? routes.memberAccount + '/' + createid
              : routes.familyCalendarPaid + '/' + createid
          }
          createUrl={
            familySchema.planType === 'Free'
              ? routes.memberCreate
              : routes.familyCreatePaid
          }
          activeStep={activeStep}
          handleNext={handleNext}
          handleBack={handleBack}
          handleReset={handleReset}
          isCancelDisable={activeStep !== 0}
        />
      </div>
    </Dashboard>
  );
}
export default withFirebase(CreateMember);
