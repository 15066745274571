import { EDIT_ICON } from 'src/assets';

export const ACTIVE_MEMBER_COLUMN = {
  LAST_NAME: {
    KEY: 'lastName',
    LABEL: 'Surname'
  },
  FIRST_NAME: {
    KEY: 'firstName',
    LABEL: 'First'
  },
  SCHOOL_NAME: {
    KEY: 'schools',
    LABEL: 'School Name'
  },
  YEAR: {
    KEY: 'studentReferences',
    LABEL: 'Year'
  },
  JOINED: {
    KEY: 'date',
    LABEL: 'Joined'
  },
  API_SYNC: {
    KEY: 'apiSync',
    LABEL: ''
  }
};

export const ACTIVE_MEMBER_COLUMNS = [
  {
    id: ACTIVE_MEMBER_COLUMN.LAST_NAME.KEY,
    label: ACTIVE_MEMBER_COLUMN.LAST_NAME.LABEL,
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '21.5%'
  },
  {
    id: ACTIVE_MEMBER_COLUMN.FIRST_NAME.KEY,
    label: ACTIVE_MEMBER_COLUMN.FIRST_NAME.LABEL,
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '19%'
  },
  {
    id: ACTIVE_MEMBER_COLUMN.SCHOOL_NAME.KEY,
    label: ACTIVE_MEMBER_COLUMN.SCHOOL_NAME.LABEL,
    setLabelStyle: true,
    iconPosition: 'before',
    hasSort: true
    // minWidth: 170,
    // width: '14%'
  },
  {
    id: ACTIVE_MEMBER_COLUMN.YEAR.KEY,
    label: ACTIVE_MEMBER_COLUMN.YEAR.LABEL,
    align: 'left',
    format: 'icon',
    onEach: true,
    icon: EDIT_ICON,
    iconPosition: 'before',
    hasSort: true,
    width: '23.8%'
  },
  // {
  //   id: 'freeHost',
  //   label: 'Free Host?',
  //   minWidth: 170,
  //   iconPosition: 'before',
  //   hasSort: true,
  //   width: '16%'
  // },
  {
    id: ACTIVE_MEMBER_COLUMN.JOINED.KEY,
    label: ACTIVE_MEMBER_COLUMN.JOINED.LABEL,
    minWidth: 170,
    setLabelStyle: true,
    iconPosition: 'before',
    hasSort: true
    // width: '14%'
  },
  {
    id: ACTIVE_MEMBER_COLUMN.API_SYNC.KEY,
    label: ACTIVE_MEMBER_COLUMN.API_SYNC.LABEL,
    align: 'center',
    format: 'icon',
    iconPosition: 'right',
    icon: false,
    width: '5%'
  }
];
