import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from './utils/theme';
import { routes, colors } from './utils/constant';

import PrivateRoute from './components/PrivateRoute';
import PageNotFoundView from './views/404';
import LoginView from './views/auth/LoginView';
import ResetPasswordView from './views/auth/ResetPasswordView';
import CodeVerifyView from './views/auth/CodeVerifyView';
import FamiliesListViewPaid from './views/families/FamilyViewPaid';
import CalendarView from './views/families/Calendar';
import FilesView from './views/families/Files';
import NotesView from './views/families/Notes';
import LinksView from './views/families/Links';
import FamilyUsersView from './views/families/Users';
import FamilyAccountView from './views/families/Account';
import TeamView from './views/console/team';
import MaintenanceView from './views/console/maintenance';
import SchoolConsoleView from './views/console/school';
import SchoolListView from './views/schools';
import CreateFamily from './views/families/CreateFamily';
import CreateSchool from './views/schools/CreateSchool';
import EditSchoolCalendar from './views/schools/EditSchoolCalendar';
import SchoolGroup from './views/schools/groups/SchoolGroup';
import SchoolStudents from './views/schools/students';
import SchoolFilesView from './views/schools/Files';
import EditSchoolProfile from './views/schools/EditSchoolProfile';
import EditSchoolForm from './views/schools/Form';
import SchoolLinksView from './views/schools/Links';
import MobileCreatePasswordView from './views/auth/MobileCreatePasswordView';
import MobilePasswordUpdatedView from './views/auth/MobilePasswordUpdatedView';
import SetNewPassword from './views/auth/SetNewPassword';
import MembersListActive from './views/members/MembersListActive';
import MembersListPending from './views/members/MembersListPending';
import MembersListNew from './views/members/MembersListNew';
import MemberCalendarView from './views/members/MemberCalendarView';
import MembersUsersView from './views/members/MembersUsersView';
import MembersAccount from './views/members/MembersAccount';
import CreateMember from './views/members/CreateMember';
const useStyles = makeStyles({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    /* Handle on hover */
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: colors.primary,
      '&:hover': {
        background: '#81b715'
      }
    },
    '.MuiButton-root': {
      textTransform: 'none'
    },
    '.MuiButton-containedSizeLarge': {
      fontSize: '16px',
      lineHeight: 1.5,
      height: 50
    },
    '.MuiInputBase-input': {
      height: 23
    },
    '.MuiTypography-gutterBottom': {
      marginBottom: 16
    },
    '.MuiSnackbarContent-root': {
      padding: '2px 24px',
      backgroundColor: colors.tertiaryConfirm,
      borderRadius: '34.5px',
      minWidth: '50px',
      width: 'auto'
    },
    '.MuiInputLabel-outlined': {
      lineHeight: '22px'
    },
    '.MuiBackdrop-root': {
      backgroundColor: `rgba(76, 91, 104, 0.6) !important`
    }
  }
});

const Routes = () => {
  useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <Switch>
          <Route exact path={routes.login} component={LoginView} />
          <Route
            exact
            path={routes.forgotPassword}
            component={ResetPasswordView}
          />
          <Route exact path={routes.resetPassword} component={SetNewPassword} />
          <Route
            exact
            path={routes.setNewPassword}
            component={SetNewPassword}
          />
          <Route exact path={routes.codeVerify} component={CodeVerifyView} />
          <PrivateRoute exact path={'/'} component={MembersListActive} />
          <PrivateRoute
            exact
            path={routes.membersActive}
            component={MembersListActive}
          />
          <PrivateRoute
            exact
            path={routes.familiesPaid}
            component={FamiliesListViewPaid}
          />
          <PrivateRoute
            exact
            path={routes.memberCalendar + '/:id'}
            component={MemberCalendarView}
          />
          <PrivateRoute
            exact
            path={routes.familyCalendarPaid + '/:id'}
            component={CalendarView}
          />
          <PrivateRoute
            exact
            path={routes.familyFilesPaid + '/:id'}
            component={FilesView}
          />
          <PrivateRoute
            exact
            path={routes.familyNotes + '/:id'}
            component={NotesView}
          />
          <PrivateRoute
            exact
            path={routes.familyNotesPaid + '/:id'}
            component={NotesView}
          />
          <PrivateRoute
            exact
            path={routes.familyLinks + '/:id'}
            component={LinksView}
          />
          <PrivateRoute
            exact
            path={routes.familyLinksPaid + '/:id'}
            component={LinksView}
          />
          <PrivateRoute
            exact
            path={routes.familyUsersPaid + '/:id'}
            component={FamilyUsersView}
          />
          <PrivateRoute
            exact
            path={routes.membersUsers + '/:id'}
            component={MembersUsersView}
          />
          <PrivateRoute
            exact
            path={routes.memberAccount + '/:id'}
            component={MembersAccount}
          />
          <PrivateRoute
            exact
            path={routes.memberAccount + '/' + 'new' + '/:id'}
            component={MembersAccount}
          />
          <PrivateRoute
            exact
            path={routes.memberAccount + '/' + 'pending' + '/:id'}
            component={MembersAccount}
          />
          <PrivateRoute
            exact
            path={routes.familyAccountPaid + '/:id'}
            component={FamilyAccountView}
          />
          <PrivateRoute exact path={routes.consoleTeam} component={TeamView} />
          <PrivateRoute
            exact
            path={routes.consoleMaintenance}
            component={MaintenanceView}
          />
          <PrivateRoute
            exact
            path={routes.consoleSchools}
            component={SchoolConsoleView}
          />
          <PrivateRoute
            exact
            path={routes.schools}
            component={SchoolListView}
          />
          <PrivateRoute
            exact
            path={routes.membersActive}
            component={MembersListActive}
          />
          <PrivateRoute
            exact
            path={routes.membersNew}
            component={MembersListNew}
          />
          <PrivateRoute
            exact
            path={routes.membersPending}
            component={MembersListPending}
          />
          <PrivateRoute
            exact
            path={routes.schoolCalendar + '/:id'}
            component={EditSchoolCalendar}
          />
          <PrivateRoute
            exact
            path={routes.schoolGroup + '/:id'}
            component={SchoolGroup}
          />
          <PrivateRoute
            exact
            path={routes.schoolStudent + '/:id'}
            component={SchoolStudents}
          />
          <PrivateRoute
            exact
            path={routes.schoolFiles + '/:id'}
            component={SchoolFilesView}
          />
          <PrivateRoute
            exact
            path={routes.schoolLinks + '/:id'}
            component={SchoolLinksView}
          />
          <PrivateRoute
            exact
            path={routes.schoolAccount + '/:id'}
            component={EditSchoolProfile}
          />
          <PrivateRoute
            exact
            path={routes.schoolForm + '/:id'}
            component={EditSchoolForm}
          />
          {/* <PrivateRoute
            exact
            path={routes.familyCreate}
            component={CreateFamily}
          /> */}
          <PrivateRoute
            exact
            path={routes.memberCreate}
            component={CreateMember}
          />
          <PrivateRoute
            exact
            path={routes.memberCreate}
            component={CreateMember}
          />
          <PrivateRoute
            exact
            path={routes.familyCreatePaid}
            component={CreateFamily}
          />
          <PrivateRoute
            exact
            path={routes.familyCreatePaid}
            component={CreateFamily}
          />
          <PrivateRoute
            exact
            path={routes.schoolCreate}
            component={CreateSchool}
          />
          <Route
            exact
            path={routes.appResetPassword}
            component={MobileCreatePasswordView}
          />
          <Route
            exact
            path={routes.appPasswordUpdated}
            component={MobilePasswordUpdatedView}
          />

          <Route
            exact
            path={routes.pageNotFound}
            component={PageNotFoundView}
          />
          <Redirect to={routes.pageNotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};
export default Routes;
