import { makeStyles } from '@material-ui/core';
import { colors } from 'src/utils/constant';

export const categoryGroupCalendarEntryStyles = makeStyles((theme) => ({
  updatedLabel: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 0.09
  },
  updatedDesc: {
    fontSize: 16,
    height: 19,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  chipLabel: {
    height: 18,
    fontfamily: 'Roboto',
    fontweight: 500,
    fontstretch: 'normal',
    fontstyle: 'normal',
    lineheight: 'normal',
    letterspacing: 'normal',
    color: colors.white,
    marginTop: -5
  },
  chipRoot: {
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    height: 20,
    position: 'absolute',
    top: -8,
    left: 10,
    opacity: 1,
    zIndex: 1
  },
  drawerLeftContent: {
    width: `calc(70%)`,
    height: `calc(100vh - 95px)`,
    opacity: 1,
    backgroundColor: colors.white,
    float: 'left',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  drawerRightContent: {
    width: '30%',
    height: `calc(100vh - 95px)`,
    backgroundColor: colors.tertiary,
    float: 'right'
  },
  siderEdit: {
    height: 40,
    marginTop: 19
  },
  actionButton: {
    float: 'right',
    marginRight: '1%'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 20
  },
  headerTitleContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center'
  },
  publishButton: { marginLeft: 10 },
  familyIconStyle: { height: '40px', width: '40px' },
  titleText: { marginLeft: 10, fontWeight: '600', fontSize: 24 },
  titleAccordionText: {
    marginLeft: 10,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    opacity: 1
  },
  whiteText: { color: colors.white },
  darkPrimaryText: {
    color: colors.primaryDark,
    fontSize: 23,
    fontWeight: '500',
    lineHeight: 1.74
  },
  initialText: {
    color: colors.primaryLightGrey,
    fontSize: 13,
    marginTop: 5,
    marginLeft: 5
  },
  leftContentMargin: { marginTop: 10 },
  inputControlBox: {
    backgroundColor: colors.white,
    marginTop: 24
  },
  formLabelControl: {
    width: 250,
    backgroundColor: colors.white,
    marginTop: 24,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '7.5px 30px 7.5px 14px',
      marginTop: 7
    }
  },
  formLabelControlTime: {
    width: 45,
    backgroundColor: colors.white,
    marginTop: 16,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 7.5,
      paddingBottom: 7.5,
      paddingLeft: 4,
      paddingRight: 4,
      marginTop: 0,
      textAlign: 'center'
    }
  },
  formDayLabelControl: {
    // width: '30%',
    // marginTop:2,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '2.5px 14px',
      margin: '4px 0'
    }
  },
  formLabelControlBordered: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: 3,
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10
    }
  },
  formDateControlFromBordered: {
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: 3,
    padding: 0,
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-input': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10
    }
  },
  formDateControlFromBorderedError: {
    border: `1px solid ${colors.error}`,
    borderRadius: 3,
    padding: 0,
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-input': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10
    }
  },
  editorClassName: {
    padding: 10,
    backgroundColor: colors.white
  },
  wrapperClassName: {
    width: 570,
    borderTop: `1px solid ${colors.primaryLightGrey}`,
    borderLeft: `1px solid ${colors.primaryLightGrey}`,
    borderRight: `1px solid ${colors.primaryLightGrey}`,
    borderTopRadius: '5px',
    borderLeftRadius: '5px',
    borderRightRadius: '5px',
    marginTop: '20px'
  },
  wrapperClassNameWithBottomBorder: {
    width: 570,
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: '5px',
    marginTop: '20px'
  },
  wrapperSecondEditorClassName: {
    width: 570,
    // borderTop: `1px solid ${colors.primaryLightGrey}`,
    borderLeft: `1px solid ${colors.primaryLightGrey}`,
    borderRight: `1px solid ${colors.primaryLightGrey}`,
    borderBottom: `1px solid ${colors.primaryLightGrey}`,
    borderTopRadius: '5px',
    borderLeftRadius: '5px',
    borderRightRadius: '5px'
    // marginTop: '20px'
  },
  toolbarClassName: {
    border: 'none',
    marginBottom: 0,
    '& .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-target-option': {
      display: 'none'
    }
  },
  linkedButton: {
    height: 40,
    width: 191,
    borderRadius: 3,
    backgroundColor: colors.secondaryGreyLight,
    marginLeft: 15,
    marginBottom: 15,
    color: colors.primaryDarkGrey,
    fontSize: 16,
    boxShadow: 'none'
  },
  includeText: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey
  },
  iconTrashStyle: {
    fontSize: 22,
    verticalAlign: 'middle',
    color: colors.primaryLightGrey
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modalBox: {
    width: 480
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  modalActions: {
    marginTop: '10%'
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  chipClass: {
    position: 'relative',
    top: 12,
    zIndex: 1,
    left: 30
  },
  groupRadioClass: {
    flexDirection: 'row',
    '& .MuiFormControlLabel-root .MuiIconButton-root': {
      padding: '9px 5px'
    }
  },
  radio: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  checkbox: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  switchBase: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    },
    '&$checked + $track': {
      backgroundColor: colors.primary
    }
  },
  checked: {
    fontWeight: 900
  },
  menuItemClass: {
    // backgroundColor: '#ff0000',
    padding: '2px 16px'
  },
  descriptionContainer: {
    width: 572,
    borderLeft: `1px solid ${colors.primaryLightGrey}`,
    borderRight: `1px solid ${colors.primaryLightGrey}`
  },
  descriptionMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    backgroundColor: colors.white
  },
  descriptionImageContainer: {
    height: 18,
    width: 18,
    opacity: 0.4
  },
  descriptionTextContainer: {
    margin: 0,
    fontSize: 14,
    color: colors.primaryLightGrey,
    padding: 10
  },
  orDivider: {
    marginTop: 24,
    borderBottom: `1px solid ${colors.primaryLightGrey}`,
    position: 'relative',
    '& span': {
      position: 'absolute',
      transform: 'translateY(-50%)',
      padding: 5,
      background: colors.tertiary,
      left: 0,
      right: 0,
      maxWidth: 50,
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center'
    }
  }
}));
