import { makeStyles } from '@material-ui/core';
import { colors } from 'src/utils/constant';

export const formStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  textEditor: {
    maxHeight: `calc(100vh - 150px)`
  },
  textEditorOnEdit: {
    maxHeight: `calc(100vh - 230px)`
  },
  drawerRightContent: {
    width: '40%',
    height: `calc(100vh - 10px)`,
    backgroundColor: '#f4f5f7',
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  leftContentHeader: {
    textAlign: 'right'
  },
  drawerLeftContent: {
    width: `calc(60% - 20px)`,
    height: `calc(100vh - 30px)`,
    borderRadius: 5,
    backgroundColor: 'rgb(255, 245, 224,1)',
    float: 'left',
    position: 'relative',
    margin: 10
  },
  leftContentMargin: {
    marginLeft: 40,
    marginRight: 40
  },
  siderEdit: {
    height: 40,
    marginTop: 19
  },
  handBookContainer: { position: 'absolute', bottom: -20, right: -10 },
  handBookClass: { width: 360, height: 297, opacity: 0.32 },
  textwraplabel: {
    display: 'inline-block',
    width: '320px',
    'white-space': 'nowrap',
    overflow: 'hidden !important',
    'text-overflow': 'ellipsis',
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: 0.13,
    color: colors.primaryDarkGrey,
    marginTop: 20,
    float: 'left'
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    height: 71
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primaryDarkGrey
  },
  siderDetail: {
    fontSize: 14,
    color: colors.primaryDarkGrey,
    marginBottom: 8
  },
  toolbarHidden: {
    display: 'none'
  },
  toolbar: {
    backgroundColor: 'transparent',
    border: 0,
    '& .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-target-option': {
      display: 'none'
    }
  },
  bredthcrumbTitle: {
    color: '#536e7b',
    fontSize: 16,
    marginRight: 24,
    display: 'inline-block',
    cursor: 'pointer'
  },
  tabNavigations: {
    padding: '0 20px 0',
    position: 'relative'
  },
  navigationButton: {
    width: 120,
    textTransform: 'none'
  },
  actionButton: {
    margin: '15px 0 20px',
    textTransform: 'none'
  },
  activeTab: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0
  },
  inputLabel: {
    color: '#000000',
    fontSize: 15,
    marginRight: 24
  },
  profileImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#BFC5D1',
    margin: '19px 18px 20px 12px',
    width: '58%',
    height: '180px',
    borderRadius: '7px',
    justifyContent: 'center'
  },
  socialImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#BFC5D1',
    margin: '19px 18px 20px 12px',
    width: '58%',
    height: '180px',
    borderRadius: '7px',
    justifyContent: 'center'
  },
  placeholderImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#BFC5D1',
    margin: '19px 18px 20px 12px',
    width: '58%',
    height: '180px',
    borderRadius: '7px',
    justifyContent: 'center'
  },
  placeholderContainer: {
    width: '100%',
    height: 80,
    fontSize: '32px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: '#ffffff'
  },
  imageContainer: {
    width: '100%',
    height: 180,
    fontSize: '32px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: '#ffffff'
  },
  supportPersonImageContainer: {
    width: 140,
    height: 140,
    fontSize: '32px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: '#ffffff',
    margin: '10px  0 10px 12px'
  },
  socialImageContainer: {
    width: '100%',
    height: 180,
    fontSize: '32px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: '#ffffff'
  },
  copyIcon: {
    color: '#ffffff',
    width: '20%',
    height: '120px',
    backgroundColor: 'green',
    justifyContent: 'center'
  },
  errorTextBox: {
    borderColor: 'red',
    borderWidth: 1,
    border: '1px solid red',
    borderRadius: 4
  },
  boxContentLabel: {
    width: '20%',
    fontSize: '17px',
    margin: '14px 0 0 10px'
  },
  cancelButton: {
    width: 106,
    borderRadius: 3,
    border: `solid 1 ${colors.primaryLightGrey}`,
    backgroundColor: colors.primaryLightGrey,
    float: 'left'
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.width,
    height: 50,
    width: 94,
    margin: '0 0 40px 0'
  },
  uploadImageText: {
    textDecoration: 'underline',
    fontSize: '15px',
    cursor: 'pointer',
    margin: '106% 0 0 140%',
    width: '100%'
  },
  uploadImageTextSupportPerson: {
    textDecoration: 'underline',
    fontSize: '15px',
    cursor: 'pointer',
    margin: '80% 0 0 355%',
    width: '100%'
  },
  resetSupportImage: {
    textDecoration: 'underline',
    fontSize: '15px',
    cursor: 'pointer',
    margin: '65px -178px 0 362px',
    width: '100%'
  },
  uploadImageTextSocialImage: {
    textDecoration: 'underline',
    fontSize: '15px',
    cursor: 'pointer',
    margin: '106% 0 0 120%',
    width: '100%'
  },
  removeImageContainer: {
    margin: '-123px 0 0 87%',
    textDecoration: 'underline',
    fontSize: '15px',
    cursor: 'pointer'
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  subtitle: {
    maxWidth: 576,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey,
    marginBottom: 40
  },
  formTitle: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  contentWrapper: {
    maxWidth: 572,
    width: '100%',
    marginLeft: '24%',
    marginTop: '3%',
    display: 'flex',
    flexDirection: 'row'
  },
  formControl: {
    width: '100%'
  },
  pauseContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '21px 23px 0 0'
  },
  unPublishText: {
    textDecoration: 'underline',
    color: '#808080',
    float: 'left'
  },
  controlBox: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#ffffff',
    '& .MuiOutlinedInput-root:hover, & .MuiOutlinedInput-root:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  inputControlBox: {
    '& .MuiSelect-outlined:hover, & .MuiSelect-outlined:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalActions: {
    marginTop: '2%'
  },
  modalSubHeading: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  modalContent: {
    minHeight: 75,
    paddingTop: 12
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark
  },
  modalBox: {
    width: 450
    // height: 170,
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  deleteButton: {
    width: 163,
    height: 50,
    position: 'absolute',
    top: 250,
    right: 100
  },
  deleteText: {
    color: colors.error,
    textDecoration: 'underline'
  },
  buttonTextSave: {
    height: 24,
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center'
  },
  errors: {
    fontSize: '14px',
    color: 'red !important',
    width: '100%',
    textAlign: 'left'
  },
  viewFormButton: {
    width: 163,
    height: 53,
    backgroundColor: colors.primary,
    color: '#ffffff',
    margin: '35px 0 0 20px',
    '&:hover': {
      backgroundColor: colors.primary
    }
  },
  disableViewFormButton: {
    width: 163,
    height: 53,
    backgroundColor: colors.primary,
    color: '#ffffff',
    margin: '35px 0 0 20px',
    '&:disabled': {
      backgroundColor: '#cccccc',
      color: '#ffffff'
    }
  },
  studentReportPopUp: {
    width: '240px',
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#4c5b68',
    color: '#ffffff',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5
  },
  reportImage: {
    position: 'absolute',
    right: 0,
    top: -35,
    cursor: 'pointer',
    zIndex: 5
  },
  flexBoxBetween: { display: 'flex', justifyContent: 'space-between' }
}));
