import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { colors } from '../utils/constant';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalWrapper: {
    padding: 25,
    borderRadius: 4,
    boxShadow: `0 0 25px 0 rgba(0, 0, 0, 0.35)`,
    backgroundColor: colors.white,
    outline: 0
  }
}));

export default function SimpleModal({ children, width, ...props }) {
  const classes = useStyles({ width });
  const [state, setState] = useState(false);
  const handleClose = () => {
    setState(false);
    props.onClose && props.onClose();
  };

  useEffect(() => {
    setState(props.isOpen);
  }, [props.isOpen]);

  return (
    <React.Fragment>
      <Modal
        className={classes.modal}
        open={state}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick
      >
        <div className={clsx(classes.modalWrapper, props?.modalWrapperStyle)}>
          {children}
        </div>
      </Modal>
    </React.Fragment>
  );
}
