import { makeStyles } from '@material-ui/core';

// Constants
import { colors } from 'src/utils/constant';

export const useStyles = makeStyles((theme) => ({
  constWrapper: {
    marginLeft: 55,
    marginRight: 55
  },
  imageWrapper: {
    marginRight: '20px'
  },
  button: {
    textDecorationColor: 'transparent',
    textTransform: 'none',
    padding: 0
  },
  resendCode: {
    color: '#8CC60F'
  },
  resendInput: {
    display: 'flex',
    marginTop: 56,
    '& > *': {
      margin: theme.spacing(1),
      width: '7ch'
    }
  },
  formField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginTop: theme.spacing(2.25),
    marginBottom: theme.spacing(0.5),
    '& input': {
      textAlign: 'center',
      height: '50px',
      fontSize: 'xxx-large'
    },
    // / Chrome, Safari, Edge, Opera /
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    // / Firefox /
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: colors.tertiaryRed
    }
  },
  errorMessage: {
    marginTop: 30,
    height: 24,
    objectFit: 'contain',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.tertiaryRed
  },
  codeText: {
    height: 36,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 0.09,
    color: colors.primaryLightGrey
  }
}));
