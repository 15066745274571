import React, { useEffect, useState } from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, Modifier } from 'draft-js';
import { getEntityRange, getSelectionEntity } from 'draftjs-utils';
import { IconButton } from '@material-ui/core';
import Modal from 'src/components/Modal';
import { copyFileUrl } from 'src/utils/constant';
import AttachFilesModal from 'src/views/families/components/AttachFilesModal';
import htmlToDraft from 'html-to-draftjs';
import { toGetHTMLContent } from 'src/utils/functions';

const CustomOption = ({ onAttachClick }) => {
  return (
    <IconButton onClick={onAttachClick}>
      <AttachFileIcon style={{ fontSize: '20px' }} />
    </IconButton>
  );
};

const EditorComponent = ({
  preserveData,
  classes,
  wrapperClassName,
  index,
  isReadOnly,
  onChange
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isOpenAttachModal, setOpenAttachModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (preserveData && !isChanged) {
      const contentBlock =
        typeof preserveData === 'string'
          ? htmlToDraft(preserveData || '')
          : htmlToDraft(toGetHTMLContent(preserveData || ''));

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const prefilledState = EditorState.createWithContent(contentState);
        setEditorState(prefilledState);
      }
      setIsChanged(true);
    }
  }, [preserveData]);

  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
    setIsChanged(true);
  };

  const onAttachClick = () => {
    setOpenAttachModal(true);
  };

  const handleInsertClick = async (link) => {
    const updatedLink = `${copyFileUrl}${link}`;
    const linkTitle = updatedLink;
    const currentEntity = editorState
      ? getSelectionEntity(editorState)
      : undefined;
    let selection = editorState.getSelection();
    if (currentEntity) {
      const entityRange = getEntityRange(editorState, currentEntity);
      const isBackward = selection.getIsBackward();
      if (isBackward) {
        selection = selection.merge({
          anchorOffset: entityRange.end,
          focusOffset: entityRange.start
        });
      } else {
        selection = selection.merge({
          anchorOffset: entityRange.start,
          focusOffset: entityRange.end
        });
      }
    }

    const entityKey = editorState
      .getCurrentContent()
      .createEntity('LINK', 'MUTABLE', {
        url: updatedLink,
        targetOption: '_blank'
      })
      .getLastCreatedEntityKey();

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      selection,
      `${linkTitle}`,
      editorState.getCurrentInlineStyle(),
      entityKey
    );
    const newEditorState = EditorState.push(
      editorState,
      contentState,
      'insert-characters'
    );
    setEditorState(newEditorState);
    onChange(newEditorState, index);
    await new Promise((resolve) => setTimeout(resolve, 100));
    setOpenAttachModal(false);
  };

  return (
    <>
      <Editor
        stripPastedStyles={true}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        placeholder={'Add description...'}
        toolbarClassName={classes.toolbarClassName}
        editorClassName={classes.editorClassName}
        wrapperClassName={wrapperClassName}
        toolbar={{
          options: ['inline', 'list', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
          },
          list: {
            options: ['unordered']
          },
          link: {
            defaultTargetOption: '_blank',
            options: ['link']
          }
        }}
        toolbarCustomButtons={[
          <CustomOption key={index} onAttachClick={onAttachClick} />
        ]}
        name={`entries[${index}].description`}
        onChange={() => {
          onChange(editorState, index);
        }}
        readOnly={isReadOnly}
      />
      <Modal
        isOpen={isOpenAttachModal}
        onClose={() => setOpenAttachModal(false)}
        modalWrapperStyle={classes.attachModalWrapper}
      >
        <AttachFilesModal
          data={isOpenAttachModal}
          handleInsertClick={handleInsertClick}
          onClose={() => setOpenAttachModal(false)}
          isSchoolFiles={true}
        />
      </Modal>
    </>
  );
};

export default EditorComponent;
