import moment from 'moment';

export const weekOfMonth = (m, v, currentNo = false) => {
  const statOfM = moment(m, 'MMM DD,YYYY').startOf('month');
  const daysInMonth = statOfM.daysInMonth();
  let weekDayNumber = 0;
  for (let i = 0; i < daysInMonth; i++) {
    const newMDate = moment(statOfM, 'MMM DD,YYYY').add(i, 'days');
    const diffDateInDay = moment(m, 'MMM DD,YYYY').diff(newMDate, 'days');
    if (currentNo) {
      if (
        newMDate.format('ddd') === moment(v, 'MMM DD,YYYY').format('ddd') &&
        diffDateInDay >= 0
      ) {
        weekDayNumber = weekDayNumber + 1;
      }
    } else {
      if (newMDate.format('ddd') === moment(v, 'MMM DD,YYYY').format('ddd')) {
        weekDayNumber = weekDayNumber + 1;
      }
    }
  }

  return weekDayNumber;
};
