import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function CheckCircleIcon({ color, ...props }) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix_checkCircle" fill="#fff">
          <path
            id="prefix_checkCircle1"
            d="M16.104 2.784c.462.206.67.748.464 1.21-.206.463-.748.671-1.21.465-3.338-1.487-7.256-.602-9.63 2.176-2.373 2.777-2.638 6.785-.649 9.85 1.989 3.065 5.756 4.457 9.26 3.421 3.424-1.012 5.799-4.108 5.907-7.658L20.25 12v-.843c0-.507.41-.917.917-.917.47 0 .857.354.91.81l.006.107V12c-.002 4.465-2.942 8.397-7.224 9.663s-8.887-.435-11.318-4.181c-2.43-3.746-2.107-8.645.794-12.04C7.236 2.05 12.025.967 16.104 2.784zm5.71 1.234c.331.33.357.85.077 1.21l-.076.087-9.166 9.175c-.33.331-.851.357-1.21.077l-.087-.076-2.75-2.75c-.358-.358-.358-.939 0-1.297.33-.33.85-.356 1.21-.076l.086.076L12 12.545l8.518-8.526c.358-.358.938-.359 1.297 0z"
          />
        </mask>
        <g fill={color || '#4C5B68'} mask="url(#prefix_checkCircle)">
          <path d="M0 0H24V24H0z" />
        </g>
      </g>
    </SvgIcon>
  );
}
export default CheckCircleIcon;
