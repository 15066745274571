/* eslint-disable camelcase */
import {
  Divider,
  Paper,
  Typography,
  TableCell,
  TableRow,
  Backdrop,
  CircularProgress,
  Link,
  Button
} from '@material-ui/core';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment-timezone';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Edit, HandBook } from 'src/assets';
import { RightArrow } from 'src/assets/Icons';
import Dashboard from 'src/views/layout/Dashboard';
import api_icon from 'src/assets/apiIcon.svg';
import schoolBytes from 'src/assets/schoolByte2.webp';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { routes } from 'src/utils/constant';
import { year as DefaultYear, shortYears } from 'src/utils/constant';
import NavTab from '../NavTab';
import TableList from 'src/components/TableList';
import {
  addMessage,
  loaderStart,
  loaderStop
} from 'src/redux/actions/appActions';
import { setSchool } from 'src/redux/actions/schoolActions';
import { withFirebase } from 'src/utils/firebase';
import CreateEditStudent from './CreateEditStudent';
import clsx from 'clsx';
import studentReportingIcon from 'src/assets/icn-student-reporting.svg';
import {
  addSchoolFormDetailsOrUpdateSchool,
  getSchoolByID,
  getSchools
} from 'src/services/schools';
import RightDrawer from 'src/components/RightDrawer';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ContentState } from 'draft-js';
import { EditorState } from 'draft-js';
import { getGroupBySchoolId } from 'src/services/groups';
import { studentIndexStyles } from 'src/styles/schoolStyle/students/studentIndexStyles';
import {
  createStudent,
  getStudent,
  getStudentsBySchool,
  updateStudent
} from 'src/services/students';
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  MESSAGE_TYPES,
  SEARCH_DEBOUNCE_TIME
} from 'src/constants/common';

function CellItem({ row, column }) {
  const classes = studentIndexStyles();
  const childVisibility = useSelector((state) => state?.auth?.user?.country);
  if (column.id === 'lastName') {
    return (
      <span
        className={classes.hoverTag}
        onClick={() => {}}
        style={{ fontWeight: 'bold' }}
      >
        {row?.lastName}
      </span>
    );
  }
  if (column.id === 'memberReferences') {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        {row?.memberReferences?.map((member, index) => {
          return (
            <a
              key={index}
              href={`/members/users/${member.id}`}
              onClick={(e) => e.stopPropagation()}
            >
              {`${member.firstName} ${member.lastName}`}
            </a>
          );
        })}
      </div>
    );
  }
  if (column.id === 'year') {
    const yearIndex = DefaultYear.findIndex((y) => y.value === row?.year);
    return yearIndex !== -1 && shortYears[DefaultYear[yearIndex].label];
  }
  if (column.id === 'firstName') {
    return (
      <span
        className={clsx({
          [classes.redacted]: childVisibility !== 'Australia'
        })}
      >
        {row?.firstName}
      </span>
    );
  }
  if (column.id === 'statusActivatedAt') {
    return row?.memberReferences?.length > 0
      ? moment(row?.memberReferences[0]?.currentStatusDate)
          .tz('Australia/Sydney')
          .format('DD MMM YY')
      : '';
  }

  if (column.id === 'unLinkedType') {
    return row?.unLinkedType || '';
  }

  if (column.id === 'unLinkedDate') {
    return row?.unLinkedDate ? moment(row?.unLinkedDate).tz('Australia/Sydney')
    .format('DD MMM YY') : '';
  }

  if (column.id === 'apiSync' && row?.createdByApiSync) {
    return <img src={api_icon} />;
  }
  return '';
}

function TableRowItem({ row, columns, onClick }) {
  const classes = studentIndexStyles();
  return (
    <TableRow
      hover
      tabIndex={-1}
      className={classes.tableRow}
      onClick={onClick}
    >
      {columns.map((column) => {
        return (
          <TableCell
            key={column.id}
            align={column.align}
            className={classes.dataTableCell}
          >
            <CellItem row={row} column={column} />
          </TableCell>
        );
      })}
    </TableRow>
  );
}

const columns = [
  {
    id: 'lastName',
    label: 'Account Name',
    width: 350,
    align: 'left'
  },
  {
    id: 'firstName',
    label: 'First Name',
    width: 320,
    align: 'left'
  },
  {
    id: 'year',
    label: 'Year',
    width: 230,
    align: 'left'
  },
  {
    id: 'memberReferences',
    label: 'Members',
    align: 'left',
    width: 320,
    isLink: true
  },
  {
    id: 'statusActivatedAt',
    label: 'Activated',
    align: 'left',
    width: 130
  },
  {
    id: 'unLinkedType',
    label: 'Reason',
    align: 'left',
    isLink: true
  },
  {
    id: 'unLinkedDate',
    label: 'Unlink Date',
    align: 'left',
    width: 130
  },
  {
    id: 'apiSync',
    label: '',
    width: 100,
    align: 'center',
    format: 'icon',
    iconPosition: 'center',
    icon: false
  }
];

const SchoolStudents = () => {
  const classes = studentIndexStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const prefillSchoolData = useSelector((state) => state.school.currentSchool);
  const isLoading = useSelector((state) => state.app.isLoading);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [rowsCountPerPage, setRowsCountPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [yearsData, setYearsData] = useState();
  const [year, setYear] = useState('');
  const [memberStatus, setMemberStatus] = useState('Active');
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [schools, setSchools] = useState();
  const [showClearFilters, setShowClearFilters] = useState(false);
  const [isOpenChildDrawer, setIsOpenChildDrawer] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreateStudent, setIsCreateStudent] = useState(false);
  const [currentStudent, setCurrentStudent] = useState({});
  const [groups, setGroups] = useState();
  const [categories, setCategories] = useState();
  const [openStudentReportPopUp, setOpenStudentReportPopUp] = useState(false);
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [memberData, setMemberData] = useState('');
  const [defaultNote, setDefaultNote] = useState('');
  const [shouldUpdateStudentList, setShouldUpdateStudentList] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [createdBy, setCreatedBy] = useState('Manual'); // ['API', 'Manual']
  const [accountStatusCount, setAccountStatusCount] = useState({
    activeCount: 0,
    inActiveCount: 0,
    unLinkedCount: 0
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDebouncedSearchValue = useCallback(
    _.debounce((debouncedValue) => {
      setDebouncedSearchText(debouncedValue);
    }, SEARCH_DEBOUNCE_TIME),
    []
  );

  const schoolID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  useEffect(() => {
    if (schoolID) {
      fetchYearsOnSchoolChange(schoolID);
    }
  }, [schoolID]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchYearsOnSchoolChange = useCallback(async (schoolId) => {
    // PHASE2/CHANGES/SCHOOL

    try {
      const school = await getSchoolByID(schoolId);
      const calendars = school.freeCalendars;
      const yearsData = DefaultYear.filter((y) => calendars.includes(y.value));
      setYearsData(yearsData);
      const categories = [];
      school.categories.forEach((category) => {
        categories.push(category);
      });
      setCategories(categories);
      fetchGroups(schoolId);
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    fetchData({
      schoolId: schoolID,
      pageNo: Number(page) + 1,
      pageSize: rowsCountPerPage,
      searchText: debouncedSearchText,
      year,
      ...(createdBy !== 'all' ? { createdBy } : {}),
      accountStatus: capitalizeStatus(memberStatus)
    });
  }, [
    schoolID,
    page,
    rowsCountPerPage,
    debouncedSearchText,
    year,
    createdBy,
    memberStatus
  ]);

  useEffect(() => {
    if (shouldUpdateStudentList) {
      fetchData({
        schoolId: schoolID,
        pageNo: Number(page) + 1,
        pageSize: rowsCountPerPage,
        searchText: debouncedSearchText,
        year,
        ...(createdBy !== 'all' ? { createdBy } : {}),
        accountStatus: capitalizeStatus(memberStatus)
      }); 
      setShouldUpdateStudentList(false);
    }
  }, [
    schoolID,
    page,
    rowsCountPerPage,
    debouncedSearchText,
    year,
    createdBy,
    memberStatus,
    shouldUpdateStudentList
  ]);

  function capitalizeStatus(status) {
    if (typeof status !== 'string') {
      throw new TypeError('The input must be a string.');
    }
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }

  const handleChangeCreatedBy = (e) => {
    setCreatedBy(e.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback(
    async ({
      schoolId,
      pageNo,
      pageSize,
      searchText,
      year,
      createdBy,
      accountStatus
    }) => {
      dispatch(loaderStart());

      getStudentsBySchool({
        schoolId,
        pageNo,
        pageSize,
        searchText,
        year,
        createdBy,
        accountStatus
      })
        .then((response) => {
          const result = response?.data;
          setTableData(result.data);
          setTotalRecords(result.totalRecords);
          dispatch(loaderStop());
          setAccountStatusCount(result.accountStatusCount);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(loaderStop());
        })
        .finally(() => {
          dispatch(loaderStop());
        });

      // PHASE2/CHANGES/SCHOOL

      try {
        const result = await getSchools();
        const schools = [];
        result.forEach((school) => {
          const yearData = school.freeCalendars.map((calendar) => {
            const getCurrentYear = _.find(
              DefaultYear,
              (elem) => elem.value === calendar
            );
            return {
              label: getCurrentYear.label,
              value: calendar
            };
          });
          schools.push({
            id: school.id,
            name: school.schoolName,
            years: yearData.length ? yearData : []
          });
        });
        setSchools(schools);
      } catch (error) {
        console.error(error);
        dispatch(
          addMessage(
            'Server connection issue. Please refresh',
            // eslint-disable-next-line no-undef
            MESSAGE_TYPES.ERROR
          )
        );
      }
    }
  );

  const fetchGroups = (schoolId) => {
    if (schoolId) {
      getGroupBySchoolId(schoolId)
        .then(function (result) {
          const groupsList = [];
          result.data.forEach(function (item) {
            groupsList.push({
              key: item.groupName,
              value: item.id,
              years: item.years,
              selected: false,
              groupMembers: item.groupMembers,
              category: item.category ? [item.category] : [],
              createdByApiSync: item.createdByApiSync || false,
              autoAssignedMembers: item?.autoAssignedMembers
            });
          });
          if (groupsList.length > 0) {
            groupsList.push({
              key: 'Select All',
              value: 'all',
              selected: false
            });
          }
          setGroups(groupsList);
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
          dispatch(
            addMessage(
              'Server connection issue. Please refresh',
              // eslint-disable-next-line no-undef
              MESSAGE_TYPES.ERROR
            )
          );
        });
    }
  };

  const { schoolName } = useMemo(() => {
    if (prefillSchoolData) {
      return {
        schoolName: prefillSchoolData.schoolName,
        id: prefillSchoolData.id
      };
    }
    return {
      schoolName: '',
      id: ''
    };
  }, [prefillSchoolData]);

  useEffect(() => {
    if (!prefillSchoolData.schoolName && schoolID) {
      // PHASE2/CHANGES/SCHOOL

      getSchoolByID(schoolID)
        .then((school) => {
          if (school) {
            dispatch(setSchool(school));
          }
        })
        .catch((error) => {
          console.error(error);
          // eslint-disable-next-line no-undef
          dispatch(addMessage('Error on getting school', MESSAGE_TYPES.ERROR));
        });
    }
  }, [prefillSchoolData.schoolName, schoolID, dispatch]);

  const navTabsOptions = useMemo(() => {
    return [
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Calendar',
              link: `${routes.schoolCalendar}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Groups',
        link: `${routes.schoolGroup}/${schoolID}`
      },
      {
        label: 'Students',
        link: `${routes.schoolStudent}/${schoolID}`
      },
      {
        divider: true
      },
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Links',
              link: `${routes.schoolLinks}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Notes',
        handleTabClick: () => openRightDrawer(prefillSchoolData)
      },
      {
        label: 'Files',
        link: `${routes.schoolFiles}/${schoolID}`
      },
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Form',
              link: `${routes.schoolForm}/${schoolID}`
            }
          ]
        : []),
      {
        divider: true
      },
      {
        label: 'Account',
        link: `${routes.schoolAccount}/${schoolID}`
      }
    ];
  }, [prefillSchoolData]);

  // Page change next/prev function
  const handlePageChange = (currentPage, newPage) => {
    setPage(newPage);
  };

  // Page size change function
  const handleRowsPerPageChange = (rowsPerPage) => {
    setRowsCountPerPage(rowsPerPage);
    setPage(DEFAULT_PAGE);
  };

  // Search text function
  const searchChangeHandler = (e) => {
    const { value } = e.target;
    const text = value?.toLowerCase();
    setSearchText(text);
    getDebouncedSearchValue(text);
    setPage(DEFAULT_PAGE);
  };

  const onClose = () => {
    setEditMode(false);
    setOpenDrawer(false);
  };

  const onCloseForm = () => {
    const defaultData = draftToHtml(
      convertToRaw(defaultNote.getCurrentContent())
    );
    const memberNotes = memberData.notes;
    if (defaultData === memberNotes) {
      // handleClose();
      onClose();
    } else if (
      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
      !memberNotes
    ) {
      onClose();
    } else if (memberNotes === '') {
      // handleClose();
      onClose();
    }
  };

  const onSaveChanges = async () => {
    // PHASE2/CHANGES/SCHOOL
    dispatch(loaderStart());

    const payload = {
      notes: memberData?.notes
    };

    try {
      await addSchoolFormDetailsOrUpdateSchool(prefillSchoolData.id, payload);
      dispatch(loaderStop());
      dispatch(setSchool(payload));
      dispatch(addMessage('Changes saved'));
      const contentBlock = htmlToDraft(payload?.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      handleClose();
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line no-undef
      dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  const onEditorChange = (value) => {
    const editorData = draftToHtml(convertToRaw(value.getCurrentContent()));
    setMemberData({ ...memberData, notes: editorData });
  };

  const openRightDrawer = useCallback(
    (data) => {
      setMemberData(data);
      const contentBlock = htmlToDraft(data.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      setOpenDrawer(true);
    },
    [setOpenDrawer, prefillSchoolData]
  );

  const yearChangeHandler = (e) => {
    setYear(e.target.value);
    setShowClearFilters(e.target.value ? true : false);
    setPage(DEFAULT_PAGE);
  };

  const studentCategoryChangeHandler = (e) => {
    setShowClearFilters(true);
    setMemberStatus(e.target.value);
    setPage(DEFAULT_PAGE);
  };

  const handleClose = useCallback(
    (isUpdated) => {
      setIsEdit(false);
      setIsCreateStudent(false);
      setIsOpenChildDrawer(false);
      setCurrentStudent();
      if (isUpdated) {
        setShouldUpdateStudentList(true);
      }
    },
    [setIsOpenChildDrawer]
  );

  const resetFilters = () => {
    setSearchText('');
    setMemberStatus('Active');
    setPage(DEFAULT_PAGE);
    setYear('');
    setShowClearFilters(false);
  };

  const handleUpdate = (formData, groupData, student) => {
    if (student?.id) {
      const selectedGroups = groupData
        ?.map((g) => ({
          groupId: g?.value,
          isAdd: g?.selected
        }))
        .filter((g) => g.isAdd);

      const studentUpdatePayload = {
        firstName: formData?.firstName,
        lastName: formData?.surname,
        year: formData?.year,
        ...(student?.color ? { color: student?.color } : {}),
        school: formData?.school,
        groups: selectedGroups
      };

      dispatch(loaderStart());
      updateStudent(student.id, studentUpdatePayload)
        .then(async () => {
          dispatch(addMessage('Student updated successfully'));
          handleClose(true);
          dispatch(loaderStop());
          setShouldUpdateStudentList(true)
        })
        .catch(() => {
          dispatch(loaderStop());
          handleClose();
          dispatch(addMessage('Error on updating student'));
        });
    } else {
      dispatch(loaderStart());
      createStudent({
        firstName: formData?.firstName,
        lastName: formData?.surname,
        year: formData?.year,
        school: formData?.school,
        ...(formData?.memberIds ? { memberIds: formData?.memberIds } : {}),
        groupIds: groupData?.filter((g) => g.selected).map((g) => g?.value)
      })
        .then(() => {
          dispatch(
            addMessage(`Student ${isEdit ? 'updated' : 'created'} successfully`)
          );
          dispatch(addMessage('Student created successfully'));
          handleClose(true);
          dispatch(loaderStop());
          setShouldUpdateStudentList(true)
        })
        .catch((error) => {
          console.log('error', error)
          dispatch(loaderStop());
          dispatch(
            addMessage(`Error on ${isEdit ? 'updating' : 'creating'} a student`, MESSAGE_TYPES.ERROR)
          );
        });
    }
  };

  const handleOpenEditModal = useCallback(
    (row) => {
      // TODO:

      if (row?.id) {
        dispatch(loaderStart());
        setIsEdit(true);
        getStudent(row?.id)
          .then((response) => {
            const result = response.data;
            const student = {
              id: result?.id,
              firstName: result?.firstName,
              lastName: result?.lastName,
              school: result?.school,
              year: result?.year,
              groups: result?.groups?.map((g) => g.id),
              memberReferences: result?.memberReferences,
              createdByApiSync: result?.createdByApiSync
            };
            setIsOpenChildDrawer(true);
            setCurrentStudent(student);
            dispatch(loaderStop());
          })
          .catch((error) => {
            console.log('error', error);
            dispatch(loaderStop());
          });
      } else {
        setIsOpenChildDrawer(true);
        setIsCreateStudent(true);
      }
    },
    [setIsOpenChildDrawer, setCurrentStudent]
  );

  return (
    <Dashboard>
      <div className={classes.root}>
        <div>
          <Typography
            variant="body1"
            className={classes.bredthcrumbTitle}
            onClick={() => history && history.push(routes.schools)}
          >
            Schools
          </Typography>
          <RightArrow
            viewBox="0 0 24 24"
            height={24}
            width={24}
            style={{ fontSize: 22, verticalAlign: 'middle' }}
          />
        </div>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20, marginTop: 22 }}
        >
          {schoolName}
        </Typography>
        <Paper style={{ position: 'relative' }}>
          <img
            className={classes.reportImage}
            src={studentReportingIcon}
            alt="Student report"
            onClick={() => setOpenStudentReportPopUp(!openStudentReportPopUp)}
          />
          {openStudentReportPopUp ? (
            <article className={classes.studentReportPopUp}>
              <div style={{ fontWeight: 500, marginBottom: '10px' }}>
                Student reporting
              </div>
              {prefillSchoolData?.createdByApiSync ? (
                <div className={classes.flexBoxBetween}>
                  <span>Population</span>
                  <span>
                    {prefillSchoolData?.studentReport?.apiStudent || 0}
                  </span>
                </div>
              ) : null}
              <div className={classes.flexBoxBetween}>
                <span>Active</span>
                <span>{prefillSchoolData?.studentReport?.active || 0}</span>
              </div>
              <div className={classes.flexBoxBetween}>
                <span>Inactive</span>
                <span>{prefillSchoolData?.studentReport?.inActive || 0}</span>
              </div>
              {prefillSchoolData?.createdByApiSync ? (
                <div className={classes.flexBoxBetween}>
                  <span>Opportunity</span>
                  <span>
                    {prefillSchoolData?.studentReport?.nonRegistered || 0}
                  </span>
                </div>
              ) : null}
            </article>
          ) : null}
          <section
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 20px 0'
            }}
          >
            <div>
              <NavTab tabs={navTabsOptions} selectedTab={'Students'} />
            </div>
            {prefillSchoolData && prefillSchoolData?.createdByApiSync && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '1rem'
                  // padding: '0 2rem'
                }}
              >
                <img src={schoolBytes} height="38px" />
                <img src={api_icon} />
              </div>
            )}
          </section>
          <Divider />
          <TableList
            currentPage={page}
            columns={columns.filter((column) => {
              if (
                memberStatus === 'Unlinked' &&
                column.id !== 'memberReferences' &&
                column.id !== 'statusActivatedAt'
              ) {
                return true;
              } else if (
                memberStatus !== 'Unlinked' &&
                column.id !== 'unLinkedType' &&
                column.id !== 'unLinkedDate'
              ) {
                return true;
              }
            })}
            rows={tableData}
            totalCount={totalRecords}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            setRowsCountPerPage={setRowsCountPerPage}
            searchPlaceholderText={'Account name or member email'}
            year={year}
            years={yearsData && [...yearsData]}
            createdBy={createdBy}
            yearChangeHandlerFree={yearChangeHandler}
            searchChangeHandler={searchChangeHandler}
            onChangeCreatedBy={handleChangeCreatedBy}
            studentCategoryChangeHandler={studentCategoryChangeHandler}
            memberStatus={memberStatus}
            onCreateStudent={handleOpenEditModal}
            showClearFilters={showClearFilters}
            filterClearHandler={() => resetFilters(true)}
            accountStatusCount={accountStatusCount}
            tableRow={(row, index) => (
              <TableRowItem
                key={index}
                columns={columns.filter((column) => {
                  if (
                    memberStatus === 'Unlinked' &&
                    column.id !== 'memberReferences' &&
                    column.id !== 'statusActivatedAt'
                  ) {
                    return true;
                  } else if (
                    memberStatus !== 'Unlinked' &&
                    column.id !== 'unLinkedType' &&
                    column.id !== 'unLinkedDate'
                  ) {
                    return true;
                  }
                })}
                row={row}
                onClick={() => handleOpenEditModal(row)}
              />
            )}
            searchText={searchText}
          />
        </Paper>
      </div>
      {isOpenChildDrawer && (
        <CreateEditStudent
          isOpen={isOpenChildDrawer}
          onClose={handleClose}
          handleSchoolChange={fetchYearsOnSchoolChange}
          onUpdate={handleUpdate}
          schools={schools}
          years={yearsData}
          student={currentStudent}
          school={prefillSchoolData}
          groups={groups}
          categories={categories}
          isEdit={isEdit}
          isCreateStudent={isCreateStudent}
        />
      )}

      <RightDrawer
        isOpen={isOpenDrawer}
        onClose={() => {
          onClose();
          onCloseForm();
        }}
        width={900}
        style={{ overflowX: 'hidden' }}
      >
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <div className={classes.drawerLeftContent}>
          <div className={classes.leftContentMargin}>
            <div className={classes.leftContentHeader}>
              {isEditMode ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.siderEdit}
                  onClick={() => {
                    const defaultData = draftToHtml(
                      convertToRaw(defaultNote.getCurrentContent())
                    );
                    const memberNotes = memberData.notes;
                    if (defaultData === memberNotes) {
                      handleClose();
                    } else if (
                      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
                      !memberNotes
                    ) {
                      handleClose();
                    } else {
                      onSaveChanges();
                    }
                  }}
                  style={{ width: 165 }}
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.siderEdit}
                  onClick={() => setEditMode(true)}
                  startIcon={<img src={Edit} />}
                >
                  Edit
                </Button>
              )}
            </div>
            <Divider
              variant="fullWidth"
              style={{ marginBottom: 20, marginTop: 22 }}
            />
            {!isEditMode && !memberData.notes && (
              <Typography className={classes.siderDetail}>
                Click Edit to add a note...
              </Typography>
            )}
            {!isEditMode &&
              memberData.notes &&
              memberData.notes.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' && (
                <Typography className={classes.siderDetail}>
                  Click Edit to add a note...
                </Typography>
              )}
            <Editor
              stripPastedStyles={true}
              readOnly={!isEditMode}
              defaultEditorState={defaultNote}
              toolbarClassName={
                !isEditMode ? classes.toolbarHidden : classes.toolbar
              }
              onEditorStateChange={onEditorChange}
              editorClassName={
                !isEditMode ? classes.textEditor : classes.textEditorOnEdit
              }
              toolbar={{
                options: ['inline', 'list', 'link'],
                inline: {
                  options: ['bold', 'italic', 'underline', 'strikethrough']
                },
                list: {
                  options: ['unordered']
                },
                link: {
                  defaultTargetOption: '_blank',
                  options: ['link']
                }
              }}
            />
          </div>
        </div>
        <div className={classes.drawerRightContent}>
          <div style={{ padding: 18, zIndex: 1 }}>
            <div className={classes.header}>
              {memberData.schoolName && (
                <Typography
                  className={classes.textwraplabel}
                  title={memberData.schoolName}
                >
                  {memberData.schoolName}
                </Typography>
              )}
            </div>
            <Divider style={{ marginBottom: 20 }} />
            {memberData.country && (
              <>
                <Typography className={classes.heading}>COUNTRY</Typography>
                <Typography className={classes.siderDetail}>
                  {memberData.country}
                </Typography>
              </>
            )}
            {memberData.websiteUrl && (
              <>
                <Typography className={classes.heading}>WEBSITE</Typography>
                <Link
                  color="secondary"
                  component={RouterLink}
                  onClick={() => window.open(memberData.websiteUrl, '_blank')}
                  className={classes.siderDetail}
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                  Click Here
                </Link>
              </>
            )}
          </div>
          <div className={classes.handBookContainer}>
            <img src={HandBook} alt="" className={classes.handBookClass} />
          </div>
        </div>
      </RightDrawer>
    </Dashboard>
  );
};

export default withFirebase(SchoolStudents);
