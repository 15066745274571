import { makeStyles } from '@material-ui/core';
import { LOADER } from 'src/assets';
import { colors } from 'src/utils/constant';

export const filesStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  siderDetail: {
    fontSize: 16,
    color: colors.primaryDarkGrey,
    marginBottom: 8,
    '&:hover': {
      color: '#8bc517'
    }
  },
  tabNavigations: {
    padding: '0 20px 0',
    marginBottom: 15,
    position: 'relative'
  },
  navigationButton: {
    width: 120,
    textTransform: 'none'
  },
  actionButton: {
    margin: '15px 0 20px',
    textTransform: 'none'
  },
  activeTab: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0
  },
  bredthcrumbTitle: {
    color: '#536e7b',
    fontSize: 16,
    marginRight: 24,
    display: 'inline-block',
    cursor: 'pointer'
  },
  tableRow: {
    height: '72px',
    '&:hover': {
      backgroundColor: `rgba(244, 245, 247, 0.51) !important`,
      cursor: 'pointer'
    }
  },
  dataTableCell: {
    color: colors.primaryDark,
    padding: 8,
    '&:first-child': {
      paddingLeft: '2.1%'
    }
  },
  fileImage: {
    width: 56,
    height: 56,
    borderRadius: 5,
    border: 'solid 1px ##bfc5d1',
    backgroundColor: colors.secondaryGreyLight
  },
  fileStatus: {
    fontSize: 14,
    backgroundColor: '#e8f5e9',
    color: '#348f37',
    padding: '0 5px',
    marginLeft: 36
  },
  dateText: {
    lineHeight: '45px',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.11,
    color: colors.primaryDark
  },
  fileName: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '45px',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: colors.primaryDark,
    '&:hover': {
      color: '#8bc517',
      '& .action-items': {
        opacity: 1
      }
    }
  },
  fileNameFormat: {
    width: '15%',
    overflowWrap: 'break-word'
  },
  actionButtons: {
    opacity: 0
  },
  actionIcons: {
    margin: '0 10px'
  },
  modalBox: {
    height: 500,
    outline: 0
  },
  imagePreview: {
    height: '100%',
    margin: 'auto',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%'
  },
  pdfPreview: {
    height: 450,
    position: 'relative',
    zIndex: 1500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${LOADER})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%'
  },
  topHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#000000',
    color: 'white',
    height: '10%',
    paddingLeft: '1.6%',
    paddingRight: '1.3%',
    marginBottom: '4.4%'
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  primaryIconButton: {
    backgroundColor: '#8bc517',
    borderRadius: 2,
    padding: 4,
    marginLeft: 16,
    '&:hover': {
      backgroundColor: '#8bc517'
    }
  },
  backArrowMargin: {
    marginLeft: 16
  },
  tableContainer: {
    marginLeft: '-20px',
    width: `calc(100% + 40px)`,
    '& .MuiTableContainer-root': {
      boxShadow: 'none'
    }
  },
  tableContainerTopBorder: {
    borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
  buttonRoot: {
    border: '1px solid white',
    paddingLeft: 20,
    paddingRight: 20
  },
  buttonLabel: {
    color: '#fff'
  },
  filterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 134,
    '& .MuiSelect-root': {
      paddingTop: 12,
      paddingBottom: 4
    }
  },
  iconStyle: {
    fontSize: 22,
    verticalAlign: 'middle'
  },
  buttonLabelText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1
  },
  familySurnameStyle: {
    marginBottom: 20,
    marginTop: 22
  },
  iconWhite: {
    color: '#fff'
  },
  drawerLeftContent: {
    width: `calc(60% - 20px)`,
    height: `calc(100vh - 30px)`,
    borderRadius: 5,
    backgroundColor: 'rgb(255, 245, 224,1)',
    float: 'left',
    position: 'relative',
    margin: 10
  },
  drawerRightContent: {
    width: '40%',
    height: `calc(100vh - 10px)`,
    backgroundColor: '#f4f5f7',
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  leftContentHeader: {
    textAlign: 'right'
  },
  label: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: 0.13,
    color: colors.primaryDarkGrey,
    marginLeft: 14,
    marginTop: 20,
    float: 'left'
  },
  siderAvatar: {
    marginTop: 12,
    width: 40,
    float: 'left',
    color: colors.primaryDarkGrey,
    fontSize: 14,
    backgroundColor: colors.primaryLightGrey,
    fontWeight: 500
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    height: 71
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primaryDarkGrey
  },
  icon: {
    marginRight: 17,
    height: 24,
    width: 24
  },
  siderEdit: {
    height: 40,
    marginTop: 19
  },
  textArea: {
    width: '96%',
    height: '25%',
    borderRadius: 5,
    backgroundColor: '#fff5e0',
    border: 0,
    color: colors.primaryDarkGrey
  },
  leftContentMargin: {
    marginLeft: 40,
    marginRight: 40
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  toolbarHidden: {
    display: 'none'
  },
  toolbar: {
    backgroundColor: 'transparent',
    border: 0,
    '& .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-target-option': {
      display: 'none'
    }
  },
  textEditor: {
    maxHeight: `calc(100vh - 150px)`
  },
  textEditorOnEdit: {
    maxHeight: `calc(100vh - 230px)`
  },
  handBookContainer: { position: 'absolute', bottom: -20, right: -10 },
  handBookClass: { width: 360, height: 297, opacity: 0.32 },
  studentReportPopUp: {
    width: '240px',
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#4c5b68',
    color: '#ffffff',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5
  },
  reportImage: {
    position: 'absolute',
    right: 0,
    top: -35,
    cursor: 'pointer',
    zIndex: 5
  },
  flexBoxBetween: { display: 'flex', justifyContent: 'space-between' }
}));
