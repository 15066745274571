export const getRouteByParams = (route, params) => {
  let newRoute = route;
  Object.entries(params).forEach(([key, value]) => {
    newRoute = newRoute.replace(`:${key}`, value);
  });
  return newRoute;
};

export const getRouteByQueryParams = (route, queryParams) => {
  let newRoute = route;

  const queryParamsArr = Object.entries(queryParams).filter(
    (params) => params?.[1]
  );

  queryParamsArr.forEach(([key, value], index) => {
    const prefix = index === 0 ? '?' : '&';
    newRoute = `${newRoute}${prefix}${key}=${value}`;
  });

  return newRoute;
};
