import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function LinkIcon({ color, ...props }) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix_link" fill="#fff">
          <path
            id="prefix_link1"
            d="M10.305 8.33l.251.013c1.592.114 3.056.913 4.011 2.191.304.406.22.98-.185 1.283-.405.304-.98.22-1.283-.185-.637-.852-1.613-1.385-2.674-1.46-.99-.072-1.964.262-2.7.919l-.155.146-2.739 2.738c-1.39 1.439-1.37 3.726.045 5.14 1.365 1.366 3.544 1.431 4.978.194l.15-.138 1.568-1.568c.358-.357.938-.357 1.296 0 .33.33.356.85.076 1.21l-.076.087-1.579 1.579c-2.158 2.084-5.588 2.054-7.71-.067-2.065-2.066-2.148-5.373-.217-7.548l.161-.174 2.75-2.75c1.07-1.069 2.528-1.648 4.032-1.61zm10.116-4.742c2.065 2.066 2.148 5.373.217 7.548l-.161.174-2.75 2.75c-1.129 1.128-2.691 1.711-4.283 1.597-1.592-.114-3.056-.913-4.011-2.191-.304-.406-.22-.98.185-1.283.405-.304.98-.22 1.283.185.637.852 1.613 1.385 2.674 1.46.99.072 1.964-.262 2.7-.919l.155-.146 2.739-2.738c1.39-1.439 1.37-3.726-.045-5.14-1.365-1.366-3.544-1.431-4.98-.192l-.15.138-1.577 1.567c-.359.357-.94.356-1.296-.003-.33-.332-.354-.852-.073-1.21l.077-.087 1.586-1.577c2.158-2.084 5.588-2.054 7.71.067z"
          />
        </mask>
        <g fill={color || '#4C5B68'} mask="url(#prefix_link)">
          <path d="M0 0H24V24H0z" />
        </g>
      </g>
    </SvgIcon>
  );
}
export default LinkIcon;
