import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { HelpIcon } from '../assets/Icons';
import { routes, drawerWidth, colors } from '../utils/constant';
import Logo from './Logo';
import { Divider } from '@material-ui/core';
import memberIconDark from 'src/assets/NavIcons/memberIconDark.svg';
import memberIconGreen from 'src/assets/NavIcons/memberIconGreen.svg';
import schoolsGreen from 'src/assets/NavIcons/schoolsGreen.svg';
import schoolsDark from 'src/assets/NavIcons/schoolsDark.svg';
import familiesDark from 'src/assets/NavIcons/familiesDark.svg';
import familiesGreen from 'src/assets/NavIcons/familiesGreen.svg';
import consoleIconGreen from 'src/assets/NavIcons/consoleIconGreen.svg';
import consoleIconDark from 'src/assets/NavIcons/consoleIconDark.svg';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },

  headerLogo: {
    padding: '16px 0',
    cursor: 'pointer'
  },
  drawer: {
    // width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#F4F6F8',
    border: 'none'
  },
  drawerContainer: {
    overflow: 'auto',
    flex: 1
  },
  menuContainer: {
    marginTop: 40
  },
  menuItem: {
    '& .MuiTypography-root': {
      fontWeight: 'bold'
    }
  },
  selectedItem: {
    backgroundColor: '#ffffff !important',
    color: colors.primary
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  menuIcon: {
    minWidth: 36,
    '& .MuiSvgIcon-root': {
      fill: 'none'
    },
    marginRight: '.4rem'
  },
  helpCenter: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  }
}));

const isRelevantPath = (currentLocation, matchPath) => {
  return currentLocation.pathname.includes(matchPath);
};
export default function ClippedDrawer() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const currentUser = useSelector((state) => state.auth.user);
  const maintenanceMode = useSelector(
    (state) => state.maintenance.maintenanceMode
  );
  const { role } = useMemo(() => {
    return { role: currentUser?.role || '' };
  }, [currentUser]);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      PaperProps={{
        style: {
          marginTop: maintenanceMode ? '40px' : '0'
        }
      }}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Toolbar>
        <Logo
          width="120"
          className={classes.headerLogo}
          onClick={() => history.push(routes.membersActive)}
        />
      </Toolbar>
      <div className={classes.drawerContainer}>
        <List className={classes.menuContainer}>
          <ListItem
            button
            classes={{ selected: classes.selectedItem, root: classes.menuItem }}
            onClick={() => history.push(routes.membersActive)}
            selected={
              isRelevantPath(location, 'member') || location.pathname === '/'
                ? true
                : false
            }
          >
            <ListItemIcon classes={{ root: classes.menuIcon }}>
              <img
                src={
                  isRelevantPath(location, 'member') ||
                  location.pathname === '/'
                    ? memberIconGreen
                    : memberIconDark
                }
              />
            </ListItemIcon>
            <ListItemText primary={'Members'} />
          </ListItem>
          <ListItem
            button
            classes={{ selected: classes.selectedItem, root: classes.menuItem }}
            onClick={() => history.push(routes.schools)}
            selected={
              !location.pathname.includes('console') &&
              isRelevantPath(location, routes.schools)
                ? true
                : false
            }
          >
            <ListItemIcon classes={{ root: classes.menuIcon }}>
              <img
                src={
                  !location.pathname.includes('console') &&
                  isRelevantPath(location, routes.schools)
                    ? schoolsGreen
                    : schoolsDark
                }
              />
            </ListItemIcon>
            <ListItemText primary={'Schools'} />
          </ListItem>
          <ListItem
            button
            classes={{ selected: classes.selectedItem, root: classes.menuItem }}
            onClick={() => history.push(routes.familiesPaid)}
            selected={isRelevantPath(location, routes.familiesPaid)}
          >
            <ListItemIcon classes={{ root: classes.menuIcon }}>
              <img
                src={
                  isRelevantPath(location, routes.familiesPaid)
                    ? familiesGreen
                    : familiesDark
                }
              />
            </ListItemIcon>
            <ListItemText primary={'Families'} />
          </ListItem>
          <ListItem
            button
            classes={{
              selected: classes.selectedItem,
              root: classes.menuItem
            }}
            onClick={() => {
              if (role === 'Agent') {
                history.push(routes.consoleSchools);
              } else {
                history.push(routes.consoleTeam);
              }
            }}
            selected={isRelevantPath(location, 'console') ? true : false}
          >
            <ListItemIcon classes={{ root: classes.menuIcon }}>
              <img
                src={
                  isRelevantPath(location, 'console')
                    ? consoleIconGreen
                    : consoleIconDark
                }
              />
            </ListItemIcon>
            <ListItemText primary={'Console'} />
          </ListItem>
        </List>
        <div className={classes.helpCenter}>
          <Divider variant="middle" />
          <List>
            <ListItem
              button
              classes={{
                selected: classes.selectedItem,
                root: classes.menuItem
              }}
              onClick={() => window.open(routes.help, '_blank')}
              selected={isRelevantPath(location, routes.help) ? true : false}
            >
              <ListItemIcon classes={{ root: classes.menuIcon }}>
                <HelpIcon
                  color={
                    isRelevantPath(location, routes.help)
                      ? colors.primary
                      : null
                  }
                />
              </ListItemIcon>
              <ListItemText primary={'Help Guides'} />
            </ListItem>
          </List>
        </div>
      </div>
    </Drawer>
  );
}
