import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function HelpIcon({ color, ...props }) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="20" cy="20" r="20" />
        <g
          stroke={color || '#4C5B68'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(9 7)"
        >
          <path d="M2.845 26.013c-1.54 0-2.787-1.248-2.787-2.787 0-1.54 1.248-2.787 2.787-2.787M2.845 20.439L22.355 20.439M2.845 26.013L22.355 26.013" />
          <path d="M22.355 20.439V2.787C22.456 1.361 21.392.118 19.967 0H2.446C1.02.118-.044 1.361.058 2.787v20.439M20.497 20.439L20.497 26.013" />
          <circle cx="9.813" cy="9.755" r="4.181" />
          <path d="M16.502 15.766L12.869 12.598" />
        </g>
      </g>
    </SvgIcon>
  );
}
export default HelpIcon;
