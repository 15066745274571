/* eslint-disable camelcase */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import clsx from 'clsx';
import _ from 'lodash';

// Assets
import { RightArrow } from 'src/assets/Icons';
import { ThemeProvider } from '@material-ui/core/styles';
import { materialTheme } from './MemberCalendarView.style';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import TodayOutlined from '@material-ui/icons/TodayOutlined';
import AddIcon from '@material-ui/icons/Add';
import { RepeatIcon } from 'src/assets';
// eslint-disable-next-line camelcase
import api_icon from 'src/assets/apiIcon.svg';
import { memberCalendarViewStyles } from 'src/styles/membersStyle/memberCalendarViewStyles';
import schoolBytes from 'src/assets/schoolByte2.webp';

// Material-UI Components
import {
  Grid,
  Paper,
  Typography,
  Box,
  IconButton,
  Button,
  Checkbox,
  Divider,
  Chip
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider, Day } from '@material-ui/pickers';

// Components
import RightDrawer from 'src/components/RightDrawer';

// Layouts
import Dashboard from 'src/views/layout/Dashboard';

// Redux-Actions
import {
  loaderStart,
  loaderStop,
  addMessage,
  setFamilyOrSchoolPreserveEntryData
} from 'src/redux/actions/appActions';
import { setFamily } from 'src/redux/actions/familyActions';
import CreateCalenderEntry from '../families/CreateCalenderEntry';

// Services
import { getMember } from 'src/services/members';

// Utils
import {
  routes,
  colors,
  userColors,
  schoolYearsColors,
  taskLabels,
  year as DefaultYear,
  multiYear
} from 'src/utils/constant';
import { withFirebase } from 'src/utils/firebase';
import { generateFileHash } from 'src/utils/functions';
import { createVirtualEntries } from 'src/utils/virtualEntryJSON';
import { MESSAGE_TYPES } from 'src/constants/common';
import { manageSchoolEntries } from 'src/services/entries';
import { getSchools } from 'src/services/schools';

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});

const defaultProps = {
  bgcolor: 'background.paper',
  m: 1
  // border: 2
};
const labelDefaultProps = {
  bgcolor: 'background.paper',
  border: 1,
  style: {
    width: 'fit-content',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: 12,
    height: '20px'
  }
};

const nameDefaultProps = {
  bgcolor: '#FF5100',
  style: {
    width: 'fit-content',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: 12,
    color: '#FFFFFF'
  }
};
// Define const

function paginate(array, pageSize, pageNumber) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}

function MemberCalendarView({ firebase }) {
  const classes = memberCalendarViewStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const calendarScroll = useRef(null);
  const [isOpen, setOpen] = useState(false);
  // New
  const [loadingEntries, setLoadingEntries] = useState(true);
  const [pagination, setPagination] = useState({ pageSize: 10, pageNumber: 1 });
  const [selectedPaginationData, setSelectedPaginationData] = useState([]);
  const [dataInScroll, setDataInScroll] = useState([]);
  const [parentIds, setParentIds] = useState([]);
  const [allEntriesFromAllSchools, setAllEntriesFromAllSchools] = useState([]);
  // New end

  // Date picker section start
  const [childOptions, setChildOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [date, changeDate] = useState(moment());
  const [sortEntriesData, setSortEntriesData] = useState([]);
  const [allCalenderData, setAllCalenderData] = useState([]);
  // Date picker section ends

  const [allSchoolList, setAllSchoolList] = useState([]);

  // List section start
  const [hasEntryData, setEntryData] = useState([]);
  // List section end

  // Get Family
  const currentFamily = useSelector((state) => state.family.currentFamily);

  const familyID = useMemo(() => {
    const familyId = history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
    return familyId;
  }, [history.location.pathname]);

  useEffect(() => {
    dispatch(loaderStart());
    getSchools()
      .then((result) => {
        setAllSchoolList(result);
        dispatch(loaderStop());
      })
      .catch((error) => {
        console.log(error);
        dispatch(loaderStop());
      });
  }, [dispatch]);

  useEffect(() => {
    if (
      selectedPaginationData.length &&
      calendarScroll.current?.clientHeight > 1766 &&
      pagination.pageSize === 10
    ) {
      setDataInScroll(selectedPaginationData.slice(0, 20));
      setPagination({ pageSize: 20, pageNumber: 1 });
      calendarScroll.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [selectedPaginationData]);

  const handleScroll = (event) => {
    const threshold = 5;
    const target = event.target;
    if (
      target.scrollTop + target.clientHeight + threshold >=
      target.scrollHeight
    ) {
      const isPageSizeIncreased = pagination.pageSize === 20;
      const pageSize = isPageSizeIncreased ? 10 : pagination.pageSize;
      const pageNumber = isPageSizeIncreased
        ? pagination.pageNumber + 2
        : pagination.pageNumber + 1;
      const paginateData = paginate(
        selectedPaginationData,
        pageSize,
        pageNumber
      );

      setDataInScroll([...dataInScroll, ...paginateData]);
      setPagination({
        pageSize,
        pageNumber
      });
    }
  };

  const hasFamilyOrSchoolEntryData = useSelector(
    (state) => state.app.hasFamilyOrSchoolEntryData
  );
  const currentUser = useSelector((state) => state.auth.user);

  useEffect(
    () => {
      if (hasFamilyOrSchoolEntryData && hasFamilyOrSchoolEntryData.entries) {
        let primaryEntryID = '';
        const updatedPreserveEntryData = hasFamilyOrSchoolEntryData.entries.map(
          (entry) => {
            entry.id = generateFileHash(20);
            if (entry.isPrimary) {
              primaryEntryID = entry.id;
            } else {
              entry.primaryID = primaryEntryID;
            }
            const childArray = currentFamily.childs.map((child) => {
              return {
                id: child.id,
                value: child.firstName,
                selected:
                  entry.assign && entry.assign.length
                    ? entry.assign.includes(child.id)
                    : false
              };
            });
            const parentArray = currentFamily.parents.map((parent) => {
              return {
                id: parent.id,
                value: parent.firstName,
                selected:
                  entry.assign && entry.assign.length
                    ? entry.assign.includes(parent.id)
                    : false
              };
            });
            entry.assignDropDownData = [...parentArray, ...childArray];
            entry.agentName = currentUser.firstName;
            entry.from.date = date.format('MMM DD,YYYY');
            entry.to.date = date.format('MMM DD,YYYY');
            return entry;
          }
        );
        dispatch(
          setFamilyOrSchoolPreserveEntryData({
            entries: [...updatedPreserveEntryData]
          })
        );
      }
    },
    // eslint-disable-next-line
    [date]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentFamily.childs && currentFamily.childs.length > 0) {
      const childArray = currentFamily.childs.map((child, index) => {
        return {
          id: child.id,
          value: child.firstName,
          color: child.color ? child.color : userColors[index]
        };
      });
      setChildOptions(childArray);
      const parents = _.map(currentFamily.parents, (e) => {
        return e.id;
      });
      setParentIds(parents);
    }
    if (currentFamily.schools && currentFamily.schools.length > 0) {
      const schoolArray =
        currentFamily.schools.length &&
        currentFamily.schools.map((school, index) => {
          const getCurrentYear = _.find(
            DefaultYear,
            (elem) => elem.value === school?.year
          );
          return {
            id: index,
            yearId: school.year,
            year: getCurrentYear?.label,
            value: `${school.schoolName} - ${getCurrentYear?.label}`,
            color: school.year.color
              ? school.year.color
              : schoolYearsColors[parseFloat(school.year) + 1]
          };
        });
      setSchoolOptions(schoolArray);
    }
  }, [currentFamily.childs, currentFamily.schools]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getEntries = async (EntriesData) => {
    if (EntriesData && EntriesData.length > 0) {
      setAllCalenderData(JSON.parse(JSON.stringify(EntriesData)));
    }
    if (
      EntriesData &&
      EntriesData.length > 0 &&
      EntriesData[0].entries.length > 0
    ) {
      let newEntries = [];
      let newRepeatEntries = [];
      if (EntriesData[0] && EntriesData[0].entries) {
        EntriesData[0].entries.forEach((entry) => {
          const fromMomentDate = moment(entry?.from?.date, 'MMM DD,YYYY');
          const toMomentDate = moment(entry?.to?.date, 'MMM DD,YYYY');
          const diffDateInDay = toMomentDate.diff(fromMomentDate, 'days');
          const startDate = entry?.from?.date;
          if (diffDateInDay === 0 || entry?.actions?.repeat?.length > 0) {
            // newEntries.push(entry);
            if (
              entry?.isPrimary &&
              entry?.actions?.repeat?.length > 0 &&
              entry?.actions?.repeat[0]?.type !== 'Does not repeat'
            ) {
              newRepeatEntries = [...newRepeatEntries, entry];
            } else {
              newEntries = [...newEntries, entry];
            }
          } else {
            for (let i = 0; i <= diffDateInDay; i++) {
              if (i === 0) {
                newEntries = [...newEntries, entry];
              } else {
                const newDate = moment(startDate, 'MMM DD,YYYY')
                  .add(i, 'days')
                  .format('MMM DD,YYYY');
                newEntries = [
                  ...newEntries,
                  {
                    ...entry,
                    isDynamic: true,
                    from: { date: newDate, time: entry.from.time }
                  }
                ];
              }
            }
          }
        });
      }

      const getRepeatVirtualEntries = createVirtualEntries(newRepeatEntries);
      const childYears = currentFamily.childs.map((e) => e.year);
      newEntries = [...newEntries, ...getRepeatVirtualEntries].filter(entry => childYears.some(
        (childYear) => entry?.forYears?.includes(childYear) || entry?.forYears?.includes('all')
      ));

      const sortEntry = _.orderBy(newEntries, (o) => {
        if (o.allDay === true) {
          // eslint-disable-next-line new-cap
          return new moment(
            o.from.date + ' ' + '11:59pm',
            'MMM DD,YYYY h:mma'
          ).format();
        } else {
          // eslint-disable-next-line new-cap
          return new moment(
            o.from.date + ' ' + o.from.time,
            'MMM DD,YYYY h:mma'
          ).format();
        }
      });

      setSortEntriesData(sortEntry);
      const index = _.findIndex(sortEntry, function (o) {
        return moment(moment(o.from.date).format('MMM DD,YYYY')).isSameOrAfter(
          moment(date).format('MMM DD,YYYY')
        );
      });
      let paginationData = [...sortEntry];
      paginationData = paginationData.slice(index);
      setSelectedPaginationData(paginationData);
      setDataInScroll(paginationData.slice(0, pagination.pageSize));
      setPagination({ pageSize: 10, pageNumber: 1 });
    } else {
      setSortEntriesData([]);
      setSelectedPaginationData([]);
      setDataInScroll([]);
      setPagination({ pageSize: 10, pageNumber: 1 });
    }

    setLoadingEntries(false);
  };

  useEffect(() => {
    if (familyID && allSchoolList.length > 0) {
      dispatch(loaderStart());
      getMember(familyID)
        .then((response) => {
          if (response) {
            // FIXED: planType = Free key is added here because mongoDB have all freeMember
            // Firebase have all paid members...
            const schoolIds = response?.data?.childs.map(
              (child) => child.school
            );
            const schools = response?.data?.childs?.map((child) => {
              const schoolId = child.school;
              const school = allSchoolList.find(
                (school) => school.id === schoolId
              );

              return {
                color: child?.color,
                schoolName: school?.schoolName,
                years: [],
                year: child?.year
              };
            });

            dispatch(
              setFamily({
                ...response?.data,
                schoolIds,
                schools: schools,
                planType: 'Free'
              })
            );
          }
          dispatch(loaderStop());
        })
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    }
    if (
      currentFamily.planType === 'Free' &&
      currentFamily?.schoolIds?.length &&
      loadingEntries
    ) {
      const years = currentFamily.schools.map((school) =>
        Number.isInteger(school.year) ? school.year : parseInt(school.year)
      );
      firebase
        .getMultipleSchoolsDataEntries(_.uniq(currentFamily.schoolIds))
        .then(function (querySnapshot) {
          let EntriesData = [];
          const Entries = [];
          querySnapshot.forEach(function (doc) {
            Entries.push({ ...doc.data(), docId: doc.id });
          });
          setAllEntriesFromAllSchools(Entries);
          const childData = currentFamily.childs.map((e) => e.id);
          const childYears = currentFamily.childs.map((e) => e.year);
          const EntriesDataMap = Entries.map((data) => {
            const filterGroupYearEntry = childYears.some(
              (item) =>
                data?.forYears?.includes(item) ||
                data?.forYears?.includes('all')
            );
            // Check for old linked entries whose groups array is empty.
            if (data?.isLinked && data?.groups?.length < 1) {
              const findParentEntry = Entries.find(
                (entry) => entry.isPrimary && entry.bulkID === data.bulkID
              );
              data.groups = findParentEntry?.groups;
            }
            if (years.length) {
              const isNumber = Number.isInteger(data.for);
              if (
                data.groups &&
                data.groups.length &&
                data.groupMembers &&
                filterGroupYearEntry
              ) {
                if (childData.length) {
                  let isExist = false;
                  childData.forEach((e) => {
                    if (data.groupMembers.includes(e)) {
                      isExist = true;
                      return false;
                    }
                  });

                  if (isExist) {
                    return data;
                  }
                }
                if (
                  data.groupMembers.includes(currentFamily.id) ||
                  data.groupMembers.includes('all') ||
                  data.for === 'all'
                ) {
                  return data;
                } else {
                  return null;
                }
              } else {
                if (
                  years.includes(isNumber ? data.for : parseInt(data.for)) ||
                  data.for === 'all' ||
                  (data.for === multiYear && filterGroupYearEntry)
                ) {
                  return data;
                } else {
                  return null;
                }
              }
            } else {
              return null;
            }
          });

          const EntriesDataFilter = EntriesDataMap.filter((data) => data);
          if (Entries.length) {
            EntriesData = [
              {
                entries: EntriesDataFilter,
                id: '',
                schoolId: Entries[0].schoolId
              }
            ];
          }
          getEntries(EntriesData);
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
          dispatch(
            addMessage(
              'Server connection issue. Please refresh',
              MESSAGE_TYPES.ERROR
            )
          );
          setLoadingEntries(false);
        });
    } else if (
      currentFamily.planType !== '' &&
      currentFamily.planType !== 'Free'
    ) {
      /*
        Below function using for a data migration
        copy all entries for this family and paste into dataEntries collection
      */
      // dataMigrationPaidFamily(familyID);

      firebase
        .getDataEntries(familyID)
        .then(function (querySnapshot) {
          const Entries = [];
          querySnapshot.forEach(function (doc) {
            // docId is using when repeated entry deleted
            Entries.push({ ...doc.data(), docId: doc.id });
          });
          getEntries([
            {
              entries: Entries,
              familyId: familyID,
              id: ''
            }
          ]);
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
          dispatch(
            addMessage(
              'Server connection issue. Please refresh',
              MESSAGE_TYPES.ERROR
            )
          );
          // dispatch(loaderStop());
          setLoadingEntries(false);
        });
    }
  }, [
    familyID,
    currentFamily.userName,
    loadingEntries,
    currentFamily.planType,
    allSchoolList
  ]);

  // Dont render all componet if family not available
  if (!currentFamily && !familyID) {
    return (
      <Dashboard>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={classes.root}>
            <div>
              <Typography
                variant="body1"
                className={classes.bredthcrumbTitle}
                onClick={() =>
                  window.location.pathname.match('/new')
                    ? history && history.push(routes.membersNew)
                    : window.location.pathname.match('/pending')
                      ? history && history.push(routes.membersPending)
                      : window.location.pathname.match('/paid')
                        ? history && history.push(routes.familiesPaid)
                        : history && history.push(routes.membersActive)
                }
              >
                Members
              </Typography>
              <RightArrow
                viewBox="0 0 24 24"
                height={24}
                width={24}
                style={{ fontSize: 22, verticalAlign: 'middle' }}
              />
            </div>
            <Typography
              variant="h2"
              color="textPrimary"
              style={{ marginBottom: 20, marginTop: 22 }}
            >
              {`${currentFamily.firstName} ${currentFamily?.lastName}`}
            </Typography>
            <Paper className={classes.tabNavigations}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  classes={{ root: classes.navigationButton }}
                  onClick={() => {
                    currentFamily.planType === 'Free'
                      ? history.push(routes.memberCalendar + '/' + familyID)
                      : history.push(
                          routes.familyCalendarPaid + '/' + familyID
                        );
                  }}
                  className={classes.activeTab}
                >
                  Calendar
                </Button>
                <Button
                  classes={{ root: classes.navigationButton }}
                  onClick={() => {
                    currentFamily.planType === 'Free'
                      ? history.push(routes.membersUsers + '/' + familyID)
                      : history.push(routes.familyUsersPaid + '/' + familyID);
                  }}
                >
                  Children
                </Button>
                <span
                  style={{
                    borderRight: `1px solid ${colors.primary}`,
                    margin: '0 8px',
                    height: 20
                  }}
                ></span>
                <Button
                  classes={{ root: classes.navigationButton }}
                  onClick={() => {
                    currentFamily.planType === 'Free'
                      ? history.push(routes.memberAccount + '/' + familyID)
                      : history.push(routes.familyAccountPaid + '/' + familyID);
                  }}
                >
                  Account
                </Button>
                <Button className={classes.familyBtn}>
                  {currentFamily.lastName}
                </Button>
              </div>
              <Divider />
              <div className={classes.accountNotificationDiv}>
                Calendar will be shown when the account is activated.
              </div>
            </Paper>
            <section className={classes.blankSpaceSkeleton}>
              <div className={classes.leftBlankDiv}></div>
              <div className={classes.rightBlankDiv}>
                <article className={classes.skeletonArticles}></article>
                <article className={classes.skeletonArticles}></article>
                <article className={classes.skeletonArticles}></article>
                <article className={classes.skeletonArticles}></article>
              </div>
            </section>
          </div>
        </MuiPickersUtilsProvider>
      </Dashboard>
    );
  }

  // Date picker section start
  const _renderDayInPicker = (date, selectedDate, dayInCurrentMonth) => {
    const updatedDotValue = [];
    let hasDot = null;
    if (!loadingEntries) {
      hasDot = _.filter(sortEntriesData, (entry) => {
        return entry.from.date === moment(date).format('MMM DD,YYYY');
      });
    }

    if (hasDot && hasDot.length > 0) {
      hasDot.forEach((entry) => {
        if (entry.for === 'Parents') {
          if (!_.includes(updatedDotValue, '#6b747b')) {
            updatedDotValue.push('#6b747b');
          }
        } else {
          const foundChild = _.find(
            childOptions,
            (data) => data.id === entry.for
          );
          if (foundChild) {
            if (!_.includes(updatedDotValue, foundChild.color)) {
              updatedDotValue.push(foundChild.color);
            }
          } else {
            if (!_.includes(updatedDotValue, '#6b747b')) {
              updatedDotValue.push('#6b747b');
            }
          }
        }
      });
    }

    const isWeekEndDay = isWeekdayHandle(date);
    if (hasDot && dayInCurrentMonth) {
      return (
        <div
          className={clsx(
            classes.dayWithDotContainer,
            isWeekEndDay && classes.dayWithWeekColor
          )}
        >
          <Day
            current={date.isSame(moment(), 'day')}
            hidden={!dayInCurrentMonth}
            selected={date.isSame(selectedDate, 'day')}
          >
            {date.date()}
          </Day>
          <div className={classes.dotContainer}>
            {!date.isSame(selectedDate, 'day') && updatedDotValue.length ? (
              updatedDotValue.map((dot, index) => (
                <div
                  key={`${index}-${dot}`}
                  className={clsx(classes.dayWithDot)}
                  style={{
                    backgroundColor: dot
                  }}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={clsx(
            classes.dayWithDotContainer,
            isWeekEndDay && classes.dayWithWeekColor
          )}
        >
          <Day
            current={date.isSame(moment(), 'day')}
            selected={date.isSame(selectedDate, 'day')}
            style={{
              color: !dayInCurrentMonth ? '#dddddd' : colors.primaryDark
            }}
          >
            {date.date()}
          </Day>
        </div>
      );
    }
  };

  const isWeekdayHandle = (date) => {
    const day = moment(date).day();
    return day === 0 || day === 6;
  };
  const _dateChangeHandle = (date) => {
    changeDate(date);

    const index = _.findIndex(sortEntriesData, function (o) {
      return moment(moment(o.from.date).format('MMM DD,YYYY')).isSameOrAfter(
        moment(date).format('MMM DD,YYYY')
      );
    });
    let paginationData = [...sortEntriesData];
    paginationData = paginationData.slice(index);
    setSelectedPaginationData(paginationData);
    setDataInScroll(paginationData.slice(0, pagination.pageSize));
    setPagination({ pageSize: 10, pageNumber: 1 });
    calendarScroll.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const goSourceEntry = (repeatEntryId) => {
    const sourceEntry = _.find(sortEntriesData, function (o) {
      return o.id === repeatEntryId;
    });
    editEntry(sourceEntry);
  };

  const getYears = (id) => {
    if (id === 'group') {
      return { year: 'Group' };
    }
    if (id) {
      const foundYear = schoolOptions.find(
        (e) => e.yearId.toString() === id.toString()
      );
      if (foundYear) {
        return foundYear;
      } else {
        return id === 'all' ? 'All Years' : '';
      }
    } else {
      return id === 'all' ? 'All Years' : '';
    }
  };

  const getBackGroundColor = (id) => {
    const foundChild = childOptions.find((child) => child.id === id);
    if (foundChild) {
      return foundChild.color;
    } else {
      return null;
    }
  };

  const getChildName = (id) => {
    const foundChild = childOptions.find((child) => child.id === id);
    if (foundChild) {
      return foundChild.value;
    } else {
      return id === 'Parents' ? 'Parents' : '';
    }
  };

  const entryCheckboxChange = (e, data) => {
    setOpen(false);
    if (
      data.isRepeatEntry &&
      data.actions.repeat &&
      data.actions.repeat.length === 0
    ) {
      const primaryData = allCalenderData[0].entries.find(
        (entry) => entry.id === data.parentRepeatEntryId
      );
      if (primaryData.completedDates) {
        const newCompletedDates = primaryData.completedDates.filter(
          (date) => date !== data.from.date
        );
        if (e.target.checked) {
          primaryData.completedDates = [...newCompletedDates, data.from.date];
        } else {
          data.completedDates = newCompletedDates;
          primaryData.completedDates = newCompletedDates;
        }
      } else {
        if (e.target.checked) {
          primaryData.completedDates = [data.from.date];
        }
      }
      data.actions.isComplete = e.target.checked;

      // remove deadline and reminder logic start
      if (e.target.checked) {
        // data.actions.deadline = []
        // data.actions.reminder = []
        if (data.actions.deadlineNotification)
          delete data.actions.deadlineNotification;
        if (data.actions.reminderNotification)
          delete data.actions.reminderNotification;
      }
      // remove deadline and reminder logic end

      if (currentFamily.planType === 'Free') {
        submitSchoolForm(primaryData);
      } else {
        submitFamilyForm(primaryData);
      }
    } else {
      data.actions.isComplete = e.target.checked;

      // remove deadline and reminder logic start
      if (e.target.checked) {
        // data.actions.deadline = []
        // data.actions.reminder = []
        if (data.actions.deadlineNotification)
          delete data.actions.deadlineNotification;
        if (data.actions.reminderNotification)
          delete data.actions.reminderNotification;
      }
      // remove deadline and reminder logic end

      if (currentFamily.planType === 'Free') {
        submitSchoolForm(data);
      } else {
        submitFamilyForm(data);
      }
    }
  };

  const submitSchoolForm = (data) => {
    if (data.bulkID) {
      firebase
        .schoolBulkDataEntries(data.bulkID)
        .then(async function (querySnapshot) {
          const EntriesData = [];
          querySnapshot.forEach(async function (doc) {
            let entry = doc.data();
            if (entry.id === data.id) {
              entry = data;
            }
            entry.bulkID = data.bulkID;
            EntriesData.push(entry);
          });

          if (EntriesData.length > 0) {
            dispatch(loaderStart());
            manageSchoolEntries(EntriesData)
              .then(() => {
                dispatch(addMessage('Entry updated'));
                dispatch(loaderStop());
              })
              .catch((error) => {
                console.log('Error getting documents: ', error);
                dispatch(addMessage('Server connection issue. Please refresh'));
              });
            setLoadingEntries(true);
          }
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
          dispatch(
            addMessage(
              'Server connection issue. Please refresh',
              MESSAGE_TYPES.ERROR
            )
          );
        });
    }
  };

  const submitFamilyForm = (data) => {
    // Repeat Logic
    const primary = [data].find((o) => o.isPrimary === true);
    if (primary !== undefined && primary.actions.repeat.length > 0) {
      const originEntry = allCalenderData[0].entries.find(
        (entry) => entry.id === primary.id
      );
      if (originEntry !== undefined) {
        const completedDates = originEntry.completedDates
          ? originEntry.completedDates.filter((e) => e !== primary.from.date)
          : [];

        data = {
          ...originEntry,
          completedDates: data.actions.isComplete
            ? [...completedDates, primary.from.date]
            : completedDates,
          actions: data.actions,
          isComplete: data.actions.isComplete
        };
      } else {
        if (data.isRepeatEntry) {
          const originEntry = allCalenderData[0].entries.find(
            (entry) => entry.id === primary.parentRepeatEntryId
          );
          const completedDates = originEntry.completedDates
            ? originEntry.completedDates.filter((e) => e !== primary.from.date)
            : [];
          data = {
            ...originEntry,
            completedDates: data.actions.isComplete
              ? [...completedDates, primary.from.date]
              : completedDates
          };
        }
      }
    } else if (data.isRepeatEntry) {
      const originEntry = allCalenderData[0].entries.find(
        (entry) => entry.id === primary.parentRepeatEntryId
      );
      const completedDates = originEntry.completedDates
        ? originEntry.completedDates.filter((e) => e !== primary.from.date)
        : [];
      data = {
        ...originEntry,
        completedDates: data.actions.isComplete
          ? [...completedDates, primary.from.date]
          : completedDates
      };
    }

    // Repeat Logic end

    if (data.bulkID) {
      firebase
        .bulkDataEntries(data.bulkID)
        .then(function (querySnapshot) {
          const EntriesData = [];
          querySnapshot.forEach(async function (doc) {
            let entry = doc.data();
            if (entry.id === data.id) {
              entry = data;
            }
            EntriesData.push(entry);
            await firebase.deleteDataEntries(entry.docId, entry.bulkID);
          });

          if (EntriesData.length > 0) {
            EntriesData.forEach(async (item) => {
              item.bulkID = generateFileHash(20);
              await firebase
                .addDataEntries(item)
                .then(() => {})
                .catch((error) => {
                  console.log('Error getting documents: ', error);
                  dispatch(
                    addMessage(
                      'Server connection issue. Please refresh',
                      MESSAGE_TYPES.ERROR
                    )
                  );
                });
            });
            setLoadingEntries(true);
          }
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
          dispatch(
            addMessage(
              'Server connection issue. Please refresh',
              MESSAGE_TYPES.ERROR
            )
          );
        });
    }
  };

  // Date picker section end

  // List section start
  let dateLabel = '';
  let monthLabel = '';
  const _singleCalendarEntry = (data, index) => {
    let showlable = null;
    let showMonthlable = null;
    if (dateLabel !== data.from.date) {
      dateLabel = data.from.date;
      showlable = data.from.date;
    } else {
      showlable = null;
    }
    const Month = data.from.date.split(' ')[0];
    if (monthLabel !== Month) {
      monthLabel = Month;
      showMonthlable = moment(data.from.date).format('MMMM YYYY');
    } else {
      showMonthlable = null;
    }

    let parrentadded = false;
    let assignedTo = [];
    if (data.assign && data.assign.length > 0) {
      assignedTo = data.assign.filter((id) => {
        if (_.includes(parentIds, id)) {
          parrentadded = true;
        } else {
          return id;
        }
      });
    }
    let yearDetails = { year: 'All Years' };
    if (currentFamily.planType === 'Free' && data.for !== 'all') {
      yearDetails = getYears(data.for);
    }
    let customType = '';
    if (data.actionParent && data.actionParent.type === 'Reminder') {
      customType = 'REMINDER';
    } else if (data.actionParent && data.actionParent.type === 'Deadline') {
      customType = 'DEADLINE';
    }
    let entryDeleted = false;
    if (Array.isArray(data.isDeleted)) {
      const entryDateExist = data.isDeleted.find(
        (item) => item.date === data.from.date
      );
      if (entryDateExist !== undefined) entryDeleted = true;
    } else if (data.isDeleted !== undefined && data.isDeleted !== null) {
      if (data.isDeleted.date === data.from.date) {
        entryDeleted = true;
      }
    }

    return (
      <div
        key={'list' + index}
        style={{
          width: '100%'
        }}
      >
        {showMonthlable && (
          <div
            className={index === 0 ? classes.monthNameTop : classes.monthName}
          >
            {showMonthlable}
          </div>
        )}
        <Paper
          className={clsx(
            classes.paper,
            !showlable && index !== 0 && classes.borderClass,
            { isdeleted: entryDeleted }
          )}
          style={{
            height: 152,
            marginTop: showlable && index !== 0 ? 20 : -6,
            boxShadow: showlable && index === 0 && 'none',
            borderTopLeftRadius: showlable ? 4 : 0,
            borderTopRightRadius: showlable ? 4 : 0,
            borderBottomRadius: !showlable && index !== 0 && 4
          }}
          onClick={(e) => editEntry(data, e)}
        >
          <Grid
            item
            xs={12}
            sm={3}
            style={{
              opacity:
                data.actions.isCancelled ||
                data.actions.isPostponed ||
                entryDeleted
                  ? 0.5
                  : 1
            }}
          >
            <Box display="flex">
              <Typography
                component="div"
                style={{
                  height: 130,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Box
                  fontWeight="fontWeightBold"
                  mb={1}
                  className={classes.labelText}
                >
                  {showlable}
                </Box>
                <Box fontWeight="fontWeightRegular" mb={1}>
                  {data.allDay ? (
                    ''
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p className={classes.fromDateText}>
                        {moment(data.from.time, 'h:mma').format('HH:mm')}
                      </p>
                      <p style={{ margin: '0 5px' }}>-</p>
                      <p className={classes.toDateText}>
                        {moment(data.to.time, 'h:mma').format('HH:mm')}
                      </p>
                    </div>
                  )}
                </Box>
                <Box display="flex" mb={1} width="40%">
                  {data.actions?.repeat?.length || data.isRepeatEntry ? (
                    <img
                      src={RepeatIcon}
                      alt="Repeat"
                      className={classes.repeatIconClass}
                    />
                  ) : (
                    <></>
                  )}
                  {data.isLinked || data.linkedArray?.length ? (
                    <IconButton
                      key={1}
                      className={classes.iconButton}
                      style={{ opacity: 0.5 }}
                      aria-label="insert-link"
                    >
                      <InsertLinkIcon />
                    </IconButton>
                  ) : (
                    // ))
                    <></>
                  )}
                </Box>
                <div style={{ marginTop: 'auto' }}>
                  {currentFamily.planType === 'Free' ? (
                    <Box
                      borderRadius={50}
                      fontWeight={500}
                      {...nameDefaultProps}
                      bgcolor={
                        data.for === 'all' ||
                        data.for === 'group' ||
                        data.for === multiYear
                          ? '#6b747b'
                          : yearDetails.color
                      }
                    ></Box>
                  ) : (
                    <Box
                      borderRadius={50}
                      fontWeight={500}
                      {...nameDefaultProps}
                      bgcolor={
                        data.for === 'Parents'
                          ? '#6b747b'
                          : getBackGroundColor(data.for)
                      }
                    >
                      {getChildName(data.for).toUpperCase()}
                    </Box>
                  )}
                </div>
              </Typography>
            </Box>
          </Grid>
          <div
            style={{
              height: 130,
              width: 2,
              opacity:
                data.actions.isCancelled || data.actions.isPostponed ? 0.5 : 1,
              backgroundColor:
                data.for === 'all'
                  ? '#6b747b'
                  : getBackGroundColor(data.for)
                    ? getBackGroundColor(data.for)
                    : '#6b747b'
            }}
          ></div>
          <Grid item xs={12} sm={9}>
            <Box
              {...defaultProps}
              borderTop={0}
              borderBottom={0}
              borderRight={0}
              border={0}
              style={{
                backgroundColor: entryDeleted ? 'transparent' : colors.white
              }}
            >
              <Typography component="div">
                <Box display="flex" justifyContent="space-between">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {entryDeleted && (
                      <Box
                        borderRadius="borderRadius"
                        fontWeight={500}
                        {...labelDefaultProps}
                        bgcolor={colors.tertiaryDeleteRed}
                        color={'#fff'}
                        style={{
                          width: 'fit-content',
                          paddingLeft: 8,
                          paddingRight: 8,
                          fontSize: 12,
                          height: 22,
                          marginRight: 10
                        }}
                      >
                        DELETED BY MEMBER
                      </Box>
                    )}
                    {data.label !== 'none' && data.label === 'Alert' ? (
                      <Box
                        fontWeight={500}
                        {...labelDefaultProps}
                        bgcolor={
                          customType === 'DEADLINE'
                            ? '#000'
                            : 'background.paper'
                        }
                        color={'rgb(189, 0, 0)'}
                        style={{
                          borderRadius: '16px',
                          width: 'fit-content',
                          paddingLeft: 8,
                          paddingRight: 8,
                          fontSize: 12,
                          height: 20,
                          marginRight: '10px'
                        }}
                      >
                        {customType && customType !== ''
                          ? customType.toUpperCase()
                          : data.label.toUpperCase()}
                      </Box>
                    ) : null}

                    {data.actions.isCancelled ||
                    data.actions.isPostponed ||
                    data.actions.isChanged ? (
                      <Chip
                        size="small"
                        label={
                          <span style={{ fontSize: 12 }}>
                            {data.actions.isPostponed
                              ? 'POSTPONED'
                              : data.actions.isCancelled
                                ? 'CANCELLED'
                                : 'UPDATED'}
                          </span>
                        }
                        classes={{
                          labelSmall: classes.chipLabel,
                          root: classes.chipRoot
                        }}
                        style={{
                          marginRight: 10,
                          backgroundColor:
                            data.actions.isCancelled || data.actions.isPostponed
                              ? colors.tertiaryRed
                              : colors.primaryDarkGrey
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {data.label !== 'none' &&
                    data.label !== 'Alert' &&
                    data.label ? (
                      <Box
                        borderRadius="borderRadius"
                        fontWeight={500}
                        {...labelDefaultProps}
                        bgcolor={
                          customType === 'DEADLINE'
                            ? '#000'
                            : 'background.paper'
                        }
                        color={customType === 'DEADLINE' ? '#fff' : '#000'}
                        style={{
                          opacity:
                            data.actions.isCancelled ||
                            data.actions.isPostponed ||
                            entryDeleted
                              ? 0.5
                              : 1,
                          width: 'fit-content',
                          paddingLeft: 8,
                          paddingRight: 8,
                          fontSize: 12,
                          height: 20
                        }}
                      >
                        {customType && customType !== ''
                          ? customType.toUpperCase()
                          : data.label.toUpperCase()}
                      </Box>
                    ) : null}
                    {(data.label === 'none' || data.label.trim() === '') &&
                    (customType === 'REMINDER' || customType === 'DEADLINE') ? (
                      <Box
                        borderRadius="borderRadius"
                        fontWeight={500}
                        {...labelDefaultProps}
                        bgcolor={
                          customType === 'DEADLINE'
                            ? '#000'
                            : 'background.paper'
                        }
                        color={customType === 'DEADLINE' ? '#fff' : '#000'}
                        style={{
                          opacity:
                            data.actions.isCancelled || data.actions.isPostponed
                              ? 0.5
                              : 1,
                          width: 'fit-content',
                          paddingLeft: 8,
                          paddingRight: 8,
                          fontSize: 12,
                          height: 20
                        }}
                      >
                        {customType && customType !== ''
                          ? customType.toUpperCase()
                          : data.label.toUpperCase()}
                      </Box>
                    ) : null}
                  </div>
                  {taskLabels.includes(data.label) &&
                  customType !== 'DEADLINE' &&
                  customType !== 'REMINDER' ? (
                    <Checkbox
                      color="primary"
                      className={classes.checkboxClass}
                      style={{
                        opacity:
                          data.actions.isCancelled ||
                          data.actions.isPostponed ||
                          entryDeleted
                            ? 0.5
                            : 1,
                        color:
                          (data.completedDates &&
                            data.completedDates.includes(data.from.date)) ||
                          data.actions.isComplete
                            ? colors.primary
                            : colors.primaryLightGrey
                      }}
                      checked={
                        (data.completedDates &&
                          data.completedDates.includes(data.from.date)) ||
                        data.actions.isComplete ||
                        false
                      }
                      onChange={(e) => {
                        if (!entryDeleted) {
                          entryCheckboxChange(e, data);
                        }
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
                <Box
                  fontWeight="fontWeightBold"
                  mt={1}
                  className={classes.mainTitleContainer}
                  style={{
                    opacity:
                      data.actions.isCancelled ||
                      data.actions.isPostponed ||
                      entryDeleted
                        ? 0.5
                        : 1
                  }}
                >
                  <p className={classes.titleTextContainer}>
                    {customType &&
                    (customType === 'DEADLINE' || customType === 'REMINDER')
                      ? `RE : ${data.title !== '' ? data.title : ''}`
                      : data.title}
                  </p>
                </Box>
                <Box
                  style={{
                    marginTop: 'auto',
                    alignItems: 'flex-end',
                    height: '45px',
                    opacity:
                      data.actions.isCancelled ||
                      data.actions.isPostponed ||
                      entryDeleted
                        ? 0.5
                        : 1
                  }}
                  display="flex"
                  justifyContent="space-between"
                >
                  {/* Regex Reference :  https://stackoverflow.com/questions/1499889/remove-html-tags-in-javascript-with-regex */}
                  <Box width="70%">
                    <p className={classes.textTruncate}>
                      {data.description
                        .replace(/&nbsp;|(<([^>]+)>)/gi, '')
                        .replace(/&amp;/g, '&')
                        .replace(/&lt;/g, '<')
                        .replace(/&gt;/g, '>')
                        .replace(/&quot;/g, '"')}
                    </p>
                  </Box>
                  <div className={classes.image}>
                    {parrentadded ? (
                      <div
                        key={`dot-${index}`}
                        className={clsx(classes.dot)}
                        style={{
                          backgroundColor: '#6b747b'
                        }}
                      ></div>
                    ) : null}
                    {Array.isArray(data.assign)
                      ? assignedTo.map(function (doc, index) {
                          return (
                            <div
                              key={`dot-${index}`}
                              className={clsx(classes.dot)}
                              style={{
                                backgroundColor: getBackGroundColor(doc)
                              }}
                            ></div>
                          );
                        })
                      : null}
                  </div>
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Paper>
      </div>
    );
  };

  const editEntry = async (data, e = null) => {
    // Modify for new date filed

    const fromhh = data.newFromTimeFormate.hh;
    const frommm = data.newFromTimeFormate.mm;
    const fromap = data.newFromTimeFormate.ap;

    const tohh = data.newToTimeFormate.hh;
    const tomm = data.newToTimeFormate.mm;
    const toap = data.newToTimeFormate.ap;

    data.newFromTimeFormate = { hh: fromhh, mm: frommm, ap: fromap };
    data.newToTimeFormate = { hh: tohh, mm: tomm, ap: toap };
    // Modify for new date filed end

    if (data.isPrimary) {
      const foundValues =
        currentFamily.planType === 'Free'
          ? allEntriesFromAllSchools.filter(
              (obj) =>
                data.linkedArray &&
                data.linkedArray.length &&
                data.linkedArray.includes(obj.id)
            )
          : allCalenderData[0].entries.filter(
              (obj) =>
                data.linkedArray &&
                data.linkedArray.length &&
                data.linkedArray.includes(obj.id)
            );

      if (data.isRepeatEntry) {
        if (e.target.getAttribute('alt') === 'Repeat') {
          // Find origin entry
          const primaryData = allCalenderData[0].entries.find(
            (entry) => entry.id === data.parentRepeatEntryId
          );

          const updatedValue = [{ ...primaryData }, ...foundValues].map(
            (value) =>
              value.id === data.id
                ? { ...value, expand: true }
                : { ...value, expand: false }
          );

          setEntryData(updatedValue);
          setOpen(true);
        } else {
          setEntryData([{ ...data, expand: true }]);
          setOpen(true);
        }
      } else if (data.isDynamic) {
        const primary = allCalenderData[0].entries.filter(
          (entry) => entry.id === data.id && entry.isPrimary
        );
        const primaryData = primary[0];
        const updatedValue = [{ ...primaryData }, ...foundValues].map(
          (value) =>
            value.id === data.id
              ? { ...value, expand: true }
              : { ...value, expand: false }
        );

        setEntryData(updatedValue);
        setOpen(true);
      } else {
        const updatedValue = [{ ...data }, ...foundValues].map((value) => {
          return value.id === data.id
            ? { ...value, expand: true }
            : { ...value, expand: false };
        });

        setEntryData(updatedValue);
        setOpen(true);
      }
    } else {
      const primary = allCalenderData[0].entries.filter(
        (entry) => entry.id === data.primaryID && entry.isPrimary
      );

      let primaryData = primary[0];
      const updatedPrimary = primary[0]?.repeatEntries?.find(
        (e) => e.id === data.primaryID && e.isPrimary
      );
      if (updatedPrimary) {
        primaryData = updatedPrimary;
      }
      const foundValues =
        currentFamily.planType === 'Free'
          ? allEntriesFromAllSchools.filter(
              (obj) =>
                primaryData &&
                primaryData.linkedArray &&
                primaryData.linkedArray.length &&
                primaryData.linkedArray.includes(obj.id)
            )
          : allCalenderData[0].entries.filter(
              (obj) =>
                primaryData &&
                primaryData.linkedArray &&
                primaryData.linkedArray.length &&
                primaryData.linkedArray.includes(obj.id)
            );
      const updatedValue = [{ ...primaryData }, ...foundValues].map((value) => {
        if (data.actionParent) {
          if (
            data.actionParent &&
            data.actionParent.linkedId &&
            data.actionParent.linkedId === value.id
          ) {
            return { ...value, expand: true };
          } else if (
            data.actionParent &&
            data.actionParent.parentID &&
            data.actionParent.parentID === value.id &&
            !data.actionParent.linkedId
          ) {
            return { ...value, expand: true };
          } else {
            return { ...value, expand: false };
          }
        } else {
          if (value.id === data.id) {
            return { ...value, expand: true };
          }
        }

        return { ...value, expand: false };
      });
      setEntryData(updatedValue);
      setOpen(true);
    }
  };
  // Pagination

  // List section end
  return (
    <Dashboard>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.root}>
          <div>
            <Typography
              variant="body1"
              className={classes.bredthcrumbTitle}
              onClick={() =>
                window.location.pathname.match('/new')
                  ? history && history.push(routes.membersNew)
                  : window.location.pathname.match('/pending')
                    ? history && history.push(routes.membersPending)
                    : window.location.pathname.match('/paid')
                      ? history && history.push(routes.familiesPaid)
                      : history && history.push(routes.membersActive)
              }
            >
              Members
            </Typography>
            <RightArrow
              viewBox="0 0 24 24"
              height={24}
              width={24}
              style={{ fontSize: 22, verticalAlign: 'middle' }}
            />
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ marginBottom: 20, marginTop: 22 }}
          >
            {`${currentFamily.firstName} ${currentFamily?.lastName}`}
          </Typography>
          <Paper className={classes.tabNavigations}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.memberCalendar + '/' + familyID)
                    : history.push(routes.familyCalendarPaid + '/' + familyID);
                }}
                className={classes.activeTab}
              >
                Calendar
              </Button>
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.membersUsers + '/' + familyID)
                    : history.push(routes.familyUsersPaid + '/' + familyID);
                }}
              >
                Children
              </Button>
              <span
                style={{
                  borderRight: `1px solid ${colors.primary}`,
                  margin: '0 8px',
                  height: 20
                }}
              ></span>
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.memberAccount + '/' + familyID)
                    : history.push(routes.familyAccountPaid + '/' + familyID);
                }}
              >
                Account
              </Button>
              {/** Hide it to match epic-3 design */}
              {/* <Button className={classes.familyBtn}>{currentFamily.familySurname}</Button> */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '1rem',
                  marginRight: 0,
                  marginLeft: 'auto'
                }}
              >
                {currentFamily.createdByApiSync && (
                  <img src={schoolBytes} height="38px" />
                )}
                {currentFamily.createdByApiSync && <img src={api_icon} />}
              </div>
            </div>
            <Divider />
            <div
              className={
                currentFamily.planType !== 'Free'
                  ? classes.withButton
                  : classes.withoutButton
              }
            >
              {currentFamily.planType !== 'Free' && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.actionButton}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setOpen(true);
                    setEntryData([]);
                  }}
                >
                  Add New Entry
                </Button>
              )}
              <div className={classes.alignFlexCenter}>
                {currentFamily.planType === 'Free' ? (
                  schoolOptions.map((school) => (
                    <div
                      key={`${school.id}-${school.index}`}
                      className={classes.alignFlexCenter}
                      style={{ margin: '0 10px' }}
                    >
                      <Typography>{school.value}</Typography>
                      <div
                        className={clsx(classes.headerDot)}
                        style={{
                          backgroundColor: school.color
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <>
                    <div
                      className={classes.alignFlexCenter}
                      style={{ margin: '0 10px' }}
                    >
                      <Typography>Parents</Typography>
                      <div
                        className={clsx(classes.headerDot)}
                        style={{
                          backgroundColor: '#6b747b'
                        }}
                      />
                    </div>
                    {childOptions && childOptions.length ? (
                      childOptions.map((child) => (
                        <div
                          key={`${child.id}-${child.index}`}
                          className={classes.alignFlexCenter}
                          style={{ margin: '0 10px' }}
                        >
                          <Typography>{child.value}</Typography>
                          <div
                            className={clsx(classes.headerDot)}
                            style={{
                              backgroundColor: child.color
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </Paper>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <ThemeProvider theme={materialTheme}>
                <Paper>
                  <DatePicker
                    autoOk
                    okLabel=""
                    cancelLabel=""
                    renderDay={_renderDayInPicker}
                    variant="static"
                    value={date}
                    onChange={(e) => _dateChangeHandle(e)}
                    disableToolbar
                  />
                  <Button
                    variant="contained"
                    size="small"
                    className={classes.todayButton}
                    startIcon={<TodayOutlined />}
                    onClick={() => _dateChangeHandle(moment())}
                  >
                    Today
                  </Button>
                </Paper>
              </ThemeProvider>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div
                className={classes.calendarEntryWrapper}
                ref={calendarScroll}
                onScroll={handleScroll}
              >
                {loadingEntries && (
                  <div className={classes.loadingEntries}>Loading...</div>
                )}

                {dataInScroll.length > 0 && !loadingEntries
                  ? dataInScroll.map((data, index) =>
                      _singleCalendarEntry(data, index)
                    )
                  : !loadingEntries && (
                      <div className={classes.loadingEntriesDots}>........</div>
                    )}
              </div>
            </Grid>
          </Grid>
        </div>
        <RightDrawer
          isOpen={isOpen}
          onClose={() => {
            setOpen(false);
          }}
          width={900}
        >
          <CreateCalenderEntry
            setRightOpen={setOpen}
            hasData={hasEntryData}
            setHasEntryData={setEntryData}
            isOpen={isOpen}
            getEntries={(date) => {
              changeDate(date);
              setLoadingEntries(true);
            }}
            date={date}
            allCalenderData={allCalenderData}
            goSourceEntry={goSourceEntry}
          />
        </RightDrawer>
      </MuiPickersUtilsProvider>
    </Dashboard>
  );
}
export default withFirebase(MemberCalendarView);
