import axios from './axios';

// Constants
import { API_ROUTES } from 'src/constants/api-routes';

export const manageSchoolEntries = async (payload) => {
  try {
    const response = await axios.post(API_ROUTES.MANAGE_SCHOOL_ENTRIES, payload);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
