import _ from 'lodash';
import { deepEqual } from './deepEqual';
import moment from 'moment';
import { getNewNotificationArray } from './getNewNotificationArray';
import { generateFileHash } from './functions';
import { weekOfMonth } from './customMoment';

export const setFromToTimeFormat = async (formData) => {
  formData.forEach(async (entry, index) => {
    // if (entry.docId && entry.isPrimary && entry.bulkID) {
    //   // Remove existing data entries
    //   await deleteSchoolDataEntry(entry.docId, entry.bulkID);
    // } else if (entry.docId && entry.isLinked && entry.bulkID) {
    //   await deleteSchoolDataEntry(entry.docId, entry.bulkID);
    // }

    if (entry.allDay) {
      formData[index].from = { ...formData[index].from, time: '12:30am' };
      formData[index].to = { ...formData[index].to, time: '12:30am' };
    } else {
      if (formData[index].newFromTimeFormate) {
        formData[index].newFromTimeFormate = {
          hh: formData[index].newFromTimeFormate.hh,
          mm:
            formData[index].newFromTimeFormate.mm.toString().length === 1
              ? '0' + formData[index].newFromTimeFormate.mm
              : formData[index].newFromTimeFormate.mm.toString(),
          ap:
            formData[index].newFromTimeFormate.ap.toLowerCase() === 'a' ||
            formData[index].newFromTimeFormate.ap.toLowerCase() === 'p'
              ? formData[index].newFromTimeFormate.ap.toLowerCase()
              : 'a'
        };
        formData[index].newToTimeFormate = {
          hh: formData[index].newToTimeFormate.hh,
          mm:
            formData[index].newToTimeFormate.mm.toString().length === 1
              ? '0' + formData[index].newToTimeFormate.mm
              : formData[index].newToTimeFormate.mm.toString(),
          ap:
            formData[index].newToTimeFormate.ap.toLowerCase() === 'a' ||
            formData[index].newToTimeFormate.ap.toLowerCase() === 'p'
              ? formData[index].newToTimeFormate.ap.toLowerCase()
              : 'p'
        };

        formData[index].from = {
          date: formData[index].from.date,
          time:
            formData[index].newFromTimeFormate.hh +
            ':' +
            formData[index].newFromTimeFormate.mm +
            formData[index].newFromTimeFormate.ap +
            'm'
        };
        formData[index].to = {
          date: formData[index].to.date,
          time:
            formData[index].newToTimeFormate.hh +
            ':' +
            formData[index].newToTimeFormate.mm +
            formData[index].newToTimeFormate.ap +
            'm'
        };

        if (
          Array.isArray(formData[index].repeatEntries) &&
          formData[index].repeatEntries.length
        ) {
          formData[index].repeatEntries[0].from = {
            date: formData[index].repeatEntries[0].from.date,
            time:
              formData[index].repeatEntries[0]?.newFromTimeFormate?.hh +
              ':' +
              formData[index].repeatEntries[0]?.newFromTimeFormate?.mm +
              formData[index].repeatEntries[0]?.newFromTimeFormate?.ap +
              'm'
          };
          formData[index].repeatEntries[0].to = {
            date: formData[index].repeatEntries[0].to.date,
            time:
              formData[index].repeatEntries[0]?.newToTimeFormate?.hh +
              ':' +
              formData[index].repeatEntries[0]?.newToTimeFormate?.mm +
              formData[index].repeatEntries[0]?.newToTimeFormate?.ap +
              'm'
          };
        }
      }
    }
  });
  return formData;
}

export const compareDataAndUpdate = (entry, hasData, formData) => {
  let newFor = entry.for;
  let assign = entry.assign;
  if (formData[0].for !== hasData[0].for) {
    newFor = formData[0].for;
  } else if (
    Array.isArray(formData[0].for) &&
    Array.isArray(hasData[0].for)
  ) {
    if (!_.isEqual(_.sortBy(formData[0].for), _.sortBy(hasData[0].for))) {
      newFor = formData[0].for;
    } else {
      newFor = hasData[0].for;
    }
  } else {
    newFor = hasData[0].for;
  }

  if (formData[0].assign !== hasData[0].assign) {
    assign = formData[0].assign;
  } else if (
    Array.isArray(formData[0].assign) &&
    Array.isArray(hasData[0].assign)
  ) {
    if (
      !_.isEqual(_.sortBy(formData[0].assign), _.sortBy(hasData[0].assign))
    ) {
      assign = formData[0].assign;
    } else {
      assign = hasData[0].assign;
    }
  } else {
    assign = hasData[0].assign;
  }

  let editedFormData = {
    ...formData[0],
    label:
      hasData[0].label === formData[0].label
        ? entry.label
        : formData[0].label,
    title:
      hasData[0].title === formData[0].title
        ? entry.title
        : formData[0].title,
    location:
      hasData[0].location === formData[0].location
        ? entry.location
        : formData[0].location,
    description:
      hasData[0].description === formData[0].description
        ? entry.description
        : formData[0].description,
    for: newFor,
    assign: assign,
    from: {
      date: entry.from.date,
      time:
        hasData[0].from.time === formData[0].from.time
          ? entry.from.time
          : formData[0].from.time
    },
    to: {
      date: entry.to.date,
      time:
        hasData[0].to.time === formData[0].to.time
          ? entry.to.time
          : formData[0].to.time
    },
    allDay:
      hasData[0].allDay === formData[0].allDay
        ? entry.allDay
        : formData[0].allDay,
    actions: {
      ...formData[0].actions,
      notification: entry.actions.notification
    }
  };
  return editedFormData;
};

// Deadline, Reminder entries will create
export const virtualEntries = async ({ allCalenderData, formData, mainEntries, isRepeatChange, repeatEntryRadioData }) => {
  const storeCoreEntry = formData && formData.length
        ? JSON.parse(JSON.stringify(formData[0]))
        : {};
  let primary = formData.find((o) => o.isPrimary === true);

  //Repeat Logic start
  if (
    mainEntries &&
    mainEntries.length > 0 &&
    mainEntries[0].actions.repeat.length === 0 &&
    !mainEntries[0].isRepeatEntry
  ) {
    // Delete extra field start
    const deleteFields = [
      'followDates',
      'repeatEntries',
      'repeatDeleted',
      'isRepeatEntry',
      'parentRepeatEntryId',
      'completedDates',
      'isDeleted'
    ];
    deleteFields.map((field) => {
      if (formData[0][field]) delete formData[0][field];
    });
    // Delete extra field end
  } else {
    if (
      mainEntries &&
      mainEntries.length > 0 &&
      (primary.isRepeatEntry ||
        (primary.actions.repeat.length > 0 &&
          primary.actions.repeat[0].type !== 'Does not repeat'))
    ) {
      if (!formData[0].isRepeatEntry && primary.actions.repeat.length > 0) {
        // If entry is origin repeat(primary) entry
        const isEqual = deepEqual(
          mainEntries[0].actions.repeat[0],
          formData[0].actions.repeat[0]
        );
        if (isEqual) {
          if (repeatEntryRadioData === 'This Event') {
            // Change specific data
            let originEntry = allCalenderData[0].entries.find(
              (entry) => entry.id === primary.id
            );
            ///primary = originEntry;
            originEntry.linkedArray = primary.linkedArray;
            // originEntry.actions = primary.actions;

            delete formData[0].repeatEntries;
            delete formData[0].followDates;
            if (
              originEntry.repeatEntries &&
              originEntry.repeatEntries.length > 0
            ) {
              let filterepeatEntries = originEntry.repeatEntries.filter(
                (entry) => entry.from.date !== formData[0].from.date
              );
              const newformData = JSON.parse(
                JSON.stringify({
                  ...originEntry,
                  repeatEntries: [...filterepeatEntries, formData[0]]
                })
              );
              formData[0] = newformData;
            } else {
              const newformData = JSON.parse(
                JSON.stringify({
                  ...originEntry,
                  repeatEntries: [formData[0]]
                })
              );
              formData[0] = newformData;
            }
            // completedDates update logic start
            if (
              !formData[0].actions.isComplete &&
              formData[0].completedDates
            ) {
              let isExistCompleteDate = formData[0].completedDates.indexOf(
                formData[0].from.date
              );
              if (isExistCompleteDate !== -1) {
                formData[0].completedDates.splice(isExistCompleteDate, 1);
              }
            }
            // completedDates update logic end
          } else {
            let newRepeatEntry = [];
            let originEntry = allCalenderData[0].entries.find(
              (entry) => entry.id === primary.id
            );
            if (
              originEntry.repeatEntries &&
              originEntry.repeatEntries.length > 0
            ) {
              originEntry.repeatEntries.forEach((entry) => {
                const newEntry = compareDataAndUpdate(
                  entry,
                  mainEntries,
                  formData
                );
                let newEntryJson = JSON.parse(JSON.stringify(newEntry));
                delete newEntryJson.followDates;
                delete newEntryJson.repeatEntries;
                delete newEntryJson.repeatDeleted;
                newRepeatEntry.push(newEntryJson);
              });
            }
            // completedDates update logic start
            let newCompleteDates = formData[0].completedDates
              ? [...formData[0].completedDates]
              : [];
            newRepeatEntry.forEach((entry) => {
              let isExistCompleteDate = newCompleteDates.indexOf(
                entry.from.date
              );
              if (entry.actions.isComplete && isExistCompleteDate === -1) {
                newCompleteDates = [...newCompleteDates, entry.from.date];
              }
              if (!entry.actions.isComplete && isExistCompleteDate !== -1) {
                newCompleteDates.splice(isExistCompleteDate, 1);
              }
            });
            formData[0].completedDates = newCompleteDates;
            // completedDates update logic end

            formData[0].repeatEntries = newRepeatEntry;
            delete formData[0].followDates;
            delete formData[0].repeatDeleted;
            if (repeatEntryRadioData === 'All events') {
              formData[0].repeatEntries = [];
            }
          }
        } else {
          // If user changes repeat logic then delete all previous edited instance
          delete formData[0].followDates;
          delete formData[0].repeatEntries;
          delete formData[0].repeatDeleted;
        }
      } else {
        //Find origin entry(virtual entry)
        let originEntry = allCalenderData[0].entries.find(
          (entry) => entry.id === primary.parentRepeatEntryId
        );
        //We dont need repeat logic in vertual entries
        formData[0].actions = { ...formData[0].actions, repeat: [] };
        primary = originEntry;
        //Check for completion
        if (originEntry.completedDates) {
          let newCompletedDates = originEntry.completedDates.filter(
            (date) => date !== formData[0].from.date
          );

          if (formData[0].actions.isComplete) {
            originEntry.completedDates = [
              ...newCompletedDates,
              formData[0].from.date
            ];
          } else {
            originEntry.completedDates = newCompletedDates;
          }
        } else {
          if (formData[0].actions.isComplete) {
            originEntry.completedDates = [formData[0].from.date];
          } else {
            originEntry.completedDates = [];
          }
        }
        //Check for completion end

        const linkedEntries =
          originEntry?.linkedArray &&
          allCalenderData[0].entries.find((entry) =>
            originEntry?.linkedArray?.includes(entry.id)
          );
        if (linkedEntries) {
          if (Array.isArray(linkedEntries)) {
            formData = [formData[0], ...linkedEntries];
          } else {
            formData = [formData[0], linkedEntries];
          }
        }

        if (repeatEntryRadioData === 'This Event') {
          delete formData[0].repeatEntries;
          delete formData[0].followDates;
          // Insert new entry
          if (
            originEntry.repeatEntries &&
            originEntry.repeatEntries.length > 0
          ) {
            let filterepeatEntries = originEntry.repeatEntries.filter(
              (entry) => entry.from.date !== formData[0].from.date
            );
            //formData =[{...originEntry, repeatEntries: [...filterepeatEntries,formData[0]] }];
            const newformData = JSON.parse(
              JSON.stringify({
                ...originEntry,
                repeatEntries: [...filterepeatEntries, formData[0]]
              })
            );
            formData[0] = newformData;
          } else {
            const newformData = JSON.parse(
              JSON.stringify({ ...originEntry, repeatEntries: [formData[0]] })
            );
            formData[0] = newformData;
          }
        } else if (repeatEntryRadioData === 'This and following events') {
          if (isRepeatChange) {
            let dailyToCustomAction = {
              type: 'Custom',
              values: {
                endsAfter: 1,
                endsDate: moment(formData[0].from.date)
                  .add(-1, 'days')
                  .format('MMM DD,YYYY'),
                endsType: 'On',
                repeatDay: 1,
                repeatMonth: { type: 'day', value: originEntry.from.date },
                repeatOn: [],
                repeatType: 'Day'
              }
            };
            let newActionsValues = {};
            if (originEntry.actions.repeat[0].type === 'Daily') {
              newActionsValues = dailyToCustomAction;
            } else {
              newActionsValues = {
                ...originEntry.actions.repeat[0],
                values: {
                  ...originEntry.actions.repeat[0].values,
                  endsDate: moment(formData[0].from.date)
                    .add(-1, 'days')
                    .format('MMM DD,YYYY'),
                  endsType: 'On'
                }
              };
            }
            const newformData = JSON.parse(
              JSON.stringify({
                ...originEntry,
                actions: {
                  ...originEntry.actions,
                  repeat: [newActionsValues]
                }
              })
            );
            formData[0] = newformData;
          } else {
            // Insert new entry
            if (
              originEntry.repeatEntries &&
              originEntry.repeatEntries.length > 0
            ) {
              /* forward notification to repeat entries */
              let filterepeatEntries = [];
              let newCompleteDates = [...originEntry.completedDates];
              originEntry.repeatEntries.forEach((entry) => {
                // if current form entry from date is greater then repeat entry date
                if (
                  moment(formData[0].from.date, 'MMM DD,YYYY').diff(
                    entry.from.date,
                    'days'
                  ) < 0
                ) {
                  // completedDates update logic start
                  let isExistCompleteDate = newCompleteDates.indexOf(
                    entry.from.date
                  );
                  if (
                    formData[0].actions.isComplete &&
                    isExistCompleteDate === -1
                  ) {
                    newCompleteDates = [...newCompleteDates, entry.from.date];
                  }
                  if (
                    !formData[0].actions.isComplete &&
                    isExistCompleteDate !== -1
                  ) {
                    newCompleteDates.splice(isExistCompleteDate, 1);
                  }
                  // completedDates update logic end

                  const newEntry = compareDataAndUpdate(
                    entry,
                    mainEntries,
                    formData
                  );
                  let newEntryJson = JSON.parse(JSON.stringify(newEntry));
                  delete newEntryJson.followDates;
                  delete newEntryJson.repeatEntries;
                  delete newEntryJson.repeatDeleted;
                  filterepeatEntries.push(newEntryJson);
                } else {
                  if (formData[0].from.date !== entry.from.date) {
                    filterepeatEntries.push(entry);
                  }
                }
              });
              // CompletedDates update here
              originEntry.completedDates = newCompleteDates;

              // filterepeatEntries = originEntry.repeatEntries ? originEntry.repeatEntries.filter(entry => moment(formData[0].from.date, 'MMM DD,YYYY').diff(entry.from.date, 'days') > 0) : [];
              const followDates = originEntry.followDates
                ? originEntry.followDates.filter(
                    (date) =>
                      moment(formData[0].from.date, 'MMM DD,YYYY').diff(
                        date,
                        'days'
                      ) > 0
                  )
                : [];
              originEntry.followDates = [
                ...followDates,
                formData[0].from.date
              ];
              delete formData[0].repeatEntries;
              delete formData[0].followDates;
              const newformData = JSON.parse(
                JSON.stringify({
                  ...originEntry,
                  repeatEntries: [...filterepeatEntries, formData[0]]
                })
              );
              formData[0] = newformData;
            } else {
              originEntry.followDates = [formData[0].from.date];
              delete formData[0].repeatEntries;
              delete formData[0].followDates;

              const newformData = JSON.parse(
                JSON.stringify({
                  ...originEntry,
                  repeatEntries: [{ ...formData[0], followAfterThis: true }]
                })
              );
              formData[0] = newformData;
            }
          }
        } else {
          let filterepeatEntries = [];
          if (
            originEntry.repeatEntries &&
            originEntry.repeatEntries.length > 0
          ) {
            let newCompleteDates = [...originEntry.completedDates];
            originEntry.repeatEntries.forEach((entry) => {
              // completedDates update logic start
              let isExistCompleteDate = newCompleteDates.indexOf(
                entry.from.date
              );
              if (
                formData[0].actions.isComplete &&
                isExistCompleteDate === -1
              ) {
                newCompleteDates = [...newCompleteDates, entry.from.date];
              }
              if (
                !formData[0].actions.isComplete &&
                isExistCompleteDate !== -1
              ) {
                newCompleteDates.splice(isExistCompleteDate, 1);
              }
              // completedDates update logic end

              // if current form entry from date is greater then repeat entry date
              if (formData[0].from.date !== entry.from.date) {
                const newEntry = compareDataAndUpdate(
                  entry,
                  mainEntries,
                  formData
                );
                let newEntryJson = JSON.parse(JSON.stringify(newEntry));
                delete newEntryJson.followDates;
                delete newEntryJson.repeatEntries;
                delete newEntryJson.repeatDeleted;
                filterepeatEntries.push(newEntryJson);
              }
            });
            // CompletedDates update here
            originEntry.completedDates = newCompleteDates;
          }
          filterepeatEntries = [...filterepeatEntries, formData[0]];

          // originEntry.repeatEntries = [];
          originEntry.followDates = [];
          primary.repeatEntries = [];
          primary.followDates = [];
          delete formData[0].id;
          delete formData[0].parentRepeatEntryId;
          delete formData[0].followDates;
          delete formData[0].repeatEntries;
          delete formData[0].repeatDeleted;

          // Change Repeat Options then update main options start
          let newActions = { ...originEntry.actions };
          if (isRepeatChange) {
            newActions = {
              ...originEntry.actions,
              repeat: [...storeCoreEntry.actions.repeat]
            };
          }
          // Change Repeat Options then update main options start

          formData[0] = {
            ...originEntry,
            ...formData[0],

            isDynamic: false,
            isRepeatEntry: false,
            // id: generateFileHash(20),
            // parentRepeatEntryId: entry.id,
            linkedArray: originEntry.linkedArray,
            actions: newActions,
            from: {
              date: originEntry.from.date,
              time: formData[0].from.time
            },
            to: { date: originEntry.to.date, time: formData[0].to.time },
            repeatEntries: filterepeatEntries
          };
        }
      }
    } else {
      //It is Normal entry, if entry is not repeat entry
      // Delete extra field start
      const deleteFields = [
        'followDates',
        'repeatEntries',
        'repeatDeleted',
        'isRepeatEntry',
        'parentRepeatEntryId',
        'completedDates',
        'isDeleted'
      ];
      deleteFields.map((field) => {
        if (formData[0][field]) delete formData[0][field];
      });
      // Delete extra field end
    }
  }
  //Repeat Logic end
  return formData;
}

// GetEntries and Create Virtual Entries
export const createVirtualEntries = (entries) => {
  let newRepeatEntriesModified = [];
  let occurance = 0;
  entries.forEach((entry) => {
    const startDate = entry?.from?.date;
    //reset at start;
    let entryActions = JSON.parse(JSON.stringify(entry?.actions));
    occurance = 0;
    // Set Original Entry Date
    entry.originalEntryDate = startDate;
    if (!entry.repeatDeleted) {
      entry.repeatDeleted = [];
    }
    if (entry?.actions?.repeat[0]?.type === 'Daily') {
      for (let i = 0; i <= 1095; i++) {
        //730
        const newDate = moment(startDate, 'MMM DD,YYYY')
          .add(i, 'days')
          .format('MMM DD,YYYY');

        // set flag for a isComplete
        let entryIsComplete = entry.followDates
          ? entry.actions.isComplete
          : false;
        if (entry.completedDates) {
          entryIsComplete = entry.completedDates.includes(newDate);
        }

        //Edit Logic
        let newNotificationDate = [];
        entry.actions.notification.forEach((noti) => {
          const days = parseInt(noti.days);

          if (noti.date) {
            noti.date = moment(newDate, 'MMM DD,YYYY')
              .add(days, 'days')
              .format('MMM DD,YYYY');
          }

          newNotificationDate = [...newNotificationDate, noti];
        });
        //Deep Copy
        newNotificationDate = JSON.parse(
          JSON.stringify(newNotificationDate)
        );

        //Find for modification
        const editedEntry = entry.repeatEntries
          ? entry.repeatEntries.find((e) => e.from.date === newDate)
          : 0;
        let newEntry = JSON.parse(JSON.stringify(entry));

        if (editedEntry) {
          newEntry = JSON.parse(
            JSON.stringify({
              ...editedEntry,
              bulkID: entry.bulkID,
              isDeleted: entry.isDeleted || []
            })
          );
        }

        //Following logic
        if (newEntry.followDates) {
          newEntry.followDates.forEach((date) => {
            const diffDateInDay = moment(newDate, 'MMM DD,YYYY').diff(
              moment(date, 'MMM DD,YYYY'),
              'days'
            );
            if (diffDateInDay >= 0) {
              const editedEntry = entry.repeatEntries
                ? entry.repeatEntries.find((e) => e.from.date === date)
                : 0;
              if (editedEntry) {
                // Notification time update as per follow dates start
                newNotificationDate = getNewNotificationArray(
                  editedEntry,
                  newDate
                );
                newNotificationDate = JSON.parse(
                  JSON.stringify(newNotificationDate)
                );
                // Notification time update as per follow dates end
                newEntry = JSON.parse(JSON.stringify(editedEntry));
                entryIsComplete = editedEntry.actions.isComplete;
              }
            }
          });
        }
        if (!entry.repeatDeleted.includes(newDate)) {
          const isOriging = i === 0;
          occurance = occurance + 1;
          newRepeatEntriesModified = [
            ...newRepeatEntriesModified,
            {
              ...newEntry,
              docId: entry.docId,
              isDynamic: isOriging ? false : true,
              isRepeatEntry: isOriging ? false : true,
              id: isOriging ? entry.id : generateFileHash(20),
              parentRepeatEntryId: entry.id,
              linkedArray: isOriging ? entry.linkedArray : [],
              actions: {
                deadline: [],
                isCancelled: newEntry.actions.isCancelled,
                isPostponed: newEntry.actions.isPostponed,
                isChanged: newEntry.actions.isChanged,
                isComplete: entryIsComplete,
                notification:
                  editedEntry 
                  // &&
                  // newEntry.actions.notification.length > 0
                    ? newEntry.actions.notification
                    : newNotificationDate,
                reminder: [],
                repeat: entry.actions.repeat,
                value: []
              },
              completedDates: entry.completedDates || [],
              from: { date: newDate, time: newEntry.from.time },
              to: { date: newDate, time: newEntry.to.time }
            }
          ];
        }
        ////Edit Logic End
      }
    } else if (entry.actions.repeat[0].type === 'Custom') {
      //Custom daily repeat
      const values = entry.actions.repeat[0].values;
      if (values.repeatType === 'Day') {
        let repeatDay = parseInt(values.repeatDay);
        let endsAfter = parseInt(values.endsAfter);
        let arrayRepeat = [...Array(repeatDay)];
        let endsOn = moment(values.endsDate, 'MMM DD,YYYY');
        let endsType = values.endsType; //On || After
        for (let i = 0; i <= 1095; i++) {
          //730 1095
          if (i === 0) {
            arrayRepeat.pop();

            //newRepeatEntriesModified = [...newRepeatEntriesModified, entry];

            // set flag for a isComplete
            let entryIsComplete = entry.followDates
              ? entry.actions.isComplete
              : false;
            if (entry.completedDates) {
              entryIsComplete = entry.completedDates.includes(startDate);
            }

            //Edit Logic
            let newNotificationDate = [];
            entry.actions.notification.forEach((noti) => {
              const days = parseInt(noti.days);

              if (noti.date) {
                noti.date = moment(startDate, 'MMM DD,YYYY')
                  .add(days, 'days')
                  .format('MMM DD,YYYY');
              }

              newNotificationDate = [...newNotificationDate, noti];
            });
            //Deep Copy
            newNotificationDate = JSON.parse(
              JSON.stringify(newNotificationDate)
            );
            //Find for modification
            const editedEntry = entry.repeatEntries
              ? entry.repeatEntries.find((e) => e.from.date === startDate)
              : 0;

            let newEntry = JSON.parse(JSON.stringify(entry));
            if (editedEntry) {
              entryActions = {
                ...entryActions,
                isCancelled:
                  editedEntry?.actions?.isCancelled ||
                  entryActions?.actions?.isCancelled ||
                  false,
                isPostponed:
                  editedEntry?.actions?.isPostponed ||
                  entryActions?.actions?.isPostponed ||
                  false,
                isChanged:
                  editedEntry?.actions?.isChanged ||
                  entryActions?.actions?.isChanged ||
                  false
              };
              newEntry = JSON.parse(
                JSON.stringify({
                  ...editedEntry,
                  bulkID: entry.bulkID,
                  isDeleted: entry.isDeleted || []
                })
              );
            }

            //Following logic
            if (newEntry.followDates) {
              newEntry.followDates.forEach((date) => {
                const diffDateInDay = moment(startDate, 'MMM DD,YYYY').diff(
                  moment(date, 'MMM DD,YYYY'),
                  'days'
                );
                if (diffDateInDay >= 0) {
                  const editedEntry = entry.repeatEntries
                    ? entry.repeatEntries.find((e) => e.from.date === date)
                    : 0;
                  if (editedEntry) {
                    // Notification time update as per follow dates start

                    newNotificationDate = getNewNotificationArray(
                      editedEntry,
                      startDate
                    );
                    newNotificationDate = JSON.parse(
                      JSON.stringify(newNotificationDate)
                    );
                    // Notification time update as per follow dates end
                    newEntry = JSON.parse(JSON.stringify(editedEntry));
                    entryIsComplete = editedEntry.actions.isComplete;
                  }
                }
              });
            }
            if (!entry.repeatDeleted.includes(startDate)) {
              const isOriging = i === 0;
              occurance = occurance + 1;
              newRepeatEntriesModified = [
                ...newRepeatEntriesModified,
                {
                  ...newEntry,
                  docId: entry.docId,
                  isDynamic: isOriging ? false : true,
                  isRepeatEntry: isOriging ? false : true,
                  id: isOriging ? entry.id : generateFileHash(20),
                  parentRepeatEntryId: entry.id,
                  linkedArray: isOriging ? entry.linkedArray : [],
                  actions: {
                    deadline: [],
                    isCancelled: newEntry.actions.isCancelled,
                    isPostponed: newEntry.actions.isPostponed,
                    isChanged: newEntry.actions.isChanged,
                    isComplete: entryIsComplete,
                    notification:
                      editedEntry
                      //  &&
                      // newEntry.actions.notification.length > 0
                        ? newEntry.actions.notification
                        : newNotificationDate,
                    reminder: [],
                    repeat: entry.actions.repeat,
                    value: []
                  },
                  completedDates: entry.completedDates || [],
                  from: { date: startDate, time: newEntry.from.time },
                  to: { date: startDate, time: newEntry.to.time }
                }
              ];
            }
          } else {
            if (arrayRepeat.length === 0) {
              arrayRepeat = [...Array(repeatDay - 1)];
              const newDate = moment(startDate, 'MMM DD,YYYY')
                .add(i, 'days')
                .format('MMM DD,YYYY');

              if (endsType === 'On') {
                const diffDateInDay = endsOn.diff(newDate, 'days');
                if (diffDateInDay >= 0) {
                  // set flag for a isComplete
                  let entryIsComplete = entry.followDates
                    ? entry.actions.isComplete
                    : false;
                  if (entry.completedDates) {
                    entryIsComplete =
                      entry.completedDates.includes(newDate);
                  }

                  //Edit Logic
                  let newNotificationDate = [];
                  entry.actions.notification.forEach((noti) => {
                    const days = parseInt(noti.days);

                    if (noti.date) {
                      noti.date = moment(newDate, 'MMM DD,YYYY')
                        .add(days, 'days')
                        .format('MMM DD,YYYY');
                    }

                    newNotificationDate = [...newNotificationDate, noti];
                  });
                  //Deep Copy
                  newNotificationDate = JSON.parse(
                    JSON.stringify(newNotificationDate)
                  );
                  //Find for modification
                  const editedEntry = entry.repeatEntries
                    ? entry.repeatEntries.find(
                        (e) => e.from.date === newDate
                      )
                    : 0;
                  let newEntry = JSON.parse(JSON.stringify(entry));
                  if (editedEntry) {
                    newEntry = JSON.parse(
                      JSON.stringify({
                        ...editedEntry,
                        bulkID: entry.bulkID,
                        isDeleted: entry.isDeleted || []
                      })
                    );
                  }

                  //Following logic
                  if (newEntry.followDates) {
                    newEntry.followDates.forEach((date) => {
                      ///const diffDateInDay = moment(date, 'MMM DD,YYYY').diff(newDate, 'days');
                      const diffDateInDay = moment(
                        newDate,
                        'MMM DD,YYYY'
                      ).diff(moment(date, 'MMM DD,YYYY'), 'days');
                      if (diffDateInDay >= 0) {
                        const editedEntry = entry.repeatEntries
                          ? entry.repeatEntries.find(
                              (e) => e.from.date === date
                            )
                          : 0;
                        if (editedEntry) {
                          // Notification time update as per follow dates start
                          newNotificationDate = getNewNotificationArray(
                            editedEntry,
                            newDate
                          );
                          newNotificationDate = JSON.parse(
                            JSON.stringify(newNotificationDate)
                          );
                          // Notification time update as per follow dates end
                          newEntry = JSON.parse(
                            JSON.stringify(editedEntry)
                          );
                          entryIsComplete = editedEntry.actions.isComplete;
                        }
                      }
                    });
                  }
                  if (!entry.repeatDeleted.includes(newDate)) {
                    const isOriging = i === 0;
                    occurance = occurance + 1;
                    newRepeatEntriesModified = [
                      ...newRepeatEntriesModified,
                      {
                        ...newEntry,
                        docId: entry.docId,
                        isDynamic: isOriging ? false : true,
                        isRepeatEntry: isOriging ? false : true,
                        id: isOriging ? entry.id : generateFileHash(20),
                        parentRepeatEntryId: entry.id,
                        linkedArray: isOriging ? entry.linkedArray : [],
                        actions: {
                          deadline: [],
                          isCancelled: newEntry.actions.isCancelled,
                          isPostponed: newEntry.actions.isPostponed,
                          isChanged: newEntry.actions.isChanged,
                          isComplete: entryIsComplete,
                          notification:
                            editedEntry
                            //  &&
                            // newEntry.actions.notification.length > 0
                              ? newEntry.actions.notification
                              : newNotificationDate,
                          reminder: [],
                          repeat: entry.actions.repeat,
                          value: []
                        },
                        completedDates: entry.completedDates || [],
                        from: { date: newDate, time: newEntry.from.time },
                        to: { date: newDate, time: newEntry.to.time }
                      }
                    ];
                  }
                  ////Edit Logic End
                } else {
                  break;
                }
              } else if (endsType === 'After') {
                if (occurance >= endsAfter) {
                  break;
                } else {
                  const newDate = moment(startDate, 'MMM DD,YYYY')
                    .add(i, 'days')
                    .format('MMM DD,YYYY');

                  // set flag for a isComplete
                  let entryIsComplete = entry.followDates
                    ? entry.actions.isComplete
                    : false;
                  if (entry.completedDates) {
                    entryIsComplete =
                      entry.completedDates.includes(newDate);
                  }
                  //Edit Logic
                  let newNotificationDate = [];
                  entry.actions.notification.forEach((noti) => {
                    const days = parseInt(noti.days);

                    if (noti.date) {
                      noti.date = moment(newDate, 'MMM DD,YYYY')
                        .add(days, 'days')
                        .format('MMM DD,YYYY');
                    }

                    newNotificationDate = [...newNotificationDate, noti];
                  });
                  //Deep Copy
                  newNotificationDate = JSON.parse(
                    JSON.stringify(newNotificationDate)
                  );
                  //Find for modification
                  const editedEntry = entry.repeatEntries
                    ? entry.repeatEntries.find(
                        (e) => e.from.date === newDate
                      )
                    : 0;
                  let newEntry = JSON.parse(JSON.stringify(entry));
                  if (editedEntry) {
                    newEntry = JSON.parse(
                      JSON.stringify({
                        ...editedEntry,
                        bulkID: entry.bulkID,
                        isDeleted: entry.isDeleted || []
                      })
                    );
                  }

                  //Following logic
                  if (newEntry.followDates) {
                    newEntry.followDates.forEach((date) => {
                      ///const diffDateInDay = moment(date, 'MMM DD,YYYY').diff(newDate, 'days');
                      const diffDateInDay = moment(
                        newDate,
                        'MMM DD,YYYY'
                      ).diff(moment(date, 'MMM DD,YYYY'), 'days');
                      if (diffDateInDay >= 0) {
                        const editedEntry = entry.repeatEntries
                          ? entry.repeatEntries.find(
                              (e) => e.from.date === date
                            )
                          : 0;
                        if (editedEntry) {
                          // Notification time update as per follow dates start
                          newNotificationDate = getNewNotificationArray(
                            editedEntry,
                            newDate
                          );
                          newNotificationDate = JSON.parse(
                            JSON.stringify(newNotificationDate)
                          );
                          // Notification time update as per follow dates end
                          newEntry = JSON.parse(
                            JSON.stringify(editedEntry)
                          );
                          entryIsComplete = editedEntry.actions.isComplete;
                        }
                      }
                    });
                  }
                  if (!entry.repeatDeleted.includes(newDate)) {
                    const isOriging = i === 0;
                    occurance = occurance + 1;
                    newRepeatEntriesModified = [
                      ...newRepeatEntriesModified,
                      {
                        ...newEntry,
                        docId: entry.docId,
                        isDynamic: isOriging ? false : true,
                        isRepeatEntry: isOriging ? false : true,
                        id: isOriging ? entry.id : generateFileHash(20),
                        parentRepeatEntryId: entry.id,
                        linkedArray: isOriging ? entry.linkedArray : [],
                        actions: {
                          deadline: [],
                          isCancelled: newEntry.actions.isCancelled,
                          isPostponed: newEntry.actions.isPostponed,
                          isChanged: newEntry.actions.isChanged,
                          isComplete: entryIsComplete,
                          notification:
                            editedEntry
                            //  &&
                            // newEntry.actions.notification.length > 0
                              ? newEntry.actions.notification
                              : newNotificationDate,
                          reminder: [],
                          repeat: entry.actions.repeat,
                          value: []
                        },
                        completedDates: entry.completedDates || [],
                        from: { date: newDate, time: newEntry.from.time },
                        to: { date: newDate, time: newEntry.to.time }
                      }
                    ];
                  }
                  ////Edit Logic End
                }
              } else {
                const newDate = moment(startDate, 'MMM DD,YYYY')
                  .add(i, 'days')
                  .format('MMM DD,YYYY');
                // set flag for a isComplete
                let entryIsComplete = entry.followDates
                  ? entry.actions.isComplete
                  : false;
                if (entry.completedDates) {
                  entryIsComplete = entry.completedDates.includes(newDate);
                }
                //Edit Logic
                let newNotificationDate = [];
                entry.actions.notification.forEach((noti) => {
                  const days = parseInt(noti.days);

                  if (noti.date) {
                    noti.date = moment(newDate, 'MMM DD,YYYY')
                      .add(days, 'days')
                      .format('MMM DD,YYYY');
                  }
                  newNotificationDate = [...newNotificationDate, noti];
                });
                //Deep Copy
                newNotificationDate = JSON.parse(
                  JSON.stringify(newNotificationDate)
                );
                //Find for modification
                const editedEntry = entry.repeatEntries
                  ? entry.repeatEntries.find((e) => e.from.date === newDate)
                  : 0;
                let newEntry = JSON.parse(JSON.stringify(entry));
                if (editedEntry) {
                  newEntry = JSON.parse(
                    JSON.stringify({
                      ...editedEntry,
                      bulkID: entry.bulkID,
                      isDeleted: entry.isDeleted || []
                    })
                  );
                }

                //Following logic
                if (newEntry.followDates) {
                  newEntry.followDates.forEach((date) => {
                    ///const diffDateInDay = moment(date, 'MMM DD,YYYY').diff(newDate, 'days');
                    const diffDateInDay = moment(
                      newDate,
                      'MMM DD,YYYY'
                    ).diff(moment(date, 'MMM DD,YYYY'), 'days');
                    if (diffDateInDay >= 0) {
                      const editedEntry = entry.repeatEntries
                        ? entry.repeatEntries.find(
                            (e) => e.from.date === date
                          )
                        : 0;
                      if (editedEntry) {
                        // Notification time update as per follow dates start
                        newNotificationDate = getNewNotificationArray(
                          editedEntry,
                          newDate
                        );
                        newNotificationDate = JSON.parse(
                          JSON.stringify(newNotificationDate)
                        );
                        // Notification time update as per follow dates end
                        newEntry = JSON.parse(JSON.stringify(editedEntry));
                        entryIsComplete = editedEntry.actions.isComplete;
                      }
                    }
                  });
                }
                if (!entry.repeatDeleted.includes(newDate)) {
                  const isOriging = i === 0;
                  occurance = occurance + 1;
                  newRepeatEntriesModified = [
                    ...newRepeatEntriesModified,
                    {
                      ...newEntry,
                      docId: entry.docId,
                      isDynamic: isOriging ? false : true,
                      isRepeatEntry: isOriging ? false : true,
                      id: isOriging ? entry.id : generateFileHash(20),
                      parentRepeatEntryId: entry.id,
                      linkedArray: isOriging ? entry.linkedArray : [],
                      actions: {
                        deadline: [],
                        isCancelled: newEntry.actions.isCancelled,
                        isPostponed: newEntry.actions.isPostponed,
                        isChanged: newEntry.actions.isChanged,
                        isComplete: entryIsComplete,
                        notification:
                          editedEntry
                          //  &&
                          // newEntry.actions.notification.length > 0
                            ? newEntry.actions.notification
                            : newNotificationDate,
                        reminder: [],
                        repeat: entry.actions.repeat,
                        value: []
                      },
                      completedDates: entry.completedDates || [],
                      from: { date: newDate, time: newEntry.from.time },
                      to: { date: newDate, time: newEntry.to.time }
                    }
                  ];
                }
                ////Edit Logic End
              }
            } else {
              arrayRepeat.pop();
            }
          }
        }
      } else if (values.repeatType === 'Week') {
        let repeatDay = parseInt(values.repeatDay);
        let repeatOn = values.repeatOn;
        let endsAfter = parseInt(values.endsAfter);
        let arrayRepeat = [...Array(repeatDay)];
        let arrayRepeatOn = [...Array(repeatOn.length)];
        let endsOn = moment(values.endsDate, 'MMM DD,YYYY');
        let endsType = values.endsType; //On || After

        for (let i = 0; i <= 1095; i++) {
          //730 1095

          const newDate = moment(startDate, 'MMM DD,YYYY').add(i, 'days');
          const newDateFormated = newDate.format('MMM DD,YYYY');
          const weekDay = newDate.format('ddd');
          if (repeatOn.includes(weekDay)) {
            if (
              arrayRepeat.length === repeatDay ||
              arrayRepeat.length === 0
            ) {
              const diffDateInDay = endsOn.diff(newDate, 'days');
              if (endsType === 'On' && diffDateInDay < 0) {
                //Do nothing
              } else {
                // set flag for a isComplete
                let entryIsComplete = entry.followDates
                  ? entry.actions.isComplete
                  : false;
                if (entry.completedDates) {
                  entryIsComplete =
                    entry.completedDates.includes(newDateFormated);
                }

                //Edit Logic
                let newNotificationDate = [];
                entry.actions.notification.forEach((noti) => {
                  const days = parseInt(noti.days);

                  if (noti.date) {
                    noti.date = moment(newDateFormated, 'MMM DD,YYYY')
                      .add(days, 'days')
                      .format('MMM DD,YYYY');
                  }

                  newNotificationDate = [...newNotificationDate, noti];
                });
                //Deep Copy
                newNotificationDate = JSON.parse(
                  JSON.stringify(newNotificationDate)
                );
                //Find for modification
                const editedEntry = entry.repeatEntries
                  ? entry.repeatEntries.find(
                      (e) => e.from.date === newDateFormated
                    )
                  : 0;
                let newEntry = JSON.parse(JSON.stringify(entry));
                if (editedEntry) {
                  entryActions = {
                    ...entryActions,
                    isCancelled:
                      editedEntry?.actions?.isCancelled ||
                      entryActions?.actions?.isCancelled ||
                      false,
                    isPostponed:
                      editedEntry?.actions?.isPostponed ||
                      entryActions?.actions?.isPostponed ||
                      false,
                    isChanged:
                      editedEntry?.actions?.isChanged ||
                      entryActions?.actions?.isChanged ||
                      false
                  };
                  newEntry = JSON.parse(
                    JSON.stringify({
                      ...editedEntry,
                      bulkID: entry.bulkID,
                      isDeleted: entry.isDeleted || []
                    })
                  );
                }
                //Following logic
                if (newEntry.followDates) {
                  newEntry.followDates.forEach((date) => {
                    ///const diffDateInDay = moment(date, 'MMM DD,YYYY').diff(newDate, 'days');
                    const diffDateInDay = moment(
                      newDateFormated,
                      'MMM DD,YYYY'
                    ).diff(moment(date, 'MMM DD,YYYY'), 'days');
                    if (diffDateInDay >= 0) {
                      const editedEntry = entry.repeatEntries
                        ? entry.repeatEntries.find(
                            (e) => e.from.date === date
                          )
                        : 0;
                      if (editedEntry) {
                        // Notification time update as per follow dates start
                        newNotificationDate = getNewNotificationArray(
                          editedEntry,
                          newDateFormated
                        );
                        newNotificationDate = JSON.parse(
                          JSON.stringify(newNotificationDate)
                        );
                        // Notification time update as per follow dates end
                        newEntry = JSON.parse(JSON.stringify(editedEntry));
                        entryIsComplete = editedEntry.actions.isComplete;
                      }
                    }
                  });
                }
                if (!entry.repeatDeleted.includes(newDateFormated)) {
                  const isOriging = i === 0;
                  occurance = occurance + 1;
                  newRepeatEntriesModified = [
                    ...newRepeatEntriesModified,
                    {
                      ...newEntry,
                      docId: entry.docId,
                      isDynamic: isOriging ? false : true,
                      isRepeatEntry: isOriging ? false : true,
                      id: isOriging ? entry.id : generateFileHash(20),
                      parentRepeatEntryId: entry.id,
                      linkedArray: isOriging ? entry.linkedArray : [],
                      actions: {
                        deadline: [],
                        isCancelled: newEntry.actions.isCancelled,
                        isPostponed: newEntry.actions.isPostponed,
                        isChanged: newEntry.actions.isChanged,
                        isComplete: entryIsComplete,
                        notification:
                          editedEntry
                          //  &&
                          // newEntry.actions.notification.length > 0
                            ? newEntry.actions.notification
                            : newNotificationDate,
                        reminder: [],
                        repeat: entry.actions.repeat,
                        value: []
                      },
                      completedDates: entry.completedDates || [],
                      from: {
                        date: newDateFormated,
                        time: newEntry.from.time
                      },
                      to: { date: newDateFormated, time: newEntry.to.time }
                    }
                  ];
                }
                ////Edit Logic End
                arrayRepeatOn.pop();
              }
            } else {
              arrayRepeatOn.pop();
            }

            if (arrayRepeatOn.length === 0) {
              arrayRepeatOn = [...Array(repeatOn.length)];
              arrayRepeat = [...Array(arrayRepeat.length - 1)];
            }
            if (arrayRepeat.length === 0) {
              arrayRepeat = [...Array(repeatDay)];
            }
            //Stop logic
            if (endsType === 'On') {
              const diffDateInDay = endsOn.diff(newDate, 'days');
              if (diffDateInDay <= 0) {
                break;
              } else {
                //Do nothing
              }
            } else if (endsType === 'After') {
              if (occurance >= endsAfter * repeatOn.length) {
                break;
              }
            }
          }
        }
      } else if (values.repeatType === 'Month') {
        let repeatDay = parseInt(values.repeatDay);
        let endsOn = moment(values.endsDate, 'MMM DD,YYYY');
        let endsAfter = parseInt(values.endsAfter);
        let arrayRepeat = [...Array(repeatDay)];
        let endsType = values.endsType; //On || After
        let repeatMonth = values.repeatMonth;
        const repeatMonths =
          (36 + (12 - moment(startDate, 'MMM DD,YYYY').format('M'))) *
          repeatDay;
        const startDay = moment(startDate, 'MMM DD,YYYY').date();
        let firstEntry = false;
        for (let i = 0; i <= repeatMonths; i++) {
          //730 1095 36 Month
          if (i === 0) {
            firstEntry = true;
          } else {
            firstEntry = false;
          }
          if (
            arrayRepeat.length === repeatDay ||
            arrayRepeat.length === 0
          ) {
            if (arrayRepeat.length === 0) {
              arrayRepeat = [...Array(repeatDay)];
            }

            const newDate = moment(startDate, 'MMM DD,YYYY').add(
              i,
              'months'
            );
            const newDateFormated = newDate.format('MMM DD,YYYY');
            const daysInMonth = newDate.daysInMonth();
            const startOfMonth = moment(newDate).startOf('month');
            // const newDateTo = moment(newDateFormated, 'MMM DD,YYYY')
            //                   .add(i, 'days')
            //                   .format('MMM DD,YYYY');

            if (repeatMonth.type === 'day') {
              for (let i = 0; i <= daysInMonth - 1; i++) {
                const newMDate = moment(startOfMonth, 'MMM DD,YYYY').add(
                  i,
                  'days'
                );
                if (startDay === newMDate.date()) {
                  const diffDateInDay = endsOn.diff(newMDate, 'days');
                  if (endsType === 'On' && diffDateInDay < 0) {
                    // Do Nothing
                  } else {
                    // set flag for a isComplete
                    let entryIsComplete = entry.followDates
                      ? entry.actions.isComplete
                      : false;
                    if (entry.completedDates) {
                      entryIsComplete =
                        entry.completedDates.includes(newDateFormated);
                    }
                    //Edit Logic
                    let newNotificationDate = [];
                    entry.actions.notification.forEach((noti) => {
                      const days = parseInt(noti.days);

                      if (noti.date) {
                        noti.date = moment(newDateFormated, 'MMM DD,YYYY')
                          .add(days, 'days')
                          .format('MMM DD,YYYY');
                      }

                      newNotificationDate = [...newNotificationDate, noti];
                    });
                    //Deep Copy
                    newNotificationDate = JSON.parse(
                      JSON.stringify(newNotificationDate)
                    );
                    //Find for modification
                    const editedEntry = entry.repeatEntries
                      ? entry.repeatEntries.find(
                          (e) => e.from.date === newDateFormated
                        )
                      : 0;
                    let newEntry = JSON.parse(JSON.stringify(entry));
                    if (editedEntry) {
                      entryActions = {
                        ...entryActions,
                        isCancelled:
                          editedEntry?.actions?.isCancelled ||
                          entryActions?.actions?.isCancelled ||
                          false,
                        isPostponed:
                          editedEntry?.actions?.isPostponed ||
                          entryActions?.actions?.isPostponed ||
                          false,
                        isChanged:
                          editedEntry?.actions?.isChanged ||
                          entryActions?.actions?.isChanged ||
                          false
                      };
                      newEntry = JSON.parse(
                        JSON.stringify({
                          ...editedEntry,
                          bulkID: entry.bulkID,
                          isDeleted: entry.isDeleted || []
                        })
                      );
                    }

                    //Following logic
                    if (newEntry.followDates) {
                      newEntry.followDates.forEach((date) => {
                        ///const diffDateInDay = moment(date, 'MMM DD,YYYY').diff(newDate, 'days');
                        const diffDateInDay = moment(
                          newDateFormated,
                          'MMM DD,YYYY'
                        ).diff(moment(date, 'MMM DD,YYYY'), 'days');
                        if (diffDateInDay >= 0) {
                          const editedEntry = entry.repeatEntries
                            ? entry.repeatEntries.find(
                                (e) => e.from.date === date
                              )
                            : 0;
                          if (editedEntry) {
                            // Notification time update as per follow dates start
                            newNotificationDate = getNewNotificationArray(
                              editedEntry,
                              newDateFormated
                            );
                            newNotificationDate = JSON.parse(
                              JSON.stringify(newNotificationDate)
                            );
                            // Notification time update as per follow dates end
                            newEntry = JSON.parse(
                              JSON.stringify(editedEntry)
                            );
                            entryIsComplete =
                              editedEntry.actions.isComplete;
                          }
                        }
                      });
                    }
                    if (!entry.repeatDeleted.includes(newDateFormated)) {
                      const isOriging = firstEntry;
                      occurance = occurance + 1;
                      newRepeatEntriesModified = [
                        ...newRepeatEntriesModified,
                        {
                          ...newEntry,
                          docId: entry.docId,
                          isDynamic: isOriging ? false : true,
                          isRepeatEntry: isOriging ? false : true,
                          id: isOriging ? entry.id : generateFileHash(20),
                          parentRepeatEntryId: entry.id,
                          linkedArray: isOriging ? entry.linkedArray : [],
                          actions: {
                            deadline: [],
                            isCancelled: newEntry.actions.isCancelled,
                            isPostponed: newEntry.actions.isPostponed,
                            isChanged: newEntry.actions.isChanged,
                            isComplete: entryIsComplete,
                            notification:
                              editedEntry
                              //  &&
                              // newEntry.actions.notification.length > 0
                                ? newEntry.actions.notification
                                : newNotificationDate,
                            reminder: [],
                            repeat: entry.actions.repeat,
                            value: []
                          },
                          completedDates: entry.completedDates || [],
                          from: {
                            date: newDateFormated,
                            time: newEntry.from.time
                          },
                          to: {
                            date: newDateFormated,
                            time: newEntry.to.time
                          }
                        }
                      ];
                    }
                  }
                  ////Edit Logic End
                }
              }
            } else {
              //Week logic
              const daysInMonth = moment(
                newDate,
                'MMM DD,YYYY'
              ).daysInMonth();
              const startOfMonth = moment(newDate, 'MMM DD,YYYY').startOf(
                'month'
              );
              let numberOfWeekDay = weekOfMonth(
                repeatMonth.value,
                repeatMonth.value
              );
              let positionOfWeekDay = weekOfMonth(
                repeatMonth.value,
                repeatMonth.value,
                true
              );

              let numberOfWeekDayM = weekOfMonth(
                newDateFormated,
                repeatMonth.value
              );

              const weekDay = moment(
                repeatMonth.value,
                'MMM DD,YYYY'
              ).format('ddd');

              if (numberOfWeekDay > numberOfWeekDayM) {
                numberOfWeekDay = numberOfWeekDayM;
              }
              if (positionOfWeekDay > numberOfWeekDayM) {
                positionOfWeekDay = numberOfWeekDayM;
              }
              let monthWeekDayCount = 0;
              for (let i = 0; i < daysInMonth; i++) {
                const newMDate = moment(startOfMonth, 'MMM DD,YYYY').add(
                  i,
                  'days'
                );
                const weekMDay = moment(newMDate, 'MMM DD,YYYY').format(
                  'ddd'
                );
                // const newDateTo = newMDate.add(i, 'days')
                // .format('MMM DD,YYYY');

                if (weekMDay === weekDay) {
                  monthWeekDayCount = monthWeekDayCount + 1;
                }
                if (monthWeekDayCount === positionOfWeekDay) {
                  const diffDateInDay = endsOn.diff(newMDate, 'days');
                  if (endsType === 'On' && diffDateInDay < 0) {
                    //Do nothing
                  } else {
                    // set flag for a isComplete
                    let entryIsComplete = entry.followDates
                      ? entry.actions.isComplete
                      : false;
                    if (entry.completedDates) {
                      entryIsComplete =
                        entry.completedDates.includes(newMDate);
                    }

                    //Edit Logic
                    let newNotificationDate = [];
                    entry.actions.notification.forEach((noti) => {
                      const days = parseInt(noti.days);

                      if (noti.date) {
                        noti.date = moment(
                          newMDate.format('MMM DD,YYYY'),
                          'MMM DD,YYYY'
                        )
                          .add(days, 'days')
                          .format('MMM DD,YYYY');
                      }

                      newNotificationDate = [...newNotificationDate, noti];
                    });
                    //Deep Copy
                    newNotificationDate = JSON.parse(
                      JSON.stringify(newNotificationDate)
                    );
                    //Find for modification
                    const editedEntry = entry.repeatEntries
                      ? entry.repeatEntries.find(
                          (e) => e.from.date === newMDate.format('MMM DD,YYYY')
                        )
                      : 0;
                    let newEntry = JSON.parse(JSON.stringify(entry));
                    if (editedEntry) {
                      newEntry = JSON.parse(
                        JSON.stringify({
                          ...editedEntry,
                          bulkID: entry.bulkID,
                          isDeleted: entry.isDeleted || []
                        })
                      );
                    }

                    //Following logic
                    if (newEntry.followDates) {
                      newEntry.followDates.forEach((date) => {
                        const diffDateInDay = moment(
                          newMDate.format('MMM DD,YYYY'),
                          'MMM DD,YYYY'
                        ).diff(moment(date, 'MMM DD,YYYY'), 'days');
                        if (diffDateInDay >= 0) {
                          const editedEntry = entry.repeatEntries
                            ? entry.repeatEntries.find(
                                (e) => e.from.date === date
                              )
                            : 0;
                          if (editedEntry) {
                            // Notification time update as per follow dates start
                            newNotificationDate = getNewNotificationArray(
                              editedEntry,
                              newMDate
                            );
                            newNotificationDate = JSON.parse(
                              JSON.stringify(newNotificationDate)
                            );
                            // Notification time update as per follow dates end
                            newEntry = JSON.parse(
                              JSON.stringify(editedEntry)
                            );
                            entryIsComplete =
                              editedEntry.actions.isComplete;
                          }
                        }
                      });
                    }

                    if (
                      !entry.repeatDeleted.includes(
                        newMDate.format('MMM DD,YYYY')
                      )
                    ) {
                      const isOriging = firstEntry;
                      occurance = occurance + 1;
                      newRepeatEntriesModified = [
                        ...newRepeatEntriesModified,
                        {
                          ...newEntry,
                          docId: entry.docId,
                          isDynamic: isOriging ? false : true,
                          isRepeatEntry: isOriging ? false : true,
                          id: isOriging ? entry.id : generateFileHash(20),
                          parentRepeatEntryId: entry.id,
                          linkedArray: isOriging ? entry.linkedArray : [],
                          actions: {
                            deadline: [],
                            isCancelled: newEntry.actions.isCancelled,
                            isPostponed: newEntry.actions.isPostponed,
                            isChanged: newEntry.actions.isChanged,
                            isComplete: entryIsComplete,
                            notification:
                              editedEntry
                              //  &&
                              // newEntry.actions.notification.length > 0
                                ? newEntry.actions.notification
                                : newNotificationDate,
                            reminder: [],
                            repeat: entry.actions.repeat,
                            value: []
                          },
                          completedDates: entry.completedDates || [],
                          from: {
                            date: newMDate.format('MMM DD,YYYY'),
                            time: newEntry.from.time
                          },
                          to: {
                            date: newMDate.format('MMM DD,YYYY'),
                            time: newEntry.to.time
                          }
                        }
                      ];
                    }
                    ////Edit Logic End
                  }

                  break;
                }
              }
            }
            if (endsType === 'After') {
              if (occurance >= endsAfter) {
                break;
              }
            }
          }
          arrayRepeat = [...Array(arrayRepeat.length - 1)];
        }
      } else if (values.repeatType === 'Year') {
        let repeatDay = parseInt(values.repeatDay);
        let endsAfter = parseInt(values.endsAfter);
        let arrayRepeat = [...Array(repeatDay)];
        let endsOn = moment(values.endsDate, 'MMM DD,YYYY');
        let endsType = values.endsType; //On || After
        const repeatYears = 50 * repeatDay;

        for (let i = 0; i <= repeatYears; i++) {
          //730 1095
          if (
            arrayRepeat.length === repeatDay ||
            arrayRepeat.length === 0
          ) {
            if (arrayRepeat.length === 0) {
              arrayRepeat = [...Array(repeatDay)];
            }
            const newDate = moment(startDate, 'MMM DD,YYYY').add(
              i,
              'years'
            );
            const newDateFormated = newDate.format('MMM DD,YYYY');

            const diffDateInDay = endsOn.diff(newDate, 'days');
            if (endsType === 'On' && diffDateInDay < 0) {
              //Do nothing
            } else {
              // set flag for a isComplete
              let entryIsComplete = entry.followDates
                ? entry.actions.isComplete
                : false;
              if (entry.completedDates) {
                entryIsComplete =
                  entry.completedDates.includes(newDateFormated);
              }

              //Edit Logic
              let newNotificationDate = [];
              entry.actions.notification.forEach((noti) => {
                const days = parseInt(noti.days);

                if (noti.date) {
                  noti.date = moment(newDateFormated, 'MMM DD,YYYY')
                    .add(days, 'days')
                    .format('MMM DD,YYYY');
                }

                newNotificationDate = [...newNotificationDate, noti];
              });
              //Deep Copy
              newNotificationDate = JSON.parse(
                JSON.stringify(newNotificationDate)
              );
              //Find for modification
              const editedEntry = entry.repeatEntries
                ? entry.repeatEntries.find(
                    (e) => e.from.date === newDateFormated
                  )
                : 0;
              let newEntry = JSON.parse(JSON.stringify(entry));
              if (editedEntry) {
                entryActions = {
                  ...entryActions,
                  isCancelled:
                    editedEntry?.actions?.isCancelled ||
                    entryActions?.actions?.isCancelled ||
                    false,
                  isPostponed:
                    editedEntry?.actions?.isPostponed ||
                    entryActions?.actions?.isPostponed ||
                    false,
                  isChanged:
                    editedEntry?.actions?.isChanged ||
                    entryActions?.actions?.isChanged ||
                    false
                };
                newEntry = JSON.parse(
                  JSON.stringify({
                    ...editedEntry,
                    bulkID: entry.bulkID,
                    isDeleted: entry.isDeleted || []
                  })
                );
              }

              //Following logic
              if (newEntry.followDates) {
                newEntry.followDates.forEach((date) => {
                  ///const diffDateInDay = moment(date, 'MMM DD,YYYY').diff(newDate, 'days');
                  const diffDateInDay = moment(
                    newDateFormated,
                    'MMM DD,YYYY'
                  ).diff(moment(date, 'MMM DD,YYYY'), 'days');
                  if (diffDateInDay >= 0) {
                    const editedEntry = entry.repeatEntries
                      ? entry.repeatEntries.find(
                          (e) => e.from.date === date
                        )
                      : 0;
                    if (editedEntry) {
                      // Notification time update as per follow dates start
                      newNotificationDate = getNewNotificationArray(
                        editedEntry,
                        newDateFormated
                      );
                      newNotificationDate = JSON.parse(
                        JSON.stringify(newNotificationDate)
                      );
                      // Notification time update as per follow dates end
                      newEntry = JSON.parse(JSON.stringify(editedEntry));
                      entryIsComplete = editedEntry.actions.isComplete;
                    }
                  }
                });
              }
              if (!entry.repeatDeleted.includes(newDateFormated)) {
                const isOriging = i === 0;
                occurance = occurance + 1;
                newRepeatEntriesModified = [
                  ...newRepeatEntriesModified,
                  {
                    ...newEntry,
                    docId: entry.docId,
                    isDynamic: isOriging ? false : true,
                    isRepeatEntry: isOriging ? false : true,
                    id: isOriging ? entry.id : generateFileHash(20),
                    parentRepeatEntryId: entry.id,
                    linkedArray: isOriging ? entry.linkedArray : [],
                    actions: {
                      deadline: [],
                      isCancelled: newEntry.actions.isCancelled,
                      isPostponed: newEntry.actions.isPostponed,
                      isChanged: newEntry.actions.isChanged,
                      isComplete: entryIsComplete,
                      notification:
                        editedEntry
                        //  &&
                        // newEntry.actions.notification.length > 0
                          ? newEntry.actions.notification
                          : newNotificationDate,
                      reminder: [],
                      repeat: entry.actions.repeat,
                      value: []
                    },
                    completedDates: entry.completedDates || [],
                    from: {
                      date: newDateFormated,
                      time: newEntry.from.time
                    },
                    to: { date: newDateFormated, time: newEntry.to.time }
                  }
                ];
              }
              ////Edit Logic End
            }
            if (endsType === 'After') {
              if (occurance >= endsAfter) {
                break;
              }
            }
          }

          arrayRepeat = [...Array(arrayRepeat.length - 1)];
        }
      }
      //Custom daily repeat end
    }
  });

  return newRepeatEntriesModified;
}