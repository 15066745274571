/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import _ from 'lodash';

// Assets
import AddIcon from '@material-ui/icons/Add';
import { ReactComponent as SearchIconSVG } from 'src/assets/search-icon.svg';
import api_icon from 'src/assets/apiIcon.svg';
import {
  membersListPendingStyles,
  membersListPendingToolbarStyles
} from 'src/styles/membersStyle/membersListPendingStyles';

// Constants
import { PENDING_MEMBER_COLUMNS } from 'src/constants/table';
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  MEMBER_ACCOUNT_STATUS,
  SEARCH_DEBOUNCE_TIME
} from 'src/constants/common';

// Material-UI Components
import {
  Typography,
  TableHead,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Checkbox,
  Paper,
  Table,
  TablePagination,
  Toolbar,
  Tooltip,
  TableContainer,
  TableBody,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Components
import Modal from 'src/components/Modal';

// Layouts
import Dashboard from 'src/views/layout/Dashboard';

// Redux-Actions
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { resetFamily } from 'src/redux/actions/familyActions';
import { setFreeSchoolList } from 'src/redux/actions/reusableStateValueActions';
import { MESSAGE_TYPES } from 'src/constants/common';

// Services
import {
  deleteMultipleMembersIds,
  getMember,
  getMembers
} from 'src/services/members';

// Utils
import { routes, year as DefaultYear, status } from 'src/utils/constant';
import { withFirebase } from 'src/utils/firebase';
import { getFreeSchools } from 'src/services/schools';

const statusColors = {
  Invited: '#e8f1f5',
  'Remind 1': '#f5f1e8',
  'Remind 2': '#f0e8f5',
  Open: '#e8f5e9',
  'Open Remind': '#e8e9f5',
  Remove: '#fbcecd'
};

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    selected
  } = props;

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{
            position: selected.length > 0 ? 'relative' : 'none',
            height: selected.length > 0 ? 0 : 0,
            background:
              selected.length > 0 ? 'rgba(139, 197, 23, 0.5)' : '#f4f5f7'
          }}
        >
          <Checkbox
            className={classes.checkbox}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>

        {PENDING_MEMBER_COLUMNS.map((headCell) => (
          <TableCell
            style={{
              background:
                selected.length > 0 ? 'rgba(139, 197, 23, 0.5)' : '#f4f5f7',
              fontSize: '14px'
            }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label.toLocaleUpperCase()}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const classes = membersListPendingToolbarStyles();
  const { numSelected, selected } = props;
  const dispatch = useDispatch();
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const isLoading = useSelector((state) => state.app.isLoading);
  const [deleteText, setDeleteText] = useState('');

  const deleteUserHandle = async () => {
    setDeleteText('');
    dispatch(loaderStart());
    selected.forEach((el) => {
      getMember(el)
        .then(async (response) => {
          const familyData = response.data;
          const pushRecord = {
            members: []
          };
          Array.isArray(familyData.childs) &&
            familyData.childs.map((childData) => {
              let groups = _.compact([childData.group, childData.groups]);
              // eslint-disable-next-line prefer-spread
              groups = _.uniq([].concat.apply([], groups));
              groups.forEach((el) => {
                pushRecord.members.push({
                  childId: childData.id,
                  groupId: el,
                  isAdd: false
                });
              });
            });
        })
        .catch((err) => {
          console.log('Error: ', err);
        });
    });

    deleteMultipleMembersIds(selected)
      .then(() => {
        dispatch(addMessage('Family Deleted'));
        setOpenDeleteModal(false);
        dispatch(loaderStop());
        window.location.reload();
      })
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(
          addMessage(
            'Family was not deleted. Please try again',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <div className={classes.modalViewerDesign}>
          <Typography
            className={classes.deleteText}
            variant="subtitle1"
            component="div"
            onClick={() => setOpenDeleteModal && setOpenDeleteModal(true)}
          >
            Delete
          </Typography>

          {isOpenDeleteModal && (
            <Modal
              isOpen={isOpenDeleteModal}
              onClose={() => {
                setOpenDeleteModal(false);
              }}
            >
              <div className={classes.modalBox}>
                <Typography className={classes.modalTitle}>
                  Delete Member Account?
                </Typography>
                <div className={classes.modalSubHeading}>
                  <Typography className={classes.modalSubTitle}>
                    <span style={{ fontWeight: 'bold' }}>
                      Warning: This action cannot be reversed.
                    </span>
                    <br />
                    The following data will be permanently removed from the
                    database.
                  </Typography>
                </div>
                <div className={classes.modalContent}>
                  <Typography className={classes.modalSubTitle}>
                    - Member account (admin)
                    <br />
                    - Member account and login credentials (app)
                    <br />
                  </Typography>
                </div>
                <div className={classes.modalSubHeading}>
                  <span
                    className={classes.modalSubTitle}
                    style={{ fontWeight: 500, paddingBottom: 14 }}
                  >
                    Type “DELETE ACCOUNT” to confirm
                  </span>
                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <TextField
                      label=""
                      variant="outlined"
                      className={classes.inputModalControlBox}
                      style={{ marginTop: 0 }}
                      value={deleteText}
                      onChange={(e) => setDeleteText(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className={classes.modalActions}>
                  <Button
                    onClick={deleteUserHandle}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={deleteText !== 'DELETE ACCOUNT'}
                    className={classes.primaryButton}
                    startIcon={
                      isLoading && (
                        <CircularProgress color="secondary" size={25} />
                      )
                    }
                  >
                    {!isLoading && 'Delete'}
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenDeleteModal(false);
                    }}
                    variant="contained"
                    size="large"
                    className={classes.greyButton}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </div>
      ) : (
        ''
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">{/* <DeleteIcon /> */}</IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            {/* <FilterListIcon /> */}
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

function MembersListPending(props) {
  const classes = membersListPendingStyles();
  const history = useHistory();

  const {
    columns,
    rows,
    schoolChangeHandlerFamilyFree,
    schoolChangeHandlerFamilyPaid,
    firebase
  } = props;

  const [activeFamilies] = useState([]);
  const [newFamilies] = useState([]);
  const [year, setYear] = useState('');
  const [school, setSchool] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [showClearFilters, setShowClearFilters] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('statusUpdatedAt');
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [schoolDropDownList, setSchoolDropDown] = useState([]);
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [familyCount, setFamilyCount] = useState({
    activeMemberCount: 0,
    newMemberCount: 0,
    pendingMemberCount: 0
  });
  const [tableData, setTableData] = useState([]);
  const [tableRecords, setTableRecords] = useState(0);

  const dispatch = useDispatch();
  const prefillFamilyData = useSelector((state) => state.family.currentFamily);
  const { freeSchoolList } = useSelector((state) => state.reusableStoreData);

  const formatTableData = useCallback(
    (unFormattedTableData) => {
      return unFormattedTableData?.map((data) => {
        return {
          ...data,
          studentReferences: data?.studentReferences?.map((student) => {
            const school = freeSchoolList.find((s) => s.id === student.school);
            return {
              ...student,
              school: school?.schoolName
            };
          })
        };
      });
    },
    [freeSchoolList]
  );

  useEffect(() => {
    dispatch(resetFamily());
  }, []);

  useEffect(() => {
    if (freeSchoolList.length > 0) {
      dispatch(loaderStart());
      getMembers({
        searchText: debouncedSearchText,
        pageNo: Number(page) + 1,
        pageSize: rowsPerPage,
        schoolId: school,
        year: year,
        accountStatus: MEMBER_ACCOUNT_STATUS.PENDING,
        pendingStatus: selectedStatus
      })
        .then((result) => {
          setTableData(formatTableData(result?.data));
          setFamilyCount(result?.accountStatusCount);
          setTableRecords(result?.totalRecords);
          dispatch(loaderStop());
        })
        .catch((error) => {
          console.log(error);
          dispatch(loaderStop());
        });
    }
  }, [
    debouncedSearchText,
    page,
    rowsPerPage,
    school,
    year,
    selectedStatus,
    freeSchoolList
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDebouncedSearchValue = useCallback(
    _.debounce((debouncedValue) => {
      setDebouncedSearchText(debouncedValue);
    }, SEARCH_DEBOUNCE_TIME),
    []
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleGlobalCheckBox = (event) => {
    if (event.target.checked) {
      const ids = tableData?.map((data) => data.id);
      setSelectedRows(ids);
      return;
    }
    setSelectedRows([]);
  };

  const handleRowCheckBox = (event, name) => {
    const selectedIndex = selectedRows.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows?.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  // Page change next/prev function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Page size change function
  const handleChangeRowsPerPage = (event) => {
    const newRowsCount = +event.target.value;
    setRowsPerPage(newRowsCount);
    setPage(DEFAULT_PAGE);
  };

  const isSelected = (name) => selectedRows.indexOf(name) !== -1;

  useEffect(() => {
    dispatch(loaderStart());
    getFreeSchools()
      .then((result) => {
        setSchoolDropDown(result);
        dispatch(setFreeSchoolList(result));
        dispatch(loaderStop());
      })
      .catch((error) => {
        dispatch(addMessage(error?.message, MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  }, [dispatch]);

  // School change function
  const schoolChangeHandler = (e) => {
    const schoolID = e.target.value;
    setPage(DEFAULT_PAGE);
    setSchool(schoolID);
  };

  // Clear all filters
  const filterClearHandler = () => {
    setPage(DEFAULT_PAGE);
    setYear('');
    setSchool('');
    setSelectedStatus('');
    setShowClearFilters(false);
  };

  // Year change function
  const yearChangeHandler = (e) => {
    const yearValue = e.target.value;
    setPage(DEFAULT_PAGE);
    setYear(yearValue);
  };

  // Status change function
  const statusChangeHandler = (e) => {
    const childStatus = e.target.value;
    setPage(DEFAULT_PAGE);
    setSelectedStatus(childStatus);
  };

  const handleRowSelect = (row) => {
    history.push(routes.memberAccount + '/' + 'pending' + '/' + row['id']);
  };

  // Filter school name
  const filterSchoolNameList = (row) => {
    const childSchools = row?.studentReferences.map((child) => child.school);
    const schools = _.compact(_.uniq(childSchools));
    return (
      <>
        {schools.map((schoolName, schoolIndex) => {
          return <div key={schoolIndex}>{schoolName}</div>;
        })}
      </>
    );
  };
  // Filter years data to display
  const filterYearsDisplayData = (value) => {
    const result = value.reduce((acc, student) => {
      const existingSchool = acc.find((item) => item.school === student.school);

      if (existingSchool) {
        // School already exists, update the year array
        if (!existingSchool.year.includes(student.year)) {
          existingSchool.year.push(student.year);
        }
      } else {
        // School doesn't exist, create a new entry
        acc.push({ school: student.school, year: [student.year] });
      }

      return acc;
    }, []);

    const yearData = result.map((p, index) => {
      return (
        <>
          <span key={index}>
            {p.year.map((data, dataIdx) => {
              return (
                <>
                  {data === '-1' ? `PK` : data === `0` ? `K` : `Y${data}`}
                  {p?.year.length !== dataIdx + 1 ? `, ` : ''}
                </>
              );
            })}
          </span>
          <br />
        </>
      );
    });
    return yearData;
  };

  // Search text change function
  const searchChangeHandler = (e) => {
    const { value } = e.target;
    setPage(DEFAULT_PAGE);
    getDebouncedSearchValue(value.toLowerCase().trim());
  };

  return (
    <Dashboard>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20 }}
        >
          Members
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.actionButton}
            startIcon={<AddIcon />}
            classes={{ startIcon: classes.startIcon }}
            onClick={() => history.push(routes.memberCreate)}
          >
            New Members
          </Button>
        </Typography>

        <div className={classes.root}>
          <Paper className={classes.paper}>
            <div className={classes.header}>
              <div colSpan={columns?.length} className={classes.inputTableCell}>
                <TextField
                  size="small"
                  name="searchClient"
                  variant="outlined"
                  placeholder="Search by surname or email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIconSVG />
                      </InputAdornment>
                    )
                  }}
                  onChange={searchChangeHandler}
                  classes={{
                    root: classes.textFieldRoot
                  }}
                />
                <FormControl
                  variant="outlined"
                  size="small"
                  classes={{ root: classes.inputControlBoxContainer }}
                >
                  <InputLabel
                    className={classes.placeholderText}
                    id="country-select-outlined-label"
                  >
                    All Schools
                  </InputLabel>

                  <Select
                    labelId="schoolName-select-outlined-label"
                    className={classes.inputControlBox}
                    style={{
                      height: 45,
                      width: 273,
                      marginRight: 12,
                      marginTop: 0
                    }}
                    name="schoolData"
                    onChange={
                      schoolChangeHandler ||
                      schoolChangeHandlerFamilyFree ||
                      schoolChangeHandlerFamilyPaid
                    }
                    id="school-name-select-outlined"
                    label="All Schools"
                    value={school}
                  >
                    <MenuItem value="">
                      <em>All Schools</em>
                    </MenuItem>

                    {schoolDropDownList?.map(function (object) {
                      return (
                        <MenuItem key={object.id} value={object.id}>
                          {object.schoolName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl
                  variant="outlined"
                  size="small"
                  classes={{ root: classes.inputControlBoxContainer }}
                  style={{ width: 185 }}
                >
                  <InputLabel
                    id="year-select-outlined-label"
                    className={classes.placeholderText}
                  >
                    Year
                  </InputLabel>
                  <Select
                    labelId="year-select-outlined-label"
                    className={classes.inputControlBox}
                    name="years"
                    onChange={yearChangeHandler}
                    style={{
                      height: 45,
                      width: 173,
                      marginRight: 12,
                      marginTop: 0
                    }}
                    value={year}
                    id="year-select-outlined"
                    label="Year"
                  >
                    <MenuItem value="">
                      <em>All Years</em>
                    </MenuItem>
                    {DefaultYear &&
                      DefaultYear.map((object) => {
                        return (
                          <MenuItem key={object.value} value={object.value}>
                            {object.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

                <FormControl
                  variant="outlined"
                  size="small"
                  classes={{ root: classes.inputControlBoxContainer }}
                  style={{ width: 185 }}
                >
                  <InputLabel
                    id="status-select-outlined-label"
                    className={classes.placeholderText}
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="status-select-outlined-label"
                    className={classes.inputControlBox}
                    name="status"
                    onChange={statusChangeHandler}
                    style={{
                      height: 45,
                      width: 173,
                      marginTop: 0
                    }}
                    id="status-select-outlined"
                    label="Status"
                    value={selectedStatus}
                  >
                    <MenuItem value="">
                      <em>All Status</em>
                    </MenuItem>
                    {status &&
                      status.map((object) => {
                        return (
                          <MenuItem key={object.value} value={object.value}>
                            {object.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

                {showClearFilters &&
                window.location.pathname.includes('member') ? (
                  <div
                    className={classes.clearFiltersContainer}
                    onClick={filterClearHandler}
                  >
                    <span>clear</span>
                  </div>
                ) : null}

                <span className={classes.statusFilterContainer}>
                  <Button
                    className={classes.freeMembersButton}
                    onClick={() => history.push(routes.membersActive)}
                    style={{
                      border:
                        window.location.pathname === routes.membersActive
                          ? '1px solid #8bc517'
                          : '1px solid #bfc5d1',
                      backgroundColor:
                        window.location.pathname === routes.membersActive
                          ? 'rgba(139, 197, 23, 0.2)'
                          : '#fff'
                    }}
                  >
                    <div className={classes.filterCard}>
                      <span>Active</span>
                      <span
                        className={
                          window.location.pathname === routes.membersActive
                            ? classes.countmemberBox
                            : classes.memberboxActiveDisable
                        }
                      >
                        {familyCount?.activeMemberCount}
                      </span>
                    </div>
                  </Button>

                  <Button
                    className={classes.freeMembersButton}
                    onClick={() => history.push(routes.membersNew)}
                    style={{
                      border:
                        window.location.pathname === routes.membersNew
                          ? '1px solid #0084ff'
                          : '1px solid #bfc5d1',
                      backgroundColor:
                        window.location.pathname === routes.membersNew
                          ? 'rgba(0, 132, 255, 0.2)'
                          : '#fff'
                    }}
                  >
                    <div className={classes.filterCard}>
                      <span>New</span>
                      <span className={classes.countmemberBoxNew}>
                        {familyCount?.newMemberCount}
                      </span>
                    </div>
                  </Button>

                  <Button
                    className={classes.freeMembersButton}
                    onClick={() => history.push(routes.membersPending)}
                    style={{
                      border:
                        window.location.pathname === routes.membersPending
                          ? '1px solid #ff9b00'
                          : '1px solid #bfc5d1',
                      backgroundColor:
                        window.location.pathname === routes.membersPending
                          ? 'rgba(255, 155, 0, 0.2)'
                          : '#fff'
                    }}
                  >
                    <div className={classes.filterCard}>
                      <span>Pending</span>
                      <span className={classes.countmemberBoxPending}>
                        {tableRecords}
                      </span>
                    </div>
                  </Button>
                </span>
              </div>
            </div>
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  position: selectedRows.length > 0 ? 'absolute' : 'absolute',
                  zIndex: selectedRows.length > 0 ? 3 : 0,
                  left: selectedRows.length > 0 ? '48px' : 0,
                  width: selectedRows.length > 0 ? 'calc(100% - 48px)' : 0,
                  top: 0,
                  backgroundColor: selectedRows.length > 0 ? 'white' : '',
                  height: selectedRows.length > 0 ? '60px' : 0,
                  overflow: selectedRows.length > 0 ? 'hidden' : '',
                  display: selectedRows.length > 0 ? '' : 'none'
                }}
              >
                <EnhancedTableToolbar
                  numSelected={selectedRows.length}
                  rows={rows}
                  classes={classes}
                  selected={selectedRows}
                  firebase={firebase}
                />
              </div>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  classes={{ stickyHeader: classes.tableStickyHeader }}
                >
                  <EnhancedTableHead
                    rowsNew={newFamilies}
                    rowsActive={activeFamilies}
                    rows={tableData}
                    classes={classes}
                    numSelected={selectedRows?.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleGlobalCheckBox}
                    onRequestSort={handleRequestSort}
                    rowCount={tableData?.length}
                    searchPlaceholderText={'Search by surname or email'}
                    schoolDropDown={schoolDropDownList}
                    prefillFamilyData={prefillFamilyData}
                    selected={selectedRows}
                    searchChangeHandler={searchChangeHandler}
                    schoolChangeHandler={schoolChangeHandler}
                    schoolChangeHandlerFamilyFree={
                      schoolChangeHandlerFamilyFree
                    }
                    schoolChangeHandlerFamilyPaid={
                      schoolChangeHandlerFamilyPaid
                    }
                    yearChangeHandlerFree={yearChangeHandler}
                    statusChangeHandlerFree={statusChangeHandler}
                    firebase={firebase}
                  />
                  <TableBody>
                    {tableData?.length > 0 ? (
                      tableData?.map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            style={{ height: '4.5rem' }}
                            hover
                            onClick={(event) =>
                              handleRowCheckBox(event, row.id)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            className={clsx({
                              [classes.selectedRow]: isItemSelected
                            })}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) =>
                                  handleRowCheckBox(event, row.id)
                                }
                                className={classes.checkbox2}
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                            <TableCell
                              id={labelId}
                              // component="th"
                              // scope="row"
                              padding="none"
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '18%' }}
                            >
                              <span style={{ marginLeft: '15px' }}>
                                {row.lastName}
                              </span>
                            </TableCell>
                            <TableCell
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '19%' }}
                            >
                              <div>{row.firstName}</div>
                            </TableCell>

                            <TableCell
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '20%' }}
                            >
                              {filterSchoolNameList(row)}
                            </TableCell>
                            <TableCell
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '20%' }}
                            >
                              {filterYearsDisplayData(row?.studentReferences)}
                            </TableCell>

                            <TableCell
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '11%' }}
                            >
                              <span
                                style={{
                                  padding: '6px 5px',
                                  borderRadius: '3px',
                                  backgroundColor:
                                    statusColors[`${row?.status}`],
                                  marginBottom: '5px'
                                }}
                              >
                                {row?.status}
                              </span>
                            </TableCell>

                            <TableCell
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '11%' }}
                            >
                              <div>
                                {row?.currentStatusDate
                                  ? moment(row.currentStatusDate)
                                      .tz('Australia/Sydney')
                                      .format('DD MMM YY')
                                  : null}
                              </div>
                            </TableCell>
                            <TableCell>
                              {row?.createdByApiSync ? (
                                <img
                                  src={api_icon}
                                  style={{
                                    paddingRight: '1rem',
                                    float: 'right'
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow className="MuiTableRow-root makeStyles-tableRow-1 MuiTableRow-hover">
                        <TableCell
                          className="MuiTableCell-root MuiTableCell-body"
                          colSpan={PENDING_MEMBER_COLUMNS?.length + 1}
                        >
                          No Records Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={tableRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
            </div>
          </Paper>
        </div>
      </MuiPickersUtilsProvider>
    </Dashboard>
  );
}

export default withFirebase(MembersListPending);
