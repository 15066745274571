import { makeStyles } from '@material-ui/core';
import { colors } from 'src/utils/constant';

export const schoolGroupStyles = makeStyles(() => ({
  hoverTag: {
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
      color: colors.primary
    }
  },
  freeStatus: {
    fontSize: 16,
    backgroundColor: '#fcf5f5',
    color: '#d6575c',
    padding: '4px 8.5px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.86,
    letterSpacing: '0.97px'
  },
  paidStatus: {
    fontSize: 16,
    backgroundColor: '#e8f5e9',
    color: '#348f37',
    padding: '4px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.86,
    letterSpacing: '0.97px'
  },
  tableRow: {
    height: 72,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: `rgba(244, 245, 247, 0.51) !important`,
      "& [class^='makeStyles-hoverTag-']": {
        color: colors.primary
      }
    }
  },
  dataTableCell: {
    color: colors.primaryDark,
    padding: '16px 10px',
    '&:first-child': {
      paddingLeft: 24
    }
  },
  modalBox: {
    width: 450
    // height: 170,
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    width: 523,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  modalContent: {
    minHeight: 75,
    paddingTop: 12
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark
  },
  boldFont: { fontWeight: 'bold' },
  inputControlBox: {
    '& .MuiSelect-outlined:hover, & .MuiSelect-outlined:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  modalActions: {
    marginTop: '2%'
  }
}));
