const API_GET_ALL_FREE_SCHOOLS = '/freeSchools';
const API_GET_MEMBERS = '/families/free/members';
const API_IS_GROUP_EXISTS = '/group/isExist';
const API_CREATE_GROUP = '/group/create';
const API_GET_SINGLE_GROUP = '/group';
const API_UPDATE_GROUP = (id) => `/group/${id}/update`;
const API_DELETE_GROUP = (id) => `/group/${id}/delete`;
const API_CHILD_UPDATE_GROUP = '/child/updateGroup';

export {
  API_CREATE_GROUP,
  API_GET_ALL_FREE_SCHOOLS,
  API_GET_MEMBERS,
  API_IS_GROUP_EXISTS,
  API_GET_SINGLE_GROUP,
  API_UPDATE_GROUP,
  API_DELETE_GROUP,
  API_CHILD_UPDATE_GROUP
};
