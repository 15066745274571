import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';

// Material-UI Components
import {
  Button,
  Typography,
  CircularProgress,
  Box,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Select,
  Grid,
  MenuItem,
  Modal as CustomModal
} from '@material-ui/core';

// Components
import Modal from 'src/components/Modal';

// Redux-Actions
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';

// Assets
import { Loop } from '@material-ui/icons';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import { UserMinusIcon } from 'src/assets/Icons';
import CalendarIcon from 'src/assets/Icons_calendar.svg';

// Utils
import { counties, timeZones, routes } from 'src/utils/constant';
import { withFirebase } from 'src/utils/firebase';
import { generatePatternPassword, getInitials } from 'src/utils/functions';
import { updateMemberStyles } from 'src/styles/membersStyle/updateMemberStyles';
import { deleteMember, isMemberExist } from 'src/services/members';
import { MESSAGE_TYPES } from 'src/constants/common';

function UpdateMember({
  submitForm,
  isEdit = false,
  handlePause,
  handleResume,
  firebase,
  accountFlag,
  handleCancelDisable,
  setHandleCancelClick,
  handleCancelClick
}) {
  const classes = updateMemberStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.app.isLoading);
  const prefillFamilyData = useSelector((state) => state.family.currentFamily);

  const [initialValues, setInitialValue] = useState({
    planType: prefillFamilyData?.planType || '',
    firstName: prefillFamilyData?.firstName || '',
    password: '',
    country: prefillFamilyData?.country || '',
    lastName: prefillFamilyData?.lastName || '',
    timezone: prefillFamilyData?.timezone || '',
    userName: prefillFamilyData?.userName || '',
    phoneNo: prefillFamilyData?.phoneNo || '',
    // subscription data
    freeHost:
      prefillFamilyData.planType === 'Free'
        ? 'true'
        : prefillFamilyData.freeHost || 'false',
    schools: (prefillFamilyData.schools &&
      prefillFamilyData.schools.length &&
      prefillFamilyData.schools) || [
      { schoolName: '', year: '', color: '', years: [] }
    ],
    // parent/child data
    childs: prefillFamilyData.childs.length ? prefillFamilyData.childs : []
  });
  const [isPauseAccountModal, setPauseAccountModal] = useState(false);
  const [formKey, setFormKey] = useState(Math.random());
  const [isResumeAccountModal, setResumeAccountModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isStudentIsLinkedWithMember, setIsStudentLinkedWithMember] =
    useState(false);
  const [resumeText, setResumeText] = useState('');
  const [pauseText, setPauseText] = useState('');
  const [deleteText, setDeleteText] = useState('');
  const [isSave, setSave] = useState(false);
  const [isOpenConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [isRotate, setRotate] = useState(false);
  const [open, setOpen] = React.useState(false);
  // Base URL
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // Existing Email check
  const [isPrimaryEmailExistCheck, setIsPrimaryEmailExistCheck] =
    useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onTogglePasswordFieldType = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
    } else {
      setPasswordFieldType('password');
    }
  };
  // SCHEMA VALIDATOR FOR CREATE NEW PASSWORD FORM
  const validationSchema = yup.object().shape({
    lastName: yup.string().trim().required('Surname is required'),
    firstName: yup.string().trim().required('First name is required'),
    primaryEmail: yup
      .string()
      .trim()
      .email()
      .required('Valid email is required'),
    userName:
      prefillFamilyData.planType !== 'Free'
        ? yup
            .string()
            .trim()
            .required('User Name is required')
            .matches(/^[a-zA-Z0-9]+$/, 'Invalid User Name')
        : null,
    password: isEdit
      ? yup
          .string()
          .trim()
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
            'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.',
            { excludeEmptyString: true }
          )
      : prefillFamilyData.planType !== 'Free'
        ? yup
            .string()
            .trim()
            .required('Password is required')
            .matches(
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
              'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.'
            )
        : null,
    planType: yup.string().trim().required('Plan Type is required'),
    country: yup.string().trim().required('Country is required'),
    timezone: yup.string().trim().required('Timezone is required')
  });

  useEffect(() => {
    if (handleCancelClick) {
      setInitialValue({
        planType: prefillFamilyData.planType || '',
        lastName: prefillFamilyData.lastName,
        userName: prefillFamilyData.userName,
        country: prefillFamilyData.country,
        timezone: prefillFamilyData.timezone,
        phoneNo: prefillFamilyData?.phoneNo || '',
        primaryEmail: prefillFamilyData?.primaryEmail
      });
      setFormKey(Math.random());
      setHandleCancelClick(false);
      handleCancelDisable(false);
    }
  }, [handleCancelClick, setHandleCancelClick]);
  useEffect(() => {
    setInitialValue({
      planType: prefillFamilyData?.planType || '',
      firstName: prefillFamilyData?.firstName || '',
      country: prefillFamilyData?.country || '',
      lastName: prefillFamilyData?.lastName || '',
      timezone: prefillFamilyData?.timezone || '',
      userName: prefillFamilyData?.userName || '',
      phoneNo: prefillFamilyData?.phoneNo || '',
      primaryEmail: prefillFamilyData?.primaryEmail,
      // subscription data
      freeHost:
        prefillFamilyData.planType === 'Free'
          ? 'true'
          : prefillFamilyData.freeHost || 'false',
      schools: (prefillFamilyData.schools &&
        prefillFamilyData.schools.length &&
        prefillFamilyData.schools) || [
        { schoolName: '', year: '', color: '', years: [] }
      ],
      childs: prefillFamilyData.childs.length ? prefillFamilyData.childs : []
    });
  }, [prefillFamilyData]);

  const deleteUserHandle = async () => {
    setDeleteText('');
    dispatch(loaderStart());
    deleteMember(prefillFamilyData.id)
      .then(() => {
        dispatch(loaderStop());
        setOpenDeleteModal(false);
        setOpenConfirmDeleteModal(true);
      })
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(
          addMessage(
            'Member was not deleted. Please try again',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });
  };

  const generatePassword = (setFieldValue) => {
    setRotate(true);
    setFieldValue('password', generatePatternPassword(8));
    dispatch(addMessage('Password generated and set'));
    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };

  const checkUserExistOrNot = (userName, setErrors) => {
    const email = `${userName}@mywhanau-user.com`;
    firebase
      .isUserExists(email)
      .then((results) => {
        if (results.size > 0) {
          setErrors({
            userName: `Username is already in use by another account.`
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className={classes.headerContainer}>
        {
          <div className={classes.pauseContainer}>
            {prefillFamilyData && prefillFamilyData.planStatus === '' && (
              <Button
                onClick={() => setPauseAccountModal(true)}
                startIcon={<PauseCircleOutlineIcon />}
              >
                <div className={classes.pauseText}>Pause Account</div>
              </Button>
            )}
            {prefillFamilyData && prefillFamilyData.planStatus === 'Pause' && (
              <Button
                onClick={() => {
                  setPauseAccountModal(false);
                  setPauseText('');
                  setResumeAccountModal(true);
                }}
                startIcon={
                  <PauseCircleOutlineIcon style={{ color: '#bd0000' }} />
                }
              >
                <div className={classes.pauseText} style={{ color: '#bd0000' }}>
                  Account Paused
                </div>
              </Button>
            )}
            <Button
              onClick={() => {
                if (prefillFamilyData.childs.length > 0) {
                  setIsStudentLinkedWithMember(true);
                } else {
                  setOpenDeleteModal(true);
                }
              }}
              startIcon={<UserMinusIcon />}
            >
              <div className={classes.pauseText}>Delete Member</div>
            </Button>
            <Button onClick={handleClickOpen}>
              <img
                src={CalendarIcon}
                width={20}
                height={20}
                style={{ marginRight: '10px' }}
                alt="calendar"
              />
              <div className={classes.pauseText}>Native Cal Link</div>
            </Button>
          </div>
        }
      </div>
      <div className={classes.contentWrapper}>
        <Formik
          enableReinitialize={true}
          key={formKey}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors }) => {
            const email = `${values.userName}@mywhanau-user.com`;
            if (
              !prefillFamilyData.userName ||
              prefillFamilyData.userName !== values.userName
            ) {
              if (email && values.userName) {
                isMemberExist(email)
                  .then((isExist) => {
                    if (isExist) {
                      setErrors({
                        userName: `${values.userName} username is already in use by another account.`
                      });
                    } else {
                      submitForm(values);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                submitForm(values);
              }
            } else {
              if (accountFlag && isSave) {
                submitForm(values, true);
              } else {
                submitForm(values);
                // eslint-disable-next-line
                handleCancelDisable?.(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            setErrors
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <div style={{ padding: '1rem 2rem' }}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: '1rem',
                      marginBottom: '1rem'
                    }}
                  >
                    <FormControl component="fieldset">
                      <TextField
                        style={{ width: 350 }}
                        variant="outlined"
                        label="First name"
                        name={`firstName`}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue(
                            `initials`,
                            getInitials(`${e.target.value} ${values.lastName}`)
                          );
                          accountFlag && isEdit && handleCancelDisable(true);
                        }}
                        value={values.firstName}
                        className={classes.inputControlBox}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                      />
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel id="country-select-outlined-label">
                        Country
                      </InputLabel>
                      <Select
                        labelId="country-select-outlined-label"
                        id="country-select-outlined"
                        value={values.country}
                        onChange={(e) => {
                          handleChange(e);
                          accountFlag && isEdit && handleCancelDisable(true);
                        }}
                        className={classes.inputControlBox}
                        style={{ width: 350 }}
                        onBlur={handleBlur}
                        error={Boolean(touched.country && errors.country)}
                        name="country"
                        label="Country"
                      >
                        <MenuItem value="">
                          <em>Country</em>
                        </MenuItem>
                        {counties.map(function (object) {
                          return (
                            <MenuItem key={object.code} value={object.name}>
                              {object.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {touched.country && errors.country ? (
                        <span style={{ height: 20 }} className={classes.error}>
                          {errors.country}
                        </span>
                      ) : (
                        ''
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: '1rem',
                      marginBottom: '1rem'
                    }}
                  >
                    <FormControl component="fieldset">
                      <TextField
                        label="Surname"
                        variant="outlined"
                        name="lastName"
                        style={{ width: 350 }}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue(
                            `initials`,
                            getInitials(`${values.firstName} ${e.target.value}`)
                          );
                          accountFlag && isEdit && handleCancelDisable(true);
                        }}
                        error={Boolean(touched.lastName && errors.lastName)}
                        value={values.lastName}
                        // className={classes.inputControlBox}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel id="timezone-select-outlined-label">
                        Timezone
                      </InputLabel>
                      <Select
                        labelId="timezone-select-outlined-label"
                        id="timezone-select-outlined"
                        value={values.timezone}
                        onChange={(e) => {
                          handleChange(e);
                          accountFlag && isEdit && handleCancelDisable(true);
                        }}
                        // className={classes.inputControlBox}
                        style={{ width: 350 }}
                        onBlur={handleBlur}
                        error={Boolean(touched.timezone && errors.timezone)}
                        name="timezone"
                        label="Timezone"
                      >
                        <MenuItem value="">
                          <em>TimeZone</em>
                        </MenuItem>
                        {timeZones.map(function (object) {
                          return (
                            <MenuItem key={object.value} value={object.value}>
                              {object.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {touched.timezone && errors.timezone ? (
                        <span style={{ height: 20 }} className={classes.error}>
                          {errors.timezone}
                        </span>
                      ) : (
                        ''
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: '1rem',
                      marginBottom: '1rem'
                    }}
                  >
                    <FormControl component="fieldset">
                      <TextField
                        label="Email Address"
                        variant="outlined"
                        style={{ width: 350 }}
                        // className={classes.inputControlBox}
                        disabled={prefillFamilyData.createdByApiSync}
                        InputProps={{
                          disabled: prefillFamilyData.createdByApiSync
                        }}
                        name="primaryEmail"
                        onBlur={async (event) => {
                          if (
                            event.target.value !==
                              prefillFamilyData.primaryEmail &&
                            event.target.value
                          ) {
                            const checkEmailLength = event.target.value.length;

                            if (event.target.value) {
                              const isEmailExist = await isMemberExist(
                                event.target.value
                              );
                              if (checkEmailLength > 0 && isEmailExist) {
                                setIsPrimaryEmailExistCheck(true);
                              } else {
                                setIsPrimaryEmailExistCheck(false);
                              }                              
                            }
                          }
                        }}
                        onChange={(e) => {
                          setFieldValue('primaryEmail', e.target.value.trim());
                          accountFlag && isEdit && handleCancelDisable(true);
                        }}
                        value={values.primaryEmail || ''}
                        error={
                          isPrimaryEmailExistCheck ||
                          Boolean(
                            touched.primaryEmail &&
                              errors.primaryEmail &&
                              'Valid email is required'
                          )
                        }
                        helperText={
                          (touched.primaryEmail &&
                            errors.primaryEmail &&
                            'Valid email is required') ||
                          (isPrimaryEmailExistCheck &&
                            values?.primaryEmail?.length > 0 &&
                            `Email already exists`)
                        }
                      />
                    </FormControl>
                    <FormControl component="fieldset">
                      <TextField
                        label="Login username"
                        variant="outlined"
                        // className={classes.inputControlBox}
                        name="userName"
                        style={{ width: 350 }}
                        onBlur={(e) => {
                          handleBlur(e);
                          if (
                            (!prefillFamilyData.userName ||
                              prefillFamilyData.userName !== values.userName) &&
                            e.target.value
                          ) {
                            checkUserExistOrNot(e.target.value, setErrors);
                          }
                        }}
                        onChange={(e) => {
                          setFieldValue(
                            'userName',
                            e.target.value
                              ? e.target.value.replace(/\s/g, '')
                              : ''
                          );
                          accountFlag && isEdit && handleCancelDisable(true);
                        }}
                        value={values.userName}
                        // error={Boolean(touched.userName && errors.userName)}
                        // helperText={touched.userName && errors.userName}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: '1rem',
                      marginBottom: '1rem'
                    }}
                  >
                    <FormControl component="fieldset">
                      <TextField
                        label="Phone Number"
                        variant="outlined"
                        name="phoneNo"
                        value={values.phoneNo}
                        disableUnderline
                        onBlur={handleBlur}
                        onChange={(e) => {
                          accountFlag && isEdit && handleCancelDisable(true);
                          setFieldValue('phoneNo', e.target.value);
                        }}
                        style={{ width: 350 }}
                      />
                    </FormControl>
                    <FormControl component="fieldset">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          label="Password"
                          variant="outlined"
                          // className={classes.inputControlBox}
                          name="password"
                          autoComplete="off"
                          onBlur={handleBlur}
                          style={{ width: 350 }}
                          onChange={(e) => {
                            handleChange(e);
                            accountFlag && isEdit && handleCancelDisable(true);
                          }}
                          error={Boolean(touched.password && errors.password)}
                          value={values.password}
                          helperText={touched.password && errors.password}
                          type={passwordFieldType}
                          InputProps={{
                            autoComplete: 'off',
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => onTogglePasswordFieldType()}
                                  tabIndex="-1"
                                >
                                  <img
                                    alt="Password Requirement"
                                    src={
                                      passwordFieldType === 'text'
                                        ? '/static/images/password-hide.png'
                                        : '/static/images/password-see.png'
                                    }
                                    width="22"
                                    height="22"
                                  />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        <span className={classes.generate}>
                          Generate
                          <Loop
                            height={24}
                            width={24}
                            style={{ cursor: 'pointer' }}
                            onClick={() => generatePassword(setFieldValue)}
                            className={isRotate && classes.closeIconRotate}
                          />
                        </span>
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSave(true);
                        handleSubmit();
                        // window.scroll(0, 0);
                      }}
                      className={classes.buttonText}
                    >
                      Save
                    </Button>
                  </Grid>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

      {/* Modals */}
      {isPauseAccountModal && (
        <Modal
          isOpen={isPauseAccountModal}
          onClose={() => {
            setPauseAccountModal(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Account Pause?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                Pausing this account will remove login access for this member
                until account pause is disabled. You can continue to manage the
                account from within the admin.
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “PAUSE ACCOUNT” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={pauseText}
                  onChange={(e) => setPauseText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  handlePause && handlePause();
                  setPauseAccountModal(false);
                }}
                color="primary"
                variant="contained"
                size="large"
                disabled={pauseText !== 'PAUSE ACCOUNT'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Pause'}
              </Button>
              <Button
                onClick={() => {
                  setPauseAccountModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isResumeAccountModal && (
        <Modal
          isOpen={isResumeAccountModal}
          onClose={() => {
            setResumeAccountModal(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Account Resume?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                Resume account will activate access for all users associated
                with this member. You can continue to manage the account from
                within the admin.
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “RESUME ACCOUNT” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={resumeText}
                  onChange={(e) => setResumeText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  handleResume && handleResume();
                  setResumeText('');
                  setResumeAccountModal(false);
                }}
                color="primary"
                variant="contained"
                size="large"
                disabled={resumeText !== 'RESUME ACCOUNT'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Resume'}
              </Button>
              <Button
                onClick={() => {
                  setResumeAccountModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenDeleteModal && (
        <Modal
          isOpen={isOpenDeleteModal}
          onClose={() => {
            setOpenDeleteModal(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Delete Member Account?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                <span style={{ fontWeight: 'bold' }}>
                  Warning: This action cannot be reversed.
                </span>
                <br />
                The following data will be permanently removed from the
                database.
              </Typography>
            </div>
            <div className={classes.modalContent}>
              <Typography className={classes.modalSubTitle}>
                - Member account (admin)
                <br />
                - Member account and login credentials (app)
                <br />
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “DELETE ACCOUNT” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={deleteText}
                  onChange={(e) => setDeleteText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={deleteUserHandle}
                color="primary"
                variant="contained"
                size="large"
                disabled={deleteText !== 'DELETE ACCOUNT'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Delete'}
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {isStudentIsLinkedWithMember && (
        <Modal
          isOpen={isStudentIsLinkedWithMember}
          onClose={() => {
            setIsStudentLinkedWithMember(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Cannot Delete Account
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                <br />
                There are Non-API child profiles linked to this member account.
                Remove the child profiles first and try again.
              </Typography>
            </div>
            <br />
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  setIsStudentLinkedWithMember(false);
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {isOpenConfirmDeleteModal && (
        <Modal
          isOpen={isOpenConfirmDeleteModal}
          onClose={() => setOpenConfirmDeleteModal(false)}
        >
          <div className={classes.modalBox}>
            <Typography
              className={classes.modalTitle}
              style={{ marginBottom: 18 }}
            >
              Member Account Deleted
            </Typography>
            <Typography
              className={classes.modalSubTitle}
              style={{ marginBottom: 26 }}
            >
              All data associated with {prefillFamilyData.userName} has now been
              removed from the database.
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  setOpenConfirmDeleteModal(false);
                  history.push(routes.membersActive);
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
              >
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {open && (
        <CustomModal open={open} onClose={handleClose}>
          <Box className={classes.customModalBox}>
            <Typography className={classes.modalTitle}>
              Member Calendar Link
            </Typography>
            <div
              className={classes.modalSubHeading}
              style={{ marginTop: '20px' }}
            >
              <Typography className={classes.modalSubTitle}>
                Native calendar subscription link
              </Typography>
            </div>
            <div
              className={classes.modalLinkContainer}
              style={{ marginTop: '20px' }}
            >
              <Typography className={classes.linkTextStyle}>
                {`${BASE_URL}/${routes.calendarSubscription}/${prefillFamilyData?.id}/calendar.ics`}
              </Typography>
            </div>
          </Box>
        </CustomModal>
      )}
    </div>
  );
}

export default withFirebase(UpdateMember);
