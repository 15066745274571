export const API_ROUTES = {
  // AUTH
  GENERATE_TOKEN: '/admin/generate-token',
  SEND_VERIFICATION_CODE: '/api/sendVerifyCodeMail',
  VERIFY_CODE: '/api/adminVerifyCode',

  // SCHOOLS
  GET_SCHOOL_ID: '/admin/school/:id',
  GET_SCHOOLS: '/admin/allSchools',
  GET_SCHOOL_BY_URL: '/admin/school-url',
  GET_SCHOOL_BY_FILTER: '/admin/schools', // LOOK_LIKE: /admin/schools?pageNo=1&pageSize=20&searchText=
  GET_FREE_SCHOOLS: '/admin/freeSchools',
  CREATE_SCHOOL: '/admin/school/create',
  DELETE_SCHOOL: '/admin/school/:id/delete',
  UPDATE_SCHOOL_OR_CREATE_SCHOOL_FORM: '/admin/school/:id/update',
  DELETE_SCHOOL_FORM: '/admin/school-form/:id/delete',
  CREATE_SCHOOL_LINK: '/admin/school-link/create',
  UPDATE_SCHOOL_LINK: '/admin/school-link/update',
  DELETE_SCHOOL_LINK: '/admin/school-link/:schoolId/:linkId/delete',

  // GROUPS
  GET_GROUP_BY_FILTER: '/admin/groups', // Pagination APIs... /admin/groups?searchText=Purple - new name
  GET_GROUP_BY_ID: '/admin/group/:id',
  GET_GROUP_BY_SCHOOL_ID: 'admin/groups/school/:schoolId',
  GROUP_EXIST: '/admin/group/isExist',
  GET_MEMBERS: '/admin/families/free/members',
  CREATE_GROUP: '/admin/group/create',
  UPDATE_GROUP: '/admin/group/:id/update',
  DELETE_GROUP: '/admin/group/:id/delete',

  // CONSOLE
  UPDATE_MULTIPLE_GROUP_AND_ENTRIES: '/admin/child/updateGroup',
  UPDATE_MAINTENANCE_MODE: 'admin/maintenance/mode/update', // MAINTENACE -> MAINTENANCE
  GET_MAINTENANCE_MODE: 'admin/maintenance/mode',
  FETCH_LANGUAGE_LIST: 'admin/get-languages',
  GENERATE_STUDENT_REPORT: 'admin/student/report',

  // ENTRIES
  MANAGE_SCHOOL_ENTRIES: 'api/manageSchoolEntries',
  
  // MEMBERS
  GET_MEMBERS_BY_FILTER: '/admin/members/:accountStatus', // Pagination APIs...
  GET_MEMBER: '/admin/member/:id',
  UPDATE_MEMBER: '/admin/member/:id/update',
  DELETE_MEMBER: '/admin/member/:id/delete',
  CREATE_MEMBER: '/admin/member/create',
  IS_MEMBER_EXIST: '/admin/member/isEmailExist',
  SEARCH_BY_PRIMARY: '/admin/member/email/:primaryEmail',
  APPROVE_MULTIPLE_MEMBERS: '/admin/member/approveMembersById',
  DELETE_MULTIPLE_MEMBERS: '/admin/member/deleteNonActiveMembers',
  LINK_UNLINK_MEMBER: '/admin/member/link-unlink',

  // STUDENT
  CREATE_STUDENT: '/admin/student/create',
  UPDATE_STUDENT: '/admin/student/:id/update',
  GET_STUDENT: '/admin/student/:id',
  DELETE_STUDENT: '/admin/student/:id/delete',
  GET_STUDENTS_BY_SCHOOL: '/admin/students/:accountStatus', // /admin/students/:accountStatus?schoolId=RsLT3frxKWawHStIhwPs
  GET_STUDENTS_MEMBERS_BY_SCHOOL: '/admin/students-members' // /admin/students/:accountStatus?schoolId=RsLT3frxKWawHStIhwPs
};
