import React, { useState, Fragment, useEffect, useCallback } from 'react';
import {
  makeStyles,
  Typography,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Chip,
  RadioGroup,
  Radio,
  Switch,
  ThemeProvider
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import clsx from 'clsx';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import { EditorState } from 'draft-js';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import Modal from 'src/components/Modal';
import {
  colors,
  labels,
  groupEntryRadio,
  taskLabels,
  muiTimePickerTheme,
  year as DefaultYear
} from 'src/utils/constant';
import { FamiliesIcon, CornerDownRight } from 'src/assets/Icons';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import { Editor } from 'react-draft-wysiwyg';
import CustomDateModal from './components/CustomeDateModal';
import { useSelector, useDispatch } from 'react-redux';
import { withFirebase } from 'src/utils/firebase';
import {
  addMessage,
  loaderStop,
  loaderStart,
  setPreserveEntryData
} from 'src/redux/actions/appActions';
import {
  generateFileHash,
  toGetContentFromHTML,
  toGetHTMLContent
} from 'src/utils/functions';
import RightDrawer from 'src/components/RightDrawer';
import { EditPencil, Calendar, LocationIcon } from 'src/assets';
import { getSchoolByID, getSchools } from 'src/services/schools';
import { getGroupBySchoolId } from 'src/services/groups';
import EditorComponent from 'src/components/EditorComponent';
import { MESSAGE_TYPES } from 'src/constants/common';
import { manageSchoolEntries } from 'src/services/entries';

const useStyles = makeStyles((theme) => ({
  updatedLabel: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 0.09
  },
  updatedDesc: {
    fontSize: 16,
    height: 19,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  chipLabel: {
    height: 18,
    fontfamily: 'Roboto',
    fontweight: 500,
    fontstretch: 'normal',
    fontstyle: 'normal',
    lineheight: 'normal',
    letterspacing: 'normal',
    color: colors.white,
    marginTop: -5
  },
  chipRoot: {
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    height: 20,
    position: 'absolute',
    top: -8,
    left: 10,
    opacity: 1,
    zIndex: 1
  },
  drawerLeftContent: {
    width: `calc(70%)`,
    height: `calc(100vh - 95px)`,
    opacity: 1,
    backgroundColor: colors.white,
    float: 'left',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  drawerRightContent: {
    width: '30%',
    height: `calc(100vh - 95px)`,
    backgroundColor: colors.tertiary,
    float: 'right'
  },
  siderEdit: {
    height: 40,
    marginTop: 19
  },
  actionButton: {
    float: 'right',
    marginRight: '1%'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 20
  },
  headerTitleContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center'
  },
  publishButton: { marginLeft: 10 },
  familyIconStyle: { height: '40px', width: '40px' },
  titleText: { marginLeft: 10, fontWeight: '600', fontSize: 24 },
  titleAccordionText: {
    marginLeft: 10,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    opacity: 1
  },
  whiteText: { color: colors.white },
  darkPrimaryText: {
    color: colors.primaryDark,
    fontSize: 23,
    fontWeight: '500',
    lineHeight: 1.74
  },
  initialText: {
    color: colors.primaryLightGrey,
    fontSize: 13,
    marginTop: 5,
    marginLeft: 5
  },
  leftContentMargin: { marginTop: 10 },
  inputControlBox: {
    backgroundColor: colors.white,
    marginTop: 24
  },
  formLabelControl: {
    width: 250,
    backgroundColor: colors.white,
    marginTop: 24,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '7.5px 30px 7.5px 14px',
      marginTop: 7
    }
  },
  formLabelControlTime: {
    width: 45,
    backgroundColor: colors.white,
    marginTop: 16,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 7.5,
      paddingBottom: 7.5,
      paddingLeft: 4,
      paddingRight: 4,
      marginTop: 0,
      textAlign: 'center'
    }
  },
  formDayLabelControl: {
    // width: '30%',
    // marginTop:2,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '2.5px 14px',
      margin: '4px 0'
    }
  },
  formLabelControlBordered: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: 3,
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10
    }
  },
  formDateControlFromBordered: {
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: 3,
    padding: 0,
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-input': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10
    }
  },
  editorClassName: {
    padding: 10,
    backgroundColor: colors.white
  },
  wrapperClassName: {
    width: 570,
    borderTop: `1px solid ${colors.primaryLightGrey}`,
    borderLeft: `1px solid ${colors.primaryLightGrey}`,
    borderRight: `1px solid ${colors.primaryLightGrey}`,
    borderTopRadius: '5px',
    borderLeftRadius: '5px',
    borderRightRadius: '5px',
    marginTop: '20px'
  },
  wrapperClassNameWithBottomBorder: {
    width: 570,
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: '5px',
    marginTop: '20px'
  },
  wrapperSecondEditorClassName: {
    width: 570,
    // borderTop: `1px solid ${colors.primaryLightGrey}`,
    borderLeft: `1px solid ${colors.primaryLightGrey}`,
    borderRight: `1px solid ${colors.primaryLightGrey}`,
    borderBottom: `1px solid ${colors.primaryLightGrey}`,
    borderTopRadius: '5px',
    borderLeftRadius: '5px',
    borderRightRadius: '5px'
    // marginTop: '20px'
  },
  toolbarClassName: {
    border: 'none',
    marginBottom: 0,
    '& .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-target-option': {
      display: 'none'
    }
  },
  linkedButton: {
    height: 40,
    width: 191,
    borderRadius: 3,
    backgroundColor: colors.secondaryGreyLight,
    marginLeft: 15,
    marginBottom: 15,
    color: colors.primaryDarkGrey,
    fontSize: 16,
    boxShadow: 'none'
  },
  includeText: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey
  },
  iconTrashStyle: {
    fontSize: 22,
    verticalAlign: 'middle',
    color: colors.primaryLightGrey
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modalBox: {
    width: 480
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  modalActions: {
    marginTop: '10%'
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  chipClass: {
    position: 'relative',
    top: 12,
    zIndex: 1,
    left: 30
  },
  groupRadioClass: {
    flexDirection: 'row',
    '& .MuiFormControlLabel-root .MuiIconButton-root': {
      padding: '9px 5px'
    }
  },
  radio: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  checkbox: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  switchBase: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    },
    '&$checked + $track': {
      backgroundColor: colors.primary
    }
  },
  checked: {
    fontWeight: 900
  },
  menuItemClass: {
    // backgroundColor: '#ff0000',
    padding: '2px 16px'
  },
  descriptionContainer: {
    width: 572,
    borderLeft: `1px solid ${colors.primaryLightGrey}`,
    borderRight: `1px solid ${colors.primaryLightGrey}`
  },
  descriptionMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    backgroundColor: colors.white
  },
  descriptionImageContainer: {
    height: 18,
    width: 18,
    opacity: 0.4
  },
  descriptionTextContainer: {
    margin: 0,
    fontSize: 14,
    color: colors.primaryLightGrey,
    padding: 10
  },
  orDivider: {
    marginTop: 24,
    borderBottom: `1px solid ${colors.primaryLightGrey}`,
    position: 'relative',
    '& span': {
      position: 'absolute',
      transform: 'translateY(-50%)',
      padding: 5,
      background: colors.tertiary,
      left: 0,
      right: 0,
      maxWidth: 50,
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center'
    }
  }
}));

const entryObject = {
  entries: [
    {
      id: generateFileHash(20),
      isLowPriority: false,
      isPrimary: true,
      linkedArray: [],
      label: '',
      title: '',
      location: '',
      description: '',
      expand: true,
      checkDescription: false,
      groupType: '',
      school: '',
      years: [],
      groups: [],
      for: [],
      categoryInfo: [],
      forDropDownData: [],
      yearDropDownData: [],
      categoryDropDownData: [],
      primary_description: {
        description: '',
        from: {
          date: moment().format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        }
      },
      assignToChild: false,
      assign: [],
      from: {
        date: moment().format('MMM DD,YYYY'),
        time: moment().format('h:mma')
      },
      to: {
        date: moment().format('MMM DD,YYYY'),
        time: moment().format('h:mma')
      },
      allDay: true,
      actions: {
        value: '',
        repeat: [],
        notification: [
          {
            days: -1,
            date: moment().subtract(1, 'days').format('MMM DD,YYYY'),
            time: '7:30pm'
          },
          { days: '', date: moment().format('MMM DD,YYYY'), time: '7:30pm' }
        ],
        deadline: [],
        reminder: [],
        isCancelled: false,
        isPostponed: false,
        isChanged: false,
        isComplete: false
      },
      newFromTimeFormate: { hh: '', mm: '', ap: '' },
      newToTimeFormate: { hh: '', mm: '', ap: '' }
    }
  ]
};

function CreateGroupCalenderEntry({
  firebase,
  onClose,
  isOpen,
  type
  // hasPreserveEntryData,
  // setPreserveEntryData
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasPreserveEntryData = useSelector(
    (state) => state.app.hasPreserveEntryData
  );
  const currentUser = useSelector((state) => state.auth.user);
  const [initialEntry, setInitialEntry] = useState({
    entries: [
      {
        ...entryObject.entries[0],
        primaryID: '',
        actionParent: { parentID: '', type: '' },
        groupType: type,
        agentName: currentUser.firstName,
        from: {
          date: moment().format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        },
        to: {
          date: moment().format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        }
      }
    ]
  });
  const [isOpenModal, setOpenModal] = useState({ open: false, value: [] });
  // const [childOptions, setChildOptions] = useState(null);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenDeleteLinkedModal, setOpenDeleteLinkedModal] = useState({
    open: false,
    id: null
  });
  const [defaultNote, setDefaultNote] = useState(EditorState.createEmpty());
  const [customValue, setCustomValue] = useState({});
  const [schools, setSchools] = React.useState([]);
  const [years, setYears] = React.useState([]);
  const [category, setCategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [groups, setGroups] = React.useState([]);
  const [family, setFamily] = React.useState([]);
  const [groupFamily, setGroupFamily] = React.useState([]);
  const [allFamily, setAllFamily] = React.useState([]);
  const [familySurnameWise, setFamilySurnameWise] = React.useState([]);
  const [updatedEntry, setUpdatedEntry] = useState({});
  const isLoading = useSelector((state) => state.app.isLoading);
  const [paidFamiliesData, setPaidFamilies] = React.useState([]);

  useEffect(
    () => {
      if (hasPreserveEntryData) {
        let primaryEntryID = '';
        const updateInitialValueEntry = hasPreserveEntryData.entries.map(
          (entry, index) => {
            entry.id = generateFileHash(20);
            if (entry.isPrimary) {
              primaryEntryID = entry.id;
            } else {
              entry.primaryID = primaryEntryID;
            }
            if (entry.isPrimary) {
              setDefaultNote(
                entry.description
                  ? toGetContentFromHTML(entry.description)
                  : EditorState.createEmpty()
              );
            }
            // }
            if (index === 0) {
              return {
                ...entry,
                groupType: type,
                school: '',
                years: [],
                groups: [],
                for: [],
                forDropDownData: []
              };
            } else {
              return { ...entry, for: [], forDropDownData: [] };
            }
          }
        );

        setInitialEntry({
          entries: updateInitialValueEntry
        });
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      // if (!isOpen) {
      dispatch(setPreserveEntryData(updatedEntry));
      // }
    },
    // eslint-disable-next-line
    [isOpen, updatedEntry]
  );

  // Only get free school
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFreeSchools = useCallback(async () => {
    // PHASE2/CHANGES/SCHOOL

    dispatch(loaderStart());

    try {
      const result = await getSchools();
      const schools = [];
      result.forEach((school) => {
        schools.push({
          id: school.id,
          name: school.schoolName,
          years: getYearsData(school.freeCalendars),
          freeCalLength: school.freeCalendars.length,
          // groups: school.groups, FIXME:
          freeCalendars: school.freeCalendars,
          categories: school.categories
        });
      });
      setSchools(schools);
      dispatch(loaderStop());
    } catch (error) {
      console.error(error);
      dispatch(loaderStop());
      dispatch(
        addMessage(
          'Server connection issue. Please refresh',
          MESSAGE_TYPES.ERROR
        )
      );
    }
  }, []);

  // Only get Paid family
  const getFamilies = useCallback(() => {
    dispatch(loaderStart());
    firebase
      .getPaidFamilies()
      .then((querySnapshot) => {
        dispatch(loaderStop());
        const paidFamily = [];
        let familySchool = [];
        const years = [];
        querySnapshot.forEach(function (family) {
          paidFamily.push({ ...family.data(), familyId: family.id });
          familySchool = [...familySchool, ...family.data().childsSchools];
          if (family.data().childs && family.data().childs.length > 0) {
            family.data().childs.forEach((child) => {
              years.push({
                key: `Year ${child.year}`,
                value: child.year,
                selected: false
              });
            });
          }
        });
        setPaidFamilies(paidFamily);
        const uniYear = _.uniqBy(years, 'value');
        const sortYear = uniYear.sort(function (a, b) {
          return parseInt(a.value) - parseInt(b.value);
        });

        setYears([
          {
            key: `All Years`,
            value: 'all',
            selected: false
          },
          ...sortYear
        ]);
        familySchool = _.uniq(familySchool);
        const schoolsData = [];
        familySchool.forEach((id, index) => {
          // PHASE2/CHANGES/SCHOOL

          getSchoolByID(id)
            .then((result) => {
              if (result) {
                schoolsData.push({
                  id: id,
                  name: result.schoolName,
                  years: getYearsData(result.freeCalendars),
                  freeCalLength: result.freeCalendars.length,
                  groups: result.groups
                });
              }
              if (index === familySchool.length - 1) {
                const sortSchoolByName = _.sortBy(schoolsData, ['name']);
                setSchools(sortSchoolByName);
              }
            })
            .catch(function (error) {
              console.log('Error getting documents: ', error);
              dispatch(
                addMessage(
                  'Server connection issue. Please refresh',
                  MESSAGE_TYPES.ERROR
                )
              );
            });
        });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(
          addMessage(
            'Server connection issue. Please refresh',
            MESSAGE_TYPES.ERROR
          )
        );
      });
  }, []);

  useEffect(() => {
    if (type === 'Families' || type === 'Group') {
      getFamilies();
    } else {
      getFreeSchools();
    }
  }, [firebase, setSchools, dispatch]);

  const getYearsData = (data) => {
    const updatedYear =
      data.length &&
      data.map((year) => {
        return { key: `Year ${year}` };
      });
    return updatedYear && updatedYear.length ? updatedYear : [];
  };

  const getInitials = (array) => {
    const childArray =
      array && array.length && array.map((child) => child.initials);
    return array && array.length ? [...childArray].join(', ') : '';
  };

  const addLinkedEntry = (values) => {
    const newLinkedId = generateFileHash(20);
    const updateInitialValue = values.entries.map((data) => {
      if (data.isPrimary) {
        return {
          ...data,
          expand: false,
          groupType: values.entries[0].groupType,
          linkedArray: data.linkedArray
            ? [...data.linkedArray, newLinkedId]
            : [newLinkedId]
        };
      } else {
        delete data.linkedArray;
        return {
          ...data,
          expand: false,
          isPrimary: false,
          groupType: values.entries[0].groupType
        };
      }
    });
    delete entryObject.entries[0].linkedArray;

    let selectedForDropDownData = [];
    if (family.length) {
      const foundSelectedFamilyMap = _.map(family, (familyData) => {
        return familyData.selected ? familyData.familyId : null;
      });
      const foundSelectedFamilyFilter = _.filter(
        foundSelectedFamilyMap,
        (familyData) => familyData
      );

      const foundSelectedFamilyWithSurnameFilter = _.filter(
        family,
        (familyData) => foundSelectedFamilyFilter.includes(familyData.familyId)
      );
      if (
        foundSelectedFamilyWithSurnameFilter &&
        foundSelectedFamilyWithSurnameFilter.length
      ) {
        selectedForDropDownData = [
          family[0],
          ...foundSelectedFamilyWithSurnameFilter
        ];
      } else {
        selectedForDropDownData = family;
      }
    }

    setInitialEntry({
      entries: [
        ...updateInitialValue,
        {
          ...entryObject.entries[0],
          id: newLinkedId,
          isPrimary: false,
          agentName: currentUser.firstName,
          groupType: values.entries[0].groupType,
          groupMembers:
            values.entries[0].groupType === 'Free'
              ? values.entries[0].groupMembers
                ? values.entries[0].groupMembers
                : []
              : [],
          groups:
            values.entries[0].groupType === 'Free'
              ? values.entries[0].groups
                ? values.entries[0].groups
                : []
              : [],
          forDropDownData:
            values.entries[0].groupType === 'Families' ||
            values.entries[0].groupType === 'Group'
              ? selectedForDropDownData
              : [],
          for: values.entries[0].for,
          assignToChild: values.entries[0].assignToChild,
          yearDropDownData: values.entries[0].yearDropDownData,
          categoryDropDownData: values.entries[0].categoryDropDownData,
          years: values.entries[0].years
        }
      ]
    });
  };

  const removeLinkedEntry = () => {
    if (isOpenDeleteLinkedModal.id) {
      const values = [...initialEntry.entries];
      const removeLinkedEntryId = _.findIndex(
        values[0].linkedArray,
        (item) => item === values[isOpenDeleteLinkedModal.id].id
      );
      if (removeLinkedEntryId > -1) {
        values[0].linkedArray.splice(removeLinkedEntryId, 1);
      }

      values.splice(isOpenDeleteLinkedModal.id, 1);
      setInitialEntry({ entries: [...values] });
    }
    setOpenDeleteLinkedModal({ open: false, id: null });
  };

  const handleExpandChange = (id, values) => {
    let updateInitialValue = [];
    updateInitialValue = values.entries.map((data, index) => {
      if (index === id) {
        return { ...data, expand: data.expand ? false : true };
      } else {
        return { ...data, expand: false };
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const validationSchema = Yup.lazy((values) => {
    return Yup.object().shape({
      entries: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          label: Yup.string()
            .trim()
            .default('none')
            .required('Label is required'),
          title: Yup.string().trim().required('Title is required'),
          location: Yup.string().trim(),
          description: Yup.string().trim(),
          groupType: Yup.string().trim(),
          school: Yup.string()
            .trim()
            .test('school test', 'School is required', function () {
              return (
                values.entries.length &&
                values.entries[0] &&
                values.entries[0].school
              );
            }),
          years: Yup.array().test(
            'year test',
            'Year is required',
            function () {
              return values.entries.length &&
                values.entries[0] &&
                values.entries[0].groupType === 'Families'
                ? values.entries[0].years.length
                : true;
            }
          ),
          groups: Yup.array().test(
            'group test',
            'Group is required',
            function () {
              return values.entries.length &&
                values.entries[0] &&
                values.entries[0].groupType === 'Group'
                ? values.entries[0].groups.length
                : true;
            }
          ),
          // for: Yup.array().required('For is required'),
          for: Yup.string().test('for test', 'For is required', function () {
            return (
              values.entries.length &&
              values.entries[0] &&
              values.entries[0].for.length
            );
          }),
          from: Yup.object().required('From is required'),
          // to: Yup.object().required('To is required'),
          to: Yup.object()
            .required('To is required')
            .test(
              'date test',
              'From date needs to be before the To date',
              function () {
                const { from, to } = this.parent;
                return moment(to.date, 'MMM DD,YYYY').isSameOrAfter(
                  moment(from.date, 'MMM DD,YYYY')
                );
              }
            ),
          allDay: Yup.boolean(),
          checkDescription: Yup.boolean(),
          actions: Yup.object().shape({
            repeat: Yup.array().of(
              Yup.object().shape({ type: Yup.string().trim() })
            ),
            notification: Yup.array().of(
              Yup.object().shape({
                days: Yup.string().trim(),
                time: Yup.string().trim()
              })
            ),
            deadline: Yup.array(),
            reminder: Yup.array()
          }),
          newFromTimeFormate: Yup.object().when('allDay', {
            is: false,
            then: Yup.object({
              hh: Yup.string().trim().required('time is required'),
              mm: Yup.string().trim().required('time is required'),
              ap: Yup.string().trim().required('time is required')
            })
          }),
          // newFromTimeFormate: Yup.object().shape({
          //   hh: Yup.string().trim().required('time is required'),
          //   mm: Yup.string().trim().required('time is required'),
          //   ap: Yup.string().trim().required('time is required'),
          // }),
          newToTimeFormate: Yup.object().when('allDay', {
            is: false,
            then: Yup.object({
              hh: Yup.string().trim().required('time is required'),
              mm: Yup.string().trim().required('time is required'),
              ap: Yup.string().trim().required('time is required')
            })
          })
        })
      )
    });
  });

  const handleActionChange = (e, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      let updatedData;
      if (index === selectedIndex) {
        if (e.target.value === 'deadline' || e.target.value === 'reminder') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              [e.target.value]: [
                ...data.actions[e.target.value],
                moment().format('MMM DD,YYYY')
              ],
              [e.target.value + 'Notification']:
                e.target.value === 'reminder'
                  ? [
                      ...(data.actions.reminderNotification || []),
                      {
                        days: '',
                        date: moment().format('MMM DD,YYYY'),
                        time: '7:30pm'
                      }
                    ]
                  : [
                      ...(data.actions.deadlineNotification || []),
                      {
                        days: '',
                        date: moment().format('MMM DD,YYYY'),
                        time: '7:30pm'
                      }
                    ]
            }
          };
        } else if (e.target.value === 'notification') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              notification: [
                ...data.actions.notification,
                {
                  days: '',
                  date: moment(data.from.date, 'MMM DD,YYYY').format(
                    'MMM DD,YYYY'
                  ),
                  time: moment().format('h:mma')
                }
              ]
            }
          };
        } else if (e.target.value === 'repeat') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              repeat: [...data.actions.repeat, { type: 'Does not repeat' }] // [...data.actions.repeat, { type: '', values: {} }]
            }
          };
        } else if (e.target.value === 'cancelled') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isChanged: false,
              isPostponed: false,
              isCancelled: true
            }
          };
        } else if (e.target.value === 'postponed') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isChanged: false,
              isCancelled: false,
              isPostponed: true
            }
          };
        } else if (e.target.value === 'changed') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isCancelled: false,
              isPostponed: false,
              isChanged: true
            }
          };
        }
      } else {
        updatedData = data;
      }
      return updatedData;
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const onEditorChange = (e, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (data.isPrimary) {
          setDefaultNote(e);
        }
        return { ...data, description: e };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleChangeCheck = (name, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (name === 'checkDescription') {
          setDefaultNote(toGetContentFromHTML(values.entries[0].description));
          return {
            ...data,
            [name]: data[name] ? false : true
          };
        } else {
          return { ...data, [name]: data[name] ? false : true };
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleNotificationDateChange = (
    e,
    selectedIndex,
    innerIndex,
    values,
    _value,
    type = ''
  ) => {
    const formateValue = 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (type === 'reminderNotification' && index === selectedIndex) {
        const notificationArray = data.actions.reminderNotification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, reminderNotification: notificationArray }
        };
      } else if (type === 'deadlineNotification' && index === selectedIndex) {
        const notificationArray = data.actions.deadlineNotification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, deadlineNotification: notificationArray }
        };
      } else if (index === selectedIndex) {
        const notificationArray = data.actions.notification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, notification: notificationArray }
        };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleDateChange = (e, selectedIndex, values, key, value) => {
    const formateValue = value === 'time' ? 'h:mma' : 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (key === 'from' && value === 'date') {
          const endDate = moment(data.to.date, 'MMM DD,YYYY').isSameOrAfter(
            moment(e, 'MMM DD,YYYY')
          )
            ? data.to.date
            : moment(e).format(formateValue);
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) },
            to: { ...data['to'], [value]: endDate }
          };
        } else if (key === 'from' && value === 'time') {
          const endTime = moment(e).add(1, 'hour').format(formateValue);
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) },
            to: { ...data['to'], [value]: endTime }
          };
        } else {
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) }
          };
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleRepeatChange = (e, selectedIndex, innerIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        const repeatArray = data.actions.repeat.map(
          (repeatData, repeatIndex) => {
            if (innerIndex === repeatIndex) {
              return { type: e.target.value };
            } else {
              return repeatData;
            }
          }
        );
        return { ...data, actions: { ...data.actions, repeat: repeatArray } };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
    if (e.target.value === 'Custom') {
      const data = {
        selectedIndex,
        innerIndex,
        values: { entries: [...updateInitialValue] }
      };
      setCustomValue(data);
      setOpenModal({
        open: true,
        value: JSON.stringify(
          data.values.entries[selectedIndex].actions.repeat[innerIndex]
        )
      });
    }
  };

  const handleDateActionChange = (
    e,
    selectedIndex,
    innerIndex,
    values,
    key,
    value
  ) => {
    const formateValue = value === 'time' ? 'h:mma' : 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (key === 'notification') {
          const notificationArray = data.actions.notification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: { ...data.actions, notification: notificationArray }
          };
        } else if (key === 'reminderNotification') {
          const notificationArray = data.actions.reminderNotification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              reminderNotification: notificationArray
            }
          };
        } else if (key === 'deadlineNotification') {
          const notificationArray = data.actions.deadlineNotification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              deadlineNotification: notificationArray
            }
          };
        } else if (key === 'reminder') {
          const remindNotifyUpdate = data.actions.reminderNotification
            ? [...data.actions.reminderNotification]
            : [];
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                if (
                  remindNotifyUpdate.length &&
                  remindNotifyUpdate[notificationIndex]
                ) {
                  const notificationDate = moment(e, formateValue);
                  const fromDate = moment(data.from.date, formateValue);
                  let dayValue = '';
                  if (moment(notificationDate).isSame(fromDate)) {
                    dayValue = '';
                  } else {
                    dayValue = notificationDate.diff(fromDate, 'days');
                  }
                  remindNotifyUpdate[notificationIndex] = {
                    ...remindNotifyUpdate[notificationIndex],
                    days: dayValue,
                    date: moment(e).format(formateValue)
                  };
                }
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              [key]: actionArray,
              reminderNotification: remindNotifyUpdate
            }
          };
        } else if (key === 'deadline') {
          const deadlineNotifyUpdate = data.actions.deadlineNotification
            ? [...data.actions.deadlineNotification]
            : [];
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                if (
                  deadlineNotifyUpdate.length &&
                  deadlineNotifyUpdate[notificationIndex]
                ) {
                  const notificationDate = moment(e, formateValue);
                  const fromDate = moment(data.from.date, formateValue);
                  let dayValue = '';
                  if (moment(notificationDate).isSame(fromDate)) {
                    dayValue = '';
                  } else {
                    dayValue = notificationDate.diff(fromDate, 'days');
                  }
                  deadlineNotifyUpdate[notificationIndex] = {
                    ...deadlineNotifyUpdate[notificationIndex],
                    days: dayValue,
                    date: moment(e).format(formateValue)
                  };
                }
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              [key]: actionArray,
              deadlineNotification: deadlineNotifyUpdate
            }
          };
        } else if (key) {
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return { ...data, actions: { ...data.actions, [key]: actionArray } };
        } else {
          return data;
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleDateActionRemove = (selectedIndex, innerIndex, values, key) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (
        key === 'isCancelled' ||
        key === 'isPostponed' ||
        key === 'isChanged'
      ) {
        if (index === selectedIndex) {
          const values = data.actions;
          values[key] = false;
          return { ...data, values };
        } else {
          return data;
        }
      } else if (key === 'reminderNotification') {
        if (index === selectedIndex) {
          const values = data.actions.reminderNotification.filter(
            (_date, index) => index !== innerIndex
          );
          return {
            ...data,
            actions: { ...data.actions, reminderNotification: values }
          };
        } else {
          return data;
        }
        // reminderNotification
      } else if (key === 'deadlineNotification') {
        if (index === selectedIndex) {
          const values = data.actions.deadlineNotification.filter(
            (_date, index) => index !== innerIndex
          );
          return {
            ...data,
            actions: { ...data.actions, deadlineNotification: values }
          };
        } else {
          return data;
        }
        // deadlineNotification
      } else {
        if (index === selectedIndex) {
          const values = data.actions;
          values[key].splice(innerIndex, 1);
          return { ...data, values };
        } else {
          return data;
        }
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleCustomDateSubmit = (dateModalData) => {
    const {
      data: { selectedIndex, innerIndex }
    } = dateModalData;
    const updateInitialValue = dateModalData.data.values.entries.map(
      (data, index) => {
        if (index === selectedIndex) {
          const repeatArray = data.actions.repeat.map(
            (repeatData, repeatIndex) => {
              if (innerIndex === repeatIndex) {
                return { ...repeatData, values: dateModalData.values };
              } else {
                return repeatData;
              }
            }
          );
          return { ...data, actions: { ...data.actions, repeat: repeatArray } };
        } else {
          return data;
        }
      }
    );
    setInitialEntry({ entries: [...updateInitialValue] });
    setOpenModal({ open: false, value: [] });
    setCustomValue({});
  };

  const handleCustomDateClose = () => {
    const modalValue = JSON.parse(isOpenModal.value);
    const { selectedIndex, innerIndex } = customValue;
    const updateInitialValue = initialEntry.entries.map((data, index) => {
      if (index === selectedIndex) {
        const repeatArray = data.actions.repeat.map(
          (repeatData, repeatIndex) => {
            if (innerIndex === repeatIndex) {
              const fromDate = moment(data.from.date, 'MMM DD,YYYY').format();
              const customDateObject = {
                repeatDay: 1,
                repeatType: 'Day',
                repeatOn: [moment(fromDate).format('ddd')],
                repeatMonth: {
                  type: 'day',
                  value: moment(fromDate).format('MMM DD,YYYY')
                },
                endsType: 'Never',
                endsDate: moment(fromDate).format('MMM DD,YYYY'),
                endsAfter: 1
              };
              return {
                ...repeatData,
                values: modalValue.values ? modalValue.values : customDateObject
              };
            } else {
              return repeatData;
            }
          }
        );
        return { ...data, actions: { ...data.actions, repeat: repeatArray } };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
    setOpenModal({ open: false, value: [] });
    setCustomValue({});
  };

  const fetchGroup = (schoolId, ids, type) => {
    setGroups([]);
    getGroupBySchoolId(schoolId)
      .then(function (result) {
        const GroupData = [];
        let groupMemberIds = [];
        result.data
          .filter((item) => ids.includes(item.category))
          .forEach(function (result) {
            if (type !== 'Free' && result.groupType !== 'Free') {
              GroupData.push({
                key: result.groupName,
                value: result.id,
                selected: false,
                groupMembers: result.groupMembers
              });
            }
            if (type === 'Free' && result.groupType === 'Free') {
              groupMemberIds = groupMemberIds.concat(result.groupMembers);
              GroupData.push({
                key: result.groupName,
                value: result.id,
                selected: hasPreserveEntryData.entries[0].groups.includes(
                  result.id
                )
                  ? true
                  : false,
                years: result.years,
                groupMembers: result.groupMembers,
                category: [result.category]
              });
            }
          });

        setGroups(GroupData);
        if (type !== 'Free') {
          const FamilyData = [];
          FamilyData.push(
            {
              id: 'Parents',
              name: 'Parents',
              selected: false,
              year: '',
              type: 'parent',
              surname: ''
            },
            {
              id: 'Child',
              name: 'Child',
              selected: false,
              year: '',
              type: 'child',
              surname: ''
            }
          );
          // });
          setFamily(FamilyData);
          setAllFamily(FamilyData);

          const updateInitialValueEntry = hasPreserveEntryData.entries.map(
            (entry) => {
              return {
                ...entry,
                forDropDownData: FamilyData,
                school: schoolId
              };
            }
          );
          setInitialEntry({
            entries: updateInitialValueEntry
          });

          firebase
            .getFamilyBySchoolId(schoolId)
            .then(function (querySnapshot) {
              const FamilyFullData = [];
              querySnapshot.forEach(function (doc) {
                const parentData = [];
                const childData = [];
                doc.data().parents.forEach((element) => {
                  parentData.push({
                    id: element.id,
                    type: 'parents',
                    familyId: doc.id,
                    assign: doc.data().childs.map((child) => {
                      return child.id;
                    })
                  });
                });
                doc.data().childs.forEach((element) => {
                  childData.push({
                    id: element.id,
                    familyId: doc.id,
                    type: 'child',
                    assign: [],
                    school: element.school
                  });
                });
                FamilyFullData.push({
                  surname: doc.data().familySurname,
                  familyId: doc.id,
                  parents: parentData,
                  childs: childData
                });
              });
              const FamilyData = [];
              FamilyData.push({
                id: 'Select All Parents',
                name: 'Select All Parents',
                type: 'Select All Parents',
                selected: false
              });
              setGroupFamily(FamilyFullData);
            })
            .catch(function (error) {
              console.log('Error getting documents: ', error);
              dispatch(
                addMessage(
                  'Server connection issue. Please refresh',
                  MESSAGE_TYPES.ERROR
                )
              );
            });
        }

        if (type === 'Free') {
          groupMemberIds = _.uniq(groupMemberIds);
          setGroupFamily(groupMemberIds);
        }
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(
          addMessage(
            'Server connection issue. Please refresh',
            MESSAGE_TYPES.ERROR
          )
        );
      });
  };

  const schoolChangeHandle = (e, selectedIndex, values, setFieldValue) => {
    const schoolId = e.target.value;
    getSchoolByID(schoolId).then((schoolData) => {
      const filterCategoryIds = schoolData?.categories.map((categoryData) => {
        return categoryData?.id;
      });

      setCategory(filterCategoryIds);
      if (values.entries[selectedIndex].groupType === 'Group') {
        setFieldValue(`entries[${selectedIndex}].groups`, []);
        fetchGroup(
          schoolId,
          filterCategoryIds,
          values.entries[selectedIndex].groupType
        );
      }
      if (values.entries[selectedIndex].groupType === 'Families') {
        setFieldValue(`entries[${selectedIndex}].years`, []);
        setFieldValue(`entries[${selectedIndex}].for`, []);
      }
    });

    if (
      values.entries[selectedIndex].groupType === 'Families' ||
      values.entries[selectedIndex].groupType === 'Group'
    ) {
      const familyData = [
        {
          id: 'Select All Parents',
          name: 'Select All Parents',
          type: 'Select All Parents',
          selected: false
        }
      ];
      const FamilyFullData = [];
      const yearsData = [];
      const attachFamily = [];
      paidFamiliesData.forEach((family) => {
        if (family.childsSchools.includes(schoolId)) {
          if (family.id) {
            attachFamily.push({ id: family.id, name: family.familySurname });
          }
          const parentData = [];
          const childData = [];
          family.childs.forEach((child) => {
            if (child.school === schoolId) {
              yearsData.push({
                key: `Year ${child.year}`,
                value: child.year,
                selected: false
              });
              childData.push({
                id: child.id,
                name: child.firstName,
                initials: child.initials,
                selected: false,
                year: child.year,
                type: 'child',
                surname: family.familySurname,
                familyId: family.familyId,
                school: child.school
              });
            }
          });
          family.parents.forEach((element) => {
            parentData.push({
              id: element.id, // set surname as id for parents
              name: `${family.familySurname} - Parents`,
              initials: element.initials,
              selected: false,
              year: '',
              type: 'parent',
              surname: family.familySurname,
              familyId: family.familyId
            });
          });
          FamilyFullData.push({
            surname: family.familySurname,
            familyId: family.familyId,
            parents: parentData,
            childs: childData
          });
        }
      });
      const uniYear = _.uniqBy(yearsData, 'value');
      const sortYear = uniYear.sort(function (a, b) {
        return parseInt(a.value) - parseInt(b.value);
      });

      setYears([
        {
          key: `All Years`,
          value: 'all',
          selected: false
        },
        ...sortYear
      ]);

      FamilyFullData.map((family) => {
        const yearArray = _.map(family.childs, 'year');
        familyData.push(
          {
            id: family.surname + ' (' + getInitials(family.childs) + ') ',
            name: family.surname + ' (' + getInitials(family.parents) + ') ',
            type: 'surname',
            familyId: family.familyId,
            yearArray: yearArray
          },
          { ...family.parents[0], yearArray },
          ...family.childs
        );
        return family;
      });
      if (values.entries[selectedIndex].groupType === 'Families') {
        setFamily(familyData);
        setAllFamily(familyData);
        setFamilySurnameWise(FamilyFullData);

        const updateInitialValueEntry = hasPreserveEntryData.entries.map(
          (entry) => {
            return {
              ...entry,
              forDropDownData: familyData,
              school: e.target.value,
              for: [],
              years: [],
              groups: []
            };
          }
        );
        setInitialEntry({
          entries: updateInitialValueEntry
        });
      }
    } else {
      const yearsData = [];
      const attachFamily = [];
      const groupCategories = [];
      let categoryIds = [];
      const selectedSchool = schools.find((school) => school.id === schoolId);

      categoryIds = selectedSchool.categories.map((categoryData) => {
        return categoryData?.id;
      });

      // const categoryId =
      categoryIds.includes(values.entries[selectedIndex].categoryInfo);

      fetchGroup(
        schoolId,
        categoryIds,
        values.entries[selectedIndex].groupType
      );
      if (selectedSchool) {
        selectedSchool.freeCalendars.forEach((year) => {
          const getCurrentYear = _.find(
            DefaultYear,
            (elem) => elem.value === year
          );

          yearsData.push({
            key: getCurrentYear?.label,
            value: year,
            selected: false
          });
        });

        selectedSchool.categories.forEach((category) => {
          groupCategories.push({
            key: category?.name,
            value: category?.id,
            selected: false
          });
        });
        // this is code for attachment family data
        attachFamily.push({ id: selectedSchool.id, name: selectedSchool.name });
        // end of this is code for attachment family data
      }
      setCategory([...groupCategories]);
      const uniYear = _.uniqBy(yearsData, 'value');
      const sortYear = uniYear.sort(function (a, b) {
        return parseInt(a.value) - parseInt(b.value);
      });

      setYears([
        {
          key: `All Years`,
          value: 'all',
          selected: false
        },
        ...sortYear
      ]);
      const updateInitialValueEntry = hasPreserveEntryData.entries.map(
        (entry) => {
          return {
            ...entry,
            yearDropDownData: [
              {
                key: `All Years`,
                value: 'all',
                selected: false
              },
              ...sortYear
            ],
            categoryDropDownData: [...groupCategories],
            school: e.target.value,
            for: [],
            years: [],
            categoryInfo: [],
            groups: []
          };
        }
      );
      setInitialEntry({
        entries: updateInitialValueEntry
      });
    }
  };

  const handleChangeMember = (
    event,
    selectedIndex,
    oldValues,
    _setFieldValue,
    values
  ) => {
    let selectValue = event.target.value;
    let selectLastValue = '';
    let lastOldValue = '';
    if (selectValue.length) {
      selectLastValue = event.target.value[event.target.value.length - 1];
      lastOldValue = oldValues[oldValues.length - 1];
      const foundSurname = _.find(
        values.entries[selectedIndex].forDropDownData,
        (data) => data.id === selectValue[selectValue.length - 1]
      );
      const foundSurnameIdMap = _.map(
        values.entries[selectedIndex].forDropDownData,
        (familyData) => {
          if (
            Object.prototype.hasOwnProperty.call(foundSurname, 'surname') &&
            familyData.surname === foundSurname.surname
          ) {
            return { id: familyData.id, type: familyData.type };
          }
        }
      );
      const foundSurnameIdFilter = _.filter(
        foundSurnameIdMap,
        (familyData) => familyData && familyData.id
      );

      // for parent select
      let foundParentsIdMap = [];
      let foundParentsIdFilter = [];
      if (selectLastValue === 'Select All Parents') {
        const foundDiffOldAndNew = _.xor(oldValues, selectValue);
        if (
          foundDiffOldAndNew &&
          foundDiffOldAndNew.length &&
          !foundDiffOldAndNew.includes('Select All Parents')
        ) {
          foundParentsIdMap = _.map(
            values.entries[selectedIndex].forDropDownData,
            (familyData) =>
              familyData.type === 'parent' &&
              !foundDiffOldAndNew.includes(familyData.id) &&
              familyData.id
          );
          foundParentsIdFilter = _.filter(
            foundParentsIdMap,
            (familyData) => familyData
          );
          selectValue = [...foundParentsIdFilter];
          selectLastValue =
            foundParentsIdFilter[foundParentsIdFilter.length - 1];
        } else {
          foundParentsIdMap = _.map(
            values.entries[selectedIndex].forDropDownData,
            (familyData) => familyData.type === 'parent' && familyData.id
          );
          foundParentsIdFilter = _.filter(
            foundParentsIdMap,
            (familyData) => familyData
          );
          selectValue = [...foundParentsIdFilter];
        }
      } else if (
        lastOldValue === 'Select All Parents' &&
        !selectValue.includes('Select All Parents')
      ) {
        foundParentsIdMap = _.map(
          values.entries[selectedIndex].forDropDownData,
          (familyData) => familyData.type === 'parent' && familyData.id
        );
        foundParentsIdFilter = _.filter(
          foundParentsIdMap,
          (familyData) => familyData
        );

        const updatedSelectValueDataMap = foundParentsIdFilter.map(
          (parentId) => {
            const foundIndex = _.findIndex(
              selectValue,
              (selectId) => selectId === parentId
            );
            if (foundIndex > -1) {
              return null;
            }
            return parentId;
          }
        );
        const updatedSelectValueDataFilter = _.filter(
          updatedSelectValueDataMap,
          (updatedSelectFilter) => updatedSelectFilter
        );
        selectValue = updatedSelectValueDataFilter
          ? updatedSelectValueDataFilter
          : [];
        selectLastValue = updatedSelectValueDataFilter.length
          ? selectLastValue
          : '';
      } else {
        // selectValue.map((id, index) => {
        const foundIndex = _.findIndex(
          selectValue,
          (selectId) => selectId === 'Select All Parents'
        );
        if (foundIndex > -1) {
          selectValue.splice(foundIndex, 1);
        }
        // });
      }
      const updatedSelectValueDataMap = selectValue.map((id) => {
        const selectedLastValue = _.find(
          foundSurnameIdFilter,
          (surname) => surname.id === selectLastValue
        );
        const foundIndex = _.findIndex(foundSurnameIdFilter, (selectId) =>
          selectedLastValue.type === 'parent'
            ? selectId.id === id
            : selectId.id === id && selectId.type !== 'child'
        );
        if (foundIndex > -1) {
          // selectValue.splice(index, 1);
          return null;
        } else {
          return id;
        }
      });
      const updatedSelectValueDataFilter = _.filter(
        updatedSelectValueDataMap,
        (updatedSelectFilter) => updatedSelectFilter
      );
      selectValue = updatedSelectValueDataFilter;
    }
    let updatedSelectValue = [];
    if (selectLastValue) {
      updatedSelectValue = _.uniq([...selectValue, selectLastValue]);
    }

    let foundAttachmentIdsMap = [];
    if (values.entries[selectedIndex].groupType === 'Group') {
      foundAttachmentIdsMap =
        Array.isArray(groupFamily) &&
        groupFamily.length &&
        groupFamily.map((familyData) => {
          return {
            id: familyData.familyId,
            name: familyData.surname
          };
        });
    } else {
      foundAttachmentIdsMap =
        Array.isArray(values.entries[selectedIndex].forDropDownData) &&
        values.entries[selectedIndex].forDropDownData.length &&
        values.entries[selectedIndex].forDropDownData.map((familyData) => {
          if (updatedSelectValue.includes(familyData.id)) {
            return {
              id: familyData.familyId,
              name: familyData.surname
            };
          }
        });
    }
    // const foundAttachmentIdsFilter =
    _.filter(
      foundAttachmentIdsMap,
      (familyIds) => familyIds && familyIds.id && familyIds
    );

    // setFieldValue(`entries[${selectedIndex}].for`, updatedSelectValue);
    const updatedFamily = values.entries[selectedIndex].forDropDownData.map(
      (familyData) => {
        if (updatedSelectValue.includes(familyData.id)) {
          return {
            ...familyData,
            selected: true
          };
        } else {
          return {
            ...familyData,
            selected: false
          };
        }
      }
    );
    if (selectedIndex === 0) {
      const updatedFamilyFormMainFamily = family.map((familyData) => {
        if (updatedSelectValue.includes(familyData.id)) {
          return {
            ...familyData,
            selected: true
          };
        } else {
          return {
            ...familyData,
            selected: false
          };
        }
      });
      setFamily(updatedFamilyFormMainFamily);
    }

    const updateInitialValueEntry = values.entries.map((entry, valueIndex) => {
      if (valueIndex === selectedIndex) {
        return {
          ...entry,
          forDropDownData: updatedFamily,
          for: updatedSelectValue
        };
      } else {
        if (selectedIndex === 0) {
          let selectedForDropDownData = [];
          const foundSelectedIdMap = _.map(updatedFamily, (familyData) => {
            return familyData.selected ? familyData.familyId : null;
          });
          // const foundSelectedIdFilter =
          _.filter(foundSelectedIdMap, (familyData) => familyData);
          if (family.length) {
            const foundSelectedFamilyMap = _.map(
              updatedFamily,
              (familyData) => {
                return familyData.selected ? familyData.familyId : null;
              }
            );
            const foundSelectedFamilyFilter = _.filter(
              foundSelectedFamilyMap,
              (familyData) => familyData
            );

            const foundSelectedFamilyWithSurnameFilter = _.filter(
              updatedFamily,
              (familyData) =>
                foundSelectedFamilyFilter.includes(familyData.familyId)
            );
            let setAllFalse = [];
            if (values.entries[valueIndex].groupType === 'Group') {
              setAllFalse = _.map(updatedFamily, (familyData) => {
                return { ...familyData };
              });
              selectedForDropDownData = setAllFalse;
            } else {
              setAllFalse = _.map(
                foundSelectedFamilyWithSurnameFilter,
                (familyData) => {
                  return { ...familyData };
                }
              );
              selectedForDropDownData = [updatedFamily[0], ...setAllFalse];
            }
          }
          return {
            ...entry,
            for: updatedSelectValue,
            forDropDownData: selectedForDropDownData
          };
        } else {
          return { ...entry };
        }
      }
    });
    setInitialEntry({
      entries: updateInitialValueEntry
    });

    // setFieldValue(`entries[${selectedIndex}].forDropDownData`, updatedFamily);
  };

  const handleChangeFreeYear = (
    event,
    selectedIndex,
    entries,
    setFieldValue
  ) => {
    let selectValue = event.target.value;

    // if we have to select all in All Years then uncomment the code

    const allId = _.map(years, (year) => year.value);
    if (
      selectValue[selectValue.length - 1] === 'all' &&
      selectValue.includes('all')
    ) {
      selectValue = allId;
    } else {
      const removeAllIndex = _.findIndex(selectValue, (id) => id === 'all');
      if (removeAllIndex > -1) {
        selectValue.splice(removeAllIndex, 1);
      }
      // selectValue = selectValue;
    }

    if (entries[selectedIndex].groupType === 'Families') {
      // setFieldValue(`entries[${selectedIndex}].for`, []);
      // setFieldValue(`entries[${selectedIndex}].years`, selectValue);
      if (selectValue.includes('all')) {
        setFamily(allFamily);
        entries.map((_entryData, index) => {
          if (selectedIndex === index) {
            setFieldValue(`entries[${selectedIndex}].for`, []);
            setFieldValue(`entries[${selectedIndex}].years`, selectValue);
            setFieldValue(
              `entries[${selectedIndex}].forDropDownData`,
              allFamily
            );
          } else {
            setFieldValue(`entries[${index}].forDropDownData`, allFamily);
            setFieldValue(`entries[${index}].for`, []);
            setFieldValue(`entries[${index}].years`, selectValue);
          }
          return entries;
        });
      } else {
        const yearFamiy = [
          {
            id: 'Select All Parents',
            name: 'Select All Parents',
            type: 'Select All Parents',
            selected: false
          }
        ];
        allFamily.forEach((element) => {
          let added = false;

          if (element.yearArray) {
            selectValue.forEach((val) => {
              if (element.yearArray.indexOf(val) > -1 && !added) {
                added = true;
                yearFamiy.push(element);
              }
            });
          } else {
            if (selectValue.includes(element.year)) {
              yearFamiy.push(element);
            }
          }
        });
        setFamily(yearFamiy);
        // setFieldValue(`entries[${selectedIndex}].forDropDownData`, yearFamiy);
        entries.map((_entryData, index) => {
          if (selectedIndex === index) {
            setFieldValue(`entries[${selectedIndex}].for`, []);
            setFieldValue(`entries[${selectedIndex}].years`, selectValue);
            setFieldValue(
              `entries[${selectedIndex}].forDropDownData`,
              yearFamiy
            );
          } else {
            setFieldValue(`entries[${index}].forDropDownData`, yearFamiy);
            setFieldValue(`entries[${index}].for`, []);
            setFieldValue(`entries[${index}].years`, selectValue);
          }
          return entries;
        });
      }
    }

    const updatedYears = years.map((yearData) => {
      if (selectValue[selectValue.length - 1] === 'all') {
        return {
          ...yearData,
          selected: true
        };
      } else if (selectValue.includes(yearData.value)) {
        return {
          ...yearData,
          selected: true
        };
      } else {
        return {
          ...yearData,
          selected: false
        };
      }
    });
    setYears(updatedYears);

    const getFieldName = event.target.name.split('.').reverse()[0];
    if (entries[selectedIndex].groupType === 'Free') {
      if (getFieldName === 'for') {
        setFieldValue(`entries[${selectedIndex}].for`, selectValue);
        setFieldValue(`entries[${selectedIndex}].groups`, []);
        setFieldValue(`entries[${selectedIndex}].groupMembers`, []);
        if (selectedIndex === 0) {
          entries.map((_entry, index) => {
            if (index !== selectedIndex) {
              setFieldValue(`entries[${index}].for`, selectValue);
              setFieldValue(`entries[${index}].yearDropDownData`, updatedYears);
              // setFieldValue(`entries[${index}].categoryDropDownData`, updatedCategory);
              setFieldValue(`entries[${index}].groups`, []);
              setFieldValue(`entries[${index}].groupMembers`, []);
            }
          });
        }
      }
      if (getFieldName === 'categoryInfo') {
        const updatedCategory = category?.map((categoryData) => {
          if (selectValue?.includes(categoryData.value)) {
            return {
              ...categoryData,
              selected: true
            };
          } else {
            return {
              ...categoryData,
              selected: false
            };
          }
        });
        const selectedCategoryData = _.map(
          _.filter(updatedCategory, (category) => category.selected),
          'value'
        );

        setCategory(updatedCategory);
        setCategoryList(selectedCategoryData);
        fetchGroup(
          entries[0].school,
          selectedCategoryData,
          entries[0].groupType
        );
        setFieldValue(
          `entries[${selectedIndex}].yearDropDownData`,
          updatedYears
        );
        setFieldValue(
          `entries[${selectedIndex}].categoryDropDownData`,
          updatedCategory
        );
        setFieldValue(`entries[${selectedIndex}].categoryInfo`, selectValue);
      }

      if (getFieldName === 'groups') {
        let groupYears = [];
        let groupMembers = [];
        const groupCategory = [];
        groups.forEach((groupData) => {
          if (selectValue.includes(groupData.value)) {
            groupMembers = groupMembers.concat(groupData.groupMembers);
            groupData &&
              groupData.years &&
              groupData.years.forEach((item) => {
                const groupVal =
                  item === 'Pre K'
                    ? '-1'
                    : item === 'Kindy'
                      ? '0'
                      : item.includes('Year ') === -1
                        ? item
                        : item.split('Year ').reverse()[0];
                groupYears.push(groupVal);
              });

            groupData &&
              groupData.category &&
              groupData.category.forEach((item) => {
                const groupCat = item;
                groupCategory.push(groupCat);
              });
          }
        });
        groupMembers = _.uniq(groupMembers);
        setGroupFamily(groupMembers);
        groupYears = _.uniq(groupYears);
        const updatedYears = years.map((yearData) => {
          if (groupYears.includes(yearData.value)) {
            return {
              ...yearData,
              selected: true
            };
          } else {
            return {
              ...yearData,
              selected: false
            };
          }
        });
        setYears(updatedYears);

        const updatedCategory = category?.map((categoryData) => {
          if (categoryList?.includes(categoryData.value)) {
            return {
              ...categoryData,
              selected: true
            };
          } else {
            return {
              ...categoryData,
              selected: false
            };
          }
        });

        const selectedCategoryData = _.map(
          _.filter(updatedCategory, (category) => category.selected),
          'value'
        );
        setCategory(updatedCategory);
        setCategoryList(selectedCategoryData);
        setFieldValue(`entries[${selectedIndex}].for`, groupYears);
        setFieldValue(`entries[${selectedIndex}].groupMembers`, groupMembers);
        setFieldValue(
          `entries[${selectedIndex}].yearDropDownData`,
          updatedYears
        );
        setFieldValue(
          `entries[${selectedIndex}].categoryDropDownData`,
          updatedCategory
        );
        setFieldValue(
          `entries[${selectedIndex}].categoryInfo`,
          selectedCategoryData
        );
        if (selectedIndex === 0) {
          entries.map((_entry, index) => {
            if (index !== selectedIndex) {
              setFieldValue(`entries[${index}].for`, groupYears);
              setFieldValue(
                `entries[${index}].categoryInfo`,
                selectedCategoryData
              );
              setFieldValue(`entries[${index}].yearDropDownData`, updatedYears);
              setFieldValue(
                `entries[${index}].categoryDropDownData`,
                updatedCategory
              );
              setFieldValue(`entries[${index}].groups`, selectValue);
              setFieldValue(`entries[${index}].groupMembers`, groupMembers);
            }
          });
        }
      }
    }

    if (entries[selectedIndex].groupType === 'Group') {
      if (selectValue && selectValue.length) {
        const foundMember = _.find(groups, (group) =>
          selectValue.includes(group.value)
        );
        if (
          foundMember &&
          foundMember.groupMembers &&
          foundMember.groupMembers.length
        ) {
          const updatedFamilyDataMap = groupFamily.map((family) => {
            const updatedChild =
              family.childs &&
              family.childs.filter((child) => {
                return foundMember.groupMembers.includes(child.id);
              });
            if (updatedChild.length) {
              return {
                ...family,
                parents: family.parents,
                childs: updatedChild
              };
            } else {
              return null;
            }
          });
          const updatedFamilyDataFilter = updatedFamilyDataMap.filter(
            (family) => family
          );
          setGroupFamily(updatedFamilyDataFilter);
        } else {
          setGroupFamily(groupFamily);
        }
      }
    }
    const updatedGroups = groups.map((groupData) => {
      if (selectValue.includes(groupData.value)) {
        return {
          ...groupData,
          selected: true
        };
      } else {
        return {
          ...groupData,
          selected: false
        };
      }
    });
    setGroups(updatedGroups);
  };

  const submitNewSchoolForm = async (formData) => {
    // New time format
    formData.forEach((entry, index) => {
      if (entry.allDay) {
        formData[index].from = { ...formData[index].from, time: '12:30am' };
        formData[index].to = { ...formData[index].to, time: '12:30am' };
      } else {
        if (formData[index].newFromTimeFormate) {
          formData[index].newFromTimeFormate = {
            hh: formData[index].newFromTimeFormate.hh,
            mm:
              formData[index].newFromTimeFormate.mm.toString().length === 1
                ? '0' + formData[index].newFromTimeFormate.mm
                : formData[index].newFromTimeFormate.mm.toString(),
            ap:
              formData[index].newFromTimeFormate.ap.toLowerCase() === 'a' ||
              formData[index].newFromTimeFormate.ap.toLowerCase() === 'p'
                ? formData[index].newFromTimeFormate.ap.toLowerCase()
                : 'a'
          };
          formData[index].newToTimeFormate = {
            hh: formData[index].newToTimeFormate.hh,
            mm:
              formData[index].newToTimeFormate.mm.toString().length === 1
                ? '0' + formData[index].newToTimeFormate.mm
                : formData[index].newToTimeFormate.mm.toString(),
            ap:
              formData[index].newToTimeFormate.ap.toLowerCase() === 'a' ||
              formData[index].newToTimeFormate.ap.toLowerCase() === 'p'
                ? formData[index].newToTimeFormate.ap.toLowerCase()
                : 'p'
          };

          formData[index].from = {
            date: formData[index].from.date,
            time:
              formData[index].newFromTimeFormate.hh +
              ':' +
              formData[index].newFromTimeFormate.mm +
              formData[index].newFromTimeFormate.ap +
              'm'
          };
          formData[index].to = {
            date: formData[index].to.date,
            time:
              formData[index].newToTimeFormate.hh +
              ':' +
              formData[index].newToTimeFormate.mm +
              formData[index].newToTimeFormate.ap +
              'm'
          };
        }
      }
    });
    // New time format end

    const actionDataAppend = [];
    const groupFormData = JSON.parse(JSON.stringify(formData));
    const groupEntryID = generateFileHash(20);
    const newFormData = groupFormData.map((entry) => {
      if (entry.forDropDownData) {
        delete entry.forDropDownData;
      }
      if (entry.yearDropDownData) {
        delete entry.yearDropDownData;
      }
      return entry;
    });

    let updatedFormData = [];
    const primary = newFormData.find((o) => o.isPrimary === true);
    const linked = newFormData.filter((o) => o.isPrimary === false);
    const primaryPerYear = [];
    const linkedPerYear = [];

    if (newFormData && newFormData[0].groups && newFormData[0].groups.length) {
      // Common entry for a free group
      const primaryFor = primary.for;
      const bulkID = generateFileHash(20);
      const primaryID = generateFileHash(20);
      const linkedIdArray = [];
      linked.forEach((entry) => {
        const linkedId = generateFileHash(20);
        linkedPerYear.push({
          ...entry,
          id: linkedId,
          for: 'group',
          forYears: entry.for,
          primaryID: primaryID,
          bulkID: bulkID
        });
        linkedIdArray.push(linkedId);
      });
      primaryPerYear.push({
        ...primary,
        id: primaryID,
        for: 'group',
        forYears: primaryFor,
        bulkID: bulkID,
        linkedArray: linkedIdArray
      });
    } else {
      // First create seperate entry for each year
      if (primary.for.includes('all')) {
        primary.for = ['all'];
      }
      primary.for.forEach((year) => {
        const bulkID = generateFileHash(20);
        const primaryID = generateFileHash(20);
        const linkedIdArray = [];
        linked.forEach((entry) => {
          if (entry.for.length) {
            if (entry.for.includes('all')) {
              const linkedId = generateFileHash(20);
              linkedPerYear.push({
                ...entry,
                id: linkedId,
                for: 'all',
                primaryID: primaryID,
                bulkID: bulkID
              });
              linkedIdArray.push(linkedId);
            } else {
              entry.for.map((entryData) => {
                if (year === entryData || year === 'all') {
                  const linkedId = generateFileHash(20);
                  linkedPerYear.push({
                    ...entry,
                    id: linkedId,
                    for: entryData,
                    primaryID: primaryID,
                    bulkID: bulkID
                  });
                  linkedIdArray.push(linkedId);
                }
              });
            }
          } else {
            const linkedId = generateFileHash(20);
            linkedPerYear.push({
              ...entry,
              id: linkedId,
              for: year,
              primaryID: primaryID,
              bulkID: bulkID
            });
            linkedIdArray.push(linkedId);
          }
        });
        primaryPerYear.push({
          ...primary,
          id: primaryID,
          for: year,
          bulkID: bulkID,
          linkedArray: linkedIdArray
        });
      });
    }
    updatedFormData = [...primaryPerYear, ...linkedPerYear];

    const updateFormData = updatedFormData.map((data, index) => {
      data.schoolId = data.school;
      if (index === 0) {
        data.groupEntryID = groupEntryID;
      } else {
        data.groupEntryID = groupEntryID;
        data.school = formData[0].school;
        // data.for = formData[0].for;
        data.assign = formData[0].assign;
      }
      if (data.isPrimary === false) {
        data.isLinked = true;
      } else {
        data.isLinked = false;
      }
      if (data.actions.reminder.length) {
        data.actions.reminder.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Reminder',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            isLinked: false,
            primaryID: data.isPrimary ? data.id : data.primaryID,
            actions: {
              ...data.actions,
              notification:
                data.actions.reminderNotification &&
                data.actions.reminderNotification[index]
                  ? [data.actions.reminderNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.actions.deadline.length) {
        data.actions.deadline.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Deadline',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            isLinked: false,
            primaryID: data.isPrimary ? data.id : data.primaryID,
            actions: {
              ...data.actions,
              notification:
                data.actions.deadlineNotification &&
                data.actions.deadlineNotification[index]
                  ? [data.actions.deadlineNotification[index]]
                  : []
            }
          });
        });
      }

      // delete data.school;
      return data;
    });

    const final = [...updateFormData, ...actionDataAppend];
    await _addSchoolEntry(final);
  };

  const submitNewFamiliesForm = (formData) => {
    // New time format
    formData.forEach((entry, index) => {
      if (entry.allDay) {
        formData[index].from = { ...formData[index].from, time: '12:30am' };
        formData[index].to = { ...formData[index].to, time: '12:30am' };
      } else {
        if (formData[index].newFromTimeFormate) {
          formData[index].newFromTimeFormate = {
            hh: formData[index].newFromTimeFormate.hh,
            mm:
              formData[index].newFromTimeFormate.mm.toString().length === 1
                ? '0' + formData[index].newFromTimeFormate.mm
                : formData[index].newFromTimeFormate.mm.toString(),
            ap:
              formData[index].newFromTimeFormate.ap.toLowerCase() === 'a' ||
              formData[index].newFromTimeFormate.ap.toLowerCase() === 'p'
                ? formData[index].newFromTimeFormate.ap.toLowerCase()
                : 'a'
          };
          formData[index].newToTimeFormate = {
            hh: formData[index].newToTimeFormate.hh,
            mm:
              formData[index].newToTimeFormate.mm.toString().length === 1
                ? '0' + formData[index].newToTimeFormate.mm
                : formData[index].newToTimeFormate.mm.toString(),
            ap:
              formData[index].newToTimeFormate.ap.toLowerCase() === 'a' ||
              formData[index].newToTimeFormate.ap.toLowerCase() === 'p'
                ? formData[index].newToTimeFormate.ap.toLowerCase()
                : 'p'
          };

          formData[index].from = {
            date: formData[index].from.date,
            time:
              formData[index].newFromTimeFormate.hh +
              ':' +
              formData[index].newFromTimeFormate.mm +
              formData[index].newFromTimeFormate.ap +
              'm'
          };
          formData[index].to = {
            date: formData[index].to.date,
            time:
              formData[index].newToTimeFormate.hh +
              ':' +
              formData[index].newToTimeFormate.mm +
              formData[index].newToTimeFormate.ap +
              'm'
          };
        }
      }
    });
    // New time format end

    const groupFormData = JSON.parse(JSON.stringify(formData));
    const groupEntryID = generateFileHash(20);
    const groupEntriesData = JSON.parse(
      JSON.stringify({
        entries: groupFormData,
        groupEntryID: groupEntryID,
        groupType: groupFormData[0].groupType,
        docId: 'newId'
      })
    );
    const newFormData = groupFormData.map((entry) => {
      if (entry.forDropDownData) {
        delete entry.forDropDownData;
      }
      if (entry.yearDropDownData) {
        delete entry.yearDropDownData;
      }
      return entry;
    });

    const primaryEntries = [];
    const linkedEntries = [];
    // start create separate primary and linked entry from the forms->for and bind assign, for
    newFormData.map((forms) => {
      if (forms.isPrimary) {
        forms.for.map((entry) => {
          if (entry !== 'Select All Parents') {
            const foundFamily = family.find(
              (e) =>
                (e.type === 'parent' || e.type === 'child') && e.id === entry
            );
            if (foundFamily) {
              let childArray = [];
              if (forms.assignToChild) {
                const foundSurameFamily = familySurnameWise.find(
                  (surnameData) => surnameData.familyId === foundFamily.familyId
                );
                if (foundSurameFamily) {
                  foundSurameFamily.childs.forEach((child) => {
                    const foundChilds = family.find((e) => e.id === child.id);
                    if (foundChilds) {
                      childArray = [...childArray, child.id];
                    }
                  });
                }
              }
              const newForm1 = {
                ...forms,
                assign: childArray,
                family: foundFamily.familyId,
                for: foundFamily.type === 'parent' ? 'Parents' : foundFamily.id
              };
              primaryEntries.push(newForm1);
            }
          }
        });
      } else {
        forms.for.map((linkFor) => {
          if (linkFor !== 'Select All Parents') {
            const foundLinkFamily = family.find(
              (fam) =>
                (fam.type === 'parent' || fam.type === 'child') &&
                fam.id === linkFor
            );
            if (foundLinkFamily) {
              let childArray = [];
              if (forms.assignToChild) {
                const foundSurameFamily = familySurnameWise.find(
                  (surnameData) =>
                    surnameData.familyId === foundLinkFamily.familyId
                );
                if (foundSurameFamily) {
                  foundSurameFamily.childs.forEach((child) => {
                    const foundChilds = family.find((e) => e.id === child.id);
                    if (foundChilds) {
                      childArray = [...childArray, child.id];
                    }
                  });
                }
              }
              const newForm2 = {
                ...forms,
                assign: childArray,
                family: foundLinkFamily.familyId,
                for:
                  foundLinkFamily.type === 'parent'
                    ? 'Parents'
                    : foundLinkFamily.id
              };
              linkedEntries.push(newForm2);
            }
          }
        });
      }
    });
    // start create separate primary and linked entry from the forms->for and bind assign, for
    const primary = JSON.parse(JSON.stringify(primaryEntries));
    const linked = JSON.parse(JSON.stringify(linkedEntries));
    const newPrimary = [];
    const newLinked = [];
    // start - assign unique primary and linkedid
    primary.map((pentry) => {
      const primaryID = generateFileHash(20);
      const linkedIdArray = [];
      linked.map((linkEntry) => {
        if (pentry.family === linkEntry.family) {
          const linkedId = generateFileHash(20);
          // const checkChildExists = newLinked.filter(item => item.family === linkEntry.family && item.for !== "Parents" && item.for === linkEntry.for && item.title === linkEntry.title);
          // const checkParentsExists = newLinked.filter(item => item.family === linkEntry.family && item.for === 'Parents' && item.title === linkEntry.title);
          // if(checkParentsExists.length){
          // push already exist linkedentry id for parents
          // const previousLinkedId = checkParentsExists[0].id;
          // linkedIdArray.push(previousLinkedId);
          // }else{
          // if(checkChildExists.length){
          // }else{
          linkedIdArray.push(linkedId);
          const newLink = JSON.parse(JSON.stringify(linkEntry));
          newLink.id = linkedId;
          newLink.primaryID = primaryID;
          const newData = JSON.parse(JSON.stringify(newLink));
          newLinked.push(newData);
          // }
          // }
        }
        return linkEntry;
      });
      newPrimary.push({
        ...pentry,
        id: primaryID,
        linkedArray: linkedIdArray
      });
      return pentry;
    });

    const newPrimaryEntry = JSON.parse(JSON.stringify(newPrimary));
    const newLinkedEntry = JSON.parse(JSON.stringify(newLinked));
    // start - assign unique primary and linkedid
    const updatedFormData = [...newPrimaryEntry, ...newLinkedEntry];
    const newGroup = _.groupBy(updatedFormData, 'family');
    const updatedGroup = [];
    Object.values(newGroup).map((item) => {
      updatedGroup.push({
        familyId: item[0].family,
        finalData: item
      });
    });

    dispatch(loaderStart());
    // submit formdata in groupEntries collection
    firebase
      .addNewGroupEntries(groupEntriesData)
      .then(() => console.log('.'))
      .catch((error) => console.log('error', error));
    updatedGroup.forEach((family) => {
      family.finalData = submitNewFamilyForm(family.finalData, groupEntryID);
      family.finalData.entries.map(async (entry) => {
        addEntry({ ...entry, familyId: family.finalData.familyId });
      });
    });
  };

  const addEntry = (finalData) => {
    firebase
      // .addNewEntries(finalData)
      .addDataEntries(finalData)
      .then(
        () => {
          dispatch(addMessage('Group Family Entry Created'));
          dispatch(setPreserveEntryData(null));
          dispatch(loaderStop());
          onClose();
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(
            addMessage(
              'Entry was not created. Please try again',
              MESSAGE_TYPES.ERROR
            )
          );
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(
          addMessage(
            'Entry was not created. Please try again',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });
  };

  const submitNewFamilyForm = (newFormData, groupId = null) => {
    const formData = JSON.parse(JSON.stringify(newFormData));
    const actionDataAppend = [];
    const primary = formData.find((o) => o.isPrimary === true);
    const groupEntryID = groupId ? groupId : generateFileHash(20);
    const bulkID = generateFileHash(20);
    const updateFormData = formData.map((data, index) => {
      data.bulkID = bulkID;
      if (data.label === '') {
        // data.label = 'none';
      }
      if (index === 0) {
        data.groupEntryID = groupEntryID;
      } else {
        data.groupEntryID = groupEntryID;
        data.family = formData[0].family;
        // data.for = formData[0].for;
        // data.assign = formData[0].assign;
      }
      if (data.isPrimary === false) {
        data.isLinked = true;
        // data.primaryID = primary.id;
      } else {
        data.isLinked = false;
      }
      if (data.actions.notification.length) {
        // Remain
      }
      if (data.actions.reminder.length) {
        data.actions.reminder.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Reminder',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            isLinked: false,
            primaryID: primary.id,
            actions: {
              ...data.actions,
              notification:
                data.actions.reminderNotification &&
                data.actions.reminderNotification[index]
                  ? [data.actions.reminderNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.actions.deadline.length) {
        data.actions.deadline.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Deadline',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            isLinked: false,
            primaryID: primary.id,
            actions: {
              ...data.actions,
              notification:
                data.actions.deadlineNotification &&
                data.actions.deadlineNotification[index]
                  ? [data.actions.deadlineNotification[index]]
                  : []
            }
          });
        });
      }

      delete data.school;
      return data;
    });

    const final = [...updateFormData, ...actionDataAppend];

    const finalData = {
      familyId: primary.family,
      entries: final,
      docId: 'newId',
      parentID: primary.id,
      isUpdate: false
    };
    return finalData;
  };

  const submitNewFamiliesGroupForm = (formData) => {
    // New time format
    formData.forEach((entry, index) => {
      if (entry.allDay) {
        formData[index].from = { ...formData[index].from, time: '12:30am' };
        formData[index].to = { ...formData[index].to, time: '12:30am' };
      } else {
        if (formData[index].newFromTimeFormate) {
          formData[index].newFromTimeFormate = {
            hh: formData[index].newFromTimeFormate.hh,
            mm:
              formData[index].newFromTimeFormate.mm.toString().length === 1
                ? '0' + formData[index].newFromTimeFormate.mm
                : formData[index].newFromTimeFormate.mm.toString(),
            ap:
              formData[index].newFromTimeFormate.ap.toLowerCase() === 'a' ||
              formData[index].newFromTimeFormate.ap.toLowerCase() === 'p'
                ? formData[index].newFromTimeFormate.ap.toLowerCase()
                : 'a'
          };
          formData[index].newToTimeFormate = {
            hh: formData[index].newToTimeFormate.hh,
            mm:
              formData[index].newToTimeFormate.mm.toString().length === 1
                ? '0' + formData[index].newToTimeFormate.mm
                : formData[index].newToTimeFormate.mm.toString(),
            ap:
              formData[index].newToTimeFormate.ap.toLowerCase() === 'a' ||
              formData[index].newToTimeFormate.ap.toLowerCase() === 'p'
                ? formData[index].newToTimeFormate.ap.toLowerCase()
                : 'p'
          };

          formData[index].from = {
            date: formData[index].from.date,
            time:
              formData[index].newFromTimeFormate.hh +
              ':' +
              formData[index].newFromTimeFormate.mm +
              formData[index].newFromTimeFormate.ap +
              'm'
          };
          formData[index].to = {
            date: formData[index].to.date,
            time:
              formData[index].newToTimeFormate.hh +
              ':' +
              formData[index].newToTimeFormate.mm +
              formData[index].newToTimeFormate.ap +
              'm'
          };
        }
      }
    });
    // New time format end

    const groupFormData = JSON.parse(JSON.stringify(formData));
    const groupEntryID = generateFileHash(20);
    const groupEntriesData = JSON.parse(
      JSON.stringify({
        entries: groupFormData,
        groupEntryID: groupEntryID,
        groupType: groupFormData[0].groupType,
        docId: 'newId'
      })
    );
    // submit formdata in groupEntries collection
    firebase
      .addNewGroupEntries(groupEntriesData)
      .then(() => console.log('.'))
      .catch((error) => console.log('error', error));

    const newFormData = groupFormData.map((entry) => {
      if (entry.forDropDownData) {
        delete entry.forDropDownData;
      }
      if (entry.yearDropDownData) {
        delete entry.yearDropDownData;
      }
      return entry;
    });

    groupFamily.forEach((family) => {
      const primary = newFormData.find((o) => o.isPrimary === true);
      const linked = newFormData.filter((o) => o.isPrimary === false);

      const primaryNewEntries = [];
      const linkedNewEntries = [];
      let updateGroupFormData = [];

      if (primary.for.includes('Parents')) {
        const primaryID = generateFileHash(20);
        const linkedIdArray = [];
        linked.forEach((linkEntry) => {
          if (linkEntry.for.length && linkEntry.for.includes('Parents')) {
            const linkedId = generateFileHash(20);
            linkedIdArray.push(linkedId);
            let assign = [];
            if (linkEntry.assignToChild) {
              assign = _.map(family.childs, 'id');
            }
            linkedNewEntries.push({
              ...linkEntry,
              id: linkedId,
              for: 'Parents',
              primaryID,
              assign
            });
          } else {
            family.childs.forEach((child) => {
              const assign = linkEntry.assignToChild ? [child.id] : [];
              const linkedId = generateFileHash(20);
              linkedIdArray.push(linkedId);
              const linkChildObj = {
                ...linkEntry,
                for: child.id,
                id: linkedId,
                primaryID,
                assign
              };
              linkedNewEntries.push(linkChildObj);
            });
          }
        });

        const assign = primary.assignToChild ? _.map(family.childs, 'id') : [];
        primaryNewEntries.push({
          ...primary,
          family: family.familyId,
          for: 'Parents',
          id: primaryID,
          linkedArray: linkedIdArray,
          assign
        });
      } else {
        family.childs.map((child) => {
          const primaryID = generateFileHash(20);
          const linkedIdArray = [];
          linked.map((linkEntry) => {
            const linkedId = generateFileHash(20);
            linkedIdArray.push(linkedId);
            const assign = linkEntry.assignToChild ? [child.id] : [];
            if (linkEntry.for.includes('Parents')) {
              linkedNewEntries.push({
                ...linkEntry,
                id: linkedId,
                for: 'Parents',
                primaryID: primaryID,
                assign,
                childID: child.id
              });
            } else {
              linkedNewEntries.push({
                ...linkEntry,
                id: linkedId,
                for: child.id,
                primaryID: primaryID,
                assign,
                childID: child.id
              });
            }
          });
          const primaryAssign = primary.assignToChild ? [child.id] : [];
          primaryNewEntries.push({
            ...primary,
            family: family.familyId,
            for: child.id,
            id: primaryID,
            linkedArray: linkedIdArray,
            assign: primaryAssign,
            childID: child.id
          });
          return child;
        });
      }

      updateGroupFormData = [...primaryNewEntries, ...linkedNewEntries];
      const finalFormData = JSON.parse(JSON.stringify(updateGroupFormData));
      family.finalData = submitNewFamilyForm(finalFormData, groupEntryID);
      dispatch(loaderStart());

      family.finalData.entries.map(async (entry) => {
        addEntry({ ...entry, familyId: family.finalData.familyId });
      });
    });
  };

  const _submitForm = async (formData) => {
    // For Free entry same as school
    if (
      formData[0].groupType === 'Free' &&
      formData[0].school &&
      formData[0].for.length
    ) {
      submitNewSchoolForm(formData);
    } else if (
      formData[0].groupType === 'Families' &&
      formData[0].school &&
      formData[0].for.length &&
      formData[0].years.length
    ) {
      submitNewFamiliesForm(formData);
    } else if (
      formData[0].groupType === 'Group' &&
      formData[0].groups &&
      formData[0].groups.length &&
      formData[0].for
    ) {
      submitNewFamiliesGroupForm(formData);
    }
  };

  const _addSchoolEntry = async (finalData) => {
    dispatch(loaderStart());
    manageSchoolEntries(finalData)
      .then(
        async () => {
          await finalData.forEach((entryItem) => {
            if (entryItem.label === 'Alert') {
              const requestOptions = {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  schoolId: entryItem.schoolId,
                  entryId: entryItem.id,
                  isFree:
                    hasPreserveEntryData?.entries?.[0]?.groupType === 'Free'
                      ? true
                      : false
                })
              };
              fetch(
                `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/sendInstantPN`,
                requestOptions
              )
                .then((response) => {
                  const result = response.json();
                  if (!response.ok) {
                    throw new Error(result?.message);
                  }
                  return result;
                })
                .then(() => console.log('.'));
            }
          });
          dispatch(loaderStop());
          dispatch(addMessage('Group Entry Created'));
          dispatch(setPreserveEntryData(null));
          onClose();
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(
            addMessage(
              'Entry was not created. Please try again',
              MESSAGE_TYPES.ERROR
            )
          );
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(
          addMessage(
            'Entry was not created. Please try again',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });
  };

  return (
    <RightDrawer isOpen={isOpen} onClose={onClose} width={900}>
      <Formik
        enableReinitialize={true}
        initialValues={initialEntry}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let primaryDesc = '';
          const updatedValues = values.entries.map((entry, index) => {
            if (entry.primaryEventTo) {
              delete entry.primaryEventTo;
            }
            if (entry.primaryEventFrom) {
              delete entry.primaryEventFrom;
            }
            if (entry.customType) {
              delete entry.customType;
            }
            if (entry.forDropDownData) {
              // delete entry.forDropDownData;
            }
            if (entry.yearDropDownData) {
              // delete entry.yearDropDownData;
            }
            // selection logic start
            if (entry.actions.notification.length) {
              const formateValue = 'MMM DD,YYYY';
              entry.actions.notification.forEach((item) => {
                const fromDate = moment(entry.from.date, formateValue);
                const notificationDate = moment(
                  item.date ? item.date : entry.from.date,
                  formateValue
                );
                let dayValue = '';
                if (moment(notificationDate).isSame(fromDate)) {
                  dayValue = '';
                } else {
                  dayValue = notificationDate.diff(fromDate, 'days');
                }
                item.days = dayValue;
              });
            }
            // selection logic end
            // remove deadline and reminder logic start
            if (entry.actions.isComplete) {
              // entry.actions.deadline = []
              // entry.actions.reminder = []
              if (entry.actions.deadlineNotification)
                delete entry.actions.deadlineNotification;
              if (entry.actions.reminderNotification)
                delete entry.actions.reminderNotification;
            }
            // remove deadline and reminder logic end
            // linked entry description logic start
            if (entry.description && index === 0) {
              primaryDesc = toGetHTMLContent(entry.description).replaceAll(
                '</a>&nbsp;<',
                '</a><'
              );
            }
            // linked entry description logic end
            return {
              ...entry,
              agentName: currentUser.firstName,
              expand: index === 0 ? true : false,
              primary_description:
                index !== 0
                  ? {
                      description: primaryDesc ? primaryDesc : '',
                      from: values.entries[0].from
                    }
                  : {},
              primaryID: index !== 0 ? values.entries[0].id : '',
              createdAt: moment().format(),
              description: entry.description
                ? toGetHTMLContent(entry.description).replaceAll(
                    '</a>&nbsp;<',
                    '</a><'
                  )
                : ''
            };
          });
          _submitForm(updatedValues);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue
        }) => {
          setUpdatedEntry(values);
          return (
            <>
              {
                <Backdrop className={classes.backdrop} open={isLoading}>
                  <CircularProgress color="primary" />
                </Backdrop>
              }
              <form noValidate onSubmit={handleSubmit}>
                <div className={classes.headerContainer}>
                  <div className={classes.headerTitleContainer}>
                    <FamiliesIcon
                      color={colors.primaryDarkGrey}
                      className={classes.familyIconStyle}
                    />
                    <Typography variant="h6" className={classes.titleText}>
                      Group Entry
                    </Typography>
                  </div>
                  <div className={classes.headerButtonContainer}>
                    <Button
                      className={classes.deleteButton}
                      onClick={() => {
                        setOpenDeleteModal(true);
                      }}
                    >
                      Delete
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.publishButton}
                    >
                      Publish
                    </Button>
                  </div>
                </div>
                <div style={{ position: 'relative' }}>
                  {values.entries.length &&
                  values.entries[0] &&
                  (values.entries[0].actions.isCancelled ||
                    values.entries[0].actions.isPostponed ||
                    values.entries[0].actions.isChanged) ? (
                    <Chip
                      size="small"
                      label={
                        <span style={{ fontSize: 12 }}>
                          {values.entries[0].actions.isPostponed
                            ? 'POSTPONED'
                            : values.entries[0].actions.isCancelled
                              ? 'CANCELLED'
                              : 'UPDATED'}
                        </span>
                      }
                      classes={{
                        labelSmall: classes.chipLabel,
                        root: classes.chipRoot
                      }}
                      style={{
                        marginRight: 10,
                        backgroundColor:
                          values.entries[0].actions.isCancelled ||
                          values.entries[0].actions.isPostponed
                            ? colors.tertiaryRed
                            : colors.primaryDarkGrey
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <Divider variant="fullWidth" />
                <div className={classes.drawerLeftContent}>
                  <div className={classes.leftContentMargin}>
                    {values.entries.map((entry, index) => (
                      <Accordion
                        style={{ margin: 10, boxShadow: 'none' }}
                        key={`expand${index}`}
                        expanded={
                          values.entries.length === 1 ? true : entry.expand
                        }
                        onChange={() =>
                          values.entries.length > 1 &&
                          handleExpandChange(index, values)
                        }
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className={classes.whiteText} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            backgroundColor:
                              values.entries.length !== 1
                                ? entry.expand && index !== 0
                                  ? colors.primaryDarkGrey7
                                  : colors.primaryDarkGrey
                                : null,
                            height:
                              values.entries.length !== 1
                                ? entry.expand
                                  ? 56
                                  : 60
                                : null
                          }}
                        >
                          {index === 0 ? (
                            <Typography
                              className={clsx(
                                classes.titleAccordionText,
                                values.entries.length > 1
                                  ? classes.whiteText
                                  : classes.darkPrimaryText
                              )}
                            >
                              Primary Entry
                            </Typography>
                          ) : (
                            <>
                              <CornerDownRight
                                className={classes.whiteText}
                                style={{ marginTop: 5 }}
                              />
                              <Typography
                                className={clsx(
                                  classes.titleAccordionText,
                                  classes.whiteText
                                )}
                                style={{
                                  fontSize: 18,
                                  lineHeight: 2.2,
                                  fontWeight: 'bold'
                                }}
                              >
                                Linked Entry {index}
                              </Typography>
                            </>
                          )}
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            backgroundColor:
                              values.entries.length > 1
                                ? colors.secondaryGreyLight
                                : ''
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <div
                              style={{ flexDirection: 'row', display: 'flex' }}
                            >
                              <FormControl
                                variant="outlined"
                                className={classes.formLabelControl}
                                // style={{ height: 40 }}
                              >
                                <InputLabel id="select-outlined-label">
                                  Label
                                </InputLabel>
                                <Select
                                  labelId="select-outlined-label"
                                  name={`entries[${index}].label`}
                                  onBlur={handleBlur}
                                  // onChange={handleChange}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `entries[${index}].label`,
                                      e.target.value
                                    );
                                  }}
                                  value={
                                    (values.entries[index] &&
                                      values.entries[index].label) ||
                                    'none'
                                  }
                                  label="Label"
                                >
                                  {labels.map((label, index) => (
                                    <MenuItem
                                      key={`${label.value}-${index}`}
                                      value={label.value}
                                    >
                                      {label.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      values.entries[index].isLowPriority
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `entries[${index}].isLowPriority`,
                                        e.target.checked
                                      );
                                    }}
                                    classes={{
                                      switchBase: classes.switchBase,
                                      thumb: classes.thumb,
                                      track: classes.track,
                                      checked: classes.checked
                                    }}
                                  />
                                }
                                label="Low Priority"
                                style={{
                                  width: '56%',
                                  // marginTop: 13,
                                  display: 'flex',
                                  marginLeft: 10,
                                  marginTop: 5
                                }}
                              />
                              {taskLabels.includes(
                                values.entries[index].label
                              ) && (
                                <div
                                  style={{
                                    width: '56%',
                                    marginTop: 13,
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }}
                                >
                                  <Checkbox
                                    color="primary"
                                    className={classes.iconButton}
                                    checked={
                                      (values.entries[index] &&
                                        values.entries[index].actions
                                          .isComplete) ||
                                      false
                                    }
                                    name={`entries[${index}].actions.isComplete`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </div>
                              )}
                            </div>
                            <FormControl component="fieldset">
                              <TextField
                                label="Add Title"
                                variant="outlined"
                                className={classes.inputControlBox}
                                name={`entries[${index}].title`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  (values.entries[index] &&
                                    values.entries[index].title) ||
                                  ''
                                }
                                error={Boolean(
                                  touched.entries &&
                                    errors.entries &&
                                    touched.entries[index] &&
                                    touched.entries[index].title &&
                                    errors.entries[index] &&
                                    errors.entries[index].title
                                )}
                              />
                            </FormControl>
                            {/* {index === 0 && ( */}
                            <FormControl component="fieldset">
                              <TextField
                                label="Add location"
                                placeholder="Add Location"
                                variant="outlined"
                                className={classes.inputControlBox}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        src={LocationIcon}
                                        style={{ opacity: 0.7 }}
                                      />
                                    </InputAdornment>
                                  )
                                }}
                                name={`entries[${index}].location`}
                                onBlur={(e) => {
                                  setFieldValue(
                                    `entries[${index}].location`,
                                    e.target.value.trim().split(/ +/).join(' ')
                                  );
                                  handleBlur(e);
                                }}
                                onChange={handleChange}
                                value={
                                  (values.entries[index] &&
                                    values.entries[index].location) ||
                                  ''
                                }
                              />
                            </FormControl>
                            {/* )} */}
                            <FormControl component="fieldset">
                              <EditorComponent
                                classes={classes}
                                preserveData={
                                  values.entries[index]?.description
                                }
                                wrapperClassName={clsx(
                                  values.entries[index] &&
                                    values.entries[index].checkDescription
                                    ? classes.wrapperClassName
                                    : classes.wrapperClassNameWithBottomBorder
                                )}
                                index={index}
                                onChange={(e, index) =>
                                  onEditorChange(e, index, values)
                                }
                              />
                            </FormControl>
                            {values.entries[index] &&
                            values.entries[index].checkDescription ? (
                              <>
                                <div className={classes.descriptionContainer}>
                                  <div
                                    className={classes.descriptionMainContainer}
                                  >
                                    <img
                                      src={EditPencil}
                                      alt=""
                                      className={
                                        classes.descriptionImageContainer
                                      }
                                    />
                                    <p
                                      className={
                                        classes.descriptionTextContainer
                                      }
                                    >
                                      Created on{' '}
                                      {values.entries[index].createdAt
                                        ? moment(
                                            values.entries[index].createdAt
                                          ).format('DD MMM YYYY')
                                        : moment().format('DD MMM YYYY')}{' '}
                                      by {values.entries[index].agentName}
                                    </p>
                                  </div>
                                  <hr
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      borderTop: `dotted 3px ${colors.primaryLightGrey}`,
                                      width: 570
                                    }}
                                  />
                                  <div
                                    className={classes.descriptionMainContainer}
                                  >
                                    <img
                                      src={Calendar}
                                      alt=""
                                      className={
                                        classes.descriptionImageContainer
                                      }
                                    />
                                    <p
                                      className={
                                        classes.descriptionTextContainer
                                      }
                                    >
                                      Relates to:{' '}
                                      {values.entries[0].from.date
                                        ? moment(
                                            values.entries[0].from.date
                                          ).format('DD MMM YYYY')
                                        : moment().format('DD MMM YYYY')}{' '}
                                      entry
                                    </p>
                                  </div>
                                </div>
                                <Editor
                                  readOnly={true}
                                  editorState={defaultNote}
                                  toolbarClassName={classes.toolbarClassName}
                                  editorClassName={classes.editorClassName}
                                  wrapperClassName={
                                    classes.wrapperSecondEditorClassName
                                  }
                                  toolbar={{
                                    options: [],
                                    inline: {
                                      options: []
                                    }
                                  }}
                                  // editorState={}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            {values.entries[index] &&
                            values.entries[index].isPrimary ? (
                              <>
                                <div
                                  className={classes.descriptionContainer}
                                  style={{
                                    borderBottomLeftRadius: 5,
                                    borderBottomRightRadius: 5,
                                    borderBottom: `1px solid ${colors.primaryLightGrey}`
                                  }}
                                >
                                  <div
                                    className={classes.descriptionMainContainer}
                                  >
                                    <img
                                      src={EditPencil}
                                      alt=""
                                      className={
                                        classes.descriptionImageContainer
                                      }
                                    />
                                    <p
                                      className={
                                        classes.descriptionTextContainer
                                      }
                                    >
                                      Created on{' '}
                                      {values.entries[index].createdAt
                                        ? moment(
                                            values.entries[index].createdAt
                                          ).format('DD MMM YYYY')
                                        : moment().format('DD MMM YYYY')}{' '}
                                      by {values.entries[index].agentName}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {index !== 0 ? (
                              <div
                                style={{
                                  marginTop: 24,
                                  marginLeft: 10,
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        (values.entries[index] &&
                                          values.entries[index]
                                            .checkDescription) ||
                                        false
                                      }
                                      onChange={() =>
                                        handleChangeCheck(
                                          'checkDescription',
                                          index,
                                          values
                                        )
                                      }
                                      name={`entries[${index}].checkDescription`}
                                    />
                                  }
                                  label="Include main description"
                                  className={classes.includeText}
                                />
                                <DeleteOutlineOutlinedIcon
                                  onClick={() =>
                                    setOpenDeleteLinkedModal({
                                      open: true,
                                      id: index
                                    })
                                  }
                                  className={classes.iconTrashStyle}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                  <div>
                    <Button
                      className={classes.linkedButton}
                      startIcon={<AddIcon color={colors.primaryDarkGrey} />}
                      variant="contained"
                      onClick={() => addLinkedEntry(values)}
                      color={colors.primaryDarkGrey}
                    >
                      Add Linked Entry
                    </Button>
                  </div>
                </div>
                <div className={classes.drawerRightContent}>
                  <Divider
                    variant="fullWidth"
                    orientation="vertical"
                    style={{ float: 'right' }}
                  />
                  {values.entries.map((entry, index) => (
                    <Fragment key={`right-content-${index}`}>
                      {values.entries.length === 1 || entry.expand ? (
                        <div
                          style={{
                            padding: '0 10px',
                            backgroundColor: colors.tertiary
                          }}
                        >
                          {index === 0 ? (
                            <FormControl
                              component="fieldset"
                              style={{ marginTop: 10 }}
                            >
                              <RadioGroup
                                aria-label="groupType"
                                name={`entries[${index}].groupType`}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (
                                    e.target.value === 'Families' ||
                                    e.target.value === 'Group'
                                  ) {
                                    getFamilies();
                                  } else {
                                    getFreeSchools();
                                  }
                                  const updateInitialValueEntry =
                                    values.entries.map((entry) => {
                                      return {
                                        ...entry,
                                        groupType: e.target.value,
                                        school: '',
                                        yearDropDownData: [],
                                        forDropDownData: [],
                                        for: [],
                                        years: [],
                                        groups: []
                                      };
                                    });
                                  setInitialEntry({
                                    entries: updateInitialValueEntry
                                  });
                                  setYears([]);
                                  setFamily([]);
                                  setGroups([]);
                                }}
                                onBlur={handleBlur}
                                className={classes.groupRadioClass}
                                value={
                                  (values.entries[index] &&
                                    values.entries[index].groupType) ||
                                  ''
                                }
                              >
                                {groupEntryRadio.map((object) => {
                                  return (
                                    <FormControlLabel
                                      key={object.label}
                                      value={object.value}
                                      control={
                                        <Radio
                                          classes={{
                                            root: classes.radio,
                                            checked: classes.checked
                                          }}
                                        />
                                      }
                                      label={object.label}
                                      className={classes.controlBox}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            </FormControl>
                          ) : (
                            <></>
                          )}
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center'
                            }}
                          >
                            <div>
                              {index === 0 ? (
                                <>
                                  <FormControl
                                    variant="outlined"
                                    className={classes.formLabelControl}
                                  >
                                    <InputLabel id="demo-simple-select-outlined-school">
                                      School
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-school"
                                      id="demo-simple-select-outlined"
                                      name={`entries[${index}].school`}
                                      onBlur={handleBlur}
                                      onChange={(e) => {
                                        if (e.target.value !== '') {
                                          handleChange(e);
                                          schoolChangeHandle(
                                            e,
                                            index,
                                            values,
                                            setFieldValue
                                          );
                                        }
                                      }}
                                      value={
                                        schools &&
                                        schools.length &&
                                        values.entries[index]
                                          ? values.entries[index].school || ''
                                          : values.entries[index].school || ''
                                      }
                                      label="school"
                                      error={Boolean(
                                        touched.entries &&
                                          errors.entries &&
                                          touched.entries[index] &&
                                          touched.entries[index].school &&
                                          errors.entries[index] &&
                                          errors.entries[index].school
                                      )}
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      {schools &&
                                        schools.length &&
                                        values.entries[index].groupType ===
                                          'Families' &&
                                        schools.map(
                                          (school, forIndex) => (
                                            // school.freeCalLength > 0 && (
                                            <MenuItem
                                              key={`school-select-${forIndex}`}
                                              value={school.id}
                                            >
                                              {school.name}
                                            </MenuItem>
                                          )
                                          // )
                                        )}
                                      {schools &&
                                        schools.length &&
                                        values.entries[index].groupType ===
                                          'Group' &&
                                        schools.map(
                                          (school, forIndex) =>
                                            school.groups > 0 && (
                                              <MenuItem
                                                key={`school-select-${forIndex}`}
                                                value={school.id}
                                              >
                                                {school.name}
                                              </MenuItem>
                                            )
                                        )}
                                      {schools &&
                                        schools.length &&
                                        values.entries[index].groupType ===
                                          'Free' &&
                                        schools.map(
                                          (school, forIndex) =>
                                            school.freeCalLength > 0 && (
                                              <MenuItem
                                                key={`school-select-${forIndex}`}
                                                value={school.id}
                                              >
                                                {school.name}
                                              </MenuItem>
                                            )
                                        )}
                                    </Select>
                                  </FormControl>
                                  {values.entries[index].groupType ===
                                  'Families' ? (
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formLabelControl}
                                      disabled={!years.length}
                                    >
                                      <InputLabel id="demo-simple-select-outlined-label-years">
                                        Years
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-outlined-label-years"
                                        id="demo-simple-select-outlined-years"
                                        multiple
                                        name={`entries.[${index}].years`}
                                        onBlur={handleBlur}
                                        // renderValue={(selected) => selected.join(', ')}
                                        renderValue={(selected) => {
                                          const familyName = years.map(
                                            (data) => {
                                              if (
                                                selected.includes(data.value)
                                              ) {
                                                return data.key;
                                              } else {
                                                return null;
                                              }
                                            }
                                          );
                                          const familyNameFilter =
                                            familyName.filter(
                                              (data) => data && data
                                            );
                                          return familyNameFilter.join(', ');
                                        }}
                                        onChange={(e) => {
                                          handleChange(e);

                                          handleChangeFreeYear(
                                            e,
                                            index,
                                            values.entries,
                                            setFieldValue
                                          );
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index].years) ||
                                          ''
                                        }
                                        label="Years"
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index].years &&
                                            errors.entries[index] &&
                                            errors.entries[index].years
                                        )}
                                      >
                                        {/* <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem> */}
                                        {years &&
                                          years.length &&
                                          years.map((yearData, forIndex) => (
                                            <MenuItem
                                              key={`years-${forIndex}`}
                                              value={yearData.value}
                                              className={classes.menuItemClass}
                                              disabled={
                                                yearData.type === 'surname'
                                              }
                                            >
                                              <div>
                                                <Checkbox
                                                  icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                  }
                                                  checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                  }
                                                  checked={yearData.selected}
                                                  name={yearData.value.toString()}
                                                  className={
                                                    classes.controlCheck
                                                  }
                                                  classes={{
                                                    root: classes.checkbox,
                                                    checked: classes.checked
                                                  }}
                                                />
                                                {yearData.key}
                                              </div>
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  ) : (
                                    <></>
                                  )}
                                  {values.entries[index].groupType ===
                                  'Group' ? (
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formLabelControl}
                                      disabled={!groups.length}
                                    >
                                      <InputLabel id="demo-simple-select-outlined-label-Group">
                                        Group
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-outlined-label-Group"
                                        id="demo-simple-select-outlined-Group"
                                        multiple
                                        name={`entries.[${index}].groups`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          handleChange(e);
                                          handleChangeFreeYear(
                                            e,
                                            index,
                                            values.entries,
                                            setFieldValue
                                          );
                                          // yearChangeHandle(e);
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index].groups) ||
                                          []
                                        }
                                        renderValue={(selected) => {
                                          const groupName = groups.map(
                                            (data) => {
                                              if (
                                                selected.includes(data.value)
                                              ) {
                                                return data.key;
                                              } else {
                                                return null;
                                              }
                                            }
                                          );
                                          const groupNameFilter =
                                            groupName.filter(
                                              (data) => data && data
                                            );
                                          return groupNameFilter.join(', ');
                                        }}
                                        label="Group"
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index].groups &&
                                            errors.entries[index] &&
                                            errors.entries[index].groups
                                        )}
                                      >
                                        {/* <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem> */}
                                        {groups &&
                                          groups.length &&
                                          groups.map((group, forIndex) => (
                                            <MenuItem
                                              key={`group-${forIndex}`}
                                              value={group.value}
                                              className={classes.menuItemClass}
                                            >
                                              <div>
                                                <Checkbox
                                                  icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                  }
                                                  checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                  }
                                                  checked={group.selected}
                                                  name={group.value}
                                                  className={
                                                    classes.controlCheck
                                                  }
                                                  classes={{
                                                    root: classes.checkbox,
                                                    checked: classes.checked
                                                  }}
                                                />
                                                {group.key}
                                              </div>
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              {values.entries[index].groupType === 'Group' ||
                              values.entries[index].groupType === 'Families' ? (
                                <FormControl
                                  variant="outlined"
                                  className={classes.formLabelControl}
                                  // disabled={index !== 0 ? true : !family.length}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label-for">
                                    For
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label-for"
                                    id="demo-simple-select-outlined-for"
                                    multiple
                                    name={`entries.[${index}].for`}
                                    onBlur={handleBlur}
                                    // renderValue={(selected) => selected.join(', ')}
                                    renderValue={(selected) => {
                                      const familyName =
                                        values.entries[index] &&
                                        values.entries[index].forDropDownData &&
                                        values.entries[index].forDropDownData
                                          .length &&
                                        values.entries[
                                          index
                                        ].forDropDownData.map((data) => {
                                          if (selected.includes(data.id)) {
                                            return data.name;
                                          } else {
                                            return null;
                                          }
                                        });
                                      const familyNameFilter =
                                        familyName &&
                                        familyName.filter(
                                          (data) => data && data
                                        );
                                      return familyNameFilter
                                        ? familyNameFilter.join(', ')
                                        : '';
                                    }}
                                    onChange={(e) => {
                                      // handleChange(e);
                                      handleChangeMember(
                                        e,
                                        index,
                                        values.entries[index].for,
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].for) ||
                                      ''
                                    }
                                    label="For"
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index].for &&
                                        errors.entries[index] &&
                                        errors.entries[index].for
                                    )}
                                  >
                                    {values.entries[index].forDropDownData &&
                                      values.entries[index].forDropDownData
                                        .length &&
                                      values.entries[index].forDropDownData.map(
                                        (familyData, forIndex) => (
                                          // familyData.type !== 'child' && (
                                          <MenuItem
                                            key={`family-${forIndex}`}
                                            value={familyData.id}
                                            disabled={
                                              familyData.type === 'surname'
                                            }
                                            className={classes.menuItemClass}
                                            style={{
                                              borderBottom:
                                                forIndex === 0
                                                  ? `1px solid ${colors.primaryLightGrey}`
                                                  : null
                                            }}
                                          >
                                            <div>
                                              {familyData.type !==
                                                'surname' && (
                                                <Checkbox
                                                  icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                  }
                                                  checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                  }
                                                  checked={familyData.selected}
                                                  name={familyData.id}
                                                  className={
                                                    classes.controlCheck
                                                  }
                                                  classes={{
                                                    root: classes.checkbox,
                                                    checked: classes.checked
                                                  }}
                                                />
                                              )}
                                              {familyData.name}
                                            </div>
                                          </MenuItem>
                                        )
                                        // )
                                      )}
                                  </Select>
                                </FormControl>
                              ) : (
                                <></>
                              )}
                              {values.entries[0].groupType === 'Free' ? (
                                <>
                                  <FormControl
                                    variant="outlined"
                                    className={classes.formLabelControl}
                                    disabled={!years.length}
                                  >
                                    <InputLabel id="demo-simple-select-outlined-label-for">
                                      For
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label-for"
                                      id="demo-simple-select-outlined-for"
                                      multiple
                                      name={`entries.[${index}].for`}
                                      onBlur={handleBlur}
                                      // renderValue={(selected) => selected.join(', ')}
                                      renderValue={(selected) => {
                                        const familyName =
                                          values.entries[index] &&
                                          values.entries[index]
                                            .yearDropDownData &&
                                          values.entries[index].yearDropDownData
                                            .length &&
                                          values.entries[
                                            index
                                          ].yearDropDownData.map((data) => {
                                            if (selected.includes(data.value)) {
                                              return data.key;
                                            } else {
                                              return null;
                                            }
                                          });
                                        const familyNameFilter =
                                          familyName &&
                                          familyName.filter(
                                            (data) => data && data
                                          );
                                        return familyNameFilter
                                          ? familyNameFilter.join(', ')
                                          : '';
                                      }}
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleChangeFreeYear(
                                          e,
                                          index,
                                          values.entries,
                                          setFieldValue
                                        );
                                      }}
                                      value={
                                        (values.entries[index] &&
                                          values.entries[index].for) ||
                                        ''
                                      }
                                      label="For"
                                      error={Boolean(
                                        touched.entries &&
                                          errors.entries &&
                                          touched.entries[index] &&
                                          touched.entries[index].for &&
                                          errors.entries[index] &&
                                          errors.entries[index].for
                                      )}
                                    >
                                      {values.entries[index].yearDropDownData &&
                                        values.entries[index].yearDropDownData
                                          .length &&
                                        values.entries[
                                          index
                                        ].yearDropDownData.map(
                                          (yearData, forIndex) => {
                                            return (
                                              <MenuItem
                                                key={`years-${forIndex}`}
                                                value={yearData.value}
                                                className={
                                                  classes.menuItemClass
                                                }
                                                disabled={
                                                  yearData.type === 'surname' ||
                                                  (index !== 0 &&
                                                    (!values.entries[0].for.includes(
                                                      yearData.value
                                                    ) ||
                                                      (values.entries[0]
                                                        .groups &&
                                                        values.entries[0].groups
                                                          .length)))
                                                }
                                              >
                                                <div>
                                                  <Checkbox
                                                    icon={
                                                      <CheckBoxOutlineBlankIcon fontSize="small" />
                                                    }
                                                    checkedIcon={
                                                      <CheckBoxIcon fontSize="small" />
                                                    }
                                                    checked={yearData.selected}
                                                    name={yearData.value.toString()}
                                                    className={
                                                      classes.controlCheck
                                                    }
                                                    classes={{
                                                      root: classes.checkbox,
                                                      checked: classes.checked
                                                    }}
                                                  />
                                                  {yearData.key}
                                                </div>
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                    </Select>
                                  </FormControl>

                                  {index === 0 ||
                                  (values.entries[0].groups &&
                                    values.entries[0].groups.length) ? (
                                    <>
                                      <div className={classes.orDivider}>
                                        <span>OR</span>
                                      </div>

                                      <FormControl
                                        variant="outlined"
                                        className={classes.formLabelControl}
                                        disabled={!category.length}
                                      >
                                        <InputLabel id="demo-simple-select-outlined-label-for">
                                          Category
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-label-for"
                                          id="demo-simple-select-outlined-for"
                                          multiple
                                          name={`entries.[${index}].categoryInfo`}
                                          onBlur={handleBlur}
                                          // renderValue={(selected) => selected.join(', ')}
                                          renderValue={(selected) => {
                                            const familyName =
                                              values.entries[index] &&
                                              values.entries[index]
                                                .categoryDropDownData &&
                                              values.entries[index]
                                                .categoryDropDownData.length &&
                                              values.entries[
                                                index
                                              ].categoryDropDownData.map(
                                                (data) => {
                                                  if (
                                                    selected.includes(
                                                      data.value
                                                    )
                                                  ) {
                                                    return data.key;
                                                  } else {
                                                    return null;
                                                  }
                                                }
                                              );
                                            const familyNameFilter =
                                              familyName &&
                                              familyName.filter(
                                                (data) => data && data
                                              );
                                            return familyNameFilter
                                              ? familyNameFilter.join(', ')
                                              : '';
                                          }}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleChangeFreeYear(
                                              e,
                                              index,
                                              values.entries,
                                              setFieldValue
                                            );
                                          }}
                                          value={
                                            (values.entries[index] &&
                                              values.entries[index]
                                                .categoryInfo) ||
                                            ''
                                          }
                                          label="Category"
                                        >
                                          {values.entries[index]
                                            .categoryDropDownData &&
                                            values.entries[index]
                                              .categoryDropDownData.length &&
                                            values.entries[
                                              index
                                            ].categoryDropDownData.map(
                                              (categoryData, forIndex) => {
                                                return (
                                                  <MenuItem
                                                    key={`years-${forIndex}`}
                                                    value={categoryData.value}
                                                    className={
                                                      classes.menuItemClass
                                                    }
                                                    disabled={
                                                      categoryData.type ===
                                                        'surname' ||
                                                      (index !== 0 &&
                                                        (!values.entries[0].for.includes(
                                                          categoryData.value
                                                        ) ||
                                                          (values.entries[0]
                                                            .groups &&
                                                            values.entries[0]
                                                              .groups.length)))
                                                    }
                                                  >
                                                    <div>
                                                      <Checkbox
                                                        icon={
                                                          <CheckBoxOutlineBlankIcon fontSize="small" />
                                                        }
                                                        checkedIcon={
                                                          <CheckBoxIcon fontSize="small" />
                                                        }
                                                        checked={
                                                          categoryData.selected
                                                        }
                                                        name={categoryData.value.toString()}
                                                        className={
                                                          classes.controlCheck
                                                        }
                                                        classes={{
                                                          root: classes.checkbox,
                                                          checked:
                                                            classes.checked
                                                        }}
                                                      />
                                                      {categoryData.key}
                                                    </div>
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                        </Select>
                                      </FormControl>

                                      <FormControl
                                        variant="outlined"
                                        className={classes.formLabelControl}
                                        disabled={!groups.length}
                                      >
                                        <InputLabel id="demo-simple-select-outlined-label-Group">
                                          Custom Group
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-label-Group"
                                          id="demo-simple-select-outlined-Group"
                                          multiple
                                          name={`entries.[${index}].groups`}
                                          onBlur={handleBlur}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleChangeFreeYear(
                                              e,
                                              index,
                                              values.entries,
                                              setFieldValue
                                            );
                                            // yearChangeHandle(e);
                                          }}
                                          value={
                                            (values.entries[index] &&
                                              values.entries[0].groups) ||
                                            []
                                          }
                                          renderValue={(selected) => {
                                            const groupName = groups.map(
                                              (data) => {
                                                if (
                                                  selected.includes(data.value)
                                                ) {
                                                  return data.key;
                                                } else {
                                                  return null;
                                                }
                                              }
                                            );
                                            const groupNameFilter =
                                              groupName.filter(
                                                (data) => data && data
                                              );
                                            return groupNameFilter.join(', ');
                                          }}
                                          label="Custom Group"
                                          error={Boolean(
                                            touched.entries &&
                                              errors.entries &&
                                              touched.entries[index] &&
                                              touched.entries[index].groups &&
                                              errors.entries[index] &&
                                              errors.entries[index].groups
                                          )}
                                        >
                                          {/* <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem> */}
                                          {groups &&
                                            groups.length &&
                                            groups
                                              .sort((a, b) => {
                                                return a?.key?.toLowerCase() >
                                                  b?.key?.toLowerCase()
                                                  ? 1
                                                  : -1;
                                              })
                                              .map((group, forIndex) => (
                                                <MenuItem
                                                  key={`group-${forIndex}`}
                                                  value={group.value}
                                                  className={
                                                    classes.menuItemClass
                                                  }
                                                  disabled={index !== 0}
                                                >
                                                  <div>
                                                    <Checkbox
                                                      icon={
                                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                                      }
                                                      checkedIcon={
                                                        <CheckBoxIcon fontSize="small" />
                                                      }
                                                      checked={group.selected}
                                                      name={group.value}
                                                      className={
                                                        classes.controlCheck
                                                      }
                                                      classes={{
                                                        root: classes.checkbox,
                                                        checked: classes.checked
                                                      }}
                                                    />
                                                    {group.key}
                                                  </div>
                                                </MenuItem>
                                              ))}
                                        </Select>
                                      </FormControl>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              {values.entries[0].groupType !== 'Free' && (
                                <FormControl
                                  variant="outlined"
                                  className={classes.formLabelControl}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .assignToChild) ||
                                          false
                                        }
                                        classes={{
                                          switchBase: classes.switchBase,
                                          thumb: classes.thumb,
                                          track: classes.track,
                                          checked: classes.checked
                                        }}
                                        onChange={handleChange}
                                        name={`entries.[${index}].assignToChild`}
                                      />
                                    }
                                    style={{
                                      backgroundColor: colors.tertiary,
                                      marginRight: 0
                                    }}
                                    label="Assign to child calendars"
                                  />
                                </FormControl>
                              )}
                            </div>

                            <Divider
                              variant="fullWidth"
                              style={{ marginTop: '20px' }}
                            />

                            <div style={{ marginTop: 20 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                    onChange={() =>
                                      handleChangeCheck('allDay', index, values)
                                    }
                                    name={`entries[${index}].allDay`}
                                  />
                                }
                                label="All day"
                              />
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 10
                              }}
                            >
                              <Typography>From</Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginTop: 5
                                }}
                              >
                                <DatePicker
                                  autoOk
                                  okLabel=""
                                  cancelLabel=""
                                  name={`entries[${index}].from.date`}
                                  onBlur={handleBlur}
                                  onChange={(date) =>
                                    handleDateChange(
                                      date,
                                      index,
                                      values,
                                      'from',
                                      'date'
                                    )
                                  }
                                  value={
                                    (values.entries[index] &&
                                      moment(
                                        values.entries[index].from.date
                                      )) ||
                                    ''
                                  }
                                  style={{ width: '40%' }}
                                  format="MMM DD,YYYY"
                                  className={
                                    classes.formDateControlFromBordered
                                  }
                                />

                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 1, max: 12 }
                                    }}
                                    variant="outlined"
                                    placeholder="hh"
                                    // style={{height: '23px', marginTop: 0}}
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newFromTimeFormate.hh`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value.length <= 2 &&
                                          parseInt(e.target.value) <= 12 &&
                                          parseInt(e.target.value) > 0 &&
                                          Number.isInteger(
                                            parseInt(e.target.value)
                                          )) ||
                                        e.target.value === ''
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          `entries[${index}].newToTimeFormate.hh`,
                                          parseInt(e.target.value) === 12
                                            ? '1'
                                            : 1 + parseInt(e.target.value)
                                        );
                                      }
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newFromTimeFormate
                                          .hh) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newFromTimeFormate &&
                                        touched.entries[index]
                                          .newFromTimeFormate.hh &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newFromTimeFormate &&
                                        errors.entries[index].newFromTimeFormate
                                          .hh
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 0, max: 59 }
                                    }}
                                    variant="outlined"
                                    placeholder="mm"
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newFromTimeFormate.mm`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value.length <= 2 &&
                                          parseInt(e.target.value) <= 59 &&
                                          parseInt(e.target.value) >= 0 &&
                                          Number.isInteger(
                                            parseInt(e.target.value)
                                          )) ||
                                        e.target.value === ''
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          `entries[${index}].newFromTimeFormate.mm`,
                                          e.target.value
                                        );
                                        setFieldValue(
                                          `entries[${index}].newToTimeFormate.mm`,
                                          e.target.value
                                        );
                                      }
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newFromTimeFormate
                                          .mm) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newFromTimeFormate &&
                                        touched.entries[index]
                                          .newFromTimeFormate.mm &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newFromTimeFormate &&
                                        errors.entries[index].newFromTimeFormate
                                          .mm
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    variant="outlined"
                                    placeholder="a/p"
                                    // style={{height: '23px', marginTop: 0}}
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newFromTimeFormate.ap`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === '' ||
                                        e.target.value.toLowerCase() === 'a' ||
                                        e.target.value.toLowerCase() === 'p'
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          `entries[${index}].newToTimeFormate.ap`,
                                          e.target.value
                                        );
                                      }
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newFromTimeFormate
                                          .ap) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newFromTimeFormate &&
                                        touched.entries[index]
                                          .newFromTimeFormate.ap &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newFromTimeFormate &&
                                        errors.entries[index].newFromTimeFormate
                                          .ap
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>

                                {/* <ThemeProvider theme={muiTimePickerTheme}>
                                  <TimePicker
                                    autoOk
                                    name={`entries[${index}].from.time`}
                                    onBlur={handleBlur}
                                    onChange={(e) =>
                                      handleDateChange(
                                        e,
                                        index,
                                        values,
                                        'from',
                                        'time'
                                      )
                                    }
                                    value={
                                      (values.entries[index] &&
                                        moment(
                                          values.entries[index].from.time,
                                          'h:mma'
                                        )) ||
                                      ''
                                    }
                                    format="h:mma"
                                    style={{ width: 80 }}
                                    className={
                                      classes.formDateControlFromBordered
                                    }
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </ThemeProvider> */}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 10
                              }}
                            >
                              <Typography>To</Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginTop: 5
                                }}
                              >
                                <DatePicker
                                  autoOk
                                  okLabel=""
                                  cancelLabel=""
                                  name={`entries[${index}].to.date`}
                                  onBlur={handleBlur}
                                  minDate={
                                    (values.entries[index] &&
                                      moment(
                                        values.entries[index].from.date
                                      )) ||
                                    ''
                                  }
                                  onChange={(date) =>
                                    handleDateChange(
                                      date,
                                      index,
                                      values,
                                      'to',
                                      'date'
                                    )
                                  }
                                  value={
                                    (values.entries[index] &&
                                      moment(values.entries[index].to.date)) ||
                                    ''
                                  }
                                  style={{ width: '40%' }}
                                  format="MMM DD,YYYY"
                                  className={
                                    classes.formDateControlFromBordered
                                  }
                                />

                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 1, max: 12 }
                                    }}
                                    variant="outlined"
                                    placeholder="hh"
                                    // style={{height: '23px', marginTop: 0}}
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newToTimeFormate.hh`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value.length <= 2 &&
                                          parseInt(e.target.value) <= 12 &&
                                          parseInt(e.target.value) > 0 &&
                                          Number.isInteger(
                                            parseInt(e.target.value)
                                          )) ||
                                        e.target.value === ''
                                      )
                                        handleChange(e);
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newToTimeFormate
                                          .hh) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newToTimeFormate &&
                                        touched.entries[index].newToTimeFormate
                                          .hh &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newToTimeFormate &&
                                        errors.entries[index].newToTimeFormate
                                          .hh
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 0, max: 59 }
                                    }}
                                    variant="outlined"
                                    placeholder="mm"
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newToTimeFormate.mm`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value.length <= 2 &&
                                          parseInt(e.target.value) <= 59 &&
                                          parseInt(e.target.value) >= 0 &&
                                          Number.isInteger(
                                            parseInt(e.target.value)
                                          )) ||
                                        e.target.value === ''
                                      )
                                        handleChange(e);
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newToTimeFormate
                                          .mm) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newToTimeFormate &&
                                        touched.entries[index].newToTimeFormate
                                          .mm &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newToTimeFormate &&
                                        errors.entries[index].newToTimeFormate
                                          .mm
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    variant="outlined"
                                    placeholder="a/p"
                                    // style={{height: '23px', marginTop: 0}}
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newToTimeFormate.ap`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === '' ||
                                        e.target.value.toLowerCase() === 'a' ||
                                        e.target.value.toLowerCase() === 'p'
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newToTimeFormate
                                          .ap) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newToTimeFormate &&
                                        touched.entries[index].newToTimeFormate
                                          .ap &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newToTimeFormate &&
                                        errors.entries[index].newToTimeFormate
                                          .ap
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>

                                {/* <ThemeProvider theme={muiTimePickerTheme}>
                                  <TimePicker
                                    autoOk
                                    name={`entries[${index}].to.time`}
                                    onBlur={handleBlur}
                                    onChange={(e) =>
                                      handleDateChange(
                                        e,
                                        index,
                                        values,
                                        'to',
                                        'time'
                                      )
                                    }
                                    value={
                                      (values.entries[index] &&
                                        moment(
                                          values.entries[index].to.time,
                                          'h:mma'
                                        )) ||
                                      ''
                                    }
                                    format="h:mma"
                                    style={{ width: 80 }}
                                    className={
                                      classes.formDateControlFromBordered
                                    }
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </ThemeProvider> */}
                              </div>
                            </div>
                            {/* <div style={{ marginTop: 20, marginLeft: 10 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                    onChange={(e) =>
                                      handleChangeCheck('allDay', index, values)
                                    }
                                    name={`entries[${index}].allDay`}
                                  />
                                }
                                label="All day"
                              />
                            </div> */}
                            <Divider
                              variant="fullWidth"
                              style={{ marginBottom: 20, marginTop: 20 }}
                            />
                            {entry.actions.repeat.length && index === 0 ? (
                              <div style={{ marginTop: 24 }}>
                                <Typography variant="subtitle2">
                                  Repeat
                                </Typography>
                                {entry.actions.repeat.map((_data, idx) => (
                                  <div
                                    key={`repeat-${idx}`}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginTop: 16
                                    }}
                                  >
                                    <FormControl
                                      className={
                                        classes.formLabelControlBordered
                                      }
                                      style={{ width: '90%' }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        name={`entries[${index}].actions.repeat[${idx}].type`}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleRepeatChange(
                                            e,
                                            index,
                                            idx,
                                            values
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index].actions
                                              .repeat[idx].type) ||
                                          ''
                                        }
                                        label="Repeat"
                                      >
                                        {/* <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem> */}
                                        <MenuItem value={'Does not repeat'}>
                                          Does not repeat
                                        </MenuItem>
                                        <MenuItem value={'Daily'}>
                                          Daily
                                        </MenuItem>
                                        <MenuItem value={'Custom'}>
                                          Custom
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                    {(values.entries[index] &&
                                      values.entries[index].actions.repeat[idx]
                                        .type) === 'Custom' ? (
                                      <VisibilityIcon
                                        onClick={() => {
                                          const data = {
                                            selectedIndex: index,
                                            innerIndex: idx,
                                            values: {
                                              entries: [...values.entries]
                                            }
                                          };
                                          setCustomValue(data);
                                          setOpenModal({
                                            open: true,
                                            value:
                                              values.entries[index].actions
                                                .repeat[idx]
                                          });
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <CloseIcon
                                      onClick={() =>
                                        handleDateActionRemove(
                                          index,
                                          idx,
                                          values,
                                          'repeat'
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {entry.actions.notification.length ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginTop: 24
                                }}
                              >
                                <Typography variant="subtitle2">
                                  Notification
                                </Typography>
                                {entry.actions.notification.map((_data, idx) => (
                                  <div
                                    key={`notification-${idx}`}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginTop: 16
                                    }}
                                  >
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formDayLabelControl}
                                    >
                                      <DatePicker
                                        autoOk
                                        okLabel=""
                                        cancelLabel=""
                                        name={`entries[${index}].actions.notification[${idx}].date`}
                                        onBlur={handleBlur}
                                        // maxDate={
                                        //   (values.entries[index] &&
                                        //     moment(
                                        //       values.entries[index].from.date
                                        //     )) ||
                                        //   ''
                                        // }
                                        onChange={(date) =>
                                          handleNotificationDateChange(
                                            date,
                                            index,
                                            idx,
                                            values,
                                            'date'
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index].actions
                                              .notification[idx].date) ||
                                          moment().format('MMM DD,YYYY')
                                        }
                                        style={{ width: '80%' }}
                                        minDateMessage=""
                                        format="MMM DD,YYYY"
                                        className={
                                          classes.formDateControlFromBordered
                                        }
                                      />
                                    </FormControl>
                                    <ThemeProvider theme={muiTimePickerTheme}>
                                      <TimePicker
                                        autoOk
                                        name={`entries[${index}].notification.time`}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleDateActionChange(
                                            e,
                                            index,
                                            idx,
                                            values,
                                            'notification',
                                            'time'
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            moment(
                                              values.entries[index].actions
                                                .notification[idx].time,
                                              'h:mma'
                                            )) ||
                                          ''
                                        }
                                        format="h:mma"
                                        style={{ width: 80 }}
                                        className={
                                          classes.formDateControlFromBordered
                                        }
                                      />
                                    </ThemeProvider>
                                    <CloseIcon
                                      onClick={() =>
                                        handleDateActionRemove(
                                          index,
                                          idx,
                                          values,
                                          'notification'
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {entry.actions.reminder.length ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginTop: 20
                                }}
                              >
                                {entry.actions.reminder.map((_data, idx) => (
                                  <Fragment key={`reminder-${idx}`}>
                                    <Typography variant="subtitle2">
                                      Reminder
                                    </Typography>
                                    <div
                                      key={`reminder-${idx}`}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: 16
                                      }}
                                    >
                                      <DatePicker
                                        autoOk
                                        okLabel=""
                                        cancelLabel=""
                                        name={`entries[${index}].reminder`}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleDateActionChange(
                                            e,
                                            index,
                                            idx,
                                            values,
                                            'reminder',
                                            'date'
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            moment(
                                              values.entries[index].actions
                                                .reminder[idx]
                                            )) ||
                                          ''
                                        }
                                        format="MMM DD,YYYY"
                                        style={{ width: '80%' }}
                                        className={
                                          classes.formDateControlFromBordered
                                        }
                                      />
                                      <CloseIcon
                                        onClick={() =>
                                          handleDateActionRemove(
                                            index,
                                            idx,
                                            values,
                                            'reminder'
                                          )
                                        }
                                      />
                                    </div>

                                    {values.entries[index] &&
                                    values.entries[index].actions
                                      .reminderNotification &&
                                    values.entries[index].actions
                                      .reminderNotification[idx] ? (
                                      <>
                                        <div
                                          key={`reminderNotificationTitle-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16
                                          }}
                                        >
                                          <Typography variant="subtitle2">
                                            Reminder (Notification)
                                          </Typography>
                                        </div>
                                        <div
                                          key={`reminderNotification-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16,
                                            marginBottom: 16
                                          }}
                                        >
                                          <FormControl
                                            variant="outlined"
                                            className={
                                              classes.formDayLabelControl
                                            }
                                          >
                                            <DatePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              okLabel=""
                                              cancelLabel=""
                                              name={`entries[${index}].actions.reminderNotification[${idx}].date`}
                                              onBlur={handleBlur}
                                              // maxDate={
                                              //   (values.entries[index] &&
                                              //     moment(
                                              //       values.entries[index].from.date
                                              //     )) ||
                                              //   ''
                                              // }
                                              onChange={(date) =>
                                                handleNotificationDateChange(
                                                  date,
                                                  index,
                                                  idx,
                                                  values,
                                                  'date',
                                                  'reminderNotification'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .reminderNotification &&
                                                  values.entries[index].actions
                                                    .reminderNotification[
                                                    idx
                                                  ] &&
                                                  values.entries[index].actions
                                                    .reminderNotification[idx]
                                                    .date) ||
                                                moment().format('MMM DD,YYYY')
                                              }
                                              style={{ width: '80%' }}
                                              minDateMessage=""
                                              format="MMM DD,YYYY"
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </FormControl>
                                          <ThemeProvider
                                            theme={muiTimePickerTheme}
                                          >
                                            <TimePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              name={`entries[${index}].reminderNotification.time`}
                                              onBlur={handleBlur}
                                              onChange={(e) =>
                                                handleDateActionChange(
                                                  e,
                                                  index,
                                                  idx,
                                                  values,
                                                  'reminderNotification',
                                                  'time'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .reminderNotification &&
                                                  values.entries[index].actions
                                                    .reminderNotification[
                                                    idx
                                                  ] &&
                                                  moment(
                                                    values.entries[index]
                                                      .actions
                                                      .reminderNotification[idx]
                                                      .time,
                                                    'h:mma'
                                                  )) ||
                                                ''
                                              }
                                              format="h:mma"
                                              style={{ width: '40%' }}
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </ThemeProvider>
                                          <CloseIcon
                                            onClick={() => {
                                              // if(!hideForRepeat){
                                              handleDateActionRemove(
                                                index,
                                                idx,
                                                values,
                                                'reminderNotification'
                                              );
                                              // }
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                  </Fragment>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {entry.actions.deadline.length ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginTop: 20
                                }}
                              >
                                {entry.actions.deadline.map((_data, idx) => (
                                  <Fragment key={`deadline-${idx}`}>
                                    <Typography variant="subtitle2">
                                      Deadline
                                    </Typography>
                                    <div
                                      key={`deadline-${idx}`}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: 16
                                      }}
                                    >
                                      <DatePicker
                                        autoOk
                                        okLabel=""
                                        cancelLabel=""
                                        name={`entries[${index}].deadline`}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleDateActionChange(
                                            e,
                                            index,
                                            idx,
                                            values,
                                            'deadline',
                                            'date'
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            moment(
                                              values.entries[index].actions
                                                .deadline[idx]
                                            )) ||
                                          ''
                                        }
                                        format="MMM DD,YYYY"
                                        style={{ width: '80%' }}
                                        className={
                                          classes.formDateControlFromBordered
                                        }
                                      />
                                      <CloseIcon
                                        onClick={() =>
                                          handleDateActionRemove(
                                            index,
                                            idx,
                                            values,
                                            'deadline'
                                          )
                                        }
                                      />
                                    </div>

                                    {values.entries[index] &&
                                    values.entries[index].actions
                                      .deadlineNotification &&
                                    values.entries[index].actions
                                      .deadlineNotification[idx] ? (
                                      <>
                                        <div
                                          key={`reminderDeadlineTitle-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16
                                          }}
                                        >
                                          <Typography variant="subtitle2">
                                            Deadline (Notification)
                                          </Typography>
                                        </div>
                                        <div
                                          key={`deadlineNotification-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16,
                                            marginBottom: 16
                                          }}
                                        >
                                          <FormControl
                                            variant="outlined"
                                            className={
                                              classes.formDayLabelControl
                                            }
                                          >
                                            <DatePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              okLabel=""
                                              cancelLabel=""
                                              name={`entries[${index}].actions.deadlineNotification[${idx}].date`}
                                              onBlur={handleBlur}
                                              // maxDate={
                                              //   (values.entries[index] &&
                                              //     moment(
                                              //       values.entries[index].from.date
                                              //     )) ||
                                              //   ''
                                              // }
                                              onChange={(date) =>
                                                handleNotificationDateChange(
                                                  date,
                                                  index,
                                                  idx,
                                                  values,
                                                  'date',
                                                  'deadlineNotification'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .deadlineNotification &&
                                                  values.entries[index].actions
                                                    .deadlineNotification[
                                                    idx
                                                  ] &&
                                                  values.entries[index].actions
                                                    .deadlineNotification[idx]
                                                    .date) ||
                                                moment().format('MMM DD,YYYY')
                                              }
                                              style={{ width: '80%' }}
                                              minDateMessage=""
                                              format="MMM DD,YYYY"
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </FormControl>
                                          <ThemeProvider
                                            theme={muiTimePickerTheme}
                                          >
                                            <TimePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              name={`entries[${index}].deadlineNotification.time`}
                                              onBlur={handleBlur}
                                              onChange={(e) =>
                                                handleDateActionChange(
                                                  e,
                                                  index,
                                                  idx,
                                                  values,
                                                  'deadlineNotification',
                                                  'time'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .deadlineNotification &&
                                                  values.entries[index].actions
                                                    .deadlineNotification[
                                                    idx
                                                  ] &&
                                                  moment(
                                                    values.entries[index]
                                                      .actions
                                                      .deadlineNotification[idx]
                                                      .time,
                                                    'h:mma'
                                                  )) ||
                                                ''
                                              }
                                              format="h:mma"
                                              style={{ width: '40%' }}
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </ThemeProvider>
                                          <CloseIcon
                                            onClick={() => {
                                              // if(!hideForRepeat){
                                              handleDateActionRemove(
                                                index,
                                                idx,
                                                values,
                                                'deadlineNotification'
                                              );
                                              // }
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                  </Fragment>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {entry.actions.isCancelled ||
                            entry.actions.isPostponed ||
                            entry.actions.isChanged ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  marginTop: 20
                                }}
                              >
                                <div>
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      color: entry.actions.isChanged
                                        ? '#000'
                                        : colors.tertiaryRed
                                    }}
                                    className={classes.updatedLabel}
                                  >
                                    {entry.actions.isPostponed
                                      ? 'Postponed'
                                      : entry.actions.isCancelled
                                        ? 'Cancelled'
                                        : 'Updated'}
                                  </Typography>
                                  <Typography
                                    className={classes.updatedDesc}
                                    style={{
                                      ...(entry.actions.isChanged
                                        ? {}
                                        : { fontSize: 16 }),
                                      color: colors.primaryDarkGrey
                                    }}
                                  >
                                    Display{' '}
                                    {entry.actions.isPostponed
                                      ? 'Postponed'
                                      : entry.actions.isCancelled
                                        ? 'Cancelled'
                                        : 'Updated'}{' '}
                                    to member
                                  </Typography>
                                </div>
                                <CloseIcon
                                  onClick={() =>
                                    handleDateActionRemove(
                                      index,
                                      0,
                                      values,
                                      entry.actions.isPostponed
                                        ? 'isPostponed'
                                        : entry.actions.isCancelled
                                          ? 'isCancelled'
                                          : 'isChanged'
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            <FormControl
                              variant="outlined"
                              style={{ marginBottom: 24 }}
                              className={classes.formLabelControl}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Apply Action
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={''}
                                onChange={(e) => {
                                  if (e.target.value) {
                                    handleActionChange(e, index, values);
                                  }
                                }}
                                label="Apply Action"
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {index === 0 && (
                                  <MenuItem
                                    disabled={
                                      entry.actions.repeat.length ? true : false
                                    }
                                    value={'repeat'}
                                  >
                                    Repeat
                                  </MenuItem>
                                )}
                                <MenuItem value={'notification'}>
                                  Notification
                                </MenuItem>
                                <MenuItem value={'deadline'}>Deadline</MenuItem>
                                <MenuItem value={'reminder'}>Reminder</MenuItem>
                                {index === 0 && (
                                  <MenuItem value={'cancelled'}>
                                    Cancelled
                                  </MenuItem>
                                )}
                                {index === 0 && (
                                  <MenuItem value={'postponed'}>
                                    Postponed
                                  </MenuItem>
                                )}
                                {index === 0 && (
                                  <MenuItem value={'changed'}>Updated</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Fragment>
                  ))}
                </div>
              </form>
            </>
          );
        }}
      </Formik>
      {isOpenModal.open && (
        <Modal isOpen={isOpenModal.open} onClose={handleCustomDateClose}>
          <CustomDateModal
            data={customValue}
            submitForm={handleCustomDateSubmit}
            selectedValue={isOpenModal.value}
            onClose={handleCustomDateClose}
          />
        </Modal>
      )}
      {isOpenDeleteModal && (
        <Modal
          isOpen={isOpenDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Delete Entry Creation?
            </Typography>
            <Typography className={classes.modalSubTitle}>
              Are you sure you want to delete this new entry?
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  setUpdatedEntry(null);
                  dispatch(setPreserveEntryData(null));
                  onClose();
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Delete'}
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenDeleteLinkedModal.open && (
        <Modal
          isOpen={isOpenDeleteLinkedModal.open}
          onClose={() => setOpenDeleteLinkedModal({ open: false, id: null })}
        >
          <div className={classes.modalBox}>
            <Typography
              className={classes.modalTitle}
              style={{ marginBottom: 18 }}
            >
              Delete Linked Entry?
            </Typography>
            <Typography className={classes.modalSubTitle}>
              Are you sure you want to delete this linked entry?
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={removeLinkedEntry}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
              >
                Delete
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteLinkedModal({ open: false, id: null });
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </RightDrawer>
  );
}

export default withFirebase(CreateGroupCalenderEntry);
