// Family set Success
export const setSchool = (school) => ({
  type: 'SET_SCHOOL',
  school
});

export const setSchoolDataUpdated = (isSchoolDataUpdated) => ({
  type: 'IS_SCHOOL_DATA_UPDATED',
  payload: isSchoolDataUpdated
})

export const removeUpdatedSchoolData = () => ({
  type: 'REMOVE_UPDATED_DATA'
})

// Family set Success
export const setSchoolPreserveValues = (payload) => ({
  type: 'SET_SCHOOL_PRESERVE',
  payload
});

// Reset Family
export const resetSchool = () => ({
  type: 'RESET_SCHOOL'
});

// Set School Created ID
export const setAPISchoolID = (schoolID) => ({
  type: 'SCHOOL_CREATED',
  APISchoolID: schoolID
});

// Set School Data
export const setAPISchoolData = (schoolData) => ({
  type: 'SCHOOL_DATA',
  APISchoolData: schoolData
});
