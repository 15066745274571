import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { colors } from 'src/utils/constant';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { LOGO } from 'src/assets';
import { useSelector } from 'react-redux';
import completeTick from 'src/assets/completeTick.svg';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 116,
    backgroundColor: '#F4F6F8'
  },
  button: {
    backgroundColor: colors.primary,
    color: colors.white,
    justifyContent: 'center'
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: -0.22,
    textAlign: 'center',
    color: colors.primaryDark,
    marginTop: 48
  },
  link: {
    display: 'flex',
    height: 50,
    width: 250,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  logoContainer: {
    width: 267.6,
    height: 268.1,
    backgroundColor: colors.primary,
    borderRadius: '50%',
    position: 'relative'
  },
  image: {
    position: 'absolute',
    top: 42,
    left: 38
  }
}));

export default function CustomSuccessComponent(props) {
  const classes = useStyles();
  const history = useHistory();
  const apiSchool = useSelector((state) => state.school.apiSchool);
  // PHASE2/CHANGES/SCHOOL
  const { title, buttonLabel, listUrl } = props;
  const { schoolData, schoolIDGeneratedAPI } = apiSchool;
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignContent="center"
      className={classes.container}
    >
      <Grid>
        <div className={classes.logoContainer}>
          <img alt="Logo" src={LOGO} className={classes.image} />
        </div>
      </Grid>
      <Grid item xs={12} align="center">
        <Typography className={classes.title} align="center">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <section
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 48,
            fontWeight: '500',
            gap: '2rem'
          }}
        >
          <span
            className={classes.textLight}
            style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}
          >
            <img src={completeTick} />
            {schoolData?.data?.years?.map((year, yearIndex) => {
              if (schoolData?.data?.years?.length === yearIndex + 1) {
                return `${year}`;
              } else {
                return `${year},`;
              }
            })}
          </span>
          <span
            className={classes.textLight}
            style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}
          >
            <img src={completeTick} />
            {schoolData?.data?.groups} class groups
          </span>
        </section>
      </Grid>

      <Grid
        item
        xs={12}
        align="center"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 48,
          marginBottom: 48
        }}
      >
        <Button
          className={classes.link}
          color="primary"
          variant="contained"
          align="center"
          onClick={() => {
            listUrl &&
              schoolIDGeneratedAPI !== null &&
              history.push(`${listUrl}/${schoolIDGeneratedAPI}`);
          }}
        >
          <span>{buttonLabel}</span>
        </Button>
      </Grid>
    </Grid>
  );
}
