import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function DownloadIcon({ color, ...props }) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__download" fill="#fff">
          <path
            id="prefix__download1"
            d="M20.25 13.833c.47 0 .858.354.91.81l.007.107v3.667c0 1.464-1.145 2.661-2.589 2.745l-.161.005H5.583c-1.464 0-2.661-1.145-2.745-2.589l-.005-.161V14.75c0-.506.41-.917.917-.917.47 0 .858.354.91.81l.007.107v3.667c0 .47.354.857.81.91l.106.006h12.834c.47 0 .857-.354.91-.81l.006-.106V14.75c0-.506.41-.917.917-.917zm-8.299 1.832l.049.002c-.04 0-.08-.003-.12-.008h-.003l-.104-.02c-.039-.01-.077-.023-.113-.038-.02-.007-.04-.016-.059-.025l-.048-.025-.043-.026-.053-.036-.019-.015-.025-.02-.05-.044-.011-.012-4.584-4.583c-.357-.358-.357-.939 0-1.297.33-.33.85-.355 1.21-.076l.087.076 3.018 3.018V3.75c0-.506.41-.917.917-.917.47 0 .858.354.91.81l.007.107v8.785l3.018-3.017c.33-.33.85-.355 1.21-.076l.087.076c.33.33.355.85.076 1.21l-.076.087-4.584 4.583c-.025.025-.052.049-.08.071l.08-.07c-.038.037-.079.072-.121.102-.021.014-.043.028-.065.04-.008.006-.016.01-.025.015-.02.011-.043.022-.065.032l-.053.022-.045.015-.054.015c-.07.017-.144.027-.22.027-.02 0-.034 0-.049-.002z"
          />
        </mask>
        <g fill={color || '#4C5B68'} mask="url(#prefix__download)">
          <path d="M0 0H24V24H0z" />
        </g>
      </g>
    </SvgIcon>
  );
}
export default DownloadIcon;
