import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  makeStyles,
  Paper,
  Button,
  Grid,
  Divider,
  Avatar,
  Typography,
  CircularProgress,
  Link
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { OpenInNew, MailOutline } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Dashboard from '../layout/Dashboard';
import RightDrawer from 'src/components/RightDrawer';
import { routes, colors } from 'src/utils/constant';
import CardComponent from './components/CardComponent';
import Modal from 'src/components/Modal';
import { parentSchema, childSchema } from 'src/utils/schemas';
import { RightArrow } from 'src/assets/Icons';
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { withFirebase } from 'src/utils/firebase';
import { setFamily } from 'src/redux/actions/familyActions';
import { HandBook, ExternalLink, Lock, Edit } from 'src/assets';
import { MESSAGE_TYPES } from 'src/constants/common';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  tabNavigations: {
    padding: '10px 20px 0',
    marginBottom: 40
  },
  navigationButton: {
    width: 120,
    textTransform: 'none'
  },
  actionButton: {
    margin: '15px 0 20px',
    textTransform: 'none'
  },
  activeTab: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0
  },
  bredthcrumbTitle: {
    color: '#536e7b',
    fontSize: 16,
    marginRight: 24,
    display: 'inline-block',
    cursor: 'pointer'
  },
  drawerLeftContent: {
    width: `calc(60% - 20px)`,
    height: `calc(100vh - 30px)`,
    borderRadius: 5,
    backgroundColor: 'rgb(255, 245, 224,1)',
    float: 'left',
    position: 'relative',
    margin: 10
  },
  drawerRightContent: {
    width: '40%',
    height: `calc(100vh - 10px)`,
    backgroundColor: '#f4f5f7',
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  leftContentHeader: {
    textAlign: 'right'
  },
  label: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: 0.13,
    color: colors.primaryDarkGrey,
    marginLeft: 14,
    marginTop: 20,
    float: 'left'
  },
  siderAvatar: {
    marginTop: 12,
    width: 40,
    float: 'left',
    color: colors.primaryDarkGrey,
    fontSize: 14,
    backgroundColor: colors.primaryLightGrey,
    fontWeight: 500
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    height: 71
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primaryDarkGrey
  },
  siderDetail: {
    fontSize: 14,
    color: colors.primaryDarkGrey,
    marginBottom: 8
  },
  icon: {
    marginRight: 17,
    height: 24,
    width: 24
  },
  siderEdit: {
    height: 40,
    marginTop: 19
  },
  textArea: {
    width: '96%',
    height: '25%',
    borderRadius: 5,
    backgroundColor: '#fff5e0',
    border: 0,
    color: colors.primaryDarkGrey
  },
  leftContentMargin: {
    marginLeft: 40,
    marginRight: 40
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalContent: {
    minHeight: 75,
    paddingTop: 12
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: 'grey'
  },
  modalBox: {
    width: 430,
    height: 170
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  toolbarHidden: {
    display: 'none'
  },
  toolbar: {
    backgroundColor: 'transparent',
    border: 0,
    '& .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-target-option': {
      display: 'none'
    }
  },
  textEditor: {
    maxHeight: `calc(100vh - 150px)`
  },
  textEditorOnEdit: {
    maxHeight: `calc(100vh - 230px)`
  },
  handBookContainer: { position: 'absolute', bottom: -20, right: -10 },
  handBookClass: { width: 360, height: 297, opacity: 0.32 }
}));

const NotesCardDataFormat = {
  headerLink: 'right',
  buttonTextHover: 'View Notes',
  headerLinkText: 'Portal',
  headerLinkIcon: <OpenInNew style={{ marginTop: 5, width: 18, height: 18 }} />,
  displayParent: 1
};

function NotesView({ firebase }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [memberData, setMemberData] = useState(false);
  const [defaultNote, setDefaultNote] = useState('');
  const isLoading = useSelector((state) => state.app.isLoading);
  const profileImage = _.get(memberData, 'profileImage.url', '');
  const schools = useSelector((state) => state.auth.schools);
  const currentFamily = useSelector((state) => state.family.currentFamily);

  const onClose = useCallback(
    (currentState) => {
      setEditMode(false);
      setOpen(currentState);
    },
    [setOpen]
  );
  const [isOpenModal, setOpenModal] = useState(false);

  const openRightDrawer = useCallback(
    (data) => {
      setMemberData(data);
      const contentBlock = htmlToDraft(data.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      setOpen(true);
    },
    [setOpen]
  );
  const handleClose = useCallback(() => {
    setEditMode(false);
    setOpenModal(false);
  }, [setOpenModal]);

  const familyID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  useEffect(() => {
    if (!currentFamily.familySurname && familyID) {
      dispatch(loaderStart());
      firebase
        .getFamilyByID(familyID)
        .then(
          async (doc) => {
            const familyData = doc.data();
            if (familyData) {
              dispatch(setFamily(familyData));
            }
            dispatch(loaderStop());
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(
              addMessage('Error on getting family', MESSAGE_TYPES.ERROR)
            );
            dispatch(loaderStop());
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    }
  }, [currentFamily, familyID, firebase, dispatch]);

  const { familySurname, parents, childs } = useMemo(() => {
    if (currentFamily) {
      return {
        familySurname: currentFamily.familySurname,
        id: currentFamily.id,
        parents: currentFamily.parents,
        childs: currentFamily.childs
      };
    }
    return {
      familySurname: '',
      id: '',
      parents: [],
      childs: []
    };
  }, [currentFamily]);

  const onEditorChange = (value) => {
    const editorData = draftToHtml(convertToRaw(value.getCurrentContent()));
    setMemberData({ ...memberData, notes: editorData });
  };

  const onSaveChanges = () => {
    let updatedFamily = [];
    if (memberData.isParent) {
      updatedFamily = currentFamily.parents.map((parent, index) => {
        if (index === memberData.index) {
          return memberData;
        } else {
          return parent;
        }
      });
      currentFamily.parents = updatedFamily;
    } else {
      updatedFamily = currentFamily.childs.map((parent, index) => {
        if (index === memberData.index) {
          return memberData;
        } else {
          return parent;
        }
      });
      currentFamily.childs = updatedFamily;
    }

    dispatch(loaderStart());
    firebase
      .updateFamily(currentFamily, currentFamily.id)
      .then(
        () => {
          dispatch(loaderStop());
          dispatch(setFamily(currentFamily));
          dispatch(addMessage('Changes saved'));
          const contentBlock = htmlToDraft(memberData.notes || '');
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setDefaultNote(editorState);
          }
          handleClose();
          setOpen(false);
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  };

  const onCopy = () => {
    dispatch(addMessage('Copied to clipboard!'));
  };

  const getSchoolName = (id) => {
    const schoolData = schools.find((school) => school.id === id);
    return schoolData ? schoolData.name : '';
  };

  const onCloseForm = () => {
    const defaultData = draftToHtml(
      convertToRaw(defaultNote.getCurrentContent())
    );
    const memberNotes = memberData.notes;
    if (defaultData === memberNotes) {
      // handleClose();
      onClose();
    } else if (
      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
      !memberNotes
    ) {
      onClose();
    } else if (memberNotes === '') {
      // handleClose();
      onClose();
    } else {
      setOpenModal(true);
    }
  };

  return (
    <Dashboard>
      <div className={classes.root}>
        <div>
          <Typography
            variant="body1"
            className={classes.bredthcrumbTitle}
            onClick={() =>
              window.location.pathname.match('/new')
                ? history && history.push(routes.familiesFreeNew)
                : window.location.pathname.match('/pending')
                  ? history && history.push(routes.familiesFreePending)
                  : window.location.pathname.match('/paid')
                    ? history && history.push(routes.familiesPaid)
                    : history && history.push(routes.familiesFree)
            }
          >
            Families
          </Typography>
          <RightArrow
            viewBox="0 0 24 24"
            height={24}
            width={24}
            style={{ fontSize: 22, verticalAlign: 'middle' }}
          />
        </div>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20, marginTop: 22 }}
        >
          {familySurname}
        </Typography>
        <Paper className={classes.tabNavigations}>
          <div>
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.familyCalendar + '/' + familyID)
                  : history.push(routes.familyCalendarPaid + '/' + familyID);
              }}
            >
              Calendar
            </Button>
            {currentFamily.planType !== 'Free' && (
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyFiles + '/' + familyID)
                    : history.push(routes.familyFilesPaid + '/' + familyID);
                }}
              >
                Files
              </Button>
            )}
            {currentFamily.planType !== 'Free' && (
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyNotes + '/' + familyID)
                    : history.push(routes.familyNotesPaid + '/' + familyID);
                }}
                className={classes.activeTab}
              >
                Notes
              </Button>
            )}
            {currentFamily.planType !== 'Free' && (
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyLinks + '/' + familyID)
                    : history.push(routes.familyLinksPaid + '/' + familyID);
                }}
              >
                Links
              </Button>
            )}
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.familyUsers + '/' + familyID)
                  : history.push(routes.familyUsersPaid + '/' + familyID);
              }}
            >
              Family
            </Button>
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.familyAccount + '/' + familyID)
                  : history.push(routes.familyAccountPaid + '/' + familyID);
              }}
            >
              Account
            </Button>
          </div>
        </Paper>
        <Grid container spacing={3}>
          {childs.map((cardData, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3}>
              <CardComponent
                cardData={{
                  ...childSchema,
                  ...cardData,
                  ...{ isParent: false },
                  ...{ index }
                }}
                format={NotesCardDataFormat}
                onClick={openRightDrawer}
              />
            </Grid>
          ))}
          {parents.map((cardData, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3}>
              <CardComponent
                cardData={{
                  ...parentSchema,
                  ...cardData,
                  ...{ isParent: true },
                  ...{ index }
                }}
                format={NotesCardDataFormat}
                onClick={openRightDrawer}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <RightDrawer
        isOpen={isOpen}
        onClose={() => {
          onClose(true);
          onCloseForm();
        }}
        width={900}
      >
        <div className={classes.drawerLeftContent}>
          <div className={classes.leftContentMargin}>
            <div className={classes.leftContentHeader}>
              {isEditMode ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.siderEdit}
                  onClick={() => {
                    const defaultData = draftToHtml(
                      convertToRaw(defaultNote.getCurrentContent())
                    );
                    const memberNotes = memberData.notes;
                    if (defaultData === memberNotes) {
                      handleClose();
                    } else if (
                      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
                      !memberNotes
                    ) {
                      handleClose();
                    } else {
                      onSaveChanges();
                    }
                  }}
                  style={{ width: 165 }}
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.siderEdit}
                  onClick={() => setEditMode(true)}
                  startIcon={<img src={Edit} />}
                >
                  Edit
                </Button>
              )}
            </div>
            <Divider
              variant="fullWidth"
              style={{ marginBottom: 20, marginTop: 22 }}
            />
            {!isEditMode && !memberData.notes && (
              <Typography className={classes.siderDetail}>
                Write here ...
              </Typography>
            )}
            {!isEditMode &&
              memberData.notes &&
              memberData.notes.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' && (
                <Typography className={classes.siderDetail}>
                  Write here ...
                </Typography>
              )}
            <Editor
              stripPastedStyles={true}
              readOnly={!isEditMode}
              defaultEditorState={defaultNote}
              toolbarClassName={
                !isEditMode ? classes.toolbarHidden : classes.toolbar
              }
              onEditorStateChange={onEditorChange}
              editorClassName={
                !isEditMode ? classes.textEditor : classes.textEditorOnEdit
              }
              toolbar={{
                options: ['inline', 'list', 'link'],
                inline: {
                  options: ['bold', 'italic', 'underline', 'strikethrough']
                },
                list: {
                  options: ['unordered']
                },
                link: {
                  defaultTargetOption: '_blank',
                  options: ['link']
                }
              }}
            />
          </div>
        </div>
        <div className={classes.drawerRightContent}>
          <div style={{ padding: 18, zIndex: 1 }}>
            <div className={classes.header}>
              <Avatar
                aria-label="recipe"
                alt={memberData.initials}
                src={profileImage}
                className={classes.siderAvatar}
              >
                {memberData.initials}
              </Avatar>
              {memberData.firstName && (
                <Typography className={classes.label}>
                  {memberData.firstName}
                </Typography>
              )}
            </div>
            <Divider style={{ marginBottom: 20 }} />
            {!memberData.isParent && (
              <>
                {memberData.school && (
                  <>
                    <Typography className={classes.heading}>SCHOOL</Typography>
                    <Typography className={classes.siderDetail}>
                      {getSchoolName(memberData.school)}
                    </Typography>
                  </>
                )}
                {memberData.year && (
                  <>
                    <Typography className={classes.heading}>YEAR</Typography>
                    <Typography className={classes.siderDetail}>
                      {memberData.year}
                    </Typography>
                  </>
                )}
                <Divider style={{ marginBottom: 20 }} />

                {memberData.schoolPortal && (
                  <div>
                    <Typography className={classes.heading}>
                      SCHOOL PORTAL
                    </Typography>

                    <Button
                      color="secondary"
                      startIcon={
                        <img
                          src={ExternalLink}
                          alt=""
                          className={classes.icon}
                        />
                      }
                      onClick={() =>
                        window.open(memberData.schoolPortal, '_blank')
                      }
                      component={Link}
                    >
                      Website link
                    </Button>
                  </div>
                )}
                {memberData.schoolPortalLogin && (
                  <CopyToClipboard
                    onCopy={onCopy}
                    text={memberData.schoolPortalLogin}
                  >
                    <Button
                      color="secondary"
                      startIcon={<MailOutline className={classes.icon} />}
                      component={Link}
                    >
                      Click to copy login
                    </Button>
                  </CopyToClipboard>
                )}
                {memberData.schoolPortalPassword && (
                  <CopyToClipboard
                    onCopy={onCopy}
                    text={memberData.schoolPortalPassword}
                  >
                    <Button
                      color="secondary"
                      startIcon={
                        <img src={Lock} alt="" className={classes.icon} />
                      }
                      component={Link}
                    >
                      Click to copy password
                    </Button>
                  </CopyToClipboard>
                )}
              </>
            )}
          </div>
          <div className={classes.handBookContainer}>
            <img src={HandBook} alt="" className={classes.handBookClass} />
          </div>
        </div>
      </RightDrawer>
      {isOpenModal && (
        <Modal
          isOpen={isOpenModal}
          onClose={() => {
            handleClose();
            setOpen(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Save your changes?
            </Typography>
            <div className={classes.modalContent}>
              <Typography className={classes.modalSubTitle}>
                Your changes will be lost if you don&apos;t save them.
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={onSaveChanges}
                color="primary"
                variant="contained"
                size="large"
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
                className={classes.primaryButton}
              >
                {!isLoading && 'Save Changes'}
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                  setOpen(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Don&apos;t Save
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </Dashboard>
  );
}

export default withFirebase(NotesView);
