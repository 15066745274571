import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function FamiliesIcon({ color, ...props }) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="20" cy="20" r="20" />
        <g
          stroke={color || '#4C5B68'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(8 10)"
        >
          <path d="M17.455 19.636v-2.181c0-2.41-1.954-4.364-4.364-4.364H4.364C1.954 13.09 0 15.045 0 17.455v2.181" />
          <circle cx="8.727" cy="4.364" r="4.364" />
          <path d="M24 19.636v-2.181c-.001-1.99-1.347-3.725-3.273-4.222M16.364.142c1.93.494 3.28 2.234 3.28 4.227 0 1.993-1.35 3.733-3.28 4.227" />
        </g>
      </g>
    </SvgIcon>
  );
}
export default FamiliesIcon;
