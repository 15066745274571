function isObject(object) {
  return object != null && typeof object === 'object' && !Array.isArray(object);
}

function isArray(object) {
  return object != null && typeof object === 'object' && Array.isArray(object);
}

export function deepEqual(object1, object2) {
  return isObject(object1) && isObject(object2)
    ? objectsEqual(object1, object2)
    : arraysEqual(object1, object2);
}

// _.differenceWith(obj1, obj2, _.IsEqual)

function objectsEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    if (
      (isObject(val1) && isObject(val2)) ||
      (isArray(val1) && isArray(val2))
    ) {
      return deepEqual(val1, val2);
    } else if (val1 !== val2) {
      return false;
    }
  }
  return true;
}

function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (isObject(arr1[i]) && isObject(arr2[i])) {
      return deepEqual(arr1[i], arr2[i]);
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}
