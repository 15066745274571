import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Paper,
  Button,
  Divider,
  Grid,
  Typography,
  Checkbox,
  CircularProgress,
  Avatar,
  Menu,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Select,
  Badge,
  Input
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { colors, routes, year } from 'src/utils/constant';
import {
  loaderStop,
  loaderStart,
  addMessage
} from 'src/redux/actions/appActions';
import Dashboard from '../layout/Dashboard';
import RightDrawer from 'src/components/RightDrawer';
import CardComponent from './components/CardComponent';
import { RightArrow } from 'src/assets/Icons';
import { withFirebase, FIREBASE_API_URL } from 'src/utils/firebase';
import Modal from 'src/components/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  MoreVert,
  Add as AddIcon,
  CameraAlt as CameraAltIcon
} from '@material-ui/icons';
import { parentSchema, childSchema } from 'src/utils/schemas';
import { setFamily } from 'src/redux/actions/familyActions';
import { generateFileHash, isValidFileType } from 'src/utils/functions';
import { Link } from 'react-router-dom';
import PhoneInput from 'src/components/PhoneInput';
import ScanningUploadFileModal from 'src/components/ScanningUploadFileModal';
import apiShortIcon from 'src/assets/api_icon_outline.svg';
import { getSchools } from 'src/services/schools';
import { getGroups } from 'src/services/groups';
import { MESSAGE_TYPES } from 'src/constants/common';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  tabNavigations: {
    padding: '10px 20px 0',
    marginBottom: 40
  },
  navigationButton: {
    width: 120,
    textTransform: 'none'
  },
  actionButton: {
    margin: '15px 0 20px',
    textTransform: 'none'
  },
  activeTab: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0
  },
  checked: {},
  checkbox: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  bredthcrumbTitle: {
    color: '#536e7b',
    fontSize: 16,
    marginRight: 24,
    display: 'inline-block',
    cursor: 'pointer'
  },
  inputControlBox: {
    width: 427,
    backgroundColor: '#ffffff',
    marginTop: 24
  },
  NonAUchildBox: {
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)',
    pointerEvents: 'none',
    userSelect: 'none',
    '&&::selection': {
      color: 'transparent',
      background: 'transparent'
    }
  },
  AuChildBox: {},
  errorBox: {
    border: '1px solid red',
    width: 427,
    backgroundColor: '#ffffff',
    marginTop: 24,
    borderRadius: '4px'
  },
  leftContentHeader: {
    textAlign: 'right'
  },
  label: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: 0.13,
    color: colors.primaryDarkGrey,
    // marginLeft: 14,
    marginTop: 20,
    float: 'left'
  },
  siderAvatar: {
    marginTop: 12,
    width: 40,
    float: 'left'
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    height: 71
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primaryDarkGrey
  },
  siderDetail: {
    fontSize: 14,
    color: colors.primaryDarkGrey,
    marginBottom: 8
  },
  icon: {
    marginRight: 17,
    height: 20,
    width: 20
  },
  siderSave: {
    width: 95,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary
  },
  mainHeading: {
    width: 93,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  subHeading: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    width: 344
  },
  accountText: {
    fontWeight: 500,
    color: colors.primary
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    width: 523,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  modalContent: {
    minHeight: 75,
    paddingTop: 12
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark
  },
  modalBox: {
    width: 450
    // height: 170,
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  modalActions: {
    marginTop: '2%'
  },
  imageContainer: {
    width: 100,
    height: 100,
    backgroundColor: colors.primaryLightGrey,
    marginTop: 16,
    borderRadius: '50%',
    fontSize: '32px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: colors.primaryDarkGrey
  },
  NonAUImageContainer: {
    width: 100,
    height: 100,
    backgroundColor: colors.primaryLightGrey,
    marginTop: 16,
    borderRadius: '50%',
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)',
    pointerEvents: 'none',
    userSelect: 'none',
    '&&::selection': {
      color: 'transparent',
      background: 'transparent'
    }
  },
  popoverClass: {
    '& .MuiPaper-rounded': {
      right: '70px',
      left: 'unset  !important',
      top: '70px !important'
    }
  },
  dividerClass: {
    marginBottom: 22,
    marginTop: 22
  },
  moreVertButton: {
    justifyContent: 'flex-end',
    padding: 'unset  !important'
    // '& .MuiButton-text':{
    // }
  },
  profileImageWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  removeImageContainer: {
    marginLeft: 30,
    marginTop: 20,
    color: colors.primaryDarkGrey,
    cursor: 'pointer'
  },
  menuItemClass: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

const AccountsCardDataFormat = {
  headerLink: 'left',
  buttonTextHover: 'Manage',
  headerLinkText: 'Primary',
  linkColor: '#FFFFFF',
  displayParent: 1,
  nameToupper: 1
};

const AccountsCardDataFormatPaid = {
  headerLink: 'left',
  buttonTextHover: 'Manage',
  headerLinkText: 'Primary',
  linkColor: '#FFFFFF',
  bordered: 1,
  displayParent: 0,
  nameToupper: 1
};

function FamilyUsersView({ firebase }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const isLoading = useSelector((state) => state.app.isLoading);
  // const schools = useSelector((state) => state.auth.schools);
  const [schools, setSchools] = React.useState([]);
  const [isOpen, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [data, setData] = useState([]);
  const [isOpenModal, setOpenModal] = useState({ open: false, values: null });
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [isNoParentDeleteModal, setNoParentDeleteModalModal] = useState(false);
  const [openDeleteMenu, setOpenDeleteMenu] = React.useState(null);
  const [isDisableButton, setDisableButton] = React.useState();
  const [deleteText, setDeleteText] = React.useState('');
  const [profileImageObject, setProfileImageObject] = React.useState(null);
  // const [years, setYears] = React.useState(null);
  const currentFamily = useSelector((state) => state.family.currentFamily);
  const [Groups, setGroupData] = useState([]);
  const [schoolCatagories, setSchoolCategories] = useState([]);
  const childVisiility = useSelector((state) => state?.auth?.user?.country);
  const [fileUploadModal, setFileUploadModal] = useState({
    isModalOpen: false,
    fileUploadState: 0
  });

  useEffect(() => {
    // PHASE2/CHANGES/SCHOOL

    getSchools()
      .then((result) => {
        const schools = [];
        result.forEach((school) => {
          schools.push({
            id: school.id,
            name: school.schoolName,
            isFree: school.freeCalendars.length ? true : false,
            categories: school.categories
          });
        });
        setSchools(schools);
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          addMessage(
            'Server connection issue. Please refresh',
            MESSAGE_TYPES.ERROR
          )
        );
      });
  }, [dispatch]);

  const onClose = useCallback(
    (currentState) => {
      setOpen(currentState);
    },
    [setOpen]
  );

  const handleOpenDeleteMenu = (event) => {
    if (!openDeleteMenu) {
      setOpenDeleteMenu(event.currentTarget);
    }
  };

  const handleCloseDeleteMenu = () => {
    setOpenDeleteMenu(null);
  };

  const openRightDrawer = useCallback(
    (data) => {
      const findSchools = _.find(
        schools,
        (school) => school.id === data?.school
      );

      const allCategory = findSchools?.categories?.map((data) => {
        return {
          key: data?.name,
          value: data?.id
        };
      });
      setSchoolCategories(allCategory);
      setOpen(true);
      setData(data);
      handleClose();
      // setYears(data.years && data.years.length ? data.years : []);
      setProfileImageObject(data.profileImage);
    },
    // eslint-disable-next-line
    [setOpen, schools]
  );
  const handleClose = useCallback(() => {
    setOpenModal({ open: false, values: null });
  }, [setOpenModal]);
  const handleDeleteCloseModal = useCallback(() => {
    setOpenDeleteModal(false);
  }, [setOpenDeleteModal]);

  const onDeleteMenuClick = () => {
    if (data.isParent) {
      if (currentFamily.parents.length === 1) {
        setOpenDeleteModal(false);
        setNoParentDeleteModalModal(true);
      } else {
        setOpenDeleteModal(true);
      }
    } else {
      setOpenDeleteModal(true);
    }
  };

  const deleteAuthUsercaller = async (userId) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: userId
      })
    };
    fetch(`${FIREBASE_API_URL}/deleteUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result;
      })
      .then(
        (result) => {
          if (result.message === 'Successfully deleted user');
        },
        (error) => {
          const errorMessage = _.get(error, 'message', '');
          console.log(errorMessage);
          dispatch(addMessage(errorMessage, MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      );
  };

  const deleteUserHandle = async () => {
    try {
      if (data.isParent) {
        const found = currentFamily.parents.find(
          (parent, index) => index === data.index
        );
        if (found) {
          currentFamily.parents.splice(data.index, 1);
        }
      } else {
        const found = currentFamily.childs.find(
          (child, index) => index === data.index
        );
        if (found) {
          currentFamily.childs.splice(data.index, 1);
          await deleteAuthUsercaller(data.id);
          await firebase.deleteUser(data.id);
        }
      }
      setDeleteText('');
      dispatch(loaderStart());
      firebase
        .updateFamily(currentFamily, currentFamily.id)
        .then(
          () => {
            dispatch(loaderStop());
            dispatch(setFamily(currentFamily));
            setOpenDeleteModal(false);
            setOpenConfirmDeleteModal(true);
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(
              addMessage(
                'User was not deleted. Please try again',
                MESSAGE_TYPES.ERROR
              )
            );
            dispatch(loaderStop());
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(
            addMessage(
              'User was not deleted. Please try again',
              MESSAGE_TYPES.ERROR
            )
          );
          dispatch(loaderStop());
        });
    } catch (error) {
      dispatch(
        addMessage(
          'User was not deleted. Please try again',
          MESSAGE_TYPES.ERROR
        )
      );
    }
  };

  const handleDeleteConfirmCloseModal = useCallback(() => {
    setOpenConfirmDeleteModal(false);
    setOpenDeleteMenu();
  }, [setOpenConfirmDeleteModal]);

  const handleNoParentDeleteModal = () => {
    setNoParentDeleteModalModal(false);
    setOpenDeleteMenu(false);
  };



  const getComparedAndDiffrece = (data, compArray = []) => {
    return data.filter((el) => !compArray.includes(el));
  };

  const saveUpdated = ({ currentFamily, id }) => {
    dispatch(loaderStart());

    const selectedChildOldData = JSON.parse(JSON.stringify(data));
    const groupData = [];
    const getUpdatedGroups = [];

    getGroups()
      .then(function (result) {
        result.data.forEach(function (item) {
          if (item.groupName) {
            groupData.push(item);
          }
        });
        dispatch(loaderStop());
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(
          addMessage(
            'Server connection issue. Please refresh',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });

    if (currentFamily.childs.length) {
      currentFamily.childs.forEach((child) => {
        if (child.id === selectedChildOldData.id) {
          let mergeNewClassAndGroups = _.compact([child.group, child.groups]);
          mergeNewClassAndGroups = _.uniq(
            // eslint-disable-next-line prefer-spread
            [].concat.apply([], mergeNewClassAndGroups)
          );
          let mergeOldClassAndGroups = _.compact([
            selectedChildOldData.group,
            selectedChildOldData.groups
          ]);
          mergeOldClassAndGroups = _.uniq(
            // eslint-disable-next-line prefer-spread
            [].concat.apply([], mergeOldClassAndGroups)
          );

          // get old groups id which is updated from old selection
          const removeGroups = getComparedAndDiffrece(
            mergeOldClassAndGroups,
            mergeNewClassAndGroups
          );

          // get new groups id which is updated from old selection
          const newGroupReceived = getComparedAndDiffrece(
            mergeNewClassAndGroups,
            mergeOldClassAndGroups
          );

          const allGroupIds = _.compact([child.group, child.groups]);

          const pushRecord = {
            familyId: child.id,
            groupIds: [],
            // eslint-disable-next-line prefer-spread
            familyGroups: [].concat.apply([], allGroupIds) || []
          };
          if (newGroupReceived) {
            newGroupReceived.forEach((el) => {
              pushRecord.groupIds.push({
                id: el,
                isAdd: true
              });
            });
          }
          if (removeGroups) {
            removeGroups.forEach((el) => {
              pushRecord.groupIds.push({
                id: el,
                isAdd: false
              });
            });
          }
          getUpdatedGroups.push({
            ...pushRecord
          });
        }
      });
    }
    if (getUpdatedGroups.length) {
      getUpdatedGroups.forEach((grp) => {
        if (grp.groupIds.length) {
          // Below function for update groups
          firebase.updateGroupsByFamilyId(grp);
          // Below function for update group related all entries
          firebase.updateGroupEntriesByFamilyId(grp);
        }
      });
    }
    firebase
      .updateFamily(currentFamily, id)
      .then(
        () => {
          dispatch(loaderStop());
          onClose();
          setOpen(false);
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(
            addMessage(
              'Family was not created. Please try again',
              MESSAGE_TYPES.ERROR
            )
          );
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(
          addMessage(
            'Family was not created. Please try again',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });
  };

  const familyID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  useEffect(() => {
    if (!currentFamily.familySurname && familyID) {
      dispatch(loaderStart());
      firebase
        .getFamilyByID(familyID)
        .then(
          async (doc) => {
            const familyData = doc.data();
            if (familyData) {
              dispatch(setFamily(familyData));
            }
            dispatch(loaderStop());
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(
              addMessage('Error on getting family', MESSAGE_TYPES.ERROR)
            );
            dispatch(loaderStop());
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    }
  }, [currentFamily, familyID, firebase, dispatch]);

  useEffect(() => {
    getGroups().then(function (result) {
      const GroupData = [];
      result.data.forEach(function (item) {
        if (item.groupName) {
          GroupData.push(item);
        }
      });
      setGroupData(GroupData);
      dispatch(loaderStop());
    });
  }, [dispatch]);

  // const { familySurname, id, parents, childs } = useMemo(() => {
  //   if (currentFamily) {
  //     return {
  //       familySurname: currentFamily.familySurname,
  //       id: currentFamily.id,
  //       parents: currentFamily.parents,
  //       childs: currentFamily.childs
  //     };
  //   }
  //   return {
  //     familySurname: '',
  //     id: '',
  //     parents: [],
  //     childs: []
  //   };
  // }, [currentFamily]);

  const handleOnClose = () => {
    setFileUploadModal((prev) => ({ ...prev, isModalOpen: false }));
  };

  const uploadProfileImage = (event) => {
    // if (isLoading) {
    //   return <CircularProgress color="secondary" size={25} />;
    // }
    const fileLength = event.target.files.length;
    if (fileLength > 0) {
      const file = event.target.files[0];
      const fileType = file.name.split('.').pop().toLowerCase();
      event.target.value = '';
      if (isValidFileType(fileType, true)) {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 0 });
        firebase
          .uploadProfilePicture(file, familyID)
          .then((snapShot) => {
            // takes a snap shot of the process as it is happening
            if (snapShot && snapShot.state === 'success') {
              firebase
                .getDownloadURLForProfileImage(file, familyID)
                .then((fireBaseUrl) => {
                  const profileImage = {
                    url: fireBaseUrl,
                    storage_path: `userProfiles/${familyID}/${file.name}`
                  };
                  setProfileImageObject(profileImage);
                  handleOnClose();
                })
                .catch((err) => {
                  // catches the errors
                  console.log('Error while Getting Download URL', err);
                  handleOnClose();
                });
            } else {
              setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
            }
          })
          .catch((err) => {
            // catches the errors
            console.log('Error while Uploading Profile Picture', err);
            setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
          });
      } else {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 1 });
      }
    }
  };

  const removeProfileImage = () => {
    const profileImageObjectData = profileImageObject
      ? profileImageObject
      : data.profileImage
        ? data.profileImage
        : null;
    // const profileImageObjectData = data.profileImage
    //   ? data.profileImage
    //   : profileImageObject;
    if (profileImageObjectData) {
      const storagePath = _.get(profileImageObjectData, 'storage_path', '');
      firebase
        .deleteProfileImageFromStorage(storagePath)
        .then(() => {
          setProfileImageObject(null);
          if (data.profileImage !== null) {
            if (data.isParent) {
              currentFamily.parents[data.index]['profileImage'] = null;
            } else {
              currentFamily.childs[data.index]['profileImage'] = null;
            }
            saveUpdated({ currentFamily, id: currentFamily.id });
          }
        })
        .catch((error) => {
          console.log('error while removing profile picture', error);
        });
    }
  };

  const validationSchemaParent = Yup.object().shape({
    firstName: Yup.string().trim().required('First name is required'),
    lastName: Yup.string().trim().required('Last name is required'),
    initials: Yup.string()
      .trim()
      .required('Initials is required')
      .max(2, 'Initials contains only 2 characters'),
    emailAddress: Yup.string()
      .trim()
      .email()
      .required('Valid email is required'),
    id: Yup.string().trim(),
    profileImage: Yup.mixed()
  });
  const validationSchemaChild = Yup.object().shape({
    userId: Yup.string().trim(),
    firstName: Yup.string().trim().required('First name is required'),
    lastName: Yup.string().trim().required('Last name is required'),
    initials: Yup.string()
      .trim()
      .required('Initials is required')
      .max(2, 'Initials contains only 2 characters'),
    profileImage: Yup.mixed(),
    school: Yup.string().trim().required('School is required'),
    year: Yup.string().trim().required('Year is required'),
    schoolPortal: Yup.string().trim().url('Enter valid Url'),
    schoolPortalLogin: Yup.string().trim(),
    schoolPortalPassword: Yup.string().trim(),
    id: Yup.string().trim()
  });

  const notSaveHandle = () => {
    if (data.isParent) {
      currentFamily.parents[data.index] = {
        ...currentFamily.parents[data.index],
        ...data
      };
    } else {
      currentFamily.childs[data.index] = {
        ...currentFamily.childs[data.index],
        ...data
      };
      const schoolData = currentFamily.childs.reduce((v, { school }) => {
        v.push(school);
        return v;
      }, []);
      currentFamily.childsSchools = schoolData;
    }
    handleClose();
    setOpen(false);
  };

  const onCloseForm = () => {
    const values = formValues;
    if (data.isParent) {
      currentFamily.parents[data.index]['id'] = values.id;
      currentFamily.parents[data.index]['firstName'] = values.firstName;
      currentFamily.parents[data.index]['lastName'] = values.lastName;
      currentFamily.parents[data.index]['initials'] = values.initials;
      currentFamily.parents[data.index]['emailAddress'] = values.emailAddress;
      currentFamily.parents[data.index]['phoneNo'] = values.phoneNo;
      if (profileImageObject) {
        currentFamily.parents[data.index]['profileImage'] = profileImageObject;
      }
      const valuesObject = {
        id: values.id,
        firstName: values.firstName,
        lastName: values.lastName,
        initials: values.initials,
        emailAddress: values.emailAddress,
        phoneNo: values.phoneNo,
        profileImage: profileImageObject
      };
      const dataObject = {
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        initials: data.initials,
        emailAddress: data.emailAddress,
        phoneNo: data.phoneNo,
        profileImage: data.profileImage
      };
      const sameData = _.isEqual(valuesObject, dataObject);
      if (sameData) {
        setOpen(false);
        handleClose();
      } else {
        setOpenModal({
          open: true,
          values: { currentFamily, id: currentFamily.id }
        });
      }
    } else {
      currentFamily.childs[data.index] = {
        ...currentFamily.childs[data.index],
        ...values
      };
      if (profileImageObject) {
        currentFamily.childs[data.index]['profileImage'] = profileImageObject;
      }
      const schoolData = currentFamily.childs.reduce((v, { school }) => {
        v.push(school);
        return v;
      }, []);
      currentFamily.childsSchools = schoolData;

      const valuesObject = {
        firstName: values.firstName,
        lastName: values.lastName,
        initials: values.initials,
        group: values.group,
        groups: values.groups,
        id: values.id,
        school: values.school,
        year: values.year,
        schoolPortal: values.schoolPortal,
        schoolPortalLogin: values.schoolPortalLogin,
        schoolPortalPassword: values.schoolPortalPassword,
        profileImage: profileImageObject ? profileImageObject : null
      };
      const dataObject = {
        firstName: data.firstName,
        lastName: data.lastName,
        initials: data.initials,
        group: data.group,
        groups: data.groups,
        id: data.id,
        school: data.school,
        year: data.year,
        schoolPortal: data.schoolPortal,
        schoolPortalLogin: data.schoolPortalLogin,
        schoolPortalPassword: data.schoolPortalPassword,
        profileImage: data.profileImage ? data.profileImage : null
      };
      const sameData = _.isEqual(valuesObject, dataObject);
      if (sameData) {
        setOpen(false);
        handleClose();
      } else {
        setOpenModal({
          open: true,
          values: { currentFamily, id: currentFamily.id }
        });
      }
    }
  };

  const handleGroupChange = (event) => {
    const updatedGroups = Groups.map((groupData) => {
      const selectValue = event.target.value;
      if (selectValue.includes(groupData.id)) {
        return {
          ...groupData,
          selected: true
        };
      } else {
        return {
          ...groupData,
          selected: false
        };
      }
    });
    setGroupData(updatedGroups);
  };

  return (
    <Dashboard>
      <div className={classes.root}>
        <div>
          <Typography
            variant="body1"
            className={classes.bredthcrumbTitle}
            onClick={() =>
              window.location.pathname.match('/new')
                ? history && history.push(routes.familiesFreeNew)
                : window.location.pathname.match('/pending')
                  ? history && history.push(routes.familiesFreePending)
                  : window.location.pathname.match('/paid')
                    ? history && history.push(routes.familiesPaid)
                    : history && history.push(routes.familiesFree)
            }
          >
            Members
          </Typography>
          <RightArrow
            viewBox="0 0 24 24"
            height={24}
            width={24}
            style={{ fontSize: 22, verticalAlign: 'middle' }}
          />
        </div>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20, marginTop: 22 }}
        >
          {currentFamily.familySurname}
        </Typography>
        <Paper className={classes.tabNavigations}>
          <div>
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.familyCalendar + '/' + familyID)
                  : history.push(routes.familyCalendarPaid + '/' + familyID);
              }}
            >
              Calendar
            </Button>
            {currentFamily.planType !== 'Free' && (
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyFiles + '/' + familyID)
                    : history.push(routes.familyFilesPaid + '/' + familyID);
                }}
              >
                Files
              </Button>
            )}
            {currentFamily.planType !== 'Free' && (
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyNotes + '/' + familyID)
                    : history.push(routes.familyNotesPaid + '/' + familyID);
                }}
              >
                Notes
              </Button>
            )}
            {currentFamily.planType !== 'Free' && (
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyLinks + '/' + familyID)
                    : history.push(routes.familyLinksPaid + '/' + familyID);
                }}
              >
                Links
              </Button>
            )}
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.familyUsers + '/' + familyID)
                  : history.push(routes.familyUsersPaid + '/' + familyID);
              }}
              className={classes.activeTab}
            >
              Family
            </Button>
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.familyAccount + '/' + familyID)
                  : history.push(routes.familyAccountPaid + '/' + familyID);
              }}
            >
              Account
            </Button>
          </div>
          <Divider />
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.actionButton}
            startIcon={<AddIcon />}
            onClick={() => {
              history.push(routes.familyAccount + '/' + familyID);
            }}
          >
            New Child
          </Button>
        </Paper>
        <Grid container spacing={3}>
          {currentFamily.parents.map((cardData, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3}>
              <CardComponent
                cardData={{
                  ...parentSchema,
                  ...cardData,
                  ...{ isParent: true },
                  ...{ index }
                }}
                format={
                  currentFamily.planType === 'Free'
                    ? AccountsCardDataFormat
                    : AccountsCardDataFormatPaid
                }
                onClick={openRightDrawer}
              />
            </Grid>
          ))}
          {currentFamily.childs.map((cardData, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3}>
              <CardComponent
                cardData={{
                  ...childSchema,
                  ...cardData,
                  ...{ isParent: false },
                  ...{ index }
                }}
                format={
                  currentFamily.planType === 'Free'
                    ? AccountsCardDataFormat
                    : AccountsCardDataFormatPaid
                }
                onClick={openRightDrawer}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <RightDrawer
        isOpen={isOpen}
        onClose={() => {
          onClose(true);
          onCloseForm();
        }}
        width={520}
      >
        <div>
          <Formik
            initialValues={{
              firstName: data.firstName || '',
              lastName: data.lastName || '',
              emailAddress: data.emailAddress || '',
              phoneNo: data.phoneNo || '',
              initials: data.initials || '',
              group: data.group || '',
              groups: data.groups || [],
              school: data.school || '',
              schoolPortal: data.schoolPortal || '',
              schoolPortalLogin: data.schoolPortalLogin || '',
              schoolPortalPassword: data.schoolPortalPassword || '',
              year: data.year || '',
              profileImage: data.profileImage || '',
              id: data.id || generateFileHash(20)
            }}
            validationSchema={
              data.isParent ? validationSchemaParent : validationSchemaChild
            }
            onSubmit={(values) => {
              if (data.isParent) {
                currentFamily.parents[data.index]['id'] = values.id;
                currentFamily.parents[data.index]['firstName'] =
                  values.firstName;
                currentFamily.parents[data.index]['lastName'] = values.lastName;
                currentFamily.parents[data.index]['initials'] = values.initials;
                currentFamily.parents[data.index]['emailAddress'] =
                  values.emailAddress;
                currentFamily.parents[data.index]['phoneNo'] = values.phoneNo;
                if (profileImageObject) {
                  currentFamily.parents[data.index]['profileImage'] =
                    profileImageObject;
                }
                const valuesObject = {
                  id: values.id,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  initials: values.initials,
                  emailAddress: values.emailAddress,
                  phoneNo: values.phoneNo,
                  profileImage: profileImageObject
                };
                const dataObject = {
                  id: data.id,
                  firstName: data.firstName,
                  lastName: data.lastName,
                  initials: data.initials,
                  emailAddress: data.emailAddress,
                  phoneNo: data.phoneNo,
                  profileImage: data.profileImage
                };
                const sameData = _.isEqual(valuesObject, dataObject);
                if (sameData) {
                  setOpen(false);
                } else {
                  saveUpdated({ currentFamily, id: currentFamily.id });
                  // setOpenModal({
                  //   open: true,
                  //   values: { currentFamily, id: currentFamily.id }
                  // });
                }
              } else {
                currentFamily.childs[data.index] = {
                  ...currentFamily.childs[data.index],
                  ...values
                };
                if (profileImageObject) {
                  currentFamily.childs[data.index]['profileImage'] =
                    profileImageObject;
                }
                const schoolData = currentFamily.childs.reduce(
                  (v, { school }) => {
                    v.push(school);
                    return v;
                  },
                  []
                );
                currentFamily.childsSchools = schoolData;

                const valuesObject = {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  initials: values.initials,
                  group: values.group,
                  groups: values.groups,
                  id: values.id,
                  school: values.school,
                  year: values.year,
                  schoolPortal: values.schoolPortal,
                  schoolPortalLogin: values.schoolPortalLogin,
                  schoolPortalPassword: values.schoolPortalPassword,
                  profileImage: profileImageObject ? profileImageObject : null
                };
                const dataObject = {
                  firstName: data.firstName,
                  lastName: data.lastName,
                  initials: data.initials,
                  group: data.group,
                  groups: data.groups,
                  id: data.id,
                  school: data.school,
                  year: data.year,
                  schoolPortal: data.schoolPortal,
                  schoolPortalLogin: data.schoolPortalLogin,
                  schoolPortalPassword: data.schoolPortalPassword,
                  profileImage: data.profileImage ? data.profileImage : null
                };
                const sameData = _.isEqual(valuesObject, dataObject);
                if (sameData) {
                  setOpen(false);
                } else {
                  saveUpdated({ currentFamily, id: currentFamily.id });
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              handleLoad,
              setFieldValue
            }) => {
              const isSubmitButtonDisabled = data.isParent
                ? _.isEmpty(values.firstName) ||
                  _.isEmpty(values.lastName) ||
                  _.isEmpty(values.initials) ||
                  _.isEmpty(values.emailAddress) ||
                  _.isEmpty(values.phoneNo)
                : _.isEmpty(values.firstName) ||
                  _.isEmpty(values.lastName) ||
                  _.isEmpty(values.initials) ||
                  _.isEmpty(values.school) ||
                  _.isEmpty(values.year.toString());
              setDisableButton(isSubmitButtonDisabled);
              if (
                !_.isEmpty(touched) &&
                !isSubmitButtonDisabled &&
                !_.isEmpty(errors)
              ) {
                setDisableButton(true);
              }
              const profileImageURL = data.profileImage
                ? data.profileImage
                : profileImageObject;
              setFormValues(values);
              return (
                // eslint-disable-next-line react/no-unknown-property
                <form noValidate onSubmit={handleSubmit} onLoad={handleLoad}>
                  <>
                    <div style={{ margin: '10px 30px', position: 'relative' }}>
                      <span className={classes.mainHeading}>Edit User</span>
                      <div className={classes.subHeading}>
                        To edit parent logins, go to
                        <Link
                          style={{
                            textDecoration: 'none'
                          }}
                          button
                          onClick={() => {
                            history.push(routes.familyAccount + '/' + familyID);
                          }}
                        >
                          <span className={classes.accountText}> Account</span>
                        </Link>
                      </div>
                      <div
                        style={{
                          float: 'right',
                          position: 'absolute',
                          top: 15,
                          right: 0
                        }}
                      >
                        <Button
                          variant="contained"
                          color={isDisableButton ? 'secondary' : 'primary'}
                          className={classes.siderSave}
                          onClick={handleSubmit}
                          disabled={isDisableButton}
                          startIcon={
                            isLoading && (
                              <CircularProgress color="secondary" size={25} />
                            )
                          }
                        >
                          {!isLoading && 'Save'}
                        </Button>
                        <Button
                          aria-controls="delete-menu"
                          aria-haspopup="true"
                          onClick={handleOpenDeleteMenu}
                          className={classes.moreVertButton}
                        >
                          <MoreVert />
                          <Menu
                            id="delete-menu"
                            open={Boolean(openDeleteMenu)}
                            onClose={handleCloseDeleteMenu}
                            className={classes.popoverClass}
                          >
                            <MenuItem onClick={onDeleteMenuClick}>
                              Delete
                            </MenuItem>
                          </Menu>
                        </Button>
                      </div>
                    </div>
                    <Divider
                      variant="middle"
                      className={classes.dividerClass}
                    />
                    <div style={{ margin: '10px 30px' }}>
                      {data.isParent ? (
                        <React.Fragment>
                          <div className={classes.label}>
                            Parent 1 (Primary)
                          </div>
                          <FormControl
                            component="fieldset"
                            style={{ width: '100%' }}
                          >
                            <TextField
                              label="First Name"
                              variant="outlined"
                              onBlur={handleBlur}
                              name="firstName"
                              value={values.firstName ? values.firstName : ''}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue(
                                  `initials`,
                                  `${e.target.value.charAt(
                                    0
                                  )}${values.lastName.charAt(0)}`
                                );
                              }}
                              error={Boolean(
                                touched.firstName && errors.firstName
                              )}
                              helperText={touched.firstName && errors.firstName}
                              className={classes.inputControlBox}
                              autoFocus
                            />
                          </FormControl>
                          <FormControl component="fieldset">
                            <TextField
                              label="Last Name"
                              variant="outlined"
                              onBlur={handleBlur}
                              name="lastName"
                              value={values.lastName ? values.lastName : ''}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue(
                                  `initials`,
                                  `${values.firstName.charAt(
                                    0
                                  )}${e.target.value.charAt(0)}`
                                );
                              }}
                              error={Boolean(
                                touched.lastName && errors.lastName
                              )}
                              helperText={touched.lastName && errors.lastName}
                              className={classes.inputControlBox}
                            />
                          </FormControl>
                          <FormControl
                            component="fieldset"
                            style={{ width: '100%' }}
                          >
                            <TextField
                              label="Initials"
                              variant="outlined"
                              className={classes.inputControlBox}
                              onBlur={handleBlur}
                              name="initials"
                              value={values.initials ? values.initials : ''}
                              onChange={handleChange}
                              error={Boolean(
                                touched.initials && errors.initials
                              )}
                              helperText={touched.initials && errors.initials}
                              style={{ width: 250 }}
                            />
                          </FormControl>
                          <FormControl component="fieldset">
                            <TextField
                              label="Email Address (Primary)"
                              variant="outlined"
                              className={classes.inputControlBox}
                              onBlur={handleBlur}
                              name="emailAddress"
                              value={
                                values.emailAddress ? values.emailAddress : ''
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  `emailAddress`,
                                  e.target.value.trim()
                                );
                              }}
                              error={Boolean(
                                touched.emailAddress && errors.emailAddress
                              )}
                              helperText={
                                touched.emailAddress && errors.emailAddress
                              }
                            />
                          </FormControl>
                          <FormControl
                            component="fieldset"
                            // classes={{ root: classes.formControl }}
                          >
                            <Input
                              inputComponent={PhoneInput && PhoneInput}
                              disableUnderline
                              onBlur={handleBlur}
                              name="phoneNo"
                              value={values.phoneNo ? values.phoneNo : ''}
                              onChange={(e) => {
                                setFieldValue(`phoneNo`, e.target.value.trim());
                              }}
                              // error={Boolean(touched.phoneNo && errors.phoneNo)}
                              // helperText={touched.phoneNo && errors.phoneNo}
                              // error={true}
                              // helperText={
                              //   touched.parents &&
                              //   errors.parents &&
                              //   touched.parents[idx] &&
                              //   touched.parents[idx].phoneNo &&
                              //   errors.parents[idx] &&
                              //   // 'errors.parents[idx].phoneNo'
                              //   ''
                              // }
                              className={classes.inputControlBox}
                              style={{ margin: '20px 0 20px 0' }}
                            />
                            <p
                              style={{
                                color: '#f44336',
                                fontSize: '15px',
                                margin: '22px 10px 10px 10px'
                              }}
                            >
                              {errors?.phoneNo}
                            </p>
                          </FormControl>

                          <div className={classes.profileImageWrapper}>
                            <label htmlFor="upload-profile-picture">
                              <TextField
                                style={{ display: 'none' }}
                                id="upload-profile-picture"
                                name="upload-profile-picture"
                                type="file"
                                onChange={(e) => uploadProfileImage(e)}
                              />
                              <Badge
                                overlap="circle"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                badgeContent={
                                  _.isEmpty(profileImageURL) && (
                                    <CameraAltIcon />
                                  )
                                }
                              >
                                <Avatar
                                  className={classes.imageContainer}
                                  alt={values.initials}
                                  src={_.get(profileImageURL, 'url', '')}
                                >
                                  {values.initials}
                                </Avatar>
                              </Badge>
                            </label>
                            {!_.isEmpty(profileImageURL) && (
                              <div
                                className={classes.removeImageContainer}
                                onClick={() => removeProfileImage()}
                              >
                                Remove
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className={classes.label}>Child 1 </div>
                          <FormControl
                            component="fieldset"
                            style={{ width: '100%' }}
                          >
                            <TextField
                              label="First Name"
                              variant="outlined"
                              onBlur={handleBlur}
                              name="firstName"
                              value={
                                currentFamily.planType === 'Free' &&
                                childVisiility !== 'Australia'
                                  ? 'Redacted'
                                  : values?.firstName
                              }
                              onChange={handleChange}
                              error={Boolean(
                                touched.firstName && errors.firstName
                              )}
                              helperText={touched.firstName && errors.firstName}
                              // className={classes.inputControlBox}
                              className={classes.inputControlBox}
                              inputProps={{
                                className:
                                  currentFamily.planType === 'Free' &&
                                  childVisiility !== 'Australia'
                                    ? classes.NonAUchildBox
                                    : classes.AuChildBox,
                                readOnly:
                                  currentFamily.planType === 'Free' &&
                                  childVisiility !== 'Australia'
                                    ? true
                                    : false
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              // readOnly={values?.firstName ? true : false }
                              autoFocus
                            />
                          </FormControl>
                          <FormControl component="fieldset">
                            <TextField
                              label="Last Name"
                              variant="outlined"
                              onBlur={handleBlur}
                              name="lastName"
                              value={
                                currentFamily.planType === 'Free' &&
                                childVisiility !== 'Australia'
                                  ? 'Redacted'
                                  : values?.lastName
                              }
                              onChange={handleChange}
                              error={Boolean(
                                touched.lastName && errors.lastName
                              )}
                              helperText={touched.lastName && errors.lastName}
                              className={classes.inputControlBox}
                              inputProps={{
                                className:
                                  currentFamily.planType === 'Free' &&
                                  childVisiility !== 'Australia'
                                    ? classes.NonAUchildBox
                                    : classes.AuChildBox,
                                readOnly:
                                  currentFamily.planType === 'Free' &&
                                  childVisiility !== 'Australia'
                                    ? true
                                    : false
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                          </FormControl>
                          <div style={{ width: 427 }}>
                            <FormControl component="fieldset">
                              <TextField
                                label="Initials"
                                variant="outlined"
                                className={classes.inputControlBox}
                                onBlur={handleBlur}
                                name="initials"
                                value={
                                  currentFamily.planType === 'Free' &&
                                  childVisiility !== 'Australia'
                                    ? 'Redacted'
                                    : values.initials
                                }
                                onChange={handleChange}
                                error={Boolean(
                                  touched.initials && errors.initials
                                )}
                                helperText={touched.initials && errors.initials}
                                style={{ width: 400, marginRight: 25 }}
                                inputProps={{
                                  className:
                                    currentFamily.planType === 'Free' &&
                                    childVisiility !== 'Australia'
                                      ? classes.NonAUchildBox
                                      : classes.AuChildBox,
                                  readOnly:
                                    currentFamily.planType === 'Free' &&
                                    childVisiility !== 'Australia'
                                      ? true
                                      : false
                                }}
                                onPaste={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                onCopy={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                              />
                            </FormControl>
                          </div>
                          <div className={classes.profileImageWrapper}>
                            <label htmlFor="upload-profile-picture">
                              <TextField
                                style={{ display: 'none' }}
                                id="upload-profile-picture"
                                name="upload-profile-picture"
                                // type="file"
                                // onChange={(e) => uploadProfileImage(e)}
                              />
                              <Badge
                                overlap="circle"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                // badgeContent={
                                //   _.isEmpty(profileImageURL) && (
                                //     <CameraAltIcon />
                                //   )
                                // }
                              >
                                <Avatar
                                  className={
                                    currentFamily.planType === 'Free' &&
                                    childVisiility !== 'Australia'
                                      ? classes.NonAUImageContainer
                                      : classes.imageContainer
                                  }
                                  alt={
                                    currentFamily.planType === 'Free' &&
                                    childVisiility !== 'Australia'
                                      ? 'Redacted'
                                      : values.initials
                                  }
                                  src={_.get(profileImageURL, 'url', '')}
                                >
                                  {currentFamily.planType === 'Free' &&
                                  childVisiility !== 'Australia'
                                    ? 'Redacted'
                                    : values.initials}
                                </Avatar>
                              </Badge>
                            </label>
                            {!_.isEmpty(profileImageURL) && (
                              <div
                                className={classes.removeImageContainer}
                                onClick={() => removeProfileImage()}
                              >
                                Remove
                              </div>
                            )}
                          </div>
                          <Divider style={{ marginTop: 16 }} />
                          <div style={{ width: '100%', marginTop: 24 }}>
                            <FormControl variant="outlined">
                              <InputLabel id="country-select-outlined-label">
                                School Name
                              </InputLabel>
                              <Select
                                labelId="schoolName-select-outlined-label"
                                className={classes.inputControlBox}
                                style={{
                                  width: 290,
                                  marginRight: 33,
                                  marginTop: 0
                                }}
                                name="school"
                                value={values.school ? values.school : ''}
                                onChange={(e) => {
                                  handleChange(e);
                                  const foundedGroupsBySchoolId = _.find(
                                    schools,
                                    (school) => school.id === e.target.value
                                  );
                                  const allCategory =
                                    foundedGroupsBySchoolId?.categories?.map(
                                      (data) => {
                                        return {
                                          key: data?.name,
                                          value: data?.id
                                        };
                                      }
                                    );
                                  if (allCategory) {
                                    setSchoolCategories(allCategory);
                                  }
                                  setFieldValue('year', '');
                                  setFieldValue('groups', '');
                                }}
                                error={Boolean(touched.school && errors.school)}
                                id="school-name-select-outlined"
                                onBlur={handleBlur}
                                label="School Name"
                              >
                                <MenuItem value="">
                                  <em>School Name</em>
                                </MenuItem>
                                {schools
                                  .filter((e) => {
                                    if (
                                      e.isFree === true ||
                                      currentFamily.planType !== 'Free'
                                    ) {
                                      return e;
                                    }
                                  })
                                  .map(function (object) {
                                    return (
                                      <MenuItem
                                        key={object.id}
                                        value={object.id}
                                      >
                                        {object.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                            <FormControl variant="outlined">
                              <InputLabel id="year-select-outlined-label">
                                Year
                              </InputLabel>
                              <Select
                                labelId="year-select-outlined-label"
                                className={classes.inputControlBox}
                                name="year"
                                value={values.year ? values.year : ''}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldValue('groups', '');
                                }}
                                error={Boolean(touched.year && errors.year)}
                                style={{ width: 103, marginTop: 0 }}
                                id="year-select-outlined"
                                onBlur={handleBlur}
                                label="Year"
                              >
                                <MenuItem value="">
                                  <em>Year</em>
                                </MenuItem>
                                {year.map(function (object) {
                                  return (
                                    <MenuItem
                                      key={object.value}
                                      value={object.value}
                                    >
                                      {object.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>

                            {currentFamily.planType === 'Free' &&
                              schoolCatagories?.map((Category, index) => {
                                return (
                                  <div key={`${Category.key}-${index}`}>
                                    <FormControl variant="outlined">
                                      <InputLabel
                                        id="demo-simple-select-outlined-label-Group"
                                        style={{ marginTop: 20 }}
                                      >
                                        {Category.key}
                                      </InputLabel>
                                      <Select
                                        className={classes.inputControlBox}
                                        labelId="demo-simple-select-outlined-label-Group"
                                        id="demo-simple-select-outlined-Group"
                                        multiple
                                        name={Category.key}
                                        onBlur={handleBlur}
                                        value={
                                          values?.groups ? values.groups : []
                                        }
                                        onChange={(e) =>
                                          handleGroupChange(
                                            e,
                                            values.groups,
                                            setFieldValue(
                                              `groups`,
                                              e.target.value
                                            )
                                          )
                                        }
                                        renderValue={(selected) => {
                                          const groupName = Groups.map(
                                            (data) => {
                                              if (
                                                selected.includes(data.id) &&
                                                data.category === Category.value
                                              ) {
                                                if (
                                                  data.years.includes(
                                                    `Year ${values.year}`
                                                  ) ||
                                                  (data.years.includes(
                                                    'Kindy'
                                                  ) &&
                                                    values.year === '0') ||
                                                  (data.years.includes(
                                                    'Pre K'
                                                  ) &&
                                                    values.year === '-1')
                                                ) {
                                                  return data.groupName;
                                                }
                                                return null;
                                              } else {
                                                return null;
                                              }
                                            }
                                          );
                                          const groupNameFilter =
                                            groupName.filter(
                                              (data) => data && data
                                            );
                                          return groupNameFilter.join(', ');
                                        }}
                                        label={Category.key}
                                      >
                                        {Groups &&
                                          Groups.length &&
                                          Groups.sort((a, b) => {
                                            return a?.groupName?.toLowerCase() >
                                              b?.groupName?.toLowerCase()
                                              ? 1
                                              : -1;
                                          })
                                            .filter((g) => {
                                              if (
                                                g.groupType === 'Free' &&
                                                g.school === values.school &&
                                                g.category === Category.value
                                              ) {
                                                if (!values.year) {
                                                  return g;
                                                }
                                                if (
                                                  g.years.includes(
                                                    `Year ${values.year}`
                                                  ) ||
                                                  (g.years.includes('Kindy') &&
                                                    values.year === '0') ||
                                                  (g.years.includes('Pre K') &&
                                                    values.year === '-1')
                                                ) {
                                                  return g;
                                                } else return false;
                                              } else {
                                                return false;
                                              }
                                            })
                                            .map((group) => {
                                              return (
                                                <MenuItem
                                                  key={group.id}
                                                  value={group.id}
                                                  className={
                                                    classes.menuItemClass
                                                  }
                                                >
                                                  <div>
                                                    <Checkbox
                                                      icon={
                                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                                      }
                                                      checkedIcon={
                                                        <CheckBoxIcon fontSize="small" />
                                                      }
                                                      checked={values?.groups?.includes(
                                                        group.id
                                                      )}
                                                      name={group.id}
                                                      className={
                                                        classes.controlCheck
                                                      }
                                                      classes={{
                                                        root: classes.checkbox,
                                                        checked: classes.checked
                                                      }}
                                                    />
                                                    {group.groupName}
                                                  </div>
                                                  {group?.createdByApiSync && (
                                                    <span>
                                                      <img src={apiShortIcon} />
                                                    </span>
                                                  )}
                                                </MenuItem>
                                              );
                                            })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                );
                              })}
                          </div>
                          {currentFamily.planType !== 'Free' && (
                            <FormControl component="fieldset">
                              <TextField
                                label="School Portal URL"
                                variant="outlined"
                                onBlur={(e) => {
                                  setFieldValue(
                                    'schoolPortal',
                                    e.target.value.trim().split(/ +/).join(' ')
                                  );
                                  handleBlur(e);
                                }}
                                name="schoolPortal"
                                value={
                                  values.schoolPortal ? values.schoolPortal : ''
                                }
                                onChange={handleChange}
                                error={Boolean(
                                  touched.schoolPortal && errors.schoolPortal
                                )}
                                helperText={
                                  touched.schoolPortal && errors.schoolPortal
                                }
                                className={classes.inputControlBox}
                              />
                            </FormControl>
                          )}
                          {currentFamily.planType !== 'Free' && (
                            <div style={{ width: '100%', marginBottom: 20 }}>
                              <FormControl component="fieldset">
                                <TextField
                                  label="School Portal Login"
                                  variant="outlined"
                                  onBlur={(e) => {
                                    setFieldValue(
                                      'schoolPortalLogin',
                                      e.target.value
                                        .trim()
                                        .split(/ +/)
                                        .join(' ')
                                    );
                                    handleBlur(e);
                                  }}
                                  name="schoolPortalLogin"
                                  value={
                                    values.schoolPortalLogin
                                      ? values.schoolPortalLogin
                                      : ''
                                  }
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.schoolPortalLogin &&
                                      errors.schoolPortalLogin
                                  )}
                                  helperText={
                                    touched.schoolPortalLogin &&
                                    errors.schoolPortalLogin
                                  }
                                  className={classes.inputControlBox}
                                />
                              </FormControl>
                              <FormControl component="fieldset">
                                <TextField
                                  label="School Portal Password"
                                  variant="outlined"
                                  onBlur={(e) => {
                                    setFieldValue(
                                      'schoolPortalPassword',
                                      e.target.value
                                        .trim()
                                        .split(/ +/)
                                        .join(' ')
                                    );
                                    handleBlur(e);
                                  }}
                                  name="schoolPortalPassword"
                                  value={
                                    values.schoolPortalPassword
                                      ? values.schoolPortalPassword
                                      : ''
                                  }
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.schoolPortalPassword &&
                                      errors.schoolPortalPassword
                                  )}
                                  helperText={
                                    touched.schoolPortalPassword &&
                                    errors.schoolPortalPassword
                                  }
                                  className={classes.inputControlBox}
                                />
                              </FormControl>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                    {isOpenModal.open && (
                      <Modal isOpen={isOpenModal.open} onClose={handleClose}>
                        <div className={classes.modalBox}>
                          <Typography className={classes.modalTitle}>
                            Save your changes?
                          </Typography>
                          <div className={classes.modalContent}>
                            <Typography className={classes.modalSubTitle}>
                              Your changes will be lost if you don&apos;t save
                              them.
                            </Typography>
                          </div>
                          <div className={classes.modalActions}>
                            <Button
                              onClick={(e) => {
                                handleClose(e);
                                saveUpdated(isOpenModal.values);
                              }}
                              color="primary"
                              variant="contained"
                              size="large"
                              className={classes.primaryButton}
                            >
                              Save Changes
                            </Button>
                            <Button
                              onClick={() => notSaveHandle()}
                              variant="contained"
                              size="large"
                              className={classes.greyButton}
                            >
                              Don&apos;t Save
                            </Button>
                          </div>
                        </div>
                      </Modal>
                    )}
                    {isOpenDeleteModal && (
                      <Modal
                        isOpen={isOpenDeleteModal}
                        onClose={handleDeleteCloseModal}
                      >
                        <div className={classes.modalBox}>
                          <Typography className={classes.modalTitle}>
                            Delete User Profile?
                          </Typography>
                          <div className={classes.modalSubHeading}>
                            <Typography className={classes.modalSubTitle}>
                              <span style={{ fontWeight: 'bold' }}>
                                Warning: This action can not be reversed.
                              </span>
                              <br />
                              The following data will be permanently removed
                              from the database.
                            </Typography>
                          </div>
                          <div className={classes.modalContent}>
                            <Typography className={classes.modalSubTitle}>
                              - User profile and associated app logins (if
                              child)
                              <br />
                              - Existing calendar entries about the user
                              <br />
                              - Associations with Schools and Groups
                              <br />
                            </Typography>
                          </div>
                          <div className={classes.modalSubHeading}>
                            <div
                              className={classes.modalSubTitle}
                              style={{ fontWeight: 500, marginBottom: '3.3%' }}
                            >
                              Type &quot;DELETE USER&quot; to confirm
                            </div>
                            <FormControl
                              component="fieldset"
                              style={{ width: '100%' }}
                            >
                              <TextField
                                label=""
                                variant="outlined"
                                className={classes.inputControlBox}
                                style={{ marginTop: 0 }}
                                value={deleteText}
                                onChange={(e) => setDeleteText(e.target.value)}
                              />
                            </FormControl>
                          </div>
                          <div className={classes.modalActions}>
                            <Button
                              onClick={deleteUserHandle}
                              color="primary"
                              variant="contained"
                              size="large"
                              disabled={deleteText !== 'DELETE USER'}
                              className={classes.primaryButton}
                              startIcon={
                                isLoading && (
                                  <CircularProgress
                                    color="secondary"
                                    size={25}
                                  />
                                )
                              }
                            >
                              {!isLoading && 'Delete'}
                            </Button>
                            <Button
                              onClick={(e) => {
                                handleDeleteCloseModal(e);
                                handleCloseDeleteMenu(e);
                              }}
                              variant="contained"
                              size="large"
                              className={classes.greyButton}
                              style={{ boxShadow: 'none' }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </Modal>
                    )}
                    {isOpenConfirmDeleteModal && (
                      <Modal
                        isOpen={isOpenConfirmDeleteModal}
                        onClose={handleDeleteConfirmCloseModal}
                      >
                        <div className={classes.modalBox}>
                          <Typography
                            className={classes.modalTitle}
                            style={{ marginBottom: 18 }}
                          >
                            User Profile Deleted
                          </Typography>
                          <Typography
                            className={classes.modalSubTitle}
                            style={{ marginBottom: 26 }}
                          >
                            All data associated with {data.firstName}{' '}
                            {data.lastName} has now been removed from the
                            database.
                          </Typography>
                          <div className={classes.modalActions}>
                            <Button
                              onClick={() => {
                                handleDeleteConfirmCloseModal();
                                setOpen(false);
                              }}
                              color="primary"
                              variant="contained"
                              size="large"
                              className={classes.primaryButton}
                            >
                              Continue
                            </Button>
                          </div>
                        </div>
                      </Modal>
                    )}
                    {isNoParentDeleteModal && (
                      <Modal
                        isOpen={isNoParentDeleteModal}
                        onClose={handleNoParentDeleteModal}
                      >
                        <div className={classes.modalBox}>
                          <Typography
                            className={classes.modalTitle}
                            style={{ marginBottom: 18 }}
                          >
                            Cannot Delete Parent
                          </Typography>
                          <Typography
                            className={classes.modalSubTitle}
                            style={{ marginBottom: 26 }}
                          >
                            A family account requires at least one Parent user.
                          </Typography>
                          <div className={classes.modalActions}>
                            <Button
                              onClick={handleNoParentDeleteModal}
                              variant="contained"
                              size="large"
                              className={classes.greyButton}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </Modal>
                    )}
                  </>
                </form>
              );
            }}
          </Formik>
        </div>
      </RightDrawer>
      <ScanningUploadFileModal
        handleOnClose={handleOnClose}
        isOpen={fileUploadModal.isModalOpen}
        fileUploadState={fileUploadModal.fileUploadState}
      />
    </Dashboard>
  );
}

export default withFirebase(FamilyUsersView);
