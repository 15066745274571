import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function TrashIcon({ color, ...props }) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__trash" fill="#fff">
          <path
            id="prefix__trash1"
            d="M13.833 1.917c1.465 0 2.662 1.145 2.746 2.588l.004.162v.916h3.667c.506 0 .917.41.917.917 0 .47-.354.858-.81.91l-.107.007h-.917v11.916c0 1.465-1.145 2.662-2.588 2.746l-.162.004H7.417c-1.465 0-2.662-1.145-2.746-2.588l-.004-.162V7.417H3.75c-.506 0-.917-.41-.917-.917 0-.47.354-.858.81-.91l.107-.007h3.666v-.916c0-1.465 1.146-2.662 2.59-2.746l.16-.004h3.667zm3.666 5.5h-11L6.5 19.333c0 .47.354.858.81.91l.107.007h9.166c.47 0 .858-.354.91-.81l.007-.107V7.417zm-7.332 2.75c.47 0 .857.354.91.81l.006.106v5.5c0 .507-.41.917-.916.917-.47 0-.858-.354-.91-.81l-.007-.107v-5.5c0-.506.41-.916.917-.916zm3.666 0c.47 0 .858.354.91.81l.007.106v5.5c0 .507-.41.917-.917.917-.47 0-.857-.354-.91-.81l-.006-.107v-5.5c0-.506.41-.916.916-.916zm0-6.417h-3.666c-.47 0-.858.354-.91.81l-.007.107v.916h5.5v-.916c0-.47-.354-.858-.81-.91l-.107-.007z"
          />
        </mask>
        <g fill={color || '#4C5B68'} mask="url(#prefix__trash)">
          <path d="M0 0H24V24H0z" />
        </g>
      </g>
    </SvgIcon>
  );
}
export default TrashIcon;
