import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Button,
  makeStyles,
  FormHelperText,
  InputAdornment,
  Link
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Logo from '../../../components/Logo';
import { withFirebase } from '../../../utils/firebase';
import { getParameterByName } from '../../../utils/functions';
import {
  loaderStop,
  loaderStart,
  addMessage
} from '../../../redux/actions/appActions';

// SCHEMA VALIDATOR FOR
const createNewPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .trim()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
      'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.'
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
    .required('Passwords do not match')
});

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: 32,
    backgroundColor: '#fdfbf9',
    maxWidth: 460,
    margin: 'auto',
    '& .MuiInputLabel-formControl': {
      color: '#474747',
      fontWeight: 400
    }
  },
  logo: {
    marginBottom: 42
  },
  description: {
    color: `rgba(76,91,104,0.6) !important`
  },
  footerLinks: {
    marginTop: 150,
    '& .MuiLink-root': {
      marginRight: 35
    }
  }
}));

function MobileCreatePasswordView({ firebase }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [newPasswordFieldType, setNewPasswordFieldType] = useState('password');
  const [errorFromFirebase, setErrorFromFirebase] = useState('');
  const [confirmPasswordFieldType, setConfirmPasswordFieldType] =
    useState('password');

  const onToggleNewPasswordFieldType = () => {
    if (newPasswordFieldType === 'password') {
      setNewPasswordFieldType('text');
    } else {
      setNewPasswordFieldType('password');
    }
  };

  const onToggleConfirmPasswordFieldType = () => {
    if (confirmPasswordFieldType === 'password') {
      setConfirmPasswordFieldType('text');
    } else {
      setConfirmPasswordFieldType('password');
    }
  };

  const _handleFormSubmit = useCallback(
    (submittedFormFieldValues) => {
      const { newPassword } = submittedFormFieldValues;
      const currentUrl = window.location.href;
      const actionCode = getParameterByName('oobCode', currentUrl);
      const mode = getParameterByName('mode', currentUrl);

      if (mode === 'resetPassword') {
        const auth = firebase.auth;
        dispatch(loaderStart());
        auth
          .verifyPasswordResetCode(actionCode)
          .then(() => {
            auth
              .confirmPasswordReset(actionCode, newPassword)
              .then(() => {
                dispatch(loaderStop());
                dispatch(addMessage('Password Updated'));
                history.push('/app-password-updated');
              })
              .catch((error) => {
                dispatch(loaderStop());
                const errorMessage = _.get(error, 'message', '');
                setErrorFromFirebase(errorMessage);
              });
          })
          .catch((error) => {
            dispatch(loaderStop());
            const errorMessage = _.get(error, 'message', '');
            setErrorFromFirebase(errorMessage);
          });
      } else {
        setErrorFromFirebase('Invalid URL');
      }
    },
    [firebase, history, dispatch]
  );

  return (
    <Box className={classes.wrapper}>
      <div className={classes.logo}>
        <Logo width="100" />
      </div>
      <Typography variant="h2" color="textPrimary" gutterBottom={true}>
        Password Reset
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" gutterBottom={true}>
        Type your new password below.
      </Typography>
      <Typography color="textSecondary" className={classes.description}>
        Password must contain at least 8 characters, at least one uppercase
        letter, at least one lowercase letter and at least one number.
      </Typography>
      <Formik
        initialValues={{
          newPassword: '',
          confirmPassword: ''
        }}
        validationSchema={createNewPasswordSchema}
        onSubmit={(values) => {
          _handleFormSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          // isSubmitting,
          touched,
          values
        }) => {
          let isSubmitButtonDisabled =
            _.isEmpty(values.newPassword) || _.isEmpty(values.confirmPassword);

          if (
            !_.isEmpty(touched) &&
            !isSubmitButtonDisabled &&
            !_.isEmpty(errors)
          ) {
            isSubmitButtonDisabled = true;
          }
          return (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                error={
                  Boolean(touched.newPassword && errors.newPassword) ||
                  !_.isEmpty(errorFromFirebase)
                }
                fullWidth
                helperText={touched.newPassword && errors.newPassword}
                label="New Password"
                autoComplete="off"
                margin="normal"
                name="newPassword"
                onBlur={handleBlur}
                onChange={(e) => {
                  setErrorFromFirebase(false);
                  handleChange(e);
                }}
                type={newPasswordFieldType}
                value={values.newPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle newPassword visibility"
                        onClick={() => onToggleNewPasswordFieldType()}
                        tabIndex="-1"
                      >
                        <img
                          alt="Password Requirement"
                          src={
                            newPasswordFieldType === 'text'
                              ? '/static/images/password-hide.png'
                              : '/static/images/password-see.png'
                          }
                          width="22"
                          height="22"
                        />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                error={
                  Boolean(touched.confirmPassword && errors.confirmPassword) ||
                  !_.isEmpty(errorFromFirebase)
                }
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                label="Confirm Password"
                autoComplete="off"
                margin="normal"
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={(e) => {
                  setErrorFromFirebase(false);
                  handleChange(e);
                }}
                type={confirmPasswordFieldType}
                value={values.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirmPassword visibility"
                        onClick={() => onToggleConfirmPasswordFieldType()}
                        tabIndex="-1"
                      >
                        <img
                          alt="Password Requirement"
                          src={
                            confirmPasswordFieldType === 'text'
                              ? '/static/images/password-hide.png'
                              : '/static/images/password-see.png'
                          }
                          width="22"
                          height="22"
                        />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {errorFromFirebase && (
                <Box ml={2} mb={4}>
                  <FormHelperText error>{errorFromFirebase}</FormHelperText>
                </Box>
              )}
              <Box mt={2}>
                <Button
                  // disabled={isSubmitButtonDisabled}
                  size="large"
                  type="submit"
                  variant="contained"
                  color={isSubmitButtonDisabled ? 'secondary' : 'primary'}
                >
                  Save
                </Button>
                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </Box>
            </form>
          );
        }}
      </Formik>
      <Typography className={classes.footerLinks}>
        <Link
          href="mailto:care@mywhanau.com.au"
          color="inherit"
          underline="none"
        >
          Contact
        </Link>
        <Link
          href="https://www.mywhanau.com.au/privacy-policy"
          color="inherit"
          underline="none"
          target="_blank"
        >
          Privacy
        </Link>
        <Link
          href="https://www.mywhanau.com.au/terms-and-conditions"
          color="inherit"
          underline="none"
          target="_blank"
        >
          Terms
        </Link>
      </Typography>
    </Box>
  );
}

export default withFirebase(MobileCreatePasswordView);
