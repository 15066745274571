import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Paper,
  Button,
  Typography,
  Divider
} from '@material-ui/core';
import _ from 'lodash';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Dashboard from '../layout/Dashboard';
import { RightArrow } from 'src/assets/Icons';
import clsx from 'clsx';
import StepperComponent from 'src/components/StepperComponent';
import AddFamily from './AddFamily';
import AddFamilyMember from './AddFamilyMember';
import AddFamilyHost from './AddFamilyHost';
import FILE from 'src/assets/file-plus.svg';
import POCKET from 'src/assets/pocket.svg';
import USER from 'src/assets/user.svg';
import { routes } from 'src/utils/constant';
import { encryptSHA256 } from 'src/utils/functions';
import { FamiliesIcon } from 'src/assets/Icons';
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { withFirebase, FIREBASE_API_URL } from 'src/utils/firebase';
import { setFamily } from 'src/redux/actions/familyActions';
import { getGroups } from 'src/services/groups';
import { MESSAGE_TYPES } from 'src/constants/common';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  tabNavigations: {
    padding: '10px 20px 0'
  },
  navigationButton: {
    width: 120,
    textTransform: 'none'
  },
  actionButton: {
    margin: '15px 0 20px',
    textTransform: 'none'
  },
  activeTab: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0
  },
  bredthcrumbTitle: {
    color: '#536e7b',
    fontSize: 16,
    marginRight: 24,
    display: 'inline-block',
    cursor: 'pointer'
  },

  iconStyle: {
    height: 48,
    width: 48,
    position: 'relative',
    borderRadius: 50,
    backgroundColor: '#8bc517'
  },
  image: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: '25%',
    left: '25%',
    tintColor: 'white'
  },
  stepperStyle: {
    height: `calc(100% - 64px)`,
    backgroundColor: '#ffffff',
    display: 'flex',
    '& .drawerClass': {
      marginLeft: 'unset !important',
      padding: 'unset !important'
    },
    '& .drawerClass .MuiToolbar-regular': {
      minHeight: 'unset !important'
    },
    '& .drawerClass .MuiPaper-root': {
      boxShadow: 'none !important'
    }
  },
  closeIcon: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: '25%',
    left: '25%',
    fill: '#ffffff'
  }
}));

function FamilyAccountView({ firebase }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [isUpdate, setUpdate] = useState(false);
  const [handleCancelClick, setHandleCancelClick] = useState(false);
  const [isCurrentFamilyLoading, setCurrentFamilyLoading] = useState(false);
  const currentFamily = useSelector((state) => state.family.currentFamily);

  const ColorlibStepIcon = (props) => {
    const { active } = props;
    const icons = {
      1: (
        <div
          className={classes.iconStyle}
          style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
        >
          <img src={FILE} alt="Search" className={classes.image} />
        </div>
      ),
      2: (
        <div
          className={classes.iconStyle}
          style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
        >
          <img src={USER} alt="Search" className={classes.image} />
        </div>
      ),
      3: (
        <div
          className={classes.iconStyle}
          style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
        >
          {currentFamily.planType === 'Free' ? (
            <CalendarTodayOutlinedIcon classes={{ root: classes.closeIcon }} />
          ) : (
            <img src={POCKET} alt="Search" className={classes.image} />
          )}
        </div>
      )
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  };

  const getStepContent = (
    step,
    getFormData,
    handlePauseAccount,
    handleResumeAccount,
    handleCancelDisable,
    setHandleCancelClick,
    handleCancelClick
  ) => {
    switch (step) {
      case 0:
        return (
          <AddFamily
            submitForm={getFormData}
            handlePause={handlePauseAccount}
            handleResume={handleResumeAccount}
            accountFlag={true}
            isEdit={true}
            handleCancelDisable={handleCancelDisable}
            setHandleCancelClick={setHandleCancelClick}
            handleCancelClick={handleCancelClick}
          />
        );
      case 1:
        return (
          <AddFamilyMember
            submitForm={getFormData}
            accountFlag={true}
            isEdit={true}
            handleCancelDisable={handleCancelDisable}
            setHandleCancelClick={setHandleCancelClick}
            handleCancelClick={handleCancelClick}
          />
        );
      case 2:
        return (
          <AddFamilyHost
            submitForm={getFormData}
            accountFlag={true}
            isEdit={true}
            handleCancelDisable={handleCancelDisable}
            setHandleCancelClick={setHandleCancelClick}
            handleCancelClick={handleCancelClick}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  const addChild = async (data) => {
    const { child } = data;
    const username = `${child.userName}@mywhanau-user.com`;
    const password = child.password;

    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email_address: username,
        password: password
      })
    };
    return await fetch(`${FIREBASE_API_URL}/createUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result;
      })
      .then(
        async (result) => {
          if (result.user) {
            const userID = result.user.uid;
            const userObject = {
              role: 'Child',
              userName: child.userName,
              email: username,
              child: child.id,
              family: currentFamily.id,
              isViewedOnboard: false
            };
            await firebase.addUser(userObject, userID);
            return userID;
          } else {
            // dispatch(loaderStop());
            if (result.code === 'auth/email-already-exists') {
              dispatch(
                addMessage(
                  `${child.userName} username is already in use by another account.`
                )
              );
            } else if (result.code === 'auth/invalid-email') {
              dispatch(addMessage(`${child.userName} username was not valid`));
            } else {
              dispatch(
                addMessage(
                  `${child.userName} user was  not created. Please try again`,
                  MESSAGE_TYPES.ERROR
                )
              );
            }
          }
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(
            addMessage(
              'User was  not created. Please try again',
              MESSAGE_TYPES.ERROR
            )
          );
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(
          addMessage(
            'User was not created. Please try again',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });
  };

  const updateUsersEmailPassword = async (data) => {
    const childUsers = (data.childs || [])
      .filter((item) => !!item.userId && !!item.userName && !!item.password)
      .map((item) => ({
        id: item.userId,
        email: `${item.userName}@mywhanau-user.com`,
        userName: item.userName,
        password: item.password
      }));

    const mainUser =
      data.userId && data.userName && data.password
        ? [
            {
              id: data.userId,
              email: `${data.userName}@mywhanau-user.com`,
              userName: data.userName,
              password: data.password
            }
          ]
        : null;

    const users = [...(mainUser || []), ...childUsers];

    users.forEach(async (item) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: item.id,
          email_address: item.email,
          new_password: item.password
        })
      };
      await fetch(`${FIREBASE_API_URL}/updateEmailPassword`, requestOptions)
        .then((response) => {
          const result = response.json();
          if (!response.ok) {
            throw new Error(result?.message);
          }
          return result;
        })
        .then(
          (result) => {
            if (result.user) {
              // 
            } else {
              if (result.code === 'auth/email-already-exists') {
                dispatch(
                  addMessage(
                    `${item.userName} username is already in use by another account.`
                  )
                );
              } else if (result.code === 'auth/invalid-email') {
                dispatch(
                  addMessage(`${item.userName}  username was not valid`)
                );
              } else {
                dispatch(
                  addMessage(
                    `${item.userName} user was  not created. Please try again`,
                    MESSAGE_TYPES.ERROR
                  )
                );
              }
            }
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(
              addMessage(
                'User email and password was not updated. Please try again',
                MESSAGE_TYPES.ERROR
              )
            );
            dispatch(loaderStop());
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(
            addMessage(
              'User email and password was not updated. Please try again',
              MESSAGE_TYPES.ERROR
            )
          );
          dispatch(loaderStop());
        });
    });
  };

  const deleteAuthUsercaller = async (userId) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: userId
      })
    };
    fetch(`${FIREBASE_API_URL}/deleteUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result;
      })
      .then(
        (result) => {
          if (result.message === 'Successfully deleted user') {
            // DO SOMETHING...
          }
        },
        (error) => {
          const errorMessage = _.get(error, 'message', '');
          console.log(errorMessage);
          dispatch(addMessage(errorMessage, MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      );
  };

  const getComparedAndDiffrece = (data, compArray = []) => {
    return data.filter((el) => !compArray.includes(el));
  };

  const getFormData = async (formData, accountFlag = false) => {
    // const oldFamilyData = JSON.parse(JSON.stringify(currentFamily));
    if (activeStep < 2 && !accountFlag) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      dispatch(setFamily({ ...currentFamily, ...formData }));
    } else if (activeStep === 2 || accountFlag) {
      if (accountFlag) {
        dispatch(setFamily({ ...currentFamily, ...formData }));
      }
      dispatch(loaderStart());
      const finalData = {
        ...currentFamily,
        ...formData
      };

      updateUsersEmailPassword(finalData);

      const groupData = [];
      const getUpdatedGroups = [];

      try {
        const result = await getGroups();
        result.data.forEach(function (item) {
          if (item.groupName) {
            groupData.push(item);
          }
        });
        dispatch(loaderStop());
      } catch (error) {
        console.log('Error getting documents: ', error);
        dispatch(
          addMessage(
            'Server connection issue. Please refresh',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      }

      firebase
        .getFamilyByID(familyID)
        .then(
          async (doc) => {
            const familyData = doc.data();
            if (familyData) {
              // const updatedChildData = currentFamily.childs;
              // const filterChildData = [];

              if (
                finalData.childs.length &&
                currentFamily.planType !== 'Free'
              ) {
                const childArray = [];
                for (const child of finalData.childs) {
                  const childData = {
                    child
                  };
                  const data = child.userId
                    ? child.userId
                    : await addChild(childData);
                  childArray.push(data);
                }
                // finalData.childs.map((child, index) => {
                //   let childObj = {
                //     ...child,
                //     userId: childArray[index]
                //   };
                //   if (child.password) {
                //     childObj = {
                //       ...childObj,
                //       password: '',
                //       encryptedPassword: encryptSHA256(childObj.password)
                //     };
                //   }
                //   return childArray[index] && childObj;
                // });
              } else {
                // update groupData if child have groups
                if (finalData.childs.length) {
                  let oldFamilyData = await firebase.getFamilyByID(familyID);
                  oldFamilyData = oldFamilyData.data();
                  finalData.childs.forEach((child, childIndex) => {
                    let mergeNewClassAndGroups = _.compact([
                      child.group,
                      child.groups
                    ]);
                    mergeNewClassAndGroups = _.uniq(
                      // eslint-disable-next-line prefer-spread
                      [].concat.apply([], mergeNewClassAndGroups)
                    );
                    let mergeOldClassAndGroups = _.compact([
                      oldFamilyData.childs[childIndex]?.group,
                      oldFamilyData.childs[childIndex]?.groups
                    ]);
                    mergeOldClassAndGroups = _.uniq(
                      // eslint-disable-next-line prefer-spread
                      [].concat.apply([], mergeOldClassAndGroups)
                    );

                    // get old groups id which is updated from old selection
                    const removeGroups = getComparedAndDiffrece(
                      mergeOldClassAndGroups,
                      mergeNewClassAndGroups
                    );

                    // get new groups id which is updated from old selection
                    const newGroupReceived = getComparedAndDiffrece(
                      mergeNewClassAndGroups,
                      mergeOldClassAndGroups
                    );

                    const allGroupIds = _.compact([child.group, child.groups]);

                    const pushRecord = {
                      familyId: child.id,
                      groupIds: [],
                      // eslint-disable-next-line prefer-spread
                      familyGroups: [].concat.apply([], allGroupIds) || []
                    };
                    if (newGroupReceived) {
                      newGroupReceived.forEach((el) => {
                        pushRecord.groupIds.push({
                          id: el,
                          isAdd: true
                        });
                      });
                    }
                    if (removeGroups) {
                      removeGroups.forEach((el) => {
                        pushRecord.groupIds.push({
                          id: el,
                          isAdd: false
                        });
                      });
                    }
                    getUpdatedGroups.push({
                      ...pushRecord
                    });
                  });
                }
              }
              // filterChildData = updatedChildData.filter((child) => {
              //   return child && child;
              // });
              let updatedFinalData = {
                ...finalData
                // childs: filterChildData
              };
              if (finalData.password) {
                updatedFinalData = {
                  ...updatedFinalData,
                  password: '',
                  encryptedPassword: encryptSHA256(finalData.password)
                };
              }
              let schoolData = [];
              if (
                updatedFinalData.childs.length &&
                currentFamily.planType !== 'Free'
              ) {
                schoolData = updatedFinalData.childs.reduce((v, { school }) => {
                  v.push(school);
                  return v;
                }, []);
              }

              updatedFinalData.childsSchools = schoolData;

              const parents = updatedFinalData.parents;
              if (parents && parents.length > 0) {
                updatedFinalData.primaryEmail = parents[0].emailAddress;
              }

              const findExtraUser = familyData.childs.filter((o1) => {
                // if match found return false
                return _.findIndex(currentFamily.childs, { id: o1.id }) !== -1
                  ? false
                  : true;
              });

              if (findExtraUser.length) {
                for (const user of findExtraUser) {
                  if (user.userId) {
                    await deleteAuthUsercaller(user.userId);
                    await firebase.deleteUser(user.userId);
                  }
                }
              }

              if (getUpdatedGroups.length) {
                getUpdatedGroups.forEach((grp) => {
                  if (grp.groupIds.length) {
                    // Below function for update groups
                    firebase.updateGroupsByFamilyId(grp);
                    // Below function for update group related all entries
                    firebase.updateGroupEntriesByFamilyId(grp);
                  }
                });
              }

              firebase
                .updateFamily(updatedFinalData, updatedFinalData.id)
                .then(
                  () => {
                    if (updatedFinalData.childs.length) {
                      updatedFinalData.childs.map((child) => {
                        let childObject = {
                          ...child,
                          role: 'Child',
                          userName: child.userName,
                          email: `${child.userName}@mywhanau-user.com`,
                          child: child.id,
                          family: updatedFinalData.id
                        };
                        if (child.password) {
                          childObject = {
                            ...childObject,
                            password: '',
                            isViewedOnboard: false,
                            encryptedPassword: encryptSHA256(
                              childObject.password
                            )
                          };
                        }
                        if (currentFamily.planType !== 'Free') {
                          firebase.addUser(childObject, child.userId);
                        }
                        return child;
                      });
                    }
                    dispatch(loaderStop());
                    dispatch(setFamily(updatedFinalData));
                    dispatch(addMessage('Family Updated!'));
                  },
                  (error) => {
                    console.log('Error: ', error);
                    dispatch(addMessage(error, MESSAGE_TYPES.ERROR));
                    dispatch(loaderStop());
                  }
                )
                .catch((error) => {
                  console.log('Error: ', error);
                  dispatch(addMessage(error, MESSAGE_TYPES.ERROR));
                  dispatch(loaderStop());
                });
            }
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(
              addMessage('Error on getting family', MESSAGE_TYPES.ERROR)
            );
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
        });
    }
  };
  const handleCancelDisable = useCallback(
    (isUpdate) => {
      setUpdate(isUpdate);
    },
    [setUpdate]
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = useCallback(() => {
    setHandleCancelClick(true);
  }, [setActiveStep]);

  const handleReset = () => {
    setActiveStep(0);
  };

  const familyID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);
  const { familySurname } = useMemo(() => {
    if (currentFamily) {
      return {
        familySurname: currentFamily.familySurname,
        id: currentFamily.id
      };
    }
    return {
      familySurname: '',
      id: ''
    };
  }, [currentFamily]);

  useEffect(() => {
    if (!currentFamily.familySurname && familyID) {
      dispatch(loaderStart());
      setCurrentFamilyLoading(true);
      firebase
        .getFamilyByID(familyID)
        .then(
          async (doc) => {
            const familyData = doc.data();
            if (familyData) {
              dispatch(setFamily(familyData));
            }
            dispatch(loaderStop());
            setCurrentFamilyLoading(false);
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(
              addMessage('Error on getting family', MESSAGE_TYPES.ERROR)
            );
            dispatch(loaderStop());
            setCurrentFamilyLoading(false);
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
          setCurrentFamilyLoading(false);
        });
    }
  }, [currentFamily, familyID, firebase, dispatch]);

  const handleSave = () => {};

  const handlePauseAccount = useCallback(() => {
    const finalData = {
      ...currentFamily,
      planStatus: 'Pause'
    };
    let schoolData = [];
    if (finalData.childs.length && currentFamily.planType !== 'FreeVersion') {
      schoolData = finalData.childs.reduce((v, { school }) => {
        v.push(school);
        return v;
      }, []);
    } else if (currentFamily.planType === 'Free') {
      finalData.childs = [];
    }
    finalData.childsSchools = schoolData;

    const parents = finalData.parents;
    if (parents && parents.length > 0) {
      finalData.primaryEmail = parents[0].emailAddress;
    }

    dispatch(loaderStart());
    firebase
      .updateFamily(finalData, finalData.id)
      .then(
        () => {
          dispatch(loaderStop());
          dispatch(setFamily(finalData));
          dispatch(addMessage('Family account paused!'));
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  }, [firebase, currentFamily, dispatch]);

  const handleResumeAccount = useCallback(() => {
    const finalData = {
      ...currentFamily,
      planStatus: ''
    };

    const parents = finalData.parents;
    if (parents && parents.length > 0) {
      finalData.primaryEmail = parents[0].emailAddress;
    }

    dispatch(loaderStart());
    firebase
      .updateFamily(finalData, finalData.id)
      .then(
        () => {
          dispatch(loaderStop());
          dispatch(setFamily(finalData));
          dispatch(addMessage('Family account resumed!'));
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  }, [firebase, currentFamily, dispatch]);

  return (
    <React.Fragment>
      <Dashboard>
        <div className={classes.root}>
          <div>
            <Typography
              variant="body1"
              className={classes.bredthcrumbTitle}
              onClick={() =>
                window.location.pathname.match('/new')
                  ? history && history.push(routes.familiesFreeNew)
                  : window.location.pathname.match('/pending')
                    ? history && history.push(routes.familiesFreePending)
                    : window.location.pathname.match('/paid')
                      ? history && history.push(routes.familiesPaid)
                      : history && history.push(routes.familiesFree)
              }
            >
              Members
            </Typography>
            <RightArrow
              viewBox="0 0 24 24"
              height={24}
              width={24}
              style={{ fontSize: 22, verticalAlign: 'middle' }}
            />
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ marginBottom: 20, marginTop: 22 }}
          >
            {familySurname}
          </Typography>
          <Paper className={classes.tabNavigations}>
            <div style={{ display: 'flex' }}>
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyCalendar + '/' + familyID)
                    : history.push(routes.familyCalendarPaid + '/' + familyID);
                }}
              >
                Calendar
              </Button>
              {currentFamily.planType !== 'Free' && (
                <Button
                  classes={{ root: classes.navigationButton }}
                  onClick={() => {
                    currentFamily.planType === 'Free'
                      ? history.push(routes.familyFiles + '/' + familyID)
                      : history.push(routes.familyFilesPaid + '/' + familyID);
                  }}
                >
                  Files
                </Button>
              )}
              {currentFamily.planType !== 'Free' && (
                <Button
                  classes={{ root: classes.navigationButton }}
                  onClick={() => {
                    currentFamily.planType === 'Free'
                      ? history.push(routes.familyNotes + '/' + familyID)
                      : history.push(routes.familyNotesPaid + '/' + familyID);
                  }}
                >
                  Notes
                </Button>
              )}
              {currentFamily.planType !== 'Free' && (
                <Button
                  classes={{ root: classes.navigationButton }}
                  onClick={() => {
                    currentFamily.planType === 'Free'
                      ? history.push(routes.familyLinks + '/' + familyID)
                      : history.push(routes.familyLinksPaid + '/' + familyID);
                  }}
                >
                  Links
                </Button>
              )}
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyUsers + '/' + familyID)
                    : history.push(routes.familyUsersPaid + '/' + familyID);
                }}
              >
                Family
              </Button>
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyAccount + '/' + familyID)
                    : history.push(routes.familyAccountPaid + '/' + familyID);
                }}
                className={classes.activeTab}
              >
                Account
              </Button>
            </div>
            <Divider />
            <div className={classes.stepperStyle}>
              {!isCurrentFamilyLoading ? (
                <StepperComponent
                  steps={[
                    'Accounts',
                    'Members',
                    currentFamily.planType === 'FreeVersion'
                      ? 'Calenders'
                      : 'Free Host'
                  ]}
                  getStepContent={(step) =>
                    getStepContent(
                      step,
                      getFormData,
                      handlePauseAccount,
                      handleResumeAccount,
                      handleCancelDisable,
                      setHandleCancelClick,
                      handleCancelClick
                    )
                  }
                  stepClick={(step) => setActiveStep(step)}
                  showSteps={Boolean(currentFamily.planType)}
                  title={'Family'}
                  colorlibStepIcon={ColorlibStepIcon}
                  successTitle={'Family edited!'}
                  subTitle={'View Family'}
                  iconImage={<FamiliesIcon />}
                  buttonLabel={'Add Another Family'}
                  listUrl={routes.families}
                  createUrl={routes.familyCreate}
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  handleReset={handleReset}
                  accountFlag={true}
                  handleSave={handleSave}
                  isEdit={true}
                  isCancelDisable={isUpdate}
                />
              ) : (
                <></>
              )}
            </div>
          </Paper>
        </div>
      </Dashboard>
    </React.Fragment>
  );
}

export default withFirebase(FamilyAccountView);
