import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  AppBar,
  Toolbar,
  Checkbox,
  Tab,
  Tabs,
  MenuItem,
  Select,
  Divider,
  Button,
  FormControl,
  TextField,
  InputLabel,
  FormControlLabel,
  Box,
  Backdrop,
  CircularProgress,
  Typography,
  IconButton,
  Menu,
  FormHelperText
} from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addMessage,
  loaderStart,
  loaderStop
} from 'src/redux/actions/appActions';
import * as Yup from 'yup';
import _ from 'lodash';
import { withFirebase } from 'src/utils/firebase';
import RightDrawer from 'src/components/RightDrawer';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { MoreVert, Link } from '@material-ui/icons';
import { year as DefaultYear, shortYears } from 'src/utils/constant';
import apiManagedIcon from 'src/assets/api-managed.png';
import apiManagedReadonlyIcon from 'src/assets/api-managed-readonly.png';
import Modal from 'src/components/Modal';
import { createEditStudentStyles } from 'src/styles/schoolStyle/students/createEditStudentStyles';
import { linkOrUnLinkMember, searchMember } from 'src/services/members';
import { SEARCH_DEBOUNCE_TIME } from 'src/constants/common';
import { MESSAGE_TYPES } from 'src/constants/common';
import { deleteStudent } from 'src/services/students';

const initialCreateEditStudentValues = {
  firstName: '',
  surname: '',
  school: '',
  year: '',
  groups: [],
  members: '',
  category: ''
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const sortByPriority = (pA, pB, s1, s2) => {
  if (pA > pB) {
    return -1;
  } else if (pA == pB) {
    return sortByName(s1, s2);
  } else {
    return 1;
  }
};

const sortByName = (s1, s2) => {
  const str1 = s1?.toLowerCase();
  const str2 = s2?.toLowerCase();
  if (str1 < str2) {
    return -1;
  }
  if (str1 > str2) {
    return 1;
  }
  return 0;
};

const sortByAll = (arr, student) => {
  const stabilizedThis = arr.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const isSelectAllOptionA = a[0].value === 'all';
    const isSelectAllOptionB = b[0].value === 'all';
    const createdByApiSyncA = a[0].createdByApiSync;
    const createdByApiSyncB = b[0].createdByApiSync;
    const pA1 =
      a[0].autoAssignedMembers?.includes(student?.id) && createdByApiSyncA
        ? true
        : false;
    const pB1 =
      b[0].autoAssignedMembers?.includes(student?.id) && createdByApiSyncB
        ? true
        : false;
    const pA3 = a[0].selected;
    const pB3 = b[0].selected;
    const pA2 = createdByApiSyncA && pA3;
    const pB2 = createdByApiSyncB && pB3;
    const nameA = a[0].key;
    const nameB = b[0].key;

    if (isSelectAllOptionB || isSelectAllOptionA) {
      if (isSelectAllOptionA && isSelectAllOptionB) {
        return 0;
      } else if (isSelectAllOptionB) {
        return 1;
      } else if (isSelectAllOptionA) {
        return -1;
      }
    } else {
      if (pA1 || pB1) {
        return sortByPriority(pA1, pB1, nameA, nameB);
      } else if (pA2 || pB2) {
        return sortByPriority(pA2, pB2, nameA, nameB);
      } else if (pA3 || pB3) {
        return sortByPriority(pA3, pB3, nameA, nameB);
      } else {
        return sortByName(nameA, nameB);
      }
    }
    return 0;
  });
  return stabilizedThis.map((el) => el[0]);
};

const filterByCategoryYear = (arr, categoryId, year) => {
  const yearIndex = DefaultYear.findIndex((y) => y.value === year);
  return arr.filter(
    (c) =>
      (c.category?.includes(categoryId) &&
        yearIndex !== -1 &&
        c.years?.includes(DefaultYear[yearIndex].label)) ||
      c.value === 'all'
  );
};

const getComaSeparateYears = (years) => {
  return years
    ?.filter((year) => year !== 'all')
    ?.map(
      (year) =>
        shortYears[year] ||
        (year === 'Year 0' ? 'K' : year === 'Year -1' ? 'PK' : '') ||
        year
    )
    .join(', ');
};

const CustomTabs = ({
  tabValue,
  handleTabChange,
  searchMember,
  onSearchMember,
  onClearMember,
  createdByApiSync
}) => {
  const classes = createEditStudentStyles();

  const [isOpenSearchBox, setIsOpenSearchBox] = useState(false);

  const handleToggleSearch = () => {
    setIsOpenSearchBox((prev) => !prev);
  };

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          {isOpenSearchBox ? (
            <div className={classes.searchFieldContainer}>
              <input
                size="small"
                name="searchMember"
                placeholder="Search for member by email"
                value={searchMember}
                onChange={(e) => onSearchMember(e.target.value)}
                className={classes.searchField}
              />

              {/* <TextField /> */}
              {searchMember?.length > 0 && (
                <Button className={classes.clearButton} onClick={onClearMember}>
                  Clear
                </Button>
              )}
            </div>
          ) : (
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              className={classes.customTabs}
              classes={{ indicator: classes.selectedTab }}
            >
              {[
                { key: 'groups', label: 'Groups' },
                { key: 'members', label: 'Members' }
              ].map((tab) => (
                <Tab className={classes.tab} label={tab.label} key={tab.key} />
              ))}
            </Tabs>
          )}

          {tabValue === 1 && !createdByApiSync && (
            <IconButton onClick={handleToggleSearch}>
              {isOpenSearchBox ? <CloseIcon /> : <SearchIcon />}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

const compareFormData = (initialData, updatedData) => {
  const isFirstNameChanged = initialData.firstName !== updatedData.firstName;
  const isSurnameChanged = initialData.surname !== updatedData.surname;
  const isSchoolChanged = initialData.school !== updatedData.school;
  const isYearChanged = initialData.year !== updatedData.year;
  const initialGroupSelected = [...initialData.groups];
  const updatedGroupSelected = [...updatedData.groups];
  const hasGroupSelectionChanged =
    _.difference(updatedGroupSelected, initialGroupSelected).length > 0 ||
    _.difference(initialGroupSelected, updatedGroupSelected).length > 0;
  if (
    isFirstNameChanged |
    isSurnameChanged |
    isSchoolChanged |
    isYearChanged |
    hasGroupSelectionChanged
  ) {
    return true;
  }
  return false;
};

const CreateEditStudent = ({
  isOpen,
  onClose,
  schools,
  school,
  years,
  student,
  groups,
  categories,
  handleSchoolChange,
  onUpdate,
  isEdit,
  isCreateStudent,
  isCreateChild
}) => {
  const classes = createEditStudentStyles();
  const [initialValues, setInitialValues] = useState(
    initialCreateEditStudentValues
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState();
  const [allGroups, setAllGroups] = useState(groups);
  const [confirm, setConfirm] = useState({ open: false, values: null });
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState({
    open: false,
    values: null
  });
  const [isOpenLinkMemberModal, setIsOpenLinkMemberModal] = useState(false);
  const [isOpenUnLinkMemberModal, setIsOpenUnLinkMemberModal] = useState(false);
  const [deleteText, setDeleteText] = useState('');
  const [linkMemberConfirmationText, setLinkMemberConfirmationText] =
    useState('');
  const [unLinkMemberConfirmationText, setUnLinkMemberConfirmationText] =
    useState('');
  const [isStudentIsLinkedWithMember, setIsStudentLinkedWithMember] =
    useState(false);
  const isLoading = useSelector((state) => state.app.isLoading);
  const childVisibility = useSelector((state) => state?.auth?.user?.country);
  const [isDeleted, setIsDeleted] = useState(false);
  const [openDeleteMenu, setOpenDeleteMenu] = useState(false);
  const [searchMemberEmail, setSearchMemberEmail] = useState('');
  const [debouncedSearchMemberEmail, setDebouncedSearchMemberEmail] =
    useState('');
  const [searchedMemberData, setSearchedMemberData] = useState(null);
  const [linkOrUnLinkMemberId, setLinkOrUnLinkMemberId] = useState(null);
  const [createStudentMembers, setCreateStudentMembers] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (student?.memberReferences?.length > 0) {
      setCreateStudentMembers(student?.memberReferences);
    }
  }, [student?.memberReferences]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      firstName: Yup.string().trim().required('First name is required'),
      surname: Yup.string().trim().required('Surname is required'),
      school: Yup.string().trim().required('School is required'),
      year: Yup.string().trim().required('Year is required')
    });
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (isEdit) {
        handleCloseModal(values);
      } else {
        dispatch(loaderStart());
        onUpdate(
          { ...values, memberIds: createStudentMembers.map((m) => m.id) },
          allGroups,
          student
        );
      }
    }
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    setFieldValue
  } = formik;

  useEffect(() => {
    if (school && isCreateStudent) {
      setFieldValue('school', school?.id);
      handleSchoolChange(school?.id);
    }
  }, [school, isCreateStudent]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDebouncedSearchValue = useCallback(
    _.debounce((debouncedValue) => {
      setDebouncedSearchMemberEmail(debouncedValue);
    }, SEARCH_DEBOUNCE_TIME),
    []
  );

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSearchMember = (searchValue) => {
    setSearchMemberEmail(searchValue);
    getDebouncedSearchValue(searchValue);
  };

  const handleClearSearchMember = () => {
    setSearchMemberEmail('');
    setDebouncedSearchMemberEmail('');
    setSearchedMemberData(null);
  };

  useEffect(() => {
    if (debouncedSearchMemberEmail.includes('@')) {
      dispatch(loaderStart());
      searchMember(debouncedSearchMemberEmail)
        .then((response) => {
          const result = response.data;
          if (result) {
            setSearchedMemberData(result);
          } else {
            setSearchedMemberData({});
          }
          dispatch(loaderStop());
        })
        .catch((error) => {
          setSearchedMemberData({});
          console.log('error', error);
          dispatch(loaderStop());
        });
    }
  }, [debouncedSearchMemberEmail, dispatch]);

  useEffect(() => {
    if (student) {
      setInitialValues({
        firstName: student?.firstName || '',
        surname: student?.lastName || '',
        school: isCreateStudent ? school?.id : student?.school || '',
        year: student?.year || '',
        groups: student?.groups || []
      });
    }
  }, [student, school, isCreateStudent]);

  useEffect(() => {
    if (categories?.length > 0) {
      const getStaffObject = categories.find(
        (category) => category.name === 'Staff'
      );
      const getSubjectsObject = categories.find(
        (category) => category.name === 'Subjects'
      );
      if (getStaffObject && getSubjectsObject) {
        const subjectsIndex = categories.findIndex(
          (category) => category.name === 'Subjects'
        );
        const subjectsCategory = categories.splice(subjectsIndex, 1)[0];
        const staffIndex = categories.findIndex(
          (category) => category.name === 'Staff'
        );
        categories.splice(staffIndex, 0, subjectsCategory);
      }
      setSelectedCategory(categories[0].id);
    }
  }, [categories]);

  useEffect(() => {
    if (groups) {
      let autoFilledGroups = [...groups];
      autoFilledGroups = autoFilledGroups.map((group) => {
        const selected =
          student?.groups?.length > 0 && student.groups.includes(group.value)
            ? true
            : false;
        return {
          ...group,
          selected
        };
      });
      setAllGroups(autoFilledGroups);
      dispatch(loaderStop());
    }
  }, [groups]);

  const groupSelectHandler = (value, year, isAllSelected) => {
    let updatedGroups = allGroups?.length > 0 ? [...allGroups] : [];
    const yearIndex = DefaultYear.findIndex((y) => y.value === year);

    if (value === 'all') {
      const selectValue = isAllSelected ? false : true;
      updatedGroups = updatedGroups.map((g) => {
        const createdByApiSync = g.createdByApiSync;
        const disabled =
          g.autoAssignedMembers?.includes(student?.id) && createdByApiSync
            ? true
            : false;
        const group =
          g.category?.includes(selectedCategory) &&
          yearIndex !== -1 &&
          g.years?.includes(DefaultYear[yearIndex].label);
        return {
          ...g,
          selected: group && !disabled ? selectValue : g.selected
        };
      });
    } else {
      const findIndex = updatedGroups.findIndex((g) => g.value === value);
      updatedGroups[findIndex] = {
        ...updatedGroups[findIndex],
        selected: !updatedGroups[findIndex].selected
      };
    }
    setAllGroups(updatedGroups);
  };

  const isSelectedAll = (year) => {
    const arr = sortByAll(
      filterByCategoryYear(allGroups, selectedCategory, year),
      student
    );
    let isAllSelected = true;
    let hasNoGroups = false;
    if (arr.length === 1) {
      isAllSelected = false;
      hasNoGroups = true;
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (!arr[i].selected && arr[i].value !== 'all') {
          isAllSelected = false;
          break;
        }
      }
    }
    return { isAllSelected, hasNoGroups };
  };

  const yearSelectHandler = () => {
    let autoFilledGroups = allGroups?.length > 0 ? [...allGroups] : [];
    autoFilledGroups = autoFilledGroups.map((group) => {
      return {
        ...group,
        selected: false
      };
    });
    setAllGroups(autoFilledGroups);
  };

  const categorySelectHandler = useCallback(
    (categoryId) => {
      setSelectedCategory(categoryId);
    },
    [setSelectedCategory]
  );

  const handleCloseModal = (values) => {
    const updatedData = {
      ...values,
      groups: allGroups
        ? _.filter(allGroups, (g) => g.selected).map((g) => g.value)
        : []
    };
    const hasChanged = compareFormData(initialValues, updatedData);
    if (hasChanged) {
      setConfirm({ open: true, values: updatedData });
    } else {
      onClose();
    }
  };

  const handleConfirm = (isCancel, errors) => {
    if (isCancel) {
      onClose();
    } else {
      if (Object.values(errors).length === 0) {
        dispatch(loaderStart());
        onUpdate(
          {
            ...confirm.values,
            memberIds: createStudentMembers.map((m) => m.id)
          },
          allGroups,
          student
        );
        setConfirm({ open: false, values: null });
      }
    }
  };

  const deleteChildHandler = () => {
    setOpenDeleteMenu(true);
  };

  const handleLinkMemberModal = (memberId) => {
    setIsOpenLinkMemberModal(true);
    setLinkOrUnLinkMemberId(memberId);
  };

  const handleUnLinkMemberModal = (memberId) => {
    setIsOpenUnLinkMemberModal(true);
    setLinkOrUnLinkMemberId(memberId);
  };

  const handleLinkMember = () => {
    dispatch(loaderStart());
    linkOrUnLinkMember({
      studentId: student?.id,
      memberId: linkOrUnLinkMemberId,
      action: 'LINK'
    })
      .then((response) => {
        if (response.message) {
          dispatch(loaderStop());
          setIsOpenLinkMemberModal(false);
          onClose(true);
          dispatch(addMessage(response.message));
        }
      })
      .catch((error) => {
        dispatch(loaderStop());
        console.log('error', error);
        dispatch(addMessage(error?.response.message));
      });
  };

  const handleUnLinkMember = () => {
    dispatch(loaderStart());
    linkOrUnLinkMember({
      studentId: student?.id,
      memberId: linkOrUnLinkMemberId,
      action: 'UNLINK'
    })
      .then((response) => {
        if (response.message) {
          dispatch(loaderStop());
          setIsOpenUnLinkMemberModal(false);
          onClose(true);
          dispatch(addMessage(response.message));
        }
      })
      .catch((error) => {
        dispatch(loaderStop());
        console.log('error', error);
        dispatch(addMessage(error?.response.message));
      });
  };

  const deleteChildConfirm = () => {
    dispatch(loaderStart());
    deleteStudent(student.id)
      .then((response) => {
        if (response) {
          dispatch(addMessage('Child deleted successfully'));
          setIsOpenDeleteModal({ open: false, values: null });
          setDeleteText('');
          setIsDeleted(true);
          dispatch(loaderStop());
        }
      })
      .catch((error) => {
        console.log('error', error);
        dispatch(loaderStop());
        dispatch(addMessage('Error on deleting child', MESSAGE_TYPES.ERROR));
      });
  };

  const handleContinue = () => {
    onClose(isDeleted);
    setIsDeleted(false);
  };

  const redacted = isEdit && childVisibility !== 'Australia';

  return (
    <>
      <RightDrawer
        isOpen={isOpen}
        onClose={() => handleCloseModal(values)}
        width={1005}
      >
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <div className={classes.header}>
          <div className={classes.title}>
            {isEdit && student?.createdByApiSync && (
              <img src={apiManagedIcon} />
            )}
            <span className={classes.mainHeading}>{`${
              values.firstName + ' ' + values.surname
            }`}</span>
            {isCreateStudent && !values.firstName && !values.lastName && (
              <span className={classes.mainHeading}>Create Student</span>
            )}
            {isCreateChild && !values.firstName && !values.lastName && (
              <span className={classes.mainHeading}>Create Child</span>
            )}
          </div>

          <Button
            aria-controls="delete-menu"
            aria-haspopup="true"
            variant="contained"
            color={'primary'}
            type="submit"
            form="StudentForm"
            className={clsx(classes.action)}
          >
            {isEdit
              ? 'Update'
              : isCreateStudent
                ? 'Create Student'
                : isCreateChild
                  ? 'Create Child'
                  : ''}
          </Button>
          {isEdit && !student?.createdByApiSync && (
            <IconButton
              type="button"
              className={classes.moreBtn}
              onClick={() => deleteChildHandler(values)}
            >
              <MoreVert />
            </IconButton>
          )}
          {!student?.createdByApiSync && openDeleteMenu ? (
            <Menu
              id="delete-menu"
              anchorEl={openDeleteMenu}
              open={Boolean(openDeleteMenu)}
              onClose={() => setOpenDeleteMenu(false)}
              className={classes.popoverClass}
            >
              <MenuItem
                onClick={() => {
                  setOpenDeleteMenu(false);
                  if (student.memberReferences.length > 0) {
                    setIsStudentLinkedWithMember(true);
                  } else {
                    setIsOpenDeleteModal({
                      open: true,
                      values: values
                    });
                  }
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          ) : (
            <></>
          )}
        </div>
        <Divider variant="fullWidth" className={classes.dividerClass} />
        <form
          id="StudentForm"
          noValidate
          onSubmit={handleSubmit}
          className={classes.mainContainer}
        >
          <div className={classes.studentDetailsForm}>
            <FormControl
              component="fieldset"
              classes={{ root: classes.formControl }}
              className={clsx(classes.textFieldContainer)}
            >
              <TextField
                label="First Name"
                variant="outlined"
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isEdit && student?.createdByApiSync}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                value={values.firstName}
                className={classes.inputControlBox}
                inputProps={{
                  className: clsx({ [classes.redacted]: redacted })
                }}
              />
            </FormControl>
            <FormControl
              component="fieldset"
              classes={{ root: classes.formControl }}
              className={clsx(classes.textFieldContainer)}
            >
              <TextField
                label="Account Name"
                variant="outlined"
                name="surname"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isEdit && student?.createdByApiSync}
                error={Boolean((touched.surname && errors.surname))}
                helperText={touched.surname && errors.surname}
                value={values.surname}
                className={classes.inputControlBox}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              classes={{ root: classes.inputControlBoxContainer }}
              className={clsx(classes.textFieldContainer)}
            >
              <InputLabel
                className={classes.placeholderText}
                id="child-school-outlined-label"
              >
                School
              </InputLabel>
              <Select
                disabled={
                  (isEdit && student?.createdByApiSync) ||
                  (school?.id && isCreateStudent)
                }
                labelId="child-school-outlined-label"
                id="child-school-outlined"
                style={{
                  height: 60,
                  marginTop: 0
                }}
                name="school"
                onChange={(e) => {
                  const schoolId = e.target.value;
                  setFieldValue('school', schoolId);
                  setFieldValue('year', '');
                  if (schoolId) {
                    handleSchoolChange(schoolId);
                  }
                }}
                label="School"
                value={values.school}
                error={Boolean(touched.school && errors.school)}
              >
                <MenuItem value="">
                  <em>All Schools</em>
                </MenuItem>
                {schools &&
                  schools.map(function (object) {
                    return (
                      <MenuItem key={object.id} value={object.id}>
                        {object.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              {errors.school && touched.school && (
                <FormHelperText style={{ color: 'red' }}>
                  {errors.school}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              variant="outlined"
              classes={{ root: classes.inputControlBoxContainer }}
              className={clsx(classes.textFieldContainer)}
            >
              <InputLabel
                className={classes.placeholderText}
                id="child-year-outlined-label"
              >
                Year
              </InputLabel>
              <Select
                disabled={isEdit && student?.createdByApiSync}
                id="child-year-outlined"
                labelId="child-year-outlined-label"
                className={classes.inputControlBox}
                style={{
                  width: 120,
                  height: 60,
                  marginTop: 0
                }}
                name="year"
                onChange={(e) => {
                  setFieldValue('year', e.target.value);
                  yearSelectHandler();
                }}
                value={values?.year}
                label="Years"
                error={errors.year || (touched.year && errors.year)}
              >
                <MenuItem value="">
                  <em>Years</em>
                </MenuItem>
                {years &&
                  years.map(function (object) {
                    return (
                      <MenuItem key={object.value} value={object.value}>
                        {object.label}
                      </MenuItem>
                    );
                  })}
              </Select>
              {errors.year && touched.year && (
                <FormHelperText style={{ color: 'red' }}>
                  {errors.year}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <Divider
            orientation="vertical"
            className={classes.dividerClass}
            variant="fullWidth"
          />
          <div className={classes.tabs}>
            <CustomTabs
              tabValue={selectedTab}
              searchMember={searchMemberEmail}
              createdByApiSync={student?.createdByApiSync}
              onSearchMember={handleSearchMember}
              onClearMember={handleClearSearchMember}
              handleTabChange={handleTabChange}
            />
            <Divider variant="fullWidth" className={classes.bottomDivider} />
            <TabPanel value={selectedTab} index={0}>
              <div className={classes.categoryList}>
                {categories?.length > 0 &&
                  categories.map((category) => {
                    return (
                      <div
                        onClick={() => categorySelectHandler(category.id)}
                        aria-disabled={!category.isDisplay}
                        key={category.id}
                        className={clsx(
                          classes.categoryBtn,
                          {
                            [classes.activeCategory]:
                              selectedCategory === category.id
                          },
                          {
                            [classes.disabledCategory]: !category.isDisplay
                          }
                        )}
                      >
                        {category.name?.replace('Co-Curricular', 'Activities')}
                      </div>
                    );
                  })}
              </div>
              <div className={classes.groupList}>
                {allGroups &&
                  values.year &&
                  sortByAll(
                    filterByCategoryYear(
                      allGroups,
                      selectedCategory,
                      values.year
                    ),
                    student
                  ).map((element, index) => {
                    const isSelectAllOption =
                      index === 0 && element.value === 'all';
                    const { isAllSelected, hasNoGroups } = isSelectedAll(
                      values.year
                    );
                    if (hasNoGroups) {
                      return (
                        <span key={index} style={{ marginLeft: 4 }}>
                          No groups found
                        </span>
                      );
                    }
                    const autoAssigned =
                      element?.createdByApiSync &&
                      element?.autoAssignedMembers?.includes(student?.id);
                    return (
                      <div
                        className={classes.groupListContainer}
                        key={element.value}
                      >
                        <FormControlLabel
                          label={
                            <div className={classes.labelcontainer}>
                              <div className={classes.checkboxLabels}>
                                <span>{element.key}</span>
                                <span className={classes.yearsLabel}>
                                  {getComaSeparateYears(element.years)}
                                </span>
                              </div>
                              {autoAssigned ? (
                                <img src={apiManagedReadonlyIcon} />
                              ) : element?.createdByApiSync ? (
                                <img src={apiManagedIcon} />
                              ) : (
                                <></>
                              )}
                            </div>
                          }
                          onChange={() =>
                            groupSelectHandler(
                              element.value,
                              values.year,
                              isAllSelected
                            )
                          }
                          className={clsx(classes.formControlLabel, {
                            [classes.selectAll]: isSelectAllOption
                          })}
                          control={
                            <Checkbox
                              disabled={
                                autoAssigned &&
                                selectedCategory === categories[0].id
                              }
                              checked={
                                isSelectAllOption
                                  ? isAllSelected
                                  : element.selected
                              }
                              className={classes.checkboxColor}
                              name={element.key}
                              classes={{
                                root: classes.checkbox,
                                checked: classes.checked,
                                disabled: classes.disabled
                              }}
                            />
                          }
                        />
                      </div>
                    );
                  })}
              </div>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <div className={classes.membersList}>
                {!searchMemberEmail ? (
                  createStudentMembers.length > 0 ? (
                    createStudentMembers.map((member) => (
                      <div
                        className={classes.details}
                        key={`${member.firstName} ${member.lastName}`}
                      >
                        <div className={classes.memberDetails}>
                          <span className={classes.memberName}>
                            {`${member.firstName} ${member.lastName}`}
                          </span>
                          <span className={classes.memberEmail}>
                            {member?.primaryEmail}
                          </span>
                        </div>

                        {student?.createdByApiSync ? (
                          <IconButton
                            className={classes.rightArrow}
                            onClick={() =>
                              history.push(`/members/account/${member.id}`)
                            }
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            type="button"
                            className={classes.moreBtn}
                            onClick={() => handleUnLinkMemberModal(member.id)}
                          >
                            <MoreVert />
                          </IconButton>
                        )}
                      </div>
                    ))
                  ) : (
                    <>No members are linked to this child</>
                  )
                ) : (
                  <>
                    {searchMemberEmail && searchedMemberData && !isLoading && (
                      <>
                        {Object.keys(searchedMemberData).length > 0 ? (
                          <div
                            className={classes.details}
                            key={`${searchedMemberData.firstName} ${searchedMemberData.lastName}`}
                          >
                            <div className={classes.memberDetails}>
                              <span className={classes.memberName}>
                                {`${searchedMemberData.firstName} ${searchedMemberData.lastName}`}
                              </span>
                              <span className={classes.memberEmail}>
                                {searchedMemberData?.primaryEmail}
                              </span>
                            </div>

                            {searchedMemberData?.createdByApiSync ? (
                              <IconButton
                                className={classes.rightArrow}
                                onClick={() =>
                                  history.push(
                                    `/members/account/${searchedMemberData.id}`
                                  )
                                }
                              >
                                <ArrowForwardIosIcon />
                              </IconButton>
                            ) : (
                              <>
                                {createStudentMembers?.some(
                                  (m) => m.id === searchedMemberData.id
                                ) ? (
                                  <IconButton
                                    type="button"
                                    className={classes.moreBtn}
                                    onClick={() => {
                                      handleUnLinkMemberModal(
                                        searchedMemberData?.id
                                      );
                                    }}
                                  >
                                    <MoreVert />
                                  </IconButton>
                                ) : (
                                  <Button
                                    aria-controls="delete-menu"
                                    aria-haspopup="true"
                                    variant="contained"
                                    color={'primary'}
                                    onClick={() => {
                                      handleLinkMemberModal(
                                        searchedMemberData?.id
                                      );
                                    }}
                                  >
                                    <IconButton
                                      type="button"
                                      className={classes.linkBtn}
                                    >
                                      <Link />
                                    </IconButton>{' '}
                                    Link
                                  </Button>
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          <>No Record Found</>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </TabPanel>
          </div>
        </form>
      </RightDrawer>
      {confirm.open && (
        <Modal isOpen={confirm.open}>
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Save your changes?
            </Typography>
            <div className={classes.modalContent}>
              <Typography className={classes.modalSubTitle}>
                {`Your changes will be lost if you don't save them.`}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={() => handleConfirm(false, errors)}
                color="primary"
                variant="contained"
                size="large"
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
                className={classes.primaryButton}
              >
                {!isLoading && 'Save Changes'}
              </Button>
              <Button
                onClick={() => handleConfirm(true, errors)}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                {`Don't Save`}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {isOpenDeleteModal.open && (
        <Modal
          isOpen={isOpenDeleteModal.open}
          onClose={() => {
            setIsOpenDeleteModal({ open: false, values: null });
            setDeleteText('');
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Delete Child Profile?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                <span style={{ fontWeight: 'bold' }}>
                  Warning: This action cannot be reversed.
                </span>
                <br />
                The following data will be permanently removed from the
                database.
              </Typography>
            </div>
            <div className={classes.modalContent}>
              <Typography className={classes.modalSubTitle}>
                - Child profile (admin)
                <br />
                - Calendar subscription (for member)
                <br />
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “DELETE PROFILE” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={deleteText}
                  onChange={(e) => setDeleteText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={deleteChildConfirm}
                color="primary"
                variant="contained"
                size="large"
                disabled={deleteText !== 'DELETE PROFILE'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Delete'}
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => {
                  setIsOpenDeleteModal({ open: false, values: null });
                  setDeleteText('');
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {isStudentIsLinkedWithMember && (
        <Modal
          isOpen={isStudentIsLinkedWithMember}
          onClose={() => {
            setIsStudentLinkedWithMember(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Cannot Delete Profile
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                <br />
                There is at least 1 member profile linked to this child. Remove
                the members and then try again.
              </Typography>
            </div>
            <br />
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  setIsStudentLinkedWithMember(false);
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {isOpenLinkMemberModal && (
        <Modal
          isOpen={isOpenLinkMemberModal}
          onClose={() => {
            setIsOpenLinkMemberModal(false);
            setLinkMemberConfirmationText('');
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Link member to child?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                Linking a member to this child will instantly add this child to
                the member account - accessible from their app login.
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “LINK MEMBER” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={linkMemberConfirmationText}
                  onChange={(e) =>
                    setLinkMemberConfirmationText(e.target.value)
                  }
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  if (isCreateStudent || isCreateChild) {
                    setCreateStudentMembers((memberArr) => {
                      if (
                        !memberArr.some((m) => m.id === searchedMemberData?.id)
                      ) {
                        return [...memberArr, searchedMemberData];
                      }
                      return memberArr;
                    });
                    handleClearSearchMember();
                    setIsOpenLinkMemberModal(false);
                  } else {
                    handleLinkMember();
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
                disabled={linkMemberConfirmationText !== 'LINK MEMBER'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Link Member'}
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => {
                  setIsOpenLinkMemberModal(false);
                  setLinkMemberConfirmationText('');
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {isOpenUnLinkMemberModal && (
        <Modal
          isOpen={isOpenUnLinkMemberModal}
          onClose={() => {
            setIsOpenUnLinkMemberModal(false);
            setUnLinkMemberConfirmationText('');
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Remove member from child?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                Unlinking a member will instantly remove this child from the
                member&apos;s account and their calendar data will no longer be
                visible to the member
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “UNLINK MEMBER” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={unLinkMemberConfirmationText}
                  onChange={(e) =>
                    setUnLinkMemberConfirmationText(e.target.value)
                  }
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  if (isCreateStudent || isCreateChild) {
                    setCreateStudentMembers((memberArr) => {
                      return memberArr.filter(
                        (m) => m.id !== linkOrUnLinkMemberId
                      );
                    });
                    setIsOpenUnLinkMemberModal(false);
                    handleClearSearchMember();
                  } else {
                    handleUnLinkMember();
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
                disabled={unLinkMemberConfirmationText !== 'UNLINK MEMBER'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Unlink Member'}
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => {
                  setIsOpenUnLinkMemberModal(false);
                  setUnLinkMemberConfirmationText('');
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {isDeleted && (
        <Modal isOpen={isDeleted} onClose={() => handleContinue()}>
          <div className={classes.modalBox}>
            <Typography
              className={classes.modalTitle}
              style={{ marginBottom: 18 }}
            >
              Child Profile Deleted
            </Typography>
            <Typography
              className={classes.modalSubTitle}
              style={{ marginBottom: 26 }}
            >
              All data has now been removed from the database.
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={() => handleContinue()}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
              >
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default withFirebase(CreateEditStudent);
