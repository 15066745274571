import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material-UI Icons
import CloseIcon from '@material-ui/icons/Close';

// Material-UI Components
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

// Styles
import { makeStyles } from '@material-ui/core';

// Redux-Actions
import { removeMessage } from 'src/redux/actions/appActions';
import { MESSAGE_TYPES } from 'src/constants/common';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#BD0000 !important'
  }
}));

export default function MessageSnackbar() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const reduxMessage = useSelector((state) => state.app.message);
  const { text: message, type: messageType } = reduxMessage;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(removeMessage());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={message ? true : false}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message?.toString() || ''}
      ContentProps={
        messageType === MESSAGE_TYPES.ERROR
          ? {
              classes: classes
            }
          : undefined
      }
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
}
