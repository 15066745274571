import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Formik } from 'formik';
import clsx from 'clsx';
import {
  makeStyles,
  Paper,
  Button,
  Grid,
  Typography,
  TableCell,
  IconButton,
  TableRow,
  FormControl,
  TextField,
  Divider
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { TrashIcon } from 'src/assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Dashboard from '../layout/Dashboard';
import { routes, colors, linkUrlRegex } from 'src/utils/constant';
import { RightArrow } from 'src/assets/Icons';
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { withFirebase } from 'src/utils/firebase';
import { setFamily } from 'src/redux/actions/familyActions';
import TableList from 'src/components/TableList';
import { generateFileHash } from 'src/utils/functions';
import { Close } from '@material-ui/icons';
import { ReactComponent as EditIcon } from 'src/assets/edit.svg';
import { MESSAGE_TYPES } from 'src/constants/common';

const columns = [
  {
    id: 'title',
    label: 'Title',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '42%'
  },
  {
    id: 'url',
    label: 'URL',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '42%'
  },
  {
    id: 'action',
    label: 'Action',
    iconPosition: 'center',
    minWidth: 170
  }
];

const titleMaxLength = 50;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  tabNavigations: {
    padding: '10px 20px 0',
    marginBottom: 40
  },
  navigationButton: {
    width: 120,
    textTransform: 'none'
  },
  actionButton: {
    margin: '15px 0 20px',
    textTransform: 'none'
  },
  activeTab: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0
  },
  bredthcrumbTitle: {
    color: '#536e7b',
    fontSize: 16,
    marginRight: 24,
    display: 'inline-block',
    cursor: 'pointer'
  },
  drawerLeftContent: {
    width: `calc(60% - 20px)`,
    height: `calc(100vh - 30px)`,
    borderRadius: 5,
    backgroundColor: 'rgb(255, 245, 224,1)',
    float: 'left',
    position: 'relative',
    margin: 10
  },
  drawerRightContent: {
    width: '40%',
    height: `calc(100vh - 10px)`,
    backgroundColor: '#f4f5f7',
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  leftContentHeader: {
    textAlign: 'right'
  },
  label: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: 0.13,
    color: colors.primaryDarkGrey,
    marginLeft: 14,
    marginTop: 20,
    float: 'left'
  },
  siderAvatar: {
    marginTop: 12,
    width: 40,
    float: 'left',
    color: colors.primaryDarkGrey,
    fontSize: 14,
    backgroundColor: colors.primaryLightGrey,
    fontWeight: 500
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    height: 71
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primaryDarkGrey
  },
  siderDetail: {
    fontSize: 14,
    color: colors.primaryDarkGrey,
    marginBottom: 8
  },
  icon: {
    height: '0.7em',
    width: '0.7em'
  },
  siderEdit: {
    height: 40,
    marginTop: 19
  },
  textArea: {
    width: '96%',
    height: '25%',
    borderRadius: 5,
    backgroundColor: '#fff5e0',
    border: 0,
    color: colors.primaryDarkGrey
  },
  leftContentMargin: {
    marginLeft: 40,
    marginRight: 40
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  toolbarHidden: {
    display: 'none'
  },
  toolbar: {
    backgroundColor: 'transparent',
    border: 0,
    '& .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-target-option': {
      display: 'none'
    }
  },
  tableContainer: {
    marginLeft: '-20px',
    width: `calc(100% + 40px)`,
    '& .MuiTableContainer-root': {
      boxShadow: 'none'
    }
  },
  inputControlBox: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
    marginBottom: 16,
    '& .MuiInputBase-input': {
      backgroundColor: colors.white
    }
  },
  addLinkForm: {
    marginTop: 12,
    backgroundColor: colors.secondaryGreyLight,
    '& fieldset': {
      display: 'flex'
    }
  },
  buttonText: {
    marginTop: 24,
    marginRight: 12
  },
  actionIcon: {
    height: 40,
    width: 40,
    padding: 10,
    cursor: 'pointer',
    margin: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
      '& svg g': {
        fill: colors.primary
      },
      '& svg path': {
        fill: colors.primary
      }
    }
  },
  padding0: {
    padding: '0 !important'
  },
  text: {
    paddingLeft: 14
  },
  linkText: {
    color: colors.primaryDark,
    paddingLeft: 14,
    wordBreak: 'break-all',
    display: 'inline-block',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  error: {
    verticalAlign: 'top',
    '& td:last-child': {
      verticalAlign: 'middle'
    }
  }
}));

const getClickableLink = (link) => {
  return link.startsWith('http://') || link.startsWith('https://')
    ? link
    : `http://${link}`;
};

function CellItem({ row, column, deleteLink, handleEdited }) {
  const value = row[column.id];
  const classes = useStyles();
  if (column.id === 'title') {
    return <span className={classes.text}>{value}</span>;
  }
  if (column.id === 'url') {
    return (
      <a
        target="_blank"
        href={getClickableLink(value)}
        className={classes.linkText}
        rel="noreferrer"
      >
        {value}
      </a>
    );
  }
  if (column.id === 'action') {
    return (
      <div
        style={{
          textAlign: 'right'
        }}
      >
        <IconButton
          aria-label="Edit"
          className={classes.actionIcon}
          onClick={() => handleEdited(row.id)}
          style={{ marginRight: 20 }}
        >
          <EditIcon className={classes.icon} />
        </IconButton>
        <IconButton
          aria-label="Delete"
          className={classes.actionIcon}
          onClick={() => deleteLink(row)}
        >
          <TrashIcon viewBox="-2 -2 28 28" />
        </IconButton>
      </div>
    );
  }
  return '';
}

function CellInputItem({
  row,
  column,
  handleRowChange,
  updateRow,
  cancel,
  errors
}) {
  const classes = useStyles();

  if (column.id === 'title') {
    return (
      <FormControl component="fieldset" style={{ width: '99%' }}>
        <TextField
          label="Title"
          variant="outlined"
          name="title"
          onChange={(e) => {
            handleRowChange(e, 'title');
          }}
          error={errors.title ? true : false}
          value={row.title}
          className={classes.inputControlBox}
          helperText={errors.title}
        />
      </FormControl>
    );
  }
  if (column.id === 'url') {
    return (
      <FormControl component="fieldset" style={{ width: '99%' }}>
        <TextField
          label="URL"
          variant="outlined"
          name="url"
          onChange={(e) => {
            handleRowChange(e, 'url');
          }}
          error={errors.url ? true : false}
          value={row.url}
          className={classes.inputControlBox}
          helperText={errors.url}
        />
      </FormControl>
    );
  }
  if (column.id === 'action') {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          component="span"
          color="primary"
          onClick={() => {
            updateRow();
          }}
        >
          Save
        </Button>
        <IconButton
          aria-label="Delete"
          className={classes.actionIcon}
          onClick={cancel}
          style={{ marginLeft: 5, marginRight: 16 }}
        >
          <Close className={classes.icon} />
        </IconButton>
      </div>
    );
  }
  return '';
}

// eslint-disable-next-line no-unused-vars
function TableRowItem({ row, index, columns, updateLink, deleteLink }) {
  const classes = useStyles();
  const [editedRow, setEditedRow] = useState('');
  const [rowValues, setRowValues] = useState(row);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setRowValues(row);
    return () => false;
  }, [row]);

  const updateRow = () => {
    let errorObj = {};
    if (!rowValues.title) {
      errorObj = { ...errorObj, title: 'Title is required.' };
    } else {
      if (rowValues.title.length > titleMaxLength) {
        errorObj = {
          ...errorObj,
          title: `Maximum ${titleMaxLength} characters used for title.`
        };
      }
    }
    if (!rowValues.url) errorObj = { ...errorObj, url: 'URL is required.' };
    if (rowValues.url) {
      const checkURLIsValid = rowValues.url.match(linkUrlRegex);
      if (checkURLIsValid == null) {
        errorObj = { ...errorObj, url: 'URL is not valid.' };
      }
    }
    setErrors(errorObj);
    if (!Object.keys(errorObj).length) {
      updateLink(rowValues);
      setEditedRow('');
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      className={clsx(classes.tableRow, {
        [classes.error]: Object.keys(errors).length > 0
      })}
    >
      {columns.map((column) => {
        return (
          <TableCell
            key={column.id}
            align={column.align}
            className={clsx(classes.dataTableCell, {
              [classes.padding0]: editedRow === row.id
            })}
            style={{ width: column.width }}
          >
            {editedRow === row.id ? (
              <CellInputItem
                row={rowValues}
                column={column}
                deleteLink={deleteLink}
                updateRow={updateRow}
                handleRowChange={(e, field) => {
                  const newValue = { ...rowValues, [field]: e.target.value };
                  setRowValues(newValue);
                }}
                errors={errors}
                cancel={() => setEditedRow('')}
              />
            ) : (
              <CellItem
                row={row}
                column={column}
                deleteLink={deleteLink}
                handleEdited={(id) => setEditedRow(id)}
              />
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function AddLinkForm({ initialValues, saveLink }) {
  const classes = useStyles();
  const currentFamily = useSelector((state) => state.family.currentFamily);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setErrors, setFieldValue }) => {
        let errorObj = {};
        if (!values.title) {
          errorObj = { ...errorObj, title: 'Title is required.' };
        } else {
          if (values.title.length > titleMaxLength) {
            errorObj = {
              ...errorObj,
              title: `Maximum ${titleMaxLength} characters used for title.`
            };
          }
        }
        if (!values.url) errorObj = { ...errorObj, url: 'URL is required.' };
        if (values.url) {
          const checkURLIsValid = values.url.match(linkUrlRegex);
          if (checkURLIsValid == null) {
            errorObj = { ...errorObj, url: 'URL is not valid.' };
          }
        }
        setErrors(errorObj);
        if (!Object.keys(errorObj).length) {
          const newLink = {
            ...values,
            id: generateFileHash(20)
          };
          const updateFamily = { ...currentFamily };
          updateFamily.links = updateFamily.links
            ? updateFamily.links.concat(newLink)
            : [newLink];

          setFieldValue('title', '');
          setFieldValue('url', '');
          saveLink(updateFamily);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <div className={classes.addLinkForm}>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  <FormControl component="fieldset">
                    <TextField
                      label="Title"
                      variant="outlined"
                      name="title"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      error={Boolean(touched.title && errors.title)}
                      value={values.title}
                      className={classes.inputControlBox}
                      helperText={touched.title && errors.title}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormControl component="fieldset">
                    <TextField
                      label="URL"
                      variant="outlined"
                      className={classes.inputControlBox}
                      name="url"
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      error={Boolean(touched.url && errors.url)}
                      value={values.url}
                      helperText={touched.url && errors.url}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Grid container justify="flex-end">
                    <Button
                      variant="contained"
                      component="span"
                      color="primary"
                      className={classes.buttonText}
                      startIcon={<AddIcon />}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Add Link
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

function LinksView({ firebase }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentFamily = useSelector((state) => state.family.currentFamily);
  const [initialValues, setInitialValues] = useState({ title: '', url: '' });
  const [links, setLinks] = useState([]);

  const familyID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  useEffect(() => {
    if (!currentFamily.familySurname && familyID) {
      dispatch(loaderStart());
      firebase
        .getFamilyByID(familyID)
        .then(
          async (doc) => {
            const familyData = doc.data();
            if (familyData) {
              dispatch(setFamily(familyData));
            }
            dispatch(loaderStop());
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(
              addMessage('Error on getting family', MESSAGE_TYPES.ERROR)
            );
            dispatch(loaderStop());
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    }
  }, [currentFamily, familyID, firebase, dispatch]);

  useEffect(() => {
    if (currentFamily.links && currentFamily.links.length) {
      const sortedLinks = _.orderBy(
        currentFamily.links,
        [(link) => link.title.toLowerCase()],
        ['asc']
      );
      setLinks(sortedLinks);
    }
  }, [currentFamily]);

  const { familySurname } = useMemo(() => {
    if (currentFamily) {
      return {
        familySurname: currentFamily.familySurname,
        id: currentFamily.id,
        parents: currentFamily.parents,
        childs: currentFamily.childs
      };
    }
    return {
      familySurname: '',
      id: '',
      parents: [],
      childs: []
    };
  }, [currentFamily]);

  const saveLink = (data) => {
    dispatch(loaderStart());
    submitFamilyData(data, 'Link has been added.');
  };

  const updateLink = (row) => {
    const updateFamily = { ...currentFamily };
    updateFamily.links = updateFamily.links.map((item) => {
      if (item.id === row.id) {
        item = row;
      }
      return item;
    });
    const sortedLinks = _.orderBy(
      updateFamily.links,
      [(link) => link.title.toLowerCase()],
      ['asc']
    );
    setLinks(sortedLinks);
    submitFamilyData(updateFamily, 'Link has been updated.');
  };

  const deleteLink = (row) => {
    const removeLink = links.filter((item) => item.id !== row.id);
    const updateFamily = { ...currentFamily };
    updateFamily.links = removeLink;
    setLinks(updateFamily.links);
    submitFamilyData(updateFamily, 'Link has been deleted.');
  };

  const submitFamilyData = (data, message) => {
    firebase
      .updateFamily(data, currentFamily.id)
      .then(
        () => {
          dispatch(loaderStop());
          dispatch(setFamily(data));
          dispatch(addMessage(message));
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
          setInitialValues({ title: '', url: '' });
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  };

  return (
    <Dashboard>
      <div className={classes.root}>
        <div>
          <Typography
            variant="body1"
            className={classes.bredthcrumbTitle}
            onClick={() =>
              window.location.pathname.match('/new')
                ? history && history.push(routes.familiesFreeNew)
                : window.location.pathname.match('/pending')
                  ? history && history.push(routes.familiesFreePending)
                  : window.location.pathname.match('/paid')
                    ? history && history.push(routes.familiesPaid)
                    : history && history.push(routes.familiesFree)
            }
          >
            Families
          </Typography>
          <RightArrow
            viewBox="0 0 24 24"
            height={24}
            width={24}
            style={{ fontSize: 22, verticalAlign: 'middle' }}
          />
        </div>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20, marginTop: 22 }}
        >
          {familySurname}
        </Typography>
        <Paper className={classes.tabNavigations}>
          <div>
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.familyCalendar + '/' + familyID)
                  : history.push(routes.familyCalendarPaid + '/' + familyID);
              }}
            >
              Calendar
            </Button>
            {currentFamily.planType !== 'Free' && (
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyFiles + '/' + familyID)
                    : history.push(routes.familyFilesPaid + '/' + familyID);
                }}
              >
                Files
              </Button>
            )}
            {currentFamily.planType !== 'Free' && (
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyNotes + '/' + familyID)
                    : history.push(routes.familyNotesPaid + '/' + familyID);
                }}
              >
                Notes
              </Button>
            )}
            {currentFamily.planType !== 'Free' && (
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyLinks + '/' + familyID)
                    : history.push(routes.familyLinksPaid + '/' + familyID);
                }}
                className={classes.activeTab}
              >
                Links
              </Button>
            )}
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.familyUsers + '/' + familyID)
                  : history.push(routes.familyUsersPaid + '/' + familyID);
              }}
            >
              Family
            </Button>
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.familyAccount + '/' + familyID)
                  : history.push(routes.familyAccountPaid + '/' + familyID);
              }}
            >
              Account
            </Button>
          </div>
          <Divider variant="fullWidth" />
          <div className={classes.tableContainer}>
            <AddLinkForm initialValues={initialValues} saveLink={saveLink} />
            <TableList
              columns={columns}
              rows={links}
              disableSearch
              disableColumnLabel
              pageTitle={'Families'}
              tableRow={(row, index) => (
                <TableRowItem
                  key={index}
                  columns={columns}
                  row={row}
                  updateLink={updateLink}
                  deleteLink={deleteLink}
                />
              )}
            />
          </div>
        </Paper>
      </div>
    </Dashboard>
  );
}

export default withFirebase(LinksView);
