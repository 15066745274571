import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function SchoolsIcon({ color, ...props }) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="20" cy="20" r="20" />
        <g
          stroke={color || '#4C5B68'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path
            d="M4.267 15.36c0 1.647 3.413 2.987 7.68 2.987 4.266 0 7.68-1.34 7.68-2.987M4.267 15.787L4.267 8.107M19.627 15.787L19.627 8.107M23.893 5.973L11.947 11.947 0 5.973 0 5.973 0 5.973 11.947 0 23.893 5.973 23.893 5.973zM23.04 6.4L23.04 12.373"
            transform="translate(8 11)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
export default SchoolsIcon;
