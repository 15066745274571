import { makeStyles } from '@material-ui/core';
import { colors } from 'src/utils/constant';

export const createEditGroupStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  headingContainer: {
    height: '98px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px'
  },
  mainHeading: {
    height: '40px',
    flexGrow: '0',
    fontFamily: 'Roboto',
    fontSize: '23px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.74,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.primaryDark
  },
  siderSave: {
    width: '144px',
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary
  },
  editSiderSave: {
    width: '88px'
  },
  moreVertButton: {
    justifyContent: 'flex-end',
    padding: 'unset  !important',
    minWidth: '20px',
    marginLeft: '25px'
  },
  popoverClass: {
    '& .MuiPaper-rounded': {
      right: '70px',
      left: 'unset  !important',
      top: '70px !important'
    }
  },
  groupSideBarBodyContainer: {
    display: 'grid',
    gridTemplateColumns: '49.90% auto 49.90%',
    boxSizing: 'border-box',
    height: 'calc(100vh - 114px)'
  },
  dividerClass: {
    backgroundColor: colors.primaryLightGrey
  },
  formControlContainer: {
    padding: '30px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
  },
  formControl: {
    width: 450,
    height: 60
  },
  formControlRadio: {
    width: '100% !important'
  },
  controlBox: {
    width: 572,
    height: 60,
    borderRadius: 4,
    borderStyle: 'solid',
    border: 1,
    borderColor: colors.primaryLightGrey,
    backgroundColor: '#ffffff',
    marginTop: 24,
    '& .MuiOutlinedInput-root:hover, & .MuiOutlinedInput-root:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  controlBoxRadio: {
    borderRadius: 4,
    borderStyle: 'solid',
    border: 1,
    borderColor: colors.primaryLightGrey,
    backgroundColor: '#ffffff',
    '& .MuiOutlinedInput-root:hover, & .MuiOutlinedInput-root:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  inputControlBox: {
    backgroundColor: '#ffffff',
    fontSize: '16px',
    '& .MuiSelect-outlined:hover, & .MuiSelect-outlined:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)',
      '&.Mui-disabled': {
        boxShadow: 'none'
      }
    }
  },
  textFieldContainer: {
    '& .MuiInputLabel-shrink': {
      height: 16,
      objectFit: 'contain',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      paddingLeft: 5,
      paddingRight: 5,
      '&.MuiFormLabel-filled': {
        transform: 'translate(14px, -8px) scale(1) !important',
        color: colors.primary,
        fontWeight: '500',
        '&.Mui-error': {
          color: '#f44336'
        }
      },
      '&.Mui-focused': {
        transform: 'translate(14px, -8px) scale(1) !important',
        color: colors.primary,
        fontWeight: '500',
        '&.Mui-error': {
          color: '#f44336'
        }
      }
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      marginLeft: '5px !important',
      width: '90px !important'
    }
  },
  selectFieldContainer: {
    '& .MuiInputLabel-shrink': {
      height: 16,
      objectFit: 'contain',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      paddingLeft: 5,
      paddingRight: 5,
      '&.MuiFormLabel-filled': {
        transform: 'translate(14px, -8px) scale(1) !important',
        color: colors.primary,
        fontWeight: '500',
        '&.Mui-error': {
          color: '#f44336'
        }
      },
      '&.Mui-focused': {
        transform: 'translate(14px, -8px) scale(1) !important',
        color: colors.primary,
        fontWeight: '500',
        '&.Mui-error': {
          color: '#f44336'
        }
      }
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      marginLeft: '5px !important',
      width: '53px !important'
    }
  },
  radioContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  checkLabel: {
    height: 16,
    objectFit: 'contain',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: colors.primary,
    marginLeft: 15,
    paddingLeft: 5,
    paddingRight: 5
  },
  labelText: {
    height: 35,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: colors.primaryDark,
    marginTop: 11.5,
    marginBottom: 11.5
  },
  memberListLabelText: {
    marginTop: 5,
    marginBottom: 5,
    width: '88%'
  },
  radio: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  checkbox: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  checked: {},
  radioButton: {
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20
    }
  },
  checkboxColor: {
    color: colors.primaryLightGrey,
    '& .MuiSvgIcon-root': {
      height: 24,
      width: 24
    }
  },
  disabledCheckboxColor: {
    color: `${colors.primaryLightGrey} !important`,
    opacity: 0.5
  },
  disabledCheckboxColorForApiConnect: {
    color: `${colors.primary} !important`,
    opacity: 0.5
  },
  formControlLabelColorCustom: {
    color: colors.primaryDarkGrey
  },
  formControlLabelColorForSelectAll: {
    '& .MuiTypography-body1': {
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal'
    }
  },
  formControlYear: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: '20px 0'
  },
  checkboxIconContainer: {
    padding: '6px 6px',
    '&:hover': {
      backgroundColor: 'rgba(74, 90, 104, 0.04)'
    }
  },
  rightPanelContainer: {
    backgroundColor: colors.secondaryGreyLight
  },
  membersDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(90vh - 70px)',
    overflow: 'auto',
    paddingLeft: '24px',
    paddingTop: '30px'
    // '@media (max-width: 1560px)': {
    //   height: 'calc(100vh - 70px)',
    // }
  },
  memebrNameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '410px',
    alignItems: 'center'
  },
  disabledFillApiConnectIconData: {
    opacity: 0.5
  },
  modalBox: {
    width: 450
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalContent: {
    minHeight: 75,
    paddingTop: 12
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark
  },
  modalActions: {
    marginTop: '2%'
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  }
}));
