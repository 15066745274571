/* eslint-disable camelcase */
import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Paper,
  Button,
  Divider,
  TextField,
  Typography,
  IconButton,
  Snackbar,
  SnackbarContent,
  FormControl,
  Select,
  MenuItem,
  Link,
  CircularProgress,
  Backdrop
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import { generateFileHash, isValidFileType } from '../../utils/functions';
import { withFirebase } from 'src/utils/firebase';
import Dashboard from '../layout/Dashboard';
import { routes, colors, copyFileUrl } from 'src/utils/constant';
import {
  RightArrow,
  TrashIcon,
  DownloadIcon,
  LinkIcon,
  CheckCircleIcon
} from 'src/assets/Icons';
import TableList from 'src/components/TableList';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Edit, HandBook } from '../../assets';
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { setSchool } from 'src/redux/actions/schoolActions';
import { useCallback } from 'react';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { ContentState } from 'draft-js';
import { EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import RightDrawer from 'src/components/RightDrawer';
import { Editor } from 'react-draft-wysiwyg';
import FileTableRow from 'src/components/FileTableRow';
import clsx from 'clsx';
import ScanningUploadFileModal from 'src/components/ScanningUploadFileModal';
import api_icon from 'src/assets/apiIcon.svg';
import schoolBytes from 'src/assets/schoolByte2.webp';
import NavTab from './NavTab';
import studentReportingIcon from 'src/assets/icn-student-reporting.svg';
import {
  addSchoolFormDetailsOrUpdateSchool,
  getSchoolByID
} from 'src/services/schools';
import { filesStyles } from 'src/styles/schoolStyle/filesStyles';
import { MESSAGE_TYPES } from 'src/constants/common';
 
const columns = [
  { id: 'fileUrl', minWidth: 50, align: 'left', cellAttr: { width: 85 } },
  { id: 'fileName', minWidth: 170, align: 'left' },
  {
    id: 'uploadDate',
    minWidth: 170,
    align: 'left',
    cellAttr: { width: 200 }
  }
];
 
const generateMonthOptionsForFilter = (files) => {
  const monthView = [];
  files.map((fileItem) => {
    const uploadedDate = dayjs.unix(fileItem.uploadDate).format('MMMM YYYY');
    const foundMonthIndex = _.findIndex(
      monthView,
      (month) => month.value === uploadedDate
    );
    if (foundMonthIndex > -1) {
      monthView[foundMonthIndex] = {
        label: uploadedDate,
        value: uploadedDate
      };
    } else {
      monthView.push({
        label: uploadedDate,
        value: uploadedDate
      });
    }
    return null;
  });
  return monthView;
};
 
const FilesView = ({ firebase }) => {
  const classes = filesStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentTimestamp = dayjs().unix();
  const [files, setFiles] = useState([]);
  const monthOptionsForFilter = generateMonthOptionsForFilter(files);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [activeRow, setActiveRowData] = useState({});
  const [isFileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCopyToChipboardSuccess, setCopyToChipboardSuccess] = useState(false);
  const [month, setMonth] = useState('');
 
  const prefillSchoolData = useSelector((state) => state.school.currentSchool);
  const isLoading = useSelector((state) => state.app.isLoading);
 
  const [isEditMode, setEditMode] = useState(false);
  const [memberData, setMemberData] = useState(false);
  const [defaultNote, setDefaultNote] = useState('');
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [openStudentReportPopUp, setOpenStudentReportPopUp] = useState(false);
  const [fileUploadModal, setFileUploadModal] = useState({
    isModalOpen: false,
    fileUploadState: 0
  });
 
  const schoolID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);
 
  useEffect(() => {
    if (!prefillSchoolData.schoolName && schoolID) {
      dispatch(loaderStart());
      // PHASE2/CHANGES/SCHOOL
 
      getSchoolByID(schoolID)
        .then((school) => {
          if (school) {
            dispatch(setSchool(school));
          }
          dispatch(loaderStop());
        })
        .catch((error) => {
          console.error(error);
          dispatch(addMessage('Error on getting school', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    }
  }, [prefillSchoolData.schoolName, schoolID, dispatch]);
 
  const schoolName = useMemo(
    () => (prefillSchoolData ? prefillSchoolData.schoolName : ''),
    [prefillSchoolData]
  );
 
  const getSchoolFiles = useCallback(() => {
    dispatch(loaderStart());
    firebase
      .getSchoolEntriesFiles(schoolID)
      .then((querySnapshot) => {
        const FilesData = [];
        querySnapshot.forEach((doc) => {
          FilesData.push({ ...doc.data(), ...{ id: doc.id } });
        });
        firebase
          .getFiles(schoolID)
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              FilesData.push({ ...doc.data(), ...{ id: doc.id } });
            });
            setFiles(FilesData);
            setFilteredFiles(FilesData);
            dispatch(loaderStop());
            setFileUploadSuccess(false);
            setMonth('');
          })
          .catch((error) => {
            console.log('Error getting documents: ', error);
            dispatch(
              addMessage(
                'Server connection issue. Please refresh',
                MESSAGE_TYPES.ERROR
              )
            );
            dispatch(loaderStop());
          });
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
        dispatch(
          addMessage(
            'Server connection issue. Please refresh',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });
  }, [firebase, setFiles, dispatch, isFileUploadSuccess, schoolID]);
 
  useEffect(() => {
    getSchoolFiles();
  }, [getSchoolFiles]);
 
  const handleOnClose = () => {
    setFileUploadModal((prev) => ({ ...prev, isModalOpen: false }));
  };
 
  const onFileChange = (event) => {
    const fileLength = event.target.files.length;
    if (fileLength > 0) {
      const file = event.target.files[0];
      const fileType = file.name.split('.').pop().toLowerCase();
      event.target.value = '';
      if (isValidFileType(fileType)) {
        const name = file.name.substring(0, file.name.lastIndexOf('.'));
        const fileName = name + '_mywhanau_' + Date.now() + '.' + fileType;
        setFileUploadModal({ isModalOpen: true, fileUploadState: 0 });
        firebase
          .uploadSchoolEntriesFile(file, schoolID, fileName)
          .then((snapShot) => {
            // takes a snap shot of the process as it is happening
            if (snapShot && snapShot.state === 'success') {
              firebase
                .getDownloadURLForSchoolEntriesFile(schoolID, fileName)
                .then((fireBaseUrl) => {
                  const fileData = {
                    schoolId: schoolID,
                    fileHash: generateFileHash(),
                    fileName: fileName,
                    fileType: file.type,
                    fileUrl: fireBaseUrl,
                    uploadDate: currentTimestamp
                  };
                  firebase.addFile(fileData);
                  setFileUploadSuccess(true);
                  handleOnClose();
                })
                .catch((err) => {
                  // catches the errors
                  console.log('Error while Getting Download URL', err);
                  handleOnClose();
                });
            } else {
              setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
            }
          })
          .catch((err) => {
            // catches the errors
            console.log('Error while Uploading URL', err);
            setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
          });
      } else {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 1 });
      }
    }
  };
 
  const handleMonthChange = (event) => {
    const selectedValue = event.target.value;
    setMonth(selectedValue);
    const selectedMonth = dayjs(selectedValue).format('MM');
    const selectedYear = dayjs(selectedValue).format('YYYY');
    let filteredFiles = files;
    if (!_.isEmpty(selectedValue)) {
      filteredFiles = filteredFiles.filter((e) => {
        const uploadedDate = e.uploadDate;
        const uploadedMonth = dayjs.unix(uploadedDate).format('MM');
        const uploadedYear = dayjs.unix(uploadedDate).format('YYYY');
        return selectedMonth === uploadedMonth && selectedYear === uploadedYear;
      });
      setFilteredFiles(filteredFiles);
    } else {
      setFilteredFiles(filteredFiles);
    }
  };
 
  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
 
    setFileUploadSuccess(false);
    setIsOpenModal(false);
  };
 
  const handleModalClose = () => {
    setActiveRowData('');
    setIsOpenModal(false);
  };
 
  const deleteFile = (fileName, docId) => {
    handleModalClose();
    dispatch(loaderStart());
    firebase
      .deleteSchoolEntriesFile(schoolID, fileName)
      .then(() => {
        firebase
          .deleteFileDocument(docId)
          .then(() => {
            getSchoolFiles();
          })
          .catch((error) => {
            console.error('Error while removing document: ', error);
            dispatch(loaderStop());
          });
      })
      .catch((error) => {
        if (error && error.code === 'storage/object-not-found') {
          firebase
            .deleteFileDocument(docId)
            .then(() => {
              getSchoolFiles();
            })
            .catch((error) => {
              console.error('Error removing document: ', error);
              dispatch(loaderStop());
            });
        }
        dispatch(loaderStop());
        console.log('Error removing file', error);
      });
  };
 
  const downloadFile = (fileUrl, fileName) => {
    fetch(fileUrl).then(async (response) => {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
 
  const CheckCircleIconComponent = ({ iconColor }) => (
    <CheckCircleIcon
      viewBox="0 0 24 24"
      height={24}
      width={24}
      color={iconColor}
      className={classes.iconStyle}
    />
  );
 
  const DownloadIconComponent = ({ iconColor }) => (
    <DownloadIcon
      viewBox="0 0 24 24"
      height={24}
      width={24}
      color={iconColor}
      className={classes.iconStyle}
    />
  );
 
  const DeleteIconComponent = ({ iconColor }) => (
    <TrashIcon
      viewBox="0 0 24 24"
      height={24}
      width={24}
      color={iconColor}
      className={classes.iconStyle}
    />
  );
 
  const CopyToClipboardComponent = ({ text }) => (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setCopyToChipboardSuccess(true);
      }}
    >
      <Button
        variant="contained"
        component="span"
        size="small"
        color="primary"
        startIcon={
          <LinkIcon
            viewBox="0 0 24 24"
            height={24}
            width={30}
            color="#FFF"
            className={classes.iconStyle}
          />
        }
        style={{ borderRadius: 3, width: 169, height: 40 }}
      >
        <Typography className={classes.buttonLabelText}>
          Copy File URL
        </Typography>
      </Button>
    </CopyToClipboard>
  );
 
  const TopHeader = () => {
    const activeFileUrl = _.get(activeRow, 'fileUrl', '');
    const activeFileId = _.get(activeRow, 'id', '');
    const activeFileUploadDate = _.get(activeRow, 'uploadDate', '');
    const activeFileName = _.get(activeRow, 'fileName', '');
    const activeFileType = _.get(activeRow, 'fileType', '');
    const activeFileHash = _.get(activeRow, 'fileHash', '');
    let imageSource = '';
    let fileTypePdfText = false;
    if (!_.isEmpty(activeFileUrl)) {
      if (activeFileType.indexOf('image/') !== -1) {
        imageSource = activeFileUrl;
      } else {
        fileTypePdfText = true;
      }
    }
 
    return (
      <>
        <div className={classes.topHeader}>
          <div className={classes.flex}>
            <IconButton
              aria-label="back button"
              onClick={() => setIsOpenModal(false)}
            >
              <ArrowBackIcon className={classes.iconWhite} />
            </IconButton>
            <span className={classes.backArrowMargin}>{activeFileName}</span>
          </div>
          <div>
            {isCopyToChipboardSuccess ? (
              <Button
                component="span"
                size="small"
                classes={{
                  root: classes.buttonRoot,
                  label: classes.buttonLabel
                }}
                style={{ borderRadius: 3, width: 119, height: 40 }}
                disable="true"
                startIcon={<CheckCircleIconComponent iconColor="#FFF" />}
              >
                <Typography className={classes.buttonLabelText}>
                  Copied
                </Typography>
              </Button>
            ) : (
              <CopyToClipboardComponent
                text={`${copyFileUrl}${activeFileHash}`}
              />
            )}
          </div>
          <div className={classes.flex}>
            {`Saved ${dayjs.unix(activeFileUploadDate).format('DD MMMM YYYY')}`}
            <IconButton
              className={classes.primaryIconButton}
              aria-label="download"
              onClick={() => downloadFile(activeFileUrl, activeFileName)}
            >
              <DownloadIconComponent iconColor="#FFF" />
            </IconButton>
            <IconButton
              className={classes.primaryIconButton}
              aria-label="delete"
              onClick={() => {
                deleteFile(activeFileName, activeFileId);
              }}
            >
              <DeleteIconComponent iconColor="#FFF" />
            </IconButton>
          </div>
        </div>
        {fileTypePdfText ? (
          <div className={classes.pdfPreview}>
            <iframe
              title="File Preview"
              src={`${activeFileUrl}#toolbar=0`}
              width="50%"
              height="450"
            ></iframe>
          </div>
        ) : (
          <div
            className={classes.imagePreview}
            style={{ backgroundImage: `url(${imageSource})` }}
          />
        )}
      </>
    );
  };
 
  const onCloseForm = () => {
    const defaultData = draftToHtml(
      convertToRaw(defaultNote.getCurrentContent())
    );
    const memberNotes = memberData.notes;
    if (defaultData === memberNotes) {
      // handleClose();
      onClose();
    } else if (
      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
      !memberNotes
    ) {
      onClose();
    } else if (memberNotes === '') {
      // handleClose();
      onClose();
    }
  };
 
  const onClose = useCallback(() => {
    setEditMode(false);
    setOpenDrawer(false);
  }, [setOpenDrawer]);
 
  const openRightDrawer = useCallback(
    (data) => {
      setMemberData(data);
      const contentBlock = htmlToDraft(data.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      setOpenDrawer(true);
    },
    [setOpenDrawer, prefillSchoolData]
  );
 
  const handleClose = useCallback(() => {
    setEditMode(false);
  }, []);
 
  const onEditorChange = (value) => {
    const editorData = draftToHtml(convertToRaw(value.getCurrentContent()));
    setMemberData({ ...memberData, notes: editorData });
  };
 
  const onSaveChanges = async () => {
    // PHASE2/CHANGES/SCHOOL
 
    const payload = {
      notes: memberData?.notes
    };
 
    dispatch(loaderStart());
 
    try {
      await addSchoolFormDetailsOrUpdateSchool(prefillSchoolData.id, payload);
      dispatch(setSchool(payload));
      dispatch(addMessage('Changes saved'));
      const contentBlock = htmlToDraft(payload?.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
        dispatch(loaderStop());
      }
      handleClose();
    } catch (error) {
      console.error(error);
      dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };
 
  const navTabsOptions = useMemo(() => {
    return [
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Calendar',
              link: `${routes.schoolCalendar}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Groups',
        link: `${routes.schoolGroup}/${schoolID}`
      },
      {
        label: 'Students',
        link: `${routes.schoolStudent}/${schoolID}`
      },
      {
        divider: true
      },
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Links',
              link: `${routes.schoolLinks}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Notes',
        handleTabClick: () => openRightDrawer(prefillSchoolData)
      },
      {
        label: 'Files',
        link: `${routes.schoolFiles}/${schoolID}`
      },
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Form',
              link: `${routes.schoolForm}/${schoolID}`
            }
          ]
        : []),
      {
        divider: true
      },
      {
        label: 'Account',
        link: `${routes.schoolAccount}/${schoolID}`
      }
    ];
  }, [prefillSchoolData]);
 
  return (
    <>
      <Dashboard>
        <div className={classes.root}>
          <div>
            <Typography
              variant="body1"
              className={classes.bredthcrumbTitle}
              onClick={() => history && history.push(routes.schools)}
            >
              Schools
            </Typography>
            <RightArrow
              viewBox="0 0 24 24"
              height={24}
              width={24}
              style={{ fontSize: 22, verticalAlign: 'middle' }}
            />
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ marginBottom: 20, marginTop: 22 }}
          >
            {schoolName}
          </Typography>
          <Paper className={classes.tabNavigations}>
            <img
              className={classes.reportImage}
              src={studentReportingIcon}
              alt="Student report"
              onClick={() => setOpenStudentReportPopUp(!openStudentReportPopUp)}
            />
            {openStudentReportPopUp ? (
              <article className={classes.studentReportPopUp}>
                <div style={{ fontWeight: 500, marginBottom: '10px' }}>
                  Student reporting
                </div>
                {prefillSchoolData?.createdByApiSync ? (
                  <div className={classes.flexBoxBetween}>
                    <span>Population</span>
                    <span>
                      {prefillSchoolData?.studentReport?.apiStudent || 0}
                    </span>
                  </div>
                ) : null}
                <div className={classes.flexBoxBetween}>
                  <span>Active</span>
                  <span>{prefillSchoolData?.studentReport?.active || 0}</span>
                </div>
                <div className={classes.flexBoxBetween}>
                  <span>Inactive</span>
                  <span>{prefillSchoolData?.studentReport?.inActive || 0}</span>
                </div>
                {prefillSchoolData?.createdByApiSync ? (
                  <div className={classes.flexBoxBetween}>
                    <span>Opportunity</span>
                    <span>
                      {prefillSchoolData?.studentReport?.nonRegistered || 0}
                    </span>
                  </div>
                ) : null}
              </article>
            ) : null}
            <section
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div>
                <NavTab
                  tabs={navTabsOptions}
                  selectedTab={isOpenDrawer ? 'Notes' : 'Files'}
                />
              </div>
              {prefillSchoolData && prefillSchoolData?.createdByApiSync && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '1rem'
                    //  padding: '0 2rem'
                  }}
                >
                  <img src={schoolBytes} height="38px" />
                  <img src={api_icon} />
                </div>
              )}
            </section>
            <Divider />
            <div className={classes.filterWrapper}>
              <label htmlFor="upload-new-file">
                <TextField
                  style={{ display: 'none' }}
                  id="upload-new-file"
                  name="upload-new-file"
                  type="file"
                  onChange={(e) => onFileChange(e)}
                />
                <Button
                  variant="contained"
                  component="span"
                  size="small"
                  color="primary"
                  className={classes.actionButton}
                  startIcon={<AddIcon />}
                >
                  Upload New File
                </Button>
              </label>
              <div className={classes.flex}>
                <span>Month</span>
                <FormControl
                  variant="outlined"
                  color="secondary"
                  className={classes.formControl}
                >
                  <Select
                    labelId="filter"
                    id="filter"
                    displayEmpty
                    notched={false}
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={month}
                    onChange={handleMonthChange}
                    label="FilterMonth"
                  >
                    <MenuItem value="">Select Month</MenuItem>
                    {!_.isEmpty(monthOptionsForFilter) &&
                      monthOptionsForFilter.map((monthValues, key) => {
                        return (
                          <MenuItem key={key} value={monthValues.value}>
                            {monthValues.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div
              className={clsx(classes.tableContainer, {
                [classes.tableContainerTopBorder]: !_.isEmpty(filteredFiles)
              })}
            >
              {!_.isEmpty(filteredFiles) && (
                <TableList
                  columns={columns}
                  rows={filteredFiles}
                  totalCount={filteredFiles.length}
                  disableSearch
                  disableColumnLabel
                  tableRow={(row, index) => (
                    <FileTableRow
                      key={index}
                      columns={columns}
                      row={row}
                      deleteFile={deleteFile}
                      isCopyToChipboardSuccess={isCopyToChipboardSuccess}
                      setActiveRowData={setActiveRowData}
                      setCopyToChipboardSuccess={setCopyToChipboardSuccess}
                      setIsOpenModal={setIsOpenModal}
                      getFiles={getSchoolFiles}
                    />
                  )}
                />
              )}
            </div>
          </Paper>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={isFileUploadSuccess}
          autoHideDuration={3000}
          onClose={handleSnackBarClose}
        >
          <SnackbarContent
            style={{
              backgroundColor: colors.primaryDarkGrey
            }}
            message="File Uploaded Successfully"
            action={
              <>
                <IconButton
                  size="small"
                  aria-label="close"
                  onClick={handleSnackBarClose}
                >
                  <CloseIcon fontSize="small" className={classes.iconWhite} />
                </IconButton>
              </>
            }
          />
        </Snackbar>
        <RightDrawer
          isOpen={isOpenDrawer}
          onClose={() => {
            onClose();
            onCloseForm();
          }}
          width={900}
          style={{ overflowX: 'hidden' }}
        >
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="primary" />
          </Backdrop>
          <div className={classes.drawerLeftContent}>
            <div className={classes.leftContentMargin}>
              <div className={classes.leftContentHeader}>
                {isEditMode ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.siderEdit}
                    onClick={() => {
                      const defaultData = draftToHtml(
                        convertToRaw(defaultNote.getCurrentContent())
                      );
                      const memberNotes = memberData.notes;
                      if (defaultData === memberNotes) {
                        handleClose();
                      } else if (
                        defaultData.replace(/(\r\n|\n|\r)/gm, '') ===
                          '<p></p>' &&
                        !memberNotes
                      ) {
                        handleClose();
                      } else {
                        onSaveChanges();
                      }
                    }}
                    style={{ width: 165 }}
                  >
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.siderEdit}
                    onClick={() => setEditMode(true)}
                    startIcon={<img src={Edit} />}
                  >
                    Edit
                  </Button>
                )}
              </div>
              <Divider
                variant="fullWidth"
                style={{ marginBottom: 20, marginTop: 22 }}
              />
              {!isEditMode && !memberData.notes && (
                <Typography className={classes.siderDetail}>
                  Click Edit to add a note...
                </Typography>
              )}
              {!isEditMode &&
                memberData.notes &&
                memberData.notes.replace(/(\r\n|\n|\r)/gm, '') ===
                  '<p></p>' && (
                  <Typography className={classes.siderDetail}>
                    Click Edit to add a note...
                  </Typography>
                )}
              <Editor
                stripPastedStyles={true}
                readOnly={!isEditMode}
                defaultEditorState={defaultNote}
                toolbarClassName={
                  !isEditMode ? classes.toolbarHidden : classes.toolbar
                }
                onEditorStateChange={onEditorChange}
                editorClassName={
                  !isEditMode ? classes.textEditor : classes.textEditorOnEdit
                }
                toolbar={{
                  options: ['inline', 'list', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough']
                  },
                  list: {
                    options: ['unordered']
                  },
                  link: {
                    defaultTargetOption: '_blank',
                    options: ['link']
                  }
                }}
              />
            </div>
          </div>
          <div className={classes.drawerRightContent}>
            <div style={{ padding: 18, zIndex: 1 }}>
              <div className={classes.header}>
                {memberData.schoolName && (
                  <Typography
                    className={classes.textwraplabel}
                    title={memberData.schoolName}
                  >
                    {memberData.schoolName}
                  </Typography>
                )}
              </div>
              <Divider style={{ marginBottom: 20 }} />
              {memberData.country && (
                <>
                  <Typography className={classes.heading}>COUNTRY</Typography>
                  <Typography className={classes.siderDetail}>
                    {memberData.country}
                  </Typography>
                </>
              )}
              {memberData.websiteUrl && (
                <>
                  <Typography className={classes.heading}>WEBSITE</Typography>
                  <Link
                    color="secondary"
                    component={Link}
                    onClick={() => window.open(memberData.websiteUrl, '_blank')}
                    className={classes.siderDetail}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    Click Here
                  </Link>
                </>
              )}
            </div>
            <div className={classes.handBookContainer}>
              <img src={HandBook} alt="" className={classes.handBookClass} />
            </div>
          </div>
        </RightDrawer>
      </Dashboard>
      <Modal open={isOpenModal} onClose={handleModalClose}>
        <div className={classes.modalBox}>
          <TopHeader />
        </div>
      </Modal>
      <ScanningUploadFileModal
        handleOnClose={handleOnClose}
        isOpen={fileUploadModal.isModalOpen}
        fileUploadState={fileUploadModal.fileUploadState}
      />
    </>
  );
};
 
export default withFirebase(FilesView);
