/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import clsx from 'clsx';
import _ from 'lodash';
import MomentUtils from '@date-io/moment';

// Layouts
import Dashboard from '../layout/Dashboard';

// Constants
import { NEW_MEMBER_COLUMNS } from 'src/constants/table';
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  MEMBER_ACCOUNT_STATUS,
  SEARCH_DEBOUNCE_TIME
} from 'src/constants/common';

// Components
import Modal from 'src/components/Modal';

// Material-UI-Components
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  CircularProgress,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Checkbox,
  Paper,
  Table,
  TablePagination,
  Toolbar,
  TableContainer,
  TableBody,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';

// Assets
import AddIcon from '@material-ui/icons/Add';
import { ReactComponent as SearchIconSVG } from 'src/assets/search-icon.svg';
import api_icon from 'src/assets/apiIcon.svg';
import {
  membersListNewStyles,
  useToolbarStyles
} from 'src/styles/membersStyle/membersListNewStyles';

// Redux-Actions
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { resetFamily } from 'src/redux/actions/familyActions';
import { setFreeSchoolList } from 'src/redux/actions/reusableStateValueActions';
import { MESSAGE_TYPES } from 'src/constants/common';

// Services
import {
  approveMultipleMembersByIds,
  deleteMultipleMembersIds,
  getMember,
  getMembers
} from 'src/services/members';
import { getFreeSchools } from 'src/services/schools';

// Utils
import { withFirebase } from 'src/utils/firebase';
import { routes, year as DefaultYear } from 'src/utils/constant';

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    selected
  } = props;

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{
            position: selected.length > 0 ? 'relative' : 'none',
            height: selected.length > 0 ? 0 : 0,
            background:
              selected.length > 0 ? 'rgba(139, 197, 23, 0.5)' : '#f4f5f7'
          }}
        >
          <Checkbox
            className={classes.checkbox}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {NEW_MEMBER_COLUMNS.map((headCell) => (
          <TableCell
            style={{
              background: selected.length > 0 ? '#fff' : '#f4f5f7',
              fontSize: '14px'
            }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label.toLocaleUpperCase()}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, selected } = props;
  const dispatch = useDispatch();
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenApproveModal, setIsOpenApproveModal] = useState(false);
  const isLoading = useSelector((state) => state.app.isLoading);
  const [deleteText, setDeleteText] = useState('');
  const [approveText, setApproveText] = useState('');

  const deleteUserHandle = async () => {
    setDeleteText('');
    dispatch(loaderStart());
    selected.forEach((el) => {
      getMember(el)
        .then(async (response) => {
          const familyData = response.data;
          const pushRecord = {
            members: []
          };
          Array.isArray(familyData.childs) &&
            familyData.childs.map((childData) => {
              let groups = _.compact([childData.group, childData.groups]);
              // eslint-disable-next-line prefer-spread
              groups = _.uniq([].concat.apply([], groups));
              groups.forEach((el) => {
                pushRecord.members.push({
                  childId: childData.id,
                  groupId: el,
                  isAdd: false
                });
              });
            });

          // if (pushRecord.members.length > 0) {
          //   const { data, error } = await makeApiRequest({
          //     url: API_CHILD_UPDATE_GROUP,
          //     method: 'POST',
          //     data: pushRecord
          //   });
          //   if (error) {
          //     throw error;
          //   }
          // }
        })
        .catch((err) => {
          console.log('Error: ', err);
        });
    });

    deleteMultipleMembersIds(selected)
      .then(() => {
        dispatch(addMessage('Family Deleted'));
        setOpenDeleteModal(false);
        dispatch(loaderStop());
        window.location.reload();
      })
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(
          addMessage(
            'Family was not deleted. Please try again',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });
  };

  const approveFamily = () => {
    dispatch(loaderStart());
    approveMultipleMembersByIds(selected)
      .then(() => {
        setIsOpenApproveModal(false);
        dispatch(loaderStop());
        dispatch(addMessage('Family Approved'));
        window.location.reload();
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <div className={classes.modalViewerDesign}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.approveButton}
            classes={{ startIcon: classes.startIcon }}
            onClick={() => setIsOpenApproveModal && setIsOpenApproveModal(true)}
          >
            Approve
          </Button>
          <Typography
            className={classes.deleteText}
            variant="subtitle1"
            component="div"
            onClick={() => setOpenDeleteModal && setOpenDeleteModal(true)}
          >
            Delete
          </Typography>

          {isOpenApproveModal && (
            <Modal
              isOpen={isOpenApproveModal}
              onClose={() => {
                setIsOpenApproveModal(false);
              }}
            >
              <div className={classes.modalBox}>
                <Typography className={classes.modalTitle}>
                  Approve Member?
                </Typography>

                <div className={classes.modalSubHeading}>
                  <span
                    className={classes.modalSubTitle}
                    style={{ fontWeight: 500, paddingBottom: 14 }}
                  >
                    Type “APPROVE MEMBER” to confirm
                  </span>
                  <FormControl
                    component="fieldset"
                    classes={{ root: classes.inputControlBoxContainer }}
                    style={{ width: '100%' }}
                  >
                    <TextField
                      label=""
                      variant="outlined"
                      className={classes.inputControlBox}
                      style={{ marginTop: 0 }}
                      value={approveText}
                      onChange={(e) => setApproveText(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className={classes.modalActions}>
                  <Button
                    onClick={approveFamily}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={approveText !== 'APPROVE MEMBER'}
                    className={classes.primaryButton}
                    startIcon={
                      isLoading && (
                        <CircularProgress color="secondary" size={25} />
                      )
                    }
                  >
                    {!isLoading && 'Approve'}
                  </Button>
                  <Button
                    onClick={() => {
                      setIsOpenApproveModal(false);
                    }}
                    variant="contained"
                    size="large"
                    className={classes.greyButton}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          )}

          {isOpenDeleteModal && (
            <Modal
              isOpen={isOpenDeleteModal}
              onClose={() => {
                setOpenDeleteModal(false);
              }}
            >
              <div className={classes.modalBox}>
                <Typography className={classes.modalTitle}>
                  Delete Member Account?
                </Typography>
                <div className={classes.modalSubHeading}>
                  <Typography className={classes.modalSubTitle}>
                    <span style={{ fontWeight: 'bold' }}>
                      Warning: This action cannot be reversed.
                    </span>
                    <br />
                    The following data will be permanently removed from the
                    database.
                  </Typography>
                </div>
                <div className={classes.modalContent}>
                  <Typography className={classes.modalSubTitle}>
                    - Member account (admin)
                    <br />
                    - Member account and login credentials (app)
                    <br />
                  </Typography>
                </div>
                <div className={classes.modalSubHeading}>
                  <span
                    className={classes.modalSubTitle}
                    style={{ fontWeight: 500, paddingBottom: 14 }}
                  >
                    Type “DELETE ACCOUNT” to confirm
                  </span>
                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <TextField
                      label=""
                      variant="outlined"
                      className={classes.inputModalControlBox}
                      style={{ marginTop: 0 }}
                      value={deleteText}
                      onChange={(e) => setDeleteText(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className={classes.modalActions}>
                  <Button
                    onClick={deleteUserHandle}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={deleteText !== 'DELETE ACCOUNT'}
                    className={classes.primaryButton}
                    startIcon={
                      isLoading && (
                        <CircularProgress color="secondary" size={25} />
                      )
                    }
                  >
                    {!isLoading && 'Delete'}
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenDeleteModal(false);
                    }}
                    variant="contained"
                    size="large"
                    className={classes.greyButton}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </div>
      ) : (
        ''
      )}
    </Toolbar>
  );
};
const MembersListNew = (props) => {
  const {
    rows,
    searchPlaceholderText,
    schoolChangeHandlerFamilyFree,
    schoolChangeHandlerFamilyPaid,
    firebase
  } = props;

  const classes = membersListNewStyles();
  const history = useHistory();

  const [school, setSchool] = useState('');
  const [year, setYear] = useState('');
  const [showClearFilters, setShowClearFilters] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('statusUpdatedAt');
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [schoolDropDown, setSchoolDropDown] = useState([]);
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableRecords, setTableRecords] = useState(0);
  const [familyCount, setFamilyCount] = useState({
    activeMemberCount: 0,
    newMemberCount: 0,
    pendingMemberCount: 0
  });

  const dispatch = useDispatch();
  const prefillFamilyData = useSelector((state) => state.family.currentFamily);
  const { freeSchoolList } = useSelector((state) => state.reusableStoreData);

  const formatTableData = useCallback(
    (unFormattedTableData) => {
      return unFormattedTableData?.map((data) => {
        return {
          ...data,
          studentReferences: data?.studentReferences?.map((student) => {
            const school = freeSchoolList.find((s) => s.id === student.school);
            return {
              ...student,
              school: school?.schoolName
            };
          })
        };
      });
    },
    [freeSchoolList]
  );

  useEffect(() => {
    dispatch(resetFamily());
  }, []);

  useEffect(() => {
    if (freeSchoolList.length > 0) {
      dispatch(loaderStart());
      getMembers({
        searchText: debouncedSearchText,
        pageNo: Number(page) + 1,
        pageSize: rowsPerPage,
        schoolId: school,
        year: year,
        accountStatus: MEMBER_ACCOUNT_STATUS.NEW
      })
        .then((result) => {
          setTableData(formatTableData(result?.data));
          setFamilyCount(result?.accountStatusCount);
          setTableRecords(result?.totalRecords);
          dispatch(loaderStop());
        })
        .catch((error) => {
          console.log(error);
          dispatch(loaderStop());
        });
    }
  }, [debouncedSearchText, page, rowsPerPage, school, year, freeSchoolList]);

  const getDebouncedSearchValue = useCallback(
    _.debounce((debouncedValue) => {
      setDebouncedSearchText(debouncedValue);
    }, SEARCH_DEBOUNCE_TIME),
    []
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleGlobalCheckBox = (event) => {
    if (event.target.checked) {
      const ids = tableData?.map((data) => data.id);
      setSelectedRows(ids);
      return;
    }
    setSelectedRows([]);
  };

  const handleRowCheckBox = (event, name) => {
    const selectedIndex = selectedRows.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows?.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };
  // Page change next/prev function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // Page size change function
  const handleChangeRowsPerPage = (event) => {
    const newRowsCount = +event.target.value;
    setRowsPerPage(newRowsCount);
    setPage(DEFAULT_PAGE);
  };

  const isSelected = (name) => selectedRows.indexOf(name) !== -1;

  useEffect(() => {
    dispatch(loaderStart());
    getFreeSchools()
      .then((result) => {
        setSchoolDropDown(result);
        dispatch(setFreeSchoolList(result));
        dispatch(loaderStop());
      })
      .catch((error) => {
        dispatch(addMessage(error?.message, MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  }, [dispatch]);

  // School change function
  const schoolChangeHandler = (e) => {
    const schoolID = e.target.value;
    setPage(DEFAULT_PAGE);
    setSchool(schoolID);
  };

  // Clear all filters
  const filterClearHandler = () => {
    setPage(DEFAULT_PAGE);
    setYear('');
    setSchool('');
    setShowClearFilters(false);
  };

  // Year change function
  const yearChangeHandler = (e) => {
    const yearValue = e.target.value;
    setPage(DEFAULT_PAGE);
    setYear(yearValue);
  };

  const handleRowSelect = (row) => {
    history.push(routes.memberAccount + '/' + 'new' + '/' + row['id']);
  };

  // Search text change function
  const searchChangeHandler = useCallback(
    (e) => {
      setPage(DEFAULT_PAGE);
      const { value } = e.target;
      getDebouncedSearchValue(value.toLowerCase().trim());
    },
    []
  );

  // Filter school name
  const filterSchoolNameList = (row) => {
    const childSchools = row.studentReferences.map((child) => child.school);
    const schools = _.compact(_.uniq(childSchools));
    return (
      <>
        {schools.map((schoolName, schoolIndex) => {
          return <div key={schoolIndex}>{schoolName}</div>;
        })}
      </>
    );
  };
  // Filter years data to display
  const filterYearsDisplayData = (value) => {
    const result = value.reduce((acc, student) => {
      const existingSchool = acc.find((item) => item.school === student.school);

      if (existingSchool) {
        // School already exists, update the year array
        if (!existingSchool.year.includes(student.year)) {
          existingSchool.year.push(student.year);
        }
      } else {
        // School doesn't exist, create a new entry
        acc.push({ school: student.school, year: [student.year] });
      }

      return acc;
    }, []);

    const childsData = result.map((p, index) => {
      return (
        <>
          <span key={index}>
            {p.year.map((data, dataIdx) => {
              return (
                <>
                  {data === '-1' ? `PK` : data === `0` ? `K` : `Y${data}`}
                  {p?.year.length !== dataIdx + 1 ? `, ` : ''}
                </>
              );
            })}
          </span>
          <br />
        </>
      );
    });
    return childsData;
  };

  return (
    <Dashboard>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20 }}
        >
          Members
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.actionButton}
            startIcon={<AddIcon />}
            classes={{ startIcon: classes.startIcon }}
            onClick={() => history.push(routes.memberCreate)}
          >
            New Member
          </Button>
        </Typography>

        <div className={classes.root}>
          <Paper className={classes.paper}>
            <div className={classes.header}>
              <div
                colSpan={NEW_MEMBER_COLUMNS?.length}
                className={classes.inputTableCell}
              >
                <TextField
                  size="small"
                  name="searchClient"
                  variant="outlined"
                  placeholder="Search by surname or email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIconSVG />
                      </InputAdornment>
                    )
                  }}
                  onChange={searchChangeHandler}
                  classes={{
                    root: classes.textFieldRoot
                  }}
                />

                <FormControl
                  variant="outlined"
                  size="small"
                  classes={{ root: classes.inputControlBoxContainer }}
                >
                  <InputLabel
                    className={classes.placeholderText}
                    id="country-select-outlined-label"
                  >
                    All Schools
                  </InputLabel>
                  <Select
                    labelId="schoolName-select-outlined-label"
                    className={classes.inputControlBox}
                    style={{
                      height: 45,
                      width: 273,
                      marginRight: 12,
                      marginTop: 0
                    }}
                    name="schoolData"
                    onChange={
                      schoolChangeHandler ||
                      schoolChangeHandlerFamilyFree ||
                      schoolChangeHandlerFamilyPaid
                    }
                    id="school-name-select-outlined"
                    label="All Schools"
                    value={school}
                  >
                    <MenuItem value="">
                      <em>All Schools</em>
                    </MenuItem>

                    {schoolDropDown &&
                      schoolDropDown.map(function (object) {
                        return (
                          <MenuItem key={object.id} value={object.id}>
                            {object.schoolName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

                <FormControl
                  variant="outlined"
                  size="small"
                  classes={{ root: classes.inputControlBoxContainer }}
                  style={{ width: 185 }}
                >
                  <InputLabel
                    id="year-select-outlined-label"
                    className={classes.placeholderText}
                  >
                    Year
                  </InputLabel>
                  <Select
                    labelId="year-select-outlined-label"
                    className={classes.inputControlBox}
                    name="years"
                    onChange={yearChangeHandler}
                    style={{
                      height: 45,
                      width: 173,
                      marginRight: 250,
                      marginTop: 0
                    }}
                    id="year-select-outlined"
                    label="Year"
                    value={year}
                  >
                    <MenuItem value="">
                      <em>All Years</em>
                    </MenuItem>
                    {DefaultYear &&
                      DefaultYear.map((object) => {
                        return (
                          <MenuItem key={object.value} value={object.value}>
                            {object.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

                {showClearFilters &&
                window.location.pathname.includes('member') ? (
                  <div
                    className={classes.clearFiltersContainer}
                    onClick={filterClearHandler}
                  >
                    <span>clear</span>
                  </div>
                ) : null}

                <span className={classes.statusFilterContainer}>
                  <Button
                    className={classes.freeMembersButton}
                    onClick={() => history.push(routes.membersActive)}
                    style={{
                      border:
                        window.location.pathname === routes.membersActive
                          ? '1px solid #8bc517'
                          : '1px solid #bfc5d1',
                      backgroundColor:
                        window.location.pathname === routes.membersActive
                          ? 'rgba(139, 197, 23, 0.2)'
                          : '#fff'
                    }}
                  >
                    <div className={classes.filterCard}>
                      <span>Active</span>
                      <span
                        className={
                          window.location.pathname === routes.membersActive
                            ? classes.countmemberBox
                            : classes.memberboxActiveDisable
                        }
                      >
                        {familyCount?.activeMemberCount}
                      </span>
                    </div>
                  </Button>

                  <Button
                    className={classes.freeMembersButton}
                    onClick={() => history.push(routes.membersNew)}
                    style={{
                      border:
                        window.location.pathname === routes.membersNew
                          ? '1px solid #0084ff'
                          : '1px solid #bfc5d1',
                      backgroundColor:
                        window.location.pathname === routes.membersNew
                          ? 'rgba(0, 132, 255, 0.2)'
                          : '#fff'
                    }}
                  >
                    <div className={classes.filterCard}>
                      <span>New</span>
                      <span className={classes.countmemberBoxNew}>
                        {familyCount?.newMemberCount}
                      </span>
                    </div>
                  </Button>

                  <Button
                    className={classes.freeMembersButton}
                    onClick={() => history.push(routes.membersPending)}
                    style={{
                      border:
                        window.location.pathname === routes.membersPending
                          ? '1px solid #ff9b00'
                          : '1px solid #bfc5d1',
                      backgroundColor:
                        window.location.pathname === routes.membersPending
                          ? 'rgba(255, 155, 0, 0.2)'
                          : '#fff'
                    }}
                  >
                    <div className={classes.filterCard}>
                      <span>Pending</span>
                      <span className={classes.countmemberBoxPending}>
                        {familyCount?.pendingMemberCount}
                      </span>
                    </div>
                  </Button>
                </span>
              </div>
            </div>

            <div style={{ position: 'relative' }}>
              <div
                style={{
                  position: selectedRows.length > 0 ? 'absolute' : 'absolute',
                  zIndex: selectedRows.length > 0 ? 3 : 0,
                  left: selectedRows.length > 0 ? '48px' : 0,
                  width: selectedRows.length > 0 ? 'calc(100% - 48px)' : 0,
                  top: 0,
                  backgroundColor: selectedRows.length > 0 ? 'white' : '',
                  height: selectedRows.length > 0 ? '60px' : 0,
                  overflow: selectedRows.length > 0 ? 'hidden' : '',
                  display: selectedRows.length > 0 ? '' : 'none'
                }}
              >
                <EnhancedTableToolbar
                  numSelected={selectedRows.length}
                  rows={rows}
                  classes={classes}
                  selected={selectedRows}
                  firebase={firebase}
                />
              </div>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  classes={{ stickyHeader: classes.tableStickyHeader }}
                >
                  <EnhancedTableHead
                    rows={tableData}
                    classes={classes}
                    numSelected={selectedRows?.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleGlobalCheckBox}
                    onRequestSort={handleRequestSort}
                    rowCount={tableData?.length}
                    searchPlaceholderText={searchPlaceholderText}
                    schoolDropDown={schoolDropDown}
                    prefillFamilyData={prefillFamilyData}
                    selected={selectedRows}
                    searchChangeHandler={searchChangeHandler}
                    schoolChangeHandler={schoolChangeHandler}
                    schoolChangeHandlerFamilyFree={
                      schoolChangeHandlerFamilyFree
                    }
                    schoolChangeHandlerFamilyPaid={
                      schoolChangeHandlerFamilyPaid
                    }
                    yearChangeHandlerFree={yearChangeHandler}
                    firebase={firebase}
                  />
                  <TableBody>
                    {tableData?.length > 0 ? (
                      tableData?.map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            style={{ height: '4.5rem' }}
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            className={clsx({
                              [classes.selectedRow]: isItemSelected
                            })}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) =>
                                  handleRowCheckBox(event, row.id)
                                }
                                className={classes.checkbox2}
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '18%' }}
                            >
                              <span style={{ marginLeft: '15px' }}>
                                {row.lastName}
                              </span>
                            </TableCell>
                            <TableCell
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '19%' }}
                            >
                              <div>{row.firstName}</div>
                            </TableCell>
                            <TableCell
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '20%' }}
                            >
                              {filterSchoolNameList(row)}
                            </TableCell>
                            <TableCell
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '23.8%' }}
                            >
                              {filterYearsDisplayData(row?.studentReferences)}
                            </TableCell>

                            <TableCell
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '14%' }}
                            >
                              <div>
                                {row?.currentStatusDate
                                  ? moment(row.currentStatusDate)
                                      .tz('Australia/Sydney')
                                      .format('DD MMM YY')
                                  : null}
                              </div>
                            </TableCell>
                            <TableCell>
                              {row?.createdByApiSync ? (
                                <img
                                  src={api_icon}
                                  style={{
                                    paddingRight: '1rem',
                                    float: 'right'
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow className="MuiTableRow-root makeStyles-tableRow-1 MuiTableRow-hover">
                        <TableCell
                          className="MuiTableCell-root MuiTableCell-body"
                          colSpan={NEW_MEMBER_COLUMNS?.length + 1}
                        >
                          No Records Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={tableRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
            </div>
          </Paper>
        </div>
      </MuiPickersUtilsProvider>
    </Dashboard>
  );
};

export default withFirebase(MembersListNew);
