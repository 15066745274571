import { schoolSchema } from 'src/utils/schemas';

// Initial State
const initialState = {
  currentSchool: schoolSchema,
  preserveValues: schoolSchema,
  apiSchool: {},
  isSchoolDataUpdated: false,
};

// Reducers (Modifies The State And Returns A New State)
const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_SCHOOL_DATA_UPDATED': {
      return {
        ...state,
        isSchoolDataUpdated: action?.payload,
      };
    }
    case 'REMOVE_UPDATED_DATA': {
      return {
        ...state, 
        isSchoolDataUpdated: false,
        currentSchool: state?.preserveValues,
        preserveValues: state?.preserveValues,
        apiSchool: state?.preserveValues,
      }
    }
    // School Set
    case 'SET_SCHOOL_PRESERVE': {
      const newFreeCalendars = [...new Set(action.payload?.freeCalendars)];
      return {
        ...state,
        preserveValues: {
          ...state.preserveValues,
          ...action.payload,
          freeCalendars: newFreeCalendars?.length > 0 ? newFreeCalendars : state.preserveValues?.freeCalendars,
        }
      };
    }
    case 'SET_SCHOOL': {
      const newFreeCalendars = [...new Set(action.school?.freeCalendars)];
      return {
        ...state,
        currentSchool: {
          ...state.currentSchool,
          ...action.school,
          freeCalendars: newFreeCalendars?.length > 0 ? newFreeCalendars : state.currentSchool?.freeCalendars,
          createdByApiSync: !!action?.school?.createdByApiSync
        },
        apiSchool: {
          ...state.currentSchool,
          ...action.school,
          freeCalendars: newFreeCalendars?.length > 0 ? newFreeCalendars : state.currentSchool?.freeCalendars,
          createdByApiSync: !!action?.school?.createdByApiSync
        }
      };
    }
    case 'RESET_SCHOOL': {
      return {
        ...state,
        isSchoolDataUpdated: false,
        currentSchool: schoolSchema,
        preserveValues: schoolSchema,
        apiSchool: {}
      };
    }
    case 'SCHOOL_CREATED': {
      return {
        ...state,
        apiSchool: {
          ...state.currentSchool,
          ...state.apiSchool,
          schoolIDGeneratedAPI: action.APISchoolID
        }
      };
    }
    case 'SCHOOL_DATA': {
      return {
        ...state,
        apiSchool: {
          ...state.currentSchool,
          ...state.apiSchool,
          schoolData: { ...action.APISchoolData },
          createdByApiSync: !!action?.APISchoolData?.createdByApiSync
        }
      };
    }
    // Default
    default: {
      return state;
    }
  }
};

// Exports
export default schoolReducer;
