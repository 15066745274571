import { createMuiTheme } from '@material-ui/core/styles';

const fontSize = 16; // px
const htmlFontSize = 16; // 16px is the default font-size used by browsers.
const coef = fontSize / 14;

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#a2d045',
      main: '#8bc517',
      dark: '#618910',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#6e7b86',
      main: '#4A5A68',
      dark: '#333e48',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#474747',
      secondary: '#4c5b68'
    },
    primaryDarkGrey: {
      light: '#6f7b86',
      main: '#4c5b68',
      dark: '#353f48',
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    pxToRem: (size) => `${(size / htmlFontSize) * coef}rem`,
    h2: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 'bold',
      letterSpacing: '-0.22px'
    },
    h6: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'normal',
      letterSpacing: '0.1px'
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'normal',
      letterSpacing: '0.1px'
    },
    body1: { fontSize: 14, lineHeight: 1.57, fontWeight: 'normal' },
    gutterBottom: { marginBottom: 16 }
  }
});
