import axios from './axios';

// Constants
import { API_ROUTES } from 'src/constants/api-routes';

// Utils
import { getRouteByParams, getRouteByQueryParams } from 'src/utils/route';

export const getGroups = async (payload) => {
  try {
    const URL = getRouteByQueryParams(API_ROUTES.GET_GROUP_BY_FILTER, {
      pageNo: payload?.pageNo,
      pageSize: payload?.pageSize,
      schoolId: payload?.schoolId,
      searchText: payload?.searchText,
      categoryId: payload?.categoryId
    });
    const response = await axios.get(URL);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getGroup = async (groupId) => {
  try {
    const URL = getRouteByParams(API_ROUTES.GET_GROUP_BY_ID, { id: groupId });
    const response = await axios.get(URL);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteGroup = async ({ groupId, groupMembers }) => {
  try {
    const URL = getRouteByParams(API_ROUTES.DELETE_GROUP, { id: groupId });
    const response = await axios.post(URL, { groupMembers });
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getMembersBySchoolId = async (schoolId) => {
  try {
    const response = await axios.post(API_ROUTES.GET_MEMBERS, { schoolId });
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createGroup = async (payload) => {
  try {
    const response = await axios.post(API_ROUTES.CREATE_GROUP, payload);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateGroup = async ({ groupId, payload }) => {
  try {
    const URL = getRouteByParams(API_ROUTES.UPDATE_GROUP, { id: groupId });
    const response = await axios.post(URL, payload);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const isGroupExists = async (payload) => {
  try {
    const response = await axios.post(API_ROUTES.GROUP_EXIST, payload);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateGroupEntries = async (payload) => {
  try {
    const response = await axios.post(
      API_ROUTES.UPDATE_MULTIPLE_GROUP_AND_ENTRIES,
      payload
    );
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getGroupBySchoolId = async (schoolId, isAllGroups = true) => {
  try {
    const URL = getRouteByParams(API_ROUTES.GET_GROUP_BY_SCHOOL_ID, {
      schoolId
    });
    const finalUrl = getRouteByQueryParams(URL, { isAllGroups });
    const response = await axios.get(finalUrl);
    return response.status === 200 && response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// ----------🔍SEARCH_BY----------
// PHASE2/CHANGES/GROUP
// useSelector((state) => state.KEY_NAME

// ✔️ 🔍 ⌛ ☠️ ✋ 👉 ❗ 📌 ❌

// ----------📌FILES_WILL_BE_CHANGE----------

// Task Entry: ✔️ 🔍 ⌛ ☠️ ✋ 👉 ❗ 📌 ❌
// GROUP RELATED CHANGES

// ❌ NOT_USED [deleteChildRelatedData, updateGroupEntriesByGroupId]

// PATH                                              : FUNCTIONS
// src/views/families/CreateCalenderEntry.js         : [❌deleteFreeSchoolFromGroup, ✔️getGroupBySchoolId: groups dropdown(old logic)]
// src/views/families/components/GroupEditSection.js : [✔️getGroupBySchoolId: to edit group - if there are paid groups]
// src/views/families/Account.js                     : [✔️getGroup: unnecessary, ❌updateGroupsByFamilyId: old code to update group entry, ❌updateGroupEntriesByFamilyId: old code to update group entry]
// src/views/families/AddFamilyMember.js             : [✔️getGroup: free family code(should be removed)]
// src/views/families/CreateFamily.js                : [✔️getGroup: unnecessary, ❌updateGroupsByFamilyId: old code to update group entry, ❌updateGroupEntriesByFamilyId: old code to update group entry]
// src/views/families/Users.js                       : [✔️getGroup: free family code(should be removed), ❌updateGroupsByFamilyId: old code to update group entry, ❌updateGroupEntriesByFamilyId: old code to update group entry]
// src/views/families/CreateGroupCalenderEntry.js    : [✔️getGroupsByCategory: group calendar entry]

// src/views/members/AddChildren.js                  : [✔️getGroup: groups dropdown] 👉 replaced with this api call ✔️getGroupBySchoolId
// src/views/members/CreateMember.js                 : [✔️getGroup: unnecessary]
// src/views/members/MembersAccount.js               : [✔️getGroup: unnecessary, ❌updateGroupsByFamilyId: old code to update group entry, ❌updateGroupEntriesByFamilyId: old code to update group entry]
// src/views/members/MembersUsersView.js             : [✔️getGroup: groups list for child add/edit] 👉 replaced with this api call ✔️getGroupBySchoolId
// src/views/members/components/GroupEditSection.js  : [✔️getGroupBySchoolId: to edit group]

// src/views/schools/EditSchoolCalendar.js           : [✔️getGroup: group entry]
// src/views/schools/students/index.js               : [✔️getGroup: to show list of groups]
// src/views/schools/groups/PaidEditGroup.js         : [✔️updateGroup: update paid group for old data]
// src/views/schools/groups/SchoolGroup.js           : [✔️deleteGroup: delete paid group for old data]
// src/views/schools/CategoryGroupCalendarEntry.js   : [✔️getGroupBySchoolId: groups dropdown, ✔️getGroupsByCategory: group calendar entry]
// src/views/schools/CreateSchoolCalenderEntry.js    : [✔️getGroupBySchoolId: unnecessary]

// src/views/groups/CreateGroup.js                   : [✔️addGroup: add group, ✔️updateGroup: update group]
