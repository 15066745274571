import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function RightArrowIcon({ color, ...props }) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <path
            id="prefix__a"
            d="M9 16.59L13.327 12 9 7.41 10.332 6 16 12 10.332 18z"
          />
        </mask>
        <g fill={color || '#474747'} mask="url(#prefix__b)">
          <path d="M0 0H24V24H0z" />
        </g>
      </g>
    </SvgIcon>
  );
}
export default RightArrowIcon;
