import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Dashboard from 'src/views/layout/Dashboard';
import StepperComponent from 'src/components/StepperComponent';
import Profile from './Profile';
import Calendar from './Calendar';
import INFO from 'src/assets/info.svg';
import Pocket from 'src/assets/pocket.svg';
import TranslationIcon from 'src/assets/translate.svg';
import { SchoolsIcon, RightArrow } from 'src/assets/Icons';
import { routes } from 'src/utils/constant';
import {
  setSchool,
  resetSchool,
  setSchoolPreserveValues,
  setSchoolDataUpdated,
  removeUpdatedSchoolData
} from 'src/redux/actions/schoolActions';
import {
  loaderStop,
  loaderStart,
  addMessage
} from 'src/redux/actions/appActions';
import { withFirebase } from 'src/utils/firebase';
import { useHistory } from 'react-router-dom';
import CustomSuccessComponent from 'src/components/schoolAutomationComp/CustomSuccessComponent';
import { addSchool } from 'src/services/schools';
import { createSchoolStyles } from 'src/styles/schoolStyle/createSchoolStyles';
import Translation from './Translation';
import _ from 'lodash';
import { MESSAGE_TYPES } from 'src/constants/common';

function ColorLibStepIcon(props) {
  const classes = createSchoolStyles();
  const { active, translationPackEnabled } = props;
  const icons = {
    1: (
      <div
        className={classes.iconStyle}
        style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
      >
        <img src={INFO} alt="Search" className={classes.image} />
      </div>
    ),
    2: (
      <div
        className={classes.iconStyle}
        style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
      >
        <img src={Pocket} alt="Search" className={classes.image} />
      </div>
    ),
    3: (
      <div
        className={translationPackEnabled ? classes.translationIconStyle : ''}
      >
        <div
          className={classes.iconStyle}
          style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
        >
          <img src={TranslationIcon} alt="Search" className={classes.image} />
        </div>
      </div>
    )
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const CreateSchool = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [translationPackEnabled, setTranslationPackEnabled] = useState(false);
  const [createId, setCreateId] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = createSchoolStyles();
  const [isApiSyncForm, setIsApiSyncForm] = useState(true);
  const [isValidationSuccessFull, setIsValidationSuccessFull] = useState(false);
  const [isSchoolCreated, setIsSchoolCreated] = useState(false);
  const [apiSyncSchoolData, setApiSyncSchoolData] = useState(null);
  const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);
  const reduxSchoolData = useSelector((state) => state.school.currentSchool);
  const isSchoolDataUpdated = useSelector((state) => state.school.isSchoolDataUpdated);
  const preserveValuesSchoolData = useSelector(
    (state) => state.school.preserveValues
  );

  useEffect(() => {
    dispatch(resetSchool());
  }, []);

  useEffect(() => {
    setTranslationPackEnabled(reduxSchoolData?.paidTranslationEnabled);
  }, [reduxSchoolData]);

  function getSteps() {
    if (isApiSyncForm) {
      return ['Profile'];
    } else {
      return ['Profile', 'Years', 'Translation'];
    }
  }
  function getStepContent({step, getFormData, getTranslationStatus, checkSchoolDataUpdated, isCancelButtonClicked, handleCancelButtonVisibility}) {
    switch (step) {
      case 0:
        return (
          <Profile
            setIsApiSyncForm={setIsApiSyncForm}
            submitForm={getFormData}
            isEdit={false}
            setIsValidationSuccessFull={setIsValidationSuccessFull}
            isValidationSuccessFull={isValidationSuccessFull}
            setIsSchoolCreated={setIsSchoolCreated}
            setApiSyncSchoolData={setApiSyncSchoolData}
            isApiSyncForm={isApiSyncForm}
            checkSchoolDataUpdated={checkSchoolDataUpdated}
            isCancelButtonClicked={isCancelButtonClicked}
            handleCancelButtonVisibility={handleCancelButtonVisibility}
          />
        );
      case 1:
        return (
          <Calendar 
            submitForm={getFormData} 
            isEdit={false}
            checkSchoolDataUpdated={checkSchoolDataUpdated}
            isCancelButtonClicked={isCancelButtonClicked}
            handleCancelButtonVisibility={handleCancelButtonVisibility}    
            />
        );
      case 2:
        return (
          <Translation
            isEdit={false}
            onSave={getFormData}
            getTranslationStatus={getTranslationStatus}
            checkSchoolDataUpdated={checkSchoolDataUpdated}
            isCancelButtonClicked={isCancelButtonClicked}
            handleCancelButtonVisibility={handleCancelButtonVisibility}
          />
        );
      default:
        return 'Unknown step';
    }
  }

  const getFormData = async (formData, buttonText) => {
    const schoolData = { ...reduxSchoolData, ...formData };
    const compareData1 = JSON.parse(JSON.stringify(preserveValuesSchoolData));
    const compareData2 = JSON.parse(JSON.stringify(schoolData));
    const isSchoolDataUpdated = !_.isEqual(compareData2, compareData1);

    if (activeStep < 1 && buttonText === 'Next') {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      dispatch(setSchool({ ...schoolData }));
      dispatch(setSchoolDataUpdated(isSchoolDataUpdated));
    } else if (activeStep === 1 && buttonText === 'Back') {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      dispatch(setSchool({ ...schoolData }));
      dispatch(setSchoolDataUpdated(isSchoolDataUpdated));
    } else if (activeStep === 1 && buttonText === 'Next') {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      dispatch(setSchool({ ...schoolData }));
      dispatch(setSchoolDataUpdated(isSchoolDataUpdated));
    } else if (activeStep === 2 && buttonText === 'Back') {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      dispatch(setSchool({ ...schoolData }));
      dispatch(setSchoolDataUpdated(isSchoolDataUpdated));
    } else if (buttonText === 'Save') {
      const newSchoolReduxData = {
        ...schoolData,
        createdDate: moment.utc().format(),
      };
      dispatch(setSchool(newSchoolReduxData));
      dispatch(setSchoolPreserveValues(newSchoolReduxData));
      dispatch(setSchoolDataUpdated(false));
      dispatch(loaderStart());

      // PHASE2/CHANGES/SCHOOL

      const payload = {
        schoolName: schoolData?.schoolName,
        country: schoolData?.country,
        timezone: schoolData?.timezone,
        state: schoolData?.state,
        notificationsTiming: {
          year: schoolData?.notificationsTiming?.year, // Example, pass as you're sending from FE side till now
          class: schoolData?.notificationsTiming?.class,
          coCurricular: schoolData?.notificationsTiming?.coCurricular,
          subject: schoolData?.notificationsTiming?.subject,
          staff: schoolData?.notificationsTiming?.staff
        },
        freeCalendars: schoolData?.freeCalendars ?? [],
        paidTranslationEnabled: schoolData?.paidTranslationEnabled
      };

      try {
        const result = await addSchool(payload);
        setCreateId(result.id);
        dispatch(loaderStop());
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error(error);
        dispatch(
          addMessage(
            'School was not created. Please try again',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      }
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleCancel = () => {
    setIsCancelButtonClicked(true);
    dispatch(removeUpdatedSchoolData())
    dispatch(resetSchool());
    setActiveStep(0);
  };

  
  const handleCancelButtonVisibility = () => {
    setIsCancelButtonClicked(false)
  }


  const checkSchoolDataUpdated = (formValues) => {
    const schoolData = {
      ...reduxSchoolData,
      ...formValues
    };
    const compareData1 = JSON.parse(JSON.stringify(preserveValuesSchoolData));
    const compareData2 = JSON.parse(JSON.stringify(schoolData));
    const isSchoolDataUpdated = !_.isEqual(compareData1, compareData2);
    dispatch(setSchoolDataUpdated(isSchoolDataUpdated));
  }

  const handleReset = () => {
    dispatch(resetSchool());
    setActiveStep(0);
  };

  const getTranslationStatus = (value) => {
    setTranslationPackEnabled(value);
  };

  if (isSchoolCreated) {
    return (
      <Dashboard>
        <CustomSuccessComponent
          apiSyncSchoolData={apiSyncSchoolData}
          title={'School Successfully Created'}
          buttonLabel={'View School'}
          listUrl={
            reduxSchoolData.freeCalendars.length
              ? routes.schoolCalendar
              : routes.schoolAccount
          }
        />
      </Dashboard>
    );
  }

  return (
    <Dashboard>
      {activeStep < 3 && (
        <>
          <div>
            <Typography
              variant="body1"
              className={classes.bredthcrumbTitle}
              onClick={() => history && history.push(routes.schools)}
            >
              Schools
            </Typography>
            <RightArrow
              viewBox="0 0 24 24"
              height={24}
              width={24}
              className={classes.iconStyleBredthcrumb}
            />
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.familySurnameStyle}
          >
            Add a New School
          </Typography>
        </>
      )}

      <div
        className={clsx({
          [classes.rootContainer]: activeStep < 3
        })}
      >
        <StepperComponent
          steps={getSteps()}
          getStepContent={(step) =>
            getStepContent({step, getFormData, getTranslationStatus, checkSchoolDataUpdated, isCancelButtonClicked, handleCancelButtonVisibility})
          }
          stepClick={(step) => setActiveStep(step)}
          title={'School'}
          colorlibStepIcon={(props) => {
            return (
              <ColorLibStepIcon
                translationPackEnabled={translationPackEnabled}
                {...props}
              />
            );
          }}
          successTitle={'New School Created!'}
          subTitle={'View School'}
          iconImage={<SchoolsIcon />}
          buttonLabel={'Add Another School'}
          listUrl={
            reduxSchoolData.freeCalendars.length
              ? routes.schoolCalendar + '/' + createId
              : routes.schoolAccount + '/' + createId
          }
          createUrl={routes.schoolCreate}
          activeStep={activeStep}
          handleNext={handleNext}
          handleReset={handleReset}
          handleCancel={handleCancel}
          isCancelButton={isSchoolDataUpdated}
        />
      </div>
    </Dashboard>
  );
};
export default withFirebase(CreateSchool);
