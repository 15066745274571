/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';

// Layouts
import Dashboard from '../layout/Dashboard';

// Material-UI Components
import { Typography, TableRow, TableCell, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Assets
import api_icon from 'src/assets/apiIcon.svg';
import AddIcon from '@material-ui/icons/Add';
import { membersListActiveStyles } from 'src/styles/membersStyle/membersListActiveStyles';

// Components
import TableList from 'src/components/TableList';

// Constants
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  MEMBER_ACCOUNT_STATUS,
  SEARCH_DEBOUNCE_TIME
} from 'src/constants/common';
import {
  ACTIVE_MEMBER_COLUMN,
  ACTIVE_MEMBER_COLUMNS
} from 'src/constants/table';

// Redux-Actions
import { loaderStart, loaderStop } from 'src/redux/actions/appActions';
import { setFamily, resetFamily } from 'src/redux/actions/familyActions';

// Services
import { getMembers } from 'src/services/members';

// Utils
import { routes } from 'src/utils/constant';
import { withFirebase } from 'src/utils/firebase';

function CellItem({ row, column }) {
  const classes = membersListActiveStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const rowValue = row[column.id];

  if (column.id === ACTIVE_MEMBER_COLUMN.LAST_NAME.KEY) {
    return (
      <span
        className={classes.surname}
        onClick={() => {
          dispatch(setFamily(row));
          history.push({
            pathname: routes.memberCalendar + '/' + row['id'],
            openRightDrawer: true
          });
        }}
      >
        {rowValue}
      </span>
    );
  }

  if (column.id === ACTIVE_MEMBER_COLUMN.FIRST_NAME.KEY) {
    return (
      <span
        className={classes.surname}
        onClick={() => {
          dispatch(setFamily(row));
          history.push({
            pathname: routes.memberCalendar + '/' + row['id'],
            openRightDrawer: true
          });
        }}
      >
        {rowValue}
      </span>
    );
  }

  if (column.id === ACTIVE_MEMBER_COLUMN.YEAR.KEY) {
    const result = rowValue?.reduce((acc, student) => {
      const existingSchool = acc.find((item) => item.school === student.school);

      if (existingSchool) {
        // School already exists, update the year array
        if (!existingSchool.year.includes(student.year)) {
          existingSchool.year.push(student.year);
        }
      } else {
        // School doesn't exist, create a new entry
        acc.push({ school: student.school, year: [student.year] });
      }

      return acc;
    }, []);

    const yearData = result?.map((p, index) => {
      return (
        <>
          <span key={index}>
            {p.year.map((data, dataIdx) => {
              return (
                <>
                  {data === '-1' ? `PK` : data === `0` ? `K` : `Y${data}`}
                  {p?.year.length !== dataIdx + 1 ? `, ` : ''}
                </>
              );
            })}
          </span>
          <br />
        </>
      );
    });
    return yearData;
  }

  if (column.id === ACTIVE_MEMBER_COLUMN.SCHOOL_NAME.KEY) {
    const childSchools = row?.studentReferences?.map((child) => child.school);
    const schools = _.compact(_.uniq(childSchools));
    return (
      <div>
        {schools.map((schoolName, schoolIndex) => (
          <div key={schoolIndex}>{schoolName}</div>
        ))}
      </div>
    );
  }

  if (column.id === ACTIVE_MEMBER_COLUMN.JOINED.KEY) {
    return (
      <span style={{ marginRight: 8, marginBottom: 5 }}>
        {row?.currentStatusDate
          ? moment(row.currentStatusDate)
              .tz('Australia/Sydney')
              .format('DD MMM YY')
          : null}
      </span>
    );
  }

  if (
    column.id === ACTIVE_MEMBER_COLUMN.API_SYNC.KEY &&
    row?.createdByApiSync
  ) {
    return (
      <img src={api_icon} style={{ paddingRight: '1rem', float: 'right' }} />
    );
  }
}

function TableRowItem({ row, columns }) {
  const classes = membersListActiveStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      className={classes.tableRow}
      onClick={(e) => {
        if (e.target.tagName.toLowerCase() === 'td') {
          dispatch(setFamily(row));
          history.push({
            pathname: routes.memberCalendar + '/' + row['id'],
            openRightDrawer: false
          });
        }
      }}
    >
      {columns.map((column) => {
        return (
          <TableCell
            key={column.id}
            align={column.align}
            className={classes.dataTableCell}
            style={{ width: column.width }}
          >
            <CellItem row={row} column={column} />
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function MembersListActive() {
  const classes = membersListActiveStyles();

  const history = useHistory();

  const [school, setSchool] = useState('');
  const [year, setYear] = useState('');
  const [showClearFilters, setShowClearFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [rowsCountPerPage, setRowsCountPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableRecords, setTableRecords] = useState(0);
  const [familyCount, setFamilyCount] = useState({
    activeMemberCount: 0,
    newMemberCount: 0,
    pendingMemberCount: 0
  });

  const dispatch = useDispatch();
  const { freeSchoolList } = useSelector((state) => state.reusableStoreData);

  useEffect(() => {
    dispatch(resetFamily());
  }, []);

  const formatTableData = useCallback(
    (tableData) => {
      return tableData?.map((data) => {
        return {
          ...data,
          studentReferences: data?.studentReferences?.map((student) => {
            const school = freeSchoolList.find((s) => s.id === student.school);
            return {
              ...student,
              school: school?.schoolName
            };
          })
        };
      });
    },
    [freeSchoolList]
  );

  useEffect(() => {
    if (freeSchoolList.length > 0) {
      dispatch(loaderStart());
      getMembers({
        searchText: debouncedSearchText,
        pageNo: Number(currentPage) + 1,
        pageSize: rowsCountPerPage,
        schoolId: school,
        year: year,
        accountStatus: MEMBER_ACCOUNT_STATUS.ACTIVE
      })
        .then((result) => {
          setTableData(formatTableData(result?.data));
          setFamilyCount(result?.accountStatusCount);
          setTableRecords(result?.totalRecords);
          dispatch(loaderStop());
        })
        .catch((error) => {
          console.log(error);
          dispatch(loaderStop());
        });
    }
  }, [
    debouncedSearchText,
    currentPage,
    rowsCountPerPage,
    school,
    year,
    freeSchoolList
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDebouncedSearchValue = useCallback(
    _.debounce((debouncedValue) => {
      setDebouncedSearchText(debouncedValue);
    }, SEARCH_DEBOUNCE_TIME),
    []
  );

  // School change function
  const schoolChangeHandler = async (e) => {
    const schoolID = e.target.value;
    setCurrentPage(DEFAULT_PAGE);
    setSchool(schoolID);
  };

  // Year change function
  const yearChangeHandler = (e) => {
    const yearValue = e.target.value;
    setCurrentPage(DEFAULT_PAGE);
    setYear(yearValue);
  };

  // Search text change function
  const searchChangeHandler = (e) => {
    const { value } = e.target;
    setCurrentPage(DEFAULT_PAGE);
    getDebouncedSearchValue(value.toLowerCase().trim());
  };

  // Clear all filters
  const filterClearHandler = () => {
    setCurrentPage(DEFAULT_PAGE);
    setYear('');
    setSchool('');
    setShowClearFilters(false);
  };

  // Page change next/prev function
  const handlePageChange = (currentPage, newPage, rowsPerPage) => {
    setCurrentPage(newPage);
    setRowsCountPerPage(rowsPerPage);
  };

  // Page size change function
  const handleRowsPerPageChange = (rowsPerPage) => {
    setRowsCountPerPage(rowsPerPage);
  };

  return (
    <Dashboard>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20 }}
        >
          Members
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.actionButton}
            startIcon={<AddIcon />}
            classes={{ startIcon: classes.startIcon }}
            onClick={() => history.push(routes.memberCreate)}
          >
            New Member
          </Button>
        </Typography>
        <TableList
          currentPage={currentPage}
          columns={ACTIVE_MEMBER_COLUMNS}
          rows={tableData}
          totalCount={tableRecords}
          totalFamilies={familyCount}
          searchPlaceholderText={'Search by surname or email'}
          searchChangeHandler={searchChangeHandler}
          schoolChangeHandlerFamilyFree={schoolChangeHandler}
          yearChangeHandlerFree={yearChangeHandler}
          filterClearHandler={filterClearHandler}
          selectedSchool={school}
          showClearFilters={showClearFilters}
          year={year}
          tableRow={(row, index) => (
            <TableRowItem
              key={index}
              columns={ACTIVE_MEMBER_COLUMNS}
              row={row}
            />
          )}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          setRowsCountPerPage={setRowsCountPerPage}
        />
      </MuiPickersUtilsProvider>
    </Dashboard>
  );
}

export default withFirebase(MembersListActive);
