import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function EditUserIcon({ color, ...props }) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <path
            id="prefix__a"
            d="M11.333 1.667c3.13 0 5.667 2.537 5.667 5.666 0 2.03-1.067 3.81-2.67 4.81l-.014-.005c.524.175.807.741.633 1.265-.175.524-.741.807-1.265.632-.179-.06-.367-.112-.581-.162-2.282-.541-4.685-.008-6.525 1.446-1.839 1.454-2.911 3.67-2.911 6.014 0 .553-.448 1-1 1-.553 0-1-.447-1-1 0-2.956 1.352-5.75 3.671-7.583.908-.718 1.924-1.257 2.998-1.608-1.603-1-2.67-2.78-2.67-4.809 0-3.13 2.538-5.666 5.667-5.666zM18.946 13.4c.39-.391 1.024-.391 1.414 0l1.574 1.573c.39.39.39 1.024 0 1.414L16.28 22.04c-.187.188-.441.293-.707.293H14c-.552 0-1-.447-1-1V19.76c0-.265.105-.52.293-.707zm.707 2.12L15 20.175v.159h.159l4.653-4.653-.16-.16zm-8.32-11.853c-2.025 0-3.666 1.641-3.666 3.666S9.308 11 11.333 11 15 9.358 15 7.333s-1.642-3.666-3.667-3.666z"
          />
        </mask>
        <g fill={color || '#474747'} mask="url(#prefix__b)">
          <path d="M0 0H24V24H0z" />
        </g>
      </g>
    </SvgIcon>
  );
}
export default EditUserIcon;
