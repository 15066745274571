import React, {
  useCallback,
  useEffect,
  useState,
  createRef,
  useMemo
} from 'react';
import { useHistory } from 'react-router-dom';
 
// Material-UI-Components
import { Box, Typography, TextField, Button } from '@material-ui/core';
 
// Styles
import { useStyles } from './CodeVerifyView.style';
 
// Constants
import { routes } from 'src/utils/constant';
import { LOCAL_STORAGE_KEY, OTP_LENGTH } from 'src/constants/common';
 
// Layouts
import FullPage from '../../layout/FullPage';
 
// Redux-Actions
import { useDispatch, useSelector } from 'react-redux';
import {
  loaderStop,
  loaderStart,
  addMessage
} from 'src/redux/actions/appActions';
 
// Services
import { sendVerificationCode, verifyCode } from 'src/services/auth';
 
// Utils
import { withFirebase } from 'src/utils/firebase';
import lodash from 'lodash';
 
function CodeVerifyView() {
  const classes = useStyles();
 
  const history = useHistory();
  const dispatch = useDispatch();
 
  const [otpCode, setOTPCode] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
 
  const user = useSelector((state) => state.auth.user);
 
  const otpFields = useMemo(() => {
    return Array.from({ length: OTP_LENGTH }, (_, index) => index + 1);
  }, []);
 
  const digitRefs = useMemo(() => {
    return otpFields?.map(() => createRef());
  }, [createRef]);
 
  const resendOTPCode = useCallback(async () => {
    setErrorMessage('');
    dispatch(loaderStart());
    try {
      const result = await sendVerificationCode(user?.email);
      dispatch(loaderStop());
      if (result.message) {
        localStorage.setItem(LOCAL_STORAGE_KEY.IS_CODE_VERIFIED, false);
        dispatch(addMessage('New Code Sent'));
      }
    } catch (error) {
      const errorMessage = lodash.get(error.response.data, 'message', '');
      setErrorMessage(errorMessage);
      dispatch(loaderStop());
    }
  }, [dispatch, user]);
 
  const verifyEmailAndCode = useCallback(
    async (code) => {
      try {
        dispatch(loaderStart());
        const result = await verifyCode({ email: user?.email, code });
        if (result.message) {
          dispatch(loaderStop());
          return result;
        }
      } catch (error) {
        const errorMessage = lodash.get(error.response.data, 'message', '');
        setErrorMessage(errorMessage);
        dispatch(loaderStop());
      }
    },
    [user]
  );
 
  useEffect(() => {
    const otpCodeValue = Object.values(otpCode).join('');
    if (otpCodeValue.length === OTP_LENGTH) {
      verifyEmailAndCode(otpCodeValue)
        .then((result) => {
          if (result?.message) {
            localStorage.setItem(LOCAL_STORAGE_KEY.IS_CODE_VERIFIED, true);
            history.push(routes.membersActive);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    } else {
      setErrorMessage('');
    }
  }, [otpCode, history]);
 
  const handleKeyDown = (event, fieldNo) => {
    const digitRefIndex = fieldNo - 1;
    const firstDigitRefIndex = 0;
    const lastDigitRefIndex = OTP_LENGTH - 1;
 
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown')
      event.preventDefault();
    else if (event.key === 'ArrowLeft' && digitRefIndex > firstDigitRefIndex)
      digitRefs[digitRefIndex - 1]?.current?.focus();
    else if (event.key === 'ArrowRight' && digitRefIndex < lastDigitRefIndex) {
      if (digitRefs[digitRefIndex]?.current?.value) {
        digitRefs[digitRefIndex + 1]?.current?.focus();
      }
    }
    else if (event.key === 'Backspace') {
      event.target.value !== ''
        ? (event.target.value = '')
        : digitRefs[digitRefIndex - 1]?.current?.focus();
    } else if (event.key === 'Delete') {
      event.target.value !== '' && (event.target.value = '');
    } else if (event.key === 'Tab') {
      if (digitRefs[digitRefIndex]?.current?.value) {
        digitRefs[digitRefIndex + 1]?.current?.focus();
      }
      event.preventDefault()
    } 
  };
 
  const handleOTPInput = (e, fieldNo) => {
    const digitValue = e.target.value.slice(-1);
    setOTPCode((prevOTP) => ({ ...prevOTP, [fieldNo]: digitValue }));
 
    const digitRefIndex = fieldNo - 1;
    const firstDigitRefIndex = 0;
    const lastDigitRefIndex = OTP_LENGTH - 1;
 
    if (digitValue && digitRefIndex < lastDigitRefIndex) {
      digitRefs[digitRefIndex + 1]?.current?.focus();
    } else if (!digitValue && digitRefIndex > firstDigitRefIndex) {
      digitRefs[digitRefIndex - 1]?.current?.focus();
    }
  };
 
  const handleValidateSingleDigit = (e) => {
    e.target.value = e.target.value.slice(0, 1);
  };
 
  return (
    <FullPage>
      <div className={classes.constWrapper}>
        <Typography variant="h2" color="textPrimary" gutterBottom={true}>
          Almost there...
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          Enter the 6 digit code sent to your account email
        </Typography>
        <form className={classes.resendInput} noValidate autoComplete="off">
          {otpFields.map((fieldNo, index) => (
            <TextField
              key={index}
              type="number"
              onKeyDown={(e) => handleKeyDown(e, fieldNo)}
              onInput={handleValidateSingleDigit}
              onChange={(e) => handleOTPInput(e, fieldNo)}
              inputRef={digitRefs[fieldNo - 1]}
              className={classes.formField}
              error={Boolean(errorMessage)}
            />
          ))}
        </form>
        <Typography color="error" className={classes.errorMessage}>
          &nbsp;{errorMessage && `Oops. ${errorMessage}`}&nbsp;
        </Typography>
 
        <Box
          mt={7}
          mb={1.5}
          display="flex"
          alignItems="center"
          style={{ marginTop: 48 }}
        >
          <img
            alt="Resend Code"
            src="/static/images/resend-code.png"
            width="40"
            height="40"
            className={classes.imageWrapper}
          />
          <Box className={classes.codeText}>
            <Typography color="textSecondary">
              It may take a minute to receive your code
            </Typography>
            <Box display="flex" style={{ marginTop: -3.5 }}>
              <Typography color="textSecondary">
                Haven&apos;t received it yet?
              </Typography>
              <Button
                color="primary"
                className={classes.button}
                onClick={resendOTPCode}
              >
                <Typography className={classes.resendCode}>
                  &nbsp;&nbsp;Resend a new code
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    </FullPage>
  );
}
 
export default withFirebase(CodeVerifyView);