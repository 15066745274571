import React, { useEffect, useState, useCallback, useMemo } from 'react';
// import app from 'firebase/app';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withFirebase, FIREBASE_API_URL } from 'src/utils/firebase';
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { loginSuccess, logoutSuccess } from 'src/redux/actions/authActions';
import { role, agentCountry } from 'src/utils/constant';
import { encryptSHA256, isValidFileType } from 'src/utils/functions';
import {
  Button,
  Divider,
  Typography,
  CircularProgress,
  Avatar,
  Menu,
  MenuItem,
  TextField,
  FormControl,
  TableRow,
  TableCell,
  IconButton,
  Badge,
  InputLabel,
  Select,
  FormHelperText,
  Grid,
  InputAdornment
} from '@material-ui/core';
import { PROFILE_CIRCLE } from 'src/assets';
import TableList from 'src/components/TableList';
import { Formik } from 'formik';
import { MoreVert, Add as AddIcon } from '@material-ui/icons';
import { EditUser } from 'src/assets/Icons';
import Dashboard from '../../layout/Dashboard';
import RightDrawer from 'src/components/RightDrawer';
import Modal from 'src/components/Modal';
import * as Yup from 'yup';
import _ from 'lodash';
import { setAgent, resetAgent } from 'src/redux/actions/agentActions';
import ScanningUploadFileModal from 'src/components/ScanningUploadFileModal';
import { indexAgentStyles } from 'src/styles/agentStyle/indexAgentStyles';
import ConsoleLayout from '../index';
import { Loop } from '@material-ui/icons';
import { generatePatternPassword } from 'src/utils/functions';
import { MESSAGE_TYPES } from 'src/constants/common';

function AgentListView({ firebase }) {
  const classes = indexAgentStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [Agents, setAgentData] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [isDisableButton, setDisableButton] = useState();
  const isLoading = useSelector((state) => state.app.isLoading);
  const [isOpenModal, setOpenModal] = useState({ open: false, values: null });
  const [formValues, setFormValues] = useState(null);
  const [isDisabledField, setDisabledField] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteMenu, setOpenDeleteMenu] = React.useState(null);
  const [deleteText, setDeleteText] = React.useState('');
  const [isOpenConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const currentAgent = useSelector((state) => state.agent.currentAgent);
  const currentUser = useSelector((state) => state.auth.user);
  const [profileImageObject, setProfileImageObject] = React.useState(null);
  const [newPasswordFieldType, setNewPasswordFieldType] = useState('password');
  const [isEdit, setIsEdit] = useState(false);
  const [isRotate, setRotate] = useState(false);
  const [rowsCountPerPage, setRowsCountPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(false);
  const [currentAgentsData, setCurrentAgentsData] = useState([]);
  const [fileUploadModal, setFileUploadModal] = useState({
    isModalOpen: false,
    fileUploadState: 0
  });
  const { userRole } = useMemo(() => {
    return { userRole: currentUser?.role || '' };
  }, [currentUser]);

  const generatePassword = (setFieldValue) => {
    setRotate(true);
    setFieldValue('password', generatePatternPassword(8));
    dispatch(addMessage('Password generated and set'));
    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };

  const onToggleNewPasswordFieldType = () => {
    if (newPasswordFieldType === 'password') {
      setNewPasswordFieldType('text');
    } else {
      setNewPasswordFieldType('password');
    }
  };

  const logout = useCallback(() => {
    firebase.doSignOut();
    localStorage.removeItem('isCodeVerified');
    dispatch(logoutSuccess());
    history.push('/login');
  }, [firebase, history, dispatch]);

  const revokeToken = (userId) => {
    //     Method : Post
    // URL : /api/revokeToken
    // param : { user_id: xxxxxxxxx }

    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: userId
      })
    };
    fetch(`${FIREBASE_API_URL}/revokeToken`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result;
      })
      .then(
        (result) => {
          dispatch(loaderStop());
          if (!result) {
            const errorMessage = _.get(result, 'error', '');
            console.log(errorMessage);
            dispatch(loaderStop());
          }
        },
        (error) => {
          const errorMessage = _.get(error, 'message', '');
          console.log(errorMessage);
          dispatch(loaderStop());
        }
      );
  };

  const changePassword = (currentAgent, password) => {
    dispatch(loaderStart());
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: currentAgent.id,
        new_password: password,
        confirm_password: password
      })
    };
    fetch(`${FIREBASE_API_URL}/changePassword`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result;
      })
      .then(
        (result) => {
          if (result) {
            if (currentUser.uid !== currentAgent.id) {
              revokeToken(currentAgent.id);
              // Update users collection with encryptedPassword to logout user
              firebase.updateAgent(
                {
                  ...currentAgent,
                  password: '',
                  encryptedPassword: encryptSHA256(password)
                },
                currentAgent.id
              );
            } else {
              logout();
              dispatch(loaderStop());
            }
          } else {
            const errorMessage = _.get(result, 'error', '');
            console.log(errorMessage);
            dispatch(loaderStop());
          }
        },
        (error) => {
          const errorMessage = _.get(error, 'message', '');
          console.log(errorMessage);
          dispatch(loaderStop());
        }
      );
  };

  const deleteUserHandle = () => {
    setCurrentPage((prev) => !prev);
    dispatch(loaderStart());
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: currentAgent.id
      })
    };
    fetch(`${FIREBASE_API_URL}/deleteUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result;
      })
      .then(
        (result) => {
          if (result.message === 'Successfully deleted user') {
            firebase.deleteAgent(currentAgent.id);
            // dispatch(addMessage(result.message));
            setDeleteText('');
            setOpenDeleteModal(false);
            setOpenConfirmDeleteModal(true);
            fetchAgents();
          } else {
            dispatch(loaderStop());
            // dispatch(addMessage(result.message));
            setDeleteText('');
            setOpenDeleteModal(false);
            setOpenDeleteMenu(false);
            fetchAgents();
          }
        },
        (error) => {
          const errorMessage = _.get(error, 'message', '');
          console.log(errorMessage);
          dispatch(addMessage(errorMessage, MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      );
  };

  const handleDeleteConfirmCloseModal = useCallback(() => {
    setOpenConfirmDeleteModal(false);
    setOpenDeleteMenu();
    setDeleteText('');
  }, [setOpenConfirmDeleteModal]);

  const handleClose = useCallback(() => {
    setOpenModal({ open: false, values: null });
    setOpen(false);
  }, [setOpenModal]);

  const handleDeleteCloseModal = useCallback(() => {
    setOpenDeleteModal(false);
    setDeleteText('');
  }, [setOpenDeleteModal]);

  const onDeleteMenuClick = () => {
    setOpenDeleteModal(true);
  };

  const handleOpenDeleteMenu = (event) => {
    if (!openDeleteMenu) {
      setOpenDeleteMenu(event.currentTarget);
    }
  };

  const handleCloseDeleteMenu = () => {
    setOpenDeleteMenu(null);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required('First name is required'),
    lastName: Yup.string().trim().required('Last name is required'),
    initials: Yup.string()
      .trim()
      .required('Initials is required')
      .max(2, 'Initials contains only 2 characters'),
    role: Yup.string().trim().required(),
    country: Yup.string().trim().required(),
    email: Yup.string().trim().email().required('Valid email is required'),
    password: !isEdit
      ? Yup.string()
          .required('Password id required')
          .trim()
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
            'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.',
            { excludeEmptyString: true }
          )
      : null,
    profileImage: Yup.mixed()
  });

  const createTeamProfile = (formData) => {
    setCurrentPage((prev) => !prev);
    dispatch(loaderStart());
    const updatedData = {
      ...currentAgent,
      ...formData,
      password: '',
      encryptedPassword: encryptSHA256(currentAgent.password)
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email_address: formData.email,
        password: formData.password
      })
    };
    fetch(`${FIREBASE_API_URL}/createUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result;
      })
      .then(
        (result) => {
          if (result.user) {
            const userID = result.user.uid;
            firebase.addUser(updatedData, userID);
            dispatch(loaderStop());
            setOpenModal({
              open: false,
              values: null
            });
            fetchAgents();
            setOpen(false);
          }
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(
            addMessage(
              'Team member was not created. Please try again',
              MESSAGE_TYPES.ERROR
            )
          );
          dispatch(loaderStop());
        }
      );
  };

  const saveUpdated = ({ currentAgent, id }) => {
    setCurrentPage((prev) => !prev);
    dispatch(loaderStart());
    const updatedData = currentAgent.password
      ? {
          ...currentAgent,
          password: '',
          encryptedPassword: encryptSHA256(currentAgent.password)
        }
      : currentAgent;
    firebase
      .updateAgent(updatedData, id)
      .then(
        async () => {
          await updateFirebaseUser(id, currentAgent.email);
          dispatch(loaderStop());
          setOpenModal({
            open: false,
            values: null
          });
          fetchAgents();
          setOpen(false);
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(
            addMessage(
              'Team member was not created. Please try again',
              MESSAGE_TYPES.ERROR
            )
          );
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(
          addMessage(
            'Team member was not created. Please try again',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });
  };

  const updateFirebaseUser = async (userId, emailAddress) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: userId,
        email_address: emailAddress
      })
    };
    await fetch(`${FIREBASE_API_URL}/updateUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result;
      })
      .then(
        () => {},
        (error) => {
          const errorMessage = _.get(error, 'message', '');
          console.log(errorMessage);
          dispatch(loaderStop());
        }
      );
  };

  const openRightDrawer = useCallback(
    () => {
      setOpen(true);
      // if (data.role === 'Manager') {
      if (currentUser && currentUser.role === 'Manager') {
        setDisabledField(false);
      } else {
        setDisabledField(true);
      }
    },
    [setOpen, currentUser]
  );

  const onClose = useCallback(
    (currentState) => {
      setOpen(currentState);
      dispatch(resetAgent());
    },
    [setOpen, dispatch]
  );

  const handlePagination = (data, currentPage, rowsPerPage) => {
    const startIndex = rowsPerPage * currentPage;
    let endIndex = rowsPerPage * (currentPage + 1);
    if (endIndex > data.length) {
      endIndex = data.length;
    }
    const filteredDataArr = data.slice(startIndex, endIndex);
    setCurrentAgentsData(filteredDataArr);
  };

  const fetchAgents = useCallback(() => {
    firebase
      .getAgents()
      .then(function (querySnapshot) {
        const AgentsData = [];
        querySnapshot.forEach(function (doc) {
          AgentsData.push({ ...doc.data(), ...{ id: doc.id } });
        });
        setAgentData(AgentsData);
        handlePagination(AgentsData, 0, rowsCountPerPage);
        dispatch(loaderStop());
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(
          addMessage(
            'Server connection issue. Please refresh',
            MESSAGE_TYPES.ERROR
          )
        );
        dispatch(loaderStop());
      });
  }, [firebase, dispatch, setAgentData]);

  useEffect(() => {
    dispatch(resetAgent());
    dispatch(loaderStart());
    fetchAgents();
  }, [firebase, dispatch, fetchAgents]);

  const handleOnClose = () => {
    setFileUploadModal((prev) => ({ ...prev, isModalOpen: false }));
  };

  const uploadAgentsProfileImage = (event) => {
    const fileLength = event.target.files.length;
    if (fileLength > 0) {
      const file = event.target.files[0];
      const fileType = file.name.split('.').pop().toLowerCase();
      event.target.value = '';
      if (isValidFileType(fileType, true)) {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 0 });
        firebase
          .uploadAgentsProfilePicture(file)
          .then((snapShot) => {
            // takes a snap shot of the process as it is happening
            if (snapShot && snapShot.state === 'success') {
              firebase
                .getDownloadURLForAgentsProfileImage(file)
                .then((fireBaseUrl) => {
                  const profileImage = {
                    url: fireBaseUrl,
                    storage_path: `userProfiles/agents/${file.name}`
                  };
                  setProfileImageObject(profileImage);
                  handleOnClose();
                })
                .catch((err) => {
                  handleOnClose();
                  // catches the errors
                  console.log('Error while Getting Team Download URL', err);
                });
            } else {
              setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
            }
          })
          .catch((err) => {
            setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
            // catches the errors
            console.log('Error while Uploading Team Profile Picture', err);
          });
      } else {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 1 });
      }
    }
  };

  const removeAgentsProfileImage = () => {
    const profileImageObjectData = profileImageObject
      ? profileImageObject
      : currentAgent.profileImage;
    const storagePath = _.get(profileImageObjectData, 'storage_path', '');
    firebase
      .deleteAgentsProfileImageFromStorage(storagePath)
      .then(() => {
        setProfileImageObject(null);
        if (isEdit) {
          if (currentAgent.profileImage !== null) {
            currentAgent.profileImage = null;
            const user = { ...currentAgent };
            delete user.password;

            saveUpdated({ currentAgent: user, id: currentAgent.id });
            if (currentUser.uid === currentAgent.id) {
              dispatch(
                loginSuccess({
                  ...user,
                  profileImage: null
                })
              );
            }
          }
        }
      })
      .catch((error) => {
        console.log('error while removing profile picture', error);
      });
  };

  const columns = [
    {
      id: 'name',
      label: 'Name',
      minWidth: 170,
      format: 'icon',
      iconPosition: 'before',
      icon: PROFILE_CIRCLE,
      width: 410,
      hasSort: true,
      style: { paddingLeft: 15 }
    },
    { id: 'role', label: 'Role', minWidth: 100 },
    {
      id: 'action',
      label: 'Action',
      format: 'icon',
      iconPosition: 'center',
      icon: false,
      width: 144
    }
  ];

  function CellItem({ row, column }) {
    const classes = indexAgentStyles();
    if (column.id === 'name') {
      const profileImage = _.get(row['profileImage'], 'url', '');
      return (
        <div className={classes.nameColumn}>
          <Avatar src={profileImage}>{row['initials']}</Avatar>
          {row['firstName'] + ' ' + row['lastName']}
        </div>
      );
    }
    if (column.id === 'role') {
      return row['role'];
    }
    if (column.id === 'action') {
      return (
        <IconButton
          aria-label="Edit Agent"
          disableRipple
          disableFocusRipple
          className={classes.actionIcon}
        >
          <EditUser viewBox="0 0 24 24" />
        </IconButton>
      );
    }
    return '';
  }

  function TableRowItem({ row, columns }) {
    const classes = indexAgentStyles();
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        className={classes.tableRow}
        onClick={(e) => {
          handleClose(e);
          setProfileImageObject(row.profileImage);
          dispatch(setAgent(row));
          openRightDrawer(row);
          setIsEdit(true);
        }}
      >
        {columns.map((column) => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              className={classes.dataTableCell}
              style={column.style}
            >
              <CellItem row={row} column={column} />
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  const onCloseForm = () => {
    const values = formValues;
    const valuesObject = {
      firstName: values.firstName,
      lastName: values.lastName,
      initials: values.initials,
      email: values.email,
      role: values.role,
      country: values.country,
      profileImage: profileImageObject
        ? profileImageObject
        : currentUser.profileImage
          ? currentUser.profileImage
          : '',
      userName: currentAgent.userName || '',
      phoneNumber: currentAgent.phoneNumber || ''
    };
    const dataObject = {
      firstName: currentAgent.firstName,
      lastName: currentAgent.lastName,
      initials: currentAgent.initials,
      role: currentAgent.role,
      country: values.country,
      email: currentAgent.email || '',
      profileImage: currentAgent.profileImage || '',
      userName: currentAgent.userName || '',
      phoneNumber: currentAgent.phoneNumber || ''
    };

    if (currentUser.uid === currentAgent.id) {
      dispatch(
        loginSuccess({
          ...currentUser,
          ...valuesObject,
          profileImage: profileImageObject
        })
      );
    }

    const sameData = _.isEqual(valuesObject, dataObject);
    if (sameData) {
      setOpen(false);
      if (!_.isEmpty(values.password)) {
        changePassword(currentAgent, values.password);
      }
    } else {
      if (!_.isEmpty(values.password)) {
        changePassword(currentAgent, values.password);
      }
      setOpenModal({
        open: true,
        values: {
          currentAgent: { ...currentAgent, ...valuesObject },
          id: currentAgent.id
        }
      });
    }
  };
  if (currentUser === null) {
    return null;
  }

  // Page change next/prev function
  const handlePageChange = (currentPage, newPage, rowsPerPage) => {
    const updatedPage = newPage;
    handlePagination(Agents, updatedPage, rowsPerPage);
  };

  // Page size change function
  const handleRowsPerPageChange = (rowsPerPage) => {
    setRowsCountPerPage(rowsPerPage);
    handlePagination(Agents, 0, rowsPerPage);
  };

  return (
    <Dashboard>
      <Typography variant="h2" color="textPrimary" style={{ marginBottom: 20 }}>
        Console
      </Typography>
      <ConsoleLayout
        sideButton={
          userRole !== 'Agent' && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.actionButton}
              startIcon={<AddIcon />}
              classes={{ startIcon: classes.startIcon }}
              onClick={() => {
                setOpen(true);
                setIsEdit(false);
              }}
            >
              Team Member
            </Button>
          )
        }
      >
        {userRole !== 'Agent' && (
          <>
            <TableList
              columns={columns}
              currentPage={currentPage}
              rows={currentAgentsData}
              totalCount={Agents?.length}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
              rowsCountPerPage={rowsCountPerPage}
              setRowsCountPerPage={setRowsCountPerPage}
              disableSearch
              searchPlaceholderText={''}
              pageTitle={'Schools'}
              tableRow={(row, index) => (
                <TableRowItem key={index} columns={columns} row={row} />
              )}
            />
            <RightDrawer
              isOpen={isOpen}
              onClose={() => {
                onClose(true);
                onCloseForm();
              }}
              width={520}
            >
              <div>
                <Formik
                  initialValues={{
                    firstName: currentAgent.firstName
                      ? currentAgent.firstName
                      : '',
                    lastName: currentAgent.lastName
                      ? currentAgent.lastName
                      : '',
                    email: currentAgent.email ? currentAgent.email : '',
                    initials: currentAgent.initials
                      ? currentAgent.initials
                      : '',
                    role: currentAgent.role ? currentAgent.role : '',
                    country: currentAgent.country ? currentAgent.country : '',
                    password: '',
                    profileImage: currentAgent.profileImage
                      ? currentAgent.profileImage
                      : ''
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    const valuesObject = {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      initials: values.initials,
                      email: values.email,
                      role: values.role,
                      country: values.country,
                      profileImage: profileImageObject
                        ? profileImageObject
                        : currentAgent.profileImage
                          ? currentAgent.profileImage
                          : '',
                      userName: currentAgent.userName || '',
                      phoneNumber: currentAgent.phoneNumber || ''
                    };
                    const dataObject = {
                      firstName: currentAgent.firstName,
                      lastName: currentAgent.lastName,
                      initials: currentAgent.initials,
                      email: currentAgent.email,
                      role: currentAgent.role,
                      country: currentAgent.country,
                      profileImage: currentAgent.profileImage || '',
                      userName: currentAgent.userName || '',
                      phoneNumber: currentAgent.phoneNumber || ''
                    };
                    const sameData = _.isEqual(valuesObject, dataObject);
                    if (currentUser.uid === currentAgent.id) {
                      dispatch(
                        loginSuccess({
                          ...currentUser,
                          ...valuesObject,
                          profileImage: profileImageObject
                        })
                      );
                    }
                    const currentAgentData = {
                      ...currentAgent,
                      ...valuesObject,
                      id: currentAgent.id
                    };
                    if (sameData) {
                      if (!_.isEmpty(values.password)) {
                        changePassword(currentAgentData, values.password);
                      }
                      setOpen(false);
                    } else {
                      const user = { ...currentAgent };

                      if (isEdit) {
                        delete user.password;
                        saveUpdated({
                          currentAgent: {
                            ...user,
                            ...valuesObject,
                            id: currentAgent.id
                          },
                          id: currentAgent.id
                        });
                        if (!_.isEmpty(values.password)) {
                          changePassword(currentAgentData, values.password);
                        }
                      } else {
                        createTeamProfile({
                          ...values,
                          profileImage: profileImageObject
                            ? profileImageObject
                            : ''
                        });
                      }
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    handleLoad,
                    setFieldValue,
                    validateForm,
                    setTouched
                  }) => {
                    const isSubmitButtonDisabled =
                      _.isEmpty(values.firstName) ||
                      _.isEmpty(values.lastName) ||
                      _.isEmpty(values.initials) ||
                      _.isEmpty(values.email);
                    setDisableButton(isSubmitButtonDisabled);
                    if (
                      !_.isEmpty(touched) &&
                      !isSubmitButtonDisabled &&
                      !_.isEmpty(errors)
                    ) {
                      setDisableButton(true);
                    }
                    const profileImageURL = profileImageObject
                      ? profileImageObject
                      : currentAgent.profileImage;
                    setFormValues(values);
                    return (
                      <form
                        noValidate
                        onSubmit={handleSubmit}
                        // eslint-disable-next-line react/no-unknown-property
                        onLoad={handleLoad}
                      >
                        <div className={classes.headingContainer}>
                          <span className={classes.mainHeading}>
                            {`${isEdit ? 'Edit' : 'New'}`} Team{' '}
                            {`${isEdit ? 'Profile' : 'Member'}`}
                          </span>
                          <div>
                            <Button
                              variant="contained"
                              color={isDisableButton ? 'secondary' : 'primary'}
                              className={classes.siderSave}
                              onClick={handleSubmit}
                              disabled={isDisableButton}
                            >
                              Save
                            </Button>
                            {isEdit &&
                              currentUser &&
                              currentUser.uid !== currentAgent.id && (
                                <Button
                                  aria-controls="delete-menu"
                                  aria-haspopup="true"
                                  className={classes.moreVertButton}
                                  onClick={handleOpenDeleteMenu}
                                >
                                  <MoreVert />
                                  <Menu
                                    id="delete-menu"
                                    open={Boolean(openDeleteMenu)}
                                    onClose={handleCloseDeleteMenu}
                                    className={classes.popoverClass}
                                  >
                                    <MenuItem onClick={onDeleteMenuClick}>
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </Button>
                              )}
                          </div>
                        </div>
                        <Divider
                          variant="middle"
                          className={classes.topDividerClass}
                        />
                        <div className={classes.formMargin}>
                          <FormControl
                            component="fieldset"
                            style={{ width: '100%' }}
                          >
                            <TextField
                              label="First Name"
                              variant="outlined"
                              onBlur={handleBlur}
                              name="firstName"
                              value={values.firstName ? values.firstName : ''}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue(
                                  `initials`,
                                  `${e.target.value.charAt(
                                    0
                                  )}${values.lastName.charAt(0)}`
                                );
                              }}
                              error={Boolean(
                                touched.firstName && errors.firstName
                              )}
                              helperText={touched.firstName && errors.firstName}
                              className={classes.inputControlBox}
                              autoFocus
                              disabled={isDisabledField}
                            />
                          </FormControl>
                          <FormControl component="fieldset">
                            <TextField
                              label="Last Name"
                              variant="outlined"
                              onBlur={handleBlur}
                              name="lastName"
                              value={values.lastName ? values.lastName : ''}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue(
                                  `initials`,
                                  `${values.firstName.charAt(
                                    0
                                  )}${e.target.value.charAt(0)}`
                                );
                              }}
                              error={Boolean(
                                touched.lastName && errors.lastName
                              )}
                              helperText={touched.lastName && errors.lastName}
                              className={classes.inputControlBox}
                              disabled={isDisabledField}
                            />
                          </FormControl>
                          <div style={{ width: 427 }}>
                            <FormControl component="fieldset">
                              <TextField
                                label="Initials"
                                variant="outlined"
                                className={classes.inputControlBox}
                                onBlur={handleBlur}
                                name="initials"
                                value={values.initials ? values.initials : ''}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.initials && errors.initials
                                )}
                                helperText={touched.initials && errors.initials}
                                style={{ width: 120, marginRight: 25 }}
                                disabled={isDisabledField}
                              />
                            </FormControl>
                          </div>
                          <div className={classes.profileImageWrapper}>
                            <label
                              htmlFor="upload-agent-profile-picture"
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                style={{ display: 'none' }}
                                id="upload-agent-profile-picture"
                                name="upload-agent-profile-picture"
                                type="file"
                                onChange={(e) => uploadAgentsProfileImage(e)}
                              />
                              <Badge
                                overlap="circle"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                // badgeContent={
                                //   _.isEmpty(profileImageURL) && <CameraAltIcon />
                                // }
                              >
                                <Avatar
                                  className={classes.imageContainer}
                                  alt={values.initials}
                                  src={_.get(profileImageURL, 'url', '')}
                                >
                                  {values.initials}
                                </Avatar>
                              </Badge>
                              {_.isEmpty(profileImageURL) && (
                                <div className={classes.removeImageContainer}>
                                  <Typography
                                    className={classes.uploadImageText}
                                  >
                                    Upload Image
                                  </Typography>
                                </div>
                              )}
                            </label>
                            {!_.isEmpty(profileImageURL) && (
                              <div
                                className={classes.removeImageContainer}
                                onClick={() => removeAgentsProfileImage()}
                              >
                                <Typography className={classes.uploadImageText}>
                                  Remove
                                </Typography>
                              </div>
                            )}
                          </div>
                          <Divider className={classes.dividerClass} />
                          <FormControl component="fieldset">
                            <TextField
                              label="Login Email"
                              variant="outlined"
                              onBlur={handleBlur}
                              name="email"
                              value={values.email ? values.email : ''}
                              onChange={handleChange}
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                              className={classes.inputControlBox}
                              disabled={isDisabledField}
                            />
                          </FormControl>
                          <div style={{ width: '100%', marginBottom: 20 }}>
                            <FormControl component="fieldset">
                              <TextField
                                label="New Password"
                                variant="outlined"
                                onBlur={handleBlur}
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.password && errors.password
                                )}
                                helperText={touched.password && errors.password}
                                className={classes.inputControlBox}
                                type={newPasswordFieldType}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle newPassword visibility"
                                        onClick={() =>
                                          onToggleNewPasswordFieldType()
                                        }
                                        tabIndex="-1"
                                      >
                                        <img
                                          alt="Password Requirement"
                                          src={
                                            newPasswordFieldType === 'text'
                                              ? '/static/images/password-hide.png'
                                              : '/static/images/password-see.png'
                                          }
                                          width="22"
                                          height="22"
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                              <span className={classes.generate}>
                                <Loop
                                  height={24}
                                  width={24}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    generatePassword(setFieldValue)
                                  }
                                  className={
                                    isRotate ? classes.closeIconRotate : ''
                                  }
                                />
                                Generate
                              </span>
                            </FormControl>
                            <Divider className={classes.dividerClass} />
                            <Grid container>
                              <Grid item xs={6}>
                                <FormControl
                                  variant="outlined"
                                  classes={{ root: classes.formControl }}
                                  style={{ marginTop: 24 }}
                                  disabled={currentUser.uid === currentAgent.id}
                                >
                                  <InputLabel id="gender-select-outlined-label">
                                    Role
                                  </InputLabel>
                                  <Select
                                    labelId="gender-select-outlined-label"
                                    id="gender-select-outlined"
                                    className={classes.inputControlBox}
                                    onBlur={handleBlur}
                                    name={`role`}
                                    onChange={handleChange}
                                    value={values.role}
                                    error={Boolean(touched.role && errors.role)}
                                    label="role"
                                    style={{ width: 199, marginTop: 0 }}
                                  >
                                    <MenuItem value="">
                                      <em>Role</em>
                                    </MenuItem>
                                    {role.map(function (object) {
                                      return (
                                        <MenuItem
                                          key={object.value}
                                          value={object.value}
                                        >
                                          {object.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  {touched.role && errors.role ? (
                                    <FormHelperText
                                      error={Boolean(
                                        touched.role && errors.role
                                      )}
                                    >
                                      {errors.role}
                                    </FormHelperText>
                                  ) : (
                                    ''
                                  )}
                                </FormControl>
                              </Grid>

                              <Grid item xs={6}>
                                <FormControl
                                  variant="outlined"
                                  classes={{ root: classes.formControl }}
                                  style={{ marginTop: 24 }}
                                >
                                  <InputLabel id="gender-select-outlined-label">
                                    Country
                                  </InputLabel>
                                  <Select
                                    labelId="gender-select-outlined-label"
                                    id="gender-select-outlined"
                                    className={classes.inputControlBox}
                                    onBlur={handleBlur}
                                    name={`country`}
                                    onChange={handleChange}
                                    value={values.country ? values.country : ''}
                                    error={Boolean(
                                      touched.country && errors.country
                                    )}
                                    label="country"
                                    style={{ width: 199, marginTop: 0 }}
                                  >
                                    <MenuItem value="">
                                      <em>Country</em>
                                    </MenuItem>
                                    {agentCountry.map(function (object) {
                                      return (
                                        <MenuItem
                                          key={object.code}
                                          value={object.name}
                                        >
                                          {object.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  {touched.country && errors.country ? (
                                    <FormHelperText
                                      error={Boolean(
                                        touched.country && errors.country
                                      )}
                                    >
                                      {errors.country}
                                    </FormHelperText>
                                  ) : (
                                    ''
                                  )}
                                </FormControl>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                        {isOpenModal.open && (
                          <Modal
                            isOpen={isOpenModal.open}
                            onClose={handleClose}
                          >
                            <div className={classes.modalBox}>
                              <Typography className={classes.modalTitle}>
                                Save your changes?
                              </Typography>
                              <div className={classes.modalContent}>
                                <Typography className={classes.modalSubTitle}>
                                  Your changes will be lost if you don&apos;t
                                  save them.
                                </Typography>
                              </div>
                              <div className={classes.modalActions}>
                                <Button
                                  onClick={() => {
                                    const user = {
                                      ...isOpenModal.values.currentAgent
                                    };
                                    delete user.password;
                                    const data = {
                                      currentAgent: user,
                                      id: isOpenModal.values.id
                                    };
                                    if (Object.keys(errors).length === 0) {
                                      saveUpdated(data);
                                    } else {
                                      validateForm(values);
                                      setTouched(errors);
                                      setOpenModal({
                                        open: false,
                                        values: null
                                      });
                                    }
                                  }}
                                  color="primary"
                                  variant="contained"
                                  size="large"
                                  startIcon={
                                    isLoading && (
                                      <CircularProgress
                                        color="secondary"
                                        size={25}
                                      />
                                    )
                                  }
                                  className={classes.primaryButton}
                                >
                                  {!isLoading && 'Save Changes'}
                                </Button>
                                <Button
                                  onClick={handleClose}
                                  variant="contained"
                                  size="large"
                                  className={classes.greyButton}
                                >
                                  Don&apos;t Save
                                </Button>
                              </div>
                            </div>
                          </Modal>
                        )}
                        {isOpenDeleteModal && (
                          <Modal
                            isOpen={isOpenDeleteModal}
                            onClose={handleDeleteCloseModal}
                          >
                            <div className={classes.modalBox}>
                              <Typography className={classes.modalTitle}>
                                Delete Team Member?
                              </Typography>
                              <div className={classes.modalSubHeading}>
                                <Typography className={classes.modalSubTitle}>
                                  <div className={classes.boldFont}>
                                    Warning: This action can not be reversed.
                                  </div>
                                  The following data will be permanently removed
                                  from the database.
                                </Typography>
                              </div>
                              <div className={classes.modalContent}>
                                <Typography className={classes.modalSubTitle}>
                                  - Team profile and associated logins
                                </Typography>
                              </div>
                              <div className={classes.modalSubHeading}>
                                <div
                                  className={classes.modalSubTitle}
                                  style={{
                                    fontWeight: 500,
                                    marginBottom: '3.3%'
                                  }}
                                >
                                  Type &quot;DELETE USER&quot; to confirm
                                </div>
                                <FormControl
                                  component="fieldset"
                                  style={{ width: '100%' }}
                                >
                                  <TextField
                                    label=""
                                    variant="outlined"
                                    className={classes.inputControlBox}
                                    style={{ marginTop: 0 }}
                                    value={deleteText}
                                    onChange={(e) =>
                                      setDeleteText(e.target.value)
                                    }
                                  />
                                </FormControl>
                              </div>
                              <div className={classes.modalActions}>
                                <Button
                                  onClick={deleteUserHandle}
                                  color="primary"
                                  variant="contained"
                                  size="large"
                                  disabled={deleteText !== 'DELETE USER'}
                                  className={classes.primaryButton}
                                  startIcon={
                                    isLoading && (
                                      <CircularProgress
                                        color="secondary"
                                        size={25}
                                      />
                                    )
                                  }
                                >
                                  {!isLoading && 'Delete'}
                                </Button>
                                <Button
                                  onClick={(e) => {
                                    handleDeleteCloseModal(e);
                                    handleCloseDeleteMenu(e);
                                  }}
                                  variant="contained"
                                  size="large"
                                  className={classes.greyButton}
                                  style={{ boxShadow: 'none' }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          </Modal>
                        )}
                        {isOpenConfirmDeleteModal && (
                          <Modal
                            isOpen={isOpenConfirmDeleteModal}
                            onClose={handleDeleteConfirmCloseModal}
                          >
                            <div className={classes.modalBox}>
                              <Typography
                                className={classes.modalTitle}
                                style={{ marginBottom: 18 }}
                              >
                                Team Profile Deleted
                              </Typography>
                              <Typography
                                className={classes.modalSubTitle}
                                style={{ marginBottom: 26 }}
                              >
                                The profile and login credentials{' '}
                                {currentAgent.firstName} {currentAgent.lastName}{' '}
                                have now been removed from the database.
                              </Typography>
                              <div className={classes.modalActions}>
                                <Button
                                  onClick={() => {
                                    handleDeleteConfirmCloseModal();
                                    setOpen(false);
                                    fetchAgents();
                                  }}
                                  color="primary"
                                  variant="contained"
                                  size="large"
                                  className={classes.primaryButton}
                                >
                                  Continue
                                </Button>
                              </div>
                            </div>
                          </Modal>
                        )}
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </RightDrawer>
            <ScanningUploadFileModal
              handleOnClose={handleOnClose}
              isOpen={fileUploadModal.isModalOpen}
              fileUploadState={fileUploadModal.fileUploadState}
            />
          </>
        )}
      </ConsoleLayout>
    </Dashboard>
  );
}

export default withFirebase(AgentListView);
