import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { colors } from 'src/utils/constant';

const useStyles = makeStyles((theme) => ({
  navigationButton: {
    boxSizing: 'border-box',
    padding: '8px 24px',
    textTransform: 'none',
    borderBottom: '2px solid transparent'
  },
  activeTab: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0
  },
  divider: {
    borderRight: `1px solid ${colors.primary}`,
    margin: '0 8px'
  }
}));

const NavTab = ({ tabs, selectedTab }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      {tabs.map((tab, index) => {
        if (tab.divider) {
          return <span key={index} className={classes.divider} />;
        }
        return (
          <Button
            classes={{ root: classes.navigationButton }}
            className={selectedTab === tab.label ? classes.activeTab : ''}
            onClick={() => {
              if (tab.handleTabClick) {
                tab.handleTabClick();
              } else {
                history.push(tab.link);
              }
            }}
            key={tab.label}
          >
            {tab.label}
          </Button>
        );
      })}
    </>
  );
};

export default NavTab;
