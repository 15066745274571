import { makeStyles } from '@material-ui/core';
import { colors } from 'src/utils/constant';

export const profileStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%'
  },
  cancelButton: {
    width: 106,
    borderRadius: 3,
    border: `solid 1 ${colors.primaryLightGrey}`,
    backgroundColor: colors.primaryLightGrey,
    // float: 'left'
    height: 50
  },
  disabledButtonText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    border: `solid 1px ${colors.primaryLightGrey}`,
    backgroundColor: colors.secondaryGreyLightSync,
    color: colors.primaryDarkGrey,
    boxShadow: 'none !important',
    '&:hover': {
      backgroundColor: colors.secondaryGreyLightSync
    },
    '&:disabled': {
      backgroundColor: colors.secondaryGreyLightSync,
      color: colors.primaryDarkGrey
    },
    height: 50,
    width: 106
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.width,
    height: 50,
    width: 106
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  subtitle: {
    maxWidth: 576,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey,
    marginBottom: 40
  },
  formTitle: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    marginBottom: 23
  },
  notificationTitle: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    marginTop: 10,
    marginBottom: 20
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  contentWrapper: {
    maxWidth: 572,
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  controlBox: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#ffffff',
    '& .MuiOutlinedInput-root:hover, & .MuiOutlinedInput-root:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  inputControlBox: {
    '& .MuiSelect-outlined:hover, & .MuiSelect-outlined:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  centerText: {
    height: 19,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: colors.primaryLightGrey,
    marginTop: 65
  },
  contentBlock: {
    width: 574,
    height: 150,
    borderRadius: 3,
    border: 1,
    borderColor: colors.primaryLightGrey,
    borderStyle: 'dashed',
    marginTop: '1.6%'
  },
  deleteButton: {
    marginRight: '0px',
    marginLeft: 'auto',
    height: 'fit-content'
  },
  deleteText: {
    color: colors.error,
    textDecoration: 'underline'
  },
  buttonTextSave: {
    height: 24,
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center'
  },
  formDateControlFromBordered: {
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: 3,
    padding: 0,
    // margin: '10px 0',
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-input': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10
    }
  },
  flexBoxClass: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  timeLabel: {
    marginLeft: '2rem'
  }
}));
