import React from 'react';
import {
  Card,
  CardContent,
  Container,
  makeStyles,
  Backdrop,
  CircularProgress
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import Logo from '../../components/Logo';
import { colors } from '../../utils/constant';
import MessageSnackbar from 'src/components/Message';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: '#F4F6F8',
    display: 'flex',
    height: '100%',
    minHeight: '100vh',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.primary
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    maxWidth: 621,
    margin: 'auto',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(7, 7, 7, 7)
  },
  logo: {
    alignSelf: 'center',
    marginBottom: '54px'
  },
  loginBar: {
    height: '60px',
    backgroundColor: '#ff8a00',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center'
  },
  textLine: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: '0.1px',
    textAlign: 'left',
    color: '#fff',
    fontWeight: 600
  }
}));

function FullPage({ children, cardContentProps }) {
  const classes = useStyles();
  const isLoading = useSelector((state) => state.app.isLoading);
  const pathname = window.location.pathname.slice(1);
  const status = useSelector((state) => state.auth.activeStatus);

  return (
    <>
      {pathname === 'reset-password' ? (
        <div className={classes.loginBar}>
          <p className={classes.textLine}>It’s time to update your password</p>
        </div>
      ) : status &&
        pathname === 'login' &&
        window.sessionStorage.getItem('isCodeVerified') ? (
        <div className={classes.loginBar}>
          <p className={classes.textLine}>
            Your session has expired due to inactivity
          </p>
        </div>
      ) : null}

      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={isLoading || false}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <MessageSnackbar />
        <div className={classes.logo}>
          <Logo width="160" />
        </div>
        <Container maxWidth="md">
          <Card className={classes.card}>
            <CardContent
              classes={{ root: classes.content }}
              {...cardContentProps}
            >
              {children}
            </CardContent>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default FullPage;
