import { EDIT_ICON } from 'src/assets';

export const PENDING_MEMBER_COLUMNS = [
  {
    id: 'familySurname',
    label: 'Surname',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '12%'
  },
  {
    id: 'parents',
    label: 'First',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '22%'
  },
  {
    id: 'schools',
    label: 'School Name',
    // minWidth: 170,
    setLabelStyle: true,
    iconPosition: 'before',
    hasSort: true
    // width: '14%'
  },
  {
    id: 'childs',
    label: 'Year',
    align: 'left',
    format: 'icon',
    onEach: true,
    icon: EDIT_ICON,
    iconPosition: 'before',
    hasSort: true,
    width: '22%'
  },
  // {
  //   id: 'freeHost',
  //   label: 'Free Host?',
  //   minWidth: 170,
  //   iconPosition: 'before',
  //   hasSort: true,
  //   width: '16%'
  // },

  {
    id: 'status',
    label: 'Status',
    // minWidth: 170,
    setLabelStyle: true,
    iconPosition: 'before',
    hasSort: true
    // width: '14%'
  },
  {
    id: 'date',
    label: 'Modified',
    minWidth: 10,
    setLabelStyle: true,
    iconPosition: 'before',
    hasSort: true,
    sortable: true
    // width: '14%'
  },
  {
    id: 'apiSync',
    label: '',
    align: 'center',
    format: 'icon',
    iconPosition: 'right',
    icon: false,
    width: '5%'
  }
];
