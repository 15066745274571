import { makeStyles } from '@material-ui/core';
import { colors } from 'src/utils/constant';

export const studentIndexStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  drawerLeftContent: {
    width: `calc(60% - 20px)`,
    height: `calc(100vh - 30px)`,
    borderRadius: 5,
    backgroundColor: 'rgb(255, 245, 224,1)',
    float: 'left',
    position: 'relative',
    margin: 10
  },
  leftContentMargin: {
    marginLeft: 40,
    marginRight: 40
  },
  leftContentHeader: {
    textAlign: 'right'
  },
  siderEdit: {
    height: 40,
    marginTop: 19
  },
  siderDetail: {
    fontSize: 16,
    color: colors.primaryDarkGrey,
    marginBottom: 8,
    '&:hover': {
      color: '#8bc517'
    }
  },
  toolbarHidden: {
    display: 'none'
  },
  toolbar: {
    backgroundColor: 'transparent',
    border: 0,
    '& .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-target-option': {
      display: 'none'
    }
  },
  textEditor: {
    maxHeight: `calc(100vh - 150px)`
  },
  textEditorOnEdit: {
    maxHeight: `calc(100vh - 230px)`
  },
  handBookContainer: { position: 'absolute', bottom: -20, right: -10 },
  handBookClass: { width: 360, height: 297, opacity: 0.32 },
  drawerRightContent: {
    width: '40%',
    height: `calc(100vh - 10px)`,
    backgroundColor: '#f4f5f7',
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    height: 71
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primaryDarkGrey
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  bredthcrumbTitle: {
    color: '#536e7b',
    fontSize: 16,
    marginRight: 24,
    display: 'inline-block',
    cursor: 'pointer'
  },
  tableRow: {
    height: 72,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: `rgba(244, 245, 247, 0.51) !important`,
      "& [class^='makeStyles-hoverTag-']": {
        color: colors.primary
      }
    }
  },
  dataTableCell: {
    '& *': {
      color: colors.primaryDark
    },
    padding: '16px 10px',
    '&:first-child': {
      paddingLeft: 22
    }
  },
  redacted: {
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)',
    pointerEvents: 'none',
    userSelect: 'none',
    '&&::selection': {
      color: 'transparent',
      background: 'transparent'
    }
  },
  conatiner: {
    display: 'inline-flex',
    height: 'calc(100vh - 10px)'
  },
  dividerClass: {
    backgroundColor: colors.primaryLightGrey
  },
  studentReportPopUp: {
    width: '240px',
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#4c5b68',
    color: '#ffffff',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5
  },
  reportImage: {
    position: 'absolute',
    right: 0,
    top: -35,
    cursor: 'pointer',
    zIndex: 5
  },
  flexBoxBetween: { display: 'flex', justifyContent: 'space-between' }
}));
