// Family set Success
export const setFamily = (family) => ({
  type: 'SET_FAMILY',
  family
});

// Reset Family
export const resetFamily = () => ({
  type: 'RESET_FAMILY'
});
